import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { map, pipe } from 'ramda';

import { AggregationStrategy } from '@/features/dashboard/features/chart/domain';
import { LabelDto } from '@/features/dashboard/features/chart/ui/entities/ChartLabel';
import {
  mapDateStringToLabelDto,
  mapEntitiesToDateStrings,
} from '@/features/dashboard/features/chart/ui/mappers';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { AggregatedChartDataEntity } from '../../domain/entities/AggregatedChartDataEntity';

import { MONTHS_SHORTCUTS } from '@/translations/enums/monthsShortcuts';

export const useChartLabels = (
  entities: AggregatedChartDataEntity[],
  selectedDate: DATE_SELECT_OPTION_VALUES | string,
  strategy: AggregationStrategy,
): string[] => {
  const { t } = useTranslation(['monthsShortcuts', 'months', 'dashboard']);

  const getSelectOptionRichString = (dayName: string, dayObj: Dayjs): string => {
    const date = dayObj.date();
    const monthKey = Object.values(MONTHS_SHORTCUTS)[dayObj.month()];
    const month = t(monthKey, {
      ns: 'months',
    });
    const year = dayObj.year();

    return `${dayName} - ${date} ${month} ${year}`;
  };

  const mapLabelDtoToLabel = ({ monthKey, date, year }: LabelDto): string => {
    const month = t(monthKey);

    if (selectedDate === DATE_SELECT_OPTION_VALUES.today) {
      const today = t('analytics.today', {
        ns: 'dashboard',
      });

      const result = getSelectOptionRichString(today, dayjs());

      return result;
    }

    if (selectedDate === DATE_SELECT_OPTION_VALUES.yesterday) {
      const yesterday = t('analytics.yesterday', {
        ns: 'dashboard',
      });

      return getSelectOptionRichString(yesterday, dayjs().subtract(1, 'day'));
    }

    if (strategy === AggregationStrategy.Monthly) {
      return `${month} ${year}`;
    }

    return `${month} ${date}`;
  };

  const mapEntitiesToLabelDto = pipe(
    mapEntitiesToDateStrings,
    mapDateStringToLabelDto,
    mapLabelDtoToLabel,
  );

  return map(mapEntitiesToLabelDto, entities);
};
