import type { Components, Theme } from '@mui/material';

export const MuiModal: Components<Theme>['MuiModal'] = {
  styleOverrides: {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
