import { BillingCycle } from '@/features/common/billing';

import { ProductCredits } from '../entities/GroupedProductMetadata';

import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class UnlimitedProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    let bulkCredits: ProductCredits = 0;

    if (this.billingCycle === BillingCycle.Yearly) {
      const product = this.getAnnualProduct();
      bulkCredits = this.getCreditsCount(product, 'bulk');
    } else {
      const product = this.getMonthlyProduct();
      bulkCredits = this.getCreditsCount(product, 'bulk');
    }

    const cycleLabel = this.billingCycle == BillingCycle.Yearly ? 'year' : 'month';

    return {
      title: 'What’s included:',
      items: [
        'Unlimited Chrome extension',
        'CRM integrations',
        `${bulkCredits} bulk credits / ${cycleLabel} (bulk enrichment & bulk save)`,
      ],
    };
  }
  getName(): string {
    return 'Unlimited';
  }
  getDescription(): string {
    return 'Unlock endless opportunities using the Powerlead Chrome Extension';
  }
  getIsCreditCardRequired(): boolean {
    return true;
  }
  getIsMostPopular(): boolean {
    return true;
  }
}
