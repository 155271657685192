import { useState } from 'react';

import { useObservableResult } from '@/utils/rx';

import { AuthStatus } from '../../domain';

import { useAuthUseCase } from './useAuthUseCase';

export const useAuthStatus = (): AuthStatus => {
  const authUseCase = useAuthUseCase();

  const [authStatus$] = useState(() => authUseCase.getAuthStatus());

  const { data: authStatus } = useObservableResult(authStatus$, {
    defaultData: AuthStatus.Initialisation,
  });

  return authStatus;
};
