import { createContext } from 'react';

import type { IAccountEntity } from '@/features/common/account';
import type {
  IBillingDetailsEntity,
  IPaymentMethodEntity,
  IProductEntity,
} from '@/features/common/billing';
import type {
  IWorkspaceEntity,
  IWorkspaceSubscriptionEntity,
} from '@/features/common/workspace';

export type PaymentDetailsContextType = {
  account: IAccountEntity;
  currentSubscription: IWorkspaceSubscriptionEntity;
  currentWorkspace: IWorkspaceEntity;
  paymentMethod: Nullable<IPaymentMethodEntity>;
  billingDetails: Nullable<IBillingDetailsEntity>;
  targetProduct: IProductEntity;
  quantity: number;
};

export const PaymentDetailsContext = createContext<PaymentDetailsContextType>({
  get account(): IAccountEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get currentSubscription(): IWorkspaceSubscriptionEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get currentWorkspace(): IWorkspaceEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get targetProduct(): IProductEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  paymentMethod: null,
  billingDetails: null,
  quantity: 1,
});
