import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { UserRole } from '@/features/common/account';

import { InvitationStatus } from '../../../../domain/entities/InvitationStatusEntity';

const MemberStatus: React.FC<GridRenderCellParams> = ({ value, row: { role } }) => {
  const { t } = useTranslation('settings');

  if (role === UserRole.Owner || !value) return null;

  const statusData = {
    [InvitationStatus.Pending]: {
      title: t('teamManagement.table.statusPendingTitle'),
      color: 'var(--team-members-table-status-pending-color)',
    },
    [InvitationStatus.Accepted]: {
      title: t('teamManagement.table.statusAcceptedTitle'),
      color: 'var(--team-members-table-status-accepted-color)',
    },
  };

  return (
    <div className="MuiDataGrid-cellContent" style={{ color: statusData[value].color }}>
      {statusData[value].title}
    </div>
  );
};

export default MemberStatus;
