import { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import clsx from 'clsx';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { PlanAction } from '../../types';

import styles from './styles.module.scss';

type PlanActionProps = {
  children: ReactNode;
  className?: string;
  color?: ButtonProps['color'];
  onClick?: () => void;
  variant: ButtonProps['variant'];
  href?: string;
  target?: HTMLAttributeAnchorTarget;
};

const PlanActionButton: FC<PlanActionProps> = ({
  children,
  className,
  onClick,
  variant,
  color,
  href,
  target,
}) => {
  return (
    // @ts-ignore
    <Button
      color={color ?? 'secondary'}
      className={clsx(styles.planAction, className)}
      variant={variant}
      onClick={onClick}
      size="large"
      fullWidth
      href={href}
      target={target}
    >
      {children}
    </Button>
  );
};

type ClickableActionButton = FC<{ onClick?: () => void }>;

// PlanAction.UpgradePlan
const UpgradePlanActionButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });
  return (
    <PlanActionButton color="primary" variant="contained" onClick={onClick}>
      {t('upgradePlan')}
    </PlanActionButton>
  );
};

const UpdatePlanActionButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });
  return (
    <PlanActionButton color="primary" variant="contained" onClick={onClick}>
      {t('updatePlan')}
    </PlanActionButton>
  );
};

// PlanAction.CommingSoon
// const CommingSoonActionButton: FC = () => {
//   const { t } = useTranslation('plans', { keyPrefix: 'actions' });
//   return (
//     <PlanActionButton variant="text" className={styles.soon}>
//       {t('commingSoon')}
//     </PlanActionButton>
//   );
// };

// PlanAction.ChangeBillingCycle
const ChangeBillingCycleActionButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });
  return (
    <PlanActionButton variant="contained" onClick={onClick}>
      {t('changeBillingCycle')}
    </PlanActionButton>
  );
};

// PlanAction.TalkToSales
const TalkToSalesActionButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });
  return (
    <PlanActionButton
      variant="contained"
      href={`${EXTERNAL_ROUTES.REQUEST_DEMO}?source=expand`}
      target="_blank"
      onClick={onClick}
    >
      {t('talkToSales')}
    </PlanActionButton>
  );
};

// PlanAction.GetStarted
// const GetStartedActionButton: ClickableActionButton = ({ onClick }) => {
//   const { t } = useTranslation('plans', { keyPrefix: 'actions' });
//   return (
//     <PlanActionButton variant="contained" onClick={onClick}>
//       {t('getStarted')}
//     </PlanActionButton>
//   );
// };

const CurrentPlanBadge: FC = () => {
  return <Box className={styles.currentPlanBadge}>Current Plan</Box>;
};

const PlanActionFabric: FC<{ action: PlanAction; onClick?: () => void }> = ({
  action,
  onClick,
}) => {
  switch (action) {
    case PlanAction.UpgradePlan:
      return <UpgradePlanActionButton onClick={onClick} />;
    case PlanAction.UpdatePlan:
      return <UpdatePlanActionButton onClick={onClick} />;
    case PlanAction.ChangeBillingCycle:
      return <ChangeBillingCycleActionButton onClick={onClick} />;
    case PlanAction.TalkToSales:
      return <TalkToSalesActionButton onClick={onClick} />;
    case PlanAction.CurrentPlan:
      return <CurrentPlanBadge />;
    default:
      return null;
  }
};

type PlanActionsProps = {
  action: { type: PlanAction; onClick?: () => void };
  creditCardRequired?: boolean;
};

export const PlanActions: FC<PlanActionsProps> = ({ action, creditCardRequired }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  const showNoCreditCardRequired =
    action.type !== PlanAction.None &&
    action.type !== PlanAction.CurrentPlan &&
    !creditCardRequired;

  return (
    <div className={styles.planActions}>
      <PlanActionFabric key={action.type} action={action.type} onClick={action.onClick} />
      {showNoCreditCardRequired ? (
        <Typography className={styles.noCreditCardRequired}>
          {t('noCreditCardRequired')}
        </Typography>
      ) : null}
    </div>
  );
};
