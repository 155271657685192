import { injectable } from 'inversify';
import { Userpilot } from 'userpilot';

import { IAccountEntity } from '@/features/common/account';

import { IContextualOnboardingRepository } from '../domain';

@injectable()
export class ContextualOnboardingRepository implements IContextualOnboardingRepository {
  public init(): void {
    Userpilot.initialize(import.meta.env.REACT_APP_USERPILOT_APP_TOKEN);
  }

  public identify(account: IAccountEntity): void {
    Userpilot.identify(account.uuid, {
      name: account.fullName,
      email: account.email,
      created_at: account.createdAt,
      role: account.role,
    });
  }

  public reload(): void {
    Userpilot.reload();
  }

  public track(eventName: string, meta: Record<string, string>): void {
    Userpilot.track(eventName, meta);
  }
}
