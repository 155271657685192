import React from 'react';
import { Tab as MuiTab, Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

export type TabOption = {
  label: string;
  value: string;
};

type TabsProps = Omit<MuiTabsProps, 'children'> & {
  options: TabOption[];
};

export const Tabs: React.FC<TabsProps> = ({ options, ...props }) => {
  return (
    <MuiTabs {...props}>
      {options.map((option) => {
        return <MuiTab key={option.value} value={option.value} label={option.label} />;
      })}
    </MuiTabs>
  );
};
