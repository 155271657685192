import { FC } from 'react';

import { PlanIconColor } from '../../types';

import { BlueCircle, GreenCircle, GreyCircle, PinkCircle } from './assets';

import styles from './styles.module.scss';

const colorIconMap: Record<PlanIconColor, string> = {
  [PlanIconColor.Blue]: BlueCircle,
  [PlanIconColor.Grey]: GreyCircle,
  [PlanIconColor.Pink]: PinkCircle,
  [PlanIconColor.Green]: GreenCircle,
};

export const PlanCardIcon: FC<{ color: PlanIconColor }> = (props) => {
  return (
    <div className={styles.planCardIcon}>
      <img
        src={colorIconMap[props.color]}
        width="32px"
        height="32px"
        alt="plan icon"
        className={styles.planCardIconImage}
      />
    </div>
  );
};
