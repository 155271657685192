import { FC } from 'react';
import { Box } from '@mui/material';

import HubspotIcon from './assets/hubspot.svg?react';
import SalesForceIcon from './assets/salesforce.svg?react';

import styles from './styles.module.scss';

type IntegrationsMenuProps = { links: { link: string; provider: string }[] };

const IconForIntegrationDictionary = {
  hubspot: HubspotIcon,
  salesforce: SalesForceIcon,
};

export const IntegrationsMenu: FC<IntegrationsMenuProps> = ({ links }) => {
  const handleClick = (link: string): void => {
    window.open(link, '_blank');
  };

  return (
    <>
      {links.map(({ link, provider }) => {
        const Component = IconForIntegrationDictionary[provider];
        return (
          <Box
            key={link}
            className={styles.button}
            onClick={(): void => handleClick(link)}
          >
            <Component />
          </Box>
        );
      })}
    </>
  );
};
