import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const XIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g xmlns="http://www.w3.org/2000/svg" data-name="Group 18680">
      <g data-name="Group 113" transform="translate(-37 -251)">
        <rect
          data-name="Rectangle 70"
          width="20"
          height="20"
          rx="4"
          transform="translate(37 251)"
        />
      </g>
      <path
        d="M18.641 3.967 22.053 0h-.809l-2.963 3.444L15.915 0h-2.73l3.579 5.209-3.579 4.16h.809l3.129-3.637 2.5 3.637h2.73l-3.712-5.4zm-1.108 1.287-.363-.519L14.285.609h1.242l2.328 3.331.363.519 3.027 4.329H20l-2.47-3.533z"
        transform="translate(-7.769 5.316)"
        style={{ fill: '#fff' }}
      />
    </g>
  </SvgIcon>
);
