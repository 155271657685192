import { inject, injectable, postConstruct } from 'inversify';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';

import { LEADER_ELECTION_TYPES } from '@/ioc/types';

import type { ILeaderElectionRepository } from '@/features/system/leaderElection';

import { IExtensionMessageEntity, PingExtensionMessage } from '../domain';

import type { IExtensionRepository } from './abstractions';

@injectable()
export class ExtensionRepository implements IExtensionRepository {
  private readonly extensionID = import.meta.env.REACT_APP_EXTENSION_ID;
  private isExtensionInstalled$ = new BehaviorSubject<boolean>(false);

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leader: ILeaderElectionRepository;

  @postConstruct()
  init(): void {
    this.leader
      .getIsLeaderSubject()
      .pipe(
        filter((isLeader) => isLeader),
        first(),
      )
      .subscribe(() => {
        this.sendMessage(new PingExtensionMessage(), (res: unknown) => {
          if (res === 'PONG') {
            this.isExtensionInstalled$.next(true);
          }
        });
      });
  }

  isExtensionInstalled(): Observable<boolean> {
    return this.isExtensionInstalled$.asObservable();
  }

  sendMessage(message: IExtensionMessageEntity, callback?: (res: any) => void): void {
    // @ts-ignore
    void window?.chrome?.runtime?.sendMessage(this.extensionID, message, callback);
  }
}
