import { Container } from 'inversify';

import { ONLINE_TRACKER_TYPES } from '@/ioc/types';

import { IOnlineTrackerRepository, OnlineTrackerRepository } from '../data';
import { IOnlineTrackerUseCase, OnlineTrackerUseCase } from '../domain';

export function bindOnlineTrackerModule(container: Container): void {
  container
    .bind<IOnlineTrackerRepository>(ONLINE_TRACKER_TYPES.OnlineTrackerRepository)
    .to(OnlineTrackerRepository)
    .inSingletonScope();

  container
    .bind<IOnlineTrackerUseCase>(ONLINE_TRACKER_TYPES.OnlineTrackerUseCase)
    .to(OnlineTrackerUseCase);
}
