import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IAggregatedCreditEntity } from '../../domain';

import { useCreditUseCase } from './useCreditUseCase';

export const useBulkAggregatedCreditsInfo = (): ObservableResult<
  Nullable<IAggregatedCreditEntity>
> => {
  const creditUseCase = useCreditUseCase();

  return useObservableResult(() => creditUseCase.getBulkAggregatedCreditsInfo(), {
    deps: [],
  });
};
