/**
 * PlanAction enum
 * @description
 * Enum for plan actions that user can do with plan
 */
export enum PlanAction {
  None = 'None',
  UpgradePlan = 'UpgradePlan',
  UpdatePlan = 'UpdatePlan',
  ChangeBillingCycle = 'ChangeBillingCycle',
  TalkToSales = 'TalkToSales',
  CurrentPlan = 'CurrentPlan',
}
