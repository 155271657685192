import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { ContactsTable, UpdateNotification } from '@/features/common/contact';

import { NoRowsOverlay, Toolbar } from './components';
import { useDashboardTableViewModel } from './useDashboardTableViewModel';

import styles from './styles.module.scss';

export const DashboardTable: FC = () => {
  const { isLoading, columns, rows, queryString, handleSortChange } =
    useDashboardTableViewModel();

  return (
    <Box className={styles.wrapper}>
      <ContactsTable
        checkboxSelection={false}
        className={clsx(styles.table, {
          [styles.emptyTable]: !rows.length,
        })}
        classes={{
          columnHeaders: styles.columnHeadersDashboard,
          row: styles.rowDashboard,
        }}
        loading={isLoading}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            isLoading: isLoading,
          },
        }}
        columns={columns}
        rows={rows}
        onSortModelChange={handleSortChange}
      />
      <UpdateNotification queryString={queryString} />
    </Box>
  );
};
