import { injectable } from 'inversify';

import { DbCollectionCreator } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { ITagDC } from '../dataContracts/TagDC';

import { tagMigrationStrategies } from './migrations/migrations';
import { tagSchema } from './schema/tagSchema';

@injectable()
export class TagCollectionCreator extends DbCollectionCreator<ITagDC> {
  constructor() {
    super({
      collectionName: CollectionName.Tag,
      schema: tagSchema,
      migrationStrategies: tagMigrationStrategies,
    });
  }
}
