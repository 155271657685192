import { useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { map } from 'rxjs';

import { useBillingUseCase } from '@/features/common/billing';

import { formatCurrency } from '@/utils/formatCurrency';
import { useObservableResult } from '@/utils/rx';

import { BillingDataItem } from '../type';

import { useBillingHistoryTableColumns } from './useBillingHistoryTableColumns';

dayjs.extend(localizedFormat);

const previewRows = Array.from({ length: 5 }).map((_, index) => ({
  transactionId: `${index}`,
})) as unknown as BillingDataItem[];

export function useBillingHistoryViewModel() {
  const billingUseCase = useBillingUseCase();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const invoices = useObservableResult(
    () =>
      billingUseCase.getInvoices().pipe(
        map((invoices) => invoices.filter((invoice) => invoice.amount !== 0)),
        map((invoices) => {
          return invoices
            .slice(page * pageSize, (page + 1) * pageSize)
            .map((invoice) => ({
              transactionId: invoice.transactionId,
              url: invoice.url,
              date: dayjs(invoice.date).format('L'),
              type: invoice.description ?? '',
              amount: formatCurrency({
                amount: invoice.amount,
                currency: invoice.currency,
              }),
            }));
        }),
      ),
    {
      cacheKey: 'invoices',
      defaultData: [],
      deps: [page, pageSize],
    },
  );
  const isLoading = !invoices.isLoaded || invoices.hasError;

  const columns = useBillingHistoryTableColumns(isLoading);

  return {
    columns,
    rows: isLoading ? previewRows : invoices.data,
    pagination: {
      page,
      pageSize,
      total: invoices.data?.length || 0,
      changePage: (page: number): void => setPage(page),
    },
  };
}
