// TODO: think to move types per each feature
import { RxCollection, RxDatabase, WithDeleted } from 'rxdb';

import { IAccountDC } from '@/features/common/account';
import { ICompanyDC, IContactByFiltersDC, IContactDC } from '@/features/common/contact';
import { IContactListDC } from '@/features/common/contactList';
import { ITagDC } from '@/features/common/tag';
import { IWorkspaceDC } from '@/features/common/workspace';
import { ChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/ChartDataDC';
import { IEnrichmentDC } from '@/features/enrichment';
import { IInvitationDC } from '@/features/referral';

export type ContactDocument = WithDeleted<IContactDC>;
export type ContactCollection = RxCollection<ContactDocument>;

export type ContactListDocument = WithDeleted<IContactListDC>;
export type ContactListCollection = RxCollection<ContactListDocument>;

export type TagDocument = WithDeleted<ITagDC>;
export type TagCollection = RxCollection<TagDocument>;

export type WorkspaceDocument = WithDeleted<IWorkspaceDC>;
export type WorkspaceCollection = RxCollection<WorkspaceDocument>;

export type AccountDocument = WithDeleted<IAccountDC>;
export type AccountCollection = RxCollection<AccountDocument>;

export type DashboardChartDocument = WithDeleted<ChartDataDC>;
export type DashboardChartCollection = RxCollection<DashboardChartDocument>;

export type CompanyDocument = WithDeleted<ICompanyDC>;
export type CompanyCollection = RxCollection<CompanyDocument>;

export type ContactByFiltersDocument = WithDeleted<IContactByFiltersDC>;
export type ContactByFiltersCollection = RxCollection<ContactByFiltersDocument>;

export type EnrichmentDocument = WithDeleted<IEnrichmentDC>;
export type EnrichmentCollection = RxCollection<EnrichmentDocument>;
export type InvitationDocument = WithDeleted<IInvitationDC>;
export type InvitationCollection = RxCollection<InvitationDocument>;

export enum CollectionName {
  Contact = 'contact',
  ContactList = 'contact-list',
  Tag = 'tag',
  Workspace = 'workspace',
  Account = 'account',
  DashboardChart = 'dashboard-chart',
  Company = 'company',
  ContactByFilters = 'contact-by-filters',
  Enrichment = 'enrichment',
  Invitation = 'invitation',
}

export type DatabaseCollections = {
  [CollectionName.Contact]: ContactCollection;
  [CollectionName.ContactList]: ContactListCollection;
  [CollectionName.Tag]: TagCollection;
  [CollectionName.Workspace]: WorkspaceCollection;
  [CollectionName.Account]: AccountCollection;
  [CollectionName.DashboardChart]: DashboardChartCollection;
  [CollectionName.Company]: CompanyCollection;
  [CollectionName.ContactByFilters]: ContactByFiltersCollection;
  [CollectionName.Enrichment]: EnrichmentCollection;
  [CollectionName.Invitation]: InvitationCollection;
};

export type Database = RxDatabase<DatabaseCollections>;
