import { SubscriptionPlan } from '@/features/common/workspace/domain/types/SubscriptionPlan';

const PLAN_ORDER_RATE = {
  [SubscriptionPlan.FreeMonthly]: 0,
  [SubscriptionPlan.FreePlusMonthly]: 0,
  [SubscriptionPlan.ProV2Daily]: 1,
  [SubscriptionPlan.ProV2Monthly]: 1,
  [SubscriptionPlan.ProV2Annually]: 1,
  [SubscriptionPlan.UnlimitedDaily]: 3,
  [SubscriptionPlan.UnlimitedMonthly]: 3,
  [SubscriptionPlan.UnlimitedAnnually]: 3,
  [SubscriptionPlan.ExpandMock]: 3,
};

export const isSubscriptionPlan = (
  plan: SubscriptionPlan,
): {
  isLowerThen: (otherPlan: SubscriptionPlan) => boolean;
  isHigherThen: (otherPlan: SubscriptionPlan) => boolean;
} => {
  return {
    isLowerThen: (anotherPlan: SubscriptionPlan): boolean => {
      return PLAN_ORDER_RATE[plan] < PLAN_ORDER_RATE[anotherPlan];
    },
    isHigherThen: (anotherPlan: SubscriptionPlan): boolean => {
      return PLAN_ORDER_RATE[plan] > PLAN_ORDER_RATE[anotherPlan];
    },
  };
};
