import { inject, injectable } from 'inversify';

import { COOKIES_TYPES, LEADS_TRACKING_TYPES } from '@/ioc/types';

import { ICookiesManager } from '@/features/system/cookies';

import { ILeadsTrackingRepository } from '../data/LeadsTrackingRepository';

import type { ILeadsTrackingEntity } from './LeadsTrackingEntity';

export interface ILeadsTrackingUseCase {
  track(url: URL): Nullable<ILeadsTrackingEntity>;
  remove(): void;
}

@injectable()
export class LeadsTrackingUseCase implements ILeadsTrackingUseCase {
  @inject(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
  private leadsTrackingRepository: ILeadsTrackingRepository;

  @inject(COOKIES_TYPES.CookiesManager)
  private cookies: ICookiesManager;

  private static COOKIES_KEY = 'pwUTM';

  private getUTMsFromUrl(url: URL): Nullable<ILeadsTrackingEntity> {
    const searchParams = url.searchParams;

    const id = searchParams.get('utm_id');
    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const content = searchParams.get('utm_content');
    const term = searchParams.get('utm_term');

    const utms = { id, source, medium, campaign, content, term };

    if (!Object.values(utms).some((param) => Boolean(param))) return;

    return utms;
  }

  private getUTMsFromCookies(): Nullable<ILeadsTrackingEntity> {
    return this.cookies.get<Nullable<ILeadsTrackingEntity>>(
      LeadsTrackingUseCase.COOKIES_KEY,
    );
  }

  track(url: URL): Nullable<ILeadsTrackingEntity> {
    const utms = this.getUTMsFromUrl(url) ?? this.getUTMsFromCookies();

    if (utms) {
      this.leadsTrackingRepository.save(utms);
    }

    return utms;
  }

  remove(): void {
    this.leadsTrackingRepository.remove();
    this.cookies.remove(LeadsTrackingUseCase.COOKIES_KEY);
  }
}
