import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { HasPermission, Permission } from '@/features/common/permissions';

import { useUploadEnrichmentFile } from '../../../../hooks';

import NoRowsIcon from './assets/noRows.webp';

import styles from './styles.module.scss';

export const NoRowsOverlay: FC = () => {
  const { t } = useTranslation('enrichment');
  const { inputRef, onChange, onClick } = useUploadEnrichmentFile();

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Container}>
        <Box className={styles.Icon}>
          <img src={NoRowsIcon} alt="No rows" />
        </Box>
        <Typography variant="h2" className={styles.Title}>
          {t('table.noRows.title')}
        </Typography>
        <Typography variant="subtitle1" className={styles.SubTitle}>
          <Trans
            t={t}
            i18nKey="table.noRows.subTitle"
            components={{
              br: <br />,
            }}
          />
        </Typography>
        <HasPermission permission={Permission.CanRunCsvEnrichemnt}>
          <input
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv, .xls, .xlsx"
            hidden
            onChange={onChange}
          />
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={styles.Button}
            onClick={onClick}
          >
            {t('table.noRows.button')}
          </Button>
        </HasPermission>
      </Box>
    </Box>
  );
};
