import { FC } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { ProspectTaskNotificationWidgetItem } from './ProspectTaskNotificationWidgetItem';
import { useProspectTaskNotificationWidgetViewModel } from './useProspectTaskNotificationWidgetViewModel';

import styles from './styles.module.scss';

export const ProspectTaskNotificationWidgetBody: FC<{
  innerOpen: boolean;
  onClose(): void;
  onToggleInner(): void;
}> = ({ innerOpen, onClose, onToggleInner }) => {
  const { items, title } = useProspectTaskNotificationWidgetViewModel();
  const itemsLength = items.length;

  return (
    <Box>
      <Box className={styles.header}>
        <Box component="span" className={styles.headerTitle}>
          {title}
        </Box>
        <Box className={styles.headerActions}>
          <Box
            className={clsx(styles.headerButton, {
              [styles.isOpen]: innerOpen,
            })}
            onClick={onToggleInner}
          >
            <ExpandMoreIcon />
          </Box>
          <Box className={styles.headerButton} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
      </Box>
      <CSSTransition in={innerOpen} timeout={300} unmountOnExit>
        <Box className={styles.content}>
          <TransitionGroup className="notificationBarList">
            {items.map((item, index) => {
              const isLastItem = index === itemsLength - 1;
              return (
                <CSSTransition
                  key={item.id}
                  timeout={500}
                  classNames="notificationBarItemFade"
                >
                  <Box>
                    <Box>
                      <ProspectTaskNotificationWidgetItem item={item} />
                    </Box>
                    <Box className={styles.itemDivider} />
                    {!isLastItem && <Box className={styles.itemDivider} />}
                  </Box>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </Box>
      </CSSTransition>
    </Box>
  );
};
