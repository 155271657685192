import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';

import TagTableActionsCell from '../components/TagTableActionsCell';
import TagTableContactsCountCell from '../components/TagTableContactsCountCell';
import TagTableCreatorCell from '../components/TagTableCreatorCell';
import TagTableTagCell from '../components/TagTableTagCell';

const useTagTableColumns = () => {
  const { t } = useTranslation('tag');

  return [
    {
      headerName: t('table.columnTitle.name'),
      field: 'name',
      width: 300,
      disableColumnMenu: true,
      renderCell: TagTableTagCell,
    },
    {
      headerName: t('table.columnTitle.contacts'),
      field: 'contacts',
      width: 250,
      disableColumnMenu: true,
      renderCell: TagTableContactsCountCell,
    },
    {
      headerName: t('table.columnTitle.creator'),
      field: 'createdBy',
      width: 250,
      disableColumnMenu: true,
      renderCell: TagTableCreatorCell,
    },
    {
      field: '',
      flex: 1,
      disableColumnMenu: true,
      renderCell: TagTableActionsCell,
      sortable: false,
    },
  ] as GridColDef[];
};

export default useTagTableColumns;
