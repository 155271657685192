import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';

import UploadProcessIcon from '../../assets/uploadProcessIcon.webp';

import styles from './styles.module.scss';

type StartViewDefaultProps = {
  fileName: string;
};

export const StartViewDefault: FC<StartViewDefaultProps> = ({ fileName }) => {
  const { t } = useTranslation('enrichment');
  const { fileMetadata } = useContext(EnrichmentUploadingContext);

  return (
    <Box className={styles.Content}>
      <Box className={styles.Icon}>
        <img src={UploadProcessIcon} alt="" />
      </Box>
      <Box className={styles.Title} component="span">
        {t('uploadingProgressModal.title')}
      </Box>
      <Box className={styles.SubTitle}>{fileName}</Box>
      <Box className={styles.Progress}>
        <Box
          className={styles.ProgressInner}
          style={{ width: `${fileMetadata?.progress || 0}%` }}
        ></Box>
      </Box>
      <Box className={styles.Info} component="span">
        {t('uploadingProgressModal.info')}
      </Box>
    </Box>
  );
};
