import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';
import { object, string } from 'yup';

import { STORAGE_TYPES } from '@/ioc/types';

import type { IStorageRepository } from '@/features/system/storage';

import type { ILeadsTrackingEntity } from '../domain/LeadsTrackingEntity';

export interface ILeadsTrackingRepository {
  save: (data: ILeadsTrackingEntity) => void;
  get$: () => Observable<Nullable<ILeadsTrackingEntity>>;
  get: () => Nullable<ILeadsTrackingEntity>;
  remove: () => void;
}

@injectable()
export class LeadsTrackingRepository implements ILeadsTrackingRepository {
  private key = 'utm';

  @inject(STORAGE_TYPES.StorageRepository)
  private storage: IStorageRepository;

  save(data: ILeadsTrackingEntity): void {
    this.storage.save({
      [this.key]: data,
    });
  }

  get(): Nullable<ILeadsTrackingEntity> {
    return this.storage.get(this.key, this.isLeadsTrackingEntity);
  }

  get$(): Observable<Nullable<ILeadsTrackingEntity>> {
    return this.storage.get$(this.key, this.isLeadsTrackingEntity);
  }

  remove(): void {
    this.storage.delete(this.key);
  }

  private isLeadsTrackingEntity = (value: unknown): value is ILeadsTrackingEntity => {
    return LeadsTrackingRepository.leadsTrackingSchema.isValidSync(value);
  };

  private static leadsTrackingSchema = object().shape({
    id: string().nullable().optional(),
    source: string().nullable().optional(),
    medium: string().nullable().optional(),
    campaign: string().nullable().optional(),
    content: string().nullable().optional(),
    term: string().nullable().optional(),
  });
}
