import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { catchError, map, throwError } from 'rxjs';

import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import type { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { IReceiptAdjustmentEntity } from '../../../domain';
import { PaymentDetailsContext } from '../../contexts';
import type { IRecieptUI } from '../../types';

import { useFormatCurrency } from './useFormatCurrency';

type UseReceipt = (params: {
  promotionCode?: IReceiptAdjustmentEntity;
}) => ObservableResult<IRecieptUI, unknown, IRecieptUI>;

function mapPromocodeName(
  adjustment: IReceiptAdjustmentEntity,
  formatCurrency: (amount: number) => string,
): string {
  if (adjustment.isApplyable) {
    return `${adjustment.title} Saving ${formatCurrency(adjustment.amount)}`;
  }

  return `${adjustment.title} (included in prorated discount)`;
}

function formatDueDate(date: Date): string {
  return date.toDateString() === new Date().toDateString()
    ? 'today'
    : Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
}

export const useReceipt: UseReceipt = ({ promotionCode }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const { targetProduct, quantity } = useContext(PaymentDetailsContext);
  const formatCurrency = useFormatCurrency();

  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const pricePerUnitTranslationKey =
    targetProduct.cycle === 'monthly' ? 'baseAmountMonth' : 'baseAmountYear';

  return useObservableResult(
    () =>
      paymentDetailsUseCase
        .getReceipt({
          product: targetProduct,
          promotionCode,
          quantity,
        })
        .pipe(
          map(({ product, total, adjustments, promotion, dueDate }) => {
            const name = `${product.name} ${product.cycle} Plan`;
            return {
              product: {
                name: name.split(' ').map(capitalizeFirstLetter).join(' '),
                priceFormatted: formatCurrency(product.price),
                details: t(pricePerUnitTranslationKey, {
                  totalPrice: formatCurrency(product.price),
                  count: product.seats,
                  credits: product.credits,
                }),
              },
              adjustments: adjustments
                .filter((adj) => adj.amount > 0)
                .map<IRecieptUI['adjustments'][number]>((adjustment) => ({
                  name: adjustment.title,
                  amount: `${adjustment.type === 'discount' ? '-' : '+'} ${formatCurrency(adjustment.amount)}`,
                  type: adjustment.type,
                })),
              promotion: promotion && {
                name: mapPromocodeName(promotion, formatCurrency),
              },
              dueDate: formatDueDate(dueDate),
              total: total,
              totalFormatted: formatCurrency(total),
            } as IRecieptUI;
          }),
          catchError((error) => {
            console.error('error', error);
            return throwError(() => error);
          }),
        ),
    {
      deps: [targetProduct, promotionCode, quantity],
      defaultData: {
        product: undefined,
        adjustments: [] as IRecieptUI['adjustments'],
        promotion: undefined,
        total: 0,
        dueDate: 'today',
        totalFormatted: '$0.00',
      },
    },
  );
};
