import * as React from "react";
const SvgEmail = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 107.723, height: 100.398, viewBox: "0 0 107.723 100.398", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "n4fvyiu0ea", x1: 0.17, y1: 0.85, x2: 0.871, y2: 0.098, gradientUnits: "objectBoundingBox" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, stopColor: "#ffece4" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#e7f0ff" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "email (1)" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18805" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32813", d: "M104.393 37.84C64.152-3.744 48.465 101.433 27.89 67.607c-3.282-5.4 6.463-21.4 12.891-28.322C75.241 2.175-12.174 10.179 1.456 79.48c3.816 19.4 23.119 23.657 41.487 20.983 11.91-1.734 18.42 15.7 30.618 17.21 10.9 1.353 16.794-7.762 14.155-17.794-5.566-21.156 30.937-47.3 16.676-62.038z", transform: "translate(0 -17.408)", style: {
  fill: "url(#n4fvyiu0ea)"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18816" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18810" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32814", d: "M131.433 135.971a24.284 24.284 0 0 0-7.081-16.592 24.549 24.549 0 0 0-7.2-5.048V95.023a5.08 5.08 0 0 0-5.081-5.083H70.234c.03-.395.05-.792.05-1.194A15.4 15.4 0 1 0 48.1 102.565v29.155a5.079 5.079 0 0 0 5.081 5.081h29.446c0 .176 0 .352.01.529a24.491 24.491 0 0 0 24.163 23.61h.236a24.276 24.276 0 0 0 13.611-4.145 2.747 2.747 0 0 0-3.068-4.558 18.949 18.949 0 0 1-29.448-15.083c0-.118-.005-.235-.007-.353h7a11.9 11.9 0 0 0 20.253 8.22 8.972 8.972 0 0 0 .708.788 8.854 8.854 0 0 0 14.887-4.515 24.6 24.6 0 0 0 .459-5.323zm-24.4 6.981a6.426 6.426 0 0 1-6.412-6.151h11.474a5.107 5.107 0 0 0 1.355-.191 6.425 6.425 0 0 1-6.416 6.341zm18.553-2.724a3.36 3.36 0 0 1-6.641-.722v-11.563a2.745 2.745 0 0 0-1.794-2.574v-4.789a19.214 19.214 0 0 1 3.3 2.664 18.811 18.811 0 0 1 5.492 12.85 19.119 19.119 0 0 1-.356 4.133z", transform: "translate(-31.184 -61.581)", style: {
  fill: "#0e4fbc",
  opacity: 0.1
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18806" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32815", d: "M149.45 133.283v36.7a5.076 5.076 0 0 1-5.056 5.081H85.481a5.079 5.079 0 0 1-5.081-5.081v-36.7a5.082 5.082 0 0 1 5.081-5.083h58.888a5.08 5.08 0 0 1 5.081 5.083z", transform: "translate(-63.485 -104.89)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32816", d: "m80.4 246.922 32.7-20.726a3.556 3.556 0 0 1 3.814 0l32.54 20.722a5.082 5.082 0 0 1-5.088 5.082H85.477a5.079 5.079 0 0 1-5.077-5.078z", transform: "translate(-63.482 -181.832)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32817", d: "m80.4 246.922 32.7-20.726a3.556 3.556 0 0 1 3.814 0l32.54 20.722a5.082 5.082 0 0 1-5.088 5.082H85.477a5.079 5.079 0 0 1-5.077-5.078z", transform: "translate(-63.482 -181.832)", style: {
  fill: "#2173ff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32818", d: "m149.443 133.285-32.693 20.726a3.556 3.556 0 0 1-3.814 0l-32.543-20.726a5.083 5.083 0 0 1 5.081-5.081h58.89a5.079 5.079 0 0 1 5.079 5.081z", transform: "translate(-63.479 -104.893)", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32819", d: "M293.3 257.494a24.375 24.375 0 1 0-24.637 24.969h.236a24.276 24.276 0 0 0 13.611-4.145 2.747 2.747 0 0 0-3.068-4.558 18.869 18.869 0 1 1 8.365-16.142 19.1 19.1 0 0 1-.355 4.133 3.36 3.36 0 0 1-6.641-.722V249.467a2.746 2.746 0 0 0-5.195-1.242 11.913 11.913 0 1 0 1.63 18.319 8.981 8.981 0 0 0 .708.788 8.854 8.854 0 0 0 14.887-4.515 24.64 24.64 0 0 0 .459-5.323zm-24.4 6.981a6.419 6.419 0 1 1 6.418-6.435v.034a6.425 6.425 0 0 1-6.418 6.401z", transform: "translate(-193.053 -188.153)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18809", transform: "translate(7.257 5.668)" }, /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1559", cx: 15.396, cy: 15.396, r: 15.396, transform: "translate(1.052 1.052)", style: {
  fill: "#2173ff"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18807" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32820", d: "M128.536 108a25.459 25.459 0 0 0-50.9-.2H49.23a4.033 4.033 0 0 1-4.027-4.029v-27.56a16.446 16.446 0 0 0 11.39.033l19.533 12.438a4.625 4.625 0 0 0 4.943.006l31.079-19.7v11.1a1.052 1.052 0 1 0 2.1 0V67.074a6.14 6.14 0 0 0-6.133-6.133H72.556a1.052 1.052 0 0 0 0 2.1h35.564a4.032 4.032 0 0 1 3.986 3.476L79.943 86.911a2.513 2.513 0 0 1-2.687 0L58.872 75.2a16.446 16.446 0 1 0-15.773.052v28.519a6.139 6.139 0 0 0 6.131 6.133h28.428a25.545 25.545 0 0 0 25.18 24.138h.246a25.337 25.337 0 0 0 14.2-4.325 3.8 3.8 0 0 0-4.242-6.3 17.9 17.9 0 0 1-27.77-13.517h4.913a12.957 12.957 0 0 0 21.166 8.657l.048.048a9.906 9.906 0 0 0 16.658-5.06 25.632 25.632 0 0 0 .479-5.551zM36.6 60.8a14.344 14.344 0 1 1 14.34 14.342A14.36 14.36 0 0 1 36.6 60.8zm79.451 47.758V100a3.8 3.8 0 0 0-1.794-3.221v-2.092a18.284 18.284 0 0 1 1.5 1.35 17.763 17.763 0 0 1 5.188 12.134 18.047 18.047 0 0 1-.336 3.906 2.275 2.275 0 0 1-2.217 1.788 2.316 2.316 0 0 1-1.652-.664 2.291 2.291 0 0 1-.687-1.643v-2.972c-.006-.008-.006-.019-.006-.03zm-3.9-12.354a3.774 3.774 0 0 0-2.619 1.14A12.957 12.957 0 0 0 90.144 107.8h-4.9a17.822 17.822 0 0 1 26.905-14.58zm13.845 16.94a7.8 7.8 0 0 1-13.117 3.97 8.023 8.023 0 0 1-.625-.7 1.052 1.052 0 0 0-1.559-.093 10.86 10.86 0 1 1-1.487-16.7 1.052 1.052 0 0 0 1.532-.391 1.694 1.694 0 0 1 3.2.765v11.562a4.412 4.412 0 0 0 8.725.927 20.171 20.171 0 0 0 .375-4.361 19.961 19.961 0 1 0-8.829 17.039 1.7 1.7 0 1 1 1.893 2.812 23.242 23.242 0 0 1-13.024 3.966h-.226a23.356 23.356 0 0 1 .208-46.71q.389 0 .78.013a23.436 23.436 0 0 1 22.586 22.8 23.553 23.553 0 0 1-.439 5.1z", transform: "translate(-34.492 -44.35)", style: {
  fill: "#2173ff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32821", d: "M332.463 314.148a7.471 7.471 0 1 0 7.47 7.488v-.036a7.483 7.483 0 0 0-7.47-7.452zm0 2.1a5.378 5.378 0 0 1 5.307 4.574q-.134.009-.27.009h-10.346a5.375 5.375 0 0 1 5.309-4.579zm0 10.734a5.376 5.376 0 0 1-5.2-4.047H337.5c.055 0 .11 0 .165-.006a5.378 5.378 0 0 1-5.202 4.057z", transform: "translate(-263.872 -257.383)", style: {
  fill: "#2173ff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18808" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32822", d: "M81.449 79.453a11.323 11.323 0 1 0-11.526 11.863h.2a11.282 11.282 0 0 0 6.218-1.856 1.052 1.052 0 1 0-1.154-1.76 9.2 9.2 0 0 1-5.228 1.509 9.308 9.308 0 0 1-9.065-9.055 9.233 9.233 0 0 1 18.454-.609c0 3.388-.89 4.053-1.877 4.053a1.624 1.624 0 0 1-1.622-1.622v-6.67a1.052 1.052 0 1 0-2.1 0v.242a5.724 5.724 0 1 0 .542 8.354 3.722 3.722 0 0 0 6.784-.964 11.4 11.4 0 0 0 .374-3.485zM70.125 83.6a3.62 3.62 0 1 1 3.62-3.62 3.624 3.624 0 0 1-3.62 3.62z", transform: "translate(-53.676 -63.533)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18811" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32823", d: "M229.622 86.425a1.052 1.052 0 0 1-1.052-1.052 1.334 1.334 0 0 0-1.332-1.332 1.052 1.052 0 1 1 0-2.1 1.334 1.334 0 0 0 1.332-1.341 1.052 1.052 0 1 1 2.1 0 1.334 1.334 0 0 0 1.332 1.332 1.052 1.052 0 1 1 0 2.1 1.334 1.334 0 0 0-1.332 1.332 1.052 1.052 0 0 1-1.048 1.061z", transform: "translate(-178.597 -66.478)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18812" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32824", d: "M208.738 435.876a1.052 1.052 0 0 1-1.052-1.052 1.334 1.334 0 0 0-1.332-1.332 1.052 1.052 0 0 1 0-2.1 1.334 1.334 0 0 0 1.332-1.332 1.052 1.052 0 0 1 2.1 0 1.334 1.334 0 0 0 1.332 1.332 1.052 1.052 0 1 1 0 2.1 1.334 1.334 0 0 0-1.332 1.332 1.052 1.052 0 0 1-1.048 1.052z", transform: "translate(-162.107 -342.405)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18813" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32825", d: "M437.5 206.24a1.052 1.052 0 0 1-1.052-1.052 1.334 1.334 0 0 0-1.332-1.332 1.052 1.052 0 0 1 0-2.1 1.334 1.334 0 0 0 1.332-1.332 1.052 1.052 0 1 1 2.1 0 1.334 1.334 0 0 0 1.332 1.332 1.052 1.052 0 1 1 0 2.1 1.334 1.334 0 0 0-1.332 1.332 1.052 1.052 0 0 1-1.048 1.052z", transform: "translate(-342.736 -161.084)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18814" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32826", d: "M188.958 42.786a1.052 1.052 0 0 1-1.052-1.052 1.334 1.334 0 0 0-1.332-1.332 1.052 1.052 0 1 1 0-2.1 1.334 1.334 0 0 0 1.332-1.332 1.052 1.052 0 1 1 2.1 0 1.334 1.334 0 0 0 1.332 1.332 1.052 1.052 0 1 1 0 2.1 1.334 1.334 0 0 0-1.332 1.332 1.052 1.052 0 0 1-1.048 1.052z", transform: "translate(-146.489 -32.02)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18815" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32827", d: "M192.906 401.373a1.052 1.052 0 0 1-.744-1.8l2.1-2.1a1.052 1.052 0 0 1 1.488 1.488l-2.1 2.1a1.049 1.049 0 0 1-.744.312z", transform: "translate(-151.489 -317.266)", style: {
  fill: "#e7f0ff"
} })))));
export default SvgEmail;
