import { FC, useEffect } from 'react';

import { useServerTimeUseCase } from '../../hook';

export const ServerTimeSync: FC = () => {
  const serverTimeUseCase = useServerTimeUseCase();

  useEffect(() => {
    serverTimeUseCase.sync();
  }, []);

  return null;
};
