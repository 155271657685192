import { FC, ReactNode } from 'react';
import { Tooltip, Typography } from '@mui/material';

import { CheckIcon, HelpIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type CheckedListProps = {
  items: { text: ReactNode; hint?: string }[];
};

export const CheckedList: FC<CheckedListProps> = ({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => (
        <li key={index}>
          <CheckIcon
            classes={{
              root: styles.iconCheck,
            }}
          />
          <Typography className={styles.item}>{item.text}</Typography>
          {item.hint ? (
            <Tooltip title={item.hint} placement="bottom">
              <div>
                <HelpIcon className={styles.iconInfo} />
              </div>
            </Tooltip>
          ) : null}
        </li>
      ))}
    </ul>
  );
};
