import { useInjection } from '@/ioc/ioc.react';
import { ACCOUNT_SETTINGS_TYPES } from '@/ioc/types';

import { IAccountSettingsUseCase } from '../../domain/abstractions/useCases/IAccountSettingsUseCase';

export function useAccountSettingsUseCase(): IAccountSettingsUseCase {
  return useInjection<IAccountSettingsUseCase>(
    ACCOUNT_SETTINGS_TYPES.AccountSettingsUseCase,
  );
}
