import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Popover, Typography } from '@mui/material';
import clsx from 'clsx';

import { Checkbox } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import { useFilterByContactInfoViewModel } from './useFilterByContactInfoViewModel';

import styles from './styles.module.scss';

export const FilterByContactInfo: FC = () => {
  const {
    options,
    value,
    anchorElement,
    valuesIsMatched,
    appliedValuesCount,
    handleApply,
    handleCloseAnimationEnd,
    handleAnchorClick,
    handleOptionClick,
    close,
  } = useFilterByContactInfoViewModel();
  const isOpen = Boolean(anchorElement);
  const { t } = useTranslation('contacts');

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Anchor} onClick={handleAnchorClick}>
        {Boolean(appliedValuesCount) && (
          <Box component={'span'} className={styles.AnchorCount}>
            {appliedValuesCount}
          </Box>
        )}
        <Box component="span" className={styles.AnchorText}>
          {t('subhead.contactInfoFilter.selectField.value')}
        </Box>
        <Box
          className={clsx(styles.AnchorIcon, {
            [styles.opened]: isOpen,
          })}
        >
          <ArrowDownIcon />
        </Box>
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorElement}
        classes={{
          paper: styles.Paper,
        }}
        TransitionProps={{
          onExited: handleCloseAnimationEnd,
        }}
        onClose={close}
      >
        <Box className={styles.MenuHeader}>
          <Typography className={styles.MenuHeaderText}>
            {t('subhead.contactInfoFilter.title')}
          </Typography>
        </Box>
        <Box className={styles.Menu}>
          {options.map((option) => (
            <Box
              className={styles.Option}
              key={option.value}
              onClick={(): void => {
                handleOptionClick(option.value);
              }}
            >
              <Typography variant="subtitle1">{option.label}</Typography>
              <Checkbox
                checked={value.includes(option.value)}
                value={option.value}
                id={option.value}
              />
            </Box>
          ))}
        </Box>
        <Box className={styles.Actions}>
          <Button
            disabled={valuesIsMatched}
            onClick={handleApply}
            variant="text"
            className={styles.ApplyButton}
          >
            {t('subhead.contactInfoFilter.apply')}
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
