import { FC, useContext } from 'react';

import {
  EnrichmentUploadingContext,
  ModalViewType,
} from '../../EnrichmentUploadingContext';

import { ConfigView, StartView } from './components';

const ModalViewByType: Record<ModalViewType, FC> = {
  StartViewDefault: StartView,
  StartViewConnectionError: StartView,
  StartViewContentError: StartView,
  StartViewSizeError: StartView,
  StartViewFormatError: StartView,
  StartViewDefaultError: StartView,
  StartViewCreditsLimitError: StartView,
  ConfigFieldsMapperView: ConfigView,
  ConfigSettingsView: ConfigView,
  ConfigResultView: ConfigView,
};

export const useEnrichmentUploadingModalViewModel = () => {
  const { currentView, uploadinModal } = useContext(EnrichmentUploadingContext);
  const CurrentViewComponent = ModalViewByType[currentView];

  return {
    uploadinModal,
    CurrentViewComponent,
  };
};
