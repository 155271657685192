import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { GridSortItem } from '@mui/x-data-grid-pro';

import { IContactEntity } from '@/features/common/contact';
import { IContactListEntity } from '@/features/common/contactList';

import { ContactsContext } from '../../context';

import {
  DefaultListNoResultsOverlay,
  NoFilterResultsOverlay,
  NoResultsOverlay,
} from './components';
import { useTableColumns } from './hooks';

enum NoResultOverlayKeys {
  NoFilterResultsOverlay,
  DefaultListNoResultsOverlay,
  NoResultsOverlay,
}

type UseContactsTableViewModelResult = {
  page: number;
  columns: any[];
  rows: any[];
  pageSize: number;
  pagesCount: number;
  noResultsOverlay: FC;
  contactEntitiesCount: number;
  isLoading: boolean;
  handlePaginationChange: (e: any, newPage: number) => void;
  handleRowSelectionModelChange: (selected: any) => void;
  handleSortModelChange: (sortData: any) => void;
};

type ContactTableRow = IContactEntity & { id: string };

const NoResultOverlaysMapByKey = {
  [NoResultOverlayKeys.NoFilterResultsOverlay]: NoFilterResultsOverlay,
  [NoResultOverlayKeys.DefaultListNoResultsOverlay]: DefaultListNoResultsOverlay,
  [NoResultOverlayKeys.NoResultsOverlay]: NoResultsOverlay,
};
const CONTACTS_PER_PAGE = 50;
const previewRows = Array.from({ length: 10 }).map((_, index) => ({
  id: `${index}`,
})) as ContactTableRow[];
const DefaultSortItem: GridSortItem = { field: 'created_at', sort: 'asc' };

const mapToSortItem = (sortItem?: GridSortItem): GridSortItem => {
  const sortFieldsMap = {
    name: 'name',
    jobTitle: 'job_title',
    company: 'company_name',
    industry: 'company_industry',
    date: 'created_at',
    contactOwner: 'assigned_to',
  };

  if (!sortItem || !sortFieldsMap[sortItem.field]) {
    return DefaultSortItem;
  }

  return {
    field: sortFieldsMap[sortItem.field],
    sort: sortItem.sort,
  };
};

export const useContactsTableViewModel = ({
  currentContactList,
  defaultContactList,
}: {
  currentContactList: IContactListEntity;
  defaultContactList: IContactListEntity;
}): UseContactsTableViewModelResult => {
  const {
    setSort,
    setSelectedContacts,
    page,
    setPage,
    contactEntitiesCount,
    contactEntities,
    contactEntitiesLoading,
    filtersIsApplied,
  } = useContext(ContactsContext);

  const getNoResultsOverlayKey = (): NoResultOverlayKeys => {
    const isDefaultContactList = currentContactList.uuid === defaultContactList.uuid;

    if (isDefaultContactList && !filtersIsApplied) {
      return NoResultOverlayKeys.DefaultListNoResultsOverlay;
    }

    if (filtersIsApplied) {
      return NoResultOverlayKeys.NoFilterResultsOverlay;
    }

    return NoResultOverlayKeys.NoResultsOverlay;
  };

  const [currentNoResultOverlayKey, setNoResultsOverlayKey] =
    useState<NoResultOverlayKeys>(() => getNoResultsOverlayKey());

  const rows = contactEntitiesLoading
    ? previewRows
    : (contactEntities || [])?.map((item) => ({ id: item.uuid, ...item }));

  const columns = useTableColumns(contactEntitiesLoading);

  const handleSortModelChange = useCallback((sortData): void => {
    const [sortItem] = sortData;
    setSort(mapToSortItem(sortItem));
  }, []);

  const handleRowSelectionModelChange = useCallback((selected): void => {
    setSelectedContacts(selected);
  }, []);

  const handlePaginationChange = useCallback((e, newPage): void => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    setNoResultsOverlayKey(getNoResultsOverlayKey());
  }, [contactEntitiesLoading]);

  return {
    page,
    columns,
    rows,
    pageSize: CONTACTS_PER_PAGE,
    pagesCount: Math.max(1, Math.ceil(contactEntitiesCount / CONTACTS_PER_PAGE)),
    noResultsOverlay: NoResultOverlaysMapByKey[currentNoResultOverlayKey],
    contactEntitiesCount,
    isLoading: contactEntitiesLoading,
    handlePaginationChange,
    handleRowSelectionModelChange,
    handleSortModelChange,
  };
};
