import { useEffect, useRef } from 'react';

type KeyListener = (e: KeyboardEvent) => void;

export function useKeyDownEvent(key: KeyboardEvent['key'], cb: KeyListener): void {
  const cbRef = useRef<KeyListener>(cb);
  cbRef.current = cb;

  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (e.key === key) {
        cbRef.current(e);
      }
    };

    document.addEventListener('keydown', handle);
    return () => document.removeEventListener('keydown', handle);
  }, [key]);
}
