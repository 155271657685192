import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { Modal, ModalProps } from '@/components';

import styles from './styles.module.scss';

export type InformationModalData = {
  imageUrl: string;
  imageClassName?: string;
  description: string;
  buttonText: string;
  handleAccept: () => void;
};

export type InformationModalProps = Omit<ModalProps, 'children'> & InformationModalData;

export const InformationModal: FC<InformationModalProps> = ({
  open,
  onClose,
  imageUrl,
  imageClassName,
  description,
  buttonText,
  handleAccept,
}) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.wrapper}>
      <Stack>
        <img className={imageClassName} src={imageUrl} alt="" />
        <Typography color="common" variant="h2">
          {description}
        </Typography>
        <Button
          className={styles.acceptButton}
          variant="contained"
          color="secondary"
          size="large"
          onClick={(): void => {
            handleAccept();
            onClose?.({}, 'escapeKeyDown');
          }}
        >
          {buttonText}
        </Button>
      </Stack>
    </Modal>
  );
};
