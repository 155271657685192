import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { COMPANY_TYPES } from '@/ioc/types';

import { ICompanyDao, ICompanyDC } from '../data';
import { ICompanyEntity, ICompanyRepository } from '../domain';

import { mapCompanyDcToEntity } from './mappers/mapper';

@injectable()
export class CompanyRepository implements ICompanyRepository {
  @inject(COMPANY_TYPES.CompanyDao)
  private companyDao: ICompanyDao;

  public getById(id: string): Observable<ICompanyEntity> {
    return this.companyDao
      .findById(id)
      .pipe(map((payload: ICompanyDC) => payload && mapCompanyDcToEntity(payload)));
  }
}
