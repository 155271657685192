import { BaseSyntheticEvent, ReactNode, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useInjection } from '@/ioc/ioc.react';
import { LOGIN_TYPES } from '@/ioc/types';

import {
  AuthError,
  EmailSendRateLimitError,
  UserNotFoundError,
} from '@/features/common/auth';
import { ISignInUseCase } from '@/features/signin/domain';
import { useLockTimer } from '@/features/signup';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { sentryCaptureException } from '@/features/system/sentry';

import { useDocumentMeta } from '@/hooks';

import { useFormWithSchema, WorkEmailValidationSchema } from '@/utils/validation';

export const enum ErrorTypes {
  EMAIL,
  TOO_MANY_ATTEMPTS,
}

const ForgotPasswordFormSchema = yup.object({
  email: WorkEmailValidationSchema,
});

type ForgotPasswordFormType = yup.InferType<typeof ForgotPasswordFormSchema>;

interface IForgotPassword {
  form: UseFormReturn<ForgotPasswordFormType>;
  linkSent: boolean;
  error?: { type: ErrorTypes; text: string | ReactNode };
  lockedUntil: Nullable<number>;
  onSubmit: (e: BaseSyntheticEvent) => void;
  onComplete(): void;
}

const LOCK_DURATION_MINUTES = 15;
const LOCK_KEY = 'pw-endLockPassRetry';

export const useForgotPasswordViewModel: () => IForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const signInUseCase = useInjection<ISignInUseCase>(LOGIN_TYPES.SignInUseCase);
  const { t } = useTranslation(['auth', 'common']);
  const [error, setError] = useState<{ type: ErrorTypes; text: ReactNode } | undefined>();
  const { trackEvent } = useAnalytics();
  const resendLockTimer = useLockTimer(LOCK_KEY, LOCK_DURATION_MINUTES);
  const { enqueueSnackbar } = useSnackbar();
  const [counterTimes, setCounterTimes] = useState<number>(0);

  const form = useFormWithSchema(ForgotPasswordFormSchema, {
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  useDocumentMeta({
    title: 'forgotPassword.title',
    description: 'forgotPassword.description',
  });

  useEffect(() => {
    if (resendLockTimer.isLocked) {
      setError({
        type: ErrorTypes.TOO_MANY_ATTEMPTS,
        text: t('forgotPassword.error.tooManyAttemps'),
      });
    } else setError(undefined);
  }, [resendLockTimer.isLocked]);

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.FORGOT_PASSWORD_CLICK_BUTTON);

    const email = searchParams.get('email');

    if (email) {
      try {
        const emailFromParams = Base64.decode(email);
        form.setValue('email', emailFromParams, {
          shouldDirty: true,
        });
      } catch (err) {
        sentryCaptureException('Invalid email in search params');
      }
    }
  }, []);

  const handleErrorOfSendEmail = (error: Error): void => {
    if (error instanceof EmailSendRateLimitError) {
      setError({
        type: ErrorTypes.EMAIL,
        text: t('forgotPassword.error.tooManyAttemps'),
      });
      resendLockTimer.lock();
      return;
    }

    if (error instanceof UserNotFoundError) {
      setError({
        type: ErrorTypes.EMAIL,
        text: t('forgotPassword.error.cantFind'),
      });
    }

    if (!(error instanceof AuthError)) {
      const options: OptionsWithExtraProps<'error'> = {
        variant: 'error',
      };

      if ('message' in error) {
        options.description = error.message;
      }

      enqueueSnackbar(
        t('errors.errorOccurred', {
          ns: 'common',
        }),
        options,
      );
    }
  };

  useEffect(() => {
    if (counterTimes > 3) {
      handleErrorOfSendEmail(new EmailSendRateLimitError());
    }
  }, [counterTimes]);

  const handleSubmit = (e: BaseSyntheticEvent): void => {
    setCounterTimes((counter) => counter + 1);
    if (resendLockTimer.isLocked) {
      return;
    }
    trackEvent(ANALYTICS_EVENTS.FORGOT_PASSWORD_INSERT_EMAIL);
    void form.handleSubmit(async ({ email }: ForgotPasswordFormType) => {
      try {
        await signInUseCase.sendVerificationLinkForRestorePassword(email);
        setLinkSent(true);
        trackEvent(ANALYTICS_EVENTS.RESET_PASSWORD_EMAIL_SENT);
        enqueueSnackbar(t('forgotPassword.successToast'), {
          variant: 'success',
        });
      } catch (error) {
        handleErrorOfSendEmail(error);
      }
    })(e);
  };

  return {
    onSubmit: handleSubmit,
    linkSent,
    form,
    error,
    lockedUntil: resendLockTimer.lockedUntil,
    onComplete: resendLockTimer.onTimerComplete,
  };
};
