import { useState } from 'react';

import { useContactUseCase } from '@/features/common/contact/ui/hooks';
import {
  useContactListSelectOptions,
  useCreateContactList,
} from '@/features/common/contactList';

type UseListCellViewModelResult = {
  options: { value: string; label: string }[];
  search: string;
  searchIsMatched: boolean;
  addToList: (newContactListId: string) => Promise<void>;
  createNewList: (name: string) => Promise<void>;
  changeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useListCellViewModel = ({
  value,
  contactId,
}: {
  value: string;
  contactId: string;
}): UseListCellViewModelResult => {
  const [search, setSearch] = useState('');
  const options = useContactListSelectOptions();
  const contactUseCase = useContactUseCase();
  const createContactList = useCreateContactList();

  const addToList = async (newContactListId: string): Promise<void> => {
    if (newContactListId === value) {
      return;
    }

    await contactUseCase.updateById(contactId, {
      contact_list_id: newContactListId,
    });
  };

  const createNewList = async (name: string): Promise<void> => {
    const contactList = await createContactList(name);
    await addToList(contactList.uuid);
  };

  const searchIsMatched = options.some(
    (option) => option.label.toLowerCase() === search.toLowerCase(),
  );

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  return {
    options,
    search,
    addToList,
    createNewList,
    searchIsMatched,
    changeSearch,
  };
};
