import { FC } from 'react';
import { Typography } from '@mui/material';
import { clsx } from 'clsx';

import { NotLegacyPlanType, PlanType } from '@/features/common/billing';

import { PlanActions } from '../PlanActions';

import { usePlanCardViewModel } from './PlanCardViewModel';

import styles from './styles.module.scss';

type PlanCardProps = {
  variant: NotLegacyPlanType;
};

export const PlanCard: FC<PlanCardProps> = ({ variant }) => {
  const { name, price, action, onAction } = usePlanCardViewModel({ variant });

  return (
    <div
      className={clsx(styles.planCard, {
        [styles.smaller]: variant === PlanType.Expand,
      })}
    >
      <Typography variant="h3" className={styles.planCardTitle}>
        {name}
      </Typography>
      <Typography
        className={clsx(styles.planCardPrice, {
          [styles.smaller]: variant === PlanType.Expand,
        })}
      >
        {price.value} {price.clarification ? <span>{price.clarification}</span> : null}
      </Typography>
      {price.underpriceText && (
        <Typography fontSize="13px">{price.underpriceText}</Typography>
      )}
      <PlanActions actions={[{ type: action, onClick: onAction }]} />
    </div>
  );
};
