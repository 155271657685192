import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GroupLargeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6 9 24 17">
    <path
      data-name="Path 23"
      d="M14.854 12.855a3.928 3.928 0 1 0-3.928-3.928 3.928 3.928 0 0 0 3.928 3.928zm0-6.265a2.338 2.338 0 1 1-2.338 2.338 2.338 2.338 0 0 1 2.338-2.338z"
      transform="translate(3.039 4)"
    />
    <path
      data-name="Path 24"
      d="M5.754 16.227a3.021 3.021 0 1 0-3.021-3.021 3.021 3.021 0 0 0 3.021 3.021zm0-4.452a1.431 1.431 0 1 1-1.431 1.431 1.431 1.431 0 0 1 1.431-1.431z"
      transform="translate(4.649 2.76)"
    />
    <path
      data-name="Path 25"
      d="M24.421 16.227a3.021 3.021 0 1 0-3.021-3.021 3.021 3.021 0 0 0 3.021 3.021zm0-4.452a1.431 1.431 0 1 1-1.431 1.431 1.431 1.431 0 0 1 1.431-1.431z"
      transform="translate(.963 2.76)"
    />
    <path
      data-name="Path 26"
      d="M20.458 19.138a4.233 4.233 0 0 0-2 .519 6.6 6.6 0 0 0-5.46-3.05 6.6 6.6 0 0 0-5.46 3.05 4.233 4.233 0 0 0-2-.519A4.752 4.752 0 0 0 1 24.074a.8.8 0 0 0 1.6 0 3.163 3.163 0 0 1 2.933-3.346 2.629 2.629 0 0 1 1.261.333 7.976 7.976 0 0 0-.569 2.967.8.8 0 0 0 1.6 0c0-3.216 2.319-5.831 5.172-5.831s5.172 2.616 5.172 5.831a.8.8 0 0 0 1.6 0 7.976 7.976 0 0 0-.569-2.967 2.629 2.629 0 0 1 1.261-.333 3.163 3.163 0 0 1 2.933 3.346.8.8 0 0 0 1.6 0 4.752 4.752 0 0 0-4.536-4.936z"
      transform="translate(5.005 1.129)"
    />
  </SvgIcon>
);
