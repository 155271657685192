export enum SubscriptionPlan {
  FreeMonthly = 'free_monthly',
  FreePlusMonthly = 'free_plus_monthly',

  ProV2Daily = 'pro_daily_v2',
  ProV2Monthly = 'pro_monthly_v2',
  ProV2Annually = 'pro_annually_v2',

  UnlimitedDaily = 'unlimited_daily_v2',
  UnlimitedMonthly = 'unlimited_monthly_v2',
  UnlimitedAnnually = 'unlimited_annually_v2',

  ExpandMock = 'expand_mock',
}
