import { Container } from 'inversify';

import { REFERRAL_TYPES } from '@/ioc/types';

import {
  IReferralApiService,
  IReferralRepository,
  ReferralApiService,
  ReferralRepository,
} from '@/features/referral/data';
import {
  IReferralDao,
  ReferralCollectionCreator,
  ReferralDao,
  ReferralReplicationService,
} from '@/features/referral/data/db';
import { IReferralUseCase, ReferralUseCase } from '@/features/referral/domain';
import { IReplicationService } from '@/features/system/replication';

export const bindReferralModule = (container: Container) => {
  container
    .bind<IReferralApiService>(REFERRAL_TYPES.ReferralApiService)
    .to(ReferralApiService);
  container
    .bind<IReferralRepository>(REFERRAL_TYPES.ReferralRepository)
    .to(ReferralRepository);
  container.bind<IReferralUseCase>(REFERRAL_TYPES.ReferralUseCase).to(ReferralUseCase);
  container.bind<IReferralDao>(REFERRAL_TYPES.ReferralDao).to(ReferralDao);

  container
    .bind<ReferralCollectionCreator>('DbCollectionCreator')
    .to(ReferralCollectionCreator);

  container
    .bind<IReplicationService>(REFERRAL_TYPES.ReferralReplicationService)
    .to(ReferralReplicationService)
    .inSingletonScope();
  container
    .bind<IReplicationService>('ReplicationService')
    .toService(REFERRAL_TYPES.ReferralReplicationService);
};
