import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import { LazyImage } from '@/components';

import styles from './styles.module.scss';

type LogoProps = {
  image?: string | null;
  images: string[] | null;
  name: string;
};

enum ContentType {
  Image = 'image',
  Placeholder = 'placeholder',
}

export const Logo: React.FC<LogoProps> = ({ images, name, image }) => {
  const [state, setState] = useState<{
    contentType: ContentType | null;
    src: string;
    fallbackSrc?: string;
  }>(() => {
    if (image) {
      return {
        contentType: ContentType.Image,
        src: image,
        fallbackSrc: images?.at(0),
      };
    }

    if (images?.length) {
      return {
        contentType: ContentType.Image,
        src: images[0],
        fallbackSrc: images?.at(1),
      };
    }

    return { contentType: ContentType.Placeholder, src: '' };
  });

  const onError = useCallback(() => {
    if (contentType === ContentType.Image && state.fallbackSrc) {
      setState((prevState) => ({
        contentType: ContentType.Image,
        src: prevState.fallbackSrc as string,
        fallbackSrc: images?.at((images?.indexOf(prevState.src) ?? -1) + 1),
      }));
    } else {
      setState((prevState) => ({ ...prevState, contentType: ContentType.Placeholder }));
    }
  }, [state.contentType]);

  if (!state.contentType) return null;

  const { contentType, src } = state;

  return (
    <Box className={styles.wrapper}>
      {contentType === ContentType.Placeholder && (
        <Box className={styles.placeholder}>{name[0]?.toUpperCase()}</Box>
      )}
      {contentType === ContentType.Image && (
        <LazyImage src={src} alt={name} onError={onError} />
      )}
    </Box>
  );
};
