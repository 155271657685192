import { DomainError } from '@/features/common/error';

export class ReduceActiveUsersCountError extends DomainError {
  public readonly activeUsers: number;
  public readonly usersToReduce: number;

  constructor(params: { activeUsers: number; usersToReduce: number }) {
    super(`You can not buy less seats than you have active users`);

    this.activeUsers = params.activeUsers;
    this.usersToReduce = params.usersToReduce;
  }
}
