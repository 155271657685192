import { useState } from 'react';

import { useObservableResult } from '@/utils/rx/hooks';

import { useTeamMembersUseCase } from '../../hooks/useTeamMembersUseCase';

export const usePermissionsButtonViewModel = () => {
  const [anchorElement, setAnchorElement] = useState<Nullable<HTMLButtonElement>>(null);
  const teamMemberUseCase = useTeamMembersUseCase();
  const canAccessAllContacts = useObservableResult(
    () => teamMemberUseCase.getMemberAccessAllContacts(),
    {
      deps: [],
    },
  );
  const onChange = async (value: 'own' | 'all'): Promise<void> => {
    try {
      await teamMemberUseCase.setMemberAccessAllContacts(value === 'all');
      setAnchorElement(null);
    } catch (error) {
      throw error; //  TODO need to handle error
    }
  };

  const onClick: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setAnchorElement(currentTarget);
  };

  const onClose = (): void => {
    setAnchorElement(null);
  };

  return {
    anchorElement,
    canAccessAllContacts,
    onClick,
    onClose,
    onChange,
  };
};
