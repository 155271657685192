import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { Modal } from '@/components';

import type { PlanDowngradeDialogProps } from './usePlanDowngradeDialog';

import styles from './styles.module.scss';

export { usePlanDowngradeDialog } from './usePlanDowngradeDialog';

export const PlanDowngradeDialog: FC<PlanDowngradeDialogProps> = (props) => {
  const { t } = useTranslation('billing', { keyPrefix: 'planDowngradeDialogSupport' });

  return (
    <Modal open={props.isOpen} containerClass={styles.modal} onClose={props.onClose}>
      <div className={styles.container}>
        <Typography variant="h1">{t('title')}</Typography>
        <Typography variant="subtitle1">{t('subtitle')}</Typography>
        <div className={styles.body}>
          <Typography variant="h2">{props.text}</Typography>
        </div>
        <div className={styles.footer}>
          <Button variant="text" color="info" size="small" onClick={props.onClose}>
            {t('cancel')}
          </Button>
          <Button variant="contained" size="small" onClick={props.onConfirm}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
