export interface IBaseSyncErrorEvent {
  message: string;
  reason: string;
}

export function isBaseSyncErrorEvent(event: unknown): event is IBaseSyncErrorEvent {
  return (
    typeof event === 'object' && event !== null && 'message' in event && 'reason' in event
  );
}
