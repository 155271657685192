import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { AUTH_TYPES, COOKIES_TYPES } from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';

import { ICookiesManager } from '../data';

import { IAuthIdentityCookieUseCase } from './abstractions';

@injectable()
export class AuthIdentityCookieUseCase implements IAuthIdentityCookieUseCase {
  @inject(COOKIES_TYPES.CookiesManager)
  private cookiesManager: ICookiesManager;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  public sync(): Observable<void> {
    return this.authRepository.getAccessToken().pipe(
      map((token) => {
        if (token) {
          this.cookiesManager.setSharableAuthIdentityCookie();
        } else {
          this.cookiesManager.removeSharableAuthIdentityCookie();
        }
        return;
      }),
    );
  }
}
