import type { Components, Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    size: 'medium',
    fullWidth: true,
    InputLabelProps: { shrink: false, sx: { display: 'none' } },
  },
  styleOverrides: {
    root: {
      padding: '0',
      '.MuiInputBase-root': {
        padding: '0 12px',
        height: '34px',
        fontSize: '13px',
      },
      '.MuiInputBase-input': {
        padding: '0',
      },
    },
  },
};
