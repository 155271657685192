import { ComponentType, FC, lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { clsx } from 'clsx';
import type { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';

import styles from './styles.module.scss';
type LazyLottieProps = Omit<LottieComponentProps, 'animationData'> & {
  dataSrc: string;
  previewSrc: string;
};
type DataStateType = {
  isLoading: boolean;
  data: JSON | null;
  error: boolean;
};

const DynamicLottie = lazy(
  () => import('lottie-react'),
) as ComponentType<LottieComponentProps>;

export const LazyLottie: FC<LazyLottieProps> = ({ dataSrc, previewSrc, ...props }) => {
  const [dataState, setDataState] = useState<DataStateType>({
    isLoading: true,
    data: null,
    error: false,
  });
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    const loadAnimationData = async (): Promise<void> => {
      try {
        const response = await fetch(dataSrc);
        const data = await response.json();
        setDataState({
          isLoading: false,
          data,
          error: false,
        });
      } catch (error) {
        setDataState({
          isLoading: false,
          data: null,
          error: true,
        });
      }
    };

    const timeout = setTimeout(() => {
      void loadAnimationData();
    }, 750);
    return () => {
      clearTimeout(timeout);
    };
  }, [dataSrc]);

  const hidePreview = Boolean(dataState.data);

  return (
    <Suspense fallback={<></>}>
      <Box className={styles.wrapper}>
        <img
          src={previewSrc}
          alt={''}
          className={clsx(styles.image, {
            [styles.invisible]: hidePreview,
          })}
        />
        <DynamicLottie
          className={styles.lottie}
          lottieRef={lottieRef}
          renderer="svg"
          animationData={dataState.data}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          {...props}
        />
      </Box>
    </Suspense>
  );
};
