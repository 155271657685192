import { FC, useEffect, useState } from 'react';

import alexJohnson from '../../assets/alexJohnson.png';
import emilyNguyen from '../../assets/emilyNguyen.png';
import jasminePatel from '../../assets/jasminePatel.png';
import noahBennett from '../../assets/noahBennett.png';
import oliverHenning from '../../assets/oliverHenning.png';
import sophiaTorres from '../../assets/sophiaTorres.png';
import { ICustomerReviewEntity } from '../../entities';

import { ReviewCard, ReviewControls } from './components';

import styles from './styles.module.scss';

const SLIDE_INTERVAL = 5000;

const reviews: ICustomerReviewEntity[] = [
  {
    review:
      'Unlike other B2B databases where we had to use different credits for each action, with Powerlead my team gets exactly what they need in an unmatched price, so they don’t have to worry about whether a prospect’s information is worth paying for.',
    author: {
      avatarUrl: sophiaTorres,
      fullName: 'Sophia Torres',
      position: 'Senior Sales Strategist',
    },
  },
  {
    review:
      'Thanks to Powerlead we’ve been able to target our audience with precision and make data-driven decisions. Their extensive database and user-friendly platform have helped our team to succeed and enjoy significant growth. Highly recommended for any marketer looking to take their campaigns to the next level.',
    author: {
      avatarUrl: emilyNguyen,
      fullName: 'Emily Nguyen',
      position: 'Chief Marketing Officer',
    },
  },
  {
    review:
      'Powerlead has been a game changer for our marketing team. Their data ensures precise targeting and informed decision-making, while unlimited usage empowers creativity and campaign customization.',
    author: {
      avatarUrl: jasminePatel,
      fullName: 'Jasmine Patel',
      position: 'Lead Talent Acquisition Specialist',
    },
  },
  {
    review:
      'Powerlead’s extensive and accurate database allows us to identify and target the most promising leads efficiently. This platform has truly transformed our sales processes, leading to more meaningful conversations and higher conversion rates.',
    author: {
      avatarUrl: oliverHenning,
      fullName: 'Oliver Henning',
      position: 'Regional Sales Manager',
    },
  },
  {
    review:
      'In my position as a recruiter I’ve tried many of the B2B data sources out there and to me, Powerlead stands out in what is a crowded space. Their database is extensive, the data is reliable, and their focus on data compliance means I am able to contact candidates with confidence.',
    author: {
      avatarUrl: alexJohnson,
      fullName: 'Alex Johnson',
      position: 'Senior Technical Recruiter',
    },
  },
  {
    review:
      'I have been so impressed with Powerlead. Their data accuracy is unmatched, providing my team with highly reliable information for informed decision-making. We’ve been able to expand our reach and tap into new customer bases yielding month-over-month growth unlike we’ve ever seen.',
    author: {
      avatarUrl: noahBennett,
      fullName: 'Noah Bennett',
      position: 'Regional Sales Manager',
    },
  },
];

export const CustomerReviews: FC = () => {
  const [_, forceUpdate] = useState(0);
  const [current, setCurrent] = useState(0);

  const total = reviews.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((current) => (current + 1) % total);
    }, SLIDE_INTERVAL);

    return () => clearInterval(interval);

    // add current as dependency to reset interval on change
  }, [current]);

  useEffect(() => {
    const handleResize = (): void => {
      requestAnimationFrame(() => {
        forceUpdate((value) => value + 1);
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const width = window.innerWidth;
  const sliderOffset = width * current;

  function handleChange(value: number): void {
    setCurrent(value);
  }

  return (
    <div className={styles.customerReviews}>
      <div className={styles.customerReviewsSlider}>
        <div
          className={styles.customerReviewsSlides}
          style={{
            position: 'absolute',
            left: `-${sliderOffset}px`,
            transition: 'left 0.3s ease-in-out',
          }}
        >
          {reviews.map((review, index) => (
            <div className={styles.customerReviewsSlide} key={index}>
              <ReviewCard {...review} />
            </div>
          ))}
        </div>
      </div>
      <ReviewControls total={total} current={current} onChange={handleChange} />
    </div>
  );
};
