import * as React from "react";
const SvgPhone = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 100.692, height: 100.399, viewBox: "0 0 100.692 100.399", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "cqvijus41a", x1: 9e-3, y1: 0.878, x2: 0.762, y2: -0.021, gradientUnits: "objectBoundingBox" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, stopColor: "#ffece4" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#e7f0ff" }))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32828", d: "M88.852 9.244C57.276-10 8.119 7.551 2.4 21.427c-7.652 18.564 5.227 21.737 7.619 36.948 1.92 12.206-10.066 23.894-3.224 34.669 7.45 11.734 32.148 8.787 41.535 3.235 17.184-10.165 8.517-22.029 16.654-36.836 7.75-14.1 22.861-8.383 31.655-19.664C105 29.057 99.454 15.707 88.852 9.244z", transform: "translate(.001 -.745)", style: {
  fill: "url(#cqvijus41a)"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18832" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18830" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18819" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18818" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32829", d: "M174.732 146.326h-37.064a5.9 5.9 0 0 1-5.9-5.9V65.561a5.9 5.9 0 0 1 5.9-5.9h37.064a5.9 5.9 0 0 1 5.9 5.9v74.866a5.9 5.9 0 0 1-5.9 5.899z", transform: "translate(-105.853 -48.075)", style: {
  opacity: 0.1,
  fill: "#cd4000"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32830", d: "M174.732 122.326h-37.064a5.9 5.9 0 0 1-5.9-5.9V41.561a5.9 5.9 0 0 1 5.9-5.9h37.064a5.9 5.9 0 0 1 5.9 5.9v74.866a5.9 5.9 0 0 1-5.9 5.899z", transform: "translate(-105.853 -28.795)", style: {
  fill: "#ffece4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32831", d: "M131.77 78.57h48.863v69.788H131.77z", transform: "translate(-105.854 -63.265)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32832", d: "M131.77 317.94h48.863v22.713H131.77z", transform: "translate(-105.854 -255.56)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32833", d: "M170.715 119.293h-37.064a6.891 6.891 0 0 1-6.883-6.883V37.544a6.891 6.891 0 0 1 6.883-6.883h37.064a6.891 6.891 0 0 1 6.883 6.883v74.866a6.891 6.891 0 0 1-6.883 6.883zm-37.064-86.665a4.922 4.922 0 0 0-4.917 4.917v74.865a4.922 4.922 0 0 0 4.917 4.917h37.064a4.922 4.922 0 0 0 4.917-4.917V37.544a4.922 4.922 0 0 0-4.917-4.917z", transform: "translate(-101.836 -24.778)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18817" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32834", d: "M241.377 55H234.5a.983.983 0 0 1 0-1.967h6.876a.983.983 0 1 1 0 1.967z", transform: "translate(-187.591 -42.749)", style: {
  fill: "#ff5001"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32835", d: "M293.778 54.012a.984.984 0 1 1-.984-.984.984.984 0 0 1 .984.984z", transform: "translate(-234.421 -42.746)", style: {
  fill: "#ff5001"
} }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18820", transform: "translate(45.978 71.458)" }, /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1560", cx: 4.369, cy: 4.369, r: 4.369, style: {
  fill: "#ffece4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32836", d: "M246.62 374.217h-.755v-.755a.983.983 0 0 0-1.967 0v.755h-.755a.983.983 0 1 0 0 1.967h.755v.755a.983.983 0 1 0 1.967 0v-.755h.755a.983.983 0 1 0 0-1.967z", transform: "translate(-240.512 -370.83)", style: {
  fill: "#cd4000"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18826" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18821" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32837", d: "M196.127 202.836h-33.609a1.967 1.967 0 0 1-1.967-1.967v-10.205a1.967 1.967 0 0 1 1.967-1.967h33.609a1.967 1.967 0 0 1 1.967 1.967v10.205a1.966 1.966 0 0 1-1.967 1.967z", transform: "translate(-128.975 -151.734)", style: {
  fill: "#ffece4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32838", d: "M196.127 289.148h-33.609a1.967 1.967 0 0 1-1.967-1.967v-10.205a1.967 1.967 0 0 1 1.967-1.967h33.609a1.967 1.967 0 0 1 1.967 1.967v10.205a1.966 1.966 0 0 1-1.967 1.967z", transform: "translate(-128.975 -221.072)", style: {
  fill: "#ffece4"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32839", d: "M177.889 201.9H175a.983.983 0 1 0 0 1.967h2.887a.983.983 0 1 0 0-1.967z", transform: "translate(-139.794 -162.338)", style: {
  fill: "#cd4000"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32840", d: "M177.889 288.336H175a.983.983 0 1 0 0 1.967h2.887a.983.983 0 0 0 0-1.967z", transform: "translate(-139.794 -231.777)", style: {
  fill: "#cd4000"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18825" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32841", d: "M198.093 134.35v4.3a1.966 1.966 0 0 1-1.967 1.967h-33.61a1.966 1.966 0 0 1-1.967-1.967v-4.3a1.967 1.967 0 0 1 1.967-1.967h33.61a1.967 1.967 0 0 1 1.967 1.967z", transform: "translate(-128.975 -106.495)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18822" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32842", d: "M192.11 137.588H158.5a2.953 2.953 0 0 1-2.95-2.95v-4.3a2.953 2.953 0 0 1 2.95-2.95h33.61a2.953 2.953 0 0 1 2.95 2.95v4.3a2.953 2.953 0 0 1-2.95 2.95zm-33.61-8.238a.984.984 0 0 0-.983.983v4.3a.984.984 0 0 0 .983.983h33.61a.984.984 0 0 0 .983-.983v-4.3a.984.984 0 0 0-.983-.983z", transform: "translate(-124.958 -102.478)", style: {
  fill: "#ff5001"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18824", transform: "translate(57.044 17.143)" }, /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1561", cx: 5.565, cy: 5.565, r: 5.565, transform: "rotate(-45 9.499 3.934)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18823" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32843", d: "M321.075 112.862a.983.983 0 0 0-.6 1.763l1.553 1.2-1.553 1.2a.983.983 0 1 0 1.2 1.558l2.565-1.975a.983.983 0 0 0 0-1.558l-2.565-1.975a.98.98 0 0 0-.6-.213z", transform: "translate(-314.183 -107.956)", style: {
  fill: "#fff"
} }))))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18829" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18827" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32844", d: "M175.871 403.537h-1.707a.983.983 0 1 0 0 1.967h1.707a.983.983 0 1 0 0-1.967z", transform: "translate(-139.12 -324.323)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32845", d: "M207.954 403.537h-1.707a.983.983 0 1 0 0 1.967h1.707a.983.983 0 1 0 0-1.967z", transform: "translate(-164.893 -324.323)", style: {
  fill: "#ff5001"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18828" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32846", d: "M290.747 403.537h-1.707a.983.983 0 1 0 0 1.967h1.707a.983.983 0 0 0 0-1.967z", transform: "translate(-231.404 -324.323)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32847", d: "M322.83 403.537h-1.707a.983.983 0 0 0 0 1.967h1.707a.983.983 0 0 0 0-1.967z", transform: "translate(-257.177 -324.323)", style: {
  fill: "#ff5001"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18831" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32848", d: "M425.8 272.6a2.811 2.811 0 1 1 2.811-2.811 2.814 2.814 0 0 1-2.811 2.811zm0-3.655a.844.844 0 1 0 .844.844.845.845 0 0 0-.844-.842z", transform: "translate(-339.799 -214.622)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32849", d: "M57.092 377.647a2.811 2.811 0 1 1 2.811-2.811 2.814 2.814 0 0 1-2.811 2.811zm0-3.655a.844.844 0 1 0 .844.844.845.845 0 0 0-.844-.843z", transform: "translate(-43.605 -299.009)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32850", d: "M409.919 313.171a.983.983 0 0 1-.983-.983 1.181 1.181 0 0 0-1.18-1.18.983.983 0 1 1 0-1.967 1.181 1.181 0 0 0 1.18-1.18.983.983 0 1 1 1.967 0 1.181 1.181 0 0 0 1.18 1.18.983.983 0 0 1 0 1.967 1.181 1.181 0 0 0-1.18 1.18.983.983 0 0 1-.984.983z", transform: "translate(-326.774 -246.673)", style: {
  fill: "#e7f0ff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32851", d: "M71.186 310.091a.983.983 0 0 1-.983-.983 1.181 1.181 0 0 0-1.18-1.18.983.983 0 1 1 0-1.967 1.181 1.181 0 0 0 1.18-1.18.983.983 0 1 1 1.967 0 1.181 1.181 0 0 0 1.18 1.18.983.983 0 1 1 0 1.967 1.181 1.181 0 0 0-1.18 1.18.983.983 0 0 1-.984.983z", transform: "translate(-54.657 -244.199)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32852", d: "M415.332 106.81a.983.983 0 0 1-.983-.983 1.181 1.181 0 0 0-1.18-1.18.983.983 0 0 1 0-1.967 1.181 1.181 0 0 0 1.18-1.18.983.983 0 1 1 1.967 0 1.181 1.181 0 0 0 1.18 1.18.983.983 0 0 1 0 1.967 1.181 1.181 0 0 0-1.18 1.18.983.983 0 0 1-.984.983z", transform: "translate(-331.122 -80.896)", style: {
  fill: "#fff"
} }))));
export default SvgPhone;
