import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OutlinedInput, Typography } from '@mui/material';

import { UnauthenticatedLayout } from '@/router/layouts';
import { ROUTES } from '@/router/routes';

import { useDocumentMeta } from '@/hooks';

import { useCustomTokenLoginForm } from './useCustomTokenLoginForm';

import styles from './styles.module.scss';

export const CustomTokenForm: React.FC = () => {
  const { loginWithCustomToken, form } = useCustomTokenLoginForm();
  const { t } = useTranslation('auth');
  const { t: validationTranslate } = useTranslation('validation');

  useDocumentMeta({ title: 'signIn.title', description: 'signIn.description' });

  return (
    <UnauthenticatedLayout.Container>
      <UnauthenticatedLayout.Title>
        {t('signInWithCustomToken.title')}
      </UnauthenticatedLayout.Title>
      <UnauthenticatedLayout.Subtitle>
        {t('signInWithCustomToken.description')}
      </UnauthenticatedLayout.Subtitle>
      <UnauthenticatedLayout.Form>
        <UnauthenticatedLayout.FormField
          error={validationTranslate(form.formState.errors.token?.message || '')}
          required
        >
          <OutlinedInput
            placeholder={t('signInWithCustomToken.tokenFieldPlaceholder')}
            type="string"
            {...form.register('token')}
            autoComplete="off"
          />
        </UnauthenticatedLayout.FormField>

        <UnauthenticatedLayout.SubmitButton
          onClick={loginWithCustomToken}
          disabled={form.formState.isSubmitting}
        >
          {t('signInWithCustomToken.submitButton')}
        </UnauthenticatedLayout.SubmitButton>

        <Typography variant={'subtitle1'} className={styles.dontHaveAccount}>
          <Trans t={t} i18nKey={'signInWithCustomToken.createAccount'}>
            Don’t have an account yet?
            <Link to={ROUTES.SIGN_UP} className={styles.linkCreate}>
              Sign up
            </Link>
          </Trans>
        </Typography>
        {form.formState.errors.root && (
          <UnauthenticatedLayout.ErrorAlert>
            {form.formState.errors.root.message}
          </UnauthenticatedLayout.ErrorAlert>
        )}
      </UnauthenticatedLayout.Form>
    </UnauthenticatedLayout.Container>
  );
};
