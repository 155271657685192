import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import styles from './styles.module.scss';

type OnboardingBannerActionProps = {
  title: string;
  description: string;
  buttonText: string;
  Icon: FC;
  onClick: () => void;
};

export const OnboardingBannerAction: FC<OnboardingBannerActionProps> = ({
  title,
  description,
  buttonText,
  Icon,
  onClick,
}) => {
  return (
    <Stack direction="row" className={styles.wrapper}>
      <Box className={styles.icon}>
        <Icon />
      </Box>
      <Stack direction="column" className={styles.content}>
        <Typography variant="inherit" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="inherit" className={styles.description}>
          {description}
        </Typography>
        <Button variant="contained" className={styles.button} onClick={onClick}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
