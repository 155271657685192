import { forwardRef } from 'react';
import { Box, MenuItem as MuiMenuItem, MenuItemProps, styled } from '@mui/material';

import { CheckIcon } from '@/components/Icons';

const Wrapper = styled(MuiMenuItem)<MenuItemProps>((props) => ({
  padding: '9px 10px',
  borderRadius: '4px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  position: 'relative',
  paddingRight: '40px',
  backgroundColor: ((props): string => {
    if (props.selected) {
      return `#f5f5fa`;
    }
    return 'transparent';
  })(props),
  color: ((props): string => {
    if (props.selected) {
      return `#2173ff`;
    }
    return 'initial';
  })(props),
  '&:hover': {
    backgroundColor: '#f5f5fa',
    color: '#2173ff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#f5f5fa',
  },
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
}));

const ChildrenWrapperBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Icon = styled(Box)(() => ({
  position: 'absolute',
  right: '10px',
  fontSize: '16px',
  display: 'flex',
}));

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(function (
  { children, ...props }: MenuItemProps,
  ref,
) {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <ChildrenWrapperBox>{children}</ChildrenWrapperBox>
      </Container>
      {props.selected && (
        <Icon as="span">
          <CheckIcon />
        </Icon>
      )}
    </Wrapper>
  );
});

MenuItem.displayName = 'MenuItem';
