import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { firstValueFrom, map } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import HttpClient from '@/features/system/network/HttpClient';

import { EnrichmentConfigForm, EnrichmentFileUploadResponse } from '../../domain/types';

export interface IEnrichmentApiService {
  uploadFile(
    formData: FormData,
    options?: Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal'>,
  ): Promise<EnrichmentFileUploadResponse>;
  downloadReport(uuid: string): Promise<string>;
  startJob(jobId: string, configForm: EnrichmentConfigForm): Promise<unknown>;
  contactAdmin(): Promise<boolean>;
}

const Endpoint = {
  default: '/api/v1/csv-enrichment',
  entity: '/api/v1/csv-enrichment/:uuid',
};

@injectable()
export class EnrichmentApiService implements IEnrichmentApiService {
  @inject(NETWORK_TYPES.HttpClient)
  httpClient: HttpClient;

  public async uploadFile(
    formData: FormData,
    options: Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal'> = {},
  ): Promise<EnrichmentFileUploadResponse> {
    return firstValueFrom(
      this.httpClient
        .request({
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          url: Endpoint.default,
          data: formData,
          ...options,
        })
        .pipe(
          map((response: AxiosResponse<EnrichmentFileUploadResponse>) => response.data),
        ),
    );
  }

  public downloadReport(uuid: string): Promise<string> {
    return firstValueFrom(
      this.httpClient
        .get<string>(Endpoint.entity.replace(':uuid', uuid))
        .pipe(map((response: AxiosResponse<string>) => response.data)),
    );
  }

  public async startJob(
    jobId: string,
    configForm: EnrichmentConfigForm,
  ): Promise<unknown> {
    return firstValueFrom(
      this.httpClient
        .patch(Endpoint.entity.replace(':uuid', jobId), configForm)
        .pipe(map((response: AxiosResponse<unknown>) => response.data)),
    );
  }

  public async contactAdmin(): Promise<boolean> {
    return firstValueFrom(
      this.httpClient
        .post<boolean>(`${Endpoint.default}/contact_admin`, {})
        .pipe(map((response: AxiosResponse<boolean>) => response.data)),
    );
  }
}
