import { useInjection } from '@/ioc';
import { BILLING_SETTINGS_TYPES } from '@/ioc/types';

import type { IBillingSettingsUseCase } from '../../domain/abstractions/IBillingSettingsUseCase';

export const useBillingSettingsUseCase = (): IBillingSettingsUseCase => {
  return useInjection<IBillingSettingsUseCase>(
    BILLING_SETTINGS_TYPES.BillingSettingsUseCase,
  );
};
