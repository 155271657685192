import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { IReferralResponseDataEntity } from '@/features/referral/domain';

import { useEmailFieldMessagesViewModel } from './useEmailFieldMessagesViewModel';

import styles from './styles.module.scss';

export type EmailFieldMessagesProps = {
  response: IReferralResponseDataEntity[];
};

export const EmailFieldMessages: React.FC<EmailFieldMessagesProps> = memo((props) => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteSection.emailInput' });
  const { successfulInvitedCount, emailsAlreadyInSystem, publicEmails, limitsExceeded } =
    useEmailFieldMessagesViewModel(props);

  if (!props.response.length) return null;

  return (
    <Box className={styles.messages}>
      {successfulInvitedCount > 0 ? (
        <Typography
          className={styles.message}
          variant={'subtitle3'}
          style={{ color: '#20d068' }}
        >
          {t('emailSent', { count: successfulInvitedCount })}
        </Typography>
      ) : null}
      {emailsAlreadyInSystem.length > 0 ? (
        <Typography
          className={styles.message}
          variant={'subtitle3'}
          style={{ color: '#ff4757' }}
        >
          {t('alreadyInvitedError', {
            email: emailsAlreadyInSystem.join(', '),
          })}
        </Typography>
      ) : null}
      {publicEmails.length > 0 ? (
        <Typography
          className={styles.message}
          variant={'subtitle3'}
          style={{ color: '#ff4757' }}
        >
          {t(publicEmails.length > 1 ? 'publicEmails' : 'publicEmail', {
            email: publicEmails.join(', '),
          })}
        </Typography>
      ) : null}
      {limitsExceeded ? (
        <Typography
          className={styles.message}
          variant={'subtitle3'}
          style={{ color: '#ff4757' }}
        >
          {t('limitsError')}
        </Typography>
      ) : null}
    </Box>
  );
});
