import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

import 'chartjs-adapter-dayjs-4';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { useChartData } from './hooks';
import { hoverLinePlugin } from './utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  hoverLinePlugin,
);

export type AnalyticsChartProps = {
  tooltipTitles: string[];
  labels: string[];
  values: { x: number; y: number }[];
  creditsUsed: number;
  selectedDate: DATE_SELECT_OPTION_VALUES;
  selectedUser: string;
};

export const AnalyticsChart: FC<AnalyticsChartProps> = (props) => {
  const chartData = useChartData(props);

  return <Line {...chartData} />;
};
