import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { useAccount } from '@/features/common/account';

import { ModalControler, useModalController } from '@/hooks';

import { NameValidationSchema, useFormWithSchema } from '@/utils/validation';

export const AccountSettingsFormSchema = yup.object({
  name: NameValidationSchema,
});
export type AccountSettingsFormType = yup.InferType<typeof AccountSettingsFormSchema>;

type UseAccountSettingsFormViewModel = (params: {
  onNameChange: (name: string) => Promise<void>;
}) => {
  emailPasswordDialog: ModalControler;
  disabled: boolean;
  form: UseFormReturn<AccountSettingsFormType>;
  onSubmit: (e: BaseSyntheticEvent) => void;
  email: string;
};

export const useAccountSettingsFormViewModel: UseAccountSettingsFormViewModel = ({
  onNameChange,
}) => {
  const { account } = useAccount();

  const emailPasswordDialog = useModalController();
  const form = useFormWithSchema(AccountSettingsFormSchema, {
    defaultValues: {
      name: account?.fullName || '',
    },
  });

  const onSubmit = (e: BaseSyntheticEvent): void => {
    void form.handleSubmit(async ({ name }: AccountSettingsFormType) => {
      await onNameChange(name);
      form.reset({ name });
    })(e);
  };

  return {
    emailPasswordDialog,
    disabled: form.formState.isSubmitting || !form.formState.isDirty,
    onSubmit,
    form,
    email: account?.unconfirmedEmail ?? account?.email ?? '',
  };
};
