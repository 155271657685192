import { FC } from 'react';
import { Box } from '@mui/material';

import ArrRight from './assets/arr-right.svg?react';

import styles from './styles.module.scss';

type OwnCellFieldValueProps = {
  value: string;
};

export const OwnCellField: FC<OwnCellFieldValueProps> = ({ value }) => (
  <Box className={styles.Cell}>
    <Box className={styles.Title}>{value}</Box>
    <Box className={styles.Icon}>
      <ArrRight />
    </Box>
  </Box>
);
