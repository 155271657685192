import { ChartDataEntity } from '../entities/ChartDataEntity';
import { countTotalPropertyValue } from '../utils';

export class ChartDataTotals {
  creditsUsed = 0;
  emailAddresses = 0;
  phoneNumbers = 0;

  constructor(data?: ChartDataEntity[]) {
    if (data) {
      this.creditsUsed = countTotalPropertyValue<ChartDataEntity>('creditsUsed', data);
      this.emailAddresses = countTotalPropertyValue<ChartDataEntity>('emailsCount', data);
      this.phoneNumbers = countTotalPropertyValue<ChartDataEntity>('phonesCount', data);
    }
  }
}
