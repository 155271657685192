import { useMemo, useState } from 'react';

import { isDateSelectOptionValue } from '../enums';
import { Option } from '../types';

import { useDateSelectOptions } from './useDateSelectOptions';

type UseDateSelectViewModel = (props: {
  externalValue: string | null;
  onChange: (value: string) => void;
}) => {
  value: Option;
  isOpen: boolean;
  anchorElement: HTMLElement | null;
  close: () => void;
  handleAnchorClick: (e: React.MouseEvent<HTMLElement>) => void;
  options: Option[];
  onSelect: (val: Option) => void;
  cutomOption: Option;
};

export const useDateSelectViewModel: UseDateSelectViewModel = ({
  externalValue,
  onChange,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorElement);
  const { options, getCustomOption } = useDateSelectOptions();

  const value = useMemo(() => {
    if (isDateSelectOptionValue(externalValue)) {
      return options.find((option) => option.value === externalValue) as Option;
    }

    return getCustomOption(externalValue ?? '');
  }, [externalValue]);

  const handleAnchorClick = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorElement((prev) => {
      if (prev) return null;
      return e.currentTarget;
    });
  };

  const close = (): void => {
    setAnchorElement(null);
  };

  const onSelect = (option: Option): void => {
    const nextValue = option.value;

    onChange(nextValue);

    if (nextValue) {
      close();
    }
  };

  return {
    value,
    isOpen,
    anchorElement,
    close,
    handleAnchorClick,
    options,
    onSelect,
    cutomOption: getCustomOption(value.value),
  };
};
