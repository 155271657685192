import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { ONLINE_TRACKER_TYPES } from '@/ioc/types';

import { IOnlineTrackerRepository } from '../data';

import { IOnlineTrackerUseCase } from './abstractions';

@injectable()
export class OnlineTrackerUseCase implements IOnlineTrackerUseCase {
  @inject(ONLINE_TRACKER_TYPES.OnlineTrackerRepository)
  private onlineTrackerRepository: IOnlineTrackerRepository;

  getIsOnline(): Observable<boolean> {
    return this.onlineTrackerRepository.getIsOnline();
  }
}
