import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { QuestionBlock } from './QuestionBlock';

import styles from './styles.module.scss';

function useCurrentBlock(): {
  currentBlock: number | null;
  toggle: (index: number) => void;
} {
  const [current, setCurrent] = useState<number | null>(null);

  function toggle(index: number): void {
    setCurrent((current) => (current === index ? null : index));
  }

  return { currentBlock: current, toggle };
}

const NUMBER_OF_QUESTIONS = 6;

export const PlansFAQ: FC = memo(() => {
  const { t } = useTranslation('plans');
  const { currentBlock, toggle } = useCurrentBlock();

  const questionBlocks = Array.from({ length: NUMBER_OF_QUESTIONS }, (_, index) => ({
    q: t(`faq.questions.${index + 1}.q`),
    a: t(`faq.questions.${index + 1}.a`),
  }));

  return (
    <Box className={styles.faq}>
      <Box className={styles.questionsList}>
        {questionBlocks.map(({ q, a }, index) => (
          <QuestionBlock
            question={q}
            answer={a}
            key={index}
            isExpanded={currentBlock === index}
            onClick={(): void => toggle(index)}
          />
        ))}
      </Box>
    </Box>
  );
});
