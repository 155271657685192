export interface IBaseSyncInvalidateEvent {
  event_type: 'invalidate';
}

export function isBaseSyncInvalidateEvent(
  event: unknown,
): event is IBaseSyncInvalidateEvent {
  return (
    typeof event === 'object' &&
    event !== null &&
    'event_type' in event &&
    event.event_type === 'invalidate'
  );
}
