import { inject, injectable } from 'inversify';
import { combineLatest, Observable, of, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceEntity, IWorkspaceRepository } from '@/features/common/workspace';

import { IAccountPermissionsUseCase, IAccountUseCase } from './abstractions';
import { IAccountEntity } from './entities';
import {
  ACCESS_PERMISSIONS,
  PERMISSION_BY_PLAN_TYPE,
  PERMISSION_BY_ROLES,
  UserRole,
} from './types';

@injectable()
export class AccountPermissionsUseCase implements IAccountPermissionsUseCase {
  @inject(ACCOUNT_TYPES.AccountUseCase)
  private readonly accountUseCase: IAccountUseCase;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  getAccountPermissions(): Observable<{
    uuid: string;
    isOwnerOrAdmin: boolean;
    permissions: Record<ACCESS_PERMISSIONS, boolean>;
    freezedToUpdate: boolean;
  }> {
    return combineLatest([
      this.accountUseCase.getAccount(),
      this.workspaceRepository.getCurrentWorkspace(),
    ]).pipe(
      switchMap(
        ([account, workspace]: [IAccountEntity | null, IWorkspaceEntity | null]) => {
          const result = {
            uuid: account?.uuid ?? '',
            isOwnerOrAdmin:
              account?.role === UserRole.Admin || account?.role === UserRole.Owner,
            permissions: {
              [ACCESS_PERMISSIONS.CAN_ACCESS_ALL_CONTACTS]: ((): boolean => {
                const isMember = account?.role === UserRole.Member;
                const memberAccessAllContacts = !!workspace?.memberAccessAllContacts;
                return isMember ? memberAccessAllContacts : true;
              })(),

              [ACCESS_PERMISSIONS.CAN_ACCESS_BILLING]: account?.role
                ? PERMISSION_BY_ROLES[ACCESS_PERMISSIONS.CAN_ACCESS_BILLING].includes(
                    account.role,
                  )
                : false,

              [ACCESS_PERMISSIONS.CAN_CHANGE_CONTACTS_OWNER]: account?.role
                ? PERMISSION_BY_ROLES[
                    ACCESS_PERMISSIONS.CAN_CHANGE_CONTACTS_OWNER
                  ].includes(account.role)
                : false,

              [ACCESS_PERMISSIONS.CAN_EDIT_DELETE_CONTACT_LIST]: account?.role
                ? PERMISSION_BY_ROLES[
                    ACCESS_PERMISSIONS.CAN_EDIT_DELETE_CONTACT_LIST
                  ].includes(account.role)
                : false,

              [ACCESS_PERMISSIONS.CAN_MANAGE_ALL_TAGS]: account?.role
                ? PERMISSION_BY_ROLES[ACCESS_PERMISSIONS.CAN_MANAGE_ALL_TAGS].includes(
                    account.role,
                  )
                : false,

              [ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS]: account?.role
                ? PERMISSION_BY_ROLES[
                    ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS
                  ].includes(account.role)
                : false,
              [ACCESS_PERMISSIONS.CAN_MANAGE_INTEGRATIONS]: account?.role
                ? PERMISSION_BY_ROLES[
                    ACCESS_PERMISSIONS.CAN_MANAGE_INTEGRATIONS
                  ].includes(account.role)
                : false,
              [ACCESS_PERMISSIONS.CAN_USE_EXTENSION]: account?.role
                ? PERMISSION_BY_ROLES[ACCESS_PERMISSIONS.CAN_USE_EXTENSION].includes(
                    account.role,
                  )
                : false,

              [ACCESS_PERMISSIONS.CAN_VIEW_PROSPECT_TASK_PROGRESS]: account?.role
                ? PERMISSION_BY_ROLES[
                    ACCESS_PERMISSIONS.CAN_VIEW_PROSPECT_TASK_PROGRESS
                  ].includes(account.role)
                : false,
              [ACCESS_PERMISSIONS.CAN_ACCESS_REFFERAL]: workspace?.subscription
                ? PERMISSION_BY_PLAN_TYPE[
                    ACCESS_PERMISSIONS.CAN_ACCESS_REFFERAL
                  ]?.includes(workspace.subscription.planType)
                : false,
            },
            freezedToUpdate: Boolean(account?.adminEmail),
          };

          return of(result);
        },
      ),
    );
  }
}
