import { RxJsonSchema } from 'rxdb';

import { ITagDC } from '../../dataContracts/TagDC';

export const tagSchema: RxJsonSchema<ITagDC> = {
  title: 'tag schema',
  version: 0,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    color: { type: 'string', maxLength: 100 },
    name: { type: 'string', maxLength: 100 },
    created_by: { type: 'string' },
    created_at: { type: 'number' },
    updated_at: { type: 'number' },
    contacts_amount: { type: 'number' },
  },
};
