import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { useModal } from '@/components';

import ModalContainer from './components/ModalContainer';
import { ContextType, ModalContext, ModalPropsType } from './context';

const { Provider } = ModalContext;

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isShown, openModal, closeModal } = useModal();

  const [modalData, setModalData] = useState<Nullable<ModalPropsType>>();

  const contextValue = useMemo<ContextType>(
    () => ({
      isShown,
      openModal: (modalProps: ModalPropsType): void => {
        setModalData(modalProps);
        openModal();
      },
      closeModal: (): void => {
        closeModal();
        setModalData(null);
      },
      modalData,
    }),
    [isShown, closeModal, modalData],
  );

  return (
    <Provider value={contextValue}>
      <ModalContainer />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
