import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon500 from '@/assets/icons/icon500.svg?react';

import { ErrorLayout } from './components';

// TODO: Should be moved to Layout.tsx and showed when needed instead of content.
export const ErrorPage500: React.FC = () => {
  const { t } = useTranslation('common');

  const onHomeButtonClick = (): void => {
    location.reload();
  };

  return (
    <ErrorLayout
      icon={<Icon500 />}
      title={'500'}
      isTitleBig
      description={t('500PageDescription')}
      buttonText={t('500PageButton')}
      onButtonClick={onHomeButtonClick}
    />
  );
};
