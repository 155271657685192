import { Container } from 'inversify';

import { CONTEXTUAL_ONBOARDING_TYPES } from '@/ioc/types';

import { ContextualOnboardingRepository } from '../data';
import {
  ContextualOnboardingUseCase,
  IContextualOnboardingRepository,
  IContextualOnboardingUseCase,
} from '../domain';

export const bindContextualOnboardingModule = (container: Container): void => {
  container
    .bind<IContextualOnboardingRepository>(
      CONTEXTUAL_ONBOARDING_TYPES.ContextualOnboardingRepository,
    )
    .to(ContextualOnboardingRepository)
    .inSingletonScope();

  container
    .bind<IContextualOnboardingUseCase>(
      CONTEXTUAL_ONBOARDING_TYPES.ContextualOnboardingUseCase,
    )
    .to(ContextualOnboardingUseCase)
    .inSingletonScope();
};
