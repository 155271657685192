import { GridSortItem } from '@mui/x-data-grid-pro';

import { convertDateSelectValueToDateFormat } from '@/components';

import { ContactInfoFilterType } from '../../../common/contact/domain';

export const CONTACTS_PER_PAGE = 50;

export const createSearchParameters = (contextState: {
  contactListId?: string;
  search: string;
  filterByUser: string | null;
  filterByDate: string;
  filterByTags: string[];
  filterByExport: string;
  page: number;
  sort: GridSortItem;
  filterByContactInfo: ContactInfoFilterType[];
}) => {
  const {
    page,
    sort,
    search,
    contactListId,
    filterByTags,
    filterByUser,
    filterByExport,
    filterByDate,
    filterByContactInfo,
  } = contextState;

  const { from, to } = convertDateSelectValueToDateFormat({
    value: filterByDate,
    unix: true,
  });

  const params = new URLSearchParams();
  params.set('start', String((page - 1) * CONTACTS_PER_PAGE || 0));
  params.set('length', String(CONTACTS_PER_PAGE));
  params.set('sort_by', sort.field);
  params.set('sort_dir', sort?.sort || 'asc');
  if (search) {
    params.set('search', search);
  }
  if (contactListId) {
    params.set('contact_list_id', contactListId);
  }
  if (filterByTags.length) {
    params.set('tag_id', filterByTags.join(','));
  }
  if (filterByUser) {
    params.set('assigned_to', filterByUser);
  }
  if (filterByExport) {
    params.set('is_exported', filterByExport);
  }
  if (from && to) {
    params.set('from_dt', String(from));
    params.set('to_dt', String(to));
  }
  params.set('stream', '1');
  filterByContactInfo.forEach((item) => {
    params.append('contact_info', item);
  });

  return params;
};
