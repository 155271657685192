import { Container } from 'inversify';

import { LEADS_TRACKING_TYPES } from '@/ioc/types';

import {
  ILeadsTrackingRepository,
  LeadsTrackingRepository,
} from '../data/LeadsTrackingRepository';
import {
  ILeadsTrackingUseCase,
  LeadsTrackingUseCase,
} from '../domain/LeadsTrackingUseCase';

export const bindLeadsTrackingModule = (container: Container): void => {
  container
    .bind<ILeadsTrackingRepository>(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
    .to(LeadsTrackingRepository)
    .inSingletonScope();

  container
    .bind<ILeadsTrackingUseCase>(LEADS_TRACKING_TYPES.LeadsTrackingUseCase)
    .to(LeadsTrackingUseCase);
};
