import { FC, PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';

import { CheckIcon } from '@/components/Icons';

type DefaultItemProps = {
  selected: boolean;
  description?: string;
};

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
}));

const Icon = styled(Box)(() => ({
  position: 'absolute',
  right: '10px',
  fontSize: '16px',
  display: 'flex',
}));

export const DefaultItem: FC<PropsWithChildren<DefaultItemProps>> = ({
  children,
  description,
  ...props
}) => {
  return (
    <>
      <Container>
        <Box>{children}</Box>
        {description && <Box component="span">{description}</Box>}
      </Container>
      {props.selected && (
        <Icon as="span">
          <CheckIcon />
        </Icon>
      )}
    </>
  );
};
