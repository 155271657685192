import { Container } from 'inversify';

import { CONTACT_LIST_TYPES } from '@/ioc/types';

import {
  IContactListDao,
  IContactListRepository,
  IContactListServicesApi,
  IContactListUseCase,
} from '@/features/common/contactList';
import ContactListRepository from '@/features/common/contactList/data/ContactListRepository';
import ContactListDao from '@/features/common/contactList/data/db/dao/ContactListDao';
import ContactListApiService from '@/features/common/contactList/data/network/ContactListApiSevices';
import ContactListUseCase from '@/features/common/contactList/domain/ContactListUseCase';
import { IReplicationService } from '@/features/system/replication';

import { ContactListCollectionCreator } from '../data/db/ContactListCollectionCreator';
import { ContactListReplicationService } from '../data/db/ContactListReplicationService';

export const bindContactListModule = (container: Container): void => {
  container
    .bind<IReplicationService>(CONTACT_LIST_TYPES.ContactListReplicationService)
    .to(ContactListReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(CONTACT_LIST_TYPES.ContactListReplicationService);

  container
    .bind<ContactListCollectionCreator>('DbCollectionCreator')
    .to(ContactListCollectionCreator);

  container
    .bind<IContactListRepository>(CONTACT_LIST_TYPES.ContactListRepository)
    .to(ContactListRepository);

  container
    .bind<IContactListServicesApi>(CONTACT_LIST_TYPES.ContactListApiService)
    .to(ContactListApiService);

  container
    .bind<IContactListUseCase>(CONTACT_LIST_TYPES.ContactListUseCase)
    .to(ContactListUseCase);

  container
    .bind<IContactListDao>(CONTACT_LIST_TYPES.ContactListDao)
    .to(ContactListDao)
    .inSingletonScope();
};
