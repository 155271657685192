import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const PaymentFormFieldset: FC<{
  title: string;
  subtitle?: string;
  children: ReactNode;
  className?: string;
}> = ({ title, subtitle, children, className }) => {
  return (
    <fieldset className={clsx(styles.formGroup, className)}>
      <legend className={styles.formGroupTitle}>{title}</legend>
      {subtitle ? (
        <Typography variant="subtitle1" color="info">
          {subtitle}
        </Typography>
      ) : null}
      <div className={styles.formGroupBody}>{children}</div>
    </fieldset>
  );
};
