import { FC, useContext } from 'react';

import { TeamMemberSelect } from '@/features/settings';

import { EnrichmentContext } from '../../EnrichmentContext';

type EnrichmentFilterByUserProps = {};

export const EnrichmentFilterByUser: FC<EnrichmentFilterByUserProps> = () => {
  const { filterByUser, setFilterByUser } = useContext(EnrichmentContext);

  const handleChange = (value): void => {
    setFilterByUser(value);
  };

  return <TeamMemberSelect value={filterByUser} onChange={handleChange} />;
};
