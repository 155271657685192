import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { AUTH_TYPES } from '@/ioc/types';

import { AuthIdentityProvider, IAuthUseCase } from '@/features/common/auth';

import { useObservableResult } from '@/utils/rx/hooks/index';

export const useProvider = (): AuthIdentityProvider | null => {
  const authUseCase = useInjection<IAuthUseCase>(AUTH_TYPES.AuthUseCase);
  const authIdentityProvider$ = useMemo(() => authUseCase.getAuthIdentityProvider(), []);
  const { data: provider } = useObservableResult(authIdentityProvider$);
  return provider;
};
