import { injectable, unmanaged } from 'inversify';
import { RxState } from 'rxdb';

import { Database } from '../types';

export interface IDbStateCreator<T extends object> {
  stateName: string;
  create(db: Database): Promise<RxState<T>>;
}

@injectable()
export class DbStateCreator<T extends object> implements IDbStateCreator<T> {
  private readonly _stateName: string;

  constructor(@unmanaged() params: { stateName: string }) {
    this._stateName = params.stateName;
  }

  public get stateName(): string {
    return this._stateName;
  }

  public create(db: Database): Promise<RxState<T>> {
    return db.addState(this._stateName);
  }
}
