import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { FAIR_USAGE_TYPES } from '@/ioc/types';

import { useObservableResult } from '@/utils/rx';

import { IFairUsageUseCase } from '../../domain';

type Return = {
  isFairUsageLimitReached: boolean;
};

export const useIsFairUsageLimitReached = (): Return => {
  const useCase = useInjection<IFairUsageUseCase>(FAIR_USAGE_TYPES.FairUsageUseCase);

  const isFairUsageLimitReached$ = useMemo(
    () => useCase.getIsFairUsageLimitReached(),
    [],
  );

  const { data: isFairUsageLimitReached } = useObservableResult(
    isFairUsageLimitReached$,
    {
      defaultData: false,
    },
  );

  return { isFairUsageLimitReached };
};
