import dayjs, { Dayjs } from 'dayjs';
import { inject, injectable } from 'inversify';
import { filter, map, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, DASHBOARD_CHART_TYPES, PERMISSIONS_TYPES } from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';
import { IPermissionsRepository, Permission } from '@/features/common/permissions';

import { IDashboardChartRepository } from '../data';

import { Aggregator } from './abstractions/Aggregation';
import { IDashboardChartUseCase } from './abstractions/IDashboardChartUseCase';
import { AggregatedChartDataEntity } from './entities/AggregatedChartDataEntity';
import { ChartDataEntity } from './entities/ChartDataEntity';
import { AggregationFiltersDto } from './types/AggregationFiltersDto';
import { AggregationStrategy } from './types/AggregationStrategies';
import { ChartDataGroup } from './types/ChartDataGroup';
import { ChartDataTotals } from './types/ChartDataTotals';
import { GetChartDataReq } from './types/GetChartDataReq';
import { DailyAggregation, MonthlyAggregation, WeeklyAggregation } from './aggregations';

interface AggregationResult {
  result: AggregatedChartDataEntity[];
  strategy: AggregationStrategy;
}

@injectable()
export class DashboardChartUseCase implements IDashboardChartUseCase {
  @inject(DASHBOARD_CHART_TYPES.ChartRepository)
  private chartRepository: IDashboardChartRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private readonly permissionsRepository: IPermissionsRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  private weekBreakpoint = 30;
  private monthBreakpoint = 360;

  getData(filters: GetChartDataReq): Observable<ChartDataGroup> {
    return this.permissionsRepository.hasPermissions(Permission.CanAccessAllContact).pipe(
      switchMap((hasPermission) => {
        if (!hasPermission) {
          return this.accountRepository.getAccount().pipe(
            filter((account) => !!account),
            switchMap((account) => {
              const query = { ...filters, selectedUser: account.uuid };
              return this.chartRepository.getData(query).pipe(
                map((dataOriginal) => ({
                  dataOriginal,
                  filters: query,
                })),
              );
            }),
          );
        }

        return this.chartRepository.getData(filters).pipe(
          map((dataOriginal) => ({
            dataOriginal,
            filters,
          })),
        );
      }),
      map(({ dataOriginal, filters }) => {
        const totals = new ChartDataTotals(dataOriginal);
        const { result, strategy } = this.aggregate(dataOriginal, filters);

        return {
          data: result,
          totals,
          strategy,
        };
      }),
    );
  }

  private aggregate(
    data: ChartDataEntity[],
    filters: GetChartDataReq,
  ): AggregationResult {
    const { fromDate, toDate } = filters;
    const from = dayjs.unix(fromDate);
    const to = dayjs.unix(toDate);

    const strategy = this.selectStrategy({ from, to });

    const { result } = this.getAggregatorImplementation(strategy, data, from, to);

    return { result, strategy };
  }

  private getAggregatorImplementation(
    strategy: AggregationStrategy,
    dataOriginal: ChartDataEntity[],
    from: Dayjs,
    to: Dayjs,
  ): Aggregator {
    switch (strategy) {
      case AggregationStrategy.Daily:
        return new DailyAggregation(dataOriginal, from, to);
      case AggregationStrategy.Weekly:
        return new WeeklyAggregation(dataOriginal, from, to);
      default:
        return new MonthlyAggregation(dataOriginal, from, to);
    }
  }

  private selectStrategy({ from, to }: AggregationFiltersDto): AggregationStrategy {
    const diff = to.diff(from, 'days');

    if (diff <= this.weekBreakpoint) {
      return AggregationStrategy.Daily;
    }

    if (diff > this.weekBreakpoint && diff <= this.monthBreakpoint) {
      return AggregationStrategy.Weekly;
    }

    return AggregationStrategy.Monthly;
  }
}
