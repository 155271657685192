import { injectable } from 'inversify';

import { BaseSyncEntityType } from '../domain';

interface IResumeTokenItem {
  entityType: string;
  token: string;
  isConfirmed: boolean;
}

export interface IResumeTokenManager {
  confirm(token: string): void;
  draft(value: IResumeTokenItem): void;
  getLastConfirmedToken(): string | null;
  clear(): void;
}

export class DLLNode<T> {
  value: T;

  next: DLLNode<T> | null;
  prev: DLLNode<T> | null;

  constructor(value: T) {
    this.value = value;
    this.next = null;
    this.prev = null;
  }

  *prevIterator(): Generator<DLLNode<T>> {
    yield this;
    let prev = this.prev;

    while (prev) {
      yield prev;
      prev = prev.prev;
    }
  }

  *nextIterator(): Generator<DLLNode<T>> {
    yield this;
    let next = this.next;

    while (next) {
      yield next;
      next = next.next;
    }
  }
}

export class DoublyLinkedList<T, IndexKey extends keyof T = any> {
  // to improve lookup performance, we can index the list by a key
  private indexKey: IndexKey;
  private indexMap = new Map<T[IndexKey], DLLNode<T>>();

  head: DLLNode<T> | null;
  tail: DLLNode<T> | null;

  constructor(params: { indexKey: IndexKey }) {
    this.head = null;
    this.tail = null;
    this.indexKey = params.indexKey;
  }

  private getIndexKey(node: DLLNode<T>): T[IndexKey] {
    return node.value[this.indexKey];
  }

  private putIndex(node: DLLNode<T>): void {
    const key = this.getIndexKey(node);
    this.indexMap.set(key, node);
  }

  private removeIndex(node: DLLNode<T>): void {
    const key = this.getIndexKey(node);
    this.indexMap.delete(key);
  }

  findByIndex(key: T[IndexKey]): DLLNode<T> | null {
    return this.indexMap.get(key) ?? null;
  }

  findLast(predicate: (value: T, node: DLLNode<T>) => boolean): DLLNode<T> | null {
    for (const node of this.tailIterator()) {
      if (predicate(node.value, node)) {
        return node;
      }
    }

    return null;
  }

  findFirst(predicate: (value: T, node: DLLNode<T>) => boolean): DLLNode<T> | null {
    for (const node of this.headIterator()) {
      if (predicate(node.value, node)) {
        return node;
      }
    }

    return null;
  }

  addToHead(node: DLLNode<T>): void {
    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      node.next = this.head;
      this.head.prev = node;
      this.head = node;
    }

    this.putIndex(node);
  }

  addToTail(node: DLLNode<T>): void {
    if (!this.tail) {
      this.head = node;
      this.tail = node;
    } else {
      node.prev = this.tail;
      this.tail.next = node;
      this.tail = node;
    }

    this.putIndex(node);
  }

  remove(node: DLLNode<T>): void {
    if (node.prev) {
      node.prev.next = node.next;
    } else {
      this.head = node.next;
    }

    if (node.next) {
      node.next.prev = node.prev;
    } else {
      this.tail = node.prev;
    }

    // Clear references from the node being removed
    node.prev = null;
    node.next = null;

    this.removeIndex(node);
  }

  *[Symbol.iterator](): Generator<DLLNode<T>> {
    yield* this.headIterator();
  }

  *headIterator(): Generator<DLLNode<T>> {
    if (!this.head) return;

    yield* this.head.nextIterator();
  }

  *tailIterator(): Generator<DLLNode<T>> {
    if (!this.tail) return;

    yield* this.tail.prevIterator();
  }

  toJSON(): T[] {
    const result: T[] = [];

    for (const item of this) {
      result.push(item.value);
    }

    return result;
  }

  toString(): string {
    return JSON.stringify(this);
  }
}

const EntityTypesWhiteList = {
  [BaseSyncEntityType.Account]: true,
  [BaseSyncEntityType.ContactList]: true,
  [BaseSyncEntityType.Tag]: true,
  [BaseSyncEntityType.Workspace]: true,
  [BaseSyncEntityType.WorkspaceStat]: true,
  [BaseSyncEntityType.ProspectTask]: true,
  [BaseSyncEntityType.Invitation]: true,
};

const AutoConfirmedEntityTypesWhiteList = {
  [BaseSyncEntityType.ProspectTask]: true,
};

const KeyToStoreInPersistanceStorage = 'resumeTokens';

type ResumeTokenNode = DLLNode<IResumeTokenItem>;
type ResumeTokenList = DoublyLinkedList<IResumeTokenItem, 'token'>;

@injectable()
export class ResumeTokenManager implements IResumeTokenManager {
  private deserializeFromPersistanceStorage(): ResumeTokenList {
    const resumeTokensFromPersistanceStorage = localStorage.getItem(
      KeyToStoreInPersistanceStorage,
    );
    const linkedList: ResumeTokenList = new DoublyLinkedList({ indexKey: 'token' });

    try {
      if (resumeTokensFromPersistanceStorage) {
        const json: IResumeTokenItem[] = JSON.parse(
          resumeTokensFromPersistanceStorage,
        ).reverse();

        json.forEach((nodeValue) => {
          const newNode: ResumeTokenNode = new DLLNode(nodeValue);
          linkedList.addToHead(newNode);
        });
      }
    } catch {}

    return linkedList;
  }

  private serializeToPersistanceStorage(list: ResumeTokenList): void {
    localStorage.setItem(KeyToStoreInPersistanceStorage, list.toString());
  }

  private optimizeLinkedList(list: ResumeTokenList): void {
    const unconfirmedNode = list.findLast(({ isConfirmed }) => !isConfirmed);

    // TODO: we should refactor this cause internals of linked list should be hidden

    if (!unconfirmedNode) {
      if (list.head) {
        list.head.next = null;
        list.tail = list.head;
      }
      return;
    }

    const lastConfirmedNodeBeforeUnconfirmed = unconfirmedNode?.next;

    if (lastConfirmedNodeBeforeUnconfirmed) {
      lastConfirmedNodeBeforeUnconfirmed.next = null;
      list.tail = lastConfirmedNodeBeforeUnconfirmed;
    } else {
      unconfirmedNode.next = null;
      list.tail = unconfirmedNode;
    }
  }

  public draft(value: IResumeTokenItem): void {
    if (!EntityTypesWhiteList[value.entityType]) return;

    const list = this.deserializeFromPersistanceStorage();

    if (list.findByIndex(value.token)) return;

    const newNode: ResumeTokenNode = new DLLNode(value);

    list.addToHead(newNode);

    this.serializeToPersistanceStorage(list);

    if (AutoConfirmedEntityTypesWhiteList[value.entityType]) {
      this.confirm(value.token);
    }
  }

  public confirm(token: string): void {
    const list = this.deserializeFromPersistanceStorage();

    const nodeToUpdate = list.findByIndex(token);

    if (!nodeToUpdate) return;

    for (const nextNode of nodeToUpdate.nextIterator()) {
      if (nextNode.value.entityType === nodeToUpdate.value.entityType) {
        nextNode.value.isConfirmed = true;
      }
    }

    this.optimizeLinkedList(list);
    this.serializeToPersistanceStorage(list);
  }

  public getLastConfirmedToken(): string | null {
    const linkedList = this.deserializeFromPersistanceStorage();
    return linkedList.findLast(({ isConfirmed }) => isConfirmed)?.value.token ?? null;
  }

  public clear(): void {
    localStorage.removeItem(KeyToStoreInPersistanceStorage);
  }
}
