import { inject, injectable } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { BillingCycle, type IBillingUseCase, PlanType } from '@/features/common/billing';
import type { ISubscriptionUseCase } from '@/features/common/workspace';

import type { IPlansUseCase } from './abstractions/IPlansUseCase';
import type { GroupedProductMetadata } from './entities/GroupedProductMetadata';
import { ExpandProductMetadataBuilder } from './ProductMetadataBuilder/ExpandProductMetadataBuilder';
import { FreeProductMetadataBuilder } from './ProductMetadataBuilder/FreeProductMetadataBuilder';
import { ProProductMetadataBuilder } from './ProductMetadataBuilder/ProProductMetadataBuilder';
import { UnlimitedProductMetadataBuilder } from './ProductMetadataBuilder/UnlimitedProductMetadataBuilder';

@injectable()
export class PlansUseCase implements IPlansUseCase {
  @inject(BILLING_TYPES.BillingUseCase)
  private billingUseCase: IBillingUseCase;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  getGroupedProductMetadata(params?: {
    billingCycle: BillingCycle;
    seats: number;
  }): Observable<GroupedProductMetadata> {
    const seats = params?.seats ?? 1;
    const billingCycle = params?.billingCycle ?? BillingCycle.Monthly;
    return combineLatest({
      products: this.billingUseCase.getProducts(),
      subscription: this.subscriptionUseCase.getSubscription(),
    }).pipe(
      map(({ products, subscription }) => ({
        [PlanType.Free]: new FreeProductMetadataBuilder(
          products.filter((p) => p.family === PlanType.Free),
          subscription,
          seats,
          billingCycle,
        ).build(),
        [PlanType.Pro]: new ProProductMetadataBuilder(
          products.filter((p) => p.family === PlanType.Pro),
          subscription,
          seats,
          billingCycle,
        ).build(),
        [PlanType.Unlimited]: new UnlimitedProductMetadataBuilder(
          products.filter((p) => p.family === PlanType.Unlimited),
          subscription,
          seats,
          billingCycle,
        ).build(),
        [PlanType.Expand]: new ExpandProductMetadataBuilder(
          [],
          subscription,
          seats,
          billingCycle,
        ).build(),
      })),
    );
  }
}
