import { useTranslation } from 'react-i18next';
import { GridEventListener } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

export const useProcessCellEditStop = (): GridEventListener<'cellEditStop'> => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('lists');

  return (_, event) => {
    // @ts-ignore
    if (event.target.ariaInvalid) {
      // @ts-ignore
      switch (event.target.getAttribute('aria-label')) {
        case 'alreadyExists':
          enqueueSnackbar(t('subhead.newList.form.errors.alreadyExists'), {
            variant: 'error',
          });
          break;
      }
    }
  };
};
