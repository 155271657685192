import { injectable } from 'inversify';
import {
  BehaviorSubject,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';

import { IOnlineTrackerRepository } from './abstraction';

@injectable()
export class OnlineTrackerRepository implements IOnlineTrackerRepository {
  private isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.listenToConnection().subscribe((isOnline) => {
      this.isOnline$.next(isOnline);
    });
  }

  private listenToConnection(): Observable<boolean> {
    return merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
      startWith(null),
      map(() => window.navigator.onLine),
      switchMap((isOnline) => {
        if (isOnline) return of(true);
        return this.checkConnection();
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  private checkConnection(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const abortController = new AbortController();
      fetch(`/1pixel.png`, {
        headers: {
          'cache-control': 'no-cache',
        },
        signal: abortController.signal,
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            subscriber.next(true);
          } else {
            subscriber.next(false);
          }
        })
        .catch(() => {
          subscriber.next(false);
        })
        .finally(() => {
          subscriber.complete();
        });

      return () => {
        abortController.abort();
      };
    });
  }

  get isOnline(): boolean {
    return this.isOnline$.getValue();
  }

  getIsOnline(): Observable<boolean> {
    return this.isOnline$.asObservable();
  }
}
