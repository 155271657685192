import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

type OverlayProps = {
  title: string;
  description: ReactNode;
  footer: ReactNode;
};

const overlayRoot = document.getElementById('overlay-root');

const Lock = () => {
  return (
    <div className={styles.lock}>
      <div className={styles.lockVertical} />
      <div className={styles.lockHorizontal} />
    </div>
  );
};

export const OverlayLayout: FC<OverlayProps> = ({ title, description, footer }) => {
  if (!overlayRoot) {
    return null;
  }

  return createPortal(
    <Box className={styles.overlay}>
      <Box className={styles.overlayContent}>
        <Lock />
        <Typography variant="h1" className={styles.overlayTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={styles.overlayDescription}>
          {description}
        </Typography>
        <Box className={styles.overlayFooter}>{footer}</Box>
      </Box>
    </Box>,
    overlayRoot,
  );
};
