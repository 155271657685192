import { useMemo } from 'react';

// Converting data to percentage is used to
// improve pie slice size for small values.

const MIN_PERCENT = 5;

export const useChartProcessedData = (raw: number[]) => {
  return useMemo(() => {
    const total = raw.reduce((acc, value) => acc + value, 0);

    return raw.map((value) => {
      if (value === 0) return 0;

      const percent = Math.max(value / total, 0.01) * 100;

      return percent < MIN_PERCENT ? MIN_PERCENT : percent;
    });
  }, [raw]);
};
