import { FC, useState } from 'react';
import { Box, Menu } from '@mui/material';

import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { MenuItem } from '@/components';
import { ArrowDownIcon, LinkedinIcon } from '@/components/Icons';

import { getSocialItemByUrl, SocialItemResult } from '../../../../utils';

import styles from './styles.module.scss';

type SocialLinksMenuProp = {
  links: string[];
};

export const SocialLinksMenu: FC<SocialLinksMenuProp> = ({ links }) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement>();
  const { trackEvent } = useAnalytics();
  const handleButtonClick = (e): void => {
    setMenuAnchorElement(e.currentTarget);
  };
  const handleClose = (): void => {
    setMenuAnchorElement(undefined);
  };
  const handleClickMenuItem = (link: string): void => {
    window.open(link);
  };
  const menuItems = links
    .map((item) => getSocialItemByUrl(item))
    .filter((item) => Boolean(item)) as SocialItemResult[];

  return (
    <>
      <Box className={styles.button} onClick={handleButtonClick}>
        <Box className={styles.buttonSocialIcon}>
          <LinkedinIcon />
        </Box>
        <Box className={styles.buttonArrowIcon}>
          <ArrowDownIcon />
        </Box>
      </Box>
      <Menu
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={handleClose}
      >
        {menuItems.map(({ url, Icon, name }) => {
          return (
            <MenuItem
              className={styles.menuItem}
              key={url}
              onClick={(): void => {
                trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_SOCIAL_ACTION);
                handleClickMenuItem(url);
              }}
            >
              <Icon className={styles.menuItemIcon} />
              <Box component="span">{name}</Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
