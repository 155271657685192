import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FeatureIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 13.997 13.997">
    <defs>
      <clipPath id="orub3hnk1a">
        <path
          d="M0-682.665h14v14H0z"
          transform="translate(0 682.665)"
          style={{ fill: '#8083a3' }}
        />
      </clipPath>
    </defs>
    <g style={{ clipPath: 'url(#orub3hnk1a)' }}>
      <path
        d="M-20.824-300a4.357 4.357 0 0 1-.436-1.906 4.374 4.374 0 0 1 4.374-4.374 4.374 4.374 0 0 1 4.374 4.374 4.359 4.359 0 0 1-1.194 3 3.589 3.589 0 0 0-.993 2.464 1.094 1.094 0 0 1-1.093 1.094h-2.187a1.094 1.094 0 0 1-1.093-1.094 3.589 3.589 0 0 0-.993-2.464 4.382 4.382 0 0 1-.759-1.094z"
        transform="translate(23.884 306.83)"
        style={{
          fill: 'none',
          stroke: '#8083a3',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
      />
      <path
        d="M0-26.12a.547.547 0 0 1 .547-.547.547.547 0 0 1 .547.547.547.547 0 0 1-.547.547A.547.547 0 0 1 0-26.12"
        transform="translate(6.452 33.775)"
        style={{ fill: '#8083a3' }}
      />
      <path
        d="M0 0h2.187"
        style={{
          fill: 'none',
          stroke: '#8083a3',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        transform="translate(5.905 13.45)"
      />
      <path
        d="M0-52.24a1.094 1.094 0 0 1 1.093-1.093 1.094 1.094 0 0 1 1.094 1.093 1.093 1.093 0 0 1-.585.968 1.026 1.026 0 0 0-.508.92"
        transform="translate(5.905 56.067)"
        style={{
          fill: 'none',
          stroke: '#8083a3',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
      />
    </g>
  </SvgIcon>
);
