import type { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    paper: () => {
      return {
        boxShadow: '10px 10px 40px 0 #d8dfea',
        margin: '5px 0',
      };
    },
    list: {
      padding: '10px',
    },
  },
};
