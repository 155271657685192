import type { Components, Theme } from '@mui/material';

import { RemoveIcon } from '@/components/Icons';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    deleteIcon: <RemoveIcon />,
  },
  variants: [
    {
      props: { variant: 'default' },
      style: ({ theme }) => ({
        border: 'none',
        borderRadius: '4px',
        height: '30px',
        backgroundColor: theme.palette.info.disabled,
        color: '#000',
        marginRight: '5px',
        '.MuiChip-deleteIcon': {
          color: 'inherit',
          '&:hover': {
            color: 'inherit',
          },
        },
        '.MuiChip-label': {
          padding: '0 8px 0 5px',
        },
      }),
    },
    {
      props: { variant: 'error' },
      style: ({ theme }) => ({
        border: 'none',
        borderRadius: '4px',
        height: '30px',
        backgroundColor: '#fde8ea',
        color: theme.palette.error.main,
        marginRight: '5px',
        '.MuiChip-deleteIcon': {
          color: 'inherit',
          '&:hover': {
            color: 'inherit',
          },
        },
        '.MuiChip-label': {
          padding: '0 8px 0 5px',
        },
      }),
    },

    {
      props: { variant: 'warning' },
      style: ({ theme }) => ({
        border: 'none',
        borderRadius: '4px',
        height: '30px',
        backgroundColor: theme.palette.primary.disabled,
        color: theme.palette.primary.main,
        marginRight: '5px',
        '.MuiChip-deleteIcon': {
          color: 'inherit',
          '&:hover': {
            color: 'inherit',
          },
        },
        '.MuiChip-label': {
          padding: '0 8px 0 5px',
        },
      }),
    },
  ],
};
