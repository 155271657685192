import type { IWorkspaceDC, IWorkspaceEntity } from '@/features/common/workspace';
import {
  mapWorkspaceSubscriptionDcToEntity,
  mapWorkspaceSubscriptionEntityToDc,
} from '@/features/common/workspace/data/mappers/mapWorkspaceSubscription';
import { mapTeamMemberDcToEntity, mapTeamMemberEntityToDc } from '@/features/settings';

export const mapWorkspaceDcToEntity = (dc: IWorkspaceDC): IWorkspaceEntity => {
  return {
    uuid: dc.uuid,
    name: dc.name,
    memberAccessAllContacts: dc.member_access_all_contacts,
    membersCount: dc.members_count,
    approvedMembersCount: dc.approved_members_count,
    billableMembersCount: dc.billable_members_count,
    updatedAt: dc.updated_at,
    createdAt: dc.created_at,
    lastLoginAt: dc.last_login_at,
    totalSearchCount: dc.total_search_count,
    totalCreditsUsed: dc.total_credits_used,
    currentSearchCount: dc.current_search_count,
    currentCreditsUsed: dc.current_credits_used,
    ownerEmail: dc.owner_email,
    ownerFullName: dc.owner_full_name,
    members: dc.members.map(mapTeamMemberDcToEntity),
    subscription: mapWorkspaceSubscriptionDcToEntity(dc.subscription),
    permissions: dc.permissions,
  };
};

export const mapWorkspaceEntityToDc = (entity: IWorkspaceEntity): IWorkspaceDC => ({
  uuid: entity.uuid,
  name: entity.name,
  member_access_all_contacts: entity.memberAccessAllContacts,
  members_count: entity.membersCount,
  approved_members_count: entity.approvedMembersCount,
  billable_members_count: entity.billableMembersCount,
  updated_at: entity.updatedAt,
  created_at: entity.createdAt,
  last_login_at: entity.lastLoginAt,
  total_search_count: entity.totalSearchCount,
  total_credits_used: entity.totalCreditsUsed,
  current_search_count: entity.currentSearchCount,
  current_credits_used: entity.currentCreditsUsed,
  owner_email: entity.ownerEmail,
  owner_full_name: entity.ownerFullName,
  members: entity.members.map(mapTeamMemberEntityToDc),
  subscription: mapWorkspaceSubscriptionEntityToDc(entity.subscription),
  permissions: entity.permissions,
});

export const mapWorkspaceEntityPropertiesToDcProperties = (entityProperties: {
  memberAccessAllContacts: IWorkspaceEntity['memberAccessAllContacts'];
}): { member_access_all_contacts: IWorkspaceDC['member_access_all_contacts'] } => ({
  member_access_all_contacts: entityProperties.memberAccessAllContacts,
});
