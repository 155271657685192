import React, { forwardRef, ReactElement } from 'react';

import { usePermissions } from '../hooks/usePermissions';

export const ActionDisableGuard = forwardRef<unknown, { children: ReactElement }>(
  ({ children, ...props }, ref) => {
    const { isSuperAdmin } = usePermissions();

    return React.cloneElement(children, {
      ...props,
      disabled: isSuperAdmin || (children.props.disabled ?? false),
      ref: ref,
    });
  },
);
