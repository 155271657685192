import { IIntegrationEntity } from '@/features/integration/domain/entities';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { useIntegrationUseCase } from './useIntegrationUseCase';

export const useCurrentIntegration = (
  provider: string,
): ObservableResult<IIntegrationEntity> => {
  const integrationsUseCases = useIntegrationUseCase();

  return useObservableResult(() => integrationsUseCases.getIntegration(provider), {
    deps: [provider],
  });
};
