import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const Title: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant={'h1'} className={styles.title}>
      {children}
    </Typography>
  );
};
