import { injectable } from 'inversify';

import { IHubspotFormSubmissionDC } from '../dataContracts';

export interface IHubspotApiService {
  sendClientSetupForm(formData: IHubspotFormSubmissionDC): Promise<Response>;
}

const HUBSPOT_FORM_SUBMISSION_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/';

@injectable()
export class HubspotApiService implements IHubspotApiService {
  sendClientSetupForm(data: IHubspotFormSubmissionDC): Promise<Response> {
    const { href } = new URL(
      import.meta.env.REACT_APP_HUBSPOT_ACCOUNT_REGISTERED_FORM,
      HUBSPOT_FORM_SUBMISSION_URL,
    );

    return fetch(href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
}
