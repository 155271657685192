import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { ITagEntity, ITagUseCase } from '@/features/common/tag';

import { ObservableResult, useObservableResult } from '@/utils/rx';

export const useTagEntities = (params?: {
  uuidIncludes?: string[];
  nameRegex?: string;
}): ObservableResult<ITagEntity[], unknown, ITagEntity[]> => {
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);

  return useObservableResult(() => tagUseCase.getTags(params), {
    defaultData: [],
    deps: [params?.nameRegex, params?.uuidIncludes],
  });
};
