import type { Components, Theme } from '@mui/material';

export const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: () => {
      return {
        borderColor: '#e4e6e8',
      };
    },
  },
};
