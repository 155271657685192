import { FC } from 'react';

import { IAccountEntity } from '@/features/common/account';
import { TeamMemberSelect } from '@/features/settings';

type AccountID = IAccountEntity['uuid'];

type MemberSelectProps = {
  member: AccountID;
  onMemberChange: (member: AccountID) => void;
};

export const MemberSelect: FC<MemberSelectProps> = ({ member, onMemberChange }) => {
  return <TeamMemberSelect value={member} onChange={onMemberChange} />;
};
