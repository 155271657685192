import { AuthIdentityProvider } from '@/features/common/auth';
import { AnalyticsProvider } from '@/features/system/analytics';

export const mapIdentityProviderToAnalyticsProvider = (
  provider: AuthIdentityProvider,
): AnalyticsProvider => {
  switch (provider) {
    case AuthIdentityProvider.google:
      return AnalyticsProvider.Google;
    case AuthIdentityProvider.microsoft:
      return AnalyticsProvider.Microsoft;
    default:
      return AnalyticsProvider.Email;
  }
};
