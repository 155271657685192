import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { OnboardingStep, OnboardingStepStatus } from '../../domain/types';

import { useOnboardingUseCase } from './useOnboardingUseCase';

export const useOnboardingStepState = (
  step: OnboardingStep,
): ObservableResult<OnboardingStepStatus, unknown, OnboardingStepStatus> => {
  const onboardingUseCase = useOnboardingUseCase();
  return useObservableResult(() => onboardingUseCase.getStepStatus(step), {
    deps: [step],
    defaultData: 'incompleted',
  });
};
