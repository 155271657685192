import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';

// @ts-ignore
import LightFont from '@/assets/fonts/SofiaProLight.otf';
// @ts-ignore
import MediumFont from '@/assets/fonts/SofiaProRegular.otf';

import { useStripeInstance } from './useStripeInstance';

export const useStripeProviderSetup = () => {
  const { t } = useTranslation('common');
  const stripeInstance = useStripeInstance();
  const { enqueueSnackbar } = useSnackbar();

  const options: StripeElementsOptions = useMemo(() => {
    return {
      fonts: [
        {
          family: 'SofiaPro',
          src: `url('${window.location.origin}${LightFont}') format('opentype')`,
          weight: '400',
        },
        {
          family: 'SofiaPro',
          src: `url('${window.location.origin}${MediumFont}') format('opentype')`,
          weight: '500',
        },
      ],
    };
  }, []);

  useEffect(() => {
    if (stripeInstance.hasError) {
      enqueueSnackbar(t('stripe.failedToInitialize'), {
        variant: 'error',
      });
    }
  }, [stripeInstance.hasError]);

  return {
    options,
    stripe: stripeInstance.data,
  };
};
