import { FC, forwardRef } from 'react';

import { CustomizedAlert, CustomizedAlertProps } from './CustomizedAlert';

type WarningAlertProps = CustomizedAlertProps;

export const WarningAlert: FC<WarningAlertProps> = forwardRef<
  HTMLDivElement,
  WarningAlertProps
>((props, ref) => {
  return <CustomizedAlert ref={ref} {...props} variant={'warning'} />;
});

WarningAlert.displayName = 'WarningAlert';
