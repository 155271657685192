import * as yup from 'yup';

export const PasswordValidationSchema = yup
  .string()
  .min(8, 'passwordMin')
  .required('passwordRequired')
  .test('containUpperCase', 'passwordContainUppercase', (value) => /[A-Z]/.test(value))
  .test('containLowerCase', 'passwordContainLowercase', (value) => /[a-z]/.test(value))
  .test('containNumber', 'passwordContainNumber', (value) => /\d/.test(value));

export const getConfirmPasswordValidationSchema = (
  refField: string,
): yup.StringSchema => {
  return yup
    .string()
    .required('confirmPasswordRequired')
    .oneOf([yup.ref(refField)], 'passwordMismatch');
};
