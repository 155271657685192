import { AuthIdentityProvider } from '@/features/common/auth';
import { AnalyticsProvider } from '@/features/system/analytics';

export const mapAuthToAnalyticsIdentityProvider = (
  authProvider: AuthIdentityProvider,
): AnalyticsProvider => {
  const map = {
    [AuthIdentityProvider.google]: AnalyticsProvider.Google,
    [AuthIdentityProvider.microsoft]: AnalyticsProvider.Microsoft,
    [AuthIdentityProvider.password]: AnalyticsProvider.Email,
  };

  return map[authProvider];
};
