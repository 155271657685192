import React from 'react';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

type Props = {
  text: string;
  userAvatar: string;
  userName: string;
  userPosition: string;
};

export const Testimonial: React.FC<Props> = ({
  userAvatar,
  text,
  userName,
  userPosition,
}) => {
  return (
    <Box className={styles.wrapper}>
      <img src={userAvatar} alt={userName} className={styles.avatar} />
      <Box className={styles.textWrapper}>
        <Typography className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
        <Typography fontSize="18px">{userName}</Typography>
        <Typography fontSize="13px">{userPosition}</Typography>
      </Box>
    </Box>
  );
};
