import { FC, useEffect } from 'react';

import { useAppLogger } from '@/features/system/logger';

import { useReplicationUseCase } from '../../hooks';

export const Replication: FC = () => {
  const replicationUseCase = useReplicationUseCase();
  const appLogger = useAppLogger();

  useEffect(() => {
    const subscription = replicationUseCase.start().subscribe({
      error: (error) => {
        appLogger.error(error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
};
