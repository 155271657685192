import { inject, injectable } from 'inversify';
import {
  catchError,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';

import { BILLING_TYPES } from '@/ioc/types';

import { NetworkError } from '@/features/system/network';

import {
  type IBillingDetailsEntity,
  type IBillingInvoiceEntity,
  type IPaymentMethodEntity,
  type IPaymentMethodUpdateSessionEntity,
  type IProductEntity,
  type IUpcomingInvoiceEntity,
} from '../domain';

import type { IBillingApiService, IBillingRepository } from './abstractions';
import type { IBillingDetailsDC } from './dataContracts';
import type { IBillingState } from './db';
import {
  mapBillingDetailsDcToEntity,
  mapBillingDetailsEntityToDc,
  mapBillingInvoiceDcToEntity,
  mapPaymentMethodDCtoEntity,
  mapPaymentMethodUpdateSessionDcToEntity,
  mapProductDcToEntity,
} from './mappers';

@injectable()
export class BillingRepository implements IBillingRepository {
  @inject(BILLING_TYPES.BillingApiService)
  private readonly billingApiService: IBillingApiService;

  @inject(BILLING_TYPES.BillingState)
  private readonly billingState: IBillingState;

  private getLocalBillingDetails(): Observable<IBillingDetailsEntity> {
    return this.billingState.get$('billingDetails').pipe(
      filter((value) => !!value),
      map(mapBillingDetailsDcToEntity),
    );
  }

  private async getRemoteBillingDetails(): Promise<IBillingDetailsDC> {
    const response = await firstValueFrom(this.billingApiService.getBillingInfo());

    await this.billingState.set('billingDetails', () => {
      return response;
    });

    return response;
  }

  getBillingDetails(): Observable<IBillingDetailsEntity> {
    return from(this.getRemoteBillingDetails()).pipe(
      switchMap(() => this.getLocalBillingDetails()),
    );
  }

  async updateBillingDetails(
    details: IBillingDetailsEntity,
  ): Promise<IBillingDetailsEntity> {
    const detailsDC = await firstValueFrom(
      this.billingApiService.updateBillingInfo(mapBillingDetailsEntityToDc(details)),
    );

    await this.billingState.set('billingDetails', () => {
      return detailsDC;
    });

    return mapBillingDetailsDcToEntity(detailsDC);
  }

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.billingApiService.getInvoices().pipe(
      filter((value) => !!value),
      map((invoices) => invoices.map(mapBillingInvoiceDcToEntity)),
    );
  }

  getPaymentMethod(): Observable<Nullable<IPaymentMethodEntity>> {
    return this.billingApiService.getPaymentMethod().pipe(
      map(mapPaymentMethodDCtoEntity),
      catchError((e) => {
        if (e instanceof NetworkError && e.statusCode === 404) {
          return of(null);
        }

        return throwError(() => e);
      }),
    );
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Promise<IPaymentMethodUpdateSessionEntity> {
    return firstValueFrom(
      this.billingApiService
        .updatePaymentMethod(params)
        .pipe(map(mapPaymentMethodUpdateSessionDcToEntity)),
    );
  }

  getUpcomingInvoice(params: {
    plan: string;
    quantity?: number;
    promoCode?: string;
  }): Observable<IUpcomingInvoiceEntity> {
    return this.billingApiService.getUpcomingInvoice(params);
  }

  getProducts(): Observable<IProductEntity[]> {
    return this.billingApiService
      .getProducts()
      .pipe(map((products) => products.map(mapProductDcToEntity)));
  }

  getProduct(id: string): Observable<IProductEntity> {
    return this.billingApiService.getProduct(id).pipe(map(mapProductDcToEntity));
  }
}
