import { createContext } from 'react';

import { PlanFeatureCategory, PlanFeaturesMetadata } from '../types';

const defaultMetadata: PlanFeaturesMetadata[PlanFeatureCategory] = {
  title: '',
  icon: '',
  features: [],
};

export const PlanFeaturesMetadataContext = createContext<PlanFeaturesMetadata>({
  [PlanFeatureCategory.General]: defaultMetadata,
  [PlanFeatureCategory.ContactManagement]: defaultMetadata,
  [PlanFeatureCategory.CrmIntegrations]: defaultMetadata,
  [PlanFeatureCategory.Analytics]: defaultMetadata,
  [PlanFeatureCategory.Compliance]: defaultMetadata,
  [PlanFeatureCategory.Support]: defaultMetadata,
  [PlanFeatureCategory.SSO]: defaultMetadata,
});
