import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, OutlinedInput, Popover } from '@mui/material';

import { FormField } from '@/components';

import { useCreateNewListForm } from './hooks';

import styles from './styles.module.scss';

const CreateNewListForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('lists');
  const { form, handleFormSubmit } = useCreateNewListForm(onClose);
  const listName = form.watch('listName');
  const errorKey = form.formState.errors.listName?.message;

  return (
    <form className={styles.newListForm} onSubmit={handleFormSubmit}>
      <FormField
        error={!!errorKey}
        errorText={t(`subhead.newList.form.errors.${errorKey}`)}
      >
        <OutlinedInput
          {...form.register('listName')}
          placeholder={t('subhead.newList.form.nameField.placeholder')}
          autoFocus
          onChange={(event): void => {
            form.setValue(
              'listName',
              event.target.value.replace(/[^\p{L}\p{N}\s_-]/gu, '').trimStart(),
            );
          }}
        />
      </FormField>
      <Box className={styles.newListFormActions}>
        <Button color="info" onClick={onClose}>
          {t('subhead.newList.form.cancel')}
        </Button>
        <Button color="primary" type="submit" disabled={!listName}>
          {t('subhead.newList.form.save')}
        </Button>
      </Box>
    </form>
  );
};

type CreateNewListPopoverProps = {
  isOpen: boolean;
  anchorEl: Element | undefined;
  onClose: () => void;
};

export const CreateNewListPopover: FC<CreateNewListPopoverProps> = ({
  isOpen,
  anchorEl,
  onClose,
}) => {
  return (
    <Popover
      open={isOpen}
      classes={{
        paper: styles.wrapper,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorPosition={{
        left: 0,
        top: 6,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <CreateNewListForm onClose={onClose} />
    </Popover>
  );
};
