import * as React from "react";
const SvgNoWifi = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 66, height: 66, viewBox: "0 0 66 66", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18934" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Ellipse 1578", transform: "translate(5 5)", style: {
  fill: "#fff",
  stroke: "#ff4757",
  strokeWidth: 5
} }, /* @__PURE__ */ React.createElement("circle", { cx: 28, cy: 28, r: 28, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 28, cy: 28, r: 30.5, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("path", { d: "m25.311-822.625-12.047-12.111a11.179 11.179 0 0 0-2.8 1.057 9.592 9.592 0 0 0-2.323 1.762l-2.691-2.755a13.554 13.554 0 0 1 2.211-1.794 16.8 16.8 0 0 1 2.531-1.346L7.3-840.7a19.172 19.172 0 0 0-2.451 1.49 17.481 17.481 0 0 0-2.163 1.842L0-840.118a18.936 18.936 0 0 1 2.131-1.842 27.266 27.266 0 0 1 2.355-1.554l-2.691-2.691L3.588-848l23.581 23.581zm-9.932-2.051a3.067 3.067 0 0 1-2.275-.945 3.11 3.11 0 0 1-.929-2.259 3.094 3.094 0 0 1 .929-2.275 3.093 3.093 0 0 1 2.275-.929 3.093 3.093 0 0 1 2.275.929 3.094 3.094 0 0 1 .929 2.275 3.11 3.11 0 0 1-.929 2.259 3.067 3.067 0 0 1-2.275.945zm7.561-7.624-.929-.929-.929-.929-4.614-4.614a14.79 14.79 0 0 1 4.854 1.314 13.965 13.965 0 0 1 3.989 2.787zm5.126-5.062a18.079 18.079 0 0 0-5.719-3.861 17.5 17.5 0 0 0-6.969-1.394q-.673 0-1.3.048t-1.266.144l-3.268-3.268a21.652 21.652 0 0 1 2.868-.577 22.643 22.643 0 0 1 2.964-.192 21.224 21.224 0 0 1 8.49 1.7 21.886 21.886 0 0 1 6.888 4.646z", transform: "translate(17.621 868.313)", style: {
  fill: "#ff4757"
} })));
export default SvgNoWifi;
