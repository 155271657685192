import { useInjection } from '@/ioc/ioc.react';
import { FEATURE_FLAG_TYPES } from '@/ioc/types';

import { IFeatureFlagManager } from '../../FeatureFlagManager';

type UseFeatureFlagResult = {
  isFeatureEnabled: boolean;
};

export const useFeatureFlag = (params: { feature: string }): UseFeatureFlagResult => {
  const featureFlagManager = useInjection<IFeatureFlagManager>(
    FEATURE_FLAG_TYPES.FeatureFlagManager,
  );
  const isFeatureEnabled = featureFlagManager.isFeatureEnabled(params.feature);

  return {
    isFeatureEnabled,
  };
};
