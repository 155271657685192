import { Container } from 'inversify';

import { APP_LOGGER_TYPES } from '@/ioc/types';

import { AppLogger } from '../data';
import { IAppLogger } from '../domain';

export const bindLoggerModule = (container: Container): void => {
  container.bind<IAppLogger>(APP_LOGGER_TYPES.AppLogger).to(AppLogger);
};
