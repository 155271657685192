import { FC } from 'react';
import { Components, Stack, Theme, useTheme } from '@mui/material';

import { CancelIcon, CheckCircleIcon } from '@/components/Icons';

import WarningRounded from '@/assets/icons/warningRounded.svg?react';

export const WarningIcon: FC = () => {
  const theme = useTheme();

  return (
    <Stack
      borderRadius="15px"
      bgcolor={theme.palette.error.main}
      width="30px"
      height="30px"
      alignItems="center"
      justifyContent="center"
    >
      <WarningRounded
        style={{
          color: 'white',
        }}
      />
    </Stack>
  );
};

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      success: <CheckCircleIcon width="30px" height="30px" />,
      error: <CancelIcon width="30px" height="30px" color="error" />,
      warning: <WarningIcon />,
    },
  },
  styleOverrides: {
    root: () => {
      return {
        backgroundColor: 'white',
        alignItems: 'center',
        borderRadius: 6,
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.16)',
        minWidth: '400px',
      };
    },
  },
};
