import { RxJsonSchema } from 'rxdb';

import { IContactByFiltersDC } from '../../dataContracts';

export const contactByFiltersSchema: RxJsonSchema<IContactByFiltersDC> = {
  title: 'contactByFilters schema',
  version: 1,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 1000 },
    contact_by_filters_ids_current: { type: 'array' },
    contact_by_filters_ids_latest: { type: 'array' },
    company_by_filters_ids_current: { type: 'array' },
    company_by_filters_ids_latest: { type: 'array' },
    created_at: { type: 'number' },
    updated_at: { type: 'number' },
    ping_at: { type: 'number' },
    count_current: { type: 'number' },
    count_latest: { type: 'number' },
    contact_info_count_current: { type: 'object' },
    contact_info_count_latest: { type: 'object' },
  },
};
