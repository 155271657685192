import * as React from "react";
const SvgChromeIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 58, height: 58, viewBox: "0 0 58 58", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "jxcsw9p7ya" }, /* @__PURE__ */ React.createElement("path", { d: "M0-682.665h34.973v34.973H0z", transform: "translate(0 682.665)", style: {
  fill: "#2173ff"
} }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 19006", transform: "translate(-692 -254)" }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 19373", width: 58, height: 58, rx: 12, transform: "translate(692 254)", style: {
  fill: "#ffece4"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#jxcsw9p7ya)"
}, transform: "translate(703.77 265.308)" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0h14.981", style: {
  fill: "none",
  stroke: "#ff5001",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeWidth: 2
}, transform: "translate(17.486 10.656)" }), /* @__PURE__ */ React.createElement("path", { d: "m-137.867 0-7.444 12.894", transform: "translate(161.225 20.979)", style: {
  fill: "none",
  stroke: "#ff5001",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("path", { d: "m-137.793-238.662-7.44-12.887", transform: "translate(149.314 259.479)", style: {
  fill: "none",
  stroke: "#ff5001",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("path", { d: "M-126.5-253a6.831 6.831 0 0 1-6.831-6.831 6.831 6.831 0 0 1 6.831-6.831 6.831 6.831 0 0 1 6.831 6.831A6.831 6.831 0 0 1-126.5-253z", transform: "translate(143.989 277.322)", style: {
  fill: "none",
  stroke: "#ff5001",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("path", { d: "M-304.871-431.373a6.831 6.831 0 0 1-6.829-6.827 6.831 6.831 0 0 1 6.831-6.831 6.831 6.831 0 0 1 6.831 6.831 6.831 6.831 0 0 1-6.833 6.827zm0-23.292a16.462 16.462 0 0 0-16.462 16.465 16.462 16.462 0 0 0 16.462 16.462 16.462 16.462 0 0 0 16.462-16.462 16.462 16.462 0 0 0-16.462-16.466z", transform: "translate(322.358 455.691)", style: {
  fill: "none",
  stroke: "#ff5001",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeWidth: 2
} }))));
export default SvgChromeIcon;
