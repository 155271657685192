import React from 'react';

import styles from './styles.module.scss';

const SplashScreen: React.FC = () => {
  return (
    <div className={styles.Wrapper}>
      <iframe src="https://lottie.host/?file=91e1be87-7b0f-4759-bdb7-5b607fd1c5c8/LX0zRhEWiA.json"></iframe>
    </div>
  );
};

export default SplashScreen;
