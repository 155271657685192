import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { unix } from 'dayjs';

import {
  CompanyCell,
  ContactInfoCell,
  ContactOwnerCell,
  HeaderCell,
  IndustryCell,
  JobTitleCell,
  ListCell,
  TagsCell,
} from '@/features/common/contact';

import { CellBasePreview, Checkbox } from '@/components';

export const useTableColumns = (isLoading?: boolean) => {
  const { t } = useTranslation('contactsTable');
  if (isLoading)
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 40,
        minWidth: 40,
        renderHeader: () => <Checkbox disabled />,
        renderCell: () => <Checkbox disabled />,
      },
      {
        headerName: t('columns.fullName'),
        field: 'name',
        sortable: false,
        minWidth: 170,
        flex: 1,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview />,
      },
      {
        field: 'contactInfo',
        sortable: false,
        minWidth: 270,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <ContactInfoCell.Preview />,
      },
      {
        headerName: t('columns.jobTitle'),
        field: 'jobTitle',
        sortable: false,
        minWidth: 170,
        flex: 1,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview variant="multiple" />,
      },
      {
        headerName: t('columns.company'),
        field: 'company',
        sortable: false,
        minWidth: 150,
        flex: 1,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview variant="multiple" />,
      },
      {
        headerName: t('columns.industry'),
        field: 'industry',
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview variant="multiple" />,
      },
      {
        headerName: t('columns.tags'),
        field: 'tags',
        sortable: false,
        minWidth: 180,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview />,
      },
      {
        headerName: t('columns.date'),
        field: 'date',
        sortable: false,
        minWidth: 180,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview />,
      },
      {
        headerName: t('columns.contactOwner'),
        field: 'contactOwner',
        sortable: false,
        minWidth: 180,
        maxWidth: 230,
        flex: 1,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <ContactOwnerCell.Preview />,
      },
      {
        headerName: t('columns.list'),
        field: 'contactListId',
        sortable: false,
        minWidth: 180,
        renderHeader: () => <HeaderCell.Preview />,
        renderCell: () => <CellBasePreview />,
      },
    ];

  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      minWidth: 40,
    },
    {
      headerName: t('columns.fullName'),
      field: 'name',
      sortable: true,
      minWidth: 170,
      flex: 1,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell: (params: GridRenderCellParams) => params.row.name,
    },
    {
      headerName: t('columns.contactInfo'),
      field: 'contactInfo',
      sortable: false,
      minWidth: 270,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <ContactInfoCell {...params} />;
      },
    },
    {
      headerName: t('columns.jobTitle'),
      field: 'jobTitle',
      sortable: true,
      minWidth: 170,
      flex: 1,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <JobTitleCell {...params} />;
      },
    },
    {
      headerName: t('columns.company'),
      field: 'company',
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <CompanyCell {...params} />;
      },
    },
    {
      headerName: t('columns.industry'),
      field: 'industry',
      sortable: true,
      minWidth: 180,
      flex: 1,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params): ReactElement {
        return <IndustryCell {...params} />;
      },
    },
    {
      headerName: t('columns.tags'),
      field: 'tags',
      sortable: false,
      minWidth: 180,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <TagsCell {...params} />;
      },
    },
    {
      headerName: t('columns.date'),
      field: 'date',
      sortable: true,
      minWidth: 180,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params): ReactElement {
        return <>{unix(params.row.createdAt).format('MMM DD, YYYY')}</>;
      },
    },
    {
      headerName: t('columns.contactOwner'),
      field: 'contactOwner',
      sortable: true,
      minWidth: 180,
      maxWidth: 230,
      flex: 1,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <ContactOwnerCell {...params} />;
      },
    },
    {
      headerName: t('columns.list'),
      field: 'contactListId',
      sortable: false,
      minWidth: 180,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell(params: GridRenderCellParams): ReactElement {
        return <ListCell {...params} value={params.value || ''} />;
      },
    },
  ];
};
