import { IIntegrationEntity } from '@/features/integration/domain';
import { IMappingByObjectTypeEntityUI } from '@/features/integration/ui/entities';

import { useFieldMappingForm } from './useFieldMappingForm';
import { useFieldMappingTabs } from './useFieldMappingTabs';

type UseFieldMappingViewModel = (params: {
  integration: IIntegrationEntity;
  integrationMapping: IMappingByObjectTypeEntityUI;
}) => ReturnType<typeof useFieldMappingForm> & {
  tabs: ReturnType<typeof useFieldMappingTabs>;
  objectType: string;
};

export const useFieldMappingViewModel: UseFieldMappingViewModel = ({
  integration,
  integrationMapping,
}) => {
  const tabs = useFieldMappingTabs({ integration });
  const form = useFieldMappingForm({ integrationMapping, integration });

  const objectType = tabs.value;

  return {
    tabs,
    objectType,
    ...form,
  };
};
