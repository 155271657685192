import { useRef, useState } from 'react';
import { number, object, ObjectSchema, string, ValidationError } from 'yup';

import { CardBrand } from '@/features/common/billing';

import { PaymentFormValues } from './types';

export const usePaymentFormSchema = (config: {
  cardError: Nullable<string>;
}): ObjectSchema<PaymentFormValues> => {
  const configRef = useRef(config);

  configRef.current = config;

  const [schema] = useState(() => {
    const PaymentFormSchema: ObjectSchema<PaymentFormValues> = object({
      seats: number().required('seats.required'),
      quantity: number().required('quantity.required'),
      billingDetails: object({
        name: string()
          .required('name.required')
          .matches(/^[a-zA-Z]+([-' ][a-zA-Z]+)*$/, 'name.incorrect')
          .max(200, 'common.maxLength'),
        email: string().nullable(),
        phone: string().nullable(),
        country: string().required('country.required'),
        state: string().optional(),
        city: string()
          .required('city.required')
          .min(2, 'common.minLength')
          .max(42, 'common.maxLength'),
        address: string().required('address.required').max(100, 'common.maxLength'),
        company: string().nullable(),
        postalCode: string().required('postalCode.required').max(20, 'common.maxLength'),
        vatId: string().nullable(),
      }).defined(),
      promotionCode: string().optional(),
      paymentMethod: object({
        id: string().required(),
        card: object({
          brand: string<CardBrand>().required(),
          last4: string().required(),
          expMonth: number().required(),
          expYear: number().required(),
        }),
      })
        .nullable()
        .optional()
        .test(async (a) => {
          if (a == null) {
            if (configRef.current.cardError) {
              return new ValidationError(
                configRef.current.cardError,
                undefined,
                'paymentMethod.card',
              );
            }
          }
          return true;
        }),
    }).nonNullable();

    return PaymentFormSchema;
  });

  return schema;
};
