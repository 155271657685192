import { IContactByFiltersDC } from '../../dataContracts';

export const contactByFiltersMigrationStrategies = {
  1: function (oldData: IContactByFiltersDC): IContactByFiltersDC {
    const defaultData = {
      emails: 0,
      phones: 0,
      verified_emails: 0,
    };
    oldData.contact_info_count_latest = defaultData;
    oldData.contact_info_count_latest = defaultData;
    return oldData;
  },
};
