import React, { useEffect } from 'react';

import { useGeoSyncUseCase } from './hooks';

export const GeoSync: React.FC = () => {
  const geoSyncUseCase = useGeoSyncUseCase();

  useEffect(() => {
    geoSyncUseCase.sync();
  }, []);

  return null;
};
