import { SyntheticEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { WrongPasswordError } from '@/features/common/auth';

import {
  getConfirmPasswordValidationSchema,
  PasswordValidationSchema,
  useFormWithSchema,
} from '@/utils/validation';

const PasswordDialogFormSchema = yup.object({
  askCurrentPassword: yup.boolean(),
  currentPassword: yup.string().when('askCurrentPassword', {
    is: true,
    then: (schema) => schema.required('passwordRequired'),
  }),
  newPassword: PasswordValidationSchema,
  confirmPassword: getConfirmPasswordValidationSchema('newPassword'),
});

type PasswordDialogFormType = yup.InferType<typeof PasswordDialogFormSchema>;

type UsePasswordDialogViewModel = (params: {
  isOpen: boolean;
  onClose: () => void;
  onPasswordChange: (password: string, currentPassword?: string) => Promise<void>;
  askCurrentPassword?: boolean;
}) => {
  handleSubmit: (e: SyntheticEvent) => void;
  form: UseFormReturn<PasswordDialogFormType>;
  isSubmitDisabled: boolean;
};

const usePasswordDialogViewModel: UsePasswordDialogViewModel = ({
  isOpen,
  onClose,
  onPasswordChange,
  askCurrentPassword = true,
}) => {
  const { t } = useTranslation('validation');

  const form = useFormWithSchema(PasswordDialogFormSchema, {
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      askCurrentPassword,
    },
  });

  useEffect(() => {
    if (isOpen) {
      return () => {
        form.reset();
      };
    }
  }, [isOpen]);

  const handleSubmit = (e: SyntheticEvent): void => {
    void form.handleSubmit(
      async ({
        currentPassword,
        newPassword,
        askCurrentPassword,
      }: PasswordDialogFormType) => {
        try {
          if (!askCurrentPassword) {
            await onPasswordChange(newPassword);
          } else {
            await onPasswordChange(newPassword, currentPassword);
          }
          onClose();
        } catch (e) {
          console.log(e);
          if (e instanceof WrongPasswordError) {
            form.setError('currentPassword', {
              message: t('passwordInvalid'),
            });
          }
        }
      },
    )(e);
  };

  return {
    form,
    handleSubmit,
    isSubmitDisabled: form.formState.isSubmitting || !form.formState.isDirty,
  };
};
export default usePasswordDialogViewModel;
