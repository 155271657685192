import { FC } from 'react';
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from 'react-player/types/lib';
import clsx from 'clsx';

import styles from './styles.module.scss';
const ReactPlayer = _ReactPlayer as unknown as FC<ReactPlayerProps>;

type PlayerProps = {
  url: string;
  className?: string;
  playerConfig?: ReactPlayerProps;
  onFirstStartPlay?: () => void;
};
export const Player: FC<PlayerProps> = ({ url, className }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <ReactPlayer url={url} width={'100%'} height={'100%'} playing controls />
    </div>
  );
};
