import { useInjection } from '@/ioc/ioc.react';
import { WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceUseCase } from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IWorkspaceEntity } from '../..';

export const useCurrentWorkspace = (): ObservableResult<IWorkspaceEntity | null> => {
  const useCases = useInjection<IWorkspaceUseCase>(WORKSPACE_TYPES.WorkspaceUseCase);
  return useObservableResult(() => useCases.getCurrentWorkspace(), { deps: [] });
};
