import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { Modal } from '@/components';

import IconClose from '@/assets/icons/iconClose.svg?react';

import { IIntegrationEntityUI } from '../../entities';

import { useContactAdminDialogViewModel } from './ContactAdminDialogViewModel';

import styles from './styles.module.scss';

type Props = {
  integration: Nullable<IIntegrationEntityUI>;
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
};

export const IntegrationContactAdminDialog: FC<Props> = ({
  integration,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('integrations');
  const currentIntegrationTitle = integration?.name || '';
  const { handleSubmit } = useContactAdminDialogViewModel({
    currentIntegration: integration,
  });

  return (
    <Modal containerClass={styles.dialogPaper} open={isOpen} onClose={onClose}>
      <>
        <IconClose onClick={onClose} className={styles.closeIcon} />
        <Typography className={styles.dialogTitle}>
          {t('contactAdminDialog.title', { provider: t(currentIntegrationTitle) })}
        </Typography>
        <Typography className={styles.dialogSubtitle}>
          {t('contactAdminDialog.description', { provider: t(currentIntegrationTitle) })}
        </Typography>
        <ActionDisableGuard>
          <Button
            onClick={(): void => {
              handleSubmit();
              onClose();
            }}
            className={styles.dialogButton}
            color="secondary"
            variant="contained"
          >
            {t('contactAdminDialog.submitBtn')}
          </Button>
        </ActionDisableGuard>
      </>
    </Modal>
  );
};
