import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Pagination as UiPagination,
  PaginationProps as UiPaginationProps,
} from '@mui/material';

import { Preview } from '@/components';

import styles from './styles.module.scss';

export type PaginationProps = UiPaginationProps & {
  showExtraInfo?: boolean;
  isLoading?: boolean;
  pageSize: number;
  totalCount?: number;
};

const calculatePrev = (pageSize: number, page: number, totalCount: number): string => {
  let start = totalCount;
  if (totalCount > 0) {
    start = pageSize * (page - 1) + 1;
  }
  return `${start} - ${Math.min(pageSize * page, totalCount)}`;
};

export const Pagination: FC<PaginationProps> = ({
  showExtraInfo = true,
  pageSize,
  isLoading,
  totalCount = 0,
  ...props
}) => {
  const { t } = useTranslation('contactsTable');
  if (showExtraInfo && pageSize === undefined) {
    throw Error(`'pageSize' is require if showExtraInfo true`);
  }

  if (showExtraInfo && props.page === undefined) {
    throw Error(`'page' is require if showExtraInfo true`);
  }

  return (
    <Box className={styles.wrapper}>
      <UiPagination {...props} showFirstButton showLastButton />
      {showExtraInfo && props.page && (
        <Box className={styles.info}>
          {isLoading ? (
            <Box className={styles.preview}>
              <Preview />
            </Box>
          ) : (
            <>
              {t('pagination.info.prevPrefix')}{' '}
              <Box component="span">
                {calculatePrev(pageSize, props.page, totalCount)}
              </Box>{' '}
              {t('pagination.info.nextPrefix')} <Box component="span">{totalCount}</Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
