import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';

import {
  useAggregatedCreditsExist,
  useAggregatedCreditsProgress,
} from '@/features/common/workspace';

import { LinearProgress } from '@/components/LinearProgress';

import { CreditBasedView } from './components/CreditBasedView';
import { UnlimitedView } from './components/UnlimitedView';

import styles from './styles.module.scss';

const TooltipTitle: FC = () => {
  const { data } = useAggregatedCreditsExist('bulk');

  switch (data) {
    case true:
      return <UnlimitedView />;
    case false:
      return <CreditBasedView />;
    default:
      return null;
  }
};

export const CreditUsage: FC = () => {
  const { data: aggregatedCreditsProgress } = useAggregatedCreditsProgress();

  return (
    <Tooltip
      classes={{
        tooltip: styles.tooltip,
        arrow: styles.arrow,
      }}
      title={<TooltipTitle />}
    >
      <Box className={styles.anchor}>
        <LinearProgress orientation="vertical" value={aggregatedCreditsProgress} />
        <Box component="span" className={styles.text}>
          <Trans
            ns="common"
            i18nKey="header.creditUsage.title"
            components={{ br: <br /> }}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};
