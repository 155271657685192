import { type ObservableResult, useObservableResult } from '@/utils/rx';

import { useCreditUseCase } from './useCreditUseCase';

export const useAggregatedCreditsExist = (
  creditType: 'full' | 'bulk',
): ObservableResult<boolean> => {
  const creditUseCase = useCreditUseCase();

  return useObservableResult<boolean>(
    () => {
      return creditType === 'full'
        ? creditUseCase.fullAggregatedCreditsExist()
        : creditUseCase.bulkAggregatedCreditsExist();
    },
    {
      deps: [creditType],
    },
  );
};
