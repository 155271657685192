import { IAggregatedCreditEntity, useCreditUseCase } from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

export const useFullAggregatedCreditsInfo = (): ObservableResult<
  Nullable<IAggregatedCreditEntity>
> => {
  const creditUseCase = useCreditUseCase();

  return useObservableResult(() => creditUseCase.getFullAggregatedCreditsInfo());
};
