import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';

import { HelpIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const TableHeader: FC<{ provider: string }> = ({ provider }) => {
  const { t } = useTranslation('integrations', {
    keyPrefix: 'integration.tabs.fieldMapping.columns',
  });
  return (
    <>
      <Box className={styles.tableHeaderWrapper}>
        <Typography className={styles.tableHeaderItem}>{t('powerleadField')}</Typography>
        <div className={styles.columnDivider} />
        <Typography className={styles.tableHeaderItem}>
          {t('providerField', { name: provider })}
        </Typography>
        <Box className={styles.tableHeaderItem}>
          <Typography className={styles.tableHeaderText}>
            {t('predefinedValue')}
          </Typography>
          <Tooltip
            title={
              <Box width="120px" textAlign="center">
                {t('predefinedValueHint', { name: provider })}
              </Box>
            }
            className={styles.tooltipPredefined}
            placement="top"
          >
            <Box className={styles.tooltipIconContainer}>
              <HelpIcon className={styles.tooltipIcon} />
            </Box>
          </Tooltip>
        </Box>
        <Box className={styles.tableHeaderItem}>
          <Typography className={styles.tableHeaderText}>
            {t('allowOverwrite')}
          </Typography>
          <Tooltip
            title={
              <Box width="120px" textAlign="center">
                {t('allowOverwriteHint', { name: provider })}
              </Box>
            }
            className={styles.tooltipPredefined}
            placement="top"
          >
            <Box className={styles.tooltipIconContainer}>
              <HelpIcon className={styles.tooltipIcon} />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box className={styles.divider} />
      <Box className={styles.hint}>
        <Typography variant={'subtitle1'} className={styles.hintText}>
          {t('globalHint')}
        </Typography>
      </Box>
    </>
  );
};
