import dayjs, { Dayjs } from 'dayjs';

import { IWorkspaceEntity } from '@/features/common/workspace';

type MinDate = {
  minDate: number;
};

export const getMinDateFromWorkspaceMember = (
  selectedUser: string | null,
  workspace: IWorkspaceEntity | null,
): MinDate => {
  const user = String(selectedUser);

  const minDate: Dayjs = dayjs.unix(
    workspace?.members.find((m) => m.uuid === user)?.acceptedInvitationAt ||
      workspace?.createdAt ||
      0,
  );

  return {
    minDate: minDate.startOf('day').unix(),
  };
};
