import { inject, injectable } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { firstValueFrom, map } from 'rxjs';

import { SYNC_TYPES, TAG_TYPES } from '@/ioc/types';

import { ITagServicesApi } from '@/features/common/tag';
import { CollectionName, TagDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { IBaseSyncRepository } from '@/features/system/sync';

@injectable()
export class TagReplicationService extends ReplicationService<TagDocument> {
  @inject(TAG_TYPES.TagApiService)
  private apiService: ITagServicesApi;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Tag,
      pullStreamFactory: () => {
        return this.syncRepository
          .getTagEvents()
          .pipe(map(mapBaseSyncEntity<TagDocument>));
      },
      pushHandlers: {
        create: (docs) => this.syncCreate(docs),
        update: (docs) => this.syncUpdate(docs),
        delete: (docs) => this.syncDelete(docs),
      },
    });
  }

  private syncCreate(
    docsToCreate: RxReplicationWriteToMasterRow<TagDocument>[],
  ): Promise<TagDocument[]> {
    return firstValueFrom(
      this.apiService.createTags(docsToCreate.map((doc) => doc.newDocumentState)).pipe(
        map((tags) => {
          return tags.map((tag) => ({ ...tag, _deleted: false }));
        }),
      ),
    );
  }

  private syncUpdate(
    docsToUpdate: RxReplicationWriteToMasterRow<TagDocument>[],
  ): Promise<TagDocument[]> {
    return firstValueFrom(
      this.apiService.updateTags(docsToUpdate.map((doc) => doc.newDocumentState)).pipe(
        map((tags) => {
          return tags.map((tag) => ({
            ...tag,
            _deleted: false,
          }));
        }),
      ),
    );
  }

  private syncDelete(
    docsToDelete: RxReplicationWriteToMasterRow<TagDocument>[],
  ): Promise<boolean> {
    return firstValueFrom(
      this.apiService.deleteTags(docsToDelete.map((doc) => doc.newDocumentState.uuid)),
    );
  }
}
