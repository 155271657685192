import { useTranslation } from 'react-i18next';

import { UserRole } from '@/features/common/account';

export const useTeamMemberRoleOptions = (): {
  value: UserRole;
  label: string;
  description: string;
}[] => {
  const { t } = useTranslation('settings');
  return [
    {
      value: UserRole.Admin,
      label: t('teamManagement.role.admin.title'),
      description: t('teamManagement.role.admin.description'),
    },
    {
      value: UserRole.Member,
      label: t('teamManagement.role.user.title'),
      description: t('teamManagement.role.user.description'),
    },
    {
      value: UserRole.Manager,
      label: t('teamManagement.role.manager.title'),
      description: t('teamManagement.role.manager.description'),
    },
  ];
};
