import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { ITeamMemberEntity, ITeamMemberUseCase } from '@/features/settings';

import { ObservableResult, useObservableResult } from '@/utils/rx/hooks';

export const useAcceptedTeamMembers = (): ObservableResult<
  ITeamMemberEntity[],
  unknown,
  ITeamMemberEntity[]
> => {
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );
  return useObservableResult(() => teamMemberUseCase.getAcceptedOnlyTeamMembers(), {
    defaultData: [],
    deps: [],
  });
};
