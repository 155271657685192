import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

export const ErrorAlert: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  const boxRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Box className={clsx(styles.wrapper, className)} ref={boxRef}>
      <Box className={styles.container}>{children}</Box>
      <ErrorIcon className={styles.errorIcon} />
    </Box>
  );
};
