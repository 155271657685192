import { ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type { ChartCardPhonesProps } from '../ChartCardPhones';

import { useChartOptions } from './useChartOptions';

import { theme } from '@/theme';

export const useChartCardPhonesViewModel = ({ data }: ChartCardPhonesProps) => {
  const options = useChartOptions();
  const chartData: ChartData<'bar', number[], string> = {
    labels: ['HQ phone', 'Direct dial'],
    datasets: [
      {
        data: [data.hqPhone, data.direct_dial],
        backgroundColor: [theme.palette.secondary.main, theme.palette.primary.main],
        borderWidth: 1,
        datalabels: {
          color: theme.palette.info.main,
          font: { family: 'SofiaPro', size: 13 },
          anchor: 'start',
          align: 'bottom',
          formatter: function (_, context) {
            return context.chart.data.labels?.[context.dataIndex];
          },
        },
      },
    ],
  };

  return {
    chartProps: {
      data: chartData,
      options,
      plugins: [ChartDataLabels],
    },
  };
};
