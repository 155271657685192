import * as React from "react";
const SvgUpgradeDialogImage = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 122.741, height: 116.959, viewBox: "0 0 122.741 116.959", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16288" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16290" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10612", d: "M55.5 0A55.5 55.5 0 1 1 0 55.5 55.5 55.5 0 0 1 55.5 0z", transform: "translate(5.409 5.96)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10660", d: "M13.508 11.429h-.084l-12.16-.759a1.347 1.347 0 0 1-1.092-2 19.887 19.887 0 0 1 4.515-5.179A14.582 14.582 0 0 1 17.945.5a1.347 1.347 0 0 1 .868 1.9l-4.1 8.273a1.348 1.348 0 0 1-1.205.756zm0 0", transform: "rotate(-45 66.693 -26.532)", style: {
  fill: "#ff193d"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10661", d: "M2.106 18.953A1.348 1.348 0 0 1 .761 17.69L0 5.53a1.348 1.348 0 0 1 .749-1.292L9.022.14a1.348 1.348 0 0 1 1.9.868 14.58 14.58 0 0 1-2.984 13.259 19.889 19.889 0 0 1-5.173 4.515 1.347 1.347 0 0 1-.659.171zm0 0", transform: "rotate(-45 72.602 -58.969)", style: {
  fill: "#ff193d"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10662", d: "M34.144 9.107 25.432.395A1.348 1.348 0 0 0 24.1.055 33.02 33.02 0 0 0 9.865 8.1a27.36 27.36 0 0 0-2.15 2.427 33.372 33.372 0 0 0-4.86 8.748A44.054 44.054 0 0 0 .9 26a41.191 41.191 0 0 0-.9 7.175 1.347 1.347 0 0 0 1.348 1.364h.017a41.122 41.122 0 0 0 7.174-.9 44.032 44.032 0 0 0 6.729-1.953 33.368 33.368 0 0 0 8.747-4.859 27.281 27.281 0 0 0 2.427-2.151 33.013 33.013 0 0 0 8.042-14.232 1.347 1.347 0 0 0-.34-1.337zm0 0", transform: "rotate(-45 50.97 -31.138)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10663", d: "M25.833 6.922 19.305.395a1.348 1.348 0 0 0-1.784-.108 13.135 13.135 0 0 0-1.179 1.043C11.8 5.872 0 24.566 0 24.863a1.348 1.348 0 0 0 1.347 1.365h.017c.3 0 18.991-11.8 23.533-16.342a13.136 13.136 0 0 0 1.043-1.179 1.348 1.348 0 0 0-.107-1.785zm0 0", transform: "rotate(-45 88.956 -23.716)", style: {
  fill: "#fff261"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10664", d: "m4.4 0-4 4.005a1.346 1.346 0 0 0 0 1.905l10.667 10.673a1.347 1.347 0 0 0 1.905 0l4-4zm0 0", transform: "rotate(-45 74.63 -38.9)", style: {
  fill: "#1b3b6b"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10665", d: "M4.109 8.221A4.111 4.111 0 0 1 1.2 1.2a4.111 4.111 0 1 1 5.815 5.817 4.084 4.084 0 0 1-2.906 1.204zm0 0", transform: "rotate(-45 54.858 -55.848)", style: {
  fill: "#e7f0ff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10666", d: "M5.426 10.857A5.428 5.428 0 0 1 1.587 1.59a5.428 5.428 0 0 1 7.677 7.677 5.394 5.394 0 0 1-3.838 1.59zm0-8.162a2.734 2.734 0 1 0 1.933.8 2.716 2.716 0 0 0-1.933-.8zm0 0", transform: "rotate(-45 53.927 -53.6)", style: {
  fill: "#1b3b6b"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10667", d: "M11.815.395A1.341 1.341 0 0 0 10.844 0 44 44 0 0 0 0 1.822l10.387 10.387a44 44 0 0 0 1.822-10.844 1.347 1.347 0 0 0-.394-.97zm0 0", transform: "rotate(-45 36.79 -59.343)", style: {
  fill: "#ff193d"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16288", transform: "translate(0 19.415)" }, /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 54", cx: 2.5, cy: 2.5, r: 2.5, transform: "translate(23.923 .025)", style: {
  fill: "#fe93bb"
} }), /* @__PURE__ */ React.createElement("ellipse", { "data-name": "Ellipse 56", cx: 2, cy: 2.5, rx: 2, ry: 2.5, transform: "translate(99.923 22.025)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 55", cx: 2.5, cy: 2.5, r: 2.5, transform: "translate(14.923 45.025)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10668", d: "m2292.244 8607.266 2.695 3.009-4.64 4.694-2.939-2.938z", transform: "translate(-2199.114 -8605.359)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10669", d: "m2334.181 8619.957-5.57 2.5 2.715 3.62z", transform: "translate(-2217.7 -8611.077)", style: {
  fill: "#05e39c"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10670", d: "m2338.748 8656.479 4.285-1.175 1.975 6.219-4.213 1.255z", transform: "translate(-2222.267 -8627.003)", style: {
  fill: "#fe93bb"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10671", d: "m2316.675 8680.664 3.6 5.964h-4.729z", transform: "translate(-2211.814 -8638.43)", style: {
  fill: "#01d0fb"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10672", d: "m2173.064 8636.531 3.851.518-1.159 6.244-3.594-.508z", transform: "translate(-2147.212 -8618.546)", style: {
  fill: "#05e39c"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10673", d: "m2134.392 8638.951 3.16-2.354 3.935 5.248-3.2 2.472z", transform: "translate(-2130.195 -8618.575)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10674", d: "m2128.242 8670.1-1.489 5.49h4.363z", transform: "translate(-2126.753 -8633.668)", style: {
  fill: "#62dbfb"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16289" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 17812", d: "M0 0h29.121v29.517a4 4 0 0 1-4 4H0V0z", transform: "translate(61.371 61.844)", style: {
  fill: "#62dbfb"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 17813", d: "M0 0h29.121v33.517H4a4 4 0 0 1-4-4V0z", transform: "translate(32.25 61.844)", style: {
  fill: "#01d0fb"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10658", d: "M3021.412 8731.252h26.927l8.463 8.463h-28.765z", transform: "translate(-2995.431 -8677.871)", style: {
  fill: "#62dbfb"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10659", d: "M3056.8 8731.252h-26.927l-8.463 8.463h28.765z", transform: "translate(-2960.041 -8677.871)", style: {
  fill: "#01d0fb"
} }))));
export default SvgUpgradeDialogImage;
