import { useState } from 'react';

import { useObservableResult } from '@/utils/rx';

import { AuthState } from '../../domain';

import { useAuthUseCase } from './useAuthUseCase';

export function useAuthState(): AuthState {
  const authUseCase = useAuthUseCase();

  const [authState$] = useState(() => authUseCase.getAuthState());

  const { data: authState } = useObservableResult(authState$, {
    defaultData: AuthState.Identifying,
  });

  return authState;
}
