import dayjs from 'dayjs';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { createChartMinDateGuard } from './createChartMinDateGuard';

type Props = { selectedDate: string; minDate: number };

type Return = {
  fromDate: number;
  toDate: number;
};

export const parseSelectedDate = ({ selectedDate, minDate }: Props): Return => {
  const minDateNormalized = dayjs.unix(minDate);
  const guardAllowedMinDate = createChartMinDateGuard(minDate);

  const now = dayjs();
  const startDay = now.startOf('day');
  const endDay = now.endOf('day');

  const yesterday = now.add(-1, 'day');
  const startYesterday = yesterday.startOf('day').format();
  const endYesterday = yesterday.endOf('day').format();

  const weekAgo = now.subtract(7, 'day');
  const startWeekAgo = weekAgo.startOf('day').format();

  const startOfMonth = now.subtract(30, 'days').startOf('day').format();

  const startOfYear = guardAllowedMinDate(now.startOf('year').format());

  const timeMap = {
    [DATE_SELECT_OPTION_VALUES.allTime]: [minDateNormalized.format(), now.format()],
    [DATE_SELECT_OPTION_VALUES.today]: [startDay, endDay],
    [DATE_SELECT_OPTION_VALUES.yesterday]: [startYesterday, endYesterday],
    [DATE_SELECT_OPTION_VALUES.lastWeek]: [startWeekAgo, endDay],
    [DATE_SELECT_OPTION_VALUES.lastMonth]: [startOfMonth, endDay],
    [DATE_SELECT_OPTION_VALUES.currentYear]: [startOfYear, endDay],
  };

  let fromDate, toDate;
  if (timeMap[selectedDate]) {
    fromDate = dayjs(guardAllowedMinDate(timeMap[selectedDate][0])).unix();
    toDate = dayjs(timeMap[selectedDate][1]).unix();
  } else {
    const customRange = selectedDate.split('-');
    const fromDateObj = guardAllowedMinDate(dayjs(customRange[0]).startOf('day'));
    fromDate = fromDateObj.unix();
    toDate =
      customRange.length === 2
        ? dayjs(customRange[1]).endOf('day').unix()
        : fromDateObj.endOf('day').unix();
  }

  return { fromDate, toDate };
};
