import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader } from '@mui/material';

import { CreditsOverview } from './components/CreditsOverview';
import { PlanOverview } from './components/PlanOverview';

import styles from './styles.module.scss';

export const SubscriptionOverview: FC = () => {
  const { t } = useTranslation('billingSettings', { keyPrefix: 'subscriptionOverview' });

  return (
    <Card className={styles.card}>
      <CardHeader
        title={t('title')}
        classes={{
          title: styles.title,
          header: styles.header,
        }}
      />
      <CardContent className={styles.content}>
        <PlanOverview />
        <CreditsOverview />
      </CardContent>
    </Card>
  );
};
