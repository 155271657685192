import { FC, useContext } from 'react';

import { MODAL_VARIANTS } from '../constants';
import { ModalContext } from '../context';

import { ConfirmationModal } from './confirmationModal/ConfirmationModal';
import { InformationModal } from './informationModal/InformationModal';

const ModalContainer: FC = () => {
  const { modalData, isShown, closeModal } = useContext(ModalContext);

  if (!modalData) return;

  if (modalData.type === MODAL_VARIANTS.confirmationModal) {
    return <ConfirmationModal onClose={closeModal} open={isShown} {...modalData.props} />;
  }

  if (modalData.type === MODAL_VARIANTS.informationModal) {
    return <InformationModal onClose={closeModal} open={isShown} {...modalData.props} />;
  }
};

export default ModalContainer;
