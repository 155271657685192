import dayjs, { Dayjs } from 'dayjs';

import { DATE_SELECT_OPTION_VALUES } from '../enums';

type StringDates = {
  from: string | null;
  to: string | null;
};

type NumberDates = {
  from: number | null;
  to: number | null;
};

export function convertDateSelectValueToDateFormat(params: {
  value: DATE_SELECT_OPTION_VALUES | string;
  format?: string;
}): StringDates;
export function convertDateSelectValueToDateFormat(params: {
  value: DATE_SELECT_OPTION_VALUES | string;
  unix?: boolean;
}): NumberDates;
export function convertDateSelectValueToDateFormat(params: {
  value: DATE_SELECT_OPTION_VALUES | string;
  format?: string;
  unix?: boolean;
}): NumberDates | StringDates {
  const { value, format, unix } = params;
  const d = dayjs();
  let from: Dayjs | null = null;
  let to: Dayjs | null = null;

  if (!DATE_SELECT_OPTION_VALUES[value]) {
    const [selectedFrom, selectedTo] = value.split('-');
    from = dayjs(selectedFrom).startOf('day');
    to = dayjs(selectedTo).endOf('day');
  }

  if (value === DATE_SELECT_OPTION_VALUES.today) {
    from = d.startOf('day');
    to = d.endOf('day');
  } else if (DATE_SELECT_OPTION_VALUES.yesterday === value) {
    from = d.subtract(1, 'day').startOf('day');
    to = d.subtract(1, 'day').endOf('day');
  } else if (DATE_SELECT_OPTION_VALUES.lastWeek === value) {
    from = d.subtract(7, 'day').startOf('day');
    to = d.endOf('day');
  } else if (DATE_SELECT_OPTION_VALUES.lastMonth === value) {
    from = d.subtract(1, 'month').startOf('day');
    to = d.endOf('day');
  } else if (DATE_SELECT_OPTION_VALUES.currentYear === value) {
    from = d.startOf('year');
    to = d.endOf('day');
  }

  if (unix) {
    return {
      from: from?.unix() ?? null,
      to: to?.unix() ?? null,
    };
  }

  return { from: from?.format(format) ?? null, to: to?.format(format) ?? null };
}
