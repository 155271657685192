import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const Divider: FC = () => {
  const { t } = useTranslation('common');

  return (
    <Typography className={styles.divider} variant={'subtitle1'} color={'info'}>
      {t('common:or')}
    </Typography>
  );
};
