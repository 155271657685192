import { useContext } from 'react';

import { PlanFeaturesMetadataContext } from '@/features/plans/ui/contexts';
import { PlanFeatureCategory, PlanFeaturesMetadata } from '@/features/plans/ui/types';

export function useComparePlansTableViewModel(params: {
  planFeatureCategory: PlanFeatureCategory;
}): PlanFeaturesMetadata[PlanFeatureCategory] {
  const planFeaturesMetadata = useContext(PlanFeaturesMetadataContext);
  const featureMetadata = planFeaturesMetadata[params.planFeatureCategory];
  return featureMetadata;
}
