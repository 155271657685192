export enum CardBrand {
  AmericanExpress = 'amex',
  DinersClub = 'dinners',
  Discover = 'discover',
  CartesBancaires = 'cartes_bancaires',
  EftposAustralia = 'eftpos',
  JCB = 'jcb',
  MasterCard = 'mastercard',
  UnionPay = 'unionpay',
  Visa = 'visa',
  Unknown = 'Unknown',
}
