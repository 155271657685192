import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy, inc, lensProp, over, pipe, reduce, toPairs } from 'ramda';

import { useProspectTaskProgressContext } from '@/features/common/prospectTask';
import { IProspectTaskProgressEntity } from '@/features/common/prospectTask/domain';

import { ProspectTaskStatus } from '../../../data';

import { ProspectTaskNotificationWidgetItemType } from './ProspectTaskNotificationWidgetItem';

type UseProspectTaskNotificationWidgetViewModelResult = {
  title: string;
  items: ProspectTaskNotificationWidgetItemType[];
};

type ProspectTaskGroupMetadata = {
  id: string;
  status: ProspectTaskStatus;
  listName: string;
  groupTotal: number;
  taskIdByStatus: Record<ProspectTaskStatus, string[]>;
  taskListLength: number;
};

class ProspectTaskGroupMetadataOrganizer {
  private taskList: IProspectTaskProgressEntity[];

  constructor(taskList: IProspectTaskProgressEntity[]) {
    this.taskList = taskList;
  }

  private getComputedMetadata(
    entities: IProspectTaskProgressEntity[],
  ): Pick<
    ProspectTaskGroupMetadata,
    'status' | 'groupTotal' | 'taskIdByStatus' | 'taskListLength'
  > {
    let groupTotal = 0;
    let status = ProspectTaskStatus.Finished;
    const taskIdByStatus: Record<ProspectTaskStatus, string[]> = {
      [ProspectTaskStatus.Pending]: [],
      [ProspectTaskStatus.Finished]: [],
      [ProspectTaskStatus.Failed]: [],
    };

    entities.forEach((entity) => {
      groupTotal += entity.enrichmentTotalCount;
      taskIdByStatus[entity.status].push(entity.uuid);
    });

    if (taskIdByStatus.pending.length) {
      status = ProspectTaskStatus.Pending;
    } else if (taskIdByStatus.failed.length) {
      status = ProspectTaskStatus.Failed;
    }

    return {
      groupTotal,
      status,
      taskIdByStatus,
      taskListLength: entities.length,
    };
  }

  public getGroupedMetadata(): ProspectTaskGroupMetadata[] {
    return pipe(
      groupBy((task: IProspectTaskProgressEntity) => task.contactList.uuid),
      toPairs,
    )(this.taskList)
      .filter(([_, entities]) => !!entities?.length)
      .map(([_, entities]) => {
        if (!entities) return [];

        return {
          id: entities[0].contactList.uuid,
          listName: entities[0].contactList.name,
          ...this.getComputedMetadata(entities),
        };
      }) as ProspectTaskGroupMetadata[];
  }
}

export const useProspectTaskNotificationWidgetViewModel =
  (): UseProspectTaskNotificationWidgetViewModelResult => {
    const { t } = useTranslation('common');
    const { taskList, deleteBulk, retryMany } = useProspectTaskProgressContext();

    return useMemo(() => {
      const items = new ProspectTaskGroupMetadataOrganizer(taskList)
        .getGroupedMetadata()
        .map(({ id, listName, groupTotal, status, taskIdByStatus }) => {
          const deleteItem = (): void => {
            deleteBulk([
              ...taskIdByStatus.failed,
              ...taskIdByStatus.finished,
              ...taskIdByStatus.pending,
            ]);
          };

          if (status === ProspectTaskStatus.Failed) {
            return {
              id,
              text: t('prospectTaskProgressNotification.item.title', {
                listName,
                contactTotalCount: groupTotal,
              }),
              status,
              deleteItem,
              errorMessage: t('prospectTaskProgressNotification.item.errorMessage'),
              onRetry: (): Promise<void> => {
                return retryMany(taskIdByStatus.failed);
              },
            };
          }

          return {
            id,
            text: t('prospectTaskProgressNotification.item.title', {
              listName,
              contactTotalCount: groupTotal,
            }),
            status,
            deleteItem,
          };
        });

      const getTitle = (): string => {
        const total = items.length;
        const totalByStatus = reduce(
          (acc, item) => over(lensProp(item.status), inc, acc),
          {
            [ProspectTaskStatus.Pending]: 0,
            [ProspectTaskStatus.Finished]: 0,
            [ProspectTaskStatus.Failed]: 0,
          },
          items,
        );

        if (!!totalByStatus[ProspectTaskStatus.Pending]) {
          return t('prospectTaskProgressNotification.pendingTitle');
        } else if (totalByStatus[ProspectTaskStatus.Failed] !== total) {
          return t('prospectTaskProgressNotification.successTitle', {
            finishedCount: totalByStatus[ProspectTaskStatus.Finished],
            totalCount: total,
          });
        } else {
          return t('prospectTaskProgressNotification.failedTitle');
        }
      };

      return {
        items,
        title: getTitle(),
      };
    }, [taskList]);
  };
