import { inject, injectable } from 'inversify';
import type { Observable } from 'rxjs';

import { LEADER_ELECTION_TYPES } from '@/ioc/types';

import type { ILeaderElectionRepository } from '../data';

export interface ILeaderElectionUseCase {
  isLeader(): boolean;
  isLeader$(): Observable<boolean>;
}

@injectable()
export class LeaderElectionUseCase implements ILeaderElectionUseCase {
  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leaderElectionRepository: ILeaderElectionRepository;

  isLeader(): boolean {
    return this.leaderElectionRepository.isLeader;
  }

  isLeader$(): Observable<boolean> {
    return this.leaderElectionRepository.isLeader$;
  }
}
