import { FC, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useUploadEnrichmentFile } from '@/features/enrichment/ui/Enrichment/hooks';

import FailedToUploadIcon from './assets/FailedToUpload.svg?react';
import NoWifiIcon from './assets/NoWifi.svg?react';
import {
  StartViewCreditsLimitError,
  StartViewDefault,
  StartViewError,
} from './components';
import { useStartViewModel } from './useStartViewModel';

import styles from './styles.module.scss';

const StartViewErrorInfoWithInput: FC<{ i18nKey: string }> = ({ i18nKey }) => {
  const { t } = useTranslation('enrichment');
  const { onChange, onClick, inputRef } = useUploadEnrichmentFile();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    onClick();
  };

  return (
    <>
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={{
          a: <a href="#" onClick={handleClick} />,
        }}
      />
      <input
        ref={inputRef}
        type="file"
        name="file"
        accept=".csv, .xls, .xlsx"
        hidden
        onChange={onChange}
      />
    </>
  );
};

export const StartView: FC = () => {
  const { t } = useTranslation('enrichment');
  const { currentView, fileMetadata } = useStartViewModel();

  if (currentView === 'StartViewFormatError') {
    return (
      <Box className={styles.Wrapper}>
        <StartViewError
          icon={<FailedToUploadIcon />}
          title={t('uploadingFormatErrorModal.title')}
          fileName={fileMetadata?.name || ''}
        >
          <StartViewErrorInfoWithInput i18nKey="uploadingFormatErrorModal.info" />
        </StartViewError>
      </Box>
    );
  }

  if (currentView === 'StartViewSizeError') {
    return (
      <Box className={styles.Wrapper}>
        <StartViewError
          icon={<FailedToUploadIcon />}
          title={t('uploadingSizeErrorModal.title')}
          fileName={fileMetadata?.name || ''}
        >
          <StartViewErrorInfoWithInput i18nKey="uploadingSizeErrorModal.info" />
        </StartViewError>
      </Box>
    );
  }

  if (currentView === 'StartViewConnectionError') {
    return (
      <Box className={styles.Wrapper}>
        <StartViewError
          icon={<NoWifiIcon />}
          title={t('uploadingConnectionErrorModal.title')}
          fileName={fileMetadata?.name || ''}
        >
          <StartViewErrorInfoWithInput i18nKey="uploadingConnectionErrorModal.info" />
        </StartViewError>
      </Box>
    );
  }

  if (currentView === 'StartViewContentError') {
    return (
      <Box className={styles.Wrapper}>
        <StartViewError
          icon={<FailedToUploadIcon />}
          title={t('uploadingContentErrorModal.title')}
          fileName={fileMetadata?.name || ''}
        >
          <StartViewErrorInfoWithInput i18nKey="uploadingContentErrorModal.info" />
        </StartViewError>
      </Box>
    );
  }

  if (currentView === 'StartViewDefaultError') {
    return (
      <Box className={styles.Wrapper}>
        <StartViewError
          icon={<FailedToUploadIcon />}
          title={t('uploadingDefaultErrorModal.title')}
          fileName={fileMetadata?.name || ''}
        >
          <StartViewErrorInfoWithInput i18nKey="uploadingDefaultErrorModal.info" />
        </StartViewError>
      </Box>
    );
  }

  if (currentView === 'StartViewCreditsLimitError') {
    return <StartViewCreditsLimitError />;
  }

  return (
    <Box className={styles.Wrapper}>
      <StartViewDefault fileName={fileMetadata?.name || ''} />
    </Box>
  );
};
