import { FC, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';

import styles from './styles.module.scss';

type Props = PropsWithChildren<TypographyProps>;

export const Subtitle: FC<Props> = ({ children, color, fontSize = '16px' }) => {
  return (
    <Typography
      variant={'subtitle1'}
      className={styles.subtitle}
      color={color}
      fontSize={fontSize}
    >
      {children}
    </Typography>
  );
};
