import { FC } from 'react';
import { Stack } from '@mui/material';

import { BillingHistory } from './components/BillingHistory';
import { PaymentDetails } from './components/PaymentDetails';
import { SubscriptionOverview } from './components/SubscriptionOverview';
import { SuccessSubscriptionModal } from './components/SuccessSubscriptionModal';

import styles from './styles.module.scss';

export const Billing: FC = () => {
  return (
    <Stack className={styles.wrapper}>
      <SubscriptionOverview />
      <PaymentDetails />
      <BillingHistory />
      <SuccessSubscriptionModal />
    </Stack>
  );
};
