import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import { uniq } from 'ramda';
import { first, firstValueFrom } from 'rxjs';

import { DB_TYPES } from '@/ioc/types';

import { IDbManager } from '../data/DbManagerNew';

export interface IDbGarbageCollector {
  process(): Promise<void>;
}

const BOUNDARY_TIME = 15 * 60; // 15 minutes in second

@injectable()
export class DbGarbageCollector implements IDbGarbageCollector {
  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  public async process(): Promise<void> {
    this.collectStaleChartData();
    return this.collectStaleContacts();
  }

  private async collectStaleContacts(): Promise<void> {
    const db = await firstValueFrom(this.dbManager.getDb());

    const contactByFiltersCollection = db['contact-by-filters'];
    const contactCollection = db['contact'];
    const companyCollection = db['company'];
    const contactByFiltersEntities = await contactByFiltersCollection.find().exec();

    const contactByFiltersToDelete: string[] = [];
    let contactsToPersist: string[] = [];
    let companiesToPersist: string[] = [];

    contactByFiltersEntities.forEach((doc) => {
      const dc = doc.toMutableJSON();
      if (Math.floor(Date.now() / 1000) - dc.ping_at > BOUNDARY_TIME) {
        contactByFiltersToDelete.push(dc.uuid);
      } else {
        contactsToPersist.push(
          ...dc.contact_by_filters_ids_current,
          ...dc.contact_by_filters_ids_latest,
        );
        contactsToPersist.push(
          ...dc.company_by_filters_ids_current,
          ...dc.company_by_filters_ids_latest,
        );
      }
    });

    contactsToPersist = uniq(contactsToPersist);
    companiesToPersist = uniq(companiesToPersist);

    if (!contactByFiltersToDelete.length) return;

    const promises: Promise<any>[] = [
      contactByFiltersCollection.bulkRemove(contactByFiltersToDelete),
    ];

    promises.push(
      contactCollection
        .find({ selector: { uuid: { $nin: contactsToPersist } } })
        .remove(),
    );

    promises.push(
      companyCollection
        .find({ selector: { uuid: { $nin: companiesToPersist } } })
        .remove(),
    );

    await Promise.all(promises);
  }

  private collectStaleChartData(): void {
    this.dbManager
      .getDb()
      .pipe(first())
      .subscribe((db) => {
        const yearAgo = dayjs().subtract(365, 'days').startOf('day').unix();
        const selector = {
          at: {
            $lte: yearAgo,
          },
        };

        void db['dashboard-chart'].find({ selector }).remove();
      });
  }
}
