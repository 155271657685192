import { inject, injectable } from 'inversify';

import { GEO_SYNC_TYPES } from '@/ioc/types';

import { IGeoSyncRepository } from '../data';

export interface IGeoSyncUseCase {
  sync(): Promise<void>;
  getCountry(): string;
}

@injectable()
export class GeoSyncUseCase implements IGeoSyncUseCase {
  @inject(GEO_SYNC_TYPES.GeoSyncRepository)
  private readonly repository: IGeoSyncRepository;

  public async sync(): Promise<void> {
    await this.repository.sync();
  }

  public getCountry(): string {
    return this.repository.getCountry();
  }
}
