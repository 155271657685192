import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PlansPageLayoutProps = {
  children: ReactNode;
};

export const PlansPageLayout: FC<PlansPageLayoutProps> = ({ children }) => {
  return (
    <div className={styles.plansPageLayout}>
      <div className={styles.plansPageLayoutContent}>{children}</div>
    </div>
  );
};
