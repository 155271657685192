import { type IAccountDC } from '@/features/common/account';
import { type IContactListDC } from '@/features/common/contactList';
import { type IProspectTaskDC } from '@/features/common/prospectTask';
import { type ITagDC } from '@/features/common/tag';
import { type IWorkspaceDC } from '@/features/common/workspace';
import { type ChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/ChartDataDC';
import { type IEnrichmentDC } from '@/features/enrichment';
import { type IIntegrationExportTaskDC } from '@/features/integration/data/dataContracts';

export enum BaseSyncEventType {
  Initial = 'initial',
  Insert = 'insert',
  Update = 'update',
  Delete = 'delete',
}

export enum BaseSyncEntityType {
  ContactList = 'contact_list',
  Tag = 'tag',
  Workspace = 'workspace',
  Account = 'account',
  WorkspaceStat = 'workspace_stat',
  ProspectTask = 'prospect_task',
  Enrichment = 'csv_enrichment',
  Invitation = 'invitation',
  IntegrationExportTask = 'export_items_task',
}

interface EntityTypeMap {
  [BaseSyncEntityType.ContactList]: IContactListDC;
  [BaseSyncEntityType.Tag]: ITagDC;
  [BaseSyncEntityType.Workspace]: IWorkspaceDC;
  [BaseSyncEntityType.Account]: IAccountDC;
  [BaseSyncEntityType.WorkspaceStat]: ChartDataDC;
  [BaseSyncEntityType.ProspectTask]: IProspectTaskDC;
  [BaseSyncEntityType.Enrichment]: IEnrichmentDC;
  [BaseSyncEntityType.IntegrationExportTask]: IIntegrationExportTaskDC;
}

export interface IBaseSyncEntity<EntityType = unknown> {
  resume_token: string | null;
  event_type: BaseSyncEventType;
  entity_type: EntityType;
  entity: EntityType extends keyof EntityTypeMap ? EntityTypeMap[EntityType] : unknown;
}

export function isBaseSyncEntity(event: unknown): event is IBaseSyncEntity {
  return (
    typeof event === 'object' &&
    event !== null &&
    'event_type' in event &&
    'entity_type' in event
  );
}

export function isInitialBaseSyncEntity(event: unknown): event is IBaseSyncEntity {
  return isBaseSyncEntity(event) && event.event_type === BaseSyncEventType.Initial;
}

export function isInserBaseSyncEntity(event: unknown): event is IBaseSyncEntity {
  return isBaseSyncEntity(event) && event.event_type === BaseSyncEventType.Insert;
}

export function isUpdateBaseSyncEntity(event: unknown): event is IBaseSyncEntity {
  return isBaseSyncEntity(event) && event.event_type === BaseSyncEventType.Update;
}

export function isDeleteBaseSyncEntity(event: unknown): event is IBaseSyncEntity {
  return isBaseSyncEntity(event) && event.event_type === BaseSyncEventType.Delete;
}
