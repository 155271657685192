import { firstValueFrom } from 'rxjs';

import { container } from '@/ioc/container';
import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import { EmailValidationSchema } from './EmailValidationSchema';

class EmailValidationClient {
  private cache: Map<string, Promise<boolean>> = new Map();

  validate(email: string): Promise<boolean> {
    const cacheResult = this.cache.get(email);

    if (cacheResult) return cacheResult;

    const result = (async (): Promise<boolean> => {
      const httpClient = container.get<IHttpClient>(NETWORK_TYPES.HttpClient);
      const response$ = httpClient.get<boolean>(
        `/api/v1/account/validate-email?email=${email}`,
      );
      const { data: isValid } = await firstValueFrom(response$);
      return isValid;
    })();

    this.cache.set(email, result);

    return result;
  }
}

const validateionClient = new EmailValidationClient();

export const WorkEmailValidationSchema = EmailValidationSchema.test(
  'bannedEmail',
  'workEmailInvalid',
  (email) => {
    return validateionClient.validate(email);
  },
);
