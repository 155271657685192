import { inject, injectable } from 'inversify';
import { firstValueFrom, map } from 'rxjs';

import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import type { IWorkspaceEntity } from '@/features/common/workspace';
import { mapWorkspaceDcToEntity } from '@/features/common/workspace';
import type {
  ITeamMemberApiService,
  ITeamMemberStateRepository,
} from '@/features/settings';

@injectable()
export default class TeamMemberStateRepository implements ITeamMemberStateRepository {
  @inject(TEAM_MEMBER_TYPES.TeamMemberApiService) private api: ITeamMemberApiService;

  inviteTeamMembers(
    payload: {
      email: string;
      role: string;
    }[],
  ): Promise<IWorkspaceEntity> {
    return firstValueFrom(
      this.api
        .inviteTeamMembers(payload)
        .pipe(map((workspaceDc) => mapWorkspaceDcToEntity(workspaceDc))),
    );
  }

  deleteTeamMembers(
    payload: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return firstValueFrom(
      this.api.deleteTeamMembers(
        payload.map((item) => ({ email: item.email, reassign_to: item.reassignTo })),
      ),
    );
  }

  async resendInvitation(payload: {
    email: string;
    role: string;
  }): Promise<IWorkspaceEntity> {
    return firstValueFrom(
      this.api.resendInvitation(payload).pipe(map(mapWorkspaceDcToEntity)),
    );
  }
}
