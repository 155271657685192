import { FC, useEffect } from 'react';
import { filter, pairwise } from 'rxjs';

import { useInjection } from '@/ioc';
import { AUTH_TYPES, LEADS_TRACKING_TYPES } from '@/ioc/types';

import { AuthStatus, type IAuthUseCase } from '@/features/common/auth';

import type { ILeadsTrackingUseCase } from '../../domain/LeadsTrackingUseCase';

export const LeadsTracker: FC = () => {
  const leadsTrackingUseCase = useInjection<ILeadsTrackingUseCase>(
    LEADS_TRACKING_TYPES.LeadsTrackingUseCase,
  );

  const authUseCase = useInjection<IAuthUseCase>(AUTH_TYPES.AuthUseCase);

  useEffect(() => {
    leadsTrackingUseCase.track(new URL(window.location.href));

    // we should remove lead tracking when user logout
    const sub = authUseCase
      .getAuthStatus()
      .pipe(
        pairwise(),
        filter(
          ([prev, cur]) =>
            prev === AuthStatus.Authorized && cur == AuthStatus.Unauthorized,
        ),
      )
      .subscribe(() => {
        leadsTrackingUseCase.remove();
      });

    return () => sub.unsubscribe();
  }, []);

  return null;
};
