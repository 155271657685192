import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';

import styles from './styles.module.scss';

export const CreditsOverviewLoadingView: FC = () => {
  return (
    <>
      <Box className={styles.tableRow}>
        <Box className={styles.tableCol}>
          <Skeleton width={100} />
        </Box>
        <Box className={styles.tableCol}>
          <Skeleton width={100} />
        </Box>
        <Box className={styles.tableCol} />
      </Box>
      <Box className={styles.tableRow}>
        <Box className={styles.tableCol}>
          <Skeleton width={100} />
        </Box>
        <Box className={styles.tableCol}>
          <Skeleton width={100} />
        </Box>
        <Box className={styles.tableCol} />
      </Box>
    </>
  );
};
