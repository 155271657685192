import { RxJsonSchema } from 'rxdb';

import { ChartDataDC } from '../../dataContracts/ChartDataDC';

export const dashboardChartSchema: RxJsonSchema<ChartDataDC> = {
  title: 'dashboardChartSchema',
  version: 1,
  primaryKey: 'uuid',
  type: 'object',
  required: ['at', 'member', 'credits_used', 'emails_count', 'phones_count'],
  properties: {
    member: { type: 'string' },
    at: { type: 'number' },
    last_revealed_contact_date: { type: 'number' },
    revealed_contacts_count: { type: 'number' },
    credits_used: { type: 'number' },
    emails_count: { type: 'number' },
    phones_count: { type: 'number' },
    uuid: { type: 'string', maxLength: 100 },
    total_revealed_contacts_count: { type: 'number' },
    total_credits_used: { type: 'number' },
    total_emails_count: { type: 'number' },
    total_phones_count: { type: 'number' },
    current_revealed_contacts_count: { type: 'number' },
    current_credits_used: { type: 'number' },
    current_emails_count: { type: 'number' },
    current_phones_count: { type: 'number' },
  },
};
