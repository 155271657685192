import { createIsEnum } from '@/utils/isEnum';

export enum DATE_SELECT_OPTION_VALUES {
  allTime = 'allTime',
  today = 'today',
  yesterday = 'yesterday',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  currentYear = 'currentYear',
}

export const isDateSelectOptionValue = createIsEnum(DATE_SELECT_OPTION_VALUES);
