import { BillingCycle } from '@/features/common/billing';

import type { ProductMetadata } from '../entities/GroupedProductMetadata';

import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class ExpandProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    return {
      title: 'What’s included:',
      items: [
        'Unlimited bulk enrichment',
        'CRM enrichment',
        'API access',
        'SSO',
        'Enterprise security, SAML and Okta',
        'Tailored CS & onboarding experience',
      ],
    };
  }

  getName(): string {
    return 'Enterprise';
  }

  getDescription(): string {
    return 'Tailored for teams with high volume';
  }

  getIsCreditCardRequired(): boolean {
    return true;
  }

  getIsMostPopular(): boolean {
    return false;
  }

  override build(): ProductMetadata {
    return {
      name: this.getName(),
      description: this.getDescription(),
      mostPopular: this.getIsMostPopular(),
      creditCardRequired: this.getIsCreditCardRequired(),
      variants: {
        [BillingCycle.Monthly]: {
          id: '',
          current: false,
          price: 'contact-sales',
          isPriceFixed: false,
          fullCredits: 'unlimited',
          bulkCredits: 'unlimited',
        },
        [BillingCycle.Yearly]: {
          id: '',
          current: false,
          price: 'contact-sales',
          isPriceFixed: false,
          fullCredits: 'unlimited',
          bulkCredits: 'unlimited',
        },
      },
      features: [],
      marketingFeatures: this.getFeatures(),
    };
  }
}
