import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-pro';

import {
  ActionsCell,
  AmountCell,
  HeaderCell,
  NameCell,
  NameCellEdit,
} from '../components';

import { usePreProcessNameCellEdit } from './usePreProcessNameCellEdit';

import styles from '../styles.module.scss';

export const useListsTableColumns = (): GridColDef[] => {
  const { t } = useTranslation('lists');
  const preProcessEditCellProps = usePreProcessNameCellEdit();

  return [
    {
      headerName: t('table.cols.listName'),
      field: 'name',
      minWidth: 270,
      renderCell: (params) => <NameCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => <NameCellEdit {...params} />,
      preProcessEditCellProps,
      editable: true,
      renderHeader: (params) => <HeaderCell {...params} />,
    },
    {
      headerName: t('table.cols.contacts'),
      field: 'contactsAmount',
      minWidth: 200,
      cellClassName: styles.listCell,
      renderCell: (params) => <AmountCell {...params} />,
      renderHeader: (params) => <HeaderCell {...params} />,
    },
    {
      headerName: t('table.cols.createdBy'),
      field: 'createdBy',
      minWidth: 180,
      cellClassName: styles.listCell,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell: undefined,
    },
    {
      headerName: t('table.cols.dateCreated'),
      field: 'createdAt',
      minWidth: 180,
      cellClassName: styles.listCell,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell: undefined,
    },
    {
      headerName: t('table.cols.lastUpdated'),
      field: 'updatedAt',
      minWidth: 180,
      cellClassName: styles.listCell,
      renderHeader: (params) => <HeaderCell {...params} />,
      renderCell: undefined,
    },
    {
      headerName: '',
      field: 'actions',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <ActionsCell {...params} />,
    },
  ];
};
