import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import styles from './styles.module.scss';

export const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>{children}</Box>
    </Box>
  );
};
