import { inject, injectable } from 'inversify';

import { AUTH_TYPES } from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';

export interface IResetPasswordUseCases {
  resetPassword(oobCode: string, newPassword: string): Promise<void>;
  checkOobCode(oobCode: string): Promise<void>;
}

@injectable()
export class ResetPasswordUseCases implements IResetPasswordUseCases {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  async checkOobCode(oobCode: string): Promise<void> {
    await this.authRepository.checkActionCode(oobCode);
  }

  resetPassword(oobCode: string, newPassword: string): Promise<void> {
    return this.authRepository.resetPassword(oobCode, newPassword);
  }
}
