import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, List, ListItem, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { Checkbox, SearchInput, TagBadge } from '@/components';

import { usePopoverBodyViewModel } from './usePopoverBodyViewModel';

import styles from './styles.module.scss';

const NoResults: FC = () => {
  const { t } = useTranslation('contactsTable');

  return (
    <Box className={styles.noResults}>
      <Typography className={styles.noResultsText}>{t('tagsCell.noResults')}</Typography>
    </Box>
  );
};

export const PopoverBody: FC<{
  defaultValue: string[];
  onApply: (values: string[]) => void;
}> = ({ defaultValue, onApply }) => {
  const { t } = useTranslation('contactsTable');
  const {
    toTagManager,
    search,
    handleSearchChange,
    handleListItemClick,
    handleSearchClear,
    valuesIsMatched,
    tags,
    isChecked,
    value,
    isEmpty,
  } = usePopoverBodyViewModel({ defaultValue });

  return (
    <>
      <Box className={styles.header}>
        <SearchInput
          variant="filled"
          value={search}
          placeholder={t('tagsCell.searchField.placeholder')}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
        />
      </Box>
      {isEmpty ? (
        <NoResults />
      ) : (
        <List className={styles.list}>
          {tags.map((tag) => (
            <ListItem
              key={tag.uuid}
              className={styles.listItem}
              onClick={(): void => {
                handleListItemClick(tag.uuid);
              }}
            >
              <TagBadge bgColor={tag.color}>{tag.name}</TagBadge>
              <Checkbox checked={isChecked(tag.uuid)} />
            </ListItem>
          ))}
        </List>
      )}
      <Box className={styles.footer}>
        <Button onClick={toTagManager}>{t('tagsCell.manageTags')}</Button>
        <ActionDisableGuard>
          <Button
            disabled={valuesIsMatched}
            onClick={(): void => {
              onApply(value);
            }}
          >
            {t('tagsCell.apply')}
          </Button>
        </ActionDisableGuard>
      </Box>
    </>
  );
};
