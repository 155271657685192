import { createTheme, ThemeOptions } from '@mui/material';

import {
  MuiAlert,
  MuiAutocomplete,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDataGrid,
  MuiDivider,
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiGrid,
  MuiIconButton,
  MuiInputBase,
  MuiMenu,
  MuiModal,
  MuiOutlinedInput,
  MuiPagination,
  MuiPaginationItem,
  MuiPopover,
  MuiSelect,
  MuiSkeleton,
  MuiSnackbar,
  MuiSvgIcon,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiTooltip,
  MuiTypography,
  typography,
} from './overrides';

import './muiGlobal.scss';
import colours from '../styles/colours.module.scss';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colours.blue500,
      dark: colours.blue700,
      disabled: colours.blue300,
    },
    secondary: {
      main: colours.orange500,
      dark: colours.orange700,
      disabled: colours.orange300,
    },
    info: {
      main: colours.grey500,
      dark: colours.grey700,
      disabled: colours.grey200,
      // @ts-ignore
      border: colours.grey300,
    },
    error: {
      main: colours.red500,
    },
    success: {
      main: colours.green500,
    },
    grey200: colours.grey200,
    grey300: colours.grey300,
    grey400: colours.grey400,
    grey500: colours.grey500,
    grey700: colours.grey700,
  },
  typography,
  components: {
    MuiButton,
    MuiIconButton,
    MuiSnackbar,
    MuiAlert,
    MuiModal,
    MuiInputBase,
    MuiFormLabel,
    MuiFormHelperText,
    MuiFormControl,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiTypography,
    // @ts-ignore
    MuiDataGrid,
    MuiPopover,
    MuiAutocomplete,
    MuiTooltip,
    MuiTabs,
    MuiTab,
    MuiPagination,
    MuiPaginationItem,
    MuiGrid,
    MuiTextField,
    MuiSelect,
    MuiMenu,
    MuiChip,
    MuiOutlinedInput,
    MuiSvgIcon,
    MuiDivider,
    MuiSkeleton,
  },
};

export const theme = createTheme(themeOptions);
