import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useElements } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';

type UsePaymentCardReturn = {
  card?: StripeCardElement | null;
  error?: string;
  validate: () => Promise<void>;
};

export function usePaymentCard(params: {
  shouldValidate: boolean;
  onError?: () => void;
}): UsePaymentCardReturn {
  const [_, forceRender] = useReducer((i: number): number => i + 1, 0);
  const onErrorRef = useRef<(() => void) | undefined>(params.onError);
  const shouldValudateRef = useRef(params.shouldValidate);
  const [error, setError] = useState<string | undefined>('required');
  const { t } = useTranslation('billing', { keyPrefix: 'validations' });

  const elements = useElements();
  const card = elements?.getElement('card');

  onErrorRef.current = params.onError;
  shouldValudateRef.current = params.shouldValidate;

  // HACK: force render to update card element until it's available
  useEffect(() => {
    if (card) return;

    const interval = setInterval(() => {
      // card on elements is not updated automatically, so we have to force render
      forceRender();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [card]);

  useEffect(() => {
    if (!card) {
      return;
    }

    const setAndNotifyError = (newError?: string): void => {
      setError(newError);
      if (newError && shouldValudateRef.current) {
        onErrorRef.current?.();
      }
    };

    const handleCardChange = (event: StripeCardElementChangeEvent): void => {
      if (event.error) {
        setAndNotifyError(event.error.code);
        return;
      }

      if (event.complete) {
        setAndNotifyError(undefined);
        return;
      }

      if (event.empty) {
        setAndNotifyError('required');
      } else {
        setAndNotifyError(undefined);
      }
    };

    card.on('change', handleCardChange);

    return () => {
      card.off('change', handleCardChange);
    };
  }, [card]);

  return {
    error: params.shouldValidate && error ? t(`card.${error}`) : undefined,
    card,
    validate: async (): Promise<void> => {
      if (!shouldValudateRef.current) {
        return;
      }

      if (!elements || !card) {
        throw new Error('required');
      }

      const { error } = await elements.submit();

      if (error?.message) {
        setError(error.message);
        throw error;
      }
    },
  };
}
