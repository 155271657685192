import { parseFinancialString } from '@/utils/parseFinancialString';

import { ICompanyEntity, IContactByFiltersEntity, IContactEntity } from '../../domain';
import { ICompanyDC, IContactByFiltersDC, IContactDC } from '../dataContracts';

export const mapContactDcToEntity = (dc: IContactDC): IContactEntity => ({
  uuid: dc.uuid,
  linkedinId: dc.linkedin_id,
  workspaceId: dc.workspace_id,
  contactListId: dc.contact_list_id,
  name: dc.name,
  jobTitle: dc.job_title,
  companyId: dc.company_id,
  thumbnail: dc.thumbnail,
  integrationUrls: Object.entries(dc.integration_urls || {}).map(([provider, link]) => ({
    provider,
    link,
  })),
  phones: (dc.phones || []).map((item) => {
    return {
      customLabel: item.custom_label,
      phone: item.phone,
      normalizedPhone: item.normalized_phone,
      type: item.type,
    };
  }),
  emails: (dc.emails || []).map((item) => {
    return {
      type: item.type,
      status: item.status,
      customLabel: item.custom_label,
      email: item.email,
    };
  }),
  socialProfiles: (dc.social_profiles || []).map((item) => {
    return {
      type: item.type,
      customLabel: item.custom_label,
      userId: item.user_id,
    };
  }),
  assignedTo: dc.assigned_to,
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  tags: dc.tags,
});

export const mapContactEntityToDc = (entity: IContactEntity): IContactDC => ({
  uuid: entity.uuid,
  linkedin_id: entity.linkedinId,
  workspace_id: entity.workspaceId,
  contact_list_id: entity.contactListId,
  name: entity.name,
  job_title: entity.jobTitle,
  company_id: entity.companyId,
  integration_urls: entity.integrationUrls.length
    ? entity.integrationUrls.reduce((acc, item) => {
        acc[item.provider] = item.link;
        return acc;
      }, {})
    : null,
  thumbnail: entity.thumbnail,
  phones: entity.phones.map((item) => {
    return {
      custom_label: item.customLabel,
      phone: item.phone,
      normalized_phone: item.normalizedPhone,
      type: item.type,
    };
  }),
  emails: entity.emails.map((item) => {
    return {
      type: item.type,
      status: item.status,
      custom_label: item.customLabel,
      email: item.email,
    };
  }),
  social_profiles: entity.socialProfiles.map((item) => {
    return {
      type: item.type,
      custom_label: item.customLabel,
      user_id: item.userId,
    };
  }),
  assigned_to: entity.assignedTo,
  created_at: entity.createdAt,
  updated_at: entity.updatedAt,
  tags: entity.tags,
});

export const mapCompanyDcToEntity = (dc: ICompanyDC): ICompanyEntity => ({
  uuid: dc.uuid,
  name: dc.name,
  companyId: dc.company_id,
  topics: dc.topics || [],
  description: dc.description || '',
  industry: dc.industry || '',
  image: dc.name === 'Powerlead' ? '/apple-touch-icon.png' : (dc.image ?? ''), // hack
  images: dc.images?.map(({ url }) => url) || null,
  foundedYear: dc.founded_year || '',
  phone: dc.phone || '',
  contactEmail: dc.contact_email || '',
  employees: dc.employees || 0,
  employeesMin: dc.employees_max || 0,
  employeesMax: dc.employees_min || 0,
  totalFunding: dc.total_funding ? parseFinancialString(dc.total_funding) : 0,
  fundingRounds:
    dc?.funding_rounds?.map((r) => ({
      moneyRaised: r.last_round_money_raised
        ? parseFinancialString(r.last_round_money_raised)
        : 0,
    })) || [],
  address: dc.address || '',
  websites: dc.websites?.map((item) => ({ url: item.url, ogImage: item.og_image })) || [],
  socialNetworks:
    dc.social_networks?.map((item) => ({
      snType: item.sn_type,
      snId: item.sn_id,
      url: item.url,
    })) || [],
  totalRevenue: dc.financials?.annual[0] ? dc.financials?.annual[0].total_revenue : 0,
});

export const mapContactByFiltersDcToEntity = (
  dc: IContactByFiltersDC,
): IContactByFiltersEntity => ({
  uuid: dc.uuid,
  contactByFiltersIdsCurrent: dc.contact_by_filters_ids_current,
  contactByFiltersIdsLatest: dc.contact_by_filters_ids_latest,
  companyByFilterIdsCurrent: dc.company_by_filters_ids_current,
  companyByFilterIdsLatest: dc.company_by_filters_ids_latest,
  countCurrent: dc.count_current,
  countLatest: dc.count_latest,
  contactInfoCountCurrent: {
    emails: dc.contact_info_count_current.emails,
    phones: dc.contact_info_count_current.phones,
    verifiedEmails: dc.contact_info_count_current.verified_emails,
  },
  contactInfoCountLatest: {
    emails: dc.contact_info_count_latest.emails,
    phones: dc.contact_info_count_latest.phones,
    verifiedEmails: dc.contact_info_count_latest.verified_emails,
  },
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  pingAt: dc.ping_at,
});

export const mapContactByFiltersEntityToDc = (
  entity: IContactByFiltersEntity,
): IContactByFiltersDC => ({
  uuid: entity.uuid,
  contact_by_filters_ids_current: entity.contactByFiltersIdsCurrent,
  contact_by_filters_ids_latest: entity.contactByFiltersIdsLatest,
  company_by_filters_ids_current: entity.companyByFilterIdsCurrent,
  company_by_filters_ids_latest: entity.companyByFilterIdsLatest,
  contact_info_count_current: {
    emails: entity.contactInfoCountCurrent.emails,
    phones: entity.contactInfoCountCurrent.phones,
    verified_emails: entity.contactInfoCountCurrent.verifiedEmails,
  },
  contact_info_count_latest: {
    emails: entity.contactInfoCountLatest.emails,
    phones: entity.contactInfoCountLatest.phones,
    verified_emails: entity.contactInfoCountLatest.verifiedEmails,
  },
  count_current: entity.countCurrent,
  count_latest: entity.countLatest,
  created_at: entity.createdAt,
  updated_at: entity.updatedAt,
  ping_at: entity.pingAt,
});
