import { Container } from 'inversify';

import { SYNC_TYPES } from '@/ioc/types';

import {
  BaseSyncRepository,
  BaseSyncService,
  type IResumeTokenManager,
  ResumeTokenManager,
  SseClient,
} from '../data';
import { BaseSyncUseCase, IBaseSyncUseCase } from '../domain';

export const bindSyncModule = (container: Container): void => {
  container.bind(SYNC_TYPES.SseClient).to(SseClient);

  container.bind(SYNC_TYPES.BaseSyncService).to(BaseSyncService).inSingletonScope();

  container
    .bind<IResumeTokenManager>(SYNC_TYPES.ResumeTokenManager)
    .to(ResumeTokenManager)
    .inSingletonScope();

  container.bind(SYNC_TYPES.BaseSyncRepository).to(BaseSyncRepository).inSingletonScope();

  container.bind<IBaseSyncUseCase>(SYNC_TYPES.BaseSyncUseCase).to(BaseSyncUseCase);
};
