import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import ModalIcon from '../../assets/ModalIcon.svg?react';

import { useUserAccessDeniedModalViewModel } from './useUserAccessDeniedModalViewModel';

import styles from './styles.module.scss';

export const UserAccessDeniedModal: FC = () => {
  const { t } = useTranslation('enrichment');
  const { asyncExecute, isLoading } = useUserAccessDeniedModalViewModel();

  return (
    <>
      <Box className={styles.Icon}>
        <ModalIcon />
      </Box>
      <Typography variant="h3" className={styles.Title}>
        {t('userAccessDeniedModal.title')}
      </Typography>
      <Typography className={styles.SubTitle}>
        {t('userAccessDeniedModal.subTitle')}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        className={styles.Button}
        disabled={isLoading}
        onClick={asyncExecute}
      >
        {t('userAccessDeniedModal.button')}
      </Button>
    </>
  );
};
