import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { useObservableResult } from '@/utils/rx';

import { AggregationStrategy, ChartDataTotals } from '../../domain';
import { ChartDataUIEntity } from '../entities/ChartDataUIEntity';

import { useChartDataMappers } from './useChartDataMappers';
import { useChartUseCase } from './useChartUseCase';

type Props = {
  selectedUser: string;
  selectedDate: DATE_SELECT_OPTION_VALUES | string;
  fromDate: number;
  toDate: number;
};

type Return = ChartDataUIEntity & {
  tooltipTitles: string[];
};

export const useChartData = ({
  selectedUser,
  selectedDate,
  fromDate,
  toDate,
}: Props): Return => {
  const chartUseCase = useChartUseCase();

  const {
    data: { data, totals, strategy },
  } = useObservableResult(
    () =>
      chartUseCase.getData({
        selectedUser,
        fromDate,
        toDate,
      }),
    {
      deps: [selectedUser, fromDate, toDate],
      defaultData: {
        strategy: AggregationStrategy.Weekly,
        data: [],
        totals: new ChartDataTotals(),
      },
    },
  );

  const { creditsUsed, emailAddresses, phoneNumbers } = totals;

  const { labels, values, tooltipTitles } = useChartDataMappers({
    data,
    selectedDate,
    strategy,
  });

  return { labels, values, tooltipTitles, creditsUsed, emailAddresses, phoneNumbers };
};
