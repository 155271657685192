import { inject, injectable } from 'inversify';

import { COOKIES_TYPES, CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import { IAccountEntity } from '@/features/common/account';
import { ICookiesManager } from '@/features/system/cookies';
import {
  CustomerMessagingEventKeys,
  ICustomerMessagingRepository,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

@injectable()
export default class CustomerMessagingUseCase implements ICustomerMessagingUseCase {
  @inject(CUSTOMER_MESSAGING_TYPES.CustomerMessagingRepository)
  private repository: ICustomerMessagingRepository;

  @inject(COOKIES_TYPES.CookiesManager)
  private cookiesManager: ICookiesManager;

  identify(account: IAccountEntity): void {
    this.repository.identify(account);
  }

  track(key: CustomerMessagingEventKeys, attrs?: Record<string, string>): void {
    return this.repository.track(key, attrs);
  }

  page(key: CustomerMessagingEventKeys, attrs?: Record<string, string>): void {
    return this.repository.page(key, attrs);
  }

  wipeCookies(): void {
    this.cookiesManager.remove('_cioid');
    this.cookiesManager.remove('_cioanonid');
  }
}
