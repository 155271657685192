import { FC, forwardRef } from 'react';

import { CustomizedAlert, CustomizedAlertProps } from './CustomizedAlert';

type SuccessAlertProps = CustomizedAlertProps;

export const SuccessAlert: FC<SuccessAlertProps> = forwardRef<
  HTMLDivElement,
  SuccessAlertProps
>((props, ref) => {
  return <CustomizedAlert ref={ref} {...props} variant="success" />;
});

SuccessAlert.displayName = 'SuccessAlert';
