import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessingModal } from '@/components/ProcessingModal';

import {
  PaymentDetailsLayout,
  PaymentForm,
  PaymentGuarantee,
  PaymentSummary,
} from './components';
import { usePaymentDetailsViewModel } from './PaymentDetailsViewModel';

export const PaymentDetails: FC = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'updatingPlanDialog' });
  const { summary, isProcessing, onSubmit } = usePaymentDetailsViewModel();

  return (
    <PaymentDetailsLayout>
      <ProcessingModal
        open={isProcessing}
        title={t('title')}
        description={t('description')}
        processingText={t('inProgress')}
      />
      <PaymentForm isProcessing={isProcessing} onSubmit={onSubmit} />
      <PaymentSummary summary={summary} isProcessing={isProcessing} />
      <PaymentGuarantee />
    </PaymentDetailsLayout>
  );
};
