import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Typography } from '@mui/material';

import Icon from '@/assets/icons/usageLimit.svg?react';

import styles from './styles.module.scss';

export const FairUsageLimitAlert: FC = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Alert icon={<Icon />} className={styles.alert} action={null}>
      <Typography variant="h3" className={styles.title}>
        {t('youVeReachedOurFairUsageLimit')}
      </Typography>
      <Typography variant="subtitle1" className={styles.description}>
        {t('tryAgainLaterOrContactOurSupportTeamIfThisIssuePersists')}
      </Typography>
    </Alert>
  );
};
