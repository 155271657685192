import { FC, useEffect } from 'react';
import { combineLatest, filter } from 'rxjs';

import { useAccountUseCase } from '@/features/common/account';
import { AuthState, AuthStatus, useAuthUseCase } from '@/features/common/auth';
import { useCustomerMessagingUseCase } from '@/features/system/CustomerIO';

export const CustomerIO: FC = () => {
  const customerMessagingUseCase = useCustomerMessagingUseCase();
  const authUseCase = useAuthUseCase();
  const accountUseCase = useAccountUseCase();

  useEffect(() => {
    const sub1 = authUseCase
      .getAuthStatus()
      .pipe(filter((authStatus) => authStatus === AuthStatus.Unauthorized))
      .subscribe(() => {
        customerMessagingUseCase.wipeCookies();
      });

    const sub2 = combineLatest({
      account: accountUseCase.getAccount(),
      authState: authUseCase.getAuthState(),
    })
      .pipe(filter((data) => data.authState === AuthState.CanAccessSite))
      .subscribe((data) => {
        if (!data.account) {
          return;
        }

        customerMessagingUseCase.identify(data.account);
      });

    return () => {
      sub1.unsubscribe();
      sub2.unsubscribe();
    };
  }, []);

  return null;
};
