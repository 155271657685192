import { RxJsonSchema } from 'rxdb';

import { IContactDC } from '../../dataContracts';

export const contactSchema: RxJsonSchema<IContactDC> = {
  title: 'contact schema',
  version: 0,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    linkedin_id: { type: 'string' },
    workspace_id: { type: 'string' },
    contact_list_id: { type: 'string' },
    job_title: { type: 'string' },
    company_id: { type: 'string' },
    thumbnail: { type: 'string' },
    phones: { type: 'object' },
    emails: { type: 'object' },
    social_profiles: { type: 'object' },
    created_at: { type: 'string' },
    updated_at: { type: 'string' },
    name: { type: 'string' },
    assigned_to: { type: 'string' },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    integration_urls: { type: 'object' },
  },
};
