import { injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { DbState } from '@/features/system/db';

type ExportState =
  | {
      status: 'pending';
    }
  | {
      status: 'finished';
      contactsNumber: number;
    }
  | {
      status: 'failed';
      reason: string;
    };

export interface IIntegrationExtportDC {
  [id: string]: ExportState;
}

export interface IIntegrationExportState {
  getExportState(id: string): Observable<ExportState>;
  setExportState(id: string, state: ExportState): Promise<void>;
}

@injectable()
export class IntegrationExportState
  extends DbState<IIntegrationExtportDC>
  implements IIntegrationExportState
{
  constructor() {
    super({ stateName: 'integration-export' });
  }

  getExportState(id: string): Observable<ExportState> {
    return this.get$(id).pipe(
      map((state) => {
        if (!state) return { status: 'pending' };

        return state;
      }),
    );
  }

  async setExportState(id: string, state: ExportState): Promise<void> {
    await this.set(id, () => state);
  }
}
