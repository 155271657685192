import type { Components, Theme } from '@mui/material';

export const MuiPagination: Components<Theme>['MuiPagination'] = {
  styleOverrides: {
    ul: ({ theme }) => {
      return {
        '&>li:nth-of-type(2), &>li:nth-last-of-type(2)': {
          display: 'flex',
          alignItems: 'center',
        },
        '&>li:nth-of-type(2)::after, &>li:nth-last-of-type(2)::before': {
          content: '""',
          width: 0,
          height: 27,
          border: `1px solid ${theme.palette.info?.disabled}`,
        },
        '&>li:nth-of-type(2)::after': {
          marginRight: '10px',
          marginLeft: '8px',
        },
        '&>li:nth-last-of-type(2)::before': {
          marginLeft: '10px',
          marginRight: '8px',
        },
      };
    },
  },
};
