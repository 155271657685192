import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { AuthState, useAuthState } from '@/features/common/auth';
import { useIsOnline } from '@/features/system/OnlineTracker';
import { SyncStatus } from '@/features/system/sync/domain';

import { useObservableResult } from '@/utils/rx';

import { useBaseSyncUseCase } from '../../hooks';

import styles from './styles.module.scss';

const IssueBanner: FC<{ reason: 'noInternet' | 'streamIssue' }> = ({ reason }) => {
  const { t } = useTranslation('common');

  return (
    <Box className={styles.banner}>
      <Typography variant="subtitle2" color="white">
        {t(
          reason === 'streamIssue'
            ? 'streamConnectivityIssue'
            : 'internetConnectionError',
        )}
      </Typography>
    </Box>
  );
};

export const RealTimeUpdatesIssueTracker: FC<PropsWithChildren> = ({ children }) => {
  const syncUseCase = useBaseSyncUseCase();
  const authState = useAuthState();

  const { data: canSync } = useObservableResult(() => syncUseCase.canSync(), {
    deps: [],
  });

  const { data: syncStatus } = useObservableResult(() => syncUseCase.getStatus(), {
    deps: [],
  });

  const isOnline = useIsOnline();

  if (!isOnline) {
    return (
      <>
        <IssueBanner reason="noInternet" />
        {children}
      </>
    );
  }

  if (!canSync || !syncStatus || authState !== AuthState.CanAccessSite) {
    return <>{children}</>;
  }

  switch (syncStatus) {
    case SyncStatus.Inactive:
    case SyncStatus.Offline:
      return (
        <>
          <IssueBanner reason="streamIssue" />
          {children}
        </>
      );
    case SyncStatus.Error:
    case SyncStatus.Initializing:
    case SyncStatus.Active:
    default:
      return <>{children}</>;
  }
};
