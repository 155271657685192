import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';

import styles from './styles.module.scss';

const TagTableTagCell: React.FC<GridRenderCellParams> = ({ value, row: { color } }) => {
  return (
    <div style={{ background: color }} className={styles.Tag}>
      {value}
    </div>
  );
};

export default TagTableTagCell;
