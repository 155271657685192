import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const useClipboardWithToast = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  return (str: string): Promise<void> => {
    return navigator.clipboard.writeText(str).then(() => {
      enqueueSnackbar({
        variant: 'success',
        message: t('copiedToClipboardSuccess'),
      });
    });
  };
};
