import { FC } from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import clsx from 'clsx';

import { FlashIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export type CallToActionBaseProps = {
  variant: 'contained' | 'outlined' | 'text';
  color?: ButtonProps['color'];
  size?: 'small' | 'medium';
  onClick?: () => void;
  text?: string;
  withIcon?: boolean;
  className?: string;
};

export const CallToActionBase: FC<CallToActionBaseProps> = ({
  variant,
  onClick,
  text,
  size = 'medium',
  withIcon = true,
  className,
  color = 'primary',
}) => {
  return (
    <Button
      startIcon={
        withIcon ? (
          <FlashIcon
            className={clsx(styles.icon, {
              [styles.small]: size === 'small',
              [styles.medium]: size === 'medium',
            })}
          />
        ) : undefined
      }
      onClick={onClick}
      color={color}
      variant={variant}
      classes={{
        root: clsx(
          styles.callToAction,
          {
            [styles.small]: size === 'small',
            [styles.medium]: size === 'medium',
            [styles.text]: variant === 'text',
          },
          className,
        ),
      }}
    >
      <Typography variant="textInButton">{text}</Typography>
    </Button>
  );
};
