import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useRetentionUseCase } from './hooks';

type ViewModel = () => {
  onAcceptClick: () => void;
  onRejectClick: () => void;
};

export const useLeavingSoonViewModel: ViewModel = () => {
  const useCase = useRetentionUseCase();
  const onboardingUseCase = useOnboardingUseCase();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const subscription = useCase
      .canUserReceiveCredits()
      .subscribe((canUseRecieveCredits) => {
        if (canUseRecieveCredits) {
          trackEvent(ANALYTICS_EVENTS.RECEIVED_RETENTION_OFFER);
        } else {
          navigate(ROUTES.DASHBOARD);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  const onAcceptClick = (): void => {
    useCase.acceptRetentionOffer().subscribe();
    trackEvent(ANALYTICS_EVENTS.ACCEPTED_RETENTION_OFFER);
    window.open(EXTERNAL_ROUTES.EXTENSION_URL);
    navigate(ROUTES.DASHBOARD);
    onboardingUseCase.completeClickDownloadExtensionStep();
  };

  const onRejectClick = (): void => {
    useCase.declineRetentionOffer().subscribe();
    trackEvent(ANALYTICS_EVENTS.DECLINED_RETENTION_OFFER);
    navigate(ROUTES.DASHBOARD);
  };

  return {
    onAcceptClick,
    onRejectClick,
  };
};
