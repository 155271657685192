import { useReducer } from 'react';

import { IProductEntity } from '@/features/common/billing';

type ChangeBillingCycleState =
  | { isOpened: false }
  | {
      isOpened: true;
      isPorcessing: boolean;
      product: IProductEntity;
      seats: number;
    };

type UseChangeBillingCycleState = () => readonly [
  ChangeBillingCycleState,
  {
    open(params: { product: IProductEntity; seats: number }): void;
    close(): void;
    startProcessing(): void;
    processedWithSuccess(): void;
    processedWithError(): void;
  },
];

type CloseAction = { type: 'close' };
type StartProcessingAction = { type: 'startProcessing' };
type ProcessedWithSuccessAction = { type: 'processedWithSuccess' };
type ProcessedWithErrorAction = { type: 'processedWithError' };
type OpenAction = {
  type: 'open';
  subscription: { product: IProductEntity; seats: number };
};

type ChangeBillingCycleAction =
  | CloseAction
  | StartProcessingAction
  | ProcessedWithSuccessAction
  | ProcessedWithErrorAction
  | OpenAction;

function reducer(
  state: ChangeBillingCycleState,
  action: ChangeBillingCycleAction,
): ChangeBillingCycleState {
  switch (action.type) {
    case 'open':
      return {
        isOpened: true,
        isPorcessing: false,
        ...action.subscription,
      };
    case 'close':
    case 'processedWithSuccess':
      return { isOpened: false };
    case 'startProcessing':
      return state.isOpened ? { ...state, isPorcessing: true } : state;
    case 'processedWithError':
      return state.isOpened ? { ...state, isPorcessing: false } : state;
  }
}

export const useChangeBillingCycleState: UseChangeBillingCycleState = () => {
  const [state, dispatch] = useReducer(reducer, {
    isOpened: false,
  });

  const open = (subscription: { product: IProductEntity; seats: number }): void => {
    dispatch({ type: 'open', subscription });
  };

  const close = (): void => {
    dispatch({ type: 'close' });
  };

  const startProcessing = (): void => {
    dispatch({ type: 'startProcessing' });
  };

  const processedWithSuccess = (): void => {
    dispatch({ type: 'processedWithSuccess' });
  };

  const processedWithError = (): void => {
    dispatch({ type: 'processedWithError' });
  };

  return [
    state,
    { open, close, startProcessing, processedWithSuccess, processedWithError },
  ] as const;
};
