import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import { MangoQuerySortPart } from 'rxdb';
import { map, Observable } from 'rxjs';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { IEnrichmentRepository } from '../domain/abstraction';
import { IEnrichmentEntity } from '../domain/entities';
import {
  EnrichmentUploadCreditsLimitError,
  EnrichmentUploadFileFormatError,
  EnrichmentUploadFileRecordsError,
  EnrichmentUploadFileSizeError,
} from '../domain/errors';
import {
  EnrichmentConfigForm,
  EnrichmentFileUploadResponse,
  EnrichmentSortSchema,
} from '../domain/types';

import { mapEnrichmentDcToEntity } from './db/mappers/mappers';
import { IEnrichmentDao } from './db';
import { IEnrichmentApiService } from './network';

@injectable()
export class EnrichmentRepository implements IEnrichmentRepository {
  @inject(ENRICHMENT_TYPES.EnrichmentApiService)
  private readonly enrichmentApiService: IEnrichmentApiService;

  @inject(ENRICHMENT_TYPES.EnrichmentDao)
  private readonly enrichmentDao: IEnrichmentDao;

  private createBlobLinkToDownload(csvContent: string): string {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    return objUrl;
  }

  public getAllByParams(
    params: Partial<{
      name: string;
      createdBy: string;
    }>,
    sortSchema?: EnrichmentSortSchema,
  ): Observable<IEnrichmentEntity[]> {
    const nameSelector = params?.name ? { name: { $regex: params?.name } } : {};
    const createdBySelector = params?.createdBy
      ? { 'created_by.uuid': params.createdBy }
      : {};

    return this.enrichmentDao
      .findAllByParams({
        selector: {
          $or: [
            {
              ...nameSelector,
              ...createdBySelector,
            },
          ],
        },
        ...(sortSchema && { sort: [sortSchema] as MangoQuerySortPart[] }),
      })
      .pipe(
        map((values) => {
          if (values) return values.map(mapEnrichmentDcToEntity);

          return [];
        }),
      );
  }

  public getById(uuid: string): Observable<IEnrichmentEntity | null> {
    return this.enrichmentDao.findById(uuid).pipe(
      map((value) => {
        if (value) return mapEnrichmentDcToEntity(value);

        return null;
      }),
    );
  }

  public async downloadReport(uuid: string): Promise<string> {
    const response = await this.enrichmentApiService.downloadReport(uuid);
    return this.createBlobLinkToDownload(response);
  }

  public async uploadFile(
    formData: FormData,
    options?: Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal'>,
  ): Promise<EnrichmentFileUploadResponse> {
    try {
      const response = await this.enrichmentApiService.uploadFile(formData, options);
      return response;
    } catch (error) {
      if (error.response?.status >= 400) {
        const code = error.response?.data?.code;
        switch (code) {
          case 101:
            throw new EnrichmentUploadFileSizeError();
          case 102:
            throw new EnrichmentUploadFileRecordsError();
          case 103:
            throw new EnrichmentUploadFileFormatError();
          case 105:
            throw new EnrichmentUploadFileFormatError();
          case 106:
            throw new EnrichmentUploadCreditsLimitError(
              'Enrichment upload credits limit reached',
              {
                fileMetadata: {
                  records: error.response.data.records,
                  filename: error.response.data.filename,
                },
              },
            );
          default:
            throw error;
        }
      }
      throw error;
    }
  }

  public startJob(jobId: string, configForm: EnrichmentConfigForm): Promise<unknown> {
    return this.enrichmentApiService.startJob(jobId, configForm);
  }

  public contactAdmin(): Promise<boolean> {
    return this.enrichmentApiService.contactAdmin();
  }
}
