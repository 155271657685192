import { FC, ReactElement } from 'react';
import { CheckCircle } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const OnboardingWidgetStepItem: FC<{
  text: string | ReactElement;
  status: 'completed' | 'incompleted';
}> = ({ text, status }) => {
  return (
    <ListItem
      className={clsx(styles.item, {
        [styles.isCompleted]: status === 'completed',
      })}
    >
      <ListItemIcon className={styles.itemIcon}>
        <CheckCircle />
      </ListItemIcon>
      <ListItemText className={styles.itemText}>{text}</ListItemText>
    </ListItem>
  );
};
