import { FC } from 'react';
import { Box } from '@mui/material';

import { RefreshIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const NotificationIcon: FC = () => {
  return (
    <Box className={styles.Icon}>
      <RefreshIcon />
    </Box>
  );
};
