import { type User } from 'firebase/auth';
import { inject, injectable } from 'inversify';
import { combineLatest, distinctUntilChanged, map, type Observable } from 'rxjs';

import { ACCOUNT_TYPES, AUTH_TYPES, REFERRAL_TYPES } from '@/ioc/types';

import { IAccountRepository, VerificationStatus } from '@/features/common/account';
import { IReferralRepository } from '@/features/referral';

import { type IAuthRepository } from '../data';

import { type IAuthUseCase } from './abstractions';
import { AuthIdentityProvider, AuthState, type AuthStatus } from './types';

@injectable()
export class AuthUseCase implements IAuthUseCase {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(REFERRAL_TYPES.ReferralRepository)
  private referralRepository: IReferralRepository;

  private getUser(): Observable<User | null | undefined> {
    return this.authRepository.getUser();
  }

  getAuthIdentityProvider(): Observable<AuthIdentityProvider | null> {
    return this.authRepository.getUser().pipe(
      map((user) => {
        if (!user) {
          return null;
        }

        if (user.providerData.length === 0) {
          return null;
        }

        const userProviderNames = user.providerData.map((data) => data.providerId);

        if (userProviderNames.includes(AuthIdentityProvider.password)) {
          return AuthIdentityProvider.password;
        } else if (userProviderNames.includes(AuthIdentityProvider.google)) {
          return AuthIdentityProvider.google;
        } else if (userProviderNames.includes(AuthIdentityProvider.microsoft)) {
          return AuthIdentityProvider.microsoft;
        } else {
          return null;
        }
      }),
    );
  }

  getAuthStatus(): Observable<AuthStatus> {
    return this.authRepository.getAuthStatus();
  }

  getAuthState(): Observable<AuthState> {
    return combineLatest([
      this.getUser(),
      this.accountRepository.getAccount(),
      this.accountRepository.getEmailForAccountCreation(),
    ]).pipe(
      map(([user, account, emailForAccountCreation]) => {
        if (user === undefined) {
          return AuthState.Identifying;
        }

        if (emailForAccountCreation) {
          return AuthState.ShouldSetupAccount;
        }

        if (!user) {
          return AuthState.ShouldProvideCredentials;
        }

        if (!account) {
          return AuthState.Identifying;
        }

        if (!account.isUserSignUpFinished) {
          return AuthState.ShouldSetupAccount;
        }

        if (account.verificationStatus === VerificationStatus.ShouldVerifySignUpEmail) {
          return AuthState.ShouldVerifyEmail;
        }

        return AuthState.CanAccessSite;
      }),
      distinctUntilChanged(),
    );
  }

  getAccessToken(): Observable<string> {
    return this.authRepository.getAccessToken();
  }

  async signOut(): Promise<void> {
    await this.authRepository.signOut();
    this.referralRepository.deleteReferralTokenFromStorage();
  }
}
