import { FC } from 'react';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps,
} from 'notistack';

import { ErrorAlert } from './components/ErrorAlert';
import { InfoAlert } from './components/InfoAlert';
import { SuccessAlert } from './components/SuccessAlert';
import { WarningAlert } from './components/WarningAlert';

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children, ...props }) => {
  return (
    <NotistackSnackbarProvider
      Components={{
        info: InfoAlert,
        success: SuccessAlert,
        error: ErrorAlert,
        warning: WarningAlert,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
