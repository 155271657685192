// @ts-ignore
import { browserTracingIntegration, init, replayIntegration } from '@sentry/react';

import { getAppVersion } from '@/utils/getAppVersion';

export const initSentry = (): void => {
  init({
    // @ts-ignore-next-line
    dsn: import.meta.env.REACT_APP_POWERLEAD_SENTRY_DSN,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // @ts-ignore-next-line
    enabled: import.meta.env.PROD,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // @ts-ignore-next-line
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    release: `powerlead-web@${getAppVersion()}`,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    // beforeSend is needed for Sentry.captureException correct work
    beforeSend(err) {
      return err;
    },
  });
};
