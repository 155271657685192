import { IAccountEntity } from '@/features/common/account';

import { OnboardingStep } from '../types';

export const mapStepToAccountOnboardingField = (
  step: OnboardingStep,
): keyof IAccountEntity['settings']['onboarding'] => {
  switch (step) {
    case 'installExtension':
      return 'isExtensionInstalled';
    case 'revealContact':
      return 'isContactRevealed';
    case 'inviteTeamMember':
      return 'isTeamMemberInvited';
    case 'exportContact':
      return 'isContactExported';
    case 'signup':
      return 'isSignupComplete';
    case 'viewInstallExtensionPage':
      return 'isViewedInstallExtensionPage';
    case 'viewDashboard':
      return 'isViewedDashboard';
    case 'clickDownloadExtension':
      return 'isClickedDownloadExtension';
    case 'mobileSignupOpenChromeStore':
      return 'mobileSignupOpenChromeStore';
  }
};
