import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, Popover, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { PhoneType } from '@/features/common/contact/domain';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { usePhoneHelper } from '@/features/system/PhoneHelper';

import { MenuItem } from '@/components';
import {
  ArrowDownIcon,
  ChatIcon,
  CopyIcon,
  DislikeIcon,
  MessengerIcon,
  MoreHorizIcon,
  PhoneIcon,
} from '@/components/Icons';

import { useClipboardWithToast } from '@/hooks';

import { getSmsLink, getWhatsappLink } from '@/utils/cttLinks';
import { openInNewTab } from '@/utils/openInNewTab';

import { useContactWrongInfoReport } from '../../hooks';

import styles from './styles.module.scss';

type PhoneItem = { phone: string; type: Nullable<PhoneType> };

type PhoneMenuProps = {
  items: PhoneItem[];
  contactId: string;
};

const PhoneMenuActions: FC<{
  phone: string;
  open: boolean;
  anchorEl: HTMLElement | undefined;
  contactId: string;
  onClose: () => void;
}> = ({ phone, open, anchorEl, contactId, onClose }) => {
  const { t } = useTranslation('contactsTable');
  const { trackEvent } = useAnalytics();
  const { sendReport } = useContactWrongInfoReport();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      classes={{
        list: styles.subMenu,
      }}
    >
      <MenuItem
        className={styles.subMenuItem}
        onClick={(): void => {
          openInNewTab(getWhatsappLink(phone));
          trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_PHONE_ACTION, {
            type: 'whatsapp',
          });
        }}
      >
        <MessengerIcon className={styles.subMenuItemIcon} />
        <Box component="span">{t('contactsInfoCell.phoneMenu.whatsApp')}</Box>
      </MenuItem>
      <MenuItem
        className={styles.subMenuItem}
        onClick={(): void => {
          openInNewTab(getSmsLink(phone));
          trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_PHONE_ACTION, {
            type: 'sms',
          });
        }}
      >
        <ChatIcon className={styles.subMenuItemIcon} />
        <Box component="span">{t('contactsInfoCell.phoneMenu.sms')}</Box>
      </MenuItem>
      <MenuItem
        className={styles.subMenuItem}
        onClick={(): void => {
          sendReport({ contactId, value: phone, entityType: 'phone' }, () => {
            trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_PHONE_ACTION, {
              type: 'wrong info',
            });
          });
          onClose();
        }}
      >
        <DislikeIcon className={styles.subMenuItemIcon} />
        <Box component="span">{t('contactsInfoCell.phoneMenu.wrongInfo')}</Box>
      </MenuItem>
    </Menu>
  );
};

export const PhoneMenu: FC<PhoneMenuProps> = ({ items, contactId }) => {
  const { t } = useTranslation('contactsTable', {
    keyPrefix: 'contactsInfoCell.phoneMenu',
  });

  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement>();
  const [activeItem, setActiveItem] = useState<Nullable<PhoneItem>>(null);
  const { trackEvent } = useAnalytics();
  const copyToClipboard = useClipboardWithToast();
  const { format } = usePhoneHelper();

  const handleClick = (e): void => {
    setAnchorElement(e.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorElement(undefined);
  };
  const handleCopy = (item: PhoneItem): void => {
    void copyToClipboard(item.phone).then(handleClose);
  };
  const handleCall = (item: PhoneItem): void => {
    window.open(`tel:${item.phone}`);
  };
  const handleMoreClick = (e, item: PhoneItem): void => {
    setMenuAnchorElement(e.currentTarget);
    setActiveItem(item);
  };

  return (
    <>
      <Box className={styles.button} onClick={handleClick}>
        <Box className={clsx(styles.buttonBaseIcon, styles.phoneIcon)}>
          <PhoneIcon />
        </Box>
        <Box className={styles.buttonArrowIcon}>
          <ArrowDownIcon />
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box component="ul" className={styles.menuList}>
          {items.map((item) => {
            return (
              <Box key={item.phone} component="li" className={styles.menuListItem}>
                <Box className={styles.menuListItemInfo}>
                  <Box component="span" className={styles.menuListItemText}>
                    {format(item.phone || '')}
                  </Box>
                  {item.type && (
                    <Box component="div" className={styles.menuListItemType}>
                      {t(`phoneTypes.${item.type}`)}
                    </Box>
                  )}
                </Box>
                <Tooltip title={t('copyTooltip')} placement="top">
                  <IconButton
                    className={styles.menuListItemButton}
                    onClick={(): void => {
                      trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_PHONE_ACTION, {
                        type: 'copy phone',
                      });
                      handleCopy(item);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('callTooltip')} placement="top">
                  <IconButton
                    onClick={(): void => {
                      trackEvent(ANALYTICS_EVENTS.CONTACT_INFO_PHONE_ACTION, {
                        type: 'call',
                      });
                      handleCall(item);
                    }}
                    className={styles.menuListItemButton}
                  >
                    <PhoneIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={(e): void => {
                    handleMoreClick(e, item);
                  }}
                  className={styles.menuListItemButton}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
        {activeItem && (
          <PhoneMenuActions
            phone={activeItem.phone}
            anchorEl={menuAnchorElement}
            open={Boolean(menuAnchorElement)}
            contactId={contactId}
            onClose={(): void => {
              setMenuAnchorElement(undefined);
              setActiveItem(null);
            }}
          />
        )}
      </Popover>
    </>
  );
};
