import {
  IIntegrationEntity,
  IIntegrationMapEntity,
  IntegrationMapRowEntity,
} from '@/features/integration/domain/entities';

import { IIntegrationDC, IIntegrationMapDC, MapRowDC } from '../dataContracts';

export const mapIntegrationDCtoEntity = (dc: IIntegrationDC): IIntegrationEntity => ({
  provider: dc.provider,
  userEmail: dc.user_email,
  enabledObjects: dc.enabled_objects,
  supportedObjects: dc.supported_objects,
  connectionActive: dc.connection_active,
});

export const mapIntegrationMapDCtoEntity = (
  dc: IIntegrationMapDC,
): IIntegrationMapEntity => ({
  powerleadFields: dc.powerlead_fields,
  providerFields: dc.provider_fields,
  mapping: dc.mapping.map((row) => ({
    powerleadField: row.powerlead_field,
    providerField: row.provider_field,
    predefinedValue: row.predefined_value,
    allowOverride: row.allow_overwrite,
  })),
  requiredProviderFields: dc.required_provider_fields,
});

export const mapIntegrationMapEntityToDC = (
  entity: IntegrationMapRowEntity,
): MapRowDC => ({
  powerlead_field: entity.powerleadField,
  provider_field: entity.providerField,
  predefined_value: entity.predefinedValue,
  allow_overwrite: entity.allowOverride,
});
