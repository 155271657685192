import { FC, PropsWithChildren } from 'react';
import { Button } from '@mui/material';

import GoogleIcon from '@/assets/icons/Google.svg?react';
import MicrosoftIcon from '@/assets/icons/Microsoft.svg?react';

import styles from './styles.module.scss';

const ICON_MAP = {
  google: <GoogleIcon />,
  microsoft: <MicrosoftIcon />,
};

export const SocialButton: FC<
  PropsWithChildren<{
    onClick: () => void;
    variant: 'google' | 'microsoft';
  }>
> = ({ children, onClick, variant }) => {
  return (
    <Button
      className={styles.button}
      startIcon={ICON_MAP[variant]}
      onClick={onClick}
      variant="outlined"
    >
      {children}
    </Button>
  );
};
