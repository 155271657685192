import { injectable } from 'inversify';

export interface IFeatureFlagManager {
  isFeatureEnabled(featureFlag: string): boolean;
}

@injectable()
export class FeatureFlagManager implements IFeatureFlagManager {
  private readonly featureFlags: Set<string> = new Set();

  constructor() {
    this.retrieveFeatureFlags();
  }

  private retrieveFeatureFlags(): void {
    return Object.entries(import.meta.env).forEach(([key, value]) => {
      if (key.startsWith('REACT_APP_FEATURE_') && value.toLowerCase() === 'true') {
        this.featureFlags.add(key.replace('REACT_APP_', ''));
      }
    });
  }

  public isFeatureEnabled(featureFlag: string): boolean {
    return this.featureFlags.has(featureFlag);
  }
}
