import { useSearchParams } from 'react-router-dom';

const qKey = 'plan_id';

export const usePlanIdFromUrl = (): [Nullable<string>, (planId: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const planIdAsString = searchParams.get(qKey);

  const handlePlanIdChange = (planId: string): void => {
    if (planId === planIdAsString) return;
    searchParams.set(qKey, planId.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return [planIdAsString, handlePlanIdChange];
};
