import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import { ActionDisableGuard } from '@/features/common/permissions';

import { Modal } from '@/components';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconArrows from '@/assets/icons/integrations/arrows.svg?react';
import IconConnectPowerlead from '@/assets/icons/integrations/connectPowerleadIcon.svg?react';

import { IIntegrationEntityUI } from '../../entities';

import styles from './styles.module.scss';

type Props = {
  integration: Nullable<IIntegrationEntityUI>;
  isOpen: boolean;
  onClose(): void;
};

export const IntegrationUpgradeDialog: FC<Props> = ({ integration, isOpen, onClose }) => {
  const { t } = useTranslation('integrations');
  const currentIntegrationTitle = integration?.name || '';
  const currentIcon = integration?.icon || '';
  const isWiderIcon = integration?.id === 'salesforce';

  return (
    <Modal containerClass={styles.dialogPaper} open={isOpen} onClose={onClose}>
      <>
        <IconClose onClick={onClose} className={styles.closeIcon} />
        <Box className={styles.dialogIcons}>
          <Box className={styles.withBorder}>
            <IconConnectPowerlead />
          </Box>
          <IconArrows />
          <Box className={styles.integrationIconWrapper}>
            <img
              src={currentIcon}
              alt={t(currentIntegrationTitle)}
              style={{ width: isWiderIcon ? 65 : 52 }}
            />
          </Box>
        </Box>
        <Typography className={styles.dialogTitle}>{t('upgradeDialog.title')}</Typography>
        <Typography className={styles.dialogSubtitle}>
          {t('upgradeDialog.description')}
        </Typography>
        <ActionDisableGuard>
          <CallToAction
            withIcon={false}
            show="getCredits"
            textOverrides={{
              getCredits: t('upgradeDialog.submit'),
            }}
            variant="contained"
            className={styles.dialogButton}
          />
        </ActionDisableGuard>
      </>
    </Modal>
  );
};
