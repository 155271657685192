import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChromeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <g data-name="Group 18688">
      <path
        d="M5.915.487H-.006A.494.494 0 0 1-.5-.006.494.494 0 0 1-.006-.5h5.921a.494.494 0 0 1 .494.494.494.494 0 0 1-.494.493z"
        transform="translate(7.006 4.307)"
        style={{ fill: '#8083a3' }}
      />
      <path
        d="M-145.318 5.584a.491.491 0 0 1-.246-.066.494.494 0 0 1-.181-.674l2.942-5.1a.494.494 0 0 1 .674-.181.494.494 0 0 1 .181.674l-2.942 5.1a.493.493 0 0 1-.428.247z"
        transform="translate(151.696 8.387)"
        style={{ fill: '#8083a3' }}
      />
      <path
        d="M-142.3-245.968a.493.493 0 0 1-.428-.247l-2.941-5.093a.494.494 0 0 1 .181-.674.494.494 0 0 1 .674.181l2.941 5.093a.494.494 0 0 1-.181.674.491.491 0 0 1-.246.066z"
        transform="translate(146.941 254.778)"
        style={{ fill: '#8083a3' }}
      />
      <path
        d="M-130.64-260.779a3.2 3.2 0 0 1-3.193-3.193 3.2 3.2 0 0 1 3.193-3.193 3.2 3.2 0 0 1 3.193 3.193 3.2 3.2 0 0 1-3.193 3.193zm0-5.4a2.209 2.209 0 0 0-2.206 2.206 2.209 2.209 0 0 0 2.206 2.206 2.209 2.209 0 0 0 2.206-2.206 2.209 2.209 0 0 0-2.206-2.206z"
        transform="translate(137.64 270.973)"
        style={{ fill: '#8083a3' }}
      />
      <path
        d="M-314.833-441.166a7.008 7.008 0 0 1-7-7 7.008 7.008 0 0 1 7-7 7.008 7.008 0 0 1 7 7 7.008 7.008 0 0 1-7 7zm0-13.013a6.019 6.019 0 0 0-6.013 6.013 6.019 6.019 0 0 0 6.013 6.013 6.019 6.019 0 0 0 6.013-6.013 6.019 6.019 0 0 0-6.013-6.012zm0 9.206a3.2 3.2 0 0 1-3.193-3.193 3.2 3.2 0 0 1 3.193-3.193 3.2 3.2 0 0 1 3.193 3.193 3.2 3.2 0 0 1-3.193 3.194zm0-5.4a2.209 2.209 0 0 0-2.206 2.206 2.209 2.209 0 0 0 2.206 2.206 2.209 2.209 0 0 0 2.206-2.206 2.209 2.209 0 0 0-2.206-2.205z"
        transform="translate(321.833 455.166)"
        style={{ fill: '#8083a3' }}
      />
    </g>
  </SvgIcon>
);
