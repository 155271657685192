import Intercom, {
  hide,
  onHide,
  onShow,
  show,
  showNewMessage,
  shutdown,
  update,
} from '@intercom/messenger-js-sdk';
import { UserArgs } from '@intercom/messenger-js-sdk/dist/types';
import { injectable } from 'inversify';

import { IHelpCenterRepository } from './abstractions/IHelpCenterRepository';

@injectable()
export class HelpCenterRepository implements IHelpCenterRepository {
  private appId = import.meta.env.REACT_APP_HELP_CENTER_APP_ID;

  constructor() {
    Intercom({
      app_id: this.appId,
    });
  }

  public update(settings: UserArgs): void {
    update({
      app_id: this.appId,
      ...settings,
    });
  }

  public hide(): void {
    hide();
  }

  public show(): void {
    show();
  }

  public showNewMessage(prePopulatedContent: string): void {
    showNewMessage(prePopulatedContent);
  }

  public onShow(callback: () => void): void {
    onShow(callback);
  }

  public onHide(callback: () => void): void {
    onHide(callback);
  }

  public shutdown(): void {
    shutdown();
  }
}
