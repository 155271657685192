import type { CardBrand, IPaymentMethodEntity } from '../../domain';
import type { IPaymentMethodDC } from '../dataContracts';

export function mapPaymentMethodDCtoEntity(dc: IPaymentMethodDC): IPaymentMethodEntity {
  return {
    id: dc.id,
    card: {
      brand: dc.card.brand as CardBrand,
      expMonth: dc.card.exp_month,
      expYear: dc.card.exp_year,
      last4: dc.card.last4,
    },
  };
}
