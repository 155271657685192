import { inject, injectable } from 'inversify';
import { firstValueFrom, Observable } from 'rxjs';

import { ACCOUNT_TYPES, TAG_TYPES } from '@/ioc/types';

import { IAccountUseCase } from '@/features/common/account';

import { ITagRepository } from './abstractions/repositories/ITagRepository';
import { ITagUseCase } from './abstractions/useCases/ITagUseCase';
import { ITagEntity } from './entities/TagEntity';

@injectable()
export default class TagUseCase implements ITagUseCase {
  @inject(TAG_TYPES.TagRepository)
  private tagRepository: ITagRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountUseCase: IAccountUseCase;

  getMetadata(): Observable<{ total: number }> {
    return this.tagRepository.getMetadata();
  }

  getTags(params?: {
    uuidIncludes?: string[];
    nameRegex?: string;
  }): Observable<ITagEntity[]> {
    return this.tagRepository.getTags(params);
  }

  async addTag(tag: Pick<ITagEntity, 'name' | 'color'>): Promise<ITagEntity> {
    const account = await firstValueFrom(this.accountUseCase.getAccount());
    return this.tagRepository.addTag({
      ...tag,
      created_by: account?.uuid ?? '',
      contacts_amount: 0,
    });
  }

  updateTag(payload: Pick<ITagEntity, 'uuid' | 'name' | 'color'>): Promise<ITagEntity> {
    return this.tagRepository.updateTag(payload);
  }

  deleteTag(uuid: string): Promise<boolean> {
    return this.tagRepository.deleteTag(uuid);
  }
}
