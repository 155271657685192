import { inject, injectable } from 'inversify';
import { map, type Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { mapBulkResponse, type NetworkBulkResponse } from '@/features/system/network';
import { IHttpClient } from '@/features/system/network/HttpClient';

import { type IContactDC } from '../dataContracts';

export interface IContactApiService {
  getByQuery(queryParams: URLSearchParams): Observable<IContactDC[]>;
  updateContacts(payload: Partial<IContactDC>[]): Observable<IContactDC[]>;
  exportToCsvAll(): Observable<string>;
  exportToCsvByFilters(queryParams: URLSearchParams): Observable<string>;
  exportToCsvByIds(ids: string[]): Observable<string>;
  wrongInfoReport(dto: {
    value: string;
    contact_uuid: string;
    entity_type: string;
  }): Observable<boolean>;
}

const CONTACT_ENDPOINTS = {
  default: `/api/v1/contacts`,
  exportToCsvByIds: '/api/v1/contacts/export_by_id',
  wrongInfoReport: '/api/v1/report/wrong-info',
};

const EXCLUDED_QUERY_PARAMS_TO_EXPORT = ['start', 'length', 'stream'];

@injectable()
export class ContactApiService implements IContactApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  public getByQuery(queryParams: URLSearchParams): Observable<IContactDC[]> {
    const urlSearchParams = new URLSearchParams(queryParams);

    urlSearchParams.delete('csv');
    urlSearchParams.delete('stream');

    return this.httpClient
      .get<{
        contacts: IContactDC[];
      }>(`${CONTACT_ENDPOINTS.default}?${urlSearchParams.toString()}`)
      .pipe(map((response) => response.data.contacts));
  }

  public updateContacts(payload: Partial<IContactDC>[]): Observable<IContactDC[]> {
    return this.httpClient
      .patch<NetworkBulkResponse<IContactDC>[]>(CONTACT_ENDPOINTS.default, payload)
      .pipe(map((response) => mapBulkResponse(response.data)));
  }

  public exportToCsvAll(): Observable<string> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('csv', '1');
    urlSearchParams.append('length', '-1');
    return this.httpClient
      .get<string>(`${CONTACT_ENDPOINTS.default}?${urlSearchParams.toString()}`)
      .pipe(map((response) => response.data));
  }

  public exportToCsvByFilters(queryParams: URLSearchParams): Observable<string> {
    const urlSearchParams = new URLSearchParams(queryParams);
    EXCLUDED_QUERY_PARAMS_TO_EXPORT.forEach((paramName) => {
      if (urlSearchParams.has(paramName)) {
        urlSearchParams.delete(paramName);
      }
    });
    urlSearchParams.append('csv', '1');
    urlSearchParams.append('length', '-1');
    return this.httpClient
      .get<string>(`${CONTACT_ENDPOINTS.default}?${urlSearchParams.toString()}`)
      .pipe(map((response) => response.data));
  }

  public exportToCsvByIds(ids: string[]): Observable<string> {
    return this.httpClient
      .post<string>(CONTACT_ENDPOINTS.exportToCsvByIds, { ids })
      .pipe(map((response) => response.data));
  }

  public wrongInfoReport(dto: {
    value: string;
    contact_uuid: string;
    entity_type: string;
  }): Observable<boolean> {
    return this.httpClient
      .post<boolean>(CONTACT_ENDPOINTS.wrongInfoReport, dto)
      .pipe(map((response) => response.data));
  }
}
