import { Container } from 'inversify';

import { GEO_SYNC_TYPES } from '@/ioc/types';

import { GeoSyncRepository, IGeoSyncRepository } from '../data';
import { GeoSyncUseCase, IGeoSyncUseCase } from '../domain';

export const bindGeoSyncModule = (container: Container): void => {
  container
    .bind<IGeoSyncRepository>(GEO_SYNC_TYPES.GeoSyncRepository)
    .to(GeoSyncRepository)
    .inSingletonScope();

  container.bind<IGeoSyncUseCase>(GEO_SYNC_TYPES.GeoSyncUseCase).to(GeoSyncUseCase);
};
