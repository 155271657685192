import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { REFERRAL_TYPES } from '@/ioc/types';

import { ReferrerTokenKey } from '@/features/referral';
import {
  IReferralApiService,
  IReferralRepository,
} from '@/features/referral/data/abstractions';
import { IReferralEmailRequestData } from '@/features/referral/data/dataContracts';
import { IReferralDao } from '@/features/referral/data/db';
import { mapInvitationDCtoEntity } from '@/features/referral/data/mappers';
import {
  IInvitationEntity,
  IReferralResponseDataEntity,
} from '@/features/referral/domain/entities';

@injectable()
export class ReferralRepository implements IReferralRepository {
  @inject(REFERRAL_TYPES.ReferralApiService)
  private api: IReferralApiService;

  @inject(REFERRAL_TYPES.ReferralDao)
  private dao: IReferralDao;

  sendInvitationEmail(
    data: IReferralEmailRequestData[],
  ): Observable<IReferralResponseDataEntity[]> {
    return this.api.sendInvitationEmail(data).pipe(
      map((response) =>
        response.map(
          (dc) =>
            ({
              email: dc.email,
              error: dc.error,
              referrerToken: dc.referrer_token,
            }) satisfies IReferralResponseDataEntity,
        ),
      ),
    );
  }

  async removeInvitation(uuid: string): Promise<void> {
    await this.dao.removeOne(uuid);
  }

  getInvitations(): Observable<IInvitationEntity[]> {
    return this.dao.findAll().pipe(map((res) => res.map(mapInvitationDCtoEntity)));
  }

  deleteReferralTokenFromStorage(): void {
    localStorage.removeItem(ReferrerTokenKey);
  }

  validateToken(token: string): Promise<boolean> {
    return firstValueFrom(this.api.validateToken(token));
  }
}
