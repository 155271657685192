import { Container } from 'inversify';

import { COOKIES_TYPES } from '@/ioc/types';

import { CookiesManager, ICookiesManager } from '../data';
import { AuthIdentityCookieUseCase, IAuthIdentityCookieUseCase } from '../domain';

export const bindCookiesModule = (container: Container): void => {
  container
    .bind<ICookiesManager>(COOKIES_TYPES.CookiesManager)
    .to(CookiesManager)
    .inSingletonScope();
  container
    .bind<IAuthIdentityCookieUseCase>(COOKIES_TYPES.AuthIdentityCookieUseCase)
    .to(AuthIdentityCookieUseCase)
    .inSingletonScope();
};
