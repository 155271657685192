export const countTotalPropertyValue = <T extends Record<string, unknown>>(
  property: keyof T,
  entities: T[],
): number => {
  return entities.reduce((prev, next) => {
    if (property in next) {
      prev += next[property] as number;
    }

    return prev;
  }, 0);
};
