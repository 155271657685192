import type { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const { variant, color, size } = ownerState as {
        variant: string;
        color: string;
        size: string;
      };

      let sizeStyle = {};

      switch (size) {
        case 'small':
          sizeStyle = {
            minHeight: 28,
            fontSize: 11,
            padding: '0 12px',
          };
          break;
        case 'medium':
          sizeStyle = {
            minHeight: 34,
            fontSize: 13,
            padding: '0 14px',
          };
          break;
        case 'large':
          sizeStyle = {
            minHeight: 40,
            fontSize: 14,
            padding: '0 16px',
          };
          break;
      }

      const commonStyle = {
        '.MuiButton-startIcon': {
          margin: '0 4px 0 0',
        },
        '&.MuiButton-root': {
          lineHeight: 1,
          textTransform: 'none',
          boxShadow: 'none',
          ...sizeStyle,
        },
      };

      if (variant === 'contained') {
        return {
          ...commonStyle,
          '&.Mui-disabled': {
            backgroundColor: theme.palette[color]?.disabled,
            color: 'white',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        };
      }

      if (variant === 'outlined') {
        return {
          ...commonStyle,
          '&:hover': {
            backgroundColor: theme.palette[color]?.dark,
            borderColor: theme.palette[color]?.dark,
            color: 'white',
          },
          '&.Mui-disabled': {
            borderColor: theme.palette[color]?.disabled,
            color: theme.palette[color]?.disabled,
          },
        };
      }

      if (variant === 'text') {
        return {
          ...commonStyle,
          '&:hover': {
            background: 'none',
            textDecoration: 'underline',
          },
          '&.Mui-disabled': {
            background: 'none',
            color: theme.palette[color]?.disabled,
          },
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        };
      }
    },
  },
};
