import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

export interface IServerTimeRepository {
  sync(): Promise<number>;
  now(): number;
}

@injectable()
export class ServerTimeRepository implements IServerTimeRepository {
  @inject(NETWORK_TYPES.HttpClient)
  private readonly httpClient: IHttpClient;

  private timeCorrection = 0;

  private get clientTime(): number {
    return Math.floor(Date.now() / 1000);
  }

  public now(): number {
    return this.clientTime + this.timeCorrection;
  }

  public async sync(): Promise<number> {
    try {
      const response = await firstValueFrom(
        this.httpClient.get<number>('/api/v1/account/time'),
      );
      const serverTime = Number.isFinite(response.data) ? Math.floor(response.data) : 0;

      this.timeCorrection = serverTime - this.clientTime;

      return this.timeCorrection;
    } catch (error) {
      return 0;
    }
  }
}
