import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { FairUsageLimitAlert } from '@/features/common/fairUsage';

import { OnboardingBanner } from './components/OnboardingBanner';
import { useDashboardHeaderViewModel } from './useDashboardHeaderViewModel';

export const DashboardHeader: FC = () => {
  const { t } = useTranslation('dashboard');
  const {
    accountFullName,
    isFairUsageLimitReached,
    onboardingStep,
    onClickInstallExtension,
    onClickRevealContact,
  } = useDashboardHeaderViewModel();

  if (onboardingStep) {
    return (
      <OnboardingBanner
        currentStep={onboardingStep}
        fullName={accountFullName}
        onClickInstallExtension={onClickInstallExtension}
        onClickRevealContact={onClickRevealContact}
      />
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack mb={2} mt={2.5}>
        <Typography fontSize="30px" fontWeight={500}>
          {t('title')}, {accountFullName}
        </Typography>
        <Typography variant="h2" fontWeight={400}>
          {t('subtitle')}
        </Typography>
      </Stack>
      {isFairUsageLimitReached && <FairUsageLimitAlert />}
    </Stack>
  );
};
