import { useObservableResult } from '@/utils/rx';

import { useCreditUseCase } from './useCreditUseCase';

export const useAggregatedCreditsProgress = () => {
  const creditUseCase = useCreditUseCase();

  return useObservableResult(() => creditUseCase.getAggregatedCreditsProgress(), {
    defaultData: 0,
    deps: [],
  });
};
