export enum EmailType {
  Work = 'work',
  Personal = 'personal',
  Predicted = 'predicted',
}

export enum EmailStatus {
  Valid = 'valid',
  CatchAll = 'catch_all',
  Invalid = 'invalid',
}

export enum PhoneType {
  FixedLine = 'fixed_line',
  Mobile = 'mobile',
  FixedLineOrMobile = 'fixed_line_or_mobile',
  TollFree = 'toll_free',
  PremiumRate = 'premium_rate',
  SharedCost = 'shared_cost',
  Voip = 'voip',
  PersonalNumber = 'personal_number',
  Pager = 'pager',
  Uan = 'uan',
  VoiceMail = 'voicemail',
  Unknown = 'unknown',
}

export type IContactEmailEntity = {
  type: EmailType;
  status: EmailStatus;
  customLabel: string;
  email: string;
};

export type IContactPhoneEntity = {
  customLabel: string;
  phone: string;
  normalizedPhone: string;
  type: Nullable<PhoneType>;
};

export type IContactSocialProfileEntity = {
  type: string;
  customLabel: string;
  userId: string;
};

export interface IContactEntity {
  uuid: string;
  linkedinId: string;
  workspaceId: string;
  contactListId: string;
  name: string;
  jobTitle: string;
  companyId: string;
  thumbnail: string;
  phones: IContactPhoneEntity[];
  emails: IContactEmailEntity[];
  socialProfiles: IContactSocialProfileEntity[];
  integrationUrls: { provider: string; link: string }[];
  assignedTo: string;
  createdAt: number;
  updatedAt: number;
  tags: string[];
}
