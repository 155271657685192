import {
  IContactListEntity,
  useContactListEntities,
} from '@/features/common/contactList';

const mapContactListEntityToSelectOption = (
  contactList: IContactListEntity,
): {
  value: string;
  label: string;
} => ({
  value: contactList.uuid,
  label: contactList.name,
});

export const useContactListSelectOptions = (): { value: string; label: string }[] => {
  return useContactListEntities().map(mapContactListEntityToSelectOption);
};
