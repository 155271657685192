import * as React from "react";
const SvgRevealContactIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 43, height: 44.202, viewBox: "0 0 43 44.202", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18720", transform: "translate(4915 -7268.784)" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 17884", transform: "translate(-4914 7282)", style: {
  stroke: "#000",
  fill: "#fff"
} }, /* @__PURE__ */ React.createElement("rect", { width: 41, height: 16, rx: 8, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: -0.5, y: -0.5, width: 42, height: 17, rx: 8.5, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17885", width: 14.054, height: 1.054, rx: 0.527, transform: "translate(-4900.027 7287.189)" }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17886", width: 8.432, height: 1.054, rx: 0.527, transform: "translate(-4900.027 7290)" }), /* @__PURE__ */ React.createElement("path", { d: "M.93 7.037a.287.287 0 1 0 .575 0A2.568 2.568 0 0 1 3 4.7a2 2 0 0 0 2.189 0 2.623 2.623 0 0 1 .733.51 2.571 2.571 0 0 1 .758 1.829.287.287 0 1 0 .575 0A3.142 3.142 0 0 0 6.328 4.8a3.183 3.183 0 0 0-.681-.513 2.012 2.012 0 1 0-3.111 0A3.143 3.143 0 0 0 .93 7.037zm3.162-5.462a1.437 1.437 0 1 1-1.438 1.437 1.439 1.439 0 0 1 1.438-1.437z", transform: "translate(-4910.794 7285.486)", style: {
  strokeWidth: ".5px",
  stroke: "#000"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16725" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 17887", transform: "rotate(-44.03 6586.13 9702.046)", style: {
  fill: "#ff5001"
}, d: "M0 0h11.543v4.617H0z" }), /* @__PURE__ */ React.createElement("g", { style: {
  fill: "#ff5001"
} }, /* @__PURE__ */ React.createElement("path", { d: "M11.077 9.832H.898c-.368 0-.706-.14-.977-.404a1.333 1.333 0 0 1-.421-.97V.874c0-.367.145-.703.42-.97C.193-.36.53-.5.899-.5h10.18c.367 0 .705.14.977.404.275.268.42.604.42.97v7.583c0 .367-.145.703-.42.971-.272.265-.61.404-.978.404z", style: {
  stroke: "none"
}, transform: "rotate(-44.03 6585.339 9702.589)" }), /* @__PURE__ */ React.createElement("path", { d: "M11.077 9.332c.24 0 .45-.087.629-.262.18-.175.27-.38.27-.613V.875a.829.829 0 0 0-.27-.613.873.873 0 0 0-.629-.262H.898C.658 0 .45.087.27.262A.829.829 0 0 0 0 .875v7.582c0 .234.09.438.27.613.18.175.389.262.628.262h10.18M.897.875l5.09 3.179 5.09-3.18v.803l-5.09 3.252-5.09-3.252V.875m10.18 9.457H.897a1.87 1.87 0 0 1-1.326-.546A1.822 1.822 0 0 1-1 8.457V.875c0-.505.198-.965.572-1.329A1.87 1.87 0 0 1 .898-1h10.18c.5 0 .959.189 1.326.546.374.364.571.824.571 1.329v7.582c0 .505-.197.965-.571 1.33a1.87 1.87 0 0 1-1.327.545z", style: {
  fill: "#fff",
  stroke: "none"
}, transform: "rotate(-44.03 6585.339 9702.589)" }))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31997", d: "M9.035.128C8.62.921 8.2 1.717 7.725 2.474a8.543 8.543 0 0 1-1.511 1.883 3.347 3.347 0 0 1-1.967.871 3.568 3.568 0 0 1-.669-.018 4.8 4.8 0 0 0 .609-1.256 3.119 3.119 0 0 0-.238-2.57 1.855 1.855 0 0 0-1.982-.9A2.131 2.131 0 0 0 .432 2.091a2.88 2.88 0 0 0 .706 2.392A3.532 3.532 0 0 0 2.7 5.526a8.624 8.624 0 0 1-.35.388A15.659 15.659 0 0 1 .112 7.825c-.265.187-.012.627.256.439a16.548 16.548 0 0 0 2.29-1.938 8.671 8.671 0 0 0 .581-.657 3.831 3.831 0 0 0 2.329-.275 5.834 5.834 0 0 0 2.156-2.01 25.248 25.248 0 0 0 1.748-3c.151-.289-.286-.547-.437-.256zM2.264 4.769a2.81 2.81 0 0 1-1.313-1.7 1.725 1.725 0 0 1 .621-1.85 1.282 1.282 0 0 1 1.756.181 2.387 2.387 0 0 1 .444 2.133A4.291 4.291 0 0 1 3.037 5.1a3.2 3.2 0 0 1-.773-.331z", transform: "rotate(64 -8290.277 -268.506)", style: {
  strokeWidth: ".5px",
  stroke: "#000"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16726" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31998", d: "M7.438 1.276a4.358 4.358 0 1 0 1.277 3.08 4.358 4.358 0 0 0-1.277-3.08zM6.6 6.07l-.221.219a1.162 1.162 0 0 1-1.1.314A3.861 3.861 0 0 1 4.16 6.1a5.181 5.181 0 0 1-.885-.713 5.221 5.221 0 0 1-.661-.8 4.067 4.067 0 0 1-.5-1.005 1.162 1.162 0 0 1 .291-1.187l.259-.259a.184.184 0 0 1 .261 0l.816.816a.184.184 0 0 1 0 .261l-.479.479a.391.391 0 0 0-.041.508 5.606 5.606 0 0 0 .574.67 5.572 5.572 0 0 0 .755.635.394.394 0 0 0 .5-.044l.463-.471a.184.184 0 0 1 .261 0l.818.819a.184.184 0 0 1 .008.261z", transform: "translate(-4907.757 7268.784)", style: {
  fill: "#ff5001"
} }))));
export default SvgRevealContactIcon;
