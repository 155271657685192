import { IProvideGoogleContactsReqDC } from '@/features/common/account/data/dataContracts/ProvideGoogleContactsReqDC';
import { IProvideGoogleContactsReqEntity } from '@/features/common/account/domain/entities/ProvideGoogleContactsReqEntity';

export const mapGoogleContactsReqEntityToDC = (
  req: IProvideGoogleContactsReqEntity,
): IProvideGoogleContactsReqDC => {
  return {
    access_token: req.accessToken,
    refresh_token: req.refreshToken,
    provider: req.provider,
  };
};
