import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@mui/material';
import clsx from 'clsx';

import { ICompanyEntity } from '@/features/common/contact';

import {
  ChatIcon,
  DollarIcon,
  FactoryIcon,
  FlagIcon,
  GroupLargeIcon,
  InfoIcon,
  LocationIcon,
  PhoneIcon,
  TrendingUpIcon,
} from '@/components/Icons';

import { getSocialItemByUrl, SocialItemResult } from '../../../../utils';
import { Logo } from '../Logo';

import { useFormattedCompanyData } from './hooks';

import styles from './styles.module.scss';

export const InfoTooltip: FC<{
  data: ICompanyEntity;
}> = ({ data }) => {
  const { t } = useTranslation('contactsTable');

  const { totalFunding, totalRevenue, showEmployeeSection, employeeCountResult, format } =
    useFormattedCompanyData(data);

  const socialItems = (data.socialNetworks || [])
    .map((item) => getSocialItemByUrl(item.url))
    .filter((item) => Boolean(item)) as SocialItemResult[];

  return (
    <Box className={styles.infoBox}>
      <Box className={styles.infoBoxHeader}>
        <Box className={styles.infoBoxHeaderColStart}>
          <Logo images={data.images} name={data.name} image={data.image} />
        </Box>

        <Box className={styles.infoBoxHeaderColEnd}>
          <Box component="span" className={styles.infoBoxName}>
            {data.name}
          </Box>
          {data.websites.length &&
            data.websites.map((item) => (
              <Link
                key={item.url}
                href={item.url}
                target="blank"
                className={styles.infoBoxLink}
              >
                {item.url}
              </Link>
            ))}
        </Box>
      </Box>
      <Box className={styles.infoBoxList} component="ul">
        {showEmployeeSection ? (
          <Box
            className={styles.infoBoxListItem}
            component="li"
            key="showEmployeeSection"
          >
            <Box className={styles.infoBoxListItemIcon}>
              <GroupLargeIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {t('companyCell.employees', {
                amount: employeeCountResult,
              })}
            </Box>
          </Box>
        ) : null}
        {!!data.description ? (
          <Box className={styles.infoBoxListItem} key="description" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <InfoIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {data.description}
            </Box>
          </Box>
        ) : null}
        {!!data.industry ? (
          <Box className={styles.infoBoxListItem} key="industry" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <FactoryIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {data.industry}
            </Box>
          </Box>
        ) : null}
        {!!data.address ? (
          <Box className={styles.infoBoxListItem} key="address" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <LocationIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {data.address}
            </Box>
          </Box>
        ) : null}
        {!!data.phone ? (
          <Box className={styles.infoBoxListItem} key="phone" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <PhoneIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {format(data.phone || '')}
            </Box>
          </Box>
        ) : null}
        {!!totalRevenue ? (
          <Box className={styles.infoBoxListItem} key="totalRevenue" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <TrendingUpIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              ~{totalRevenue}
            </Box>
          </Box>
        ) : null}
        {!!totalFunding ? (
          <Box className={styles.infoBoxListItem} component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <DollarIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {t('companyCell.raised_amount', {
                amount: totalFunding,
                count: data.fundingRounds.length,
              })}
            </Box>
          </Box>
        ) : null}
        {!!socialItems.length ? (
          <Box className={styles.infoBoxListItem} key="socialNetworks" component="li">
            <Box className={clsx(styles.infoBoxListItemIcon, styles.socialNetworksIcon)}>
              <ChatIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {socialItems.map(({ url, Icon }) => {
                return (
                  <Link
                    key={url}
                    href={url}
                    target="_blank"
                    className={styles.infoBoxListItemSocialLink}
                  >
                    <Icon />
                  </Link>
                );
              })}
            </Box>
          </Box>
        ) : null}
        {!!data.foundedYear ? (
          <Box className={styles.infoBoxListItem} key="foundedYear" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <FlagIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              {data.foundedYear}
            </Box>
          </Box>
        ) : null}
        {!!data.topics.length ? (
          <Box className={styles.infoBoxListItem} key="topics" component="li">
            <Box className={styles.infoBoxListItemIcon}>
              <FactoryIcon />
            </Box>
            <Box className={styles.infoBoxListItemBody} component="span">
              <Box component="span" className={styles.infoBoxListItemLabel}>
                {t('companyCell.specialties')}
              </Box>
              <Box className={styles.infoBoxListItemTopicsBox}>
                {data.topics.map((item, index) => {
                  return (
                    <Box key={`link-${index}`} className={styles.infoBoxListItemTopic}>
                      {item}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
