const DATA = `AF🇦🇫Afghanistan
AO🇦🇴Angola
AL🇦🇱Albania
AD🇦🇩Andorra
AE🇦🇪United Arab Emirates
AI🇦🇮Anguilla
AR🇦🇷Argentina
AM🇦🇲Armenia
AG🇦🇬Antigua and Barbuda
AU🇦🇺Australia
AS🇦🇸American Samoa
AT🇦🇹Austria
AW🇦🇼Aruba
AZ🇦🇿Azerbaijan
BI🇧🇮Burundi
BE🇧🇪Belgium
BJ🇧🇯Benin
BF🇧🇫Burkina Faso
BD🇧🇩Bangladesh
BG🇧🇬Bulgaria
BH🇧🇭Bahrain
BS🇧🇸Bahamas
BA🇧🇦Bosnia and Herzegovina
BY🇧🇾Belarus
BZ🇧🇿Belize
BO🇧🇴Bolivia
BR🇧🇷Brazil
BB🇧🇧Barbados
BM🇧🇲Bermuda
BN🇧🇳Brunei
BQ🇧🇶Bonaire, Sint Eustatius and Saba
BT🇧🇹Bhutan
BW🇧🇼Botswana
CF🇨🇫Central African Republic
CA🇨🇦Canada
CH🇨🇭Switzerland
CK🇨🇰Cook Islands
CL🇨🇱Chile
CN🇨🇳China
CI🇨🇮Côte d\`Ivoire
CM🇨🇲Cameroon
CD🇨🇩DR Congo
CG🇨🇬Republic of the Congo
CO🇨🇴Colombia
KM🇰🇲Comoros
CV🇨🇻Cape Verde
CR🇨🇷Costa Rica
CU🇨🇺Cuba
CY🇨🇾Cyprus
CW🇨🇼Curaçao
CZ🇨🇿Czech Republic
DE🇩🇪Germany
DJ🇩🇯Djibouti
DM🇩🇲Dominica
DK🇩🇰Denmark
DO🇩🇴Dominican Republic
DZ🇩🇿Algeria
EC🇪🇨Ecuador
EG🇪🇬Egypt
ER🇪🇷Eritrea
ES🇪🇸Spain
EE🇪🇪Estonia
ET🇪🇹Ethiopia
FI🇫🇮Finland
FJ🇫🇯Fiji
FK🇫🇰Falkland Islands
FM🇫🇲Micronesia
FO🇫🇴Faroe Islands
FR🇫🇷France
GA🇬🇦Gabon
GB🇬🇧United Kingdom
GE🇬🇪Georgia
GF🇬🇫French Guiana
GH🇬🇭Ghana
GI🇬🇮Gibraltar
GL🇬🇱Greenland
GN🇬🇳Guinea
GM🇬🇲Gambia
GP🇬🇵Guadeloupe
GU🇬🇺Guam
GW🇬🇼Guinea-Bissau
GQ🇬🇶Equatorial Guinea
GR🇬🇷Greece
GD🇬🇩Grenada
GT🇬🇹Guatemala
GY🇬🇾Guyana
HK🇭🇰Hong Kong
HN🇭🇳Honduras
HR🇭🇷Croatia
HT🇭🇹Haiti
HU🇭🇺Hungary
ID🇮🇩Indonesia
IN🇮🇳India
IO🇩🇬Diego Garcia
IE🇮🇪Ireland
IR🇮🇷Iran
IQ🇮🇶Iraq
IS🇮🇸Iceland
IL🇮🇱Israel
IT🇮🇹Italy
JM🇯🇲Jamaica
JO🇯🇴Jordan
JP🇯🇵Japan
KZ🇰🇿Kazakhstan
KE🇰🇪Kenya
KG🇰🇬Kyrgyzstan
KH🇰🇭Cambodia
KI🇰🇮Kiribati
KN🇰🇳Saint Kitts and Nevis
KR🇰🇷South Korea
KW🇰🇼Kuwait
KY🇰🇾Cayman Islands
LA🇱🇦Laos
LB🇱🇧Lebanon
LR🇱🇷Liberia
LY🇱🇾Libya
LC🇱🇨Saint Lucia
LI🇱🇮Liechtenstein
LK🇱🇰Sri Lanka
LS🇱🇸Lesotho
LT🇱🇹Lithuania
LU🇱🇺Luxembourg
LV🇱🇻Latvia
MA🇲🇦Morocco
MC🇲🇨Monaco
MD🇲🇩Moldova
MG🇲🇬Madagascar
MP🇲🇵Northern Mariana Islands
MO🇲🇴Macau
MS🇲🇸Montserrat
MV🇲🇻Maldives
MX🇲🇽Mexico
MH🇲🇭Marshall Islands
MK🇲🇰Macedonia
ML🇲🇱Mali
MT🇲🇹Malta
MM🇲🇲Myanmar
ME🇲🇪Montenegro
MN🇲🇳Mongolia
MZ🇲🇿Mozambique
MQ🇲🇶Martinique
MR🇲🇷Mauritania
MU🇲🇺Mauritius
MW🇲🇼Malawi
MY🇲🇾Malaysia
NA🇳🇦Namibia
NC🇳🇨New Caledonia
NE🇳🇪Niger
NF🇳🇫Norfolk Island
NG🇳🇬Nigeria
NI🇳🇮Nicaragua
NL🇳🇱Netherlands
NO🇳🇴Norway
NP🇳🇵Nepal
NU🇳🇺Niue
NR🇳🇷Nauru
NZ🇳🇿New Zealand
OM🇴🇲Oman
PK🇵🇰Pakistan
PA🇵🇦Panama
PE🇵🇪Peru
PF🇵🇫French Polynesia
PH🇵🇭Philippines
PM🇵🇲Saint Pierre and Miquelon
PR🇵🇷Puerto Rico
PS🇵🇸Palestine
PW🇵🇼Palau
PG🇵🇬Papua New Guinea
PL🇵🇱Poland
KP🇰🇵North Korea
PT🇵🇹Portugal
PY🇵🇾Paraguay
QA🇶🇦Qatar
RE🇷🇪Réunion
RO🇷🇴Romania
RU🇷🇺Russia
RW🇷🇼Rwanda
SA🇸🇦Saudi Arabia
SD🇸🇩Sudan
SN🇸🇳Senegal
SG🇸🇬Singapore
SH🇸🇭Saint Helena
SB🇸🇧Solomon Islands
SL🇸🇱Sierra Leone
SV🇸🇻El Salvador
SM🇸🇲San Marino
SO🇸🇴Somalia
RS🇷🇸Serbia
SS🇸🇸South Sudan
ST🇸🇹São Tomé and Príncipe
SR🇸🇷Suriname
SK🇸🇰Slovakia
SI🇸🇮Slovenia
SE🇸🇪Sweden
SZ🇸🇿Swaziland
SC🇸🇨Seychelles
SX🇸🇽Sint Maarten
SY🇸🇾Syria
TC🇹🇨Turks and Caicos Islands
TD🇹🇩Chad
TG🇹🇬Togo
TH🇹🇭Thailand
TJ🇹🇯Tajikistan
TK🇹🇰Tokelau
TM🇹🇲Turkmenistan
TL🇹🇱Timor-Leste
TO🇹🇴Tonga
TT🇹🇹Trinidad and Tobago
TN🇹🇳Tunisia
TR🇹🇷Turkey
TV🇹🇻Tuvalu
TW🇹🇼Taiwan
TZ🇹🇿Tanzania
UG🇺🇬Uganda
UA🇺🇦Ukraine
UY🇺🇾Uruguay
US🇺🇸United States
UZ🇺🇿Uzbekistan
VA🇻🇦Vatican City
VC🇻🇨Saint Vincent and the Grenadines
VE🇻🇪Venezuela
VG🇻🇬British Virgin Islands
VI🇻🇮US Virgin Islands
VN🇻🇳Vietnam
VU🇻🇺Vanuatu
WF🇼🇫Wallis and Futuna
WS🇼🇸Samoa
XK🇽🇰Kosovo
YE🇾🇪Yemen
ZA🇿🇦South Africa
ZM🇿🇲Zambia
ZW🇿🇼Zimbabwe`;

export type CountryMetadata = {
  code: string;
  flag: string;
  name: string;
};

export const countries: CountryMetadata[] = DATA.split('\n').map((line) => {
  const code = line.slice(0, 2);
  const flag = line.slice(2, 6);
  const name = line.slice(6);

  return { code, flag, name };
});
