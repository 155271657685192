import { ICreditEntity } from '../../domain';
import { ICreditDC } from '../dataContracts';

export const mapCreditDcToEntity = (dc: ICreditDC): ICreditEntity => {
  return {
    workspaceId: dc.workspaceId,
    issuer: dc.issuer,
    creditType: dc.credit_type,
    issuedCredits: dc.issued_credits,
    usedCredits: dc.used_credits,
    activatesAt: dc.activates_at,
    expiresAt: dc.expires_at,
    isActive: dc.is_active,
    isArchived: dc.is_archived,
    isUnlimited: dc.is_unlimited,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
  };
};
