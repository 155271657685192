import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, OutlinedInput, Popover, Typography } from '@mui/material';
import clsx from 'clsx';

import { ITagEntity } from '@/features/common/tag';

import { FormField } from '@/components';
import { CheckIcon } from '@/components/Icons';

import { EditTagConfirmationModal } from './components/EditTagConfirmationModal';
import { useTagSettingsDialogViewModel } from './useTagSettingsDialogViewModel';

import styles from './styles.module.scss';

interface Props {
  anchorEl: HTMLElement | null;
  tag?: Pick<ITagEntity, 'name' | 'color' | 'uuid'>;
  onClose(): void;
}

const TagSettingsDialogBody: FC<Pick<Props, 'onClose' | 'tag'>> = ({ tag, onClose }) => {
  const { t } = useTranslation(['tag', 'validation']);
  const {
    handleSave,
    updateTag,
    form,
    editTagModal,
    colorsList,
    saveDisabled,
    handleChangeName,
  } = useTagSettingsDialogViewModel({ tag, onClose });
  const color = form.watch('color');
  const errorText = t(`${form.formState.errors.name?.message}`);

  return (
    <>
      <FormField error={!!form.formState.errors.name} errorText={errorText}>
        <OutlinedInput
          {...form.register('name')}
          placeholder={t('createTag.dialogNamePlaceholder')}
          onChange={(e): void => handleChangeName(e.target.value)}
          type="text"
          autoFocus
        />
      </FormField>
      <Typography className={styles.ColorTitle}>
        {t('createTag.dialogColorTitle')}
      </Typography>
      <Box className={styles.ColorPicker}>
        {colorsList.map((tagColor) => {
          return (
            <Box
              key={tagColor}
              onClick={(): void => {
                form.setValue('color', tagColor, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              className={clsx(styles.ColorPickerItemWrapper, {
                [styles.Checked]: tagColor === color,
              })}
            >
              <Box className={styles.ColorPickerItem} style={{ background: tagColor }} />
              <CheckIcon />
            </Box>
          );
        })}
      </Box>
      <Box className={styles.Buttons}>
        <Button color="info" onClick={onClose}>
          {t('createTag.dialogCancel')}
        </Button>
        <Button color="primary" onClick={handleSave} disabled={saveDisabled}>
          {t('createTag.dialogSave')}
        </Button>
      </Box>
      {!!tag && (
        <EditTagConfirmationModal modalController={editTagModal} onConfirm={updateTag} />
      )}
    </>
  );
};

export const TagSettingsDialog: React.FC<Props> = ({ anchorEl, onClose, tag }) => {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorPosition={{
        top: 0,
        left: 0,
      }}
      classes={{ paper: styles.DialogPaper }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      keepMounted={false}
    >
      <TagSettingsDialogBody tag={tag} onClose={onClose} />
    </Popover>
  );
};
