import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LinkedinIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        data-name="Path 10639"
        d="M3.077 0h13.846A3.077 3.077 0 0 1 20 3.077v13.846A3.077 3.077 0 0 1 16.923 20H3.077A3.077 3.077 0 0 1 0 16.923V3.077A3.077 3.077 0 0 1 3.077 0z"
        fill="#0a66c2"
      />
      <path
        data-name="Path 10502"
        d="M36.353 34.477A1.477 1.477 0 1 1 34.877 33a1.477 1.477 0 0 1 1.476 1.477z"
        transform="translate(-28.776 -28.413)"
        fill="#fff"
      />
      <path
        data-name="Path 10503"
        d="M39.048 69.646v6.523a.365.365 0 0 1-.365.367h-1.557a.365.365 0 0 1-.366-.366v-6.524a.366.366 0 0 1 .366-.366h1.556a.366.366 0 0 1 .366.366z"
        transform="translate(-31.805 -61.122)"
        fill="#fff"
      />
      <path
        data-name="Path 10504"
        d="M77.574 72.05v3.158a.336.336 0 0 1-.337.337h-1.673a.336.336 0 0 1-.337-.337v-3.06c0-.457.134-2-1.194-2-1.029 0-1.238 1.056-1.28 1.531v3.53a.337.337 0 0 1-.332.337h-1.615a.336.336 0 0 1-.336-.337v-6.583a.336.336 0 0 1 .336-.337h1.616a.337.337 0 0 1 .337.337v.569a2.291 2.291 0 0 1 2.156-1.015c2.675 0 2.659 2.498 2.659 3.87z"
        transform="translate(-62.197 -60.13)"
        fill="#fff"
      />
    </SvgIcon>
  );
};
