import { FC } from 'react';

import { ICustomerReviewEntity } from '@/features/plans/ui/entities';

import QuoteImg from './assets/quote.png';

import styles from './styles.module.scss';

type ReviewCardProps = ICustomerReviewEntity;

export const ReviewCard: FC<ReviewCardProps> = ({ review, author }) => {
  return (
    <article className={styles.reviewCard}>
      <div className={styles.reviewCardWrapper}>
        <img
          src={QuoteImg}
          className={styles.reviewCardQuote}
          height="72px"
          width="86px"
        />
        <p>{review}</p>
        <div className={styles.reviewCardAuthor}>
          <img src={author.avatarUrl} width="64px" height="64px" />
          <div className={styles.reviewCardAuthorInfo}>
            <span className={styles.reviewCardAuthorName}>{author.fullName}</span>
            <span className={styles.reviewCardAuthorCompany}>{author.position}</span>
          </div>
        </div>
      </div>
    </article>
  );
};
