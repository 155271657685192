import { injectable } from 'inversify';

import { CollectionName, DbCollectionCreator } from '@/features/system/db';

import type { IInvitationDC } from '../dataContracts';

import { referralMigrationStrategy } from './migrations';
import { referralSchema } from './schema';

@injectable()
export class ReferralCollectionCreator extends DbCollectionCreator<IInvitationDC> {
  constructor() {
    super({
      collectionName: CollectionName.Invitation,
      schema: referralSchema,
      migrationStrategies: referralMigrationStrategy,
    });
  }
}
