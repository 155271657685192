import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import { EXTERNAL_ROUTES } from '@/router/routes';

import {
  ACCOUNT_TYPES,
  ANALYTICS_TYPES,
  APP_LOGGER_TYPES,
  AUTH_TYPES,
  EXTENSION_TYPES,
  LEADER_ELECTION_TYPES,
  WORKSPACE_TYPES,
} from '@/ioc/types';

import {
  ANALYTICS_EVENTS,
  type IAnalyticsRepository,
  WalkthroughStep,
} from '@/features/system/analytics';
import { FbPixelEvent, fbPixelTrackEvent } from '@/features/system/FbPixel';
import type { ILeaderElectionRepository } from '@/features/system/leaderElection';
import type { IAppLogger } from '@/features/system/logger';

import { isMobile } from '@/utils/isMobile';

import { type IAccountEntity, type IAccountRepository, UserRole } from '../../account';
import { AuthStatus, type IAuthRepository } from '../../auth';
import type { IExtensionRepository } from '../../extension';
import type { IWorkspaceEntity, IWorkspaceRepository } from '../../workspace';

import type { IOnboardingUseCase } from './abstractions';
import { mapStepToAccountOnboardingField } from './mappers';
import type { OnboardingStep, OnboardingStepStatus } from './types';

@injectable()
export class OnboardingUseCase implements IOnboardingUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  @inject(EXTENSION_TYPES.ExtensionRepository)
  private readonly extensionRepository: IExtensionRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private readonly authRepository: IAuthRepository;

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private readonly leaderElectionRepository: ILeaderElectionRepository;

  @inject(ANALYTICS_TYPES.AnalyticsRepository)
  private readonly analyticsRepository: IAnalyticsRepository;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  public getStatus(): Observable<'completed' | 'incompleted'> {
    return this.getStepList().pipe(
      map((steps) => {
        return steps.every((step) => step.status === 'completed')
          ? 'completed'
          : 'incompleted';
      }),
    );
  }

  private toStatus(status: boolean): OnboardingStepStatus {
    return status ? 'completed' : 'incompleted';
  }

  private listenerGuard(): Observable<boolean> {
    return combineLatest({
      isLeader: this.leaderElectionRepository.getIsLeaderSubject().asObservable(),
      authStatus: this.authRepository.getAuthStatus(),
    }).pipe(
      map(({ isLeader, authStatus }) => authStatus === AuthStatus.Authorized && isLeader),
      distinctUntilChanged(),
      filter((canListen) => canListen),
    );
  }

  private async completeStep(step: OnboardingStep): Promise<void> {
    try {
      await this.accountRepository.completeOnboardingStep([
        mapStepToAccountOnboardingField(step),
      ]);
    } catch (error) {
      this.logger.error(error);
    }
  }

  private listenToExtensionInstallation(): Observable<void> {
    return this.extensionRepository.isExtensionInstalled().pipe(
      distinctUntilChanged(),
      filter((extensionInstalled) => !!extensionInstalled),
      switchMap(() => {
        this.analyticsRepository.trackEvent(ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH, {
          step: WalkthroughStep.installedExtension,
        });
        fbPixelTrackEvent({ type: FbPixelEvent.SubmitApplication });
        return this.completeStep('installExtension');
      }),
    );
  }

  private listenToProspectRevealing(): Observable<void> {
    return this.accountRepository.getAccount().pipe(
      map((account) => (account?.totalCreditsUsed ?? 0) > 0),
      distinctUntilChanged(),
      filter((hasUsedCredits) => hasUsedCredits),
      switchMap(() => {
        this.analyticsRepository.trackEvent(ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH, {
          step: WalkthroughStep.revealedContact,
        });
        return this.completeStep('revealContact');
      }),
    );
  }

  private listenForInviteTeamMember(): Observable<void> {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
    }).pipe(
      map(
        ({ account, workspace }) =>
          !!account &&
          !!workspace &&
          (workspace.members.some((member) => member.invitedBy === account.uuid) ||
            account.referrerCreditsEarned > 0),
      ),
      distinctUntilChanged(),
      filter((hasInvitedMembers) => hasInvitedMembers),
      switchMap(() => this.completeStep('inviteTeamMember')),
    );
  }

  public startListeners(): Observable<void> {
    return this.listenerGuard().pipe(
      switchMap(() =>
        combineLatest([
          this.listenToExtensionInstallation(),
          this.listenToProspectRevealing(),
          this.listenForInviteTeamMember(),
        ]),
      ),
      map(() => void 0),
    );
  }

  public getStepStatus(step: OnboardingStep): Observable<OnboardingStepStatus> {
    return this.accountRepository.getAccount().pipe(
      filter((account) => !!account),
      map((account) => {
        const { onboarding } = account.settings;
        if (onboarding[mapStepToAccountOnboardingField(step)])
          return 'completed' as OnboardingStepStatus;
        return 'incompleted' as OnboardingStepStatus;
      }),
      distinctUntilChanged(),
    );
  }

  public getStepList(): Observable<
    { step: OnboardingStep; status: OnboardingStepStatus }[]
  > {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      subscription: this.workspaceRepository.getCurrentWorkspaceSubscription(),
    }).pipe(
      filter(({ account, subscription }) => !!account && !!subscription),
      map(
        ({
          account,
          subscription,
        }: {
          account: IAccountEntity;
          subscription: IWorkspaceEntity['subscription'];
        }) => {
          const isManager = account.role === UserRole.Manager;
          const isMember = account.role === UserRole.Member;
          const { onboarding } = account.settings;

          if (subscription.planIsFree) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(onboarding.isExtensionInstalled),
              },
              {
                step: 'revealContact',
                status: this.toStatus(onboarding.isContactRevealed),
              },
              {
                step: 'inviteTeamMember',
                status: this.toStatus(onboarding.isTeamMemberInvited),
              },
            ];
          }

          if (isManager) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(onboarding.isExtensionInstalled),
              },
              {
                step: 'exportContact',
                status: this.toStatus(onboarding.isContactExported),
              },
            ];
          }

          if (isMember) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(onboarding.isExtensionInstalled),
              },
              {
                step: 'revealContact',
                status: this.toStatus(onboarding.isContactRevealed),
              },
              {
                step: 'exportContact',
                status: this.toStatus(onboarding.isContactExported),
              },
            ];
          }

          return [
            {
              step: 'installExtension',
              status: this.toStatus(onboarding.isExtensionInstalled),
            },
            {
              step: 'revealContact',
              status: this.toStatus(onboarding.isContactRevealed),
            },
            {
              step: 'inviteTeamMember',
              status: this.toStatus(onboarding.isTeamMemberInvited),
            },
            {
              step: 'exportContact',
              status: this.toStatus(onboarding.isContactExported),
            },
          ];
        },
      ),
    );
  }

  public completeInviteTeamMemberStep(): Promise<void> {
    return this.completeStep('inviteTeamMember');
  }

  public completeExportContactStep(): Promise<void> {
    return this.completeStep('exportContact');
  }

  public completeSignupStep(): Promise<void> {
    return this.completeStep('signup');
  }

  public completeClickDownloadExtensionStep(): Promise<void> {
    return this.completeStep('clickDownloadExtension');
  }

  public completeViewDashboardStep(): Promise<void> {
    return this.completeStep('viewDashboard');
  }

  public completeViewInstallExtensionPageStep(): Promise<void> {
    return this.completeStep('viewInstallExtensionPage');
  }

  public async completeMobileSignupOpenChromeStoreStep(): Promise<void> {
    const isExtensionInstalled = await firstValueFrom(
      this.extensionRepository.isExtensionInstalled(),
    );

    if (isExtensionInstalled) return;
    if (!isMobile()) return;

    await this.completeStep('mobileSignupOpenChromeStore');

    window.location.href = EXTERNAL_ROUTES.EXTENSION_URL;
  }
}
