import { combineLatest, of } from 'rxjs';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IIntegrationEntity } from '../../domain';
import { IMappingByObjectTypeEntityUI } from '../entities';

import { useIntegrationUseCase } from './useIntegrationUseCase';

export const useCurrentIntegrationMap = (
  integration: Nullable<IIntegrationEntity>,
): ObservableResult<
  IMappingByObjectTypeEntityUI,
  unknown,
  IMappingByObjectTypeEntityUI
> => {
  const integrationUseCase = useIntegrationUseCase();

  return useObservableResult<IMappingByObjectTypeEntityUI>(
    () =>
      integration
        ? combineLatest(
            integration.supportedObjects.reduce((acc, objectType) => {
              acc[objectType] = integrationUseCase.getMapping(
                integration.provider,
                objectType,
              );
              return acc;
            }, {} satisfies IMappingByObjectTypeEntityUI),
          )
        : of({}),
    {
      deps: [integration?.provider],
      defaultData: {} satisfies IMappingByObjectTypeEntityUI,
    },
  );
};
