import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { LazyLottie } from '@/components';

import ExportImage from '@/assets/images/exportImage.webp';

import styles from './styles.module.scss';

export const Pending: FC<{ text: string }> = ({ text }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.animationContainer}>
        <LazyLottie
          dataSrc="/animations/Export/animation.json"
          previewSrc={ExportImage}
          autoPlay={true}
        />
      </Box>
      <Typography variant="subtitle1" className={styles.description}>
        {text}
      </Typography>
    </Box>
  );
};
