import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { PROSPECT_TASK_TYPES } from '@/ioc/types';

import { IProspectTaskUseCase } from './abstractions/IProspectTaskUseCase';
import { IProspectTaskRepository } from './abstractions';
import { IProspectTaskProgressEntity } from './entities';
import { ProspectTaskRetryResponse } from './types';

@injectable()
export class ProspectTaskUseCase implements IProspectTaskUseCase {
  @inject(PROSPECT_TASK_TYPES.ProspectTaskRepository)
  private readonly prospectTaskRepository: IProspectTaskRepository;

  public getProspectTaskProgress(): Observable<IProspectTaskProgressEntity> {
    return this.prospectTaskRepository.getProspectTaskProgress();
  }

  public getCleanupIndicator(): Observable<number> {
    return this.prospectTaskRepository.getCleanupIndicator();
  }

  public sendCleanupEvent(): void {
    return this.prospectTaskRepository.sendCleanupEvent();
  }

  public retry(taskId: string): Promise<ProspectTaskRetryResponse> {
    return this.prospectTaskRepository.retry(taskId);
  }
}
