import { injectable } from 'inversify';

import { DbStateCreator } from '@/features/system/db/data/DbStateCreator';

import { IIntegrationsStateDC } from './IntegrationsState';

@injectable()
export class IntegrationsStateCreator extends DbStateCreator<IIntegrationsStateDC> {
  constructor() {
    super({
      stateName: 'integrations',
    });
  }
}
