import { type FC, Fragment, type ReactNode } from 'react';

import { useBillingOverlayViewModel } from './BillingOverlayViewModel';

type BillingOverlayProps = {
  children: ReactNode;
};

export const BillingOverlay: FC<BillingOverlayProps> = ({ children }) => {
  const { overlay } = useBillingOverlayViewModel();

  return (
    <Fragment>
      {overlay}
      {children}
    </Fragment>
  );
};
