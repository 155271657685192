import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import { CheckboxWithLabel } from '@/components';
import { IOSSwitch } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import { ViewLayout } from '../ViewLayout';

import EmailIcon from './assets/Email.svg?react';
import PhoneIcon from './assets/Phone.svg?react';
import { useConfigViewSettingsViewModel } from './useConfigViewSettingsViewModel';

import styles from './styles.module.scss';

export const ConfigViewSettings: FC = () => {
  const { t } = useTranslation('enrichment');
  const {
    form,
    settingsIsOpen,
    setSettingsIsOpen,
    toggleSetting,
    settingIsChecked,
    toggleAllSettings,
    items,
    isSomeSelected,
    startEnrich,
    back,
    isDisabled,
    selectedSettingsCount,
  } = useConfigViewSettingsViewModel();

  return (
    <ViewLayout>
      <ViewLayout.Header title={t('uploadingSettingsModal.title')} showFileMetadata />
      <ViewLayout.Container>
        <Typography variant="h3" className={styles.Title}>
          {t('uploadingSettingsModal.form.title')}
        </Typography>
        <Typography variant="subtitle1" className={styles.SubTitle}>
          {t('uploadingSettingsModal.form.subTitle')}
        </Typography>
        <Box className={styles.Fields}>
          <Box className={styles.FieldItem}>
            <Box className={styles.FieldItemSwitch}>
              <Controller
                name="enrichByEmail"
                control={form.control}
                render={({ field }) => {
                  return (
                    <IOSSwitch
                      value={field.value}
                      checked={field.value}
                      onChange={(_, checked) => {
                        field.onChange(checked);
                        form.trigger('enrichByEmail');
                        form.trigger('enrichByPhone');
                      }}
                    />
                  );
                }}
              />
            </Box>
            <Box className={styles.FieldItemIcon}>
              <EmailIcon />
            </Box>
            <Box className={styles.FieldItemName} component="span">
              {t('uploadingSettingsModal.form.enrichTypeFields.email')}
            </Box>
          </Box>
          <Box className={styles.FieldItem}>
            <Box className={styles.FieldItemSwitch}>
              <Controller
                name="enrichByPhone"
                control={form.control}
                render={({ field }) => {
                  return (
                    <IOSSwitch
                      value={field.value}
                      checked={field.value}
                      onChange={(_, checked) => {
                        field.onChange(checked);
                        form.trigger('enrichByEmail');
                        form.trigger('enrichByPhone');
                      }}
                    />
                  );
                }}
              />
            </Box>
            <Box className={styles.FieldItemIcon}>
              <PhoneIcon />
            </Box>
            <Box className={styles.FieldItemName} component="span">
              {t('uploadingSettingsModal.form.enrichTypeFields.phone')}
            </Box>
          </Box>
        </Box>
        <Box className={styles.Settings}>
          <Accordion
            expanded={settingsIsOpen}
            onChange={(_, expanded) => setSettingsIsOpen(expanded)}
            className={styles.SettingsPaper}
          >
            <AccordionSummary
              expandIcon={<ArrowDownIcon className={styles.SettingsSummaryIcon} />}
              className={styles.SettingsSummary}
              classes={{
                content: styles.SettingsSummaryContent,
              }}
            >
              <Stack>
                <Box component="span" className={styles.SettingsTitle}>
                  {t('uploadingSettingsModal.form.settings.title')}
                </Box>
                <Box component="span" className={styles.SettingsSubTitle}>
                  {t('uploadingSettingsModal.form.settings.subTitle')}
                </Box>
              </Stack>
              {!!selectedSettingsCount && (
                <Box className={styles.SettingsSummaryCount}>
                  <Box component="span">{selectedSettingsCount}</Box>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails className={styles.SettingsDetails}>
              <Box className={styles.SettingsDetailsHead}>
                <Box className={styles.SettingsDetailsHeadText}>
                  {t('uploadingSettingsModal.form.settings.standardFields')}
                </Box>
                <Link
                  href="#"
                  className={styles.SettingsDetailsHeadLink}
                  onClick={toggleAllSettings}
                >
                  {isSomeSelected
                    ? t('uploadingSettingsModal.form.settings.deselectButton')
                    : t('uploadingSettingsModal.form.settings.selectButton')}
                </Link>
              </Box>
              <Box className={styles.SettingsDetailsFields}>
                {items.map((item) => {
                  return (
                    <CheckboxWithLabel
                      checked={settingIsChecked(item)}
                      label={item}
                      key={item}
                      onChange={() => {
                        toggleSetting(item);
                      }}
                    />
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </ViewLayout.Container>
      <ViewLayout.Actions
        leftButton={
          <Button variant="outlined" color="info" onClick={back}>
            {t('uploadingSettingsModal.actions.back')}
          </Button>
        }
        rightButton={
          <Button
            variant="contained"
            color="primary"
            disabled={isDisabled}
            onClick={startEnrich}
          >
            {t('uploadingSettingsModal.actions.enrichFile')}
          </Button>
        }
        text={t('uploadingSettingsModal.actions.stepsInfo')}
      />
    </ViewLayout>
  );
};
