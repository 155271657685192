import { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import { Box, Popover, PopoverProps } from '@mui/material';

import { PopoverBody } from './components/PopoverBody';
import { useTagApplierViewModel } from './useTagApplierViewModel';

type TagApplierProps = {
  defaultValue: string[];
  transformOrigin?: PopoverProps['transformOrigin'];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  renderAnchor: (params: {
    setAnchorElement: Dispatch<SetStateAction<Nullable<HTMLElement>>>;
    isOpen: boolean;
  }) => ReactElement;
  onApply: (value: string[]) => void;
};

export const TagApplier: FC<TagApplierProps> = ({
  defaultValue = [],
  onApply,
  renderAnchor,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}) => {
  const { setAnchorElement, isOpen, onClose, anchorElement } = useTagApplierViewModel();

  return (
    <Box>
      {renderAnchor({ setAnchorElement, isOpen })}
      <Popover
        anchorEl={anchorElement}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        keepMounted={false}
        open={isOpen}
        onClose={onClose}
      >
        <PopoverBody
          defaultValue={defaultValue}
          onApply={(value): void => {
            onApply(value);
            onClose();
          }}
        />
      </Popover>
    </Box>
  );
};
