import { inject, injectable } from 'inversify';
import { combineLatest, filter, first, map, Observable, of, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, AUTH_TYPES, RETENTION_TYPES, STORAGE_TYPES } from '@/ioc/types';

import { IAccountRepository, IAccountSettingsEntity } from '@/features/common/account';
import { AuthStatus, IAuthUseCase } from '@/features/common/auth';
import { IStorageRepository } from '@/features/system/storage';

import { IRetentionRepository, IRetentionUseCase } from './abstractions';

const STORAGE_KEY = 'displayRetentionOfferAfterLogin';

@injectable()
export class RetentionUseCase implements IRetentionUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(AUTH_TYPES.AuthUseCase)
  private authUseCase: IAuthUseCase;

  @inject(STORAGE_TYPES.StorageRepository)
  private storageRepository: IStorageRepository;

  @inject(RETENTION_TYPES.RetentionRepository)
  private retentionRepository: IRetentionRepository;

  public acceptRetentionOffer(): Observable<boolean> {
    return this.authUseCase.getAuthStatus().pipe(
      first(),
      switchMap((status) => {
        if (status === AuthStatus.Authorized)
          return this.retentionRepository.acceptRetentionOffer().pipe(map(() => true));
        else {
          this.storageRepository.save({
            [STORAGE_KEY]: true,
          });

          return of(true);
        }
      }),
    );
  }

  public canUserReceiveCredits(): Observable<boolean> {
    return this.accountRepository.getAccount().pipe(
      map((account) => {
        if (!account) {
          return true;
        }

        return !account.settings.gotRetentionCredits;
      }),
    );
  }

  public declineRetentionOffer(): Observable<void> {
    return this.accountRepository.getAccount().pipe(
      first(),
      switchMap((account) => {
        if (account) {
          const updatedSettings: Partial<IAccountSettingsEntity> = {
            ...account.settings,
            gotRetentionCredits: false,
          };
          return this.accountRepository.updateSettings(updatedSettings);
        } else {
          this.storageRepository.save({
            [STORAGE_KEY]: false,
          });
          return of(void 0);
        }
      }),
      map(() => void 0),
    );
  }

  public handleRetentionOfferAfterLogin(): Observable<unknown> {
    const status$ = this.storageRepository.get$(STORAGE_KEY).pipe(
      map((status) => {
        if (status === 'true') return true;
        if (status === 'false') return false;
        return status;
      }),
    );
    const acc$ = this.accountRepository.getAccount();

    return combineLatest([acc$, status$]).pipe(
      filter(([acc, status]) => {
        return !!acc && typeof status === 'boolean';
      }),
      first(),
      switchMap(([, status]) => {
        if (status === true) return this.acceptRetentionOffer();
        else return this.declineRetentionOffer();
      }),
      switchMap(() => {
        this.storageRepository.save({
          [STORAGE_KEY]: null,
        });

        return of(void 0);
      }),
    );
  }
}
