import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const MemberLastActivity: React.FC<GridRenderCellParams> = ({ value }) => {
  return (
    <div className="MuiDataGrid-cellContent">
      {value ? dayjs(value * 1000).format('MMMM D, YYYY') : ''}
    </div>
  );
};

export default MemberLastActivity;
