import * as React from "react";
const SvgIcon404 = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 552.049, height: 290.271, viewBox: "0 0 552.049 290.271", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17979" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32107", d: "m-51.2 10613.6 33.193-15.508 54.516 33.765 38.215-18.257-62.962-38.5 30.832-13.12 65.364 36.111 21.081-10.741 28.021 16.6-17.786 10.627 56.344 30.549-28.585 16.127c0 .771-57.036-30.85-57.036-30.85.257 0-73.488 37.711-73.488 37.711z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#3636c6"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32108", d: "m42.586 10561.987-.2 31.839-30.621-18.726z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32109", d: "M41.953 10561.112v36.088L8.8 10576.6z", transform: "translate(-8.8 -10340.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32110", d: "m74.736 10613.6 1.155 34.337-39.323 20.163-.023-36.219z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32111", d: "m240.379 10459.1 145.124 83.52-145.986 82.659-159.189-86.731z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#3636c6"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32112", d: "m239.54 10625.3.808-166.167-159.914 79.392z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32113", d: "m260.291 10447.341 31.719-17.36 62.026 32.154 36.791-18.956-67.833-36.306 28.1-15.472 69.545 37.02 20.219-10.8 28.69 16.927-17.552 10.623 48.857 26.284-28.449 16.028-49.092-26.547-63.812 36.122z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#3636c6"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32115", d: "M351.124 10391.4v30.5l-28.09-15.035z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32116", d: "M353.007 10390.777v33.143l-31.682-15.8z", transform: "translate(-9.825 -10338.631)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32117", d: "m354.01 10462.174.05 32.15 5.42 2.714 32.395-18.325-1.036-35.532z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#5656f4"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32118", d: "m105.142 10415.948 22.7 3 35.253 164.676-25.88-14.105z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#ff6b57"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32119", d: "m135.211 10415.948-7.387 3.031 35.209 164.643 7.954 4.32z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#e25547"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32120", d: "m112.108 10412.864-6.963 3.087 22.707 3.058 7.38-3.058z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32121", d: "m179.579 10380.834 4.96-2.991 22.7 2.533-5.771 2.883z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32122", d: "m207.248 10380.352 45.971 237.14-5.8 3.291-45.979-237.544c-.002.107 5.808-2.887 5.808-2.887z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#e25547"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32123", d: "m247.412 10620.78-7.839 4.526-15.655-8.518-44.346-235.979 21.878 2.438z", transform: "translate(51.2 -10377.843)", style: {
  fill: "#ff6b57"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17975", transform: "translate(-396.8 -208.843)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32124", d: "m138.975 10434.049 55.244-23.737 4.59 8.81-57.571 25.86z", transform: "translate(448 -10169)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 101", cx: 5, cy: 5, r: 5, transform: "translate(639 241)", style: {
  fill: "#ffa197"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17976", transform: "translate(-386.369 -158.742)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32124", d: "m138.975 10434.049 55.244-23.737 4.59 8.81-57.571 25.86z", transform: "translate(448 -10169)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 101", cx: 5, cy: 5, r: 5, transform: "translate(639 241)", style: {
  fill: "#ffa197"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17978", transform: "translate(-375.383 -108.642)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32124", d: "m138.453 10434.229 55.742-23.918 4.588 8.81-58.036 26.159z", transform: "translate(448 -10169)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 101", cx: 5, cy: 5, r: 5, transform: "translate(638.975 241)", style: {
  fill: "#ffa197"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17977", transform: "translate(-366.369 -61.742)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32124", d: "m139.115 10433.938 55.1-23.627 4.59 8.81-54.421 23.79-3.912-2.105", transform: "translate(448 -10169)", style: {
  fill: "#ffa197"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 101", cx: 5, cy: 5, r: 5, transform: "translate(639 241)", style: {
  fill: "#ffa197"
} }))));
export default SvgIcon404;
