import { BaseSyntheticEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useAccount } from '@/features/common/account';
import { UserAlreadyExistsError } from '@/features/common/auth';

import {
  getConfirmPasswordValidationSchema,
  PasswordValidationSchema,
  useFormWithSchema,
  WorkEmailValidationSchema,
} from '@/utils/validation';

const EmailPasswordFormSchema = yup.object({
  email: WorkEmailValidationSchema,
  password: PasswordValidationSchema,
  confirmPassword: getConfirmPasswordValidationSchema('password'),
});

type EmailPasswordFormType = yup.InferType<typeof EmailPasswordFormSchema>;

type UseEmailPasswordViewModel = (params: {
  isOpen: boolean;
  onClose: () => void;
  onEmailAndPasswordChange: (email: string, password: string) => Promise<void>;
}) => {
  form: UseFormReturn<EmailPasswordFormType>;
  onSubmit: (e: BaseSyntheticEvent) => void;
  isSubmitDisabled: boolean;
};

export const useEmailPasswordViewModel: UseEmailPasswordViewModel = ({
  isOpen,
  onClose,
  onEmailAndPasswordChange,
}) => {
  const { account } = useAccount();
  const email = account?.unconfirmedEmail ?? account?.email ?? '';
  const { t } = useTranslation('validation');

  const form = useFormWithSchema(EmailPasswordFormSchema, {
    defaultValues: {
      email,
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (isOpen) {
      form.reset({
        email,
        password: '',
        confirmPassword: '',
      });
    }
  }, [email, isOpen]);

  const handleSubmit = (e: BaseSyntheticEvent): void => {
    void form.handleSubmit(
      async ({ email, password }: EmailPasswordFormType): Promise<void> => {
        try {
          await onEmailAndPasswordChange(email, password);
          onClose();
        } catch (error) {
          if (error instanceof UserAlreadyExistsError) {
            form.setError('email', {
              message: t('emailAlreadyExists'),
            });
          }
        }
      },
    )(e);
  };

  return {
    form,
    onSubmit: handleSubmit,
    isSubmitDisabled: form.formState.isSubmitting || !form.formState.isDirty,
  };
};
