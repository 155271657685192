// noinspection AllyPlainJsInspection

export enum MONTHS_SHORTCUTS {
  Jan = 'Jan',
  Feb = 'Feb',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  Aug = 'Aug',
  Sept = 'Sept',
  Oct = 'Oct',
  Nov = 'Nov',
  Dec = 'Dec',
}
