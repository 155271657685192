import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const SummaryRow: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return <div className={clsx(styles.summaryRow, className)}>{children}</div>;
};
