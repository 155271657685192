import { useTeamMemberSelect, useTeamMemberSelectOptions } from '@/features/settings';
import {
  ANALYTICS_EVENTS,
  DataFiltersPageProperty,
  useAnalytics,
} from '@/features/system/analytics';

type Return = {
  handleUsersChange: (value: string) => void;
  selectedUser: string;
};

export const useMembersSelectFacade = (): Return => {
  const analytics = useAnalytics();
  const options = useTeamMemberSelectOptions();
  const { value: filterByUser, setValue: setFilterByUser } = useTeamMemberSelect(
    options[0] ? options[0].value : '',
  );

  const handleUsersChange = (value: string): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.FILTER_BY_USERS, {
      page: DataFiltersPageProperty.Dashboard,
    });
    setFilterByUser(value);
  };

  return {
    selectedUser: filterByUser,
    handleUsersChange,
  };
};
