import { Container } from 'inversify';

import { INTEGRATION_TYPES } from '@/ioc/types';

import {
  IIntegrationExportState,
  IIntegrationState,
  IntegrationExportState,
  IntegrationExportStateCreator,
  IntegrationState,
  IntegrationStateCreator,
} from '../data/db';
import { IntegrationRepository } from '../data/IntegrationRepository';
import IntegrationApiService from '../data/network/IntegrationApiService';
import {
  IIntegrationApiService,
  IIntegrationRepository,
  IIntegrationUseCase,
} from '../domain/abstractions';
import IntegrationUseCase from '../domain/IntegrationUseCase';

export const bindIntegrationModule = (container: Container): void => {
  container
    .bind<IIntegrationState>(INTEGRATION_TYPES.IntegrationState)
    .to(IntegrationState)
    .inSingletonScope();

  container
    .bind<IIntegrationExportState>(INTEGRATION_TYPES.IntegrationExportState)
    .to(IntegrationExportState)
    .inSingletonScope();

  container.bind<IntegrationStateCreator>('DbStateCreator').to(IntegrationStateCreator);
  container
    .bind<IntegrationExportStateCreator>('DbStateCreator')
    .to(IntegrationExportStateCreator);

  container
    .bind<IIntegrationApiService>(INTEGRATION_TYPES.IntegrationApiService)
    .to(IntegrationApiService);

  container
    .bind<IIntegrationUseCase>(INTEGRATION_TYPES.IntegrationUseCases)
    .to(IntegrationUseCase);

  container
    .bind<IIntegrationRepository>(INTEGRATION_TYPES.IntegrationRepository)
    .to(IntegrationRepository)
    .inSingletonScope();
};
