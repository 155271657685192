import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChatIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6 6 24 24">
    <path
      data-name="Path 27"
      d="M2.782 18.785a4.685 4.685 0 0 1-2.3-.606.726.726 0 0 1-.29-1.017 5.1 5.1 0 0 0 .657-3.921A15.768 15.768 0 0 1 0 9.222 9.285 9.285 0 0 1 9.443 0 9.333 9.333 0 0 1 18.5 9.244a9.327 9.327 0 0 1-12.8 8.538 4.75 4.75 0 0 1-2.919 1zM1.8 17.2a3.317 3.317 0 0 0 3.275-.772.715.715 0 0 1 .806-.131 7.871 7.871 0 0 0 11.192-7.053 7.819 7.819 0 0 0-15.638-.019 16.6 16.6 0 0 0 .837 3.827A6.385 6.385 0 0 1 1.8 17.2z"
      transform="translate(5.995 6.001)"
      strokeWidth={25}
    />
    <path
      data-name="Path 28"
      d="M155.921 155.523a4.75 4.75 0 0 1-2.919-1 9.354 9.354 0 0 1-9.607-1.535.715.715 0 1 1 .935-1.083 7.911 7.911 0 0 0 8.488 1.133.715.715 0 0 1 .806.131 3.317 3.317 0 0 0 3.275.772 6.358 6.358 0 0 1-.265-4.866.55.55 0 0 1 .017-.043 7.9 7.9 0 0 0-1.188-8.085.715.715 0 1 1 1.1-.918 9.293 9.293 0 0 1 2.138 5.933 9.184 9.184 0 0 1-.721 3.607 5.047 5.047 0 0 0 .537 4.335.726.726 0 0 1-.29 1.017 4.687 4.687 0 0 1-2.306.602z"
      transform="translate(-128.7 -125.523)"
      strokeWidth={25}
    />
    <g data-name="Group 21" transform="translate(14.092 14.117)">
      <ellipse
        data-name="Ellipse 3"
        cx="1.132"
        cy="1.131"
        rx="1.132"
        ry="1.131"
        strokeWidth={25}
      />
    </g>
    <g data-name="Group 22" transform="translate(9.921 14.117)">
      <ellipse
        data-name="Ellipse 4"
        cx="1.132"
        cy="1.131"
        rx="1.132"
        ry="1.131"
        strokeWidth={25}
      />
    </g>
    <g data-name="Group 23" transform="translate(18.262 14.117)">
      <ellipse
        data-name="Ellipse 5"
        cx="1.132"
        cy="1.131"
        rx="1.132"
        ry="1.131"
        strokeWidth={25}
      />
    </g>
  </SvgIcon>
);
