import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { ITagUseCase } from '@/features/common/tag';

import { ObservableResult, useObservableResult } from '@/utils/rx';

export const useTagEntitiesMetadata = (): ObservableResult<
  { total: number },
  unknown,
  { total: number }
> => {
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);

  return useObservableResult(() => tagUseCase.getMetadata(), {
    defaultData: { total: 0 },
  });
};
