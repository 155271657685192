import React from 'react';
import { Button } from '@mui/material';

import { useAuthUseCase } from '@/features/common/auth';

import { LogOutIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const OverlayLogoutButton: React.FC = () => {
  const authUseCase = useAuthUseCase();
  const handleClick = async (): Promise<void> => {
    await authUseCase.signOut();
  };

  return (
    <Button
      variant="outlined"
      size="small"
      color="info"
      className={styles.button}
      startIcon={<LogOutIcon color="info" />}
      onClick={handleClick}
    >
      Logout
    </Button>
  );
};
