import { DomainError } from '@/features/common/error';

export class WorkspaceSeatsLimitError extends DomainError {
  metadata?: {
    limit: number;
  };

  constructor(
    message = 'Workspace seats limit reached',
    metadata?: {
      limit: number;
    },
  ) {
    super(message);
    this.name = 'WorkspaceSeatsLimitError';
    this.metadata = metadata;
  }
}
