import { ICompanyDC } from '../../dataContracts';

export const companyMigrationStrategies = {
  1: (oldDoc: ICompanyDC): ICompanyDC => {
    oldDoc.images = null;
    oldDoc.funding_rounds = null;
    oldDoc.financials = null;
    oldDoc.company_id = oldDoc.uuid;

    return oldDoc;
  },
};
