import { VerificationStatus } from '../../domain/types';
import { IAccountDC } from '../dataContracts/AccountDC';

export const mapVerificationStatus = (dc: IAccountDC): VerificationStatus => {
  if (!dc.unconfirmed_email) {
    return VerificationStatus.Verified;
  }

  // current emauil is unconfirmed
  if (dc.email === dc.unconfirmed_email) {
    return VerificationStatus.ShouldVerifySignUpEmail;
  }

  // current email is confirmed, but we have changed email and it is unconfirmed
  if (dc.email !== dc.unconfirmed_email) {
    return VerificationStatus.ShouldVerifyChangeEmail;
  }

  return VerificationStatus.Verified;
};
