import { UserArgs } from '@intercom/messenger-js-sdk/dist/types';
import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

import { ACCOUNT_TYPES, HELP_CENTER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountEntity, IAccountRepository } from '@/features/common/account';
import { IWorkspaceEntity, IWorkspaceRepository } from '@/features/common/workspace';

import { IHelpCenterRepository } from '../data';

import { IHelpCenterUseCase } from './abstractions/IHelpCenterUseCase';

@injectable()
export class HelpCenterUseCase implements IHelpCenterUseCase {
  @inject(HELP_CENTER_TYPES.HelpCenterRepository)
  private helpCenterRepository: IHelpCenterRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  public init(): Observable<void> {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
      credits: this.accountRepository
        .getAccount()
        .pipe(map((account) => account?.currentCreditsUsed ?? 0)),
    }).pipe(
      distinctUntilChanged((prev, current) => {
        return (
          prev.account === current.account &&
          prev.workspace?.subscription.plan === current.workspace?.subscription.plan &&
          prev.credits === current.credits
        );
      }),
      filter(({ account, workspace }) => !!account && !!workspace),
      tap(
        ({
          account,
          workspace,
          credits,
        }: {
          account: IAccountEntity;
          workspace: IWorkspaceEntity;
          credits: number;
        }) => {
          this.helpCenterRepository.update({
            name: account.fullName ?? '',
            user_id: account.uuid,
            email: account.email,
            phone: account.settings?.phone || '',
            subscription: workspace.subscription.plan,
            creditsAmount: credits,
            created_at: dayjs().unix(),
          });
        },
      ),
      switchMap(() => EMPTY),
    );
  }

  public update(userArgs: UserArgs): void {
    this.helpCenterRepository.update(userArgs);
  }

  public show(): void {
    this.helpCenterRepository.show();
  }

  public hide(): void {
    this.helpCenterRepository.hide();
  }

  public showNewMessage(prePopulatedContent: string): void {
    this.helpCenterRepository.showNewMessage(prePopulatedContent);
  }

  public onShow(callback: () => void): void {
    this.helpCenterRepository.onShow(callback);
  }

  public onHide(callback: () => void): void {
    this.helpCenterRepository.onHide(callback);
  }

  public shutdown(): void {
    this.helpCenterRepository.shutdown();
  }
}
