/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IContactListDC } from '@/features/common/contactList';
import { mapBulkResponse, NetworkBulkResponse } from '@/features/system/network';
import { IHttpClient } from '@/features/system/network/HttpClient';

const contactEndpoints = {
  contactLists: '/api/v1/contact-lists',
};

export interface IContactListServicesApi {
  getContactLists(): Observable<IContactListDC[]>;
  createContactLists(payload: IContactListDC[]): Observable<IContactListDC[]>;
  updateContactLists(payload: Partial<IContactListDC>[]): Observable<IContactListDC[]>;
  deleteContactLists(items: string[]): Observable<boolean>;
}

type ContactListBulkResponse = NetworkBulkResponse<IContactListDC>[];

@injectable()
export default class ContactListApiService implements IContactListServicesApi {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  getContactLists(): Observable<IContactListDC[]> {
    return this.httpClient
      .get(contactEndpoints.contactLists)
      .pipe(map((response: AxiosResponse<IContactListDC[]>) => response.data));
  }

  createContactLists(payload: IContactListDC[]): Observable<IContactListDC[]> {
    return this.httpClient
      .post<ContactListBulkResponse>(contactEndpoints.contactLists, payload)
      .pipe(map((response) => mapBulkResponse(response.data)));
  }

  updateContactLists(payload: Partial<IContactListDC>[]): Observable<IContactListDC[]> {
    return this.httpClient
      .patch<ContactListBulkResponse>(contactEndpoints.contactLists, payload)
      .pipe(map((response) => mapBulkResponse(response.data)));
  }

  deleteContactLists(items: string[]): Observable<boolean> {
    return this.httpClient
      .delete(contactEndpoints.contactLists, items)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
}
