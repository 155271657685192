import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useAccount } from '@/features/common/account';

export const useSentryTrackerViewModel = (): void => {
  const { account } = useAccount();

  useEffect(() => {
    Sentry.setUser({ email: account?.email });
  }, [account?.email]);
};
