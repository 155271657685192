import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useOptimisticState = <T>(
  stateToSyncWith: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [optimisticState, setOptimisticState] = useState(stateToSyncWith);

  useEffect(() => {
    setOptimisticState(stateToSyncWith);
  }, [stateToSyncWith]);

  return [optimisticState, setOptimisticState];
};
