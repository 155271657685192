import { useInjection } from '@/ioc/ioc.react';
import { GET_FREE_CREDITS_TYPES } from '@/ioc/types';

import { IGetFreeCreditsUseCase } from '@/features/getFreeCredits';

export const useGetFreeCreditsUseCase = (): IGetFreeCreditsUseCase => {
  return useInjection<IGetFreeCreditsUseCase>(
    GET_FREE_CREDITS_TYPES.GetFreeCreditsUseCase,
  );
};
