import { FC, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export const IntegrationCardsGrid: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.CardsWrapper}>
      <div className={styles.CardsGrid}>{children}</div>
    </div>
  );
};
