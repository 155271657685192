import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { Modal } from '@/components';

import { ModalControler } from '@/hooks';

import Icon from './assets/icon.svg?react';

import styles from './styles.module.scss';

export const EditTagConfirmationModal: FC<{
  modalController: ModalControler<'EditTagModal'>;
  onConfirm: () => Promise<void>;
}> = ({ modalController, onConfirm }) => {
  const { t } = useTranslation(['tag', 'validation']);

  return (
    <Modal
      open={modalController.isEditTagModalOpen}
      onClose={modalController.onEditTagModalClose}
      containerClass={styles.EditModal}
    >
      <>
        <Icon />
        <Typography className={styles.EditModalTitle}>
          {t('editTag.dialogTitle')}
        </Typography>
        <Typography className={styles.EditModalDescription}>
          {t('editTag.dialogDescription')}
        </Typography>
        <Box className={styles.EditModalButtons}>
          <Button onClick={modalController.onEditTagModalClose}>
            {t('editTag.dialogCancel')}
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            {t('editTag.dialogSave')}
          </Button>
        </Box>
      </>
    </Modal>
  );
};
