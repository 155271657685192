import { FC } from 'react';
import { Box } from '@mui/material';

import { Modal } from '@/components';

import CloseIcon from './assets/Close.svg?react';
import { useEnrichmentUploadingModalViewModel } from './useEnrichmentUploadingModalViewModel';

import styles from './styles.module.scss';

export const EnrichmentUploadingModal: FC = () => {
  const { CurrentViewComponent, uploadinModal } = useEnrichmentUploadingModalViewModel();

  return (
    <Modal
      open={uploadinModal.isUploadingModalOpen}
      onClose={uploadinModal.onUploadingModalClose}
      containerClass={styles.Root}
      disableRestoreFocus
    >
      <Box className={styles.Body}>
        <Box className={styles.Icon} onClick={uploadinModal.onUploadingModalClose}>
          <CloseIcon />
        </Box>
        <CurrentViewComponent />
      </Box>
    </Modal>
  );
};
