import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { useKeyDownEvent } from '@/hooks';

import styles from './styles.module.scss';

type CancelSubscriptionCardProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const CancelSubscriptionCard: FC<CancelSubscriptionCardProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.cancelSubscriptionCard',
  });

  useKeyDownEvent('Escape', onCancel);
  useKeyDownEvent('Enter', onSubmit);

  return (
    <Box className={styles.cancelWrapper}>
      <Typography className={styles.cancelTitle}>{t('title')}</Typography>
      <Typography className={styles.cancelBody}>
        <Trans t={t} i18nKey="description">
          <span>Note:</span> Once you cancel your subscription, any team members on this
          account will lose access to all your saved contacts.
        </Trans>
      </Typography>
      <Box className={styles.cancelFooter}>
        <Button
          variant="contained"
          color="error"
          onClick={onSubmit}
          className={styles.buttonSubmit}
        >
          {t('submit')}
        </Button>
        <Button
          variant="outlined"
          color="info"
          onClick={onCancel}
          className={styles.buttonCancel}
        >
          {t('cancel')}
        </Button>
      </Box>
    </Box>
  );
};
