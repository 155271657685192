import { useMemo, useState } from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid-pro';

import { IContactEntity, useContacts } from '@/features/common/contact';

import { useTableColumns } from './hooks';

interface UseDashboardTableViewModelResult {
  isLoading: boolean;
  columns: GridColDef[];
  rows: ContactTableRow[];
  queryString: string;
  handleSortChange: (sortData) => void;
}

type ContactTableRow = IContactEntity & { id: string };

const CONTACTS_PER_PAGE = 50;
const previewRows = Array.from({ length: 10 }).map((_, index) => ({
  id: `${index}`,
})) as ContactTableRow[];
const DefaultSortItem: GridSortItem = { field: 'created_at', sort: 'desc' };

const mapToSortItem = (sortItem?: GridSortItem): GridSortItem => {
  const sortFieldsMap = {
    name: 'name',
    jobTitle: 'job_title',
    company: 'company_name',
    date: 'created_at',
    contactOwner: 'assigned_to',
  };

  if (!sortItem || !sortFieldsMap[sortItem.field]) {
    return DefaultSortItem;
  }

  return {
    field: sortFieldsMap[sortItem.field],
    sort: sortItem.sort,
  };
};

export const useDashboardTableViewModel = (): UseDashboardTableViewModelResult => {
  const [sort, setSort] = useState<GridSortItem>({ field: 'created_at', sort: 'desc' });

  const params: URLSearchParams = useMemo(() => {
    const result = new URLSearchParams();
    result.set('start', '0');
    result.set('length', String(CONTACTS_PER_PAGE));
    result.set('sort_by', sort.field || 'created_at');
    result.set('sort_dir', sort.sort || 'desc');
    result.set('stream', '1');

    return result;
  }, [sort]);
  const queryString = params.toString();

  const { data: contacts, isLoading } = useContacts(queryString, true);
  const rows = isLoading
    ? previewRows
    : (contacts.entities || []).map((item) => ({ id: item.uuid, ...item }));

  const columns = useTableColumns(isLoading);

  const handleSortChange = (sortData): void => {
    const [sortItem] = sortData;
    setSort(mapToSortItem(sortItem));
  };

  return {
    isLoading,
    columns,
    rows,
    queryString,
    handleSortChange,
  };
};
