import { useContext } from 'react';

import { useBulkAggregatedCreditsInfo } from '@/features/common/workspace';

import { EnrichmentUploadingContext } from '../../../../../../EnrichmentUploadingContext';

export const useStartViewCreditsLimitErrorViewModel = () => {
  const { data } = useBulkAggregatedCreditsInfo();
  const { fileMetadata } = useContext(EnrichmentUploadingContext);

  const rowsCount = fileMetadata?.rowsCount || 0;
  const creditsLeft = data ? Math.max(data.limit - data.used, 0) : 0;

  return {
    requireCredits: rowsCount,
    needToChargeCredits: Math.max(rowsCount - creditsLeft, 0),
  };
};
