import { Container } from 'inversify';

import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import {
  ITeamMemberApiService,
  ITeamMemberStateRepository,
  ITeamMemberUseCase,
  TeamMemberApiService,
  TeamMemberRepository,
  TeamMemberUseCase,
} from '@/features/settings';

export const bindTeamMemberModule = (container: Container): void => {
  container
    .bind<ITeamMemberApiService>(TEAM_MEMBER_TYPES.TeamMemberApiService)
    .to(TeamMemberApiService);
  container
    .bind<ITeamMemberStateRepository>(TEAM_MEMBER_TYPES.TeamMemberRepository)
    .to(TeamMemberRepository);
  container
    .bind<ITeamMemberUseCase>(TEAM_MEMBER_TYPES.TeamMemberUseCase)
    .to(TeamMemberUseCase);
};
