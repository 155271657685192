import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const GetCredits: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscriptionUseCase = useSubscriptionUseCase();

  const { data: isCustomPlan } = useObservableResult(() =>
    subscriptionUseCase.getIsCustomPlan(),
  );

  const handleClick = (): void => {
    onClick?.();

    if (isCustomPlan) {
      window.open(EXTERNAL_ROUTES.TALK_TO_SALES);
      return;
    }

    navigate(`${ROUTES.BILLING.PLANS}?source=get-credits`);
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={props.text ?? t('callToActions.getCredits')}
    />
  );
};
