import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

type UsePaymentErrorSnackbarReturn = {
  show: (description?: string) => void;
};

export function usePaymentErrorSnackbar(): UsePaymentErrorSnackbarReturn {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'errorSnackbar' });
  const { enqueueSnackbar } = useSnackbar();

  return {
    show: (description: string = t('description')): void => {
      enqueueSnackbar(t('title'), {
        variant: 'error',
        autoHideDuration: 10000,
        preventDuplicate: true,
        description,
      });
    },
  };
}
