import { AggregatedChartDataEntity } from '../../domain/entities/AggregatedChartDataEntity';
import { mapDateStringToLabelDto, mapEntitiesToDateStrings } from '../mappers';

type Props = {
  data: AggregatedChartDataEntity[];
};

export const useChartTooltipTitles = ({ data }: Props): string[] => {
  return data
    .map(mapEntitiesToDateStrings)
    .map(mapDateStringToLabelDto)
    .map(({ monthKey, date }) => {
      return `${date} ${monthKey}`;
    });
};
