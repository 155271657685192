import React from 'react';
import { Button } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick(): void;
  isTitleBig?: boolean;
};

export const ErrorLayout: React.FC<Props> = ({
  icon,
  title,
  description,
  buttonText,
  onButtonClick,
  isTitleBig,
}) => (
  <Box className={styles.Wrapper}>
    {icon}
    <Typography className={clsx(styles.Title, { [styles.Big]: isTitleBig })}>
      {title}
    </Typography>
    <Typography className={styles.Description}>{description}</Typography>
    <Button
      onClick={onButtonClick}
      color={'secondary'}
      variant={'contained'}
      className={styles.Button}
    >
      {buttonText}
    </Button>
  </Box>
);
