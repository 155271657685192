import { FC, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, OutlinedInput, Typography } from '@mui/material';
import clsx from 'clsx';

import { UnauthenticatedLayout } from '@/router/layouts';

import { SignUpError } from '@/features/signup/ui/components/SignUpPage/components/SignUpForm/components';

import { useDocumentMeta } from '@/hooks';

import { SignupError, useSignUpViewModel } from './useSignUpViewModel';

import styles from './styles.module.scss';

export const SignUpForm: FC = () => {
  const {
    error,
    signUpGoogle,
    signUpMicrosoft,
    signUpWithEmail,
    form,
    onClickCaptcha,
    captchaError,
    loginRoute,
    onCaptchaExpired,
    emailInputError,
  } = useSignUpViewModel();
  const { t } = useTranslation('auth');
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  useDocumentMeta({ title: 'signUp.title', description: 'signUp.description' });

  return (
    <UnauthenticatedLayout.Container>
      <UnauthenticatedLayout.Title>{t('signup.title')}</UnauthenticatedLayout.Title>
      <UnauthenticatedLayout.Subtitle>
        {t('signup.description')}
      </UnauthenticatedLayout.Subtitle>
      <UnauthenticatedLayout.SocialButton variant="google" onClick={signUpGoogle}>
        {t('signup.signUpGoogle')}
      </UnauthenticatedLayout.SocialButton>
      <UnauthenticatedLayout.SocialButton variant="microsoft" onClick={signUpMicrosoft}>
        {t('signup.signUpMicrosoft')}
      </UnauthenticatedLayout.SocialButton>

      <UnauthenticatedLayout.Divider />

      <UnauthenticatedLayout.Form>
        <UnauthenticatedLayout.FormField
          label={t('signup.emailFieldLabel')}
          error={emailInputError}
          hasError={error === SignupError.InvalidWorkEmail}
          required
        >
          <OutlinedInput
            placeholder={t('common:emailPlaceholder')}
            {...form.register('email')}
            autoFocus
          />
        </UnauthenticatedLayout.FormField>
        <UnauthenticatedLayout.SubmitButton
          onClick={signUpWithEmail}
          disabled={form.formState.isSubmitting}
        >
          {t('signup.SubmitButton')}
        </UnauthenticatedLayout.SubmitButton>
        <Typography variant={'subtitle1'} className={styles.haveAccount} fontSize="15px">
          <Trans t={t} i18nKey={'signup.SignInText'}>
            Already have can account?
            <Link to={loginRoute} className={styles.linkCreate}>
              Sign in
            </Link>
          </Trans>
        </Typography>

        <Box className={styles.captchaWrapper}>
          <ReCAPTCHA
            sitekey={import.meta.env.REACT_APP_RECAPTCHA_KEY}
            className={clsx({
              [styles.captchaError]: captchaError,
              [styles.captcha]: true,
            })}
            ref={captchaRef}
            onChange={(value: string): void => {
              onClickCaptcha(value);
            }}
            onExpired={(): void => {
              setTimeout(() => {
                captchaRef.current && captchaRef.current.reset();
              }, 1000);
              onCaptchaExpired();
            }}
          />
        </Box>

        <SignUpError error={error} />
      </UnauthenticatedLayout.Form>
    </UnauthenticatedLayout.Container>
  );
};
