import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { COMPANY_TYPES } from '@/ioc/types';

import { ICompanyRepository, ICompanyUseCase } from './abstractions';
import { ICompanyEntity } from './entities';

@injectable()
export class CompanyUseCase implements ICompanyUseCase {
  @inject(COMPANY_TYPES.CompanyRepository)
  private companyRepository: ICompanyRepository;

  public getById(id: string): Observable<ICompanyEntity> {
    return this.companyRepository.getById(id);
  }
}
