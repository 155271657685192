import dayjs, { Dayjs } from 'dayjs';

import { Aggregator } from '../abstractions/Aggregation';
import { ChartDataEntity } from '../entities/ChartDataEntity';

export class WeeklyAggregation extends Aggregator {
  private readonly diff: number;

  constructor(dataOriginal: ChartDataEntity[], from: Dayjs, to: Dayjs) {
    super(dataOriginal, from, to);

    this.diff = to.diff(from, 'weeks');
    this.populate(this.diff);
    this.result = this.groupDataByTimestamps(this.dateObjs, this.data);
  }

  private populate(diff: number): void {
    for (let i = 1; i <= diff; i++) {
      this.dateObjs.push(this.to.subtract(i, 'weeks').unix());
    }

    if (!this.isFromTheWeekStart()) {
      this.dateObjs.push(this.from.unix());
    }
  }

  private isFromTheWeekStart(): boolean {
    const lastItem = this.dateObjs[this.dateObjs.length - 1];
    return this.from.startOf('day').isSame(dayjs.unix(lastItem).startOf('day'));
  }
}
