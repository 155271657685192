import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

type UseAccountSettingsSnackbar = () => {
  notifyEmailUpdated: () => void;
  notifyPasswordUpdated: () => void;
  notifyNameUpdated: () => void;
  notifyEmailAlreadyExists: () => void;
  notifyCredentialsOutdated: () => void;
};

export const useAccountSettingsSnackbar: UseAccountSettingsSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('settings');

  return {
    notifyEmailUpdated: (): void => {
      enqueueSnackbar(t('account.actions.emailUpdate.title'), {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true,
        description: t('account.actions.emailUpdate.description'),
      });
    },
    notifyPasswordUpdated: (): void => {
      enqueueSnackbar(t('account.actions.passwordUpdate.title'), {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true,
        description: t('account.actions.passwordUpdate.description'),
      });
    },
    notifyNameUpdated: (): void => {
      enqueueSnackbar(t('account.actions.nameUpdate.title'), {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true,
        description: t('account.actions.nameUpdate.description'),
      });
    },
    notifyEmailAlreadyExists: (): void => {
      enqueueSnackbar(t('account.actions.emailUpdate.title'), {
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
        description: t('account.actions.emailUpdate.errors.exists'),
      });
    },
    notifyCredentialsOutdated: (): void => {
      enqueueSnackbar(t('account.actions.passwordUpdate.title'), {
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
        description: t('account.actions.passwordUpdate.credentialsOutdated'),
      });
    },
  };
};
