import { mapTagDcToEntity } from 'features/common/tag/data/mappers/mapper';
import { inject, injectable } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import { TAG_TYPES } from '@/ioc/types';

import { ITagDao, ITagDC, ITagEntity, ITagRepository } from '@/features/common/tag';

@injectable()
export default class TagRepository implements ITagRepository {
  @inject(TAG_TYPES.TagDao)
  private tagDao: ITagDao;

  getMetadata(): Observable<{ total: number }> {
    return combineLatest({
      total: this.tagDao.count().pipe(map((total) => ({ total }))),
    }).pipe(map(({ total }) => total));
  }

  getTags = (params?: {
    uuidIncludes?: string[];
    nameRegex?: string;
  }): Observable<ITagEntity[]> => {
    const uuidSelector = params?.uuidIncludes
      ? { uuid: { $in: params.uuidIncludes } }
      : {};
    const nameRegex = params?.nameRegex
      ? { name: { $regex: params.nameRegex, $options: 'i' } }
      : {};

    return this.tagDao
      .findAll({
        selector: {
          ...uuidSelector,
          ...nameRegex,
        },
        sort: [{ created_at: 'asc' }],
      })
      .pipe(map((tagArr) => tagArr.map(mapTagDcToEntity)));
  };

  async addTag(
    payload: Pick<ITagDC, 'name' | 'color' | 'created_by' | 'contacts_amount'>,
  ): Promise<ITagEntity> {
    const result = await this.tagDao.upsert(payload);
    return mapTagDcToEntity(result);
  }

  async insertTag(payload: WithOptionalId<ITagDC, 'uuid'>): Promise<ITagEntity> {
    const result = await this.tagDao.insertOne(payload);
    return mapTagDcToEntity(result);
  }

  async updateTag({
    uuid,
    ...patch
  }: Pick<ITagDC, 'uuid' | 'color' | 'name'>): Promise<ITagEntity> {
    const result = await this.tagDao.updateOne(uuid, patch);
    return mapTagDcToEntity(result);
  }

  async deleteTag(uuid: string): Promise<boolean> {
    const result = await this.tagDao.removeOne(uuid);
    return !!result;
  }
}
