import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

export const DefaultAnchor: FC<Props> = ({ onClick, disabled }) => {
  const { t } = useTranslation('contactsTable');

  return (
    <Button
      variant="text"
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
    >
      {t('tagsCell.addTag')}
    </Button>
  );
};
