import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { IIntegrationEntity } from '@/features/integration/domain';
import { useIntegrationUseCase } from '@/features/integration/ui/hooks';

import {
  ModalControler,
  useDebounceCallback,
  useModalController,
  useOptimisticState,
} from '@/hooks';

type SettingOption = { label: string; value: string; checked: boolean };

type UseSettingsTabViewModel = (integration: IIntegrationEntity) => {
  options: SettingOption[];
  handleOptionToogle: (option: SettingOption) => void;
  disconnectDialog: ModalControler;
};

const useSettingOptions = (integration: IIntegrationEntity): SettingOption[] => {
  return useMemo(() => {
    return integration.supportedObjects.map((object) => ({
      label: object,
      value: object,
      checked: integration.enabledObjects.includes(object),
    }));
  }, [integration]);
};

export const useSettingsTabViewModel: UseSettingsTabViewModel = (integration) => {
  const { t } = useTranslation('integrations');
  const integrationUseCase = useIntegrationUseCase();

  const disconnectDialog = useModalController();

  const [optimisticIntegration, setOptimisticIntegration] =
    useOptimisticState(integration);

  const options = useSettingOptions(optimisticIntegration);

  const { enqueueSnackbar } = useSnackbar();

  const updateSettings = useDebounceCallback(
    async (updatedIntegration: IIntegrationEntity): Promise<void> => {
      try {
        await integrationUseCase.updateSettings(
          updatedIntegration.provider,
          updatedIntegration.enabledObjects,
        );
        enqueueSnackbar({
          variant: 'success',
          message: t('integration.settingsUpdated'),
          preventDuplicate: true,
        });
      } catch {
        enqueueSnackbar({
          variant: 'error',
          message: t('integration.settingsUpdateFailed'),
          preventDuplicate: true,
        });
      }
    },
    800,
    [],
  );

  const handleOptionToogle = async (option: SettingOption): Promise<void> => {
    const enabledSet = new Set(optimisticIntegration.enabledObjects);

    if (enabledSet.has(option.value)) {
      enabledSet.delete(option.value);
    } else {
      enabledSet.add(option.value);
    }

    const enabledObjects = Array.from(enabledSet);

    const updatedIntegration = {
      ...optimisticIntegration,
      enabledObjects,
    };

    setOptimisticIntegration(updatedIntegration);

    updateSettings(updatedIntegration);
  };

  return {
    disconnectDialog,
    options,
    handleOptionToogle,
  };
};
