import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { EnrichmentFileHeaderField } from '@/features/enrichment/domain/types';

import { useExampleDataViewModel } from './useExampleDataViewModel';

import styles from './styles.module.scss';

type ExampleDataCellProps = { fieldName: EnrichmentFileHeaderField };

export const ExampleDataCell: FC<ExampleDataCellProps> = ({ fieldName }) => {
  const { isValid, error, data } = useExampleDataViewModel(fieldName);

  return (
    <Box className={styles.Cell}>
      {isValid && (
        <Box component="span" className={clsx(styles.Text)}>
          {data}
        </Box>
      )}
      {error && (
        <Box component="span" className={clsx(styles.Text, styles.Error)}>
          {error.message}
        </Box>
      )}
    </Box>
  );
};
