import { PropsWithChildren, ReactElement, useContext } from 'react';
import { Divider } from '@mui/material';

import {
  CustomSelectV2Context,
  CustomSelectV2ContextType,
} from '../../../../CustomSelectV2Context';
import { PrimitiveValue } from '../../../../types';
import { Wrapper } from '../Wrapper';

export type FooterProps<OptionValue extends PrimitiveValue, Multiple extends boolean> = {
  render?: (
    params: Pick<
      CustomSelectV2ContextType<OptionValue, Multiple>,
      'inputData' | 'closeMenu'
    >,
  ) => ReactElement;
};

export function Footer<OptionValue extends PrimitiveValue, Multiple extends boolean>({
  render,
  children,
}: PropsWithChildren<FooterProps<OptionValue, Multiple>>): ReactElement {
  const { inputData, closeMenu } = useContext(CustomSelectV2Context);

  return (
    <>
      <Divider />
      <Wrapper>{render?.({ inputData, closeMenu }) || children}</Wrapper>
    </>
  );
}
