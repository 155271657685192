import * as React from "react";
const SvgMasterCard = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 37.586, height: 23.063, viewBox: "0 0 37.586 23.063", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17918" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31893", d: "M31.793 30.308a11.277 11.277 0 0 1-7.393 2.754A11.532 11.532 0 0 1 24.4 10a11.277 11.277 0 0 1 7.394 2.755A11.277 11.277 0 0 1 39.187 10a11.532 11.532 0 0 1 0 23.062 11.277 11.277 0 0 1-7.394-2.754z", transform: "translate(-13 -10)", style: {
  fillRule: "evenodd",
  fill: "#ed0006"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31894", d: "M35.5 30.308a11.619 11.619 0 0 0 0-17.553A11.277 11.277 0 0 1 42.894 10a11.532 11.532 0 0 1 0 23.062 11.277 11.277 0 0 1-7.394-2.754z", transform: "translate(-16.707 -10)", style: {
  fill: "#f9a000",
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31895", d: "M34.71 13.3a11.619 11.619 0 0 1 0 17.553 11.619 11.619 0 0 1 0-17.553z", transform: "translate(-15.917 -10.543)", style: {
  fill: "#ff5e00",
  fillRule: "evenodd"
} })));
export default SvgMasterCard;
