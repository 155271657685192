import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Divider } from '@mui/material';

import { Modal } from '@/components';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconError from '@/assets/icons/integrations/close-rount-red-icon.svg?react';
import IconConnectPowerlead from '@/assets/icons/integrations/connectPowerleadIcon.svg?react';

import { useIntegrationsUseCase } from '../../hooks';
import { ErrorVariant } from '../../IntegrationsViewModel';

import styles from './styles.module.scss';

type Props = {
  integrationId: Nullable<string>;
  isOpen: boolean;
  onClose(): void;
  onRetry?(): void;
  variant?: ErrorVariant;
};

export const IntegrationErrorDialog: FC<Props> = ({
  integrationId,
  isOpen,
  onClose,
  onRetry,
  variant = 'Unknown',
}) => {
  const integrationsUseCase = useIntegrationsUseCase();
  const { t } = useTranslation('integrations');
  const integration = useMemo(
    () => integrationsUseCase.getIntegrations(true).find((i) => i.id === integrationId),
    [integrationId],
  );

  const currentIntegrationTitle = integration?.name ?? '';
  const currentIcon = integration?.icon ?? '';
  const isWiderIcon = integration?.id === 'salesforce';

  const subtitlesDictionary = {
    ['Unknown']: t('errorDialog.description', {
      provider: t(currentIntegrationTitle),
    }),
    ['SalesforceApiDisabled']: (
      <Trans
        i18nKey={'errorDialog.descriptionSalesForce'}
        ns="integrations"
        components={{
          a: (
            <a
              className={styles.link}
              target={'_blank'}
              href="https://help.salesforce.com/s/articleView?id=000386981&type=1"
            ></a>
          ),
        }}
      />
    ),
  };

  return (
    <Modal containerClass={styles.dialogPaper} open={isOpen} onClose={onClose}>
      <>
        <IconClose onClick={onClose} className={styles.closeIcon} />
        <div className={styles.dialogIcons}>
          <div className={styles.withBorder}>
            <IconConnectPowerlead />
          </div>
          <Divider className={styles.divider} />
          <IconError />
          <div className={styles.integrationIconWrapper}>
            <img
              src={currentIcon}
              alt={t(currentIntegrationTitle)}
              style={{ width: isWiderIcon ? 65 : 52 }}
            />
          </div>
        </div>
        <Typography className={styles.dialogTitle}>{t('errorDialog.title')}</Typography>
        <Typography className={styles.dialogSubtitle}>
          {subtitlesDictionary[variant] ?? subtitlesDictionary.Unknown}
        </Typography>
        <Button
          onClick={onRetry ?? onClose}
          className={styles.dialogButton}
          color="primary"
          variant="contained"
        >
          {t('errorDialog.submit')}
        </Button>
      </>
    </Modal>
  );
};
