import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormLabel, OutlinedInput, Typography } from '@mui/material';

import { AuthIdentityProvider } from '@/features/common/auth/domain/types/AuthIdentityProvider';
import { EmailPasswordDialog } from '@/features/settings/features/account/ui/components/EmailPasswordDialog';

import { FormField } from '@/components';

import GoogleIcon from '@/assets/icons/Google.svg?react';
import MicrosoftIcon from '@/assets/icons/Microsoft.svg?react';

import { useAccountSettingsFormViewModel } from './useAccountSettingsFormProviderViewModel';

import styles from './styles.module.scss';

const ICONS = {
  [AuthIdentityProvider.google]: <GoogleIcon className={styles.providerIcon} />,
  [AuthIdentityProvider.microsoft]: <MicrosoftIcon />,
};

type AccountSettingsFormProviderProps = {
  provider: AuthIdentityProvider;
  onEmailAndPasswordChange: (email: string, password: string) => Promise<void>;
  onNameChange: (name: string) => Promise<void>;
};

export const AccountSettingsFormProvider: FC<AccountSettingsFormProviderProps> = ({
  provider,
  onEmailAndPasswordChange,
  onNameChange,
}) => {
  const { t } = useTranslation('settings');
  const { t: validationTranslate } = useTranslation('validation');

  const { onSubmit, form, disabled, emailPasswordDialog, email } =
    useAccountSettingsFormViewModel({ onNameChange });

  return (
    <>
      <Box className={styles.inputWrapper}>
        <FormField
          error={!!form.formState.errors.name}
          errorText={validationTranslate(form.formState.errors.name?.message || '')}
          labelText={t('account.providerFields.name')}
        >
          <OutlinedInput className={styles.input} {...form.register('name')} />
        </FormField>
      </Box>

      <FormField>
        <Box className={styles.inputWrapper}>
          <FormLabel className={styles.label}>
            {t('account.providerFields.email')}
          </FormLabel>
          <Box className={styles.emailChangeWrapper}>
            {ICONS[provider]}
            <Typography variant={'h3'} className={styles.email}>
              {email}
            </Typography>
            <Button className={styles.editButton} onClick={emailPasswordDialog.onOpen}>
              {t('account.providerFields.edit')}
            </Button>
          </Box>
        </Box>
      </FormField>

      <Button
        type={'submit'}
        variant={'contained'}
        disabled={disabled}
        className={styles.buttonSave}
        onClick={onSubmit}
      >
        {t('account.saveChangesButton')}
      </Button>

      <EmailPasswordDialog
        onEmailAndPasswordChange={onEmailAndPasswordChange}
        isOpen={emailPasswordDialog.isOpen}
        onClose={emailPasswordDialog.onClose}
      />
    </>
  );
};
