import { Container } from 'inversify';

import { RETENTION_TYPES } from '@/ioc/types';

import { IRetentionServicesApi, RetentionApiService, RetentionRepository } from '../data';
import { RetentionUseCase } from '../domain';
import { IRetentionRepository, IRetentionUseCase } from '../domain/abstractions';

export const bindRetentionModule = (container: Container): void => {
  container
    .bind<IRetentionUseCase>(RETENTION_TYPES.RetentionUseCase)
    .to(RetentionUseCase);
  container
    .bind<IRetentionRepository>(RETENTION_TYPES.RetentionRepository)
    .to(RetentionRepository);
  container
    .bind<IRetentionServicesApi>(RETENTION_TYPES.RetentionApiService)
    .to(RetentionApiService);
};
