import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { MenuList, MenuListProps, styled } from '@mui/material';

import { CustomSelectV2Context } from '../../../../CustomSelectV2Context';
import { Option, OptionType, PrimitiveValue } from '../../../../types';

import { Item, NoResults } from './components';

export type ListProps<
  OptionValue extends PrimitiveValue,
  Type extends OptionType = 'default',
> = MenuListProps & {
  optionType?: Type;
  emptyState?: Nullable<string | ReactElement>;
  renderItem?: (params: {
    option: Option<OptionValue, Type>;
    index: number;
    isSelected: boolean;
  }) => ReactElement;
};

const Wrapper = styled(MenuList)<MenuListProps>(() => ({
  padding: '10px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '5px',
  border: 'none',
  outline: 'none',
}));

export function List<
  OptionValue extends PrimitiveValue,
  Type extends OptionType = 'default',
>({
  emptyState,
  optionType,
  renderItem,
  ...restProps
}: ListProps<OptionValue, Type>): ReactElement | null {
  const { value, selectItem, searchValue, searchable, options, isSelected } =
    useContext(CustomSelectV2Context);
  const focusedItemRef = useRef<HTMLLIElement | null>(null);
  const isAvailableToProgramAccessability = searchable;

  const getOptions = (): Option<OptionValue, Type>[] => {
    if (!searchValue) options;

    return options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    ) as Option<OptionValue, Type>[];
  };
  const optionsList: Option<OptionValue, Type>[] = getOptions();
  const isEmpty = optionsList.length === 0;
  const [focusedIndex, setFocusedIndex] = useState(getInitialFocusedIndex);

  function getInitialFocusedIndex(): number {
    if (!isAvailableToProgramAccessability || !value) return -1;

    if (Array.isArray(value)) {
      return optionsList.findIndex((option) => option.value === value[0]) || -1;
    }

    return optionsList.findIndex((option) => option.value === value) || -1;
  }

  const handleKeyDown = (event: KeyboardEvent): void => {
    const listLength = optionsList.length;

    switch (event.key) {
      case 'ArrowDown':
        setFocusedIndex((prevIndex) => {
          return Math.min(prevIndex + 1, listLength - 1);
        });
        break;
      case 'ArrowUp':
        setFocusedIndex((prevIndex) => {
          return Math.max(prevIndex - 1, 0);
        });
        break;
      case 'Enter':
        if (focusedItemRef.current) {
          focusedItemRef.current.click();
        }
        break;
    }
  };

  useEffect(() => {
    if (!isAvailableToProgramAccessability) return;

    setFocusedIndex(getInitialFocusedIndex);

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [optionsList.length]);

  useEffect(() => {
    if (focusedItemRef.current) {
      focusedItemRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [focusedIndex]);

  if (isEmpty) {
    if (emptyState === undefined || emptyState === null) return null;

    return <NoResults>{emptyState}</NoResults>;
  }

  return (
    <Wrapper {...restProps} autoFocusItem={!searchable}>
      {optionsList.map((option, index) => {
        const selected = isSelected(option);

        return (
          <Item
            {...(focusedIndex === index && { ref: focusedItemRef })}
            key={option.label}
            index={index}
            option={option}
            renderItem={renderItem}
            // @ts-ignore
            value={option.value}
            variant={optionType || ('default' satisfies OptionType)}
            selected={selected}
            disabled={option?.disabled || false}
            focused={index === focusedIndex}
            onClick={(): void => {
              selectItem(option);
            }}
            onFocus={(): void => {
              setFocusedIndex(-1);
            }}
          />
        );
      })}
    </Wrapper>
  );
}
