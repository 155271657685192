import { Container } from 'inversify';

import { BILLING_TYPES } from '@/ioc/types';

import { billingConfig } from '../config';
import { BillingRepository } from '../data';
import { BillingState, IBillingState } from '../data/db/BillingState';
import { BillingStateCreator } from '../data/db/BillingStateCreator';
import { BillingApiService } from '../data/network/BillingApiService';
import { BillingUseCase } from '../domain';

export function bindBillingModule(container: Container): void {
  container
    .bind(BILLING_TYPES.BillingRepository)
    .to(BillingRepository)
    .inSingletonScope();
  container.bind<BillingStateCreator>('DbStateCreator').to(BillingStateCreator);
  container
    .bind<IBillingState>(BILLING_TYPES.BillingState)
    .to(BillingState)
    .inSingletonScope();
  container.bind(BILLING_TYPES.BillingApiService).to(BillingApiService);
  container.bind(BILLING_TYPES.BillingUseCase).to(BillingUseCase);
  container.bind(BILLING_TYPES.BillingConfig).toConstantValue(billingConfig);
}
