import { useTranslation } from 'react-i18next';

import { ChartCardCircleProps } from '../ChartCardCircle';

import { useChartOptions } from './useChartOptions';
import { useChartProcessedData } from './useChartProcessedData';

import { theme } from '@/theme';

const PieColors = {
  Success: theme.palette.success.main,
  Fail: theme.palette.error.main,
  NotFound: theme.palette.info.main,
};

export const useChartCardCircleViewModel = ({ type, data }: ChartCardCircleProps) => {
  const { t } = useTranslation('enrichment', { keyPrefix: 'report.chart' });
  const isEmailsChart = type === 'emails';
  const title = isEmailsChart ? t('emailTitle') : t('recordTitle');
  const successTitle = isEmailsChart ? t('verified') : t('matched');
  const failedTitle = isEmailsChart ? t('notVerified') : t('duplicate');
  const notFoundTitle = t('notFound');
  const datasetData = isEmailsChart
    ? [data.verified, data.notVerified, data.notFound]
    : [data.matched, data.duplicate, data.notFound];
  const isChartRendered = datasetData.some((value) => value > 0);

  const processedData = useChartProcessedData(datasetData);
  const options = useChartOptions(datasetData);

  const legendItems = [
    { title: successTitle, color: PieColors.Success },
    { title: failedTitle, color: PieColors.Fail },
    { title: notFoundTitle, color: PieColors.NotFound },
  ];

  const chartData = {
    labels: [notFoundTitle, successTitle, failedTitle],
    datasets: [
      {
        data: processedData,
        backgroundColor: Object.values(PieColors),
        borderWidth: 0,
        datalabels: {
          formatter: () => null,
        },
      },
    ],
  };

  return {
    title,
    legendItems,
    chartProps: {
      data: chartData,
      options,
    },
    isChartRendered,
  };
};
