import type { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    color: 'info',
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const { color } = ownerState as {
        color: string;
      };

      const palette = theme.palette[color];

      if (ownerState.disabled) {
        return {
          borderRadius: '4px',

          path: {
            fill: palette?.disabled ?? color,
          },
        };
      }

      return {
        borderRadius: '4px',

        path: {
          fill: palette?.main,
        },

        '&:hover': {
          backgroundColor: palette?.disabled,
        },

        '&:hover path': {
          fill: palette?.dark,
        },
      };
    },
  },
};
