import { Container } from 'inversify';

import { PERMISSIONS_TYPES } from '@/ioc/types';

import {
  IPermissionsRepository,
  PermissionsRepository,
} from '../data/PermissionsRepository';
import { IPermissionsUseCase, PermissionsUseCase } from '../domain/PermissionsUseCase';

export const bindPermissionsModule = (container: Container): void => {
  container
    .bind<IPermissionsUseCase>(PERMISSIONS_TYPES.PermissionsUseCase)
    .to(PermissionsUseCase);

  container
    .bind<IPermissionsRepository>(PERMISSIONS_TYPES.PermissionsRepository)
    .to(PermissionsRepository)
    .inSingletonScope();
};
