import dayjs from 'dayjs';

import { LabelDto } from '@/features/dashboard/features/chart/ui/entities/ChartLabel';

import { MONTHS_SHORTCUTS } from '@/translations/enums/monthsShortcuts';

export const mapEntitiesToDateStrings = ({ at }: { at: number }): number => at;

export const mapDateStringToLabelDto = (dateString: number): LabelDto => {
  const dateObj = dayjs.unix(dateString);
  const date = dateObj.get('date');
  const monthIndex = dateObj.get('month');
  const monthKey = Object.values(MONTHS_SHORTCUTS)[monthIndex];
  const year = dateObj.format('YY');

  return {
    monthKey,
    date,
    year,
  };
};
