import {
  useAccount,
  useAccountMonthlyCredits,
  UserRole,
} from '@/features/common/account';
import { PlanType, useCurrentPlanType } from '@/features/common/billing';
import { usePlanName } from '@/features/common/workspace';
import { useHasUserReceivedFreeCredits } from '@/features/getFreeCredits';

import { UserData } from './types';

export const useHeaderViewModel = () => {
  const { account } = useAccount();
  const { data: planName } = usePlanName();
  const { data: planType } = useCurrentPlanType();
  const { data: hasUserReceivedFreeCredits } = useHasUserReceivedFreeCredits();

  const accountMontlyCredits = useAccountMonthlyCredits();

  const userData: UserData = {
    planName: planName ?? '',
    planType: planType ?? PlanType.Free,
    userLogo: account?.thumbnail ?? '',
    fullName: account?.fullName ?? '',
    email: account?.email ?? '',
    creditsUsed: accountMontlyCredits.used,
    creditsLimit: accountMontlyCredits.limit,
    hasUserReceivedFreeCredits,
    role: account?.role || UserRole.Member,
  };

  return {
    userData,
  };
};
