import { FC } from 'react';
import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { EmailMenu } from './components/EmailMenu';
import { IntegrationsMenu } from './components/IntegrationsMenu';
import { PhoneMenu } from './components/PhoneMenu';
import { Preview } from './components/Preview';
import { SocialLinksMenu } from './components/SocialLinksMenu';
import { useContactInfoViewModel } from './useContactInfoViewModel';

import styles from './styles.module.scss';

export const ContactInfoCell: FC<GridCellParams> & {
  Preview: FC;
} = ({ row }) => {
  const value = useContactInfoViewModel(row);

  if (!value) {
    return <Box>-</Box>;
  }

  return (
    <Box className={styles.contactsCell}>
      {Boolean(value.emails?.length) && (
        <EmailMenu contactId={String(row.uuid)} emails={value.emails} />
      )}
      {Boolean(value.phoneNumbers?.length) && (
        <PhoneMenu contactId={String(row.uuid)} items={value.phoneNumbers} />
      )}
      {Boolean(value.socialLinks?.length) && (
        <SocialLinksMenu links={value.socialLinks} />
      )}
      {Boolean(value.exportedLinks?.length) && (
        <IntegrationsMenu links={value.exportedLinks} />
      )}
    </Box>
  );
};

ContactInfoCell.Preview = Preview;
