import {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { indexBy, prop } from 'ramda';
import * as yup from 'yup';

import {
  EnrichmentDataType,
  EnrichmentFileHeaderField,
} from '@/features/enrichment/domain/types';
import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';
import { useEnrichmentFileUploadUseCase } from '@/features/enrichment/ui/Enrichment/hooks';

import { useFormWithSchema } from '@/utils/validation';

const useConfigFieldsMapperFormSchema = () => {
  const { fileUploadResponse } = useContext(EnrichmentUploadingContext);
  const enrichmentFileUploadUseCase = useEnrichmentFileUploadUseCase();
  const { t } = useTranslation('enrichment');

  const exampleDataMapByCsvHeaderName = useMemo(() => {
    if (!fileUploadResponse) return {};

    return indexBy(prop('csv_name'), fileUploadResponse.headers);
  }, [fileUploadResponse?.uuid]);

  const validationFn = (headerField: EnrichmentFileHeaderField, value?: string) => {
    const exampleData = exampleDataMapByCsvHeaderName[value ?? '']?.example ?? '';

    return enrichmentFileUploadUseCase.validateHeaderField({
      headerField,
      exampleData,
    }).isValid;
  };

  return useMemo(() => {
    return yup.object({
      headers_map: yup
        .object({
          [EnrichmentFileHeaderField.FirstName]: yup
            .string()
            .test(
              'isMatch',
              t('uploadingFieldsMapperModal.errors.firstName'),
              (value) => {
                return validationFn(EnrichmentFileHeaderField.FirstName, value);
              },
            ),
          [EnrichmentFileHeaderField.LastName]: yup
            .string()
            .test('isMatch', t('uploadingFieldsMapperModal.errors.lastName'), (value) => {
              return validationFn(EnrichmentFileHeaderField.LastName, value);
            }),
          [EnrichmentFileHeaderField.FullName]: yup
            .string()
            .test('isMatch', t('uploadingFieldsMapperModal.errors.fullName'), (value) => {
              return validationFn(EnrichmentFileHeaderField.FullName, value);
            }),
          [EnrichmentFileHeaderField.Email]: yup
            .string()
            .test('isMatch', t('uploadingFieldsMapperModal.errors.email'), (value) => {
              return validationFn(EnrichmentFileHeaderField.Email, value);
            }),
          [EnrichmentFileHeaderField.LinkedinUrl]: yup
            .string()
            .test(
              'isMatch',
              t('uploadingFieldsMapperModal.errors.linkedinUrl'),
              (value) => {
                return validationFn(EnrichmentFileHeaderField.LinkedinUrl, value);
              },
            ),
          [EnrichmentFileHeaderField.Website]: yup
            .string()
            .test('isMatch', t('uploadingFieldsMapperModal.errors.website'), (value) => {
              return validationFn(EnrichmentFileHeaderField.Website, value);
            }),
          [EnrichmentFileHeaderField.CompanyName]: yup
            .string()
            .test(
              'isMatch',
              t('uploadingFieldsMapperModal.errors.companyName'),
              (value) => {
                return validationFn(EnrichmentFileHeaderField.CompanyName, value);
              },
            ),
        })
        .required()
        .test(
          'requiredCombination',
          t('uploadingFieldsMapperModal.errors.requiredCombination'),
          (value) => {
            return enrichmentFileUploadUseCase.validateRequiredHeaderFieldsCombination(
              value,
            ).isValid;
          },
        ),
    });
  }, [fileUploadResponse?.uuid]);
};

const ConfigViewSettingsFormSchema = yup.object({
  enrichByEmail: yup
    .boolean()
    .required()
    .test('oneOf', 'At least one option should be selected', (value, { parent }) => {
      if (value === true) return true;

      return parent.enrichByPhone === true;
    }),
  enrichByPhone: yup
    .boolean()
    .required()
    .test('oneOf', 'At least one option should be selected', (value, { parent }) => {
      if (value === true) return true;

      return parent.enrichByEmail === true;
    }),
  settings: yup.array().of(yup.string().required()).required(),
});

export type ConfigViewFieldsMapperForm = yup.InferType<
  ReturnType<typeof useConfigFieldsMapperFormSchema>
>;
export type ConfigViewSettingsForm = yup.InferType<typeof ConfigViewSettingsFormSchema>;

export const FormContext = createContext<{
  fieldsMapperForm: UseFormReturn<ConfigViewFieldsMapperForm>;
  settingsForm: UseFormReturn<ConfigViewSettingsForm>;
}>({
  fieldsMapperForm: {} as UseFormReturn<ConfigViewFieldsMapperForm>,
  settingsForm: {} as UseFormReturn<ConfigViewSettingsForm>,
});

const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const ConfigViewFieldsMapperFormSchema = useConfigFieldsMapperFormSchema();
  const { fileUploadResponse } = useContext(EnrichmentUploadingContext);
  const fieldsMapperForm = useFormWithSchema(ConfigViewFieldsMapperFormSchema, {
    defaultValues: {
      headers_map: Object.values(EnrichmentFileHeaderField).reduce((acc, field) => {
        acc[field] = fileUploadResponse?.headers_map[field] ?? '';
        return acc;
      }, {}),
    },
  });

  const settingsForm = useFormWithSchema(ConfigViewSettingsFormSchema, {
    defaultValues: {
      enrichByEmail:
        fileUploadResponse?.previous_config?.data_to_enrich.includes(
          EnrichmentDataType.Email,
        ) ?? true,
      enrichByPhone:
        fileUploadResponse?.previous_config?.data_to_enrich.includes(
          EnrichmentDataType.Phones,
        ) ?? true,
      settings:
        fileUploadResponse?.previous_config?.settings ??
        fileUploadResponse?.settings ??
        [],
    },
  });

  useEffect(() => {
    fieldsMapperForm.trigger('headers_map');
  }, []);

  return (
    <FormContext.Provider value={{ fieldsMapperForm, settingsForm }}>
      {children}
    </FormContext.Provider>
  );
};

export function withFormProvider<Props extends object>(
  Component: ComponentType<Props>,
): FC<Props> {
  return (props: Props) => {
    return (
      <FormProvider>
        {/* @ts-ignore */}
        <Component {...props} />
      </FormProvider>
    );
  };
}
