import { injectable } from 'inversify';
import { Observable } from 'rxjs';

import { DbState, IDbState } from '@/features/system/db/data/DbState';

import { IIntegrationDC, IIntegrationMapDC } from '../dataContracts';

type Provider = IIntegrationDC['provider'];

export interface IIntegrationStateDC {
  [key: Provider]: Nullable<{
    integration: IIntegrationDC;
    mappings: {
      [key: string]: Nullable<IIntegrationMapDC>;
    };
  }>;
}

export type IIntegrationState = IDbState<IIntegrationStateDC> & {
  getIntegration: (provider: Provider) => Observable<Nullable<IIntegrationDC>>;
  setIntegration: (provider: Provider, integration: IIntegrationDC) => Promise<void>;

  getMapping: (
    provider: Provider,
    mappingObjectType: string,
  ) => Observable<Nullable<IIntegrationMapDC>>;
  setMapping: (
    provider: Provider,
    mappingObjectType: string,
    mapping: IIntegrationMapDC,
  ) => Promise<void>;
};

@injectable()
export class IntegrationState
  extends DbState<IIntegrationStateDC>
  implements IIntegrationState
{
  constructor() {
    super({ stateName: 'integration' });
  }

  private getIntegrationKey(provider: IIntegrationDC['provider']): string {
    return `${provider}.integration`;
  }

  private getMappingKey(
    provider: IIntegrationDC['provider'],
    mappingObjectType: string,
  ): string {
    return `${provider}.mappings.${mappingObjectType}`;
  }

  getIntegration(
    provider: IIntegrationDC['provider'],
  ): Observable<Nullable<IIntegrationDC>> {
    return this.get$(this.getIntegrationKey(provider)) as Observable<
      Nullable<IIntegrationDC>
    >;
  }

  setIntegration(provider: Provider, integration: IIntegrationDC): Promise<void> {
    return this.set(this.getIntegrationKey(provider), () => integration);
  }

  getMapping(
    provider: Provider,
    mappingObjectType: string,
  ): Observable<Nullable<IIntegrationMapDC>> {
    return this.get$(this.getMappingKey(provider, mappingObjectType)) as Observable<
      Nullable<IIntegrationMapDC>
    >;
  }

  setMapping(
    provider: Provider,
    mappingObjectType: string,
    mapping: IIntegrationMapDC,
  ): Promise<void> {
    return this.set(this.getMappingKey(provider, mappingObjectType), () => mapping);
  }
}
