import { FC, useContext } from 'react';

import { EnrichmentUploadingContext } from '../../../../EnrichmentUploadingContext';

import {
  ConfigViewFieldsMapper,
  ConfigViewResult,
  ConfigViewSettings,
} from './components';
import { withFormProvider } from './FormContext';

export const ConfigView: FC = withFormProvider(() => {
  const { currentView } = useContext(EnrichmentUploadingContext);

  switch (currentView) {
    case 'ConfigSettingsView':
      return <ConfigViewSettings />;
    case 'ConfigResultView':
      return <ConfigViewResult />;
    default:
      return <ConfigViewFieldsMapper />;
  }
});
