import { FC } from 'react';

import { useBillingBannerViewModel } from './BillingBannerViewModel';

type BillingBannerProps = {
  // in all pages we use global except for the settings page.
  isGlobal?: boolean;
};

export const BillingBanner: FC<BillingBannerProps> = ({ isGlobal }) => {
  const { banner: Banner } = useBillingBannerViewModel({ isGlobal });

  if (!Banner) {
    return null;
  }

  return <Banner />;
};
