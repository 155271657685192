import { EXTERNAL_ROUTES } from '@/router/routes';

import { useAccount, UserRole } from '@/features/common/account';
import { useExtensionState } from '@/features/common/extension';
import { useIsFairUsageLimitReached } from '@/features/common/fairUsage';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import {
  ANALYTICS_EVENTS,
  useAnalytics,
  WalkthroughStep,
} from '@/features/system/analytics';

import { openInNewTab } from '@/utils/openInNewTab';
import { useObservableResult } from '@/utils/rx';

export const useDashboardHeaderViewModel = (): {
  accountFullName: string;
  isFairUsageLimitReached: boolean;
  onboardingStep: Nullable<'installExtension' | 'revealContact'>;
  onClickRevealContact: () => void;
  onClickInstallExtension: () => void;
} => {
  const { account } = useAccount();
  const { isFairUsageLimitReached } = useIsFairUsageLimitReached();
  const analytics = useAnalytics();
  const onboardingUseCase = useOnboardingUseCase();
  const { isInstalled: isExtensionInstalled } = useExtensionState();
  const { data: stepList } = useObservableResult(() => onboardingUseCase.getStepList(), {
    deps: [],
  });
  const isContactRevealed = stepList?.some(
    (i) => i.step === 'revealContact' && i.status === 'completed',
  );

  const onClickRevealContact = (): void => {
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL);
  };

  const onClickInstallExtension = (): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH, {
      step: WalkthroughStep.idle,
    });
    onboardingUseCase.completeClickDownloadExtensionStep();
    openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
  };

  const getOnboardingStep = (): Nullable<'installExtension' | 'revealContact'> => {
    if (account?.role === UserRole.Manager) return null;

    if (!isExtensionInstalled || !isContactRevealed) return null;

    if (isExtensionInstalled) return 'revealContact';

    return 'installExtension';
  };

  return {
    accountFullName: account?.fullName || '',
    isFairUsageLimitReached,
    onboardingStep: getOnboardingStep(),
    onClickRevealContact,
    onClickInstallExtension,
  };
};
