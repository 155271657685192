import { FC, PropsWithChildren, useContext } from 'react';
import { Box, BoxProps, Popover, PopoverProps, styled } from '@mui/material';

import { CustomSelectV2Context } from '../../CustomSelectV2Context';

export type MenuProps = {
  PopoverProps?: Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>;
};

const Wrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Menu: FC<PropsWithChildren<MenuProps>> = ({
  children,
  PopoverProps = {},
}) => {
  const { anchorElement, closeMenu } = useContext(CustomSelectV2Context);

  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={closeMenu}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      {...PopoverProps}
    >
      <Wrapper>{children}</Wrapper>
    </Popover>
  );
};
