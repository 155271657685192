import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { ENRICHMENT_TYPES, PERMISSIONS_TYPES } from '@/ioc/types';

import { IPermissionsRepository, Permission } from '@/features/common/permissions';

import { IEnrichmentRepository, IEnrichmentUseCase } from './abstraction';
import { IEnrichmentEntity } from './entities';
import { EnrichmentSortSchema } from './types';

@injectable()
export class EnrichmentUseCase implements IEnrichmentUseCase {
  @inject(ENRICHMENT_TYPES.EnrichmentRepository)
  private readonly enrichmentRepository: IEnrichmentRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private readonly permissionsRepository: IPermissionsRepository;

  public getAllByParams(
    params: {
      name?: string;
      createdBy?: string;
    },
    sortSchema?: EnrichmentSortSchema,
  ): Observable<IEnrichmentEntity[]> {
    return this.enrichmentRepository.getAllByParams(params, sortSchema);
  }

  public download(uuid: string): Promise<string> {
    return this.enrichmentRepository.downloadReport(uuid);
  }

  public ensureAccess(): Observable<{ hasAccess: boolean }> {
    return this.permissionsRepository
      .hasPermissions(Permission.CanAccessCsvEnrichemnt)
      .pipe(
        map((hasAccess) => {
          return {
            hasAccess,
          };
        }),
      );
  }

  public contactAdmin(): Promise<boolean> {
    return this.enrichmentRepository.contactAdmin();
  }
}
