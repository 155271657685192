import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ICompanyEntity } from '@/features/common/contact';
import { usePhoneHelper } from '@/features/system/PhoneHelper';

export const getFormattedMarketCap = (value?: Nullable<number>): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
  });

  if (!value) return '';

  if (Number.isNaN(value)) return '';

  return formatter.format(value);
};

export const useFormattedCompanyData = (
  obj: ICompanyEntity,
): {
  showEmployeeSection: number;
  employeeCountResult: string | number;
  totalFunding: string;
  totalRevenue: string;
  format: (phone: string) => string;
} => {
  const { t } = useTranslation('contactsTable');
  const { format } = usePhoneHelper();

  const totalFunding =
    obj.totalFunding ||
    obj.fundingRounds?.reduce((sum, round) => sum + round.moneyRaised, 0) ||
    0;

  const formatEmployeesCount = useCallback(
    (count = 0) => {
      return count > 1000
        ? t('companyCell.formattedThousand', {
            amount: parseFloat((count / 1000).toFixed(1)),
          })
        : count;
    },
    [t],
  );

  const showEmployeeSection = (obj.employeesMax && obj.employeesMin) || obj.employees;

  const employeesMinFormatted = formatEmployeesCount(obj.employeesMin);
  const employeesMaxFormatted = formatEmployeesCount(obj.employeesMax);
  const employeesDefaultFormatted = formatEmployeesCount(obj.employees);

  const isEmployeeRangeAvailable =
    employeesMinFormatted &&
    employeesMaxFormatted &&
    employeesMaxFormatted !== employeesMinFormatted;

  const employeeCountResult = isEmployeeRangeAvailable
    ? `${employeesMinFormatted} - ${employeesMaxFormatted}`
    : employeesDefaultFormatted || employeesMinFormatted; // sometimes range min and max are the same and employee default field is empty

  return {
    showEmployeeSection,
    employeeCountResult,
    totalFunding: getFormattedMarketCap(totalFunding),
    totalRevenue: getFormattedMarketCap(obj.totalRevenue),
    format,
  };
};
