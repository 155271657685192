import { QueryChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/QueryChartDataDC';
import { QueryChartData } from '@/features/dashboard/features/chart/domain/types/QueryChartData';

export const mapQueryChartEntityToDc = (entity: QueryChartData): QueryChartDataDC => {
  return {
    from_dt: entity.fromDate,
    to_dt: entity.toDate,
    member: entity.member,
  };
};
