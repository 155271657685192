import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HelpAndSupportIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.7 17.7a6.789 6.789 0 0 1-2.724-.552 7.028 7.028 0 0 1-3.724-3.724 7 7 0 0 1 0-5.448 7.028 7.028 0 0 1 3.724-3.724 7.022 7.022 0 0 1 5.457 0 7.051 7.051 0 0 1 3.716 3.724 7 7 0 0 1 0 5.448 7.028 7.028 0 0 1-3.724 3.724 6.789 6.789 0 0 1-2.725.552zm-2.069-1.517 1.052-2.517a3.129 3.129 0 0 1-1.172-.724 3.165 3.165 0 0 1-.776-1.207l-2.517 1a6.959 6.959 0 0 0 1.405 2.112 5.725 5.725 0 0 0 2.009 1.336zm-.914-6.5A3.373 3.373 0 0 1 8.5 8.459a3.053 3.053 0 0 1 1.164-.724L8.648 5.217a6.582 6.582 0 0 0-2.164 1.431 5.2 5.2 0 0 0-1.267 2.035zm2.983 2.983a1.943 1.943 0 0 0 1.966-1.966A1.943 1.943 0 0 0 10.7 8.734 1.943 1.943 0 0 0 8.734 10.7a1.943 1.943 0 0 0 1.966 1.966zm2.069 3.517a6.175 6.175 0 0 0 2.069-1.362 6 6 0 0 0 1.345-2.052l-2.517-1.034a3.145 3.145 0 0 1-.776 1.224 2.948 2.948 0 0 1-1.172.707zm.9-6.517 2.517-1.034a5.941 5.941 0 0 0-1.353-2.052 6.315 6.315 0 0 0-2.06-1.362l-1.021 2.516a2.863 2.863 0 0 1 1.155.716 3.47 3.47 0 0 1 .759 1.216z"
      transform="translate(-3.7 -3.7)"
    />
  </SvgIcon>
);
