export const dashboardChartMigrationStrategies = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  1: (oldDoc) => {
    oldDoc.credits_used = oldDoc.credit_used;
    oldDoc.emails_count = oldDoc.email_count;
    oldDoc.phones_count = oldDoc.phone_count;
    oldDoc.revealed_contacts_count = 0;
    oldDoc.total_revealed_contacts_count = 0;
    oldDoc.total_credits_used = 0;
    oldDoc.total_emails_count = 0;
    oldDoc.total_phones_count = 0;
    oldDoc.current_revealed_contacts_count = 0;
    oldDoc.current_credits_used = 0;
    oldDoc.current_emails_count = 0;
    oldDoc.current_phones_count = 0;

    return oldDoc;
  },
};
