import { type FC, useEffect } from 'react';
import { distinctUntilKeyChanged, filter } from 'rxjs';

import { type IAccountEntity, useAccountUseCase } from '@/features/common/account';

import { useContextualOnboardingUseCase } from '../../hooks';

export const ContextualOnboarding: FC = () => {
  const contextualOnboadringUseCase = useContextualOnboardingUseCase();
  const accountUseCase = useAccountUseCase();

  useEffect(() => {
    contextualOnboadringUseCase.init();

    const subscription = accountUseCase
      .getAccount()
      .pipe(
        filter((account): account is IAccountEntity => !!account),
        distinctUntilKeyChanged('uuid'),
      )
      .subscribe((account) => {
        contextualOnboadringUseCase.identify(account);
      });

    return () => subscription.unsubscribe();
  }, []);

  return null;
};
