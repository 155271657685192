import { useState } from 'react';

type UseFormatCurrency = () => (amount: number) => string;

export const useFormatCurrency: UseFormatCurrency = () => {
  const [formatter] = useState(
    () => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
  );

  return (amount: number) => formatter.format(amount);
};
