import { Container } from 'inversify';

import { FEATURE_FLAG_TYPES } from '@/ioc/types';

import { FeatureFlagManager, IFeatureFlagManager } from '../FeatureFlagManager';

export const bindFeatureFlagModule = (container: Container): void => {
  container
    .bind<IFeatureFlagManager>(FEATURE_FLAG_TYPES.FeatureFlagManager)
    .to(FeatureFlagManager)
    .inSingletonScope();
};
