import { inject, injectable } from 'inversify';
import {
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';

import { INTEGRATION_TYPES, SYNC_TYPES } from '@/ioc/types';

import type { IBaseSyncRepository } from '@/features/system/sync';

import {
  ExportToIntegrationError,
  type IIntegrationApiService,
  type IIntegrationEntity,
  type IIntegrationMapEntity,
  type IIntegrationRepository,
} from '../domain';

import { mapError } from './mappers/mapError';
import { IIntegrationDC, IIntegrationMapDC } from './dataContracts';
import { IIntegrationExportState, IIntegrationState } from './db';
import {
  mapIntegrationDCtoEntity,
  mapIntegrationMapDCtoEntity,
  mapIntegrationMapEntityToDC,
} from './mappers';

@injectable()
export class IntegrationRepository implements IIntegrationRepository {
  @inject(INTEGRATION_TYPES.IntegrationApiService)
  private api: IIntegrationApiService;

  @inject(INTEGRATION_TYPES.IntegrationState)
  private state: IIntegrationState;

  @inject(INTEGRATION_TYPES.IntegrationExportState)
  private exportState: IIntegrationExportState;

  constructor(
    @inject(SYNC_TYPES.BaseSyncRepository)
    private readonly baseSyncRepository: IBaseSyncRepository,
  ) {
    this.baseSyncRepository.getIntegrationExportTaskEvents().subscribe((event) => {
      if (event.entity.status === 'pending') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'pending',
        });
      }

      if (event.entity.status === 'finished') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'finished',
          contactsNumber: Object.values(event.entity.result ?? {}).length,
        });
      }

      if (event.entity.status === 'failed') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'failed',
          reason: event.entity.error_desc ?? 'Unknown error',
        });
      }
    });
  }

  private async getRemoteMapping(
    provider: string,
    objectType: string,
  ): Promise<IIntegrationMapDC> {
    try {
      const mapping = await firstValueFrom(this.api.getMapping(provider, objectType));

      await this.state.setMapping(provider, objectType, mapping);

      return mapping;
    } catch (e) {
      throw mapError(e);
    }
  }

  getMapping(provider: string, objectType: string): Observable<IIntegrationMapEntity> {
    return from(this.getRemoteMapping(provider, objectType)).pipe(
      switchMap(() => this.state.getMapping(provider, objectType)),
      filter((mapping): mapping is IIntegrationMapDC => !!mapping),
      map(mapIntegrationMapDCtoEntity),
    );
  }

  async saveMapping(provider: string, objectType: string, data: []): Promise<void> {
    const dataDC = data.map(mapIntegrationMapEntityToDC);
    await firstValueFrom(this.api.saveMapping(provider, objectType, dataDC));
  }

  async updateSettings(provider: string, enabledObjects: string[]): Promise<void> {
    const integration = await firstValueFrom(
      this.api.updateSettings(provider, enabledObjects),
    );
    await this.state.setIntegration(provider, integration);
  }

  async disconnectUser(provider: string): Promise<void> {
    await firstValueFrom(this.api.disconnectUser(provider));
  }

  async exportByIds(integrationName: string, ids: string[]): Promise<void> {
    return firstValueFrom(
      this.api.exportByIds(integrationName, ids).pipe(
        map(({ task_id }) => task_id),
        switchMap((taskId) => this.exportState.getExportState(taskId)),
        filter((state) => state.status !== 'pending'),
        switchMap((state) => {
          if (state.status === 'failed') {
            return throwError(
              () =>
                new ExportToIntegrationError('Export to integration error', {
                  cause: state.reason,
                }),
            );
          }

          return of(undefined);
        }),
      ),
    );
  }

  private async getRemoteIntegration(provider: string): Promise<IIntegrationDC> {
    try {
      const integration = await firstValueFrom(this.api.getIntegration(provider));

      await this.state.setIntegration(provider, integration);

      return integration;
    } catch (e) {
      throw mapError(e);
    }
  }

  private getLocalIntegration(provider: string): Observable<IIntegrationEntity> {
    return this.state.getIntegration(provider).pipe(
      filter((integration): integration is IIntegrationDC => !!integration),
      map(mapIntegrationDCtoEntity),
    );
  }

  getIntegration(provider: string): Observable<IIntegrationEntity> {
    return from(this.getRemoteIntegration(provider)).pipe(
      switchMap(() => this.getLocalIntegration(provider)),
    );
  }
}
