import React from 'react';
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import styles from '@/features/settings/features/teamManagement/ui/components/TeamMembersTable/styles.module.scss';

const MemberName: React.FC<GridRenderCellParams> = ({ value }) => {
  return <Typography className={styles.Name}>{value}</Typography>;
};

export default MemberName;
