import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { COMPANY_TYPES } from '@/ioc/types';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { ICompanyEntity, ICompanyUseCase } from '../../domain';

export const useCompanyById = (id: string): ObservableResult<ICompanyEntity> => {
  const companyUseCase = useInjection<ICompanyUseCase>(COMPANY_TYPES.CompanyUseCase);
  const companyById$ = useMemo(() => companyUseCase.getById(id), [id]);

  return useObservableResult(companyById$);
};
