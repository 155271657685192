import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { ArrowBackIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const PaymentDetailsLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation('paymentDetails');

  return (
    <Box className={styles.page}>
      <NavLink to={ROUTES.BILLING.PLANS}>
        <div className={styles.breadcrumb}>
          <ArrowBackIcon className={styles.breadcrumbIcon} />
          <Typography className={styles.breadcrumbText}>{t('back')}</Typography>
        </div>
      </NavLink>
      <Typography variant="h1" className={styles.title}>
        {t('title')}
      </Typography>
      <div className={styles.content}>{children}</div>
    </Box>
  );
};
