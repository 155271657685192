import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OutlinedInput, Typography } from '@mui/material';
import clsx from 'clsx';

import { FormField } from '@/components';

import {
  AppliedViewModel,
  EnteringViewModel,
  InitialViewModel,
  PromotionCodeViewModelKind,
  usePromotionCodeViewModel,
} from './PromotionCodeViewModel';

import styles from './styles.module.scss';

const InitialView: FC<InitialViewModel> = (props) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'promotionCode' });

  return (
    <div className={styles.promo}>
      <Button variant="text" onClick={props.onEnter} className={styles.promoButtonText}>
        {t('enter')}
      </Button>
    </div>
  );
};

const EnteringView: FC<EnteringViewModel> = (props) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'promotionCode' });
  const [isProcessing, setIsProcessing] = useState(false);

  async function handleApply(): Promise<void> {
    try {
      setIsProcessing(true);
      await props.onApply();
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <div className={styles.promo}>
      <div>
        <FormField error={Boolean(props.error)} errorText={props.error && t(props.error)}>
          <Fragment>
            <OutlinedInput
              placeholder={t('placeholder')}
              value={props.code}
              onChange={(e): void => props.onChange(e.target.value)}
              // @ts-ignore
              size="large"
              className={styles.promoInput}
              disabled={isProcessing}
              autoFocus
              error={Boolean(props.error)}
              onKeyDown={(e): void => {
                if (e.key === 'Enter') {
                  handleApply();
                }
              }}
            />
            <Button
              variant="text"
              onClick={(): void => {
                handleApply();
              }}
              disabled={isProcessing}
              className={styles.promoButtonText}
            >
              {isProcessing ? t('applying') : t('apply')}
            </Button>
          </Fragment>
        </FormField>
      </div>
    </div>
  );
};

const AppliedView: FC<AppliedViewModel> = (props) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'promotionCode' });

  return (
    <div className={clsx(styles.promo, styles.applied)}>
      <div className={styles.promoAppliedBlock}>
        <Typography className={styles.promoAppliedTitle}>Coupon code</Typography>
        <Typography className={styles.promoAppliedCode}>{props.appliedPromo}</Typography>
      </div>
      <Button
        variant="text"
        size="small"
        color="info"
        onClick={props.onRemove}
        className={styles.promoButtonRemove}
      >
        {t('remove')}
      </Button>
    </div>
  );
};

export const PromotionCode: FC<{
  promotionCode?: string;
  onApply: (couponCode: string) => Promise<void>;
  onRemove: () => void;
}> = (props) => {
  const viewModel = usePromotionCodeViewModel(props);

  switch (viewModel.kind) {
    case PromotionCodeViewModelKind.Initial:
      return <InitialView {...viewModel} />;
    case PromotionCodeViewModelKind.Entering:
      return <EnteringView {...viewModel} />;
    case PromotionCodeViewModelKind.Applied:
      return <AppliedView {...viewModel} />;
    default:
      return null;
  }
};
