import { Container } from 'inversify';

import { FEATURE_FLAG_TYPES } from '@/ioc/types';

import { IFeatureFlagManager } from '../FeatureFlagManager';

type BindingFunction = <T>(container: Container) => T | void;

export const bindModuleWithFeatureFlag = (
  bindingFunction: BindingFunction,
  options: {
    featureName: string;
  },
) => {
  return (container: Container): void => {
    const featureFlagManager = container.get<IFeatureFlagManager>(
      FEATURE_FLAG_TYPES.FeatureFlagManager,
    );

    if (!featureFlagManager.isFeatureEnabled(options.featureName)) return;

    bindingFunction(container);
  };
};
