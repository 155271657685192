import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { useAuthUseCase } from '@/features/common/auth';

import { Modal } from '@/components';

import changedEmailIcon from '@/assets/icons/settings/changedEmailIcon.webp';

import styles from './styles.module.scss';

interface ChangedEmailModalProps {
  isOpen: boolean;
}

export const ChangedEmailModal: FC<ChangedEmailModalProps> = ({ isOpen }) => {
  const authUseCase = useAuthUseCase();

  const { t } = useTranslation('settings', {
    keyPrefix: 'account.emailChangedDialog',
  });

  const onClose = (): void => {
    authUseCase.signOut();
  };

  return (
    <Modal open={isOpen} onClose={onClose} containerClass={styles.modalContainer}>
      <div className={styles.contentContainer}>
        <img
          src={changedEmailIcon}
          alt="Email has been sent"
          className={styles.emailChandedImage}
        />
        <Typography variant="subtitle1" color="info" className={styles.description}>
          {t('description')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {t('action')}
        </Button>
      </div>
    </Modal>
  );
};
