import { injectable } from 'inversify';

import { CollectionName, DbCollectionCreator } from '@/features/system/db';

import { ICompanyDC } from '../dataContracts';

import { companyMigrationStrategies } from './migration';
import { companySchema } from './schema';

@injectable()
export class CompanyCollectionCreator extends DbCollectionCreator<ICompanyDC> {
  constructor() {
    super({
      collectionName: CollectionName.Company,
      schema: companySchema,
      migrationStrategies: companyMigrationStrategies,
    });
  }
}
