import { MouseEvent, useContext, useState } from 'react';

import { useContactUseCase } from '@/features/common/contact';

import { ContactsContext } from '../../context';

import { Value } from './types';

export const useTagApplyActionViewModel = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorElement);
  const { selectedContacts, contactEntitiesMapById } = useContext(ContactsContext);
  const contactUseCase = useContactUseCase();
  const isDisabled = !selectedContacts.length;

  const close = (): void => {
    setAnchorElement(null);
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(e.currentTarget);
  };

  const handleApply = async (items: Value[]): Promise<void> => {
    const tagsMapToApply = {};
    for (const item of items) {
      if (!tagsMapToApply[item.contactId]) {
        tagsMapToApply[item.contactId] = [];
      }
      if (item.isChecked) tagsMapToApply[item.contactId].push(item.tagId);
    }

    const patch = selectedContacts
      .map((contactId) => {
        const contact = contactEntitiesMapById[contactId];

        if (contact) {
          return {
            ...contact,
            tags: tagsMapToApply[contactId] || contact?.tags,
          };
        }

        return null;
      })
      .filter((contact) => !!contact);
    await contactUseCase.upsertPatch(patch);
    close();
  };

  const getIntederminatedValues = (): Value[] => {
    const result: Value[] = [];
    for (const id of selectedContacts) {
      const tags = contactEntitiesMapById[id]?.tags;
      if (tags) {
        const generatedItems = tags.map((tagId) => ({
          contactId: id,
          tagId,
          isChecked: true,
          isIndeterminated: true,
        }));
        result.push(...generatedItems);
      }
    }
    return result;
  };

  return {
    anchorElement,
    isOpen,
    handleButtonClick,
    handleApply,
    intederminatedValues: getIntederminatedValues(),
    close,
    isDisabled,
    selectedContacts,
  };
};
