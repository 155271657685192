import { useContext } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';

import { EnrichmentFileHeaderField } from '@/features/enrichment/domain/types';
import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';

import { FormContext } from '../../../../FormContext';

type UseFileFieldCellViewModelResult = {
  value: string;
  options: string[];
  register: UseFormRegisterReturn;
  isValid: boolean;
  error: FieldError | undefined;
  onChange: (e: SelectChangeEvent) => void;
};

export const useFileFieldCellViewModel = (
  fieldName: EnrichmentFileHeaderField,
): UseFileFieldCellViewModelResult => {
  const { fieldsMapperForm: form } = useContext(FormContext);
  const { fileUploadResponse } = useContext(EnrichmentUploadingContext);

  const value = form.watch(`headers_map.${fieldName}`);
  const register = form.register(`headers_map.${fieldName}`);

  const options = (fileUploadResponse?.headers || []).map((item) => item.csv_name);
  const error = form.formState.errors.headers_map?.[fieldName];
  const isValid = !error && value !== '';

  const onChange = (e: SelectChangeEvent) => {
    register.onChange(e);
    form.trigger('headers_map');
  };

  return {
    value,
    options,
    register,
    isValid,
    error,
    onChange,
  };
};
