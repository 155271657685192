import { Container } from 'inversify';

import { HELP_CENTER_TYPES } from '@/ioc/types';

import {
  HelpCenterUseCase,
  IHelpCenterUseCase,
} from '@/features/system/helpCenter/domain';

import { HelpCenterRepository, IHelpCenterRepository } from '../data';

export const bindHelpCenterModule = (container: Container): void => {
  container
    .bind<IHelpCenterRepository>(HELP_CENTER_TYPES.HelpCenterRepository)
    .to(HelpCenterRepository)
    .inSingletonScope();
  container
    .bind<IHelpCenterUseCase>(HELP_CENTER_TYPES.HelpCenterUseCase)
    .to(HelpCenterUseCase);
};
