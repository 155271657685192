import { Container } from 'inversify';

import { EXTENSION_AUTH_TYPES } from '@/ioc/types';

import { ExtensionAuthUseCase, IExtensionAuthUseCase } from '../domain';

export const bindExtensionAuthModule = (container: Container): void => {
  container
    .bind<IExtensionAuthUseCase>(EXTENSION_AUTH_TYPES.ExtensionAuthUseCase)
    .to(ExtensionAuthUseCase);
};
