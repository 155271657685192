import { inject, injectable } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { firstValueFrom, map } from 'rxjs';

import { REFERRAL_TYPES, SYNC_TYPES } from '@/ioc/types';

import type { IReferralApiService } from '@/features/referral/data';
import { CollectionName, InvitationDocument } from '@/features/system/db';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { ReplicationService } from '@/features/system/replication/data/ReplicationService';
import type { IBaseSyncRepository } from '@/features/system/sync';

@injectable()
export class ReferralReplicationService extends ReplicationService<InvitationDocument> {
  @inject(REFERRAL_TYPES.ReferralApiService)
  private api: IReferralApiService;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Invitation,
      pullStreamFactory: () =>
        this.syncRepository
          .getInvitationEvents()
          .pipe(map(mapBaseSyncEntity<InvitationDocument>)),
      pushHandlers: {
        delete: (docs) => this.syncDelete(docs),
      },
    });
  }

  private syncDelete(
    docsToDelete: RxReplicationWriteToMasterRow<InvitationDocument>[],
  ): Promise<boolean> {
    return firstValueFrom(
      this.api.removeReferral(docsToDelete.map((doc) => doc.newDocumentState.uuid)),
    );
  }
}
