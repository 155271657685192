import type { IProductEntity } from '@/features/common/billing';
import { DomainError } from '@/features/common/error';

export class ProductDowngradeError extends DomainError {
  public readonly product: IProductEntity;

  constructor(params: { product: IProductEntity }) {
    super('You can not downgrade to this product');
    this.product = params.product;
  }
}
