import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid';

const TagTableCreatorCell: React.FC<GridRenderCellParams> = ({ row: { createdBy } }) => {
  const { t } = useTranslation('tag');

  return (
    <div className="MuiDataGrid-cellContent">
      {createdBy ? createdBy : t('table.defaultTagTitle')}
    </div>
  );
};

export default TagTableCreatorCell;
