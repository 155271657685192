import { FC } from 'react';
import { Box } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { HasPermission, Permission } from '@/features/common/permissions';

import { GroupsIcon } from '@/components/Icons';

import { useCellProspectTaskStateByListId } from '../../hooks';

import styles from './styles.module.scss';

const UpdatingIndicator: FC<{ id: string }> = ({ id }) => {
  const { isUpdating } = useCellProspectTaskStateByListId(id);

  if (!isUpdating) return null;

  return (
    <Box className={styles.nameCellProgressWrapper}>
      <CircularProgress thickness={2} />
    </Box>
  );
};

export const NameCell: FC<GridRenderCellParams> = ({ row }) => {
  return (
    <Box className={styles.nameCell}>
      <Box className={styles.nameCellIcon}>
        <HasPermission permission={Permission.CanViewProspectTaskProgress}>
          <UpdatingIndicator id={row.id} />
        </HasPermission>
        <GroupsIcon />
      </Box>
      <Typography className={styles.nameCellText}>{row.name}</Typography>
    </Box>
  );
};
