import type { IBillingDetailsEntity } from '../../domain/entities/BillingDetailsEntity';
import type { IBillingDetailsDC } from '../dataContracts/BillingDetailsDC';

export function mapBillingDetailsDcToEntity(
  dc: IBillingDetailsDC,
): IBillingDetailsEntity {
  return {
    name: dc.name ?? '',
    vatId: dc.personal_id_number,
    country: dc.country ?? '',
    phone: dc.phone,
    city: dc.city ?? '',
    state: dc.state,
    postalCode: dc.postal_code ?? '',
    address: dc.address_line1 ?? '',
    email: dc.business_email,
    company: dc.business_name,
  };
}

export function mapBillingDetailsEntityToDc(
  entity: IBillingDetailsEntity,
): IBillingDetailsDC {
  return {
    name: entity.name,
    personal_id_number: entity.vatId,
    country: entity.country,
    phone: entity.phone,
    city: entity.city,
    state: entity.state,
    postal_code: entity.postalCode,
    address_line1: entity.address,
    business_email: entity.email,
    business_name: entity.company,
  };
}
