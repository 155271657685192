import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { FormContext } from '../../FormContext';
import { ViewLayout } from '../ViewLayout';

import { useConfigViewFieldsMapperTableColumns } from './hooks';
import { useConfigViewFieldsMapperViewModel } from './useConfigViewFieldsMapperViewModel';

import styles from './styles.module.scss';

const ConfigViewFieldsMapperInfo: FC = () => {
  const { t } = useTranslation('enrichment');
  const { fieldsMapperForm: form } = useContext(FormContext);
  const requiredCombinationError =
    form.formState.errors.headers_map?.type === 'requiredCombination';

  return (
    <Box className={styles.ContainerInfo}>
      <Box className={styles.ContainerInfoTitle} component="span">
        {t('uploadingFieldsMapperModal.subTitle')}
      </Box>
      {requiredCombinationError && (
        <Box className={styles.ContainerInfoRootError} component="span">
          {t(`uploadingFieldsMapperModal.errors.requiredCombination`)}
        </Box>
      )}
    </Box>
  );
};

const ConfigViewFieldsMapperNextButton: FC<{ onClick(): void }> = ({ onClick }) => {
  const { t } = useTranslation('enrichment');
  const { fieldsMapperForm: form } = useContext(FormContext);
  const hasRequiredCombinationErrors =
    form.formState.errors.headers_map?.type === 'requiredCombination';

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={hasRequiredCombinationErrors}
    >
      {t('uploadingFieldsMapperModal.actions.next')}
    </Button>
  );
};

export const ConfigViewFieldsMapper: FC = memo(() => {
  const { t } = useTranslation('enrichment');
  const { rows, toNext, close } = useConfigViewFieldsMapperViewModel();
  const columns = useConfigViewFieldsMapperTableColumns();

  return (
    <ViewLayout>
      <ViewLayout.Header title={t('uploadingFieldsMapperModal.title')} showFileMetadata />
      <ViewLayout.Container>
        <ConfigViewFieldsMapperInfo />
        <DataGridPro
          columnHeaderHeight={40}
          variant="basic"
          editMode="cell"
          className={styles.Table}
          disableChildrenFiltering
          classes={{
            columnHeaderTitle: styles.ColumnHeaderTitle,
            columnHeader: styles.ColumnHeader,
            row: styles.Row,
            cell: styles.Cell,
            root: styles.TableRoot,
          }}
          rowHeight={80}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableColumnFilter
          hideFooterPagination
          disableColumnReorder
          rows={rows}
          columns={columns}
        />
      </ViewLayout.Container>
      <ViewLayout.Actions
        leftButton={
          <Button variant="outlined" color="info" onClick={close}>
            {t('uploadingFieldsMapperModal.actions.cancel')}
          </Button>
        }
        rightButton={<ConfigViewFieldsMapperNextButton onClick={toNext} />}
        text={t('uploadingFieldsMapperModal.actions.stepsInfo')}
      />
    </ViewLayout>
  );
});
