import { EmailFieldMessagesProps } from './EmailFieldMessages';

type UseEmailFieldMessagesViewModel = (props: EmailFieldMessagesProps) => {
  successfulInvitedCount: number;
  emailsAlreadyInSystem: string[];
  publicEmails: string[];
  limitsExceeded: boolean;
};

export const useEmailFieldMessagesViewModel: UseEmailFieldMessagesViewModel = ({
  response,
}: EmailFieldMessagesProps) => {
  const successfulInvitedCount = response.filter((r) => !r.error).length;

  const emailsAlreadyInSystem = response
    .filter((r) => r.error === 'email_exists')
    .map(({ email }) => email);

  const publicEmails = response
    .filter((r) => r.error === 'public_email')
    .map(({ email }) => email);

  const limitsExceeded = response.some((r) => r.error === 'rate_limited');

  return {
    successfulInvitedCount,
    emailsAlreadyInSystem,
    publicEmails,
    limitsExceeded,
  };
};
