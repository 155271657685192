import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { PickerValue } from '../../types';

dayjs.extend(isBetween);

type PickerViewModel = (props: {
  minDateTimestamp?: number;
  value: string;
  onChange: (value: string) => void;
}) => {
  value: PickerValue;
  open: boolean;
  minDate: Dayjs;
  handleChange: (value: PickerValue) => void;
  handleInputClick: () => void;
  handelClose: () => void;
};

const FORMAT = 'MM/DD/YYYY';

const formatToValue = ([startDate, endDate]: PickerValue): string => {
  if (!startDate || !endDate) return '';
  return `${startDate.format(FORMAT)}-${endDate.format(FORMAT)}`;
};

export const usePickerViewModel: PickerViewModel = ({
  minDateTimestamp,
  value: externalValue,
  onChange,
}) => {
  const value = useMemo<PickerValue>(() => {
    if (!externalValue) return [null, null];
    const [startDateStr, endDateStr] = externalValue.split('-');
    const startDate = startDateStr ? dayjs(startDateStr) : null;
    const endDate = endDateStr ? dayjs(endDateStr) : null;
    return [startDate, endDate];
  }, [externalValue]);

  const [open, setOpen] = useState(() => {
    if (value[0] && value[1]) {
      return false;
    }
    return true;
  });

  const minDate = dayjs.unix(minDateTimestamp ?? 0);

  const handleChange = ([startDate, endDate]: PickerValue): void => {
    const maxDay = dayjs();

    if (!startDate || !endDate) return;

    // startDate can not be after endDate
    if (startDate.isAfter(endDate)) return;

    // startDate can not be before minDate
    if (startDate.isBefore(minDate)) return;

    // endDate can not be after maxDay
    if (endDate.isAfter(maxDay)) return;

    onChange(formatToValue([startDate, endDate]));
  };

  const handleInputClick = (): void => {
    setOpen(true);
  };

  const handelClose = (): void => {
    setOpen(false);
  };

  return {
    value,
    open,
    minDate,
    handleChange,
    handleInputClick,
    handelClose,
  };
};
