import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="8 6 21 24">
    <path
      d="M3.316 21.818a1.1 1.1 0 0 1-1.105-1.091V7.636a1.1 1.1 0 0 0-1.106-1.091A1.1 1.1 0 0 0 0 7.636v13.091A3.294 3.294 0 0 0 3.316 24h11.052a1.091 1.091 0 1 0 0-2.182zm1.105-5.455a3.294 3.294 0 0 0 3.316 3.273h9.947A3.294 3.294 0 0 0 21 16.364V3.273A3.294 3.294 0 0 0 17.684 0H7.737a3.294 3.294 0 0 0-3.316 3.273zm3.316 1.091a1.1 1.1 0 0 1-1.105-1.091V3.273a1.1 1.1 0 0 1 1.105-1.091h9.947a1.1 1.1 0 0 1 1.105 1.091v13.091a1.1 1.1 0 0 1-1.105 1.091z"
      transform="translate(8 6)"
    />
  </SvgIcon>
);
