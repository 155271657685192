import { useState } from 'react';

import { useObservableResult } from '@/utils/rx';

import { SyncStatus } from '../../domain';

import { useBaseSyncUseCase } from './useBaseSyncUseCase';

export const useBaseSyncStatus = (): SyncStatus => {
  const baseSyncUseCase = useBaseSyncUseCase();

  const [status$] = useState(() => baseSyncUseCase.getStatus());

  const { data: status } = useObservableResult(status$, {
    defaultData: SyncStatus.Initializing,
  });

  return status;
};
