import { useInjection } from '@/ioc/index';
import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import { ICustomerMessagingUseCase } from '../../domain/abstractions/ICustomerMessagingUseCase';

export const useCustomerMessagingUseCase = (): ICustomerMessagingUseCase => {
  return useInjection<ICustomerMessagingUseCase>(
    CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase,
  );
};
