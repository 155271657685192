import { useObservableResult } from '@/utils/rx';

import { useOnlineTrackerUseCase } from './useOnlineTrackerUseCase';

export const useIsOnline = (): boolean => {
  const onlineTrackerUseCase = useOnlineTrackerUseCase();

  const { data: isOnline } = useObservableResult(
    () => onlineTrackerUseCase.getIsOnline(),
    {
      deps: [],
      defaultData: window.navigator.onLine,
    },
  );

  return isOnline;
};
