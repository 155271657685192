import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { clsx } from 'clsx';

import { Checkbox, Container } from '@/components';

import { Pagination, PaginationProps } from './components';

import styles from './styles.module.scss';

type ContactsTableProps = Omit<DataGridProProps, 'variant'> & {
  pageSize?: number;
  loading?: boolean;
};

const ContactsTablePagination: FC<PaginationProps> = (props) => {
  return (
    <Box className={styles.pagination}>
      <Container>
        <Pagination {...props} />
      </Container>
    </Box>
  );
};

export const permanentScrollbar = (): string | undefined => {
  if ('platform' in navigator) {
    if (navigator.platform.toLowerCase().includes('mac')) {
      return 'pw-permanent-scrollbar-mac';
    }

    return 'pw-permanent-scrollbar';
  }
};

export const ContactsTable: FC<PropsWithChildren<ContactsTableProps>> & {
  Pagination: FC<PaginationProps>;
} = ({
  pageSize = 0,
  checkboxSelection = true,
  className,
  slots = {},
  slotProps = {},
  loading,
  rows,
  classes,
  children,
  ...props
}) => {
  return (
    <Box className={styles.wrapper}>
      <Container className={styles.container}>
        <DataGridPro
          columnHeaderHeight={60}
          variant="basic"
          editMode="cell"
          className={clsx(styles.tableRoot, permanentScrollbar(), className)}
          checkboxSelection={checkboxSelection}
          classes={{
            columnHeaders: styles.columnHeaders,
            row: styles.row,
            cell: styles.cell,
            ...(!rows.length && { root: styles.isEmpty }),
            ...classes,
          }}
          slotProps={{
            baseCheckbox: {
              disabled: loading,
            },
            ...slotProps,
          }}
          slots={{
            baseCheckbox: Checkbox,
            ...slots,
          }}
          rowHeight={60}
          rowSelection
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableColumnFilter
          rowCount={pageSize}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          rowsLoadingMode="server"
          hideFooterPagination
          disableColumnReorder
          rows={rows}
          {...props}
        />
      </Container>
      {children}
    </Box>
  );
};

ContactsTable.Pagination = ContactsTablePagination;
