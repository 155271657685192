import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { AuthState, useAuthState } from '@/features/common/auth';

import {
  ObservableResultCache,
  ObservableResultCacheContext,
} from './ObservableResultCacheContext';

export const ObservableResultCacheProvider: FC<PropsWithChildren> = ({ children }) => {
  const authState = useAuthState();
  const cache = useState<ObservableResultCache>(() => new ObservableResultCache())[0];

  useEffect(() => {
    if (authState !== AuthState.CanAccessSite) {
      cache.clear();
    }
  }, [authState]);

  return (
    <ObservableResultCacheContext.Provider value={cache}>
      {children}
    </ObservableResultCacheContext.Provider>
  );
};
