import { Dispatch, SetStateAction, useState } from 'react';

export const useCreateNewListPopover = (): {
  anchorEl: Element | undefined;
  setAnchorEl: Dispatch<SetStateAction<Element | undefined>>;
  handleClose: () => void;
} => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const handleClose = (): void => {
    setAnchorEl(undefined);
  };

  return {
    anchorEl,
    setAnchorEl,
    handleClose,
  };
};
