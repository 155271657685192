import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessengerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6.25 6 24 24">
    <path
      d="M20.753 3.488a11.961 11.961 0 0 0-20.4 8.4 11.828 11.828 0 0 0 1.6 5.946L.25 24l6.335-1.654a11.982 11.982 0 0 0 5.71 1.448h.005A11.938 11.938 0 0 0 24.25 11.9a11.791 11.791 0 0 0-3.497-8.412zM12.3 21.785a9.956 9.956 0 0 1-5.055-1.378l-.363-.214-3.759.981 1-3.648-.236-.374A9.881 9.881 0 0 1 12.3 2.009a9.888 9.888 0 1 1 0 19.777zm5.448-7.4c-.3-.149-1.767-.867-2.04-.967s-.473-.149-.672.149-.771.967-.946 1.165-.348.223-.647.074a8.164 8.164 0 0 1-2.4-1.475 8.97 8.97 0 0 1-1.661-2.061c-.174-.3 0-.443.131-.606a8.407 8.407 0 0 0 .746-1.016.544.544 0 0 0-.025-.521c-.074-.149-.672-1.611-.92-2.206-.243-.579-.489-.5-.672-.51s-.373-.01-.572-.01a1.1 1.1 0 0 0-.8.372 3.326 3.326 0 0 0-1.042 2.478 5.766 5.766 0 0 0 1.219 3.074 13.284 13.284 0 0 0 5.1 4.487 17.144 17.144 0 0 0 1.7.626 4.112 4.112 0 0 0 1.881.118 3.076 3.076 0 0 0 2.015-1.413 2.472 2.472 0 0 0 .174-1.413c-.071-.125-.27-.199-.569-.348zm0 0"
      transform="translate(6 6)"
    />
  </SvgIcon>
);
