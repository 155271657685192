export function downloadFileByUrl(fileUrl: string, name: string): void {
  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = fileUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}
