import { FC, PropsWithChildren, useEffect } from 'react';

import { useAppLogger } from '@/features/system/logger';
import { SyncStatus } from '@/features/system/sync/domain';

import { SplashScreen } from '@/components';

import { useBaseSyncStatus, useBaseSyncUseCase } from '../../hooks';
import { BaseSyncError } from '../BaseSyncError';
import { RealTimeUpdatesIssueTracker } from '../RealTimeUpdatesIssue';

export const BaseSync: FC<PropsWithChildren> = ({ children }) => {
  const baseSyncUseCase = useBaseSyncUseCase();
  const appLogger = useAppLogger();
  const syncStatus = useBaseSyncStatus();

  useEffect(() => {
    const subscription = baseSyncUseCase.start().subscribe({
      error: (error) => {
        appLogger.error(error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (syncStatus === SyncStatus.Initializing) {
    return <SplashScreen />;
  }

  if (syncStatus === SyncStatus.Error) {
    return <BaseSyncError />;
  }

  return <RealTimeUpdatesIssueTracker>{children}</RealTimeUpdatesIssueTracker>;
};
