import { EffectCallback, useEffect, useRef } from 'react';

export function useOnMount(callback: EffectCallback): void {
  // to prevent double execution of the callback in strict mode
  const callbackRef = useRef(callback);
  const mountedRef = useRef(false);

  callbackRef.current = callback;

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return callbackRef.current();
    }
  }, []);
}
