import { Container } from 'inversify';

import { DB_TYPES } from '@/ioc/types';

import { DbCollection, IDbCollection } from '../data/DbCollection';
import { DbManager, IDbManager } from '../data/DbManagerNew';
import { DbUseCase, IDbUseCase } from '../domain';
import { DbGarbageCollector, IDbGarbageCollector } from '../jobs';

export const bindDatabaseManagerModule = (container: Container): void => {
  container.bind<IDbManager>(DB_TYPES.DbManager).to(DbManager).inSingletonScope();
  container.bind<IDbCollection<any, any>>(DB_TYPES.DbCollection).to(DbCollection);
  container
    .bind<IDbGarbageCollector>(DB_TYPES.DbGarbageCollector)
    .to(DbGarbageCollector)
    .inSingletonScope();
  container.bind<IDbUseCase>(DB_TYPES.DbUseCase).to(DbUseCase);
};
