import { useContext } from 'react';

import { EnrichmentJobStatus } from '@/features/enrichment/domain/types';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useAsyncExecutor } from '@/hooks';

import { EnrichmentContext } from '../../../../EnrichmentContext';

type UseDownloadCellViewModelResult = {
  isDisabled: boolean;
  handleClick: () => Promise<void>;
};

export const useDownloadCellViewModel = (
  uuid: string,
  status: EnrichmentJobStatus,
): UseDownloadCellViewModelResult => {
  const { trackEvent } = useAnalytics();
  const { downloadFile } = useContext(EnrichmentContext);

  const { isLoading, asyncExecute } = useAsyncExecutor(downloadFile);

  const handleClick = async (): Promise<void> => {
    await asyncExecute(uuid);
    trackEvent(ANALYTICS_EVENTS.ENRICHMENT_DOWNLOAD_CSV);
  };

  return {
    isDisabled:
      isLoading ||
      ![EnrichmentJobStatus.Completed, EnrichmentJobStatus.CompletedPartial].includes(
        status,
      ),
    handleClick,
  };
};
