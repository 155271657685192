import { Container } from 'inversify';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { EnrichmentReportUseCase } from '@/features/enrichment';
import { bindModuleWithFeatureFlag } from '@/features/system/featureFlag';
import { IReplicationService } from '@/features/system/replication';

import {
  EnrichmentCollectionCreator,
  EnrichmentDao,
  EnrichmentReplicationService,
  IEnrichmentDao,
} from '../data/db';
import { EnrichmentMigrationService } from '../data/db/migration';
import { EnrichmentRepository } from '../data/EnrichmentRepository';
import { EnrichmentApiService, IEnrichmentApiService } from '../data/network';
import {
  IEnrichmentFileUploadUseCase,
  IEnrichmentReportUseCase,
  IEnrichmentRepository,
  IEnrichmentUseCase,
} from '../domain/abstraction';
import { EnrichmentFileUploadUseCase } from '../domain/EnrichmentFileUploadUseCase';
import { EnrichmentUseCase } from '../domain/EnrichmentUseCase';

export const bindEnrichmentModule = bindModuleWithFeatureFlag(
  (container: Container): void => {
    container
      .bind<IReplicationService>(ENRICHMENT_TYPES.EnrichmentReplicationService)
      .to(EnrichmentReplicationService)
      .inSingletonScope();

    container
      .bind<IReplicationService>('ReplicationService')
      .toService(ENRICHMENT_TYPES.EnrichmentReplicationService);

    container
      .bind<EnrichmentCollectionCreator>('DbCollectionCreator')
      .to(EnrichmentCollectionCreator);

    container
      .bind<IEnrichmentDao>(ENRICHMENT_TYPES.EnrichmentDao)
      .to(EnrichmentDao)
      .inSingletonScope();

    container
      .bind<EnrichmentMigrationService>(ENRICHMENT_TYPES.EnrichmentMigrationService)
      .to(EnrichmentMigrationService)
      .inSingletonScope();

    container
      .bind<IEnrichmentRepository>(ENRICHMENT_TYPES.EnrichmentRepository)
      .to(EnrichmentRepository);

    container
      .bind<IEnrichmentApiService>(ENRICHMENT_TYPES.EnrichmentApiService)
      .to(EnrichmentApiService)
      .inSingletonScope();

    container
      .bind<IEnrichmentFileUploadUseCase>(ENRICHMENT_TYPES.EnrichmentFileUploadUseCase)
      .to(EnrichmentFileUploadUseCase);

    container
      .bind<IEnrichmentUseCase>(ENRICHMENT_TYPES.EnrichmentUseCase)
      .to(EnrichmentUseCase);

    container
      .bind<IEnrichmentReportUseCase>(ENRICHMENT_TYPES.EnrichmentReportUseCase)
      .to(EnrichmentReportUseCase);
  },
  { featureName: 'FEATURE_ENRICHMENT' },
);
