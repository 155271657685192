import { Container } from 'inversify';

import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import {
  CustomerMessagingRepository,
  CustomerMessagingUseCase,
  ICustomerMessagingRepository,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

export const bindCustomerMessagingModule = (container: Container): void => {
  container
    .bind<ICustomerMessagingRepository>(
      CUSTOMER_MESSAGING_TYPES.CustomerMessagingRepository,
    )
    .to(CustomerMessagingRepository)
    .inSingletonScope();
  container
    .bind<ICustomerMessagingUseCase>(CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase)
    .to(CustomerMessagingUseCase);
};
