import { FC, MouseEvent } from 'react';
import clsx from 'clsx';

import { TagBadge } from '@/components';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  label: string;
  color: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

export const SingleAnchor: FC<Props> = ({ color, label, onClick, disabled }) => {
  return (
    <TagBadge
      onClick={(e): void => {
        if (disabled) return;
        onClick(e);
      }}
      bgColor={color}
      className={clsx(styles.badge, {
        [styles.disabled]: disabled,
      })}
    >
      {label}
    </TagBadge>
  );
};
