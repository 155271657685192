import { FC } from 'react';
import { Box } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import clsx from 'clsx';

import { Preview, PreviewVariant } from '@/components';

import styles from './styles.module.scss';

type HeaderCellProps = GridColumnHeaderParams;

const HeaderCellPreview: FC = () => {
  return (
    <Box className={clsx(styles.cell, styles.preview)}>
      <Preview variant={PreviewVariant.DARK} />
    </Box>
  );
};

export const HeaderCell: FC<HeaderCellProps> & {
  Preview: FC;
} = ({ colDef }) => {
  return <Box className={styles.cell}>{colDef.headerName}</Box>;
};

HeaderCell.Preview = HeaderCellPreview;
