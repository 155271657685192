import { useContextualOnboardingUseCase } from './useContextualOnboardingUseCase';

export const useContextualOnboarding = (): {
  reload: () => void;
  track: (event: string, meta?: Record<string, string>) => void;
} => {
  const onboardingUseCase = useContextualOnboardingUseCase();

  const reload = (): void => {
    onboardingUseCase.reload();
  };

  const track = (event: string, meta?: Record<string, string>): void => {
    onboardingUseCase.track(event, meta);
  };

  return {
    reload,
    track,
  };
};
