import * as React from "react";
const SvgMicrosoft = (props) => /* @__PURE__ */ React.createElement("svg", { "data-name": "Group 352", xmlns: "http://www.w3.org/2000/svg", width: 28, height: 28, viewBox: "0 0 28 28", ...props }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17479", width: 28, height: 28, rx: 4, style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10607", d: "M272 8.853h8.853V.59a.59.59 0 0 0-.59-.59H272z", transform: "translate(-257.434 4.573)", style: {
  fill: "#8cbd00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10608", d: "M8.853 8.853V0H.59A.59.59 0 0 0 0 .59v8.263z", style: {
  fill: "#f35325"
}, transform: "translate(4.532 4.573)" }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10609", d: "M8.853 272H0v8.263a.59.59 0 0 0 .59.59h8.263z", transform: "translate(4.532 -257.393)", style: {
  fill: "#00aee9"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10610", d: "M272 272v8.853h8.263a.59.59 0 0 0 .59-.59V272z", transform: "translate(-257.434 -257.393)", style: {
  fill: "#f7bd00"
} }));
export default SvgMicrosoft;
