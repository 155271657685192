import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { UnauthenticatedLayout } from '@/router/layouts';
import { EXTERNAL_ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

const toMinutesAndSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes}:${String(secondsLeft).padStart(2, '0')}`;
};

type Props = {
  lockedUntil: Nullable<number>;
  onComplete: () => void;
};

export const LockTimer: FC<Props> = ({ lockedUntil, onComplete }) => {
  const { t } = useTranslation('auth');
  const now = dayjs().unix();
  const [timeLeft, setTimeLeft] = useState<Nullable<number>>(() => {
    if (lockedUntil) {
      return lockedUntil - now;
    }

    return null;
  });

  useEffect(() => {
    if (!lockedUntil) return;

    setTimeLeft(lockedUntil - now);
  }, [lockedUntil]);

  useEffect(() => {
    if (timeLeft === null) return;

    if (typeof timeLeft === 'number' && timeLeft <= 0) {
      onComplete();
      setTimeLeft(null);
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prev) => {
        if (typeof prev === 'number') {
          return prev - 1;
        }

        return prev;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  if (typeof timeLeft !== 'number') return null;

  const i18nKey =
    timeLeft < 60
      ? 'verification.tooManyAttemptsSeconds'
      : 'verification.tooManyAttemptsMinutes';

  return (
    <UnauthenticatedLayout.ErrorAlert className={styles.error}>
      <Box className={styles.errorContent}>
        <Trans
          t={t}
          i18nKey={i18nKey}
          values={{
            time: toMinutesAndSeconds(timeLeft),
          }}
          components={{
            a: <a href={EXTERNAL_ROUTES.CONTACT_US} />,
            b: <b className={styles.highlightText} />,
          }}
        >
          You’ve tried this too many times. Try again in a few minutes.
          <b>{toMinutesAndSeconds(timeLeft)}</b>
        </Trans>
      </Box>
    </UnauthenticatedLayout.ErrorAlert>
  );
};
