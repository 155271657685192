import type { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: () => {
      return {
        minHeight: 'auto',
        padding: '0 12px',
        height: '34px',
        fontSize: '13px',
        lineHeight: 1.14,
        alignItems: 'center',
        display: 'flex',
      };
    },
    icon: () => {
      return {
        width: '10px',
        right: '10px',
      };
    },
  },
};
