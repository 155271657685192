import { type ObservableResult, useObservableResult } from '@/utils/rx';

import type { IIntegrationEntity } from '../../domain';

import { useIntegrationsUseCase } from './useIntegrationsUseCase';

export const useIntegrations = (): ObservableResult<
  IIntegrationEntity[],
  unknown,
  IIntegrationEntity[]
> => {
  const integrationsUseCases = useIntegrationsUseCase();

  return useObservableResult(() => integrationsUseCases.getIntegrations(), {
    defaultData: [],
  });
};
