import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

import { PasswordValidationSchema } from '@/utils/validation';

import styles from './styles.module.scss';

type PasswordValidationProps = {
  password: string;
};

export const PasswordValidationTips: FC<PasswordValidationProps> = ({
  password = '',
}) => {
  const { t } = useTranslation('validation');

  const errorsByType = useMemo(() => {
    try {
      PasswordValidationSchema.validateSync(password, {
        abortEarly: false,
      });
      return {
        containLowerCase: false,
        containNumber: false,
        containUpperCase: false,
        min: false,
      };
    } catch (e) {
      return e.inner.reduce((acc, error) => {
        acc[error.type] = true;
        return acc;
      }, {});
    }
  }, [password]);

  return (
    <Box className={styles.passwordTipsContainer}>
      <ul>
        <li>
          <span
            className={clsx({
              [styles.ruleVerified]: !errorsByType['containLowerCase'],
            })}
          >
            {t('passwordValidationTips.lowerCase')}
          </span>
        </li>
        <li>
          <span
            className={clsx({
              [styles.ruleVerified]: !errorsByType['containNumber'],
            })}
          >
            {t('passwordValidationTips.number')}
          </span>
        </li>
        <li>
          <span
            className={clsx({
              [styles.ruleVerified]: !errorsByType['containUpperCase'],
            })}
          >
            {t('passwordValidationTips.upperCase')}
          </span>
        </li>

        <li>
          <span
            className={clsx({
              [styles.ruleVerified]: !errorsByType['min'],
            })}
          >
            {t('passwordValidationTips.length')}
          </span>
        </li>
      </ul>
    </Box>
  );
};
