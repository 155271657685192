import { useChartLabels } from '@/features/dashboard/features/chart/ui/hooks/useChartLabels';
import { useChartTooltipTitles } from '@/features/dashboard/features/chart/ui/hooks/useChartTooltipTitles';
import { mapChartEntityToValues } from '@/features/dashboard/features/chart/ui/mappers';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { AggregationStrategy } from '../../domain';
import { AggregatedChartDataEntity } from '../../domain/entities/AggregatedChartDataEntity';

type Props = {
  data: AggregatedChartDataEntity[];
  selectedDate: DATE_SELECT_OPTION_VALUES | string;
  strategy: AggregationStrategy;
};

type Return = {
  values: { x: number; y: number }[];
  labels: string[];
  tooltipTitles: string[];
};

export const useChartDataMappers = ({ data, selectedDate, strategy }: Props): Return => {
  const labels = useChartLabels(data, selectedDate, strategy);
  const tooltipTitles = useChartTooltipTitles({ data });
  const values = mapChartEntityToValues(data);

  return {
    labels,
    tooltipTitles,
    values,
  };
};
