import { FC, useContext, useMemo } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';

import { Permission, usePermissions } from '@/features/common/permissions';

import { IEnrichmentEntity } from '../../../../domain/entities';
import { EnrichmentJobStatus } from '../../../../domain/types';
import { EnrichmentContext } from '../../EnrichmentContext';

import { NoResultsOverlay, NoRowsOverlay } from './components';
import { useEnrichmentTableColumns } from './useEnrichmentTableColumns';

type UseEnrichmentTableViewModelResult = {
  noRowsOverlay: FC | undefined;
  columns: GridColDef[];
  rows: any[];
  onSortModelChange(model: GridSortModel): void;
};

const genLoadingRows = (): (IEnrichmentEntity & { id: string })[] => {
  const now = Date.now();
  return Array.from({ length: 5 }).map((_, index) => ({
    id: String(index),
    uuid: String(index),
    name: '',
    status: EnrichmentJobStatus.InProgress,
    progress: 0,
    updatedAt: now,
    createdAt: now,
    enrichType: [],
    createdBy: {
      uuid: '',
      fullName: '',
    },
    statistic: {
      total: {
        count: 0,
        matched: 0,
        duplicate: 0,
        notFound: 0,
      },
      emails: {
        count: 0,
        verified: 0,
        notVerified: 0,
        notFound: 0,
      },
      phones: {
        count: 0,
        hqPhone: 0,
        direct_dial: 0,
      },
      fields: [],
    },
  }));
};

export const useEnrichmentTableViewModel = (): UseEnrichmentTableViewModelResult => {
  const { search, filterByUser, data, isLoading, isLoaded, setSort } =
    useContext(EnrichmentContext);
  const { hasPermissions } = usePermissions();
  const canAccessAllContacts = hasPermissions(Permission.CanAccessAllContact);
  const filtersInclude = canAccessAllContacts ? !!filterByUser || !!search : !!search;
  const columns = useEnrichmentTableColumns(isLoading);

  const noRowsOverlay: FC | undefined = useMemo(() => {
    if (isLoaded && data.length === 0 && !filtersInclude) {
      return NoRowsOverlay;
    }

    return NoResultsOverlay;
  }, [isLoaded, data.length, filtersInclude]);

  const rows = useMemo(() => {
    if (isLoading) {
      return genLoadingRows();
    }

    return data;
  }, [isLoading, data]);

  const onSortModelChange = (model: GridSortModel): void => {
    setSort(model[0] || null);
  };

  return {
    noRowsOverlay,
    columns,
    rows,
    onSortModelChange,
  };
};
