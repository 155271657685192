import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { EnrichmentJobStatus } from '@/features/enrichment/domain/types';

import { CancelIcon, CheckCircleIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type StatusCellInProcessingProps = {
  type: EnrichmentJobStatus.InProgress;
  value: number;
};

type StatusCellSuccessProps = {
  type: EnrichmentJobStatus.Completed;
};

type StatusCellCompletedPartialProps = {
  type: EnrichmentJobStatus.CompletedPartial;
};

type StatusCellFailedProps = {
  type: EnrichmentJobStatus.Failed;
};

type StatusCellProps =
  | StatusCellInProcessingProps
  | StatusCellSuccessProps
  | StatusCellFailedProps
  | StatusCellCompletedPartialProps;

const StatusCellInProcessing: FC<Pick<StatusCellInProcessingProps, 'value'>> = ({
  value,
}) => {
  const { t } = useTranslation('enrichment');
  return (
    <Box className={clsx(styles.Container, styles.ProcessingContainer)}>
      <Box className={styles.ProcessingInner} style={{ width: `${value}%` }}>
        <Box component="span" className={clsx(styles.Text, styles.ProcessingInnerText)}>
          {t('table.row.status.processing')}
        </Box>
      </Box>
      <Box component="span" className={clsx(styles.Text, styles.ProcessingText)}>
        {t('table.row.status.processing')}
      </Box>
    </Box>
  );
};

const StatusCellSuccess: FC = () => {
  const { t } = useTranslation('enrichment');
  return (
    <Box className={clsx(styles.Container, styles.SuccessContainer)}>
      <CheckCircleIcon className={styles.Icon} />
      <Box className={styles.Text} component="span">
        {t('table.row.status.success')}
      </Box>
    </Box>
  );
};

const StatusCellCompletedPartial: FC = () => {
  const { t } = useTranslation('enrichment');
  return (
    <Box className={clsx(styles.Container, styles.CompletedPartialContainer)}>
      <CheckCircleIcon className={styles.Icon} />
      <Box className={styles.Text} component="span">
        {t('table.row.status.completedPartial')}
      </Box>
    </Box>
  );
};

const StatusCellFailed: FC = () => {
  const { t } = useTranslation('enrichment');
  return (
    <Box className={clsx(styles.Container, styles.FailedContainer)}>
      <CancelIcon className={styles.Icon} />
      <Box className={styles.Text} component="span">
        {t('table.row.status.failed')}
      </Box>
    </Box>
  );
};

export const StatusCell: FC<StatusCellProps> = (props) => {
  switch (props.type) {
    case EnrichmentJobStatus.Completed:
      return <StatusCellSuccess />;
    case EnrichmentJobStatus.CompletedPartial:
      return <StatusCellCompletedPartial />;
    case EnrichmentJobStatus.Failed:
      return <StatusCellFailed />;
    default:
      return <StatusCellInProcessing value={props.value} />;
  }
};
