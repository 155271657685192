import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Title } from 'chart.js';

import { IEnrichmentStatisticEntity } from '../../../../domain/entities';
import { ChartCard } from '../ChartCard';

import { useChartCardCircleViewModel } from './hooks';

import styles from './styles.module.scss';

export type ChartCardCircleProps =
  | {
      type: 'records';
      data: IEnrichmentStatisticEntity['total'];
    }
  | {
      type: 'emails';
      data: IEnrichmentStatisticEntity['emails'];
    };

ChartJS.register(Title, ArcElement);

export const ChartCardCircle: React.FC<ChartCardCircleProps> = (props) => {
  const { t } = useTranslation('enrichment', { keyPrefix: 'report.chart' });
  const { title, legendItems, chartProps, isChartRendered } =
    useChartCardCircleViewModel(props);

  if (!props.data.count) return null;

  return (
    <ChartCard title={title}>
      <Box className={styles.chartWrapper}>
        <Doughnut height={212} {...chartProps} />
        <Box className={styles.chartInnerTitle}>
          <Typography variant={'h1'} color={'info'}>
            {props.data.count}
          </Typography>
          <Typography color={'info'}>{t('totalRecordsSubmitted')}</Typography>
        </Box>
        {isChartRendered && (
          <Box className={styles.legend}>
            {legendItems.map(({ title, color }) => (
              <Box key={title} className={styles.legendItem}>
                <Box style={{ backgroundColor: color }} />
                <Typography color={'info'} fontSize={13}>
                  {title}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </ChartCard>
  );
};
