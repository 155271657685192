import { inject, injectable } from 'inversify';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import type { IBillingApiService } from '../abstractions/IBillingApiService';
import type {
  IBillingDetailsDC,
  IBillingInvoiceDC,
  IPaymentMethodDC,
  IPaymentMethodUpdateSessionDC,
  IProductDC,
  IUpcomingInvoiceDC,
} from '../dataContracts';

@injectable()
export class BillingApiService implements IBillingApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private readonly httpClient: IHttpClient;

  private productsCache$: Observable<IProductDC[]> | null = null;

  private buildUrl(path: string): string {
    return `/api/v1/billing-service${path}`;
  }

  getBillingInfo(): Observable<IBillingDetailsDC> {
    return this.httpClient
      .get<IBillingDetailsDC>(this.buildUrl('/accounts/my'), {
        cachePolicy: 'network-first',
        revalidateAfter: 60 * 1000,
      })
      .pipe(map(({ data }) => data));
  }

  updateBillingInfo(billingInfo: IBillingDetailsDC): Observable<IBillingDetailsDC> {
    return this.httpClient
      .post<IBillingDetailsDC>(this.buildUrl('/stripe/billing-info'), billingInfo)
      .pipe(map(({ data }) => data));
  }

  getInvoices(): Observable<IBillingInvoiceDC[]> {
    return this.httpClient
      .get<IBillingInvoiceDC[]>(this.buildUrl('/stripe/invoices'), {
        cachePolicy: 'network-first',
        revalidateAfter: 10_000,
      })
      .pipe(map(({ data }) => data));
  }

  getPaymentMethod(): Observable<IPaymentMethodDC> {
    return this.httpClient
      .get<IPaymentMethodDC>(this.buildUrl('/stripe/payment-method'))
      .pipe(map(({ data }) => data));
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Observable<IPaymentMethodUpdateSessionDC> {
    return this.httpClient
      .post<IPaymentMethodUpdateSessionDC>(
        this.buildUrl('/stripe/payment-method-setup-session'),
        {
          success_url: params.successUrl,
          cancel_url: params.cancelUrl,
        },
      )
      .pipe(map(({ data }) => data));
  }

  getUpcomingInvoice(params: {
    plan: string;
    quantity?: number;
    promoCode?: string;
  }): Observable<IUpcomingInvoiceDC> {
    return this.httpClient
      .post<IUpcomingInvoiceDC>('/api/v1/billing/upcoming-invoice', {
        plan: params.plan,
        paid_members_count: params.quantity,
        promo_code_id: params.promoCode,
      })
      .pipe(
        map(({ data }) => {
          if (typeof data !== 'object') {
            throw new Error('Invalid response');
          }
          return data;
        }),
      );
  }

  getProducts(): Observable<IProductDC[]> {
    if (!this.productsCache$) {
      this.productsCache$ = this.httpClient
        .get<IProductDC[]>('/api/v1/billing/available-plans')
        .pipe(
          map(({ data }) => data),
          catchError((error) => {
            this.productsCache$ = null;
            return throwError(() => error);
          }),
          shareReplay(2),
        );
    }

    return this.productsCache$;
  }

  getProduct(id: string): Observable<IProductDC> {
    return this.httpClient
      .get<IProductDC>(`/api/v2/billing-service/products/${id}`, {
        cachePolicy: 'cache-first',
        revalidateAfter: 60 * 1000,
      })
      .pipe(map(({ data }) => data));
  }
}
