import { useContext } from 'react';

import { ContactsContext } from '../../context';

export const useFilterByTagActionViewModel = () => {
  const { filterByTags, setFilterByTags } = useContext(ContactsContext);
  const selectedCount = filterByTags.length;

  const onApply = (values: string[]): void => {
    setFilterByTags(values);
  };

  return {
    selectedCount,
    onApply,
    defaultValue: filterByTags,
  };
};
