export function formatCurrency(
  params: {
    amount: number | string;
    currency: string;
    currencyDisplay?: 'code' | 'symbol';
    locale?: Intl.LocalesArgument;
  } & Intl.NumberFormatOptions,
): string {
  let amount = 0;

  if (typeof params.amount === 'string') {
    amount = Number.parseFloat(params.amount);
  } else {
    amount = params.amount;
  }

  return amount.toLocaleString(params.locale, {
    style: 'currency',
    ...params,
    currencyDisplay: params.currencyDisplay ?? 'symbol',
  });
}
