import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const KeyIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 -960 960 960">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M278-351q54 0 91.5-37.5T407-480q0-54-37.5-91.5T278-609q-54 0-91.5 37.5T149-480q0 54 37.5 91.5T278-351Zm0 113q-100 0-171-71T36-480q0-100 71-171t171-71q84 0 140.5 45.5T499-560h334l79 78-113 141-115-86-89 90-64-65h-32q-19 72-79 118t-142 46Z"
    />
  </SvgIcon>
);
