import { Container } from 'inversify';

import { EXTENSION_TYPES } from '@/ioc/types';

import { ExtensionRepository, IExtensionRepository } from '../data';
import { ExtensionUseCase, IExtensionUseCase } from '../domain';

export const bindExtensionModule = (container: Container): void => {
  container
    .bind<IExtensionRepository>(EXTENSION_TYPES.ExtensionRepository)
    .to(ExtensionRepository)
    .inSingletonScope();

  container
    .bind<IExtensionUseCase>(EXTENSION_TYPES.ExtensionUseCase)
    .to(ExtensionUseCase);
};
