import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogOutIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14.001 13.888">
    <path
      d="M6.859 19.588a1.258 1.258 0 0 1-.92-.389 1.237 1.237 0 0 1-.389-.9V6.99a1.227 1.227 0 0 1 .389-.911 1.274 1.274 0 0 1 .92-.379H12.4v1.29H6.859V18.3H12.4v1.29zm9.221-3.453-.93-.93 1.916-1.916H10.5V12h6.526l-1.916-1.916.93-.93 3.51 3.51z"
      transform="translate(-5.55 -5.7)"
    />
  </SvgIcon>
);
