import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { filter, first } from 'rxjs';

import { useInjection } from '@/ioc/ioc.react';
import { DB_TYPES } from '@/ioc/types';

import { type IDbGarbageCollector } from '@/features/system/db/jobs';
import { useAppLogger } from '@/features/system/logger';

import { SplashScreen } from '@/components/SplashScreen';

import { DbStatus } from '../../../data';
import { type IDbUseCase } from '../../../domain';

export const DatabaseInitializer: FC<PropsWithChildren> = ({ children }) => {
  const databaseUseCase = useInjection<IDbUseCase>(DB_TYPES.DbUseCase);
  const dbGarbageCollector = useInjection<IDbGarbageCollector>(
    DB_TYPES.DbGarbageCollector,
  );

  const [dbStatus, setDbStatus] = useState<DbStatus>(DbStatus.Initializing);
  const appLogger = useAppLogger();

  useEffect(() => {
    const sub = databaseUseCase
      .getStatus()
      .pipe(
        filter((status) => status === DbStatus.Ready),
        first(), // run only once on start
      )
      .subscribe(() => {
        dbGarbageCollector.process();
      });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = databaseUseCase.start().subscribe({
      next: (status) => {
        setDbStatus(status);
      },
      error: (error) => {
        appLogger.error(error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (dbStatus === DbStatus.Ready) {
    return <>{children}</>;
  }

  return <SplashScreen />;
};
