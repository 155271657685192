import { Container } from 'inversify';

import { HUBSPOT_TYPES } from '@/ioc/types';

import {
  HubspotApiService,
  HubspotRepository,
  type IHubspotApiService,
  type IHubspotRepository,
} from '../data';
import { HubspotUseCase, type IHubspotUseCase } from '../domain';

export const bindHubspotModule = (container: Container): void => {
  container
    .bind<IHubspotApiService>(HUBSPOT_TYPES.HubspotApiService)
    .to(HubspotApiService);
  container
    .bind<IHubspotRepository>(HUBSPOT_TYPES.HubspotRepository)
    .to(HubspotRepository);
  container.bind<IHubspotUseCase>(HUBSPOT_TYPES.HubspotUseCase).to(HubspotUseCase);
};
