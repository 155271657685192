import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FacebookIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g transform="translate(-10 -43.741)">
        <rect
          width="20"
          height="20"
          rx="4"
          transform="translate(10 43.741)"
          fill="#1977f4"
        />
        <path
          d="M42.683 16V8.7h2.449l.368-2.843h-2.817V4.041c0-.823.228-1.385 1.41-1.385H45.6V.111A20.41 20.41 0 0 0 43.4 0a3.427 3.427 0 0 0-3.658 3.759v2.1H37.29V8.7h2.456V16z"
          fill="#fff"
          transform="translate(-19.123 47.741)"
        />
      </g>
    </SvgIcon>
  );
};
