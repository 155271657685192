import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ChartLegendItem } from '@/features/dashboard/features/chart/ui/components/ChartLegendItem';

import styles from '../styles.module.scss';

type ChartLegendProps = {
  creditsUsed: number;
  emailAddresses: number;
  phoneNumbers: number;
};

export const ChartLegend: FC<ChartLegendProps> = ({
  creditsUsed,
  emailAddresses,
  phoneNumbers,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <Box className={styles.chartLegend}>
      <ChartLegendItem title={t('analytics.creditsUsed')} value={creditsUsed} />
      <ChartLegendItem title={t('analytics.emailAddresses')} value={emailAddresses} />
      <ChartLegendItem title={t('analytics.phoneNumbers')} value={phoneNumbers} />
    </Box>
  );
};
