import { Dayjs } from 'dayjs';

import { Aggregator } from '../abstractions/Aggregation';
import { ChartDataEntity } from '../entities/ChartDataEntity';

export class DailyAggregation extends Aggregator {
  private readonly diff: number;

  constructor(dataOriginal: ChartDataEntity[], from: Dayjs, to: Dayjs) {
    super(dataOriginal, from, to);

    this.diff = to.diff(from, 'days');
    this.populate(this.diff);
    this.result = this.groupDataByTimestamps(this.dateObjs, this.data);
  }

  private populate(diff: number): void {
    for (let i = 0; i < diff; i++) {
      this.dateObjs.push(this.to.subtract(i + 1, 'days').unix());
    }
  }
}
