import {
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

import { CacheKey, ObservableResultCacheContext } from '../context';

class ObservableResultLoading<DefaultData = null> {
  readonly isLoading = true;
  readonly hasError = false;
  readonly isLoaded = false;
  readonly data: DefaultData;
  readonly error = null;
  readonly state = 'loading';

  constructor(data: DefaultData) {
    this.data = data;
  }
}

class ObservableResultLoaded<Data> {
  readonly isLoading = false;
  readonly hasError = false;
  readonly isLoaded = true;
  readonly data: Data;
  readonly error = null;
  readonly state = 'loaded';

  constructor(data: Data) {
    this.data = data;
  }
}

class ObservableResultError<E, DefaultData = null> {
  readonly isLoading = false;
  readonly hasError = true;
  readonly isLoaded = false;
  readonly data: DefaultData;
  readonly error: E;
  readonly state = 'error';

  constructor(error: E, data: DefaultData) {
    this.error = error;
    this.data = data;
  }
}

export type ObservableResult<Data, E = unknown, DefaultData extends null | Data = null> =
  | ObservableResultLoading<DefaultData>
  | ObservableResultLoaded<Data>
  | ObservableResultError<E, DefaultData>;

function useObservableResult<Data, E = unknown>(
  observable: Observable<Data> | (() => Observable<Data>),
  options?: {
    deps?: DependencyList;
    cacheKey?: CacheKey;
  },
): ObservableResult<Data, E, null>;

function useObservableResult<Data, E = unknown>(
  observable: Observable<Data> | (() => Observable<Data>),
  options: {
    defaultData: Data;
    deps?: DependencyList;
    cacheKey?: CacheKey;
  },
): ObservableResult<Data, E, Data>;

function useObservableResult<Data, E = unknown>(
  observable: Observable<Data> | (() => Observable<Data>),
  options?: {
    defaultData: Data;
    deps?: DependencyList;
    cacheKey?: CacheKey;
  },
): ObservableResult<Data, E, Data | null> | Data {
  const cache = useContext(ObservableResultCacheContext);
  const defaultData = options?.defaultData ?? null;
  const defaultDataRef = useRef<Data | null>(defaultData);
  defaultDataRef.current = defaultData;

  const [state$] = useState(
    () =>
      new BehaviorSubject<ObservableResult<Data, E, Data | null>>(
        options?.cacheKey && cache.has(options?.cacheKey)
          ? (cache.get(options.cacheKey) as ObservableResult<Data, E, Data | null>)
          : new ObservableResultLoading(defaultData),
      ),
  );

  useEffect(() => {
    return () => state$.complete();
  }, []);

  const getSnapshot = useCallback(() => {
    return state$.getValue();
  }, []);

  const observableDeps =
    typeof observable === 'function' ? (options?.deps ?? []) : [observable];

  const subscribe = useCallback((onStoreChange: () => void) => {
    const currentState = state$.getValue();
    const unwrappedObservable =
      typeof observable === 'function' ? observable() : observable;

    if (options?.cacheKey && cache.has(options?.cacheKey)) {
      state$.next(cache.get(options.cacheKey) as ObservableResult<Data, E, Data | null>);
    } else {
      state$.next(new ObservableResultLoading(currentState.data));
    }
    onStoreChange();

    const subscription = unwrappedObservable.subscribe({
      next: (data) => {
        const sdkResult = new ObservableResultLoaded(data);
        state$.next(sdkResult);
        options?.cacheKey && cache.set(options?.cacheKey, sdkResult);
        onStoreChange();
      },
      error: (error) => {
        state$.next(new ObservableResultError(error, defaultDataRef.current));
        onStoreChange();
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, observableDeps);

  return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
}

export { useObservableResult };
