import {
  Control,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';

import { FieldMappingFormValues } from '../types';

type FieldArrayWithId = UseFieldArrayReturn<FieldMappingFormValues, string, 'id'>;

type UseTableViewModel = (params: {
  objectType: string;
  providerOptionsCount: number;
}) => {
  addButtonDisabled: boolean;
  fields: FieldArrayWithId['fields'];
  handleRemoveField: (index: number) => void;
  handleAppendField: () => void;
  control: Control<FieldMappingFormValues>;
};

export const useTableViewModel: UseTableViewModel = ({
  objectType,
  providerOptionsCount,
}) => {
  const form = useFormContext<FieldMappingFormValues>();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: objectType,
  });

  return {
    addButtonDisabled: fields.length === providerOptionsCount,
    fields,
    handleAppendField: () =>
      append({
        powerleadField: 'predefined_value',
        providerField: null,
        predefinedValue: null,
        allowOverride: false,
      }),
    handleRemoveField: (index: number) => remove(index),
    control: form.control,
  };
};
