import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type MetaData = {
  title: string | { key: string; options: Record<string, string> };
  description: string;
};

export const useDocumentMeta = ({ title, description }: MetaData, deps?: any[]): void => {
  const { t } = useTranslation('meta');

  useEffect(() => {
    if (typeof title === 'string') {
      document.title = t(title) || 'Powerlead';
    } else {
      document.title = t(title.key, title.options) || 'Powerlead';
    }

    document.getElementsByTagName('meta')['description'].content = t(description) || '';

    return () => {
      document.title = 'Powerlead';
      document.getElementsByTagName('meta')['description'].content = '';
    };
  }, [deps]);
};
