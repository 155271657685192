import { Container } from 'inversify';

import { REPLICATION_TYPES } from '@/ioc/types';

import { IReplicationRepository } from '../data';
import ReplicationRepository from '../data/ReplicationRepository';
import { IReplicationUseCase, ReplicationUseCase } from '../domain';

export const bindReplicationModule = (container: Container): void => {
  container
    .bind<IReplicationRepository>(REPLICATION_TYPES.ReplicationRepository)
    .to(ReplicationRepository)
    .inSingletonScope();

  container
    .bind<IReplicationUseCase>(REPLICATION_TYPES.ReplicationUseCase)
    .to(ReplicationUseCase)
    .inSingletonScope();
};
