import { FirebaseError } from 'firebase/app';

import {
  AuthError,
  CredentialsToOldError,
  InvalidCredential,
  InvalidEmailError,
  UserAlreadyExistsError,
  UserNotFoundError,
  WrongPasswordError,
} from '../../domain';

export function mapFirebaseErrorToAuthError(error: FirebaseError): AuthError {
  switch (error.code) {
    case 'auth/user-not-found':
      return new UserNotFoundError();
    case 'auth/invalid-credential':
      return new InvalidCredential();
    case 'auth/invalid-email':
      return new InvalidEmailError();
    case 'auth/user-existed':
      return new UserAlreadyExistsError();
    case 'auth/wrong-password':
      return new WrongPasswordError();
    case 'auth/requires-recent-login':
      return new CredentialsToOldError();
    default:
      return new AuthError(error.message);
  }
}
