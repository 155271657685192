import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import NoSearchImage from './assets/noSearchResults.svg?react';

import styles from './styles.module.scss';

export const NoFilterResultsOverlay: FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.image}>
        <NoSearchImage />
      </Box>
      <Box className={styles.title}>
        <Typography variant="h1">
          <Trans
            ns="contacts"
            i18nKey="table.noFIlterResultsOverlay.title"
            components={{ br: <br /> }}
          />
        </Typography>
      </Box>
    </Box>
  );
};
