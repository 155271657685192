import * as React from "react";
const SvgVisa = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 71.259, height: 23.062, viewBox: "0 0 71.259 23.062", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17917" }, /* @__PURE__ */ React.createElement("path", { d: "M439.279 133.051h-5.773l3.611-22.325h5.772z", transform: "translate(-408.4 -110.322)", style: {
  fill: "#00579f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M582.494 106a14.225 14.225 0 0 0-5.177-.95c-5.7 0-9.714 3.04-9.74 7.386-.047 3.206 2.874 4.987 5.059 6.057 2.233 1.092 2.992 1.806 2.992 2.779-.023 1.5-1.8 2.185-3.466 2.185a11.491 11.491 0 0 1-5.416-1.187l-.76-.357-.808 5.011a17.568 17.568 0 0 0 6.438 1.188c6.057 0 10-2.993 10.047-7.624.023-2.541-1.52-4.489-4.845-6.08-2.019-1.021-3.255-1.71-3.255-2.755.024-.95 1.046-1.923 3.325-1.923a9.808 9.808 0 0 1 4.3.855l.522.237.785-4.821z", transform: "translate(-530.69 -105.047)", style: {
  fill: "#00579f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M799.453 125.143c.475-1.283 2.3-6.246 2.3-6.246-.024.048.474-1.306.759-2.137l.4 1.924s1.093 5.344 1.33 6.46zm7.125-14.416h-4.465a2.849 2.849 0 0 0-3.017 1.852l-8.574 20.472h6.057l1.212-3.349h7.409c.166.784.689 3.349.689 3.349h5.345l-4.657-22.324z", transform: "translate(-739.976 -110.322)", style: {
  fill: "#00579f"
} }), /* @__PURE__ */ React.createElement("path", { d: "m180.087 110.727-5.653 15.223-.618-3.087a17.087 17.087 0 0 0-7.981-9.358l5.178 19.523h6.1l9.073-22.3z", transform: "translate(-159.802 -110.322)", style: {
  fill: "#00579f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.548 110.727h-9.287l-.1.451c7.245 1.853 12.043 6.318 14.014 11.686L93.161 112.6a2.389 2.389 0 0 0-2.613-1.877z", transform: "translate(-81.166 -110.322)", style: {
  fill: "#faa61a"
} })));
export default SvgVisa;
