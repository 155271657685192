import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_BY_FILTERS_TYPES } from '@/ioc/types';

import { IContactByFiltersUseCase } from '../../domain';

export const useContactByFiltersUseCase = (): IContactByFiltersUseCase => {
  return useInjection<IContactByFiltersUseCase>(
    CONTACT_BY_FILTERS_TYPES.ContactByFiltersUseCase,
  );
};
