import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, Observable, of, switchMap, throwError } from 'rxjs';

import {
  APP_LOGGER_TYPES,
  BILLING_TYPES,
  PAYMENT_DETAILS_TYPES,
  WORKSPACE_TYPES,
} from '@/ioc/types';

import type { IBillingUseCase, IProductEntity } from '@/features/common/billing';
import { ISubscriptionUseCase, IWorkspaceRepository } from '@/features/common/workspace';
import { IAppLogger } from '@/features/system/logger';

import { IPaymentDetailsRepository } from '../data';

import type { IPaymentDetailsUseCase } from './abstractions/useCases/IPaymentDetailsUseCase';
import { FullCreditsQuotaNotFoundError } from './errors/FullCreditsQuotaNotFoundError';
import { ProductAlreadyOwnedError } from './errors/ProductAlreadyOwnedError';
import { ProductDowngradeError } from './errors/ProductDowngradeError';
import { ProductNotForBuyError } from './errors/ProductNotForBuyError';
import { ReduceActiveUsersCountError } from './errors/ReduceActiveUsersCountError';
import { WrongUpcomingReceiptTotalError } from './errors/WrongUpcomingReceiptTotalError';
import {
  IReceiptAdjustmentEntity,
  IReceiptEntity,
  IUpcomingReceiptEntity,
  PercentageReceiptAdjustment,
  ReceiptBuilder,
} from './entities';

@injectable()
export class PaymentDetailsUseCase implements IPaymentDetailsUseCase {
  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsRepository)
  private paymentDetailsRepository: IPaymentDetailsRepository;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  @inject(BILLING_TYPES.BillingUseCase)
  private billingUseCase: IBillingUseCase;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private appLogger: IAppLogger;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  assertCanBuyProduct = async (params: {
    plan: string;
    seats: number;
  }): Promise<void> => {
    const workspace = await firstValueFrom(
      this.workspaceRepository
        .getCurrentWorkspace()
        .pipe(filter((workspace) => !!workspace)),
    );

    const subscription = workspace.subscription;

    const [currentProduct, nextProduct] = await Promise.all([
      firstValueFrom(this.billingUseCase.getProduct(subscription.plan)),
      firstValueFrom(this.billingUseCase.getProduct(params.plan)),
    ]);

    if (
      !nextProduct.isPriceFixed &&
      subscription.isActive &&
      subscription.plan === params.plan &&
      subscription.paidMembersCount === params.seats
    ) {
      throw new ProductAlreadyOwnedError({ product: nextProduct });
    }

    if (
      nextProduct.isPriceFixed &&
      subscription.isActive &&
      subscription.plan === params.plan
    ) {
      throw new ProductAlreadyOwnedError({ product: nextProduct });
    }

    const isUpgrade = await firstValueFrom(
      this.subscriptionUseCase.isUpgrade(nextProduct, currentProduct),
    );

    if (!isUpgrade) {
      throw new ProductDowngradeError({ product: nextProduct });
    }

    // fixed price products members count check
    if (
      nextProduct.isPriceFixed &&
      workspace.billableMembersCount > (nextProduct.maxSeats ?? Number.POSITIVE_INFINITY)
    ) {
      throw new ReduceActiveUsersCountError({
        activeUsers: workspace.billableMembersCount,
        usersToReduce: Math.abs(
          workspace.billableMembersCount -
            (nextProduct.maxSeats ?? Number.POSITIVE_INFINITY),
        ),
      });
    }

    // products with price per unit members count check
    if (!nextProduct.isPriceFixed && params.seats < workspace.billableMembersCount) {
      throw new ReduceActiveUsersCountError({
        activeUsers: workspace.billableMembersCount,
        usersToReduce: Math.abs(workspace.billableMembersCount - params.seats),
      });
    }
  };

  getReceipt(params: {
    product: IProductEntity;
    quantity: number;
    promotionCode?: IReceiptAdjustmentEntity;
  }): Observable<IReceiptEntity> {
    return this.subscriptionUseCase.isUpgradable().pipe(
      switchMap((isUpgradable) => {
        if (isUpgradable) {
          return this.paymentDetailsRepository
            .getUpcomingReceipt({
              plan: params.product.id,
              quantity: params.quantity,
              promoCode: params.promotionCode?.id,
            })
            .pipe(
              switchMap((upcomingReceipt) =>
                this.getLocalReceipt({ ...params, upcomingReceipt }),
              ),
            );
        } else {
          return this.getLocalReceipt(params); // as we don't have a stripe customer id, we can't get the receipt from stripe
        }
      }),
    );
  }

  private mapAnnaulDiscount(params: {
    monthlyPrice: number;
    annualPrice: number;
  }): IReceiptAdjustmentEntity {
    const { monthlyPrice, annualPrice } = params;
    const fixedDiscount = monthlyPrice - annualPrice;
    const percentage = fixedDiscount / monthlyPrice;
    const displayPercent = Math.round(percentage * 100);

    return new PercentageReceiptAdjustment({
      title: `${displayPercent}% annual discount`,
      percentage: percentage,
      type: 'discount',
    });
  }

  private calculateCredits(product: IProductEntity, seats: number): number | 'unlimited' {
    const fullCredits = product.quotas.find((q) => q.creditType === 'full');

    if (!fullCredits) throw new FullCreditsQuotaNotFoundError();

    if (fullCredits.isUnlimited) return 'unlimited';

    return fullCredits.creditsFixed + fullCredits.creditsPerSeat * seats;
  }

  private getLocalReceipt(params: {
    product: IProductEntity;
    quantity: number;
    promotionCode?: IReceiptAdjustmentEntity;
    upcomingReceipt?: IUpcomingReceiptEntity;
  }): Observable<IReceiptEntity> {
    return this.billingUseCase.getProducts().pipe(
      switchMap((allProducts) => {
        const { quantity, product } = params;

        if (product.status === 'draft') {
          return throwError(() => new ProductNotForBuyError(product.id));
        }

        const receiptBuilder = new ReceiptBuilder();

        receiptBuilder.addName(product.name);

        if (product.isPriceFixed) {
          receiptBuilder.addSeats(product.maxSeats ?? Number.POSITIVE_INFINITY);
        } else {
          receiptBuilder.addSeats(quantity);
        }

        receiptBuilder.addCycle(product.cycle);
        receiptBuilder.addIsPriceFixed(product.isPriceFixed);
        receiptBuilder.addPricePerSeat(product.price);
        receiptBuilder.addCredits(this.calculateCredits(product, quantity));

        // for annual plans we should show monthly price and discount
        if (product.cycle === 'annually') {
          const relevantMonthlyProduct = allProducts.find(
            (p) => p.cycle === 'monthly' && p.family === product.family,
          );

          if (relevantMonthlyProduct) {
            const monthlyPrice = relevantMonthlyProduct.price * 12;
            const annualPrice = product.price;
            receiptBuilder.addPricePerSeat(monthlyPrice);
            receiptBuilder.addAdjustment(
              this.mapAnnaulDiscount({ monthlyPrice, annualPrice }),
            );
          }
        }

        if (params.upcomingReceipt) {
          params.upcomingReceipt.adjustments.forEach((adjustment) =>
            receiptBuilder.addAdjustment(adjustment),
          );
          receiptBuilder.addDueDate(params.upcomingReceipt.dueDate);
        }

        if (params.promotionCode) {
          // if we have promo code in upcoming receipt, we should use it
          if (params.upcomingReceipt?.promoCode) {
            params.upcomingReceipt.promoCode.title = params.promotionCode.title;
            receiptBuilder.addPromotion(params.upcomingReceipt.promoCode);
          } else {
            receiptBuilder.addPromotion(params.promotionCode);
          }
        }

        const receipt = receiptBuilder.build();

        if (
          params.upcomingReceipt &&
          receipt.total.toFixed(2) !== params.upcomingReceipt.total.toFixed(2)
        ) {
          this.appLogger.error(
            new WrongUpcomingReceiptTotalError({
              upcomingReceipt: params.upcomingReceipt,
              predictedReceipt: receipt,
            }),
          );
        }

        return of(receipt);
      }),
    );
  }

  getPromocode(params: {
    code: string;
    plan: string;
  }): Observable<IReceiptAdjustmentEntity> {
    return this.subscriptionUseCase.isUpgradable().pipe(
      switchMap((isUgradable) => {
        return this.paymentDetailsRepository.getPromocode({
          ...params,
          isApplyable: !isUgradable,
        });
      }),
    );
  }
}
