import { FC } from 'react';
import { Box, List } from '@mui/material';

import { MenuItem } from '@/components/MenuItem';

import { Option } from '../../types';
import { Picker } from '../Picker';

import styles from './styles.module.scss';

export const PopoverBody: FC<{
  value: Option;
  minDateTimestamp?: number;
  options: Option[];
  cutomOption: Option;
  onSelect: (value: Option) => void;
}> = ({ value, minDateTimestamp, cutomOption, options, onSelect }) => {
  return (
    <>
      <List className={styles.list}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            selected={value.value === option.value}
            onClick={(): void => {
              onSelect(option);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
        <MenuItem
          key={cutomOption.value}
          value={cutomOption.value}
          selected={value.value === cutomOption.value}
          onClick={(): void => {
            onSelect(cutomOption);
          }}
        >
          {cutomOption.label}
        </MenuItem>
      </List>
      {cutomOption.value === value.value && (
        <Box className={styles.footer}>
          <Picker
            onChange={(newValue): void => {
              onSelect({ ...cutomOption, value: newValue });
            }}
            value={value.value}
            minDateTimestamp={minDateTimestamp}
          />
        </Box>
      )}
    </>
  );
};
