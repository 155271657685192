import { useMemo } from 'react';
import { TimeScaleTimeOptions } from 'chart.js';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { AnalyticsChartProps } from '../../AnalyticsChart/AnalyticsChart';

type UseChartTimeScaleSettings = (
  props: Pick<AnalyticsChartProps, 'selectedDate'>,
) => Pick<TimeScaleTimeOptions, 'unit' | 'displayFormats'>;

export const useChartTimeScaleSettings: UseChartTimeScaleSettings = ({
  selectedDate,
}) => {
  const displayFormats = {
    day: 'MMM DD',
    week: 'MMM DD',
  };

  return useMemo(() => {
    switch (selectedDate) {
      case DATE_SELECT_OPTION_VALUES.today:
      case DATE_SELECT_OPTION_VALUES.yesterday:
      case DATE_SELECT_OPTION_VALUES.lastWeek:
        return {
          unit: 'day',
          displayFormats,
        };
      default:
        return {
          unit: 'week',
          displayFormats,
        };
    }
  }, [selectedDate]);
};
