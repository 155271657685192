import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import InstallExtensionIcon from './assets/installExtensionIcon.svg?react';
import RevealContactIcon from './assets/revealContactIcon.svg?react';
import { OnboardingBannerAction } from './components/OnboardingBannerAction';

import styles from './styles.module.scss';

export const OnboardingBanner: FC<{
  fullName: string;
  currentStep: 'installExtension' | 'revealContact';
  onClickInstallExtension: () => void;
  onClickRevealContact: () => void;
}> = ({ fullName, currentStep, onClickInstallExtension, onClickRevealContact }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Box className={styles.wrapper}>
      <Stack
        className={styles.stack}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className={styles.stackItem}>
          <Typography variant="h1" className={styles.title}>
            {t('onboarding.banner.title', { fullName })}
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            {t('onboarding.banner.description')}
          </Typography>
        </Box>
        <Box className={styles.stackItem}>
          {currentStep === 'installExtension' ? (
            <OnboardingBannerAction
              title={t('onboarding.banner.installExtension.title')}
              description={t('onboarding.banner.installExtension.description')}
              buttonText={t('onboarding.banner.installExtension.buttonText')}
              Icon={InstallExtensionIcon}
              onClick={onClickInstallExtension}
            />
          ) : (
            <OnboardingBannerAction
              title={t('onboarding.banner.revealContact.title')}
              description={t('onboarding.banner.revealContact.description')}
              buttonText={t('onboarding.banner.revealContact.buttonText')}
              Icon={RevealContactIcon}
              onClick={onClickRevealContact}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
