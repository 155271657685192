import * as React from "react";
const SvgFailedToUpload = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 66, height: 66, viewBox: "0 0 66 66", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18933" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Ellipse 1578", transform: "translate(5 5)", style: {
  fill: "#fff",
  stroke: "#ff4757",
  strokeWidth: 5
} }, /* @__PURE__ */ React.createElement("circle", { cx: 28, cy: 28, r: 28, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 28, cy: 28, r: 30.5, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("path", { d: "M136.335-859.309h1.21v-4.6h-1.21zm.605-5.748a.6.6 0 0 0 .423-.172.538.538 0 0 0 .181-.4.539.539 0 0 0-.181-.4.6.6 0 0 0-.423-.172.6.6 0 0 0-.424.172.538.538 0 0 0-.181.4.538.538 0 0 0 .181.4.6.6 0 0 0 .424.173zM122.42-877.7zm2.42 11.5h4.447a7.854 7.854 0 0 1 .771-1.236 7.717 7.717 0 0 1 .983-1.063h-6.2zm0 4.6h3.721a7.364 7.364 0 0 1-.091-1.15 7.364 7.364 0 0 1 .091-1.15h-3.721zm-2.42 4.6a2.394 2.394 0 0 1-1.709-.675 2.16 2.16 0 0 1-.711-1.624V-877.7a2.16 2.16 0 0 1 .711-1.624 2.393 2.393 0 0 1 1.709-.676h9.68l7.26 6.9v2.644a8.069 8.069 0 0 0-1.18-.259 8.8 8.8 0 0 0-1.24-.086v-1.15h-6.05v-5.749h-8.47v18.392h6.867a7.87 7.87 0 0 0 .771 1.236 7.709 7.709 0 0 0 .983 1.063zm14.52-11.5a5.988 5.988 0 0 1 4.28 1.681 5.4 5.4 0 0 1 1.77 4.066 5.4 5.4 0 0 1-1.77 4.066 5.988 5.988 0 0 1-4.28 1.681 5.988 5.988 0 0 1-4.28-1.681 5.4 5.4 0 0 1-1.77-4.066 5.4 5.4 0 0 1 1.77-4.066 5.988 5.988 0 0 1 4.28-1.681z", transform: "translate(-98.495 901.504)", style: {
  fill: "#ff4757"
} })));
export default SvgFailedToUpload;
