import { injectable } from 'inversify';

import { DbStateCreator } from '@/features/system/db/data/DbStateCreator';

import { IIntegrationExtportDC } from './IntegrationExportState';

@injectable()
export class IntegrationExportStateCreator extends DbStateCreator<IIntegrationExtportDC> {
  constructor() {
    super({
      stateName: 'integration-export',
    });
  }
}
