import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DislikeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6.25 6 24 24">
    <path
      d="M20.85 26.75H8.507V11.086l7.527-8.336 1.384 1.1a1.186 1.186 0 0 1 .3.519 2.547 2.547 0 0 1 .111.786V5.3l-1.19 5.785h8.108a2.073 2.073 0 0 1 1.536.712 2.333 2.333 0 0 1 .678 1.661v1.661a4.9 4.9 0 0 0 .028.5.99.99 0 0 1-.055.475l-3.57 8.811a3 3 0 0 1-1.01 1.305 2.455 2.455 0 0 1-1.504.54zm-10.323-2.344h10.682l3.515-8.959v-1.988H14.1L15.425 6.7l-4.9 5.577zm0-12.133zm-2.02-1.187v2.373H5.186v10.947h3.321v2.344H3V11.086z"
      transform="rotate(180 16.625 16.375)"
    />
  </SvgIcon>
);
