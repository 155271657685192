import { injectable } from 'inversify';

import { IAccountEntity } from '@/features/common/account';
import {
  CustomerMessagingEventKeys,
  ICustomerMessagingRepository,
} from '@/features/system/CustomerIO';
import { mapAccountToCioAccountDC } from '@/features/system/CustomerIO/data/mapper';

@injectable()
export default class CustomerMessagingRepository implements ICustomerMessagingRepository {
  private cio;

  constructor() {
    this.cio = _cio;
  }

  identify(account: IAccountEntity): void {
    this.cio.identify(mapAccountToCioAccountDC(account));
  }

  track(key: CustomerMessagingEventKeys, attrs?: Record<string, string>): void {
    this.cio?.track(key, attrs);
  }

  page(key: CustomerMessagingEventKeys, attrs?: Record<string, string>): void {
    this.cio?.page(key, attrs);
  }
}
