import { FC, useMemo } from 'react';
import clsx from 'clsx';

import { ArrowBackIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type ReviewControlsProps = {
  onChange: (value: number) => void;
  total: number;
  current: number;
};

export const ReviewControls: FC<ReviewControlsProps> = ({ total, current, onChange }) => {
  const points = useMemo(() => {
    return Array.from({ length: total }, (_, index) => ({
      index,
      active: index === current,
    }));
  }, [total, current]);

  function next() {
    onChange((current + 1) % total);
  }

  function prev() {
    if (current === 0) {
      return onChange(total - 1);
    }

    onChange(current - 1);
  }

  function goTo(index: number) {
    onChange(index);
  }

  return (
    <div className={styles.reviewControls}>
      <button onClick={prev} className={styles.reviewControlsPrev}>
        <ArrowBackIcon />
      </button>
      <div className={styles.reviewControlsPoints}>
        {points.map(({ index, active }) => (
          <span
            key={index}
            onClick={() => goTo(index)}
            className={clsx({
              [styles.isActive]: active,
            })}
          />
        ))}
      </div>
      <button onClick={next} className={styles.reviewControlsNext}>
        <ArrowBackIcon />
      </button>
    </div>
  );
};
