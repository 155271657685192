import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { unix } from 'dayjs';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import {
  ContactListSortableField,
  IContactListEntity,
  IContactListEntityUI,
  IContactListUseCase,
} from '@/features/common/contactList';
import { GridSortModel } from '@/features/lists/ui/ListsTable';
import {
  ITeamMemberEntity,
  useAcceptedTeamMembers,
  useTeamMemberSelect,
} from '@/features/settings';
import {
  ANALYTICS_EVENTS,
  DataFiltersPageProperty,
  useAnalytics,
} from '@/features/system/analytics';

import { useObservableResult } from '@/utils/rx';

export type ContactListsViewModel = {
  contactLists: IContactListEntityUI[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  filterByUser: null | string;
  handleFilterByUserChange: (val: null | string) => void;
  handleChangeSortSchema: (sortSchema: GridSortModel) => void;
  isLoading: boolean;
};

export const useContactListsViewModel = (): ContactListsViewModel => {
  const [search, setSearch] = useState('');
  const { value: filterByUser, setValue: setFilterByUser } = useTeamMemberSelect();
  const [sortSchema, setSortSchema] = useState<GridSortModel | null>(null);
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );
  const { data: teamMembers } = useAcceptedTeamMembers();
  const { trackEvent } = useAnalytics();

  const teamMembersMap: Record<string, ITeamMemberEntity> = useMemo(
    () =>
      teamMembers.reduce((acc, item) => {
        acc[item.uuid] = item;
        return acc;
      }, {}),
    [teamMembers],
  );

  const contactListsByQuery$ = useMemo(() => {
    const query = { nameReg: search };
    if (filterByUser) {
      Object.assign(query, { created_by: filterByUser });
    }

    if (sortSchema?.length)
      Object.assign(query, {
        sortSchema: {
          [ContactListSortableField[sortSchema[0].field]]: sortSchema[0].sort,
        },
      });

    return contactListUseCase.getContactListByQuery(query);
  }, [search, filterByUser, sortSchema]);

  const contactListsResult = useObservableResult(contactListsByQuery$, {
    defaultData: [],
  });

  const contactLists = useMemo(() => {
    return contactListsResult.data.map(
      (entity: IContactListEntity): IContactListEntityUI => {
        return {
          id: entity.uuid,
          createdAt: unix(entity.createdAt).format('MMM DD, YYYY'),
          updatedAt: unix(entity.updatedAt).format('MMM DD, YYYY'),
          createdBy: teamMembersMap[entity.createdBy]?.fullName || '',
          name: entity.name,
          specialType: entity.specialType,
          contactsAmount: entity.contactsAmount,
        };
      },
    );
  }, [contactListsResult.data, contactListsResult.isLoading, teamMembers.length]);

  useEffect(() => {
    if (!contactLists.length) return;

    trackEvent(ANALYTICS_EVENTS.VIEW_CONTACTS_TAB, {
      lists_count: contactLists?.length,
    });
  }, [contactLists?.length]);

  const handleChangeSortSchema = (newSortSchema: GridSortModel): void => {
    setSortSchema(newSortSchema);
  };

  const handleFilterByUserChange = (val): void => {
    trackEvent(ANALYTICS_EVENTS.FILTER_BY_USERS, {
      page: DataFiltersPageProperty.ContactLists,
    });
    setFilterByUser(val);
  };

  return {
    contactLists,
    search,
    setSearch,
    filterByUser,
    handleFilterByUserChange,
    handleChangeSortSchema,
    isLoading: contactListsResult.isLoading,
  };
};
