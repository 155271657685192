import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { PERMISSIONS_TYPES } from '@/ioc/types';

import { IPermissionsRepository } from '../data/PermissionsRepository';

import { Permission } from './types/Permission';

export interface IPermissionsUseCase {
  getAccessablePermissions: () => Observable<Record<Permission, boolean>>;
  isSuperAdmin: () => Observable<boolean>;
}

@injectable()
export class PermissionsUseCase implements IPermissionsUseCase {
  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private readonly permissionsRepository!: IPermissionsRepository;

  public getAccessablePermissions(): Observable<Record<Permission, boolean>> {
    return this.permissionsRepository.getAccessablePermissions();
  }

  public isSuperAdmin(): Observable<boolean> {
    return this.permissionsRepository.isSuperAdmin();
  }
}
