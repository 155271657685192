import { FC, MouseEvent } from 'react';
import { Box, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { ITagEntity } from '@/features/common/tag';

import { TagBadge } from '@/components';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  selectedTags: ITagEntity[];
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

export const MultipleAnchor: FC<Props> = ({ onClick, selectedTags, disabled }) => {
  const firstElement = selectedTags[0];
  const exludedFirstList = selectedTags.slice(1);
  const tooltipTitle = exludedFirstList.map((tag) => {
    return <Box key={tag.uuid}>{tag.name}</Box>;
  });

  return (
    <Box className={styles.wrapper}>
      <TagBadge bgColor={firstElement.color}>{firstElement.name}</TagBadge>
      <Tooltip title={tooltipTitle} placement="top">
        <Box
          className={clsx(styles.counter, { [styles.disabled]: disabled })}
          onClick={(e): void => {
            if (disabled) return;
            onClick(e);
          }}
        >
          +{exludedFirstList.length}
        </Box>
      </Tooltip>
    </Box>
  );
};
