import { FC, PropsWithChildren, useEffect } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { AUTH_TYPES } from '@/ioc/types';

import { SplashScreen } from '@/components';

import { AuthStatus, IAuthWatcherUseCase } from '../../../domain';
import { useAuthStatus } from '../../hooks';

export const Auth: FC<PropsWithChildren> = ({ children }) => {
  const authStatus = useAuthStatus();
  const authWatcherUseCase = useInjection<IAuthWatcherUseCase>(
    AUTH_TYPES.AuthWatcherUseCase,
  );

  useEffect(() => {
    const subscription = authWatcherUseCase.start().subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (authStatus === AuthStatus.Initialisation) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};
