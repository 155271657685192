import { Container } from 'inversify';

import { GLOBAL_ERROR_TYPES } from '@/ioc/types';

import GlobalErrorRepository from '../data/GlobalErrorRepository';
import { IGlobalErrorRepository } from '../domain/abstractions/repositories/IGlobalErrorRepository';
import { IGlobalErrorUseCase } from '../domain/abstractions/useCases/IGlobalErrorUseCase';
import GlobalErrorUseCase from '../domain/GlobalErrorUseCase';

export const bindGlobalErrorModule = (container: Container): void => {
  container
    .bind<IGlobalErrorRepository>(GLOBAL_ERROR_TYPES.GlobalErrorRepository)
    .to(GlobalErrorRepository)
    .inSingletonScope();
  container
    .bind<IGlobalErrorUseCase>(GLOBAL_ERROR_TYPES.GlobalErrorUseCase)
    .to(GlobalErrorUseCase);
};
