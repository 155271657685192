import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { UserRole } from '@/features/common/account';

import { TagBadge } from '@/components';

import { RoleManagementButton } from '../RoleManagementButton';

import styles from './styles.module.scss';

const roleTitleTranslationKeys = {
  [UserRole.Owner]: 'owner',
  [UserRole.Admin]: 'admin',
  [UserRole.Manager]: 'manager',
  [UserRole.Member]: 'user',
};

const MemberRole: React.FC<
  GridRenderCellParams & {
    canManageTeamMembers: boolean;
    currentUserEmail: string;
  }
> = (props) => {
  const {
    canManageTeamMembers,
    row: { email, role },
    currentUserEmail,
  } = props;
  const { t } = useTranslation('settings');
  const roleTitle = t(`teamManagement.role.${roleTitleTranslationKeys[role]}.title`);

  const isCurrentAccount = email === currentUserEmail;
  const isOwner = role === UserRole.Owner;

  if (isCurrentAccount || !canManageTeamMembers || isOwner) {
    return (
      <TagBadge className={styles.RoleTag} bgColor={'white'}>
        {roleTitle}
      </TagBadge>
    );
  } else {
    return <RoleManagementButton role={role} email={email} roleTitle={roleTitle} />;
  }
};

export default MemberRole;
