import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { Permission, usePermissions } from '@/features/common/permissions';
import { SalesforceApiDisabledError } from '@/features/integration/domain';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { ModalControler, useDocumentMeta, useModalController, useOnMount } from '@/hooks';

import { useObservableResult } from '@/utils/rx';

import { IIntegrationEntityUI } from './entities';
import { useIntegrationsUseCase } from './hooks';

type Return = {
  selectedIntegration: Nullable<IIntegrationEntityUI>;
  handleIntegrationSelect(integration: IIntegrationEntityUI): void;
  handleIntegrationConnect(integration: IIntegrationEntityUI): void;
  handleRetryConnect(): void;
  handleContactSales(integration: IIntegrationEntityUI): void;
  integrationsList: IIntegrationEntityUI[];
  isLoading: boolean;
  connectionFailedDialog: ModalControler;
  errorVariant: ErrorVariant;
  requestDialog: ModalControler;
  rightsDialog: ModalControler;
  connectDialog: ModalControler;
  upgradeDialog: ModalControler;
};

export type ErrorVariant = 'SalesforceApiDisabled' | 'Unknown';

export const useIntegrationsViewModel = (): Return => {
  useDocumentMeta({
    title: 'integrations.title',
    description: 'integrations.description',
  });
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [searchParams] = useSearchParams();
  const [selectedIntegrationId, setSelectedIntegrationId] =
    useState<Nullable<IIntegrationEntityUI['id']>>(null);
  const requestDialog = useModalController();
  const connectDialog = useModalController();
  const upgradeDialog = useModalController();
  const rightsDialog = useModalController();
  const connectionFailedDialog = useModalController();
  const [errorVariant, setErrorVariant] = useState<ErrorVariant>('Unknown');
  const integrationsUseCases = useIntegrationsUseCase();
  const permissions = usePermissions();

  useOnMount(() => {
    analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_VIEW_PAGE);

    const provider = searchParams.get('provider');
    const code = searchParams.get('code');
    if (provider && code) {
      integrationsUseCases
        .connectUser(provider, code)
        .then(() => {
          navigate(ROUTES.INTEGRATIONS);
        })
        .catch((error) => {
          if (error instanceof SalesforceApiDisabledError) {
            setErrorVariant('SalesforceApiDisabled');
            return connectionFailedDialog.onOpen();
          }

          setErrorVariant('Unknown');
          connectionFailedDialog.onOpen();
        });
    }
  });

  const { data: currentIntegrations, isLoading } = useObservableResult(
    () => integrationsUseCases.getIntegrations(),
    {
      defaultData: [],
      cacheKey: 'integrations-list',
    },
  );

  const integrationsListUI = useMemo(() => {
    return currentIntegrations.map((integration) => {
      return {
        ...integration,
        description: `cards.${integration.id}Description`,
        isConnected: integration.status === 'connected',
        isExpired: integration.status === 'expired',
        isAvailable: integration.status !== 'not-available',
      };
    });
  }, [currentIntegrations]);

  const selectedIntegration = useMemo(() => {
    if (!selectedIntegrationId) return null;

    return integrationsListUI.find((i) => i.id === selectedIntegrationId);
  }, [selectedIntegrationId, integrationsListUI]);

  const handleIntegrationSelect = (integration: IIntegrationEntityUI): void => {
    setSelectedIntegrationId(integration.id);
  };

  const handleIntegrationConnectByUsers = (integration: IIntegrationEntityUI): void => {
    if (!integration.isConnected) return rightsDialog.onOpen();
  };

  const handleIntegrationConnectByAdmins = (integration: IIntegrationEntityUI): void => {
    if (!permissions.hasPermissions(Permission.CanAccessCrmIntegration))
      return upgradeDialog.onOpen();

    if (integration.isAvailable && (!integration.isConnected || integration.isExpired))
      return connectDialog.onOpen();
  };

  const handleIntegrationConnect = (integration: IIntegrationEntityUI): void => {
    setSelectedIntegrationId(integration.id);

    if (!integration.isAvailable) return connectDialog.onOpen();

    if (!permissions.hasPermissions(Permission.CanEditIntegrations))
      return handleIntegrationConnectByUsers(integration);

    return handleIntegrationConnectByAdmins(integration);
  };

  const handleContactSales = (integration: IIntegrationEntityUI): void => {
    setSelectedIntegrationId(integration.id);
    requestDialog.onOpen();
  };

  const handleRetryConnect = (): void => {
    connectionFailedDialog.onClose();

    if (!selectedIntegration) return;

    handleIntegrationConnect(selectedIntegration);
  };

  const provider = searchParams.get('provider');

  if (provider && !selectedIntegrationId) {
    setSelectedIntegrationId(provider);
  }

  return {
    integrationsList: integrationsListUI,
    isLoading,
    selectedIntegration,
    handleIntegrationSelect,
    handleIntegrationConnect,
    handleContactSales,
    handleRetryConnect,
    requestDialog,
    connectDialog,
    upgradeDialog,
    rightsDialog,
    connectionFailedDialog,
    errorVariant,
  };
};
