import { inject, injectable, multiInject, postConstruct } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_LOGGER_TYPES } from '@/ioc/types';

import { CollectionName, Database } from '@/features/system/db';
import { IAppLogger } from '@/features/system/logger';

import type { IReplicationService } from './ReplicationService';
import { ReplicationStatus } from './types';

export interface IReplicationRepository {
  start(): Promise<void>;
  stop(): Promise<void>;
  init(db: Database): void;
  getStatus(): Observable<ReplicationStatus>;
}

@injectable()
export default class ReplicationRepository implements IReplicationRepository {
  private replicationServicesMap: Map<CollectionName, IReplicationService> = new Map();

  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  private readonly replicationStatus: BehaviorSubject<ReplicationStatus> =
    new BehaviorSubject(ReplicationStatus.Starting);

  constructor(
    @multiInject('ReplicationService')
    registeredReplicationServices: IReplicationService[],
  ) {
    registeredReplicationServices.forEach((service) => {
      this.replicationServicesMap.set(service.getCollectionName(), service);
    });
  }

  @postConstruct()
  logStatus(): void {
    this.getStatus().subscribe((status) => {
      this.logger.log(`[Replication]: ${status}`);
    });
  }

  public stop = async (): Promise<void> => {
    this.replicationStatus.next(ReplicationStatus.Stopping);
    for (const [, replicationService] of this.replicationServicesMap.entries()) {
      await replicationService.stop();
    }
    this.replicationStatus.next(ReplicationStatus.Stopped);
  };

  public start = async (): Promise<void> => {
    this.replicationStatus.next(ReplicationStatus.Starting);
    for (const [, replicationService] of this.replicationServicesMap.entries()) {
      await replicationService.start();
    }
    this.replicationStatus.next(ReplicationStatus.Started);
  };

  public init = (db: Database): void => {
    for (const [, replicationService] of this.replicationServicesMap.entries()) {
      replicationService.create(db);
    }
  };

  public getStatus(): Observable<ReplicationStatus> {
    return this.replicationStatus;
  }
}
