import { inject, injectable } from 'inversify';
import { indexBy, prop } from 'ramda';
import { catchError, filter, firstValueFrom, map, Observable, switchMap } from 'rxjs';

import { CONTACT_BY_FILTERS_TYPES, CONTACT_TYPES } from '@/ioc/types';

import {
  IContactApiService,
  IContactByFiltersDao,
  IContactByFiltersDC,
  IContactDao,
  IContactDC,
} from '../data';
import {
  ContactGetByFiltersResult,
  IContactEntity,
  IContactRepository,
  IContactSyncRepository,
} from '../domain';

import { mapContactDcToEntity, mapContactEntityToDc } from './mappers/mapper';

@injectable()
export class ContactRepository implements IContactRepository {
  @inject(CONTACT_TYPES.ContactDao)
  private readonly contactDao: IContactDao;

  @inject(CONTACT_TYPES.ContactApiService)
  private readonly contactApiService: IContactApiService;

  @inject(CONTACT_TYPES.ContactSyncRepository)
  private readonly contactSyncRepository: IContactSyncRepository;

  @inject(CONTACT_BY_FILTERS_TYPES.ContactByFiltersDao)
  private readonly contactByFiltersDao: IContactByFiltersDao;

  private createBlobLinkToDownload(csvContent: string): string {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    return objUrl;
  }

  public async moveToList(listId: string, ids: string[]): Promise<IContactEntity[]> {
    const contacts = await firstValueFrom(this.contactDao.findByIds(ids));
    const result = await this.contactDao.bulkUpsert(
      contacts.map((contact) => ({
        ...contact,
        contact_list_id: listId,
      })),
    );
    return result.map(mapContactDcToEntity);
  }

  public getByQuery(query: URLSearchParams): Observable<IContactEntity[]> {
    return this.contactApiService
      .getByQuery(query)
      .pipe(map((result) => result.map(mapContactDcToEntity)));
  }

  public getByFilters(params: {
    queryString: string;
    autoUpdate?: boolean;
  }): Observable<ContactGetByFiltersResult> {
    return this.contactSyncRepository.syncByQuery(params).pipe(
      catchError(() => {
        return this.contactByFiltersDao.findById(params.queryString);
      }),
      switchMap(() => {
        return this.contactByFiltersDao.findById(params.queryString);
      }),
      filter((dc) => !!dc),
      switchMap((dc: IContactByFiltersDC) => {
        return this.getByIds(dc.contact_by_filters_ids_current).pipe(
          map((entities) => ({
            entities,
            totalCount: dc.count_current,
          })),
        );
      }),
    );
  }

  public getByIds(ids: string[]): Observable<IContactEntity[]> {
    return this.contactDao.findByIds(ids).pipe(
      filter((result) => result.length === ids.length), // contacts inserts in db in parallel by pullstream, so we need to wait for all contacts
      map((result: IContactDC[]) => {
        const mapByIds = indexBy(prop('uuid'), result);
        const sortedArray: IContactDC[] = [];
        for (const id of ids) {
          if (!mapByIds[id]) continue;
          sortedArray.push(mapByIds[id]);
        }
        return sortedArray.map(mapContactDcToEntity);
      }),
    );
  }

  public getAll(): Observable<IContactEntity[]> {
    return this.contactDao.findAll().pipe(
      map((result) => {
        return result.map(mapContactDcToEntity);
      }),
    );
  }

  public async updateById(id: string, dto: Partial<IContactDC>): Promise<IContactEntity> {
    const result = await this.contactDao.updateOne(id, dto);
    return mapContactDcToEntity(result);
  }

  public async upsertPatch(patch: IContactEntity[]): Promise<IContactEntity[]> {
    const result = await this.contactDao.bulkUpsert(patch.map(mapContactEntityToDc));
    return result.map(mapContactDcToEntity);
  }

  public async exportToCsvAll(): Promise<{ linkToDownload: string }> {
    const csvContent = await firstValueFrom(this.contactApiService.exportToCsvAll());
    return {
      linkToDownload: this.createBlobLinkToDownload(csvContent),
    };
  }

  public async exportToCsvByFilters(
    queryParams: URLSearchParams,
  ): Promise<{ linkToDownload: string }> {
    const csvContent = await firstValueFrom(
      this.contactApiService.exportToCsvByFilters(queryParams),
    );
    return {
      linkToDownload: this.createBlobLinkToDownload(csvContent),
    };
  }

  public async exportToCsvByIds(ids: string[]): Promise<{ linkToDownload: string }> {
    const csvContent = await firstValueFrom(this.contactApiService.exportToCsvByIds(ids));
    return {
      linkToDownload: this.createBlobLinkToDownload(csvContent),
    };
  }

  public wrongInfoReport(dto: {
    value: string;
    contact_uuid: string;
    entity_type: string;
  }): Promise<boolean> {
    return firstValueFrom(this.contactApiService.wrongInfoReport(dto));
  }
}
