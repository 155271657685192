import { Children, cloneElement, FC, PropsWithChildren, ReactElement } from 'react';

import { useIsOnline } from '../../hooks';

export const OnlineRequiredAction: FC<PropsWithChildren> = ({ children }) => {
  const isOnline = useIsOnline();

  if (isOnline) {
    return children;
  }

  return Children.map(children, (child: ReactElement) => {
    return cloneElement(child, {
      disabled: true,
    });
  });
};
