import { FC } from 'react';
import { Box } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';

import styles from './styles.module.scss';

type HeaderCellProps = GridColumnHeaderParams;

export const HeaderCell: FC<HeaderCellProps> = ({ colDef }) => {
  return <Box className={styles.headerCell}>{colDef.headerName}</Box>;
};
