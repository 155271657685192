export class ChartCalculator {
  getIntCeilingByFirstDigit(int: number): number {
    if (!Number.isInteger(int)) {
      throw new Error(`Arguments error: only integers are supported. Received: ${int}`);
    }

    const length = this.getDigitsAmount(int);

    const factor = Math.pow(10, length - 1);

    const float = int / factor;

    const ceiledFloat = Math.ceil(float);

    return ceiledFloat * factor;
  }

  private getDigitsAmount(int: number): number {
    return int.toString().length;
  }
}
