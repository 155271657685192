import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { indexBy, prop } from 'ramda';

import { ROUTES } from '@/router/routes';

import { ITagEntity, useTagEntities } from '@/features/common/tag';

import { useSearch } from '@/hooks';

import { Value } from '../../types';

export const usePopoverBodyViewModel = ({
  defaultIndeterminatedValues,
  selectedContacts,
}: {
  selectedContacts: string[];
  defaultIndeterminatedValues: Value[];
}) => {
  const [value, setValue] = useState<Value[]>(defaultIndeterminatedValues);
  const { value: search, handleSearchChange, handleSearchClear } = useSearch();
  const navigate = useNavigate();
  const { data: allTags, isLoading: isLoadingAllTags } = useTagEntities();
  const { data: filteredTags } = useTagEntities({ nameRegex: search });
  const tagMapById: Record<string, ITagEntity> = useMemo(() => {
    return indexBy(prop('uuid'), allTags);
  }, [allTags]);

  const applyIsDisable = !value.length;

  const isCheckedItem = (id: string): boolean => {
    return value.some(
      (item) => item.tagId === id && item.isChecked && !item.isIndeterminated,
    );
  };

  const isIndeterminate = (id: string): boolean => {
    return value.some((item) => item.tagId === id && item.isIndeterminated);
  };

  const goToTagManagement = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };

  const handleListItemClick = (tagId: string): void => {
    if (isIndeterminate(tagId)) {
      setValue((prev) => {
        return prev.map((item) => {
          if (item.tagId === tagId) {
            return {
              ...item,
              isIndeterminated: false,
              isChecked: false,
            };
          }
          return item;
        });
      });
      return;
    }

    if (isCheckedItem(tagId)) {
      setValue((prev) => {
        return prev.map((item) => {
          if (item.tagId === tagId) {
            return {
              ...item,
              isChecked: false,
            };
          }
          return item;
        });
      });
    } else {
      setValue((prev) => {
        const result = [...prev];
        for (const item of selectedContacts) {
          result.push({
            contactId: item,
            tagId,
            isChecked: true,
            isIndeterminated: false,
          });
        }
        return result;
      });
    }
  };

  useEffect(() => {
    const needToReApply =
      value.some((item) => !tagMapById[item.tagId]) && !isLoadingAllTags;

    if (!needToReApply) return;

    setValue((prev) => {
      return prev.filter((item) => tagMapById[item.tagId]);
    });
  }, [tagMapById, isLoadingAllTags]);

  return {
    search,
    handleSearchChange,
    handleSearchClear,
    tags: filteredTags,
    isCheckedItem,
    isIndeterminate,
    goToTagManagement,
    handleListItemClick,
    applyIsDisable,
    value,
  };
};
