import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { IEnrichmentReportUseCase, IEnrichmentRepository } from './abstraction';
import { IEnrichmentStatisticEntity } from './entities';

@injectable()
export class EnrichmentReportUseCase implements IEnrichmentReportUseCase {
  @inject(ENRICHMENT_TYPES.EnrichmentRepository)
  private readonly enrichmentRepository: IEnrichmentRepository;

  public getById(uuid: string): Observable<IEnrichmentStatisticEntity | null> {
    return this.enrichmentRepository
      .getById(uuid)
      .pipe(map((value) => value?.statistic || null));
  }
}
