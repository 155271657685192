import { RxJsonSchema } from 'rxdb';

import type { IWorkspaceDC } from '../../dataContracts';

export const workspaceSchema: RxJsonSchema<IWorkspaceDC> = {
  title: 'workspace schema',
  version: 6,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    member_access_all_contacts: { type: 'string' },
    updated_at: { type: 'string' },
    created_at: { type: 'string' },
    billable_members_count: { type: 'number' },
    members: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          email: { type: 'string' },
          thumbnail: { type: 'string' },
          full_name: { type: 'string' },
          invitation_status: { type: 'string' },
          invited_by: { type: 'string' },
          invited_at: { type: 'number' },
          accepted_invitation_at: { type: 'string' },
          role: { type: 'string' },
          last_login_at: { type: 'string' },
        },
      },
    },
    last_login_at: { type: 'string' },
    subscription: {
      type: 'object',
      properties: {
        plan: {
          type: 'string',
        },
        future_plan: {
          type: 'string',
        },
        purchase_type: { type: 'string' },
        start_date: { type: 'number' },
        expiration_date: { type: 'number' },
        paid: { type: 'boolean' },
        is_grace_period: { type: 'boolean' },
        is_canceled: { type: 'boolean' },
        paid_members_count: { type: 'number' },
        billing_details_filled: { type: 'boolean' },
        promo_code_id: { type: 'string' },
        credits: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              credit_type: { type: 'string' },
              used: { type: 'number' },
              limit: { type: 'number' },
              created_at: { type: 'number' },
              expires_at: { type: 'number' },
              is_unlimited: { type: 'boolean' },
            },
          },
        },
        is_active: { type: 'boolean' },
        is_individual: { type: 'boolean' },
        recurring_price: { type: 'string' },
        stripe_customer_id: { type: 'string' },
        monthly_fair_limit: { type: 'number' },
        daily_fair_limit: { type: 'number' },
        plan_name: { type: 'string' },
        plan_is_custom: { type: 'boolean' },
        plan_is_unlimited: { type: 'boolean' },
        plan_is_paid_members_count_fixed: { type: 'boolean' },
        plan_selected_features: { type: 'array', items: { type: 'string' } },
        plan_paid_members_limit: { type: 'number' },
      },
    },
    name: { type: 'string' },
    members_count: { type: 'number' },
    approved_members_count: { type: 'number' },
    total_search_count: { type: 'number' },
    total_credits_used: { type: 'number' },
    current_search_count: { type: 'number' },
    current_credits_used: { type: 'number' },
    owner_email: { type: 'string' },
    owner_full_name: { type: 'string' },
    permissions: { type: 'string' },
  },
};
