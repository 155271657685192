import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { PlanType } from '@/features/common/billing';

import { PlanPrice } from './components/PlanPrice';
import { PlanActions, PlanCredits, PlanFeatures } from './components';
import { usePlanCardViewModel } from './PlanCardViewModel';

import styles from './styles.module.scss';

type PlanCardType = {
  variant: PlanType;
};

export const PlanCard: FC<PlanCardType> = ({ variant }) => {
  const {
    isPopular,
    name,
    description,
    perSeatPrice,
    totalPrice,
    action,
    onAction,
    features,
    featuresDetailed,
    credits,
    creditCardRequired,
  } = usePlanCardViewModel({
    planType: variant,
  });
  const { t } = useTranslation('plans');

  return (
    <article className={clsx(styles.planCard, { [styles.mostPopular]: isPopular })}>
      <Box className={styles.planCardTop}>
        {isPopular ? (
          <span className={clsx(styles.badge, styles.mostPopularBadge)}>
            {t('badges.mostPopular')}
          </span>
        ) : null}

        <Typography className={styles.planCardTitle}>{name}</Typography>
        <Typography className={styles.planCardSubtitle}>{description}</Typography>

        <PlanPrice perSeat={perSeatPrice} total={totalPrice} />
      </Box>

      <PlanCredits
        credits={credits}
        variant={variant === PlanType.Free ? 'free' : 'paid'}
      />

      <PlanActions
        action={{ type: action, onClick: onAction }}
        creditCardRequired={creditCardRequired}
      />

      <PlanFeatures features={features} featuresDetailed={featuresDetailed} />
    </article>
  );
};
