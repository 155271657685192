import { FC, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { Modal } from '@/components';

export const ProcessingModal: FC<{
  open: boolean;
  title: string;
  description?: string;
  processingText: string;
}> = ({ open, title, description, processingText }) => {
  const [dotsCount, setDotsCount] = useState(0);

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setDotsCount((prev) => (prev + 1) % 4);
      }, 500);

      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <Modal onClose={undefined} open={open}>
      <Stack direction="column" gap="35px" maxWidth="340px">
        <Stack direction="column" gap="15px">
          <Typography variant="h2">{title}</Typography>
          {description && (
            <Typography fontSize="14px" lineHeight="20px">
              {description}
            </Typography>
          )}
        </Stack>
        <Typography fontSize="13px" color="info">
          {processingText}
          {Array.from({ length: dotsCount }, () => '.').join('')}
        </Typography>
      </Stack>
    </Modal>
  );
};
