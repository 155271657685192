import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import styles from './styles.module.scss';

export interface IAddToListButtonProps {
  onClick: () => void;
  inputValue: string;
}

export const AddToListButton: FC<IAddToListButtonProps> = ({ onClick, inputValue }) => {
  const { t } = useTranslation('contacts');

  return (
    <Box className={styles.wrapper}>
      <Button onClick={onClick} disabled={!inputValue.trim()}>
        {t('subhead.moveToList.newListButton', {
          inputValue,
          interpolation: { escapeValue: false },
        })}
      </Button>
    </Box>
  );
};
