import { BaseSyntheticEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType, object } from 'yup';

import { useAccount } from '@/features/common/account';
import { useHelpCenterUseCase } from '@/features/system/helpCenter/ui';

import { NameValidationSchema, useFormWithSchema } from '@/utils/validation';

const AccountSettingsFormSchema = object({
  name: NameValidationSchema,
});

type AccountSettingsFormType = InferType<typeof AccountSettingsFormSchema>;

type UseAccountSettingsFormViewModel = (params: {
  onNameChange: (name: string) => Promise<void>;
}) => {
  form: UseFormReturn<AccountSettingsFormType>;
  isFormDisabled: boolean;
  email: string;
  onSupportContact: () => void;
  onSubmit: (e: BaseSyntheticEvent) => void;
};

export const useAccountSettingsFormViewModel: UseAccountSettingsFormViewModel = ({
  onNameChange,
}) => {
  const { t } = useTranslation('support', {
    keyPrefix: 'prePopulatedMessages',
  });
  const { account } = useAccount();
  const helpCenterUseCase = useHelpCenterUseCase();

  const name = account?.fullName || '';

  const form = useFormWithSchema(AccountSettingsFormSchema, {
    defaultValues: {
      name,
    },
  });

  useEffect(() => {
    form.resetField('name', {
      defaultValue: name,
    });
  }, [name]);

  const onSubmit = (e: BaseSyntheticEvent): void => {
    void form.handleSubmit(async ({ name: updatedName }: AccountSettingsFormType) => {
      await onNameChange(updatedName);
    })(e);
  };

  const onSupportContact = (): void => {
    helpCenterUseCase.showNewMessage(t('changeEmail'));
  };

  return {
    form,
    onSubmit,
    onSupportContact,
    isFormDisabled: form.formState.isSubmitting || !form.formState.isDirty,
    email: account?.email || '',
  };
};
