import { FC } from 'react';
import { Box, Button, InputBase, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const UsersCounter: FC<{ value: number; onChange: (value: number) => void }> = ({
  value,
  onChange,
}) => {
  function handleDecrement(): void {
    onChange(value - 1);
  }

  function handleIncrement(): void {
    onChange(value + 1);
  }

  return (
    <Box className={styles.usersCounter}>
      <Typography>How many users do you have?</Typography>
      <Box className={styles.buttons}>
        <Button
          variant="outlined"
          className={styles.button}
          color="info"
          onClick={handleDecrement}
          disabled={value === 1}
        >
          -
        </Button>
        <InputBase className={styles.input} value={value} />
        <Button
          variant="outlined"
          className={styles.button}
          color="info"
          onClick={handleIncrement}
          disabled={value === 100}
        >
          +
        </Button>
      </Box>
    </Box>
  );
};
