import { FC } from 'react';
import { Box } from '@mui/material';

import { Preview as BasePreview } from '@/components';

import styles from './styles.module.scss';

export const Preview: FC = () => {
  return (
    <Box className={styles.preview}>
      <Box className={styles.box}>
        <BasePreview />
      </Box>
      <Box className={styles.box}>
        <BasePreview />
      </Box>
      <Box className={styles.box}>
        <BasePreview />
      </Box>
    </Box>
  );
};
