import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IDashboardChartApiService } from '@/features/dashboard/features/chart/data/abstractions/IDashboardChartApiService';
import { ChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/ChartDataDC';
import { FetchChartDataReqDC } from '@/features/dashboard/features/chart/data/dataContracts/FetchChartDataReqDC';
import { IHttpClient } from '@/features/system/network';

@injectable()
export class DashboardChartApiService implements IDashboardChartApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  fetchChartData(req: FetchChartDataReqDC): Observable<ChartDataDC[]> {
    const params = new URLSearchParams(req).toString();

    return this.httpClient
      .get(`/api/v1/dashboard/stats?${params}`)
      .pipe(map((response: AxiosResponse<ChartDataDC[]>) => response.data));
  }
}
