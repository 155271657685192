import { FC, memo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { Container } from '@/components';

import { useEnrichmentTableViewModel } from './useEnrichmentTableViewModel';

import styles from './styles.module.scss';

export const EnrichmentTable: FC = memo(() => {
  const { noRowsOverlay, columns, rows, onSortModelChange } =
    useEnrichmentTableViewModel();

  return (
    <Box className={styles.Wrapper}>
      <Container className={styles.Container}>
        <DataGridPro
          columnHeaderHeight={60}
          variant="basic"
          editMode="cell"
          className={styles.Table}
          disableChildrenFiltering
          getRowId={(row) => row.uuid}
          classes={{
            columnHeaders: styles.ColumnHeader,
            row: styles.Row,
            cell: styles.Cell,
            root: styles.TableRoot,
          }}
          rowHeight={60}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableColumnFilter
          sortingMode="server"
          filterMode="server"
          rowsLoadingMode="server"
          hideFooterPagination
          disableColumnReorder
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: noRowsOverlay,
          }}
          onSortModelChange={onSortModelChange}
        />
      </Container>
    </Box>
  );
});
