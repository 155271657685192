import type { Components, Theme } from '@mui/material';

declare module '@mui/material' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    size: 'medium',
    fullWidth: true,
    multiline: false,
  },
  styleOverrides: {
    root({ theme, ownerState, isMatched }) {
      return {
        padding: ((): string => {
          if (ownerState.multiline) return '12px';

          return '0 12px';
        })(),
        ...(isMatched && {
          '&:not(.Mui-error)': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.success.main,
            },
          },
        }),
      };
    },
    input({ theme, ownerState }) {
      return {
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '0',
        height: ((): string => {
          if (ownerState.multiline) {
            return 'initial';
          }

          if (ownerState.size === 'small') {
            return '28px';
          }

          if (ownerState.size === 'large') {
            return '40px';
          }

          return '34px';
        })(),
        fontSize: ((): string => {
          if (ownerState.multiline) {
            return '14px';
          }

          if (ownerState.size === 'small') {
            return '11px';
          }

          if (ownerState.size === 'large') {
            return '14px';
          }

          return '13px';
        })(),
        minHeight: ((): string => {
          if (ownerState.multiline) {
            return '46px';
          }

          return 'initial';
        })(),
        '&::placeholder': {
          color: theme.palette.grey500,
        },
      };
    },
  },
};
