import { useMemo } from 'react';
import { AnimationEvent } from 'chart.js';

import { theme } from '@/theme';

export const useChartOptions = (datasetData: number[]) => {
  return useMemo(
    () => ({
      responsive: true,
      plugins: { legend: { display: false }, title: { display: false } },
      events: [],
      cutout: 135,
      radius: 100,
      rotation: -10,
      animation: {
        duration: 0.1,
        onComplete({ chart }: AnimationEvent): void {
          const {
            ctx,
            data: { datasets },
          } = chart;

          datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((arc, index) => {
              const { x, y } = arc.tooltipPosition(true);
              const isRightDirection = x >= ctx.canvas.width / 4;
              const isBottomDirection = y > ctx.canvas.height / 4;
              const circleRadius = 4;
              const lineDisplacement = isRightDirection ? 40 : -40;
              const textCoordinateX = isRightDirection
                ? x + lineDisplacement
                : x + lineDisplacement;
              const labelValue = dataset.data[index];

              if (labelValue) {
                const realLabelValue = datasetData[index].toString();

                ctx.font = `17px ${theme.typography.fontFamily}`;

                const additionalDisplacement = getLabelDisplacement(
                  realLabelValue,
                  isRightDirection,
                );

                // Draw circle
                ctx.beginPath();
                ctx.setLineDash([]);
                ctx.arc(x, y, circleRadius, 0, 2 * Math.PI);
                ctx.stroke();

                // Draw angled lines
                ctx.setLineDash([2, 2]);

                const yLinePosition = isBottomDirection ? y + 20 : y - 20;

                ctx.moveTo(
                  isRightDirection ? x + circleRadius : x - circleRadius,
                  isBottomDirection ? y + circleRadius : y - circleRadius,
                );
                ctx.lineTo(x + lineDisplacement / 2, yLinePosition);

                ctx.moveTo(x + lineDisplacement / 2, yLinePosition);
                ctx.lineTo(
                  x + lineDisplacement / 2 + (isRightDirection ? 32 : -32),
                  yLinePosition,
                );

                ctx.stroke();

                ctx.fillText(
                  realLabelValue,
                  textCoordinateX + additionalDisplacement,
                  yLinePosition - 3,
                );
              }
            });
          });
        },
      },
    }),
    [datasetData],
  );
};

const getLabelDisplacement = (label: string, isRightDirection: boolean) => {
  if (label.length === 1) return isRightDirection ? -9 : 2;

  const rightOffset = -14;
  const leftOffset = -4;

  return (label.length - 1) * (isRightDirection ? rightOffset : leftOffset);
};
