import * as React from "react";
const SvgGoogle = (props) => /* @__PURE__ */ React.createElement("svg", { "data-name": "Group 351", xmlns: "http://www.w3.org/2000/svg", width: 28, height: 28, viewBox: "0 0 28 28", ...props }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17479", width: 28, height: 28, rx: 4, style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10399", d: "m4.186 144.582-.658 2.455-2.4.051a9.461 9.461 0 0 1-.07-8.82l2.14.392.937 2.127a5.637 5.637 0 0 0 .053 3.8z", transform: "translate(4.554 -128.613)", style: {
  fill: "#fbbb00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10400", d: "M270.7 208.176a9.441 9.441 0 0 1-3.367 9.13l-2.695-.138-.381-2.381a5.629 5.629 0 0 0 2.422-2.874h-5.05v-3.736h9.071z", transform: "translate(-247.421 -195.941)", style: {
  fill: "#518ef8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10401", d: "M44.741 314.8a9.448 9.448 0 0 1-14.232-2.89l3.061-2.505a5.617 5.617 0 0 0 8.095 2.876z", transform: "translate(-24.829 -293.44)", style: {
  fill: "#28b446"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10402", d: "m43.042 2.174-3.06 2.5A5.617 5.617 0 0 0 31.7 7.62L28.625 5.1a9.447 9.447 0 0 1 14.417-2.926z", transform: "translate(-23.014 4.554)", style: {
  fill: "#f14336"
} }));
export default SvgGoogle;
