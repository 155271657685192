import { BroadcastChannel } from 'broadcast-channel';
import { inject, injectable, postConstruct } from 'inversify';
import { filter, firstValueFrom, map, Observable, Subject, switchMap, tap } from 'rxjs';

import { CONTACT_LIST_TYPES, PROSPECT_TASK_TYPES, SYNC_TYPES } from '@/ioc/types';

import {
  IContactListEntity,
  IContactListRepository,
} from '@/features/common/contactList';
import { IBaseSyncRepository } from '@/features/system/sync';

import {
  IProspectTaskProgressEntity,
  IProspectTaskRepository,
  ProspectTaskRetryResponse,
} from '../domain';

import { IProspectTaskDC, ProspectTaskEnrichmentType } from './dataContract';
import { IProspectTaskApiService } from './network';

const CHANNEL_NAME = 'prospectTaskProgress';

enum ChannelMessageType {
  Default = 'default',
  Cleanup = 'clear',
}

type ChannelMessageDefault = {
  type: ChannelMessageType.Default;
  message: IProspectTaskDC;
  createdAt: number;
};

type ChannelMessageCleanup = {
  type: ChannelMessageType.Cleanup;
  createdAt: number;
};

type ChannelMessage = ChannelMessageDefault | ChannelMessageCleanup;

@injectable()
export class ProspectTaskRepository implements IProspectTaskRepository {
  @inject(SYNC_TYPES.BaseSyncRepository)
  private readonly baseSyncRepository: IBaseSyncRepository;

  @inject(CONTACT_LIST_TYPES.ContactListRepository)
  private readonly contactListRepository: IContactListRepository;

  @inject(PROSPECT_TASK_TYPES.ProspectTaskApiService)
  private readonly prospectTaskApiService: IProspectTaskApiService;

  private readonly channel: BroadcastChannel = new BroadcastChannel(CHANNEL_NAME); // To share data between tabs
  private messageSubject: Subject<ChannelMessage> = new Subject();

  constructor() {
    this.channel.addEventListener('message', (event: ChannelMessage): void => {
      this.messageSubject.next(event);
    });
  }

  @postConstruct()
  subscribeOnProspectTaskEvents(): void {
    this.baseSyncRepository
      .getProspectTaskEvents()
      .pipe(
        filter(
          (event) => event.entity.enrichment_type === ProspectTaskEnrichmentType.Full,
        ),
        tap((event) => {
          const nextValue = {
            type: ChannelMessageType.Default,
            message: event.entity,
            createdAt: Date.now(),
          };
          this.messageSubject.next(nextValue);
          this.channel.postMessage(nextValue);
        }),
      )
      .subscribe();
  }

  private getDefaultMessages(): Observable<ChannelMessage> {
    return this.messageSubject
      .asObservable()
      .pipe(filter((message) => message.type === ChannelMessageType.Default));
  }

  private getCleanupMessages(): Observable<ChannelMessage> {
    return this.messageSubject
      .asObservable()
      .pipe(filter((message) => message.type === ChannelMessageType.Cleanup));
  }

  public getCleanupIndicator(): Observable<number> {
    return this.getCleanupMessages().pipe(map((message) => message.createdAt));
  }

  public sendCleanupEvent(): void {
    this.channel.postMessage({
      type: ChannelMessageType.Cleanup,
      createdAt: Date.now(),
    });
  }

  public getProspectTaskProgress(): Observable<IProspectTaskProgressEntity> {
    return this.getDefaultMessages().pipe(
      switchMap(({ message: prospectTask }: ChannelMessageDefault) => {
        return this.contactListRepository
          .getContactListById(prospectTask.contact_list_id)
          .pipe(
            filter((contactList): contactList is IContactListEntity => !!contactList),
            map((contactList) => {
              return {
                uuid: prospectTask.uuid,
                status: prospectTask.status,
                contactList: {
                  uuid: contactList.uuid,
                  name: contactList.name,
                },
                enrichmentTotalCount: prospectTask.enrichment_data.length,
                enrichmentCompletedCount: prospectTask.enrichment_result?.length || 0,
              } satisfies IProspectTaskProgressEntity;
            }),
          );
      }),
    );
  }

  public retry(taskId: string): Promise<ProspectTaskRetryResponse> {
    return firstValueFrom(
      this.prospectTaskApiService.retry(taskId).pipe(
        map((response) => {
          return {
            taskId: response.task_id,
          };
        }),
      ),
    );
  }
}
