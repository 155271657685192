import { FC } from 'react';

import { SplashScreen } from '@/components/SplashScreen';

import { CanBuyProvider, PaymentDetailsProvider, PaymentFormProvider } from './contexts';
import { PaymentDetails as PaymentDetailsInner } from './PaymentDetails';

export const PaymentDetails: FC = () => {
  return (
    <PaymentDetailsProvider fallback={<SplashScreen />}>
      <CanBuyProvider>
        <PaymentFormProvider>
          <PaymentDetailsInner />
        </PaymentFormProvider>
      </CanBuyProvider>
    </PaymentDetailsProvider>
  );
};
