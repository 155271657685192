import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import LogoIcon from '@/assets/icons/logo.svg?react';
import MobileScreenImage from '@/assets/images/MobileScreen.webp';

import styles from './styles.module.scss';

const BACKGROUND_LINES = Array.from(Array(10).keys());

export const MobileScreen: FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.logoWrapper}>
        <LogoIcon />
      </Box>
      <Box className={styles.container}>
        <Box className={styles.background}>
          <Box className={styles.lineBackgroundRoot}>
            {BACKGROUND_LINES.map((item) => (
              <Box className={styles.lineBackgroundCol} key={item} />
            ))}
          </Box>
        </Box>
        <img src={MobileScreenImage} alt={''} />
        <Typography variant={'h1'} className={styles.title}>
          {t('mobileScreen.title')}
        </Typography>
        <Typography variant={'h3'} className={styles.description}>
          {t('mobileScreen.description')}
        </Typography>
      </Box>
    </Box>
  );
};
