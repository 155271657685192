import type { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      borderRadius: '6px',
      boxShadow: '0px 3px 6px 0 #d8dfea',
      backgroundColor: '#000',
      padding: '7px',
      fontSize: '11px',
    },
    arrow: {
      color: '#000',
    },
  },
};
