import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useCurrentWorkspace } from '@/features/common/workspace';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const InviteTeamMember: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation('common', { keyPrefix: 'callToActions' });
  const navigate = useNavigate();

  const { data } = useCurrentWorkspace();

  const isWorkspaceFull =
    data?.subscription.planPaidMembersLimit &&
    data?.billableMembersCount <= data?.subscription.planPaidMembersLimit;

  const inviteMembersButtonText = isWorkspaceFull
    ? t('manageMembers')
    : t('inviteTeamMembers');

  const handleClick = (): void => {
    const options = isWorkspaceFull
      ? undefined
      : {
          state: { isInviteTeamMemberModalOpen: true },
        };

    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, options);
    onClick?.();
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={props.text ?? inviteMembersButtonText}
    />
  );
};
