import { inject, injectable } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { firstValueFrom, map } from 'rxjs';

import { ACCOUNT_TYPES, SYNC_TYPES } from '@/ioc/types';

import { IAccountServicesApi } from '@/features/common/account';
import { AccountDocument, CollectionName } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers/mapBaseSyncEntity';
import { IBaseSyncRepository } from '@/features/system/sync';

@injectable()
export class AccountReplicationService extends ReplicationService<AccountDocument> {
  @inject(ACCOUNT_TYPES.AccountApiService)
  private apiService: IAccountServicesApi;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Account,
      pullStreamFactory: () => {
        return this.syncRepository
          .getAccountEvents()
          .pipe(map(mapBaseSyncEntity<AccountDocument>));
      },
      pushHandlers: {
        update: (docs) => this.syncUpdate(docs),
      },
    });
  }

  private syncUpdate(
    docsToUpdate: RxReplicationWriteToMasterRow<AccountDocument>[],
  ): Promise<AccountDocument> {
    const accountDocument = docsToUpdate[0].newDocumentState;
    return firstValueFrom(
      this.apiService
        .updateAccount(accountDocument)
        .pipe(map((account) => ({ ...account, _deleted: false }))),
    );
  }
}
