import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { FormField, Modal, PasswordInput, PasswordValidationTips } from '@/components';

import usePasswordDialogViewModel from './usePasswordDialogViewModel';

import styles from './styles.module.scss';

interface IPasswordDialog {
  askCurrentPassword?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onPasswordChange: (password: string, currentPassword?: string) => Promise<void>;
}

export const PasswordDialog: FC<IPasswordDialog> = ({
  askCurrentPassword = true,
  isOpen,
  onClose,
  onPasswordChange,
}) => {
  const { t } = useTranslation(['auth', 'settings']);
  const { t: validationTranslate } = useTranslation('validation');
  const { isSubmitDisabled, handleSubmit, form } = usePasswordDialogViewModel({
    isOpen,
    onClose,
    onPasswordChange,
    askCurrentPassword,
  });

  return (
    <Modal open={isOpen} onClose={onClose} containerClass={styles.container}>
      <>
        <Typography className={styles.title} variant={'h1'}>
          {t('settings:account.emailPasswordChangeDialog.passwordTitle')}
        </Typography>
        <Box className={styles.formContainer}>
          {askCurrentPassword ? (
            <Box className={styles.inputContainer}>
              <FormField
                error={!!form.formState.errors.currentPassword}
                errorText={validationTranslate(
                  form.formState.errors.currentPassword?.message || '',
                )}
                labelText={t(
                  'settings:account.emailPasswordChangeDialog.currentPassword',
                )}
              >
                <PasswordInput
                  disabled={form.formState.isSubmitting}
                  className={styles.input}
                  {...form.register('currentPassword')}
                />
              </FormField>
            </Box>
          ) : null}

          <Box className={styles.inputContainer}>
            <FormField
              error={!!form.formState.errors.newPassword}
              errorText={validationTranslate(
                form.formState.errors.newPassword?.message || '',
              )}
              labelText={t('settings:account.emailPasswordChangeDialog.newPassword')}
            >
              <PasswordInput
                disabled={form.formState.isSubmitting}
                className={styles.input}
                {...form.register('newPassword')}
              />
            </FormField>
          </Box>
          <Box className={styles.inputContainer}>
            <FormField
              error={!!form.formState.errors.confirmPassword}
              errorText={validationTranslate(
                form.formState.errors.confirmPassword?.message || '',
              )}
              labelText={t('settings:account.emailPasswordChangeDialog.confirmPassword')}
            >
              <PasswordInput
                disabled={form.formState.isSubmitting}
                className={styles.input}
                {...form.register('confirmPassword')}
              />
            </FormField>
          </Box>

          <PasswordValidationTips password={form.watch('newPassword')} />

          <Box className={styles.actionsContainer}>
            <Button variant={'text'} className={styles.cancelButton} onClick={onClose}>
              {t('common:cancel')}
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t('settings:account.emailPasswordChangeDialog.saveButtonPassword')}
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};
