import { map } from 'rxjs';

import { useInjection } from '@/ioc';
import { WORKSPACE_TYPES } from '@/ioc/types';

import type { IWorkspaceUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

type UseTeamMembersCardViewModelReturn = {
  teamMembers: number;
  isLoading: boolean;
};

export const useTeamMembersCardViewModel = (): UseTeamMembersCardViewModelReturn => {
  const workspaceUseCase = useInjection<IWorkspaceUseCase>(
    WORKSPACE_TYPES.WorkspaceUseCase,
  );

  const { data: teamMembers, isLoading } = useObservableResult(
    () =>
      workspaceUseCase
        .getCurrentWorkspace()
        .pipe(map((workspace) => workspace?.approvedMembersCount ?? 1)),
    { defaultData: 1 },
  );

  return {
    teamMembers,
    isLoading,
  };
};
