import { injectable } from 'inversify';

import { DbCollectionCreator } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { ChartDataDC } from '../dataContracts/ChartDataDC';

import { dashboardChartMigrationStrategies } from './migrations/migrations';
import { dashboardChartSchema } from './schema/dashboardChartSchema';

@injectable()
export class DashboardChartCollectionCreator extends DbCollectionCreator<ChartDataDC> {
  constructor() {
    super({
      collectionName: CollectionName.DashboardChart,
      schema: dashboardChartSchema,
      migrationStrategies: dashboardChartMigrationStrategies,
    });
  }
}
