import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import styles from '../styles.module.scss';

type ChartLegendItemProps = {
  title: string;
  value: number;
};

export const ChartLegendItem: FC<ChartLegendItemProps> = ({ title, value }) => {
  return (
    <Box className={styles.chartLegendItem}>
      <Typography variant="h3" color="info">
        {title}
      </Typography>
      <Typography variant="h1">{value}</Typography>
    </Box>
  );
};
