import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RemoveIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M6 0a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6zm0 11.25A5.25 5.25 0 1 1 11.25 6 5.256 5.256 0 0 1 6 11.25zm2.14-6.86L6.53 6l1.61 1.61a.375.375 0 1 1-.53.53L6 6.53 4.39 8.14a.375.375 0 0 1-.53-.53L5.47 6 3.86 4.39a.375.375 0 0 1 .53-.53L6 5.47l1.61-1.61a.375.375 0 0 1 .53.53z"
      transform="translate(3 3)"
    />
  </SvgIcon>
);
