import { FC } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { ArrowDownIcon } from '@/components/Icons';

import { useCollapsable } from '@/hooks';

import styles from './styles.module.scss';

export const QuestionBlock: FC<{
  question: string;
  answer: string;
  isExpanded: boolean;
  onClick: () => void;
}> = (props) => {
  const collapsable = useCollapsable<HTMLDivElement>(props.isExpanded);

  return (
    <div className={clsx(styles.question, { [styles.isExpanded]: props.isExpanded })}>
      <button onClick={props.onClick} className={styles.questionHeader}>
        <Typography variant="h3">{props.question}</Typography>
        <div className={clsx(styles.icon, { [styles.isExpanded]: props.isExpanded })}>
          <ArrowDownIcon />
        </div>
      </button>
      <div className={styles.questionBody} ref={collapsable.ref}>
        {props.answer.split('\n').map((answer, index) => (
          <Typography key={index} className={styles.answer}>
            {answer}
          </Typography>
        ))}
      </div>
    </div>
  );
};
