import { inject, injectable } from 'inversify';
import { catchError, combineLatest, map, Observable, of } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceRepository } from '../data/abstractions/WorksapceRepository';

import { ICreditUseCase } from './abstractions';
import {
  IAggregatedCreditEntity,
  ICreditEntity,
  IWorkspaceSubscriptionEntity,
} from './entities';

@injectable()
export class CreditUseCase implements ICreditUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  private getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workspaceRepository.getCurrentWorkspaceSubscription();
  }

  private getCreditsByType(
    creditType: ICreditEntity['creditType'],
  ): Observable<ICreditEntity[]> {
    return this.workspaceRepository
      .getAllCredits()
      .pipe(
        map((credits) => credits.filter((credit) => credit.creditType === creditType)),
      );
  }

  public getFullCredits(): Observable<ICreditEntity[]> {
    return this.getCreditsByType('full');
  }

  public getBulkCredits(): Observable<ICreditEntity[]> {
    return this.getCreditsByType('bulk');
  }

  public getFullAggregatedCreditsInfo(): Observable<Nullable<IAggregatedCreditEntity>> {
    return this.getSubscription().pipe(
      map((subscription) => {
        return (
          subscription.credits.find((credit) => credit.creditType === 'full') || null
        );
      }),
    );
  }

  public getBulkAggregatedCreditsInfo(): Observable<Nullable<IAggregatedCreditEntity>> {
    return this.getSubscription().pipe(
      map((subscription) => {
        return (
          subscription.credits.find((credit) => credit.creditType === 'bulk') || null
        );
      }),
    );
  }

  public fullAggregatedCreditsExist(): Observable<boolean> {
    return this.getBulkAggregatedCreditsInfo().pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public bulkAggregatedCreditsExist(): Observable<boolean> {
    return this.getBulkAggregatedCreditsInfo().pipe(map((value) => !!value));
  }

  public getAggregatedCreditsProgress(): Observable<number> {
    return combineLatest({
      fullInfo: this.getFullAggregatedCreditsInfo(),
      bulkInfo: this.getBulkAggregatedCreditsInfo(),
    }).pipe(
      map(({ fullInfo, bulkInfo }) => {
        if (!fullInfo) {
          return 0;
        }

        if (fullInfo.isUnlimited) {
          return 100;
        }

        const usedTotal = fullInfo.used + (bulkInfo?.used || 0);
        const limitTotal = fullInfo.limit + (bulkInfo?.limit || 0);

        return (usedTotal / limitTotal) * 100;
      }),
    );
  }
}
