import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ROUTES } from '@/router/routes';

import { Permission, usePermissions } from '@/features/common/permissions';
import { SettingsViewModelReturn } from '@/features/settings/ui/Settings.types';

type TabConfig = {
  to: string;
  titleTranslationKey: string;
  permission?: Permission;
};

const TABS_CONFIG: TabConfig[] = [
  {
    to: ROUTES.SETTINGS.INDEX,
    titleTranslationKey: 'tabs.accountTitle',
  },
  {
    to: ROUTES.SETTINGS.TEAM_MANAGEMENT,
    titleTranslationKey: 'tabs.teamManagementTitle',
  },
  {
    to: ROUTES.SETTINGS.SUBSCRIPTION,
    titleTranslationKey: 'tabs.subscriptionandBillingTitle',
    permission: Permission.CanAccessBilling,
  },
  {
    to: ROUTES.SETTINGS.TAG_MANAGEMENT,
    titleTranslationKey: 'tabs.tagManagementTitle',
  },
  // {
  //   to: ROUTES.SETTINGS.REFERRAL,
  //   titleTranslationKey: 'tabs.referral',
  //   permission: Permission.CanAccessRefferal,
  // },
];

const useSettingsViewModel = (): SettingsViewModelReturn => {
  const { t } = useTranslation('settings');
  const { pathname } = useLocation();

  const isAccountTab = pathname === ROUTES.SETTINGS.INDEX;
  const isTeamManagementTab = pathname === ROUTES.SETTINGS.TEAM_MANAGEMENT;
  const isSubscriptionTab = pathname === ROUTES.SETTINGS.SUBSCRIPTION;
  const isTagManagementTab = pathname === ROUTES.SETTINGS.TAG_MANAGEMENT;

  const { hasPermissions } = usePermissions();

  const headerData = useMemo(() => {
    return TABS_CONFIG.filter((tab: TabConfig) => {
      if (tab.permission) {
        return hasPermissions(tab.permission);
      }
      return true;
    }).map((tab: TabConfig) => ({
      title: t(tab.titleTranslationKey),
      isActive: pathname === tab.to,
      to: tab.to,
    }));
  }, [hasPermissions, t, pathname]);

  return {
    tabs: {
      isAccountTab,
      isTeamManagementTab,
      isSubscriptionTab,
      isTagManagementTab,
    },
    headerData,
  };
};

export default useSettingsViewModel;
