import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@mui/material';
import clsx from 'clsx';

import { ActionDisableGuard } from '@/features/common/permissions';

import { MenuItem } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import { usePermissionsButtonViewModel } from './usePermissionsButtonViewModel';

import styles from './styles.module.scss';

export const PermissionsButton: React.FC = () => {
  const { t } = useTranslation('settings');
  const { anchorElement, onClick, onClose, canAccessAllContacts, onChange } =
    usePermissionsButtonViewModel();

  return (
    <>
      <ActionDisableGuard>
        <Button
          className={clsx(styles.PermissionsButton, {
            [styles.Opened]: !!anchorElement,
          })}
          variant="outlined"
          color="info"
          size={'small'}
          endIcon={<ArrowDownIcon />}
          onClick={onClick}
        >
          {t('teamManagement.accessPermissionsButton')}
        </Button>
      </ActionDisableGuard>
      <Popover
        onClose={onClose}
        anchorOrigin={{
          horizontal: -140,
          vertical: 'bottom',
        }}
        classes={{ paper: styles.PermissionsList }}
        open={!!anchorElement}
        anchorEl={anchorElement}
      >
        <MenuItem
          selected={canAccessAllContacts.data === false}
          onClick={(): void => {
            onChange('own');
          }}
        >
          {t('teamManagement.accessPermissionsButtonOwnText')}
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            onChange('all');
          }}
          selected={canAccessAllContacts.data === true}
        >
          {t('teamManagement.accessPermissionsButtonAllText')}
        </MenuItem>
      </Popover>
    </>
  );
};
