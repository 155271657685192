import { FC, ImgHTMLAttributes, ReactElement, useEffect, useState } from 'react';

import { Preview } from '../preview';

type LazyImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  fallback?: ReactElement;
};

const defaultImgState = {
  loading: true,
  success: false,
  error: false,
};

export const LazyImage: FC<LazyImageProps> = ({ fallback, src, ...imageProps }) => {
  const [imgState, setImgState] = useState(defaultImgState);

  useEffect(() => {
    const setLoaded = (): void => {
      setImgState({ loading: false, success: true, error: false });
    };

    const setFailed = (): void => {
      setImgState({ loading: false, success: false, error: true });
    };

    if (!src) {
      setFailed();
      return;
    }

    setImgState(defaultImgState);
    const img = new Image();

    const abourtController = new AbortController();

    img.addEventListener(
      'load',
      () => {
        setLoaded();
      },
      { signal: abourtController.signal },
    );

    img.addEventListener(
      'error',
      (e) => {
        setFailed();

        if (imageProps.onError) {
          // TODO: find a way to transfrorm native event to React SyntheticEvent
          // @ts-ignore
          imageProps.onError(e);
        }
      },
      { signal: abourtController.signal },
    );

    img.src = src;

    return () => {
      abourtController.abort();
    };
  }, [src]);

  if (imgState.loading) {
    return fallback || <Preview />;
  }

  if (imgState.error) {
    // TODO need to add default preview
    return null;
  }

  return <img src={src} {...imageProps} />;
};
