import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import type { IReferralApiService } from '../abstractions/IReferralApiService';
import type { IReferralEmailRequestData } from '../dataContracts/IReferralRequestData';
import type { IReferralResponseDataDC } from '../dataContracts/IReferralResponseDataDC';

const ApiUrl = {
  default: '/api/v1/invitations',
  validate: '/api/v1/invitations/validate',
};

@injectable()
export class ReferralApiService implements IReferralApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  sendInvitationEmail(
    data: IReferralEmailRequestData[],
  ): Observable<IReferralResponseDataDC[]> {
    return this.httpClient
      .post(ApiUrl.default, data)
      .pipe(map((response: AxiosResponse<IReferralResponseDataDC[]>) => response.data));
  }

  removeReferral(data: string[]): Observable<boolean> {
    return this.httpClient
      .delete(ApiUrl.default, data)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }

  validateToken(token: string): Observable<boolean> {
    return this.httpClient
      .post(`${ApiUrl.validate}`, { referrer_token: token })
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
}
