import * as React from "react";
const SvgIcon500 = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 286.148, height: 256.202, viewBox: "0 0 286.148 256.202", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17984", transform: "translate(-575.852 -212.361)" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18317", transform: "translate(788 284.563)", style: {
  strokeWidth: 2,
  stroke: "#8083a3",
  strokeDasharray: 8,
  fill: "none"
} }, /* @__PURE__ */ React.createElement("rect", { width: 74, height: 79, rx: 10, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 72, height: 77, rx: 9, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18318", transform: "translate(579 352.563)", style: {
  strokeWidth: 2,
  stroke: "#8083a3",
  strokeDasharray: 8,
  fill: "none"
} }, /* @__PURE__ */ React.createElement("rect", { width: 74, height: 79, rx: 10, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 72, height: 77, rx: 9, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18319", width: 175, height: 174, rx: 4, transform: "translate(635 294.563)", style: {
  fill: "#ddd"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Path 32128", style: {
  fill: "#e4e6e8"
} }, /* @__PURE__ */ React.createElement("path", { d: "M171 173H4c-1.654 0-3-1.346-3-3V4c0-1.654 1.346-3 3-3h167c1.654 0 3 1.346 3 3v166c0 1.654-1.346 3-3 3z", style: {
  stroke: "none"
}, transform: "translate(635 292.563)" }), /* @__PURE__ */ React.createElement("path", { d: "M4 2c-1.103 0-2 .897-2 2v166c0 1.103.897 2 2 2h167c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H4m0-2h167a4 4 0 0 1 4 4v166a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z", style: {
  fill: "#4a4e80",
  stroke: "none"
}, transform: "translate(635 292.563)" })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17980", transform: "translate(-2769 -9800.438)" }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18314", width: 214, height: 58, rx: 10, transform: "translate(3383 10130)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18312", transform: "translate(3383 10128)", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("rect", { width: 214, height: 58, rx: 10, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 212, height: 56, rx: 9, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 102", cx: 11, cy: 11, r: 11, transform: "translate(3558 10146)", style: {
  fill: "#8083a3"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18313", width: 66, height: 4, rx: 2, transform: "translate(3484 10155)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18315", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("path", { d: "M8 0h88a8 8 0 0 1 8 8v5H0V8a8 8 0 0 1 8-8z", style: {
  stroke: "none"
}, transform: "translate(3403 10173)" }), /* @__PURE__ */ React.createElement("path", { d: "M8 1h88a7 7 0 0 1 7 7v4H1V8a7 7 0 0 1 7-7z", style: {
  fill: "none"
}, transform: "translate(3403 10173)" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17981", transform: "translate(-2769 -9731.438)" }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18314", width: 214, height: 58, rx: 10, transform: "translate(3383 10130)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18312", transform: "translate(3383 10128)", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("rect", { width: 214, height: 58, rx: 10, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 212, height: 56, rx: 9, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 102", cx: 11, cy: 11, r: 11, transform: "translate(3558 10146)", style: {
  fill: "#8083a3"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18313", width: 66, height: 4, rx: 2, transform: "translate(3484 10155)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18315", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("path", { d: "M8 0h88a8 8 0 0 1 8 8v5H0V8a8 8 0 0 1 8-8z", style: {
  stroke: "none"
}, transform: "translate(3403 10173)" }), /* @__PURE__ */ React.createElement("path", { d: "M8 1h88a7 7 0 0 1 7 7v4H1V8a7 7 0 0 1 7-7z", style: {
  fill: "none"
}, transform: "translate(3403 10173)" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17982", transform: "translate(-2769 -9869.438)" }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18314", width: 214, height: 58, rx: 10, transform: "translate(3383 10130)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18312", transform: "translate(3383 10128)", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("rect", { width: 214, height: 58, rx: 10, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 212, height: 56, rx: 9, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 102", cx: 11, cy: 11, r: 11, transform: "translate(3558 10146)", style: {
  fill: "#8083a3"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18313", width: 66, height: 4, rx: 2, transform: "translate(3484 10155)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18315", style: {
  stroke: "#4a4e80",
  fill: "#fff",
  strokeWidth: 2
} }, /* @__PURE__ */ React.createElement("path", { d: "M8 0h88a8 8 0 0 1 8 8v5H0V8a8 8 0 0 1 8-8z", style: {
  stroke: "none"
}, transform: "translate(3403 10173)" }), /* @__PURE__ */ React.createElement("path", { d: "M8 1h88a7 7 0 0 1 7 7v4H1V8a7 7 0 0 1 7-7z", style: {
  fill: "none"
}, transform: "translate(3403 10173)" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 17983" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(575.853 174.14)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32125", d: "M242.419 197.459a2.384 2.384 0 0 1-2.384-2.384v-16.687a2.384 2.384 0 0 1 4.768 0v16.687a2.384 2.384 0 0 1-2.384 2.384z", transform: "translate(-204.272 -115.684)", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32126", d: "M75.285 93.744 45.607 44.281a8.71 8.71 0 0 0-14.92 0L1.009 93.744a7.06 7.06 0 0 0 6.046 10.678h62.184a7.06 7.06 0 0 0 6.046-10.678zm-6.046 5.911H7.055A2.287 2.287 0 0 1 5.1 96.2l29.675-49.467a3.937 3.937 0 0 1 6.743 0L71.2 96.2a2.287 2.287 0 0 1-1.961 3.454z", style: {
  fill: "#e4e6e8"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 103", cx: 3.576, cy: 3.576, r: 3.576, transform: "translate(34.571 84.159)", style: {
  fill: "#e4e6e8"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32127", d: "m3242.895 9988.429-4.59 2.212-26.9 45.324-4.029 6.32v3.679l7.65 1.665s57.685-1.923 58.578-1.665a22.1 22.1 0 0 0 4.183 0v-5.222l-6.085-9-24.311-41.1z", transform: "translate(-2628.895 -9773.223)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "warning", transform: "translate(575.853 172.295)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32125", d: "M242.419 197.459a2.384 2.384 0 0 1-2.384-2.384v-16.687a2.384 2.384 0 0 1 4.768 0v16.687a2.384 2.384 0 0 1-2.384 2.384z", transform: "translate(-204.272 -115.684)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32126", d: "M75.285 93.744 45.607 44.281a8.71 8.71 0 0 0-14.92 0L1.009 93.744a7.06 7.06 0 0 0 6.046 10.678h62.184a7.06 7.06 0 0 0 6.046-10.678zm-6.046 5.911H7.055A2.287 2.287 0 0 1 5.1 96.2l29.675-49.467a3.937 3.937 0 0 1 6.743 0L71.2 96.2a2.287 2.287 0 0 1-1.961 3.454z", style: {
  fill: "#4a4e80"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 103", cx: 3.576, cy: 3.576, r: 3.576, transform: "translate(34.571 84.159)", style: {
  fill: "#ff5001"
} })))));
export default SvgIcon500;
