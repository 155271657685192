import { Container } from 'inversify';

import { RESET_PASSWORD_TYPES } from '@/ioc/types';

import {
  IResetPasswordUseCases,
  ResetPasswordUseCases,
} from '../domain/ResetPasswordUseCases';

export const bindResetPasswordModule = (container: Container): void => {
  container
    .bind<IResetPasswordUseCases>(RESET_PASSWORD_TYPES.ResetPasswordUseCases)
    .to(ResetPasswordUseCases);
};
