import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { TagApplier } from '@/features/common/tag';

import { ArrowDownIcon } from '@/components/Icons';

import { useFilterByTagActionViewModel } from './useFilterByTagActionViewModel';

import styles from './styles.module.scss';

export const FilterByTagAction: FC = () => {
  const { selectedCount, onApply, defaultValue } = useFilterByTagActionViewModel();
  const { t } = useTranslation('contacts');

  return (
    <Box className={styles.Wrapper}>
      <TagApplier
        defaultValue={defaultValue}
        onApply={onApply}
        renderAnchor={({ setAnchorElement, isOpen }): ReactElement => {
          return (
            <Box
              className={styles.Anchor}
              onClick={(e): void => {
                setAnchorElement((prev) => (prev ? null : e.currentTarget));
              }}
            >
              {Boolean(selectedCount) && (
                <Box component={'span'} className={styles.AnchorCounter}>
                  {selectedCount}
                </Box>
              )}
              <Box component="span" className={styles.AnchorText}>
                {t('subhead.tagsFilter.selectField.value')}
              </Box>
              <Box
                className={clsx(styles.AnchorIcon, {
                  [styles.opened]: isOpen,
                })}
              >
                <ArrowDownIcon />
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};
