import { ChartOptions } from 'chart.js';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import {
  useChartHistoryEvents,
  useChartTooltipCallbacks,
  useSuggestedChartValues,
} from '../../../hooks';
import { AnalyticsChartProps } from '../AnalyticsChart';

import { useChartTimeScaleSettings } from './useChartTimeScaleSettings';

import { theme } from '@/theme';

export const HISTORY_EVENTS_DATASET_INDEX = 1;

export const useChartData = ({
  selectedDate,
  tooltipTitles,
  labels,
  creditsUsed,
  values,
  selectedUser,
}: AnalyticsChartProps) => {
  const commonTicks = {
    font: {
      family: 'SofiaPro',
      size: 12,
    },
    color: theme.palette.info.main,
    padding: 10,
  };

  const isRichTooltip =
    selectedDate === DATE_SELECT_OPTION_VALUES.yesterday ||
    selectedDate === DATE_SELECT_OPTION_VALUES.today;
  const { suggestedMax, suggestedMin } = useSuggestedChartValues({ values, creditsUsed });

  const { values: historyValues } = useChartHistoryEvents({
    selectedDate,
    selectedUser,
  });

  const { label, title } = useChartTooltipCallbacks({
    selectedDate,
    tooltipTitles,
    historyValues,
  });

  const timeScaleSettings = useChartTimeScaleSettings({ selectedDate });

  const fontData = {
    family: 'SofiaPro',
    size: 11,
    weight: 400,
  };

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: fontData,
        titleAlign: isRichTooltip ? 'center' : 'left',
        titleMarginBottom: 0,
        bodyFont: fontData,
        bodyAlign: isRichTooltip ? 'center' : 'left',
        displayColors: false,
        padding: 10,
        callbacks: {
          label,
          title,
        },
        animation: {
          duration: 0,
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: theme.palette.info.disabled,
        },
        border: {
          display: false,
        },
        ticks: {
          ...commonTicks,
          align: labels.length > 1 ? 'end' : 'center',
        },
        offset: true,
        type: 'time',
        time: timeScaleSettings,
      },
      y: {
        grid: {
          color: theme.palette.info.disabled,
          tickColor: 'white',
        },
        border: {
          display: false,
        },
        ticks: {
          ...commonTicks,
          callback: (value): Nullable<string> => {
            return Number.isInteger(value) ? value.toString() : null;
          },
          maxTicksLimit: 6,
        },
        suggestedMin,
        suggestedMax,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'index',
    },
  };

  const data = {
    datasets: [
      {
        data: values,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.disabled,
        fill: 'origin',
        order: 1,
      },
      {
        data: historyValues,
        fill: 'origin',
        borderColor: 'transparent',
        backgroundColor: theme.palette.primary.main,
        order: 0,
      },
    ],
  };

  return { data, options };
};
