import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PlansListProps = {
  children: ReactNode;
};

export const PlansList: FC<PlansListProps> = ({ children }) => {
  return <div className={styles.plansList}>{children}</div>;
};
