import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

export enum PreviewVariant {
  LIGHT = 'light',
  MEDIUM = 'medium',
  DARK = 'dark',
}

export type PreviewProps = {
  variant?: PreviewVariant;
};

export const Preview: FC<PropsWithChildren<PreviewProps>> = ({
  variant = PreviewVariant.LIGHT,
  children,
}) => {
  return (
    <Box
      className={clsx(styles.preview, {
        [styles.previewLight]: variant === PreviewVariant.LIGHT,
        [styles.previewMedium]: variant === PreviewVariant.MEDIUM,
        [styles.previewDark]: variant === PreviewVariant.DARK,
      })}
    >
      {children}
    </Box>
  );
};
