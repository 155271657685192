import { Container } from 'inversify';

import { ONBOARDING_TYPES } from '@/ioc/types';

import { IOnboardingUseCase } from '../domain/abstractions';
import { OnboardingUseCase } from '../domain/OnboardingUseCase';

export const bindOnboardingModule = (container: Container): void => {
  container
    .bind<IOnboardingUseCase>(ONBOARDING_TYPES.OnboardingUseCase)
    .to(OnboardingUseCase);
};
