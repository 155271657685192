import { useMemo } from 'react';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IContactByFiltersEntity } from '../../domain';

import { useContactByFiltersUseCase } from './useContactByFiltersUseCase';

export const useContactByFilters = (
  id: string,
): ObservableResult<IContactByFiltersEntity | null> => {
  const contactByFilterUseCase = useContactByFiltersUseCase();
  const getById$ = useMemo(() => {
    return contactByFilterUseCase.getById(id);
  }, [id]);
  return useObservableResult(getById$);
};
