import { useState } from 'react';

export const useTagApplierViewModel = () => {
  const [anchorElement, setAnchorElement] = useState<Nullable<HTMLElement>>(null);

  return {
    anchorElement,
    setAnchorElement,
    isOpen: Boolean(anchorElement),
    onClose: (): void => setAnchorElement(null),
  };
};
