import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { EmptyHeader } from '@/components';

import leavingSoon from './assets/leavingSoon.webp';
import { useLeavingSoonViewModel } from './LeavingSoonViewModel';

import styles from './styles.module.scss';

export const LeavingSoon: FC = () => {
  const { t } = useTranslation('retention', { keyPrefix: 'leavingSoon' });
  const { onAcceptClick, onRejectClick } = useLeavingSoonViewModel();

  return (
    <div className={styles.wrapper}>
      <EmptyHeader />
      <div className={styles.container}>
        <img className={styles.image} src={leavingSoon} alt="leaving soon" />
        <Typography variant="h1" className={styles.title}>
          {t('title')}
        </Typography>
        <Typography className={styles.description} color="info">
          {t('description')}
        </Typography>
        <Button
          className={styles.cta}
          variant="contained"
          color="secondary"
          onClick={onAcceptClick}
        >
          {t('cta')}
        </Button>
        <Button variant="text" onClick={onRejectClick}>
          {t('cancelButton')}
        </Button>
      </div>
    </div>
  );
};
