import { ChartCalculator } from '../utils';

type Props = {
  values: { x: number; y: number }[];
  creditsUsed: number;
};

abstract class SuggestedValuesStrategy {
  suggestedMin = 0;
  suggestedMax = 15;

  constructor(suggestedMax?: number) {
    if (suggestedMax && suggestedMax > 15) {
      this.suggestedMax = suggestedMax;
    }
  }
}

class AbsentDataStrategy extends SuggestedValuesStrategy {}

class NoFoundDataStrategy extends SuggestedValuesStrategy {}

class SingleValueStrategy extends SuggestedValuesStrategy {
  constructor(values: { x: number; y: number }[]) {
    const value = values[0];
    super(value.y * 2);
  }
}

class MultipleValuesStrategy extends SuggestedValuesStrategy {
  constructor(values: { x: number; y: number }[], calculator: ChartCalculator) {
    const lastValue = values[values.length - 1];
    super(calculator.getIntCeilingByFirstDigit(lastValue.y));
  }
}

export const useSuggestedChartValues = ({
  values,
  creditsUsed,
}: Props): SuggestedValuesStrategy => {
  const calculator = new ChartCalculator();

  if (creditsUsed === 0) {
    return new AbsentDataStrategy();
  }

  if (values.length < 1) {
    return new NoFoundDataStrategy();
  }

  if (values.length === 1) {
    return new SingleValueStrategy(values);
  }

  return new MultipleValuesStrategy(values, calculator);
};
