import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6 11 24 14">
    <path
      d="M24 30.6 12 18.727l2.15-2.127L24 26.4l9.85-9.746L36 18.777z"
      transform="translate(-6 -5.6)"
    />
  </SvgIcon>
);
