import { FC } from 'react';
import { Box } from '@mui/material';

import { useUserCellViewModel } from './useUserCellViewModel';

import styles from './styles.module.scss';

export const UserCell: FC<{ uuid: string; fullName: string }> = (props) => {
  const { value } = useUserCellViewModel(props);

  return <Box className={styles.Container}>{value}</Box>;
};
