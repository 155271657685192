import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HasPermission, Permission } from '@/features/common/permissions';

import { SubHead } from '@/components';

import {
  EnrichmentAccessDeniedModal,
  EnrichmentFilterByUser,
  EnrichmentSearch,
  EnrichmentTable,
  EnrichmentUploadAction,
  EnrichmentUploadingModal,
} from './components';
import { withEnrichmentContextProvider } from './EnrichmentContext';
import { EnrichmentUploadingContextProvider } from './EnrichmentUploadingContext';

export const Enrichment: FC = withEnrichmentContextProvider(() => {
  const { t } = useTranslation('enrichment');

  return (
    <EnrichmentUploadingContextProvider>
      <SubHead>
        <SubHead.Title>{t('subhead.title')}</SubHead.Title>
        <SubHead.Action>
          <EnrichmentSearch />
          <EnrichmentFilterByUser />
          <HasPermission permission={Permission.CanRunCsvEnrichemnt}>
            <EnrichmentUploadAction />
          </HasPermission>
        </SubHead.Action>
      </SubHead>
      <EnrichmentTable />
      <EnrichmentUploadingModal />
      <EnrichmentAccessDeniedModal />
    </EnrichmentUploadingContextProvider>
  );
});
