import { inject, injectable } from 'inversify';
import {
  catchError,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  share,
  switchMap,
} from 'rxjs';

import { AUTH_TYPES, DB_TYPES, SYNC_TYPES } from '@/ioc/types';
import { APP_LOGGER_TYPES } from '@/ioc/types';

import { AuthStatus, IAuthRepository } from '@/features/common/auth';
import { IAppLogger } from '@/features/system/logger';
import { IBaseSyncRepository } from '@/features/system/sync';

import { DbStatus, IDbManager } from '../data';

export interface IDbUseCase {
  start(): Observable<DbStatus>;
  getStatus(): Observable<DbStatus>;
}

@injectable()
export class DbUseCase implements IDbUseCase {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private baseSyncRepository: IBaseSyncRepository;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  private listenAuth(): Observable<unknown> {
    return this.authRepository.getAuthStatus().pipe(
      filter((status) => status !== AuthStatus.Initialisation),
      switchMap((status) => {
        if (status === AuthStatus.Authorized) {
          return this.dbManager.init({ withMigration: true });
        } else {
          return this.dbManager.clear();
        }
      }),
      catchError((e) => {
        this.logger.error(e);
        return of(null);
      }),
    );
  }

  private listenInvalidation(): Observable<unknown> {
    return this.baseSyncRepository.getInvalidateEvents().pipe(
      switchMap(() => this.dbManager.clear({ reloadOtherTabs: true })),
      catchError((e) => {
        this.logger.error(e);
        return of(null);
      }),
    );
  }

  public start(): Observable<DbStatus> {
    return merge(this.listenAuth(), this.listenInvalidation()).pipe(
      switchMap(() => this.getStatus()),
      share(),
    );
  }

  public getStatus(): Observable<DbStatus> {
    return this.dbManager.getStatus().pipe(distinctUntilChanged());
  }
}
