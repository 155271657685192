import { inject, injectable } from 'inversify';
import type { Observable } from 'rxjs';

import { INTEGRATIONS_TYPES } from '@/ioc/types';

import type IIntegrationsRepository from '@/features/integrations/domain/abstractions/IIntegrationsRepository';

import type IIntegrationsUseCases from './abstractions/IIntegrationsUseCases';
import type { IIntegrationEntity } from './entities';

@injectable()
export default class IntegrationsUseCases implements IIntegrationsUseCases {
  @inject(INTEGRATIONS_TYPES.IntegrationsRepository)
  private repository: IIntegrationsRepository;

  sendRequest(params: { title: string; message: string }): Promise<void> {
    return this.repository.sendRequest(params);
  }

  getIntegrations(isStatic: true): Array<Omit<IIntegrationEntity, 'status'>>;
  getIntegrations(): Observable<IIntegrationEntity[]>;
  getIntegrations(
    isStatic?: true,
  ): Array<Omit<IIntegrationEntity, 'status'>> | Observable<IIntegrationEntity[]> {
    if (isStatic) {
      return this.repository.getIntegrations(isStatic);
    }

    return this.repository.getIntegrations();
  }

  sendRequestToAdminForIntegration(provider: string): Promise<string> {
    return this.repository.sendRequestToAdminForIntegration(provider);
  }

  getAuthUrl(provider: string): Promise<{ url: string }> {
    return this.repository.getAuthUrl(provider);
  }

  connectUser(provider: string, code: string): Promise<void> {
    return this.repository.connectUser(provider, code);
  }
}
