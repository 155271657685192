import { Container } from 'inversify';

import { DASHBOARD_CHART_TYPES } from '@/ioc/types';

import { IReplicationService } from '@/features/system/replication';

import {
  DashboardChartApiService,
  DashboardChartRepository,
  IDashboardChartApiService,
  IDashboardChartDao,
  IDashboardChartRepository,
} from '../data';
import { DashboardChartDao } from '../data/';
import { DashboardChartCollectionCreator } from '../data/db/DashboardChartCollectionCreator';
import { WorkspaceStatReplicationService } from '../data/db/WorkspaceStatReplicationService';
import { DashboardChartUseCase, IDashboardChartUseCase } from '../domain';

export const bindDashboardChartModule = (container: Container): void => {
  container
    .bind<DashboardChartCollectionCreator>('DbCollectionCreator')
    .to(DashboardChartCollectionCreator);

  container
    .bind<IReplicationService>(DASHBOARD_CHART_TYPES.WorkspaceStatReplicationService)
    .to(WorkspaceStatReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(DASHBOARD_CHART_TYPES.WorkspaceStatReplicationService);

  container
    .bind<IDashboardChartDao>(DASHBOARD_CHART_TYPES.ChartDao)
    .to(DashboardChartDao)
    .inSingletonScope();

  container
    .bind<IDashboardChartApiService>(DASHBOARD_CHART_TYPES.ChartApiService)
    .to(DashboardChartApiService)
    .inSingletonScope();

  container
    .bind<IDashboardChartRepository>(DASHBOARD_CHART_TYPES.ChartRepository)
    .to(DashboardChartRepository)
    .inSingletonScope();

  container
    .bind<IDashboardChartUseCase>(DASHBOARD_CHART_TYPES.ChartUseCase)
    .to(DashboardChartUseCase);
};
