import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { useExtensionState } from '@/features/common/extension';
import { useOnboardingUseCase } from '@/features/common/onboarding';

import { VideoModal } from '@/components/VideoModal';

import { useModalController } from '@/hooks';

import { openInNewTab } from '@/utils/openInNewTab';

import VideoPlaceholderSrc from '@/assets/images/video_placeholder.webp';

import styles from './styles.module.scss';

export const NoRowsOverlay: FC = () => {
  const { t } = useTranslation('contacts');
  const videoTutorialModalController = useModalController('VideoTutorial');
  const { isInstalled: isExtensionInstalled } = useExtensionState();
  const onboardingUseCase = useOnboardingUseCase();

  const handleClick = (): void => {
    if (isExtensionInstalled) {
      openInNewTab(EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL);
    } else {
      openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
      onboardingUseCase.completeClickDownloadExtensionStep();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.titleContainer}>
          <Typography variant="h2">
            {t('table.defaultListNoResultsOverlay.noContacts')}
          </Typography>
        </div>

        <div className={styles.descriptionContainer}>
          <Typography variant="h3" color="info">
            {t('table.defaultListNoResultsOverlay.onceYouReveal')}
          </Typography>
        </div>

        <Button variant="contained" onClick={handleClick} className={styles.button}>
          <Typography variant="textInButton">
            {t('table.defaultListNoResultsOverlay.startProspecting')}
          </Typography>
        </Button>
      </div>
      <div className={styles.rightContainer}>
        <Button
          onClick={videoTutorialModalController.onVideoTutorialOpen}
          className={styles.placeholder}
        >
          <div className="pw-play-button" />
          <img src={VideoPlaceholderSrc} alt={''} />
        </Button>
        <VideoModal
          videoSrc={'/videos/video_placeholder.mp4'}
          open={videoTutorialModalController.isVideoTutorialOpen}
          onClose={videoTutorialModalController.onVideoTutorialClose}
        />
      </div>
    </div>
  );
};
