import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InstagramIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g transform="translate(-1065 -814)">
        <rect width="20" height="20" rx="4" fill="#000" transform="translate(1065 814)" />
        <path
          d="M10.943 3.094a3.845 3.845 0 0 0-.243-1.277A2.7 2.7 0 0 0 9.159.278 3.855 3.855 0 0 0 7.882.033C7.319.006 7.14 0 5.712 0S4.1.006 3.543.031a3.846 3.846 0 0 0-1.277.244 2.568 2.568 0 0 0-.933.609 2.591 2.591 0 0 0-.606.931 3.855 3.855 0 0 0-.245 1.277c-.027.563-.033.742-.033 2.171S.455 6.87.48 7.431a3.845 3.845 0 0 0 .245 1.277 2.7 2.7 0 0 0 1.54 1.54 3.855 3.855 0 0 0 1.277.245c.561.025.74.031 2.169.031s1.608-.006 2.169-.031a3.844 3.844 0 0 0 1.277-.245 2.692 2.692 0 0 0 1.54-1.54 3.858 3.858 0 0 0 .245-1.277c.025-.561.031-.74.031-2.169s-.005-1.607-.03-2.168zM10 7.39a2.884 2.884 0 0 1-.181.976 1.746 1.746 0 0 1-1 1 2.894 2.894 0 0 1-.976.181c-.555.025-.721.031-2.126.031s-1.573-.006-2.126-.031a2.883 2.883 0 0 1-.976-.181 1.619 1.619 0 0 1-.6-.393 1.636 1.636 0 0 1-.393-.6 2.9 2.9 0 0 1-.181-.976c-.032-.56-.041-.726-.041-2.13s.006-1.573.031-2.126a2.883 2.883 0 0 1 .181-.976 1.6 1.6 0 0 1 .395-.6 1.633 1.633 0 0 1 .6-.393A2.9 2.9 0 0 1 3.59.987c.555-.025.722-.031 2.126-.031s1.572.006 2.125.031a2.884 2.884 0 0 1 .976.181 1.618 1.618 0 0 1 .6.393 1.636 1.636 0 0 1 .393.6 2.9 2.9 0 0 1 .19.98c.025.555.031.721.031 2.126S10.02 6.835 10 7.39zm0 0"
          transform="translate(1069.29 818.739)"
          fill="#fff"
        />
        <path
          d="M126.828 124.5a1.878 1.878 0 1 0 1.878 1.878 1.879 1.879 0 0 0-1.878-1.878zm0 3.1a1.218 1.218 0 1 1 1.218-1.218 1.219 1.219 0 0 1-1.218 1.218zm0 0"
          transform="translate(948.175 697.624)"
          fill="#fff"
        />
        <path
          d="M364.118 89.436a.835.835 0 1 1-.835-.835.835.835 0 0 1 .835.835zm0 0"
          transform="translate(714.404 731.879)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};
