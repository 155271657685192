// noinspection AllyPlainJsInspection

import { MONTHS_SHORTCUTS } from '@/translations/enums/monthsShortcuts';

export default {
  [MONTHS_SHORTCUTS.Jan]: 'January',
  [MONTHS_SHORTCUTS.Feb]: 'February',
  [MONTHS_SHORTCUTS.March]: 'March',
  [MONTHS_SHORTCUTS.April]: 'April',
  [MONTHS_SHORTCUTS.May]: 'May',
  [MONTHS_SHORTCUTS.June]: 'June',
  [MONTHS_SHORTCUTS.July]: 'July',
  [MONTHS_SHORTCUTS.Aug]: 'Aug',
  [MONTHS_SHORTCUTS.Sept]: 'September',
  [MONTHS_SHORTCUTS.Oct]: 'October',
  [MONTHS_SHORTCUTS.Nov]: 'November',
  [MONTHS_SHORTCUTS.Dec]: 'December',
};
