import { initializeApp } from 'firebase/app';
import {
  ActionCodeInfo,
  ActionCodeSettings,
  AdditionalUserInfo,
  applyActionCode,
  Auth,
  AuthProvider,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
  UserCredential,
} from 'firebase/auth';
import { injectable, postConstruct } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserDoesNotHaveEmailError } from '../../domain';

@injectable()
export class FirebaseService {
  public get microsoftAuthProvider(): OAuthProvider {
    const provider = new OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      prompt: 'consent',
    });

    return provider;
  }

  private _user: BehaviorSubject<User | null | undefined> = new BehaviorSubject<
    User | null | undefined
  >(undefined);

  public get user(): Observable<User | null | undefined> {
    return this._user;
  }

  public get googleAuthProvider(): GoogleAuthProvider {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({ prompt: 'select_account' });
    return googleProvider;
  }

  private _auth: Auth = getAuth(
    initializeApp({
      apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    }),
  );

  private get auth(): Auth {
    return this._auth;
  }

  @postConstruct()
  init(): void {
    this.onAuthStateChanged((user) => {
      this._user.next(user);
    });
  }

  onAuthStateChanged(listener: (nextUser: User | null) => void): void {
    onAuthStateChanged(this.auth, listener);
  }

  updateEmail(email: string): Promise<void> {
    const user = this.getCurrentUserOrThrow();
    return updateEmail(user, email);
  }

  getAdditionalUserInfo(userCredential: UserCredential): AdditionalUserInfo | null {
    return getAdditionalUserInfo(userCredential);
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  applyActionCode(code: string): Promise<void> {
    return applyActionCode(this.auth, code);
  }

  async reauthenticateWithPassword(password: string): Promise<void> {
    const user = this.getCurrentUserOrThrow();

    if (!user.email) {
      throw new UserDoesNotHaveEmailError();
    }

    await reauthenticateWithCredential(
      user,
      EmailAuthProvider.credential(user.email, password),
    );
  }

  updatePassword(password: string): Promise<void> {
    const user = this.getCurrentUserOrThrow();
    return updatePassword(user, password);
  }

  checkActionCode(actionCode: string): Promise<ActionCodeInfo> {
    return checkActionCode(this.auth, actionCode);
  }

  async checkUserExists(email: string): Promise<boolean> {
    const { length } = await fetchSignInMethodsForEmail(this.auth, email);
    return length > 0;
  }

  signOut(): Promise<void> {
    return signOut(this.auth);
  }

  signInWithPopup(provider: AuthProvider): Promise<UserCredential> {
    return signInWithPopup(this.auth, provider);
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return setPersistence(this.auth, indexedDBLocalPersistence).then(() => {
      return signInWithEmailAndPassword(this.auth, email, password);
    });
  }

  signInWithCustomToken(token: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, token);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  sendEmailVerification(actionCodeSettings?: ActionCodeSettings | null): Promise<void> {
    const user = this.getCurrentUserOrThrow();
    return sendEmailVerification(user, actionCodeSettings);
  }

  updateProfile(data: { displayName?: string }): Promise<void> {
    const user = this.getCurrentUserOrThrow();
    return updateProfile(user, data);
  }

  deleteUser(): Promise<void> {
    const user = this.getCurrentUserOrThrow();
    return user.delete();
  }

  async reloadUser(): Promise<void> {
    try {
      const user = this.getCurrentUserOrThrow();
      await user.reload();
    } catch {
      return;
    }
  }

  private getCurrentUserOrThrow(): User {
    const user = this.auth.currentUser;

    if (!user) {
      throw new Error('User is not logged in');
    }

    return user;
  }
}
