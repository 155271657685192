import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { UnauthenticatedLayout } from '@/router/layouts';

import { FormField, PasswordInput, PasswordValidationTips } from '@/components';

import Logo from '@/assets/icons/headerLogo.svg?react';

import useResetPasswordViewModel from './useResetPasswordViewModel';

import styles from './styles.module.scss';

export const ResetPassword: FC = () => {
  const { form, onSubmit } = useResetPasswordViewModel();
  const { t } = useTranslation('auth', { keyPrefix: 'resetPassword' });
  const { t: validationTranslate } = useTranslation('validation');
  const newPassword = form.watch('newPassword');

  const errorIncludes =
    form.formState.errors.newPassword || form.formState.errors.confirmPassword;
  let errorToDisplay;
  if (errorIncludes) {
    errorToDisplay =
      form.formState.errors.newPassword?.message ||
      form.formState.errors.confirmPassword?.message;
  }

  return (
    <>
      <Box className={styles.header}>
        <Logo />
      </Box>
      <Box className={styles.wrapper}>
        <Box className={styles.container}>
          <Typography className={styles.title} variant={'h1'}>
            {t('title')}
          </Typography>
          <Box className={styles.formContainer}>
            <Box className={styles.inputContainer}>
              <FormField
                labelText={t('newPasswordLabel')}
                error={!!form.formState.errors.newPassword}
              >
                <PasswordInput
                  {...form.register('newPassword')}
                  className={styles.input}
                />
              </FormField>
            </Box>
            <Box className={styles.inputContainer}>
              <FormField
                labelText={t('confirmPassword')}
                error={!!form.formState.errors.confirmPassword}
              >
                <PasswordInput
                  {...form.register('confirmPassword')}
                  className={styles.input}
                />
              </FormField>
            </Box>
            <PasswordValidationTips password={newPassword} />
            {errorToDisplay && (
              <UnauthenticatedLayout.ErrorAlert className={styles.error}>
                {validationTranslate(errorToDisplay)}
              </UnauthenticatedLayout.ErrorAlert>
            )}
            <Button
              variant={'contained'}
              disabled={form.formState.isSubmitting || !form.formState.isDirty}
              onClick={onSubmit}
              className={styles.submitButton}
            >
              {t('submitButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
