import { FC, useContext } from 'react';

import { TeamMemberSelect } from '@/features/settings';
import {
  ANALYTICS_EVENTS,
  DataFiltersPageProperty,
  useAnalytics,
} from '@/features/system/analytics';

import { ContactsContext } from '../../context';

type FilterByUserActionProps = {};

export const FilterByUserAction: FC<FilterByUserActionProps> = () => {
  const analytics = useAnalytics();
  const { filterByUser, setFilterByUser } = useContext(ContactsContext);

  const handleChange = (value: string): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.FILTER_BY_USERS, {
      page: DataFiltersPageProperty.Contacts,
    });
    setFilterByUser(value);
  };

  return <TeamMemberSelect value={filterByUser} onChange={handleChange} />;
};
