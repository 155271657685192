import { useSubscriptionUseCase } from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { PlanType } from '../../domain';

export function useCurrentPlanType(): ObservableResult<PlanType> {
  const subscriptionUseCase = useSubscriptionUseCase();

  return useObservableResult(() => subscriptionUseCase.getSubscriptionPlanType());
}
