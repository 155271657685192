import { useInjection } from '@/ioc';
import { LEADER_ELECTION_TYPES, STORAGE_TYPES } from '@/ioc/types';

import type { ILeaderElectionUseCase } from '@/features/system/leaderElection';
import type { IStorageUseCase } from '@/features/system/storage';

import { useObservableResult } from '@/utils/rx';

const REDIRECT_PATH_KEY = 'redirectPath';

const validatePath: ValueTypeValidator<string> = (value) => typeof value === 'string';

export const useRedirectAfterLogin = (): [
  Nullable<string>,
  (path: Nullable<string>) => void,
] => {
  const storageUseCase = useInjection<IStorageUseCase>(STORAGE_TYPES.StorageUseCase);
  const leaderUseCase = useInjection<ILeaderElectionUseCase>(
    LEADER_ELECTION_TYPES.LeaderElectionUseCase,
  );

  const { data: redirectPath } = useObservableResult(
    () => storageUseCase.get$(REDIRECT_PATH_KEY, validatePath),
    { defaultData: storageUseCase.get(REDIRECT_PATH_KEY, validatePath) },
  );

  const { data: isLeader } = useObservableResult(() => leaderUseCase.isLeader$(), {
    defaultData: leaderUseCase.isLeader(),
  });

  const setRedirectPath = (path: Nullable<string>): void => {
    storageUseCase.save({
      [REDIRECT_PATH_KEY]: path,
    });
  };

  return [isLeader ? redirectPath : null, setRedirectPath];
};
