import { inject, injectable } from 'inversify';

import { CONTEXTUAL_ONBOARDING_TYPES } from '@/ioc/types';

import { IAccountEntity } from '@/features/common/account';

import { IContextualOnboardingRepository } from '../abstraction';
import { IContextualOnboardingUseCase } from '../abstraction/IContextualOnboardingUseCase';

@injectable()
export class ContextualOnboardingUseCase implements IContextualOnboardingUseCase {
  @inject(CONTEXTUAL_ONBOARDING_TYPES.ContextualOnboardingRepository)
  private contextualOnboardingRepository: IContextualOnboardingRepository;

  public init(): void {
    this.contextualOnboardingRepository.init();
  }
  public identify(account: IAccountEntity): void {
    this.contextualOnboardingRepository.identify(account);
  }
  public reload(): void {
    this.contextualOnboardingRepository.reload();
  }
  public track(eventName: string, meta?: Record<string, string>): void {
    this.contextualOnboardingRepository.track(eventName, meta);
  }
}
