import { FC, PropsWithChildren } from 'react';
import { Popover } from '@mui/material';

import { Failed } from './components/Failed';
import { Pending } from './components/Pending';
import { Success } from './components/Success';
import { ContactExportStatus } from './types';

import styles from './styles.module.scss';

type ContactExportProgressProps = {
  open: boolean;
  anchorEl: Element | undefined;
  status: ContactExportStatus;
  successText: string;
  errorText: string;
  pendingText: string;
  onRetry: () => void;
  onClose: () => void;
  onTransitionExited?: () => void;
};

export const ContactExportProgress: FC<PropsWithChildren<ContactExportProgressProps>> = ({
  open,
  anchorEl,
  status = 'pending',
  successText,
  pendingText,
  errorText,
  onRetry,
  onClose,
  onTransitionExited,
}) => {
  return (
    <Popover
      onTransitionExited={onTransitionExited}
      transitionDuration={{ enter: 0, exit: 400 }}
      open={open}
      anchorEl={anchorEl}
      keepMounted={false}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      onClose={onClose}
      classes={{ paper: styles.wrapper }}
    >
      {status === 'finished' ? (
        <Success text={successText} />
      ) : status === 'failed' ? (
        <Failed text={errorText} onRetry={onRetry} />
      ) : (
        <Pending text={pendingText} />
      )}
    </Popover>
  );
};
