import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { HasPermission, Permission } from '@/features/common/permissions';

import { useCellProspectTaskStateByListId } from '../../hooks';

import styles from './styles.module.scss';

const UpdatingIndicator: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation('lists');
  const { isUpdating } = useCellProspectTaskStateByListId(id);

  if (!isUpdating) return null;

  return (
    <Typography className={styles.amountCellProgressText}>
      &nbsp;{t('table.rows.amount.progressText')}
    </Typography>
  );
};

export const AmountCell: FC<GridRenderCellParams> = ({ row }) => {
  return (
    <Box className={styles.amountCell}>
      <Typography className={styles.amountCellText}>{row.contactsAmount}</Typography>
      <HasPermission permission={Permission.CanViewProspectTaskProgress}>
        <UpdatingIndicator id={row.id} />
      </HasPermission>
    </Box>
  );
};
