import * as React from "react";
const SvgFile = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 17.124, height: 22.092, viewBox: "0 0 17.124 22.092", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18921" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18919" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32925", d: "M106.33 0h7.142l6.307 6.307v12.11a2.683 2.683 0 0 1-2.675 2.675H106.33a2.683 2.683 0 0 1-2.675-2.675V2.675A2.683 2.683 0 0 1 106.33 0z", transform: "translate(-103.156 .501)", style: {
  fillRule: "evenodd",
  stroke: "#1d7c4d",
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18920" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 19322", transform: "translate(4.033 9.619)", style: {
  strokeWidth: ".75px",
  stroke: "#1d7c4d",
  fill: "#fff"
} }, /* @__PURE__ */ React.createElement("rect", { width: 9, height: 9, rx: 1, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.375, y: 0.375, width: 8.25, height: 8.25, rx: 0.625, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("path", { "data-name": "Line 1115", transform: "translate(7.533 10.119)", style: {
  fill: "none",
  strokeWidth: ".75px",
  stroke: "#1d7c4d"
}, d: "M0 0v8" }), /* @__PURE__ */ React.createElement("path", { "data-name": "Line 1116", transform: "translate(4.533 13.119)", style: {
  fill: "none",
  strokeWidth: ".75px",
  stroke: "#1d7c4d"
}, d: "M0 0h8" }))));
export default SvgFile;
