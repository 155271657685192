import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ROUTES } from '@/router/routes';

import { useAppLogger } from '@/features/system/logger';

import { useIntegrationUseCase } from '../../hooks';

type UseDisconnectDialogViewModel = (params: {
  onClose: () => void;
  provider: string;
}) => {
  isProcessing: boolean;
  handleDisconnect: () => void;
};

export const useDisconnectDialogViewModel: UseDisconnectDialogViewModel = ({
  provider,
  onClose,
}) => {
  const { t } = useTranslation('integrations');
  const navigate = useNavigate();
  const appLogger = useAppLogger();
  const integrationUseCase = useIntegrationUseCase();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDisconnect = async (): Promise<void> => {
    try {
      setIsProcessing(true);
      await integrationUseCase.disconnectUser(provider);
      onClose();
      navigate(ROUTES.INTEGRATIONS);
    } catch (e) {
      appLogger.error(e);
      enqueueSnackbar({ variant: 'error', message: t('integration.disconnectFailed') });
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    handleDisconnect,
  };
};
