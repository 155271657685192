import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import { useCurrentWorkspace } from '@/features/common/workspace';
import { parseSelectedDate } from '@/features/dashboard/features/chart/ui/utils';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

type ChartHistoryOptions = {
  selectedDate: DATE_SELECT_OPTION_VALUES;
  selectedUser: string;
};

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const useChartHistoryEvents = ({
  selectedDate,
  selectedUser,
}: ChartHistoryOptions) => {
  const { data: workspace } = useCurrentWorkspace();

  const isDateInRange = useCallback(
    (date: number): boolean => {
      const { fromDate, toDate } = parseSelectedDate({
        selectedDate,
        minDate: workspace?.createdAt ?? 0,
      });

      switch (selectedDate) {
        case DATE_SELECT_OPTION_VALUES.today:
          return dayjs.unix(date).isToday();
        case DATE_SELECT_OPTION_VALUES.yesterday:
          return dayjs.unix(date).isYesterday();
        default:
          // +1ms is added to the acceptedInvitationAt to make sure that the member is included in the selected date
          // because dayjs.isBetween() returns false if the date is the same as the fromDate or toDate
          return dayjs(dayjs.unix(date + 1)).isBetween(
            dayjs.unix(fromDate),
            dayjs.unix(toDate),
          );
      }
    },
    [selectedDate],
  );

  const values = useMemo(() => {
    const initialValue: { x: number; y: number; name: string }[] = [];

    const getX = (
      date?: Nullable<number>,
      partOfDay: 'start' | 'end' = 'end',
    ): number => {
      if (partOfDay === 'start') {
        return dayjs
          .unix(date as number)
          .startOf('day')
          .valueOf();
      }

      return dayjs
        .unix(date as number)
        .endOf('day')
        .valueOf();
    };

    if (isDateInRange(workspace?.createdAt as number)) {
      initialValue.push({
        x: getX(workspace?.createdAt, 'start'),
        y: 0,
        name: '',
      });
    }

    workspace?.members
      .filter((member) => {
        if ((selectedUser && selectedUser !== member.uuid) || member.role === 'owner') {
          return false;
        }

        return isDateInRange(member.acceptedInvitationAt as number);
      })
      .forEach((member) => {
        initialValue.push({
          x: getX(member.acceptedInvitationAt, 'start'),
          y: 0,
          name: member.fullName as string,
        });
      });

    return initialValue;
  }, [workspace, selectedUser, selectedDate]);

  if (!workspace) {
    return {
      values: [],
    };
  }

  return {
    values,
  };
};
