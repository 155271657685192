import { FC } from 'react';
import { InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import clsx from 'clsx';

import { IPaymentMethodEntity } from '@/features/common/billing/domain';

import { CardIcon } from '../CardIcon';

import styles from './styles.module.scss';

type CardFieldProps = {
  disabled?: boolean;
  error?: boolean;
  card?: IPaymentMethodEntity['card'];
};

/**
 * NOTE:
 * 1. Some styles variables are in JSX cause we can not override styling of inner stripe elements (as card input, csv input etc)
 * from scss module.
 * 2. Scss modules is used for styling of outer elements as control border etc
 **/
export const CardField: FC<CardFieldProps> = ({ disabled, error, card }) => {
  if (card) {
    return (
      <OutlinedInput
        readOnly
        // @ts-ignore
        size="large"
        startAdornment={
          <InputAdornment position="start">
            <CardIcon brand={card.brand} />
          </InputAdornment>
        }
        value={`**** **** **** ${card.last4}`}
        endAdornment={
          <InputAdornment position="end">
            <Stack spacing={1} direction="row" alignItems="center">
              <span>
                {card.expMonth}/{card.expYear}
              </span>
              <span>***</span>
            </Stack>
          </InputAdornment>
        }
      />
    );
  }

  return (
    <CardElement
      options={{
        disabled,
        hidePostalCode: true,
        disableLink: true,
        style: {
          base: {
            fontFamily: 'SofiaPro',
            fontSize: '14px',
            fontWeight: 500,
            '::placeholder': {
              fontSize: '14px',
              fontWeight: 400,
              color: '#CACBD8',
            },
          },
          invalid: {
            color: 'error',
          },
        },
        classes: {
          base: clsx(styles.baseInputField, {
            [styles.baseInputFieldDisabled]: disabled,
            [styles.baseInputFieldInvalid]: error,
          }),
          invalid: styles.baseInputFieldInvalid,
          focus: styles.baseInputFieldFocus,
        },
      }}
    />
  );
};
