import * as React from "react";
const SvgExcel = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 16, height: 15.336, viewBox: "0 0 16 15.336", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 101" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10480", d: "M9.606 10.7a.359.359 0 0 0-.295-.067l-9.042 2a.337.337 0 0 0-.27.328V24.3a.338.338 0 0 0 .295.329l9.042 1.333a.346.346 0 0 0 .4-.279.32.32 0 0 0 0-.051v-14.67a.328.328 0 0 0-.13-.262z", transform: "translate(0 -10.626)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10481", d: "M284.636 65.928h-6.955a.331.331 0 1 1 0-.661h6.608V54.038h-6.608a.331.331 0 1 1 0-.661h6.955a.339.339 0 0 1 .348.33V65.6a.339.339 0 0 1-.348.328z", transform: "translate(-268.984 -51.983)", style: {
  fill: "#4caf50"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 102" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10482", d: "M88.98 166.028a.348.348 0 0 1-.295-.163l-3.477-5.565a.348.348 0 1 1 .578-.387l.012.019 3.478 5.564a.348.348 0 0 1-.295.531z", transform: "translate(-82.527 -155.229)", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10483", d: "M85.7 166.007a.348.348 0 0 1-.294-.532l3.478-5.564a.348.348 0 0 1 .6.349l-.012.019L86 165.843a.348.348 0 0 1-.3.164z", transform: "translate(-82.726 -155.208)", style: {
  fill: "#fafafa"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 103" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10484", d: "M363.015 65.927a.339.339 0 0 1-.348-.33V53.706a.348.348 0 0 1 .7 0V65.6a.339.339 0 0 1-.352.327z", transform: "translate(-351.536 -51.982)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10485", d: "M284.636 374.072h-6.955a.348.348 0 0 1 0-.7h6.955a.348.348 0 1 1 0 .7z", transform: "translate(-268.984 -362.102)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10486", d: "M284.636 310.072h-6.955a.348.348 0 0 1 0-.7h6.955a.348.348 0 1 1 0 .7z", transform: "translate(-268.984 -300.078)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10487", d: "M284.636 246.072h-6.955a.348.348 0 0 1 0-.7h6.955a.348.348 0 1 1 0 .7z", transform: "translate(-268.984 -238.054)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10488", d: "M284.636 182.072h-6.955a.348.348 0 0 1 0-.7h6.955a.348.348 0 1 1 0 .7z", transform: "translate(-268.984 -176.03)", style: {
  fill: "#4caf50"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10489", d: "M284.636 118.072h-6.955a.348.348 0 0 1 0-.7h6.955a.348.348 0 1 1 0 .7z", transform: "translate(-268.984 -114.006)", style: {
  fill: "#4caf50"
} })));
export default SvgExcel;
