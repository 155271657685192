import { Container } from 'inversify';

import { COMPANY_TYPES, CONTACT_BY_FILTERS_TYPES, CONTACT_TYPES } from '@/ioc/types';

import {
  IContactApiService,
  IContactDao,
  IContactRepository,
  IContactUseCase,
} from '@/features/common/contact';
import { IReplicationService } from '@/features/system/replication';

import {
  CompanyCollectionCreator,
  CompanyDao,
  CompanyReplicationService,
  CompanyRepository,
  ContactApiService,
  ContactByFiltersDao,
  ContactDao,
  ContactRepository,
  ICompanyDao,
  IContactByFiltersDao,
} from '../data';
import { ContactByFiltersRepository } from '../data/ContactByFiltersRepository';
import { ContactSyncRepository } from '../data/ContactSyncRepository';
import { ContactByFiltersCollectionCreator } from '../data/db/ContactByFiltersCollectionCreator';
import { ContactCollectionCreator } from '../data/db/ContactCollectionCreator';
import { ContactReplicationService } from '../data/db/ContactReplicationService';
import {
  CompanyUseCase,
  ContactUseCase,
  ICompanyRepository,
  ICompanyUseCase,
  IContactByFiltersRepository,
  IContactByFiltersUseCase,
  IContactSyncRepository,
} from '../domain';
import { ContactByFiltersUseCase } from '../domain/ContactByFiltersUseCase';

export const bindContactModule = (container: Container): void => {
  container
    .bind<CompanyCollectionCreator>('DbCollectionCreator')
    .to(CompanyCollectionCreator);
  container
    .bind<ContactByFiltersCollectionCreator>('DbCollectionCreator')
    .to(ContactByFiltersCollectionCreator);
  container
    .bind<ContactCollectionCreator>('DbCollectionCreator')
    .to(ContactCollectionCreator);

  container
    .bind<IReplicationService>(COMPANY_TYPES.CompanyReplicationService)
    .to(CompanyReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(COMPANY_TYPES.CompanyReplicationService);

  container
    .bind<IReplicationService>(CONTACT_TYPES.ContactReplicationService)
    .to(ContactReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(CONTACT_TYPES.ContactReplicationService);

  container
    .bind<IContactRepository>(CONTACT_TYPES.ContactRepository)
    .to(ContactRepository)
    .inSingletonScope();

  container
    .bind<IContactApiService>(CONTACT_TYPES.ContactApiService)
    .to(ContactApiService)
    .inSingletonScope();

  container.bind<IContactUseCase>(CONTACT_TYPES.ContactUseCase).to(ContactUseCase);

  container.bind<IContactDao>(CONTACT_TYPES.ContactDao).to(ContactDao).inSingletonScope();

  container.bind<ICompanyUseCase>(COMPANY_TYPES.CompanyUseCase).to(CompanyUseCase);

  container
    .bind<ICompanyRepository>(COMPANY_TYPES.CompanyRepository)
    .to(CompanyRepository);

  container.bind<ICompanyDao>(COMPANY_TYPES.CompanyDao).to(CompanyDao).inSingletonScope();

  container
    .bind<IContactByFiltersRepository>(
      CONTACT_BY_FILTERS_TYPES.ContactByFiltersRepository,
    )
    .to(ContactByFiltersRepository);

  container
    .bind<IContactByFiltersUseCase>(CONTACT_BY_FILTERS_TYPES.ContactByFiltersUseCase)
    .to(ContactByFiltersUseCase);

  container
    .bind<IContactByFiltersDao>(CONTACT_BY_FILTERS_TYPES.ContactByFiltersDao)
    .to(ContactByFiltersDao)
    .inSingletonScope();

  container
    .bind<IContactSyncRepository>(CONTACT_TYPES.ContactSyncRepository)
    .to(ContactSyncRepository)
    .inSingletonScope();
};
