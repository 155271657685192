import type { IProductEntity } from '@/features/common/billing';

import type { IReceiptAdjustmentEntity } from './ReceiptAdjustmentEntity';
import type { IReceiptEntity } from './ReceiptEntity';

export class ReceiptBuilder {
  private name = '';
  private cycle: IProductEntity['cycle'] = 'monthly';
  private isPriceFixed = false;
  private pricePerSeat = 0;
  private credits: number | 'unlimited' = 0;
  private seats = 0;
  private adjustments: IReceiptAdjustmentEntity[] = [];
  private promotion?: IReceiptAdjustmentEntity;
  private dueDate?: Date;

  addName(name: string): this {
    this.name = name;
    return this;
  }

  addDueDate(dueDate: Date): this {
    this.dueDate = dueDate;
    return this;
  }

  addCycle(cycle: IProductEntity['cycle']): this {
    this.cycle = cycle;
    return this;
  }

  addIsPriceFixed(isPriceFixed: boolean): this {
    this.isPriceFixed = isPriceFixed;
    return this;
  }

  addPricePerSeat(pricePerSeat: number): this {
    this.pricePerSeat = pricePerSeat;
    return this;
  }

  addCredits(credits: number | 'unlimited'): this {
    this.credits = credits;
    return this;
  }

  addSeats(seats: IReceiptEntity['product']['seats']): this {
    this.seats = seats;
    return this;
  }

  addAdjustment(adjustment: IReceiptAdjustmentEntity): this {
    this.adjustments.push(adjustment);
    return this;
  }

  addPromotion(promotion: IReceiptAdjustmentEntity): this {
    this.promotion = promotion;
    return this;
  }

  private calculateTotal(
    initialPrice: number,
    adjustments: IReceiptAdjustmentEntity[],
    promotion?: IReceiptAdjustmentEntity,
  ): number {
    let sum = initialPrice;

    for (const adjustment of adjustments) {
      if (adjustment.isApplyable) {
        sum = adjustment.apply(sum);
      }
    }

    if (promotion && promotion.isApplyable) {
      sum = promotion.apply(sum);
    }

    return sum;
  }

  build(): IReceiptEntity {
    const price = this.isPriceFixed ? this.pricePerSeat : this.pricePerSeat * this.seats;
    const total = this.calculateTotal(price, this.adjustments, this.promotion);

    return {
      product: {
        name: this.name,
        seats: this.seats,
        price: price,
        credits: this.credits,
        cycle: this.cycle,
      },
      adjustments: this.adjustments,
      promotion: this.promotion,
      dueDate: this.dueDate ?? new Date(),
      total,
    };
  }
}
