import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const VerifiedUserIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 -960 960 960">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m436-356 228-228-42-41-183 183-101-101-44 44 142 143Zm44 275q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Z"
    />
  </SvgIcon>
);
