import iconCustom from '@/assets/icons/integrations/custom.png';
import iconHubspot from '@/assets/icons/integrations/hubspot.png';
import iconOutreach from '@/assets/icons/integrations/outreach.png';
import iconPipedrive from '@/assets/icons/integrations/pipedrive.png';
import iconSalesforce from '@/assets/icons/integrations/salesforce.png';
import iconZapier from '@/assets/icons/integrations/zapier.png';
import iconZoho from '@/assets/icons/integrations/zoho.png';

import { IIntegrationEntity } from '../../domain';
import { IIntegrationProviderDC, IIntegrationStatusDC } from '../dataContracts';

const allIntegrations = [
  {
    icon: iconSalesforce,
    id: 'salesforce',
    name: 'Salesforce',
  },
  {
    icon: iconHubspot,
    id: 'hubspot',
    name: 'HubSpot',
  },
  {
    icon: iconZoho,
    id: 'zoho',
    name: 'Zoho CRM',
  },
  {
    icon: iconPipedrive,
    id: 'pipedrive',
    name: 'Pipedrive',
  },
  {
    icon: iconZapier,
    id: 'zapier',
    name: 'Zapier',
  },
  {
    icon: iconOutreach,
    id: 'outreach',
    name: 'Outreach',
  },
  {
    id: 'custom',
    icon: iconCustom,
    name: 'Custom integration',
  },
];

type DC = {
  integrations: IIntegrationStatusDC[];
  providers: IIntegrationProviderDC[];
};

function resolveStatus(id: string, dc: DC): IIntegrationEntity['status'] {
  if (!dc.providers.find((provider) => provider.name === id)) {
    return 'not-available';
  }

  const integrationStatus = dc.integrations.find(
    (integration) => integration.provider === id,
  );

  if (integrationStatus?.connection_active) {
    return 'connected';
  }

  if (integrationStatus && integrationStatus.connection_active === false) {
    return 'expired';
  }

  return 'disconnected';
}

export function mapIntegrationsDcToEntity(dc: DC): IIntegrationEntity[] {
  return allIntegrations.map<IIntegrationEntity>((integration) => ({
    id: integration.id,
    name: integration.name,
    icon: integration.icon,
    status: resolveStatus(integration.id, dc),
  }));
}
