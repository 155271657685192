import { type FC, useEffect } from 'react';

import { useAnalyticsUseCase } from '../../hooks';

export const Analytics: FC = () => {
  const analyticsUseCase = useAnalyticsUseCase();

  useEffect(() => {
    analyticsUseCase.init();
  }, []);

  useEffect(() => {
    const subscription = analyticsUseCase.identify().subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
};
