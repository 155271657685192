import { useInjection } from '@/ioc/ioc.react';
import { CONTEXTUAL_ONBOARDING_TYPES } from '@/ioc/types';

import { IContextualOnboardingUseCase } from '../../domain';

export const useContextualOnboardingUseCase = (): IContextualOnboardingUseCase => {
  return useInjection<IContextualOnboardingUseCase>(
    CONTEXTUAL_ONBOARDING_TYPES.ContextualOnboardingUseCase,
  );
};
