import { FC } from 'react';

import MoneyBackIcon from '@/assets/icons/paymentDetails/30days-money-back.svg?react';
import MasterCardIcon from '@/assets/icons/paymentDetails/master-card.svg?react';
import VerifiedByVisaIcon from '@/assets/icons/paymentDetails/visa.svg?react';

import styles from './styles.module.scss';

export const PaymentGuarantee: FC = () => {
  return (
    <div className={styles.guarantee}>
      <MoneyBackIcon className={styles.moneyBack} />
      <VerifiedByVisaIcon />
      <MasterCardIcon />
    </div>
  );
};
