import { useDefaultContactList } from '@/features/common/contactList';
import { useProspectTaskProgressContext } from '@/features/common/prospectTask';

type UseCellProspectTaskStateResult = {
  isUpdating: boolean;
};

export const useCellProspectTaskStateByListId = (
  listId: string,
): UseCellProspectTaskStateResult => {
  const { hasPendingTask } = useProspectTaskProgressContext();
  const { data: defaultList } = useDefaultContactList();
  const isDefaultList = listId === defaultList?.uuid;

  const computeTaskIsLoadingState = (): boolean => {
    if (isDefaultList) return hasPendingTask();

    return hasPendingTask(listId);
  };

  return {
    isUpdating: computeTaskIsLoadingState(),
  };
};
