import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { Modal } from '@/components';

import { useDisconnectDialogViewModel } from './useDisconnectDialogViewModel';

import styles from './styles.module.scss';

type DisconnectDialogProps = {
  provider: string;
  providerName: string;
  isOpen: boolean;
  onClose: () => void;
};
export const DisconnectDialog: FC<DisconnectDialogProps> = ({
  provider,
  providerName,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('integrations');

  const { handleDisconnect, isProcessing } = useDisconnectDialogViewModel({
    onClose,
    provider,
  });

  return (
    <Modal onClose={onClose} open={isOpen} containerClass={styles.paperRoot}>
      <>
        <Typography variant={'h3'} className={styles.title}>
          {t('disconnectDialog.title', { name: providerName })}
        </Typography>
        <Typography variant={'subtitle1'} className={styles.description}>
          {t('disconnectDialog.description', { name: providerName })}
        </Typography>
        <Box className={styles.actionsWrapper}>
          <Button variant="text" className={styles.cancel} onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            className={styles.submit}
            onClick={handleDisconnect}
            disabled={isProcessing}
          >
            {t('disconnectDialog.submit', { name: providerName })}
          </Button>
        </Box>
      </>
    </Modal>
  );
};
