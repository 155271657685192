import { FC, useEffect } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { COOKIES_TYPES } from '@/ioc/types';

import { IAuthIdentityCookieUseCase } from '../domain';

export const AuthIdentityCookieSync: FC = () => {
  const authIdentityCookieUseCase = useInjection<IAuthIdentityCookieUseCase>(
    COOKIES_TYPES.AuthIdentityCookieUseCase,
  );
  useEffect(() => {
    authIdentityCookieUseCase.sync().subscribe();
  }, []);

  return null;
};
