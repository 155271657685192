import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { FormField as KitFormField } from '@/components';

import styles from './styles.module.scss';

export const FormField: FC<{
  label?: string;
  error?: string;
  required?: boolean;
  hasError?: boolean;
  children: ReactElement;
}> = ({ children, label, error, required, hasError }) => {
  return (
    <Box
      className={clsx(styles.formField, {
        [styles.hasLabel]: !!label,
      })}
    >
      <KitFormField
        labelText={label}
        error={!!error || hasError}
        errorText={error}
        required={required}
      >
        {children}
      </KitFormField>
    </Box>
  );
};
