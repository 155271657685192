import { inject, injectable, unmanaged } from 'inversify';
import { MangoQuery, MangoQuerySelector, RxCollection } from 'rxdb';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { v4 } from 'uuid';

import { DB_TYPES } from '@/ioc/types';

import { IDbManager } from '../data/DbManagerNew';
import { Database } from '../types';

export interface IDbCollection<T extends object, IdKey extends keyof T> {
  findAll(query?: MangoQuery<T>): Observable<T[]>;
  findOne(query?: MangoQuery<T>): Observable<T | null>;
  findById(id: T[IdKey]): Observable<T | null>;
  findByIds(ids: Array<T[IdKey]>): Observable<T[]>;
  updateOne(id: T[IdKey], patch: Partial<T>): Promise<T>;
  upsert(patch: Partial<T>): Promise<T>;
  bulkUpsert(patches: Partial<T>[]): Promise<T[]>;
  insertOne(entity: WithOptionalId<T, IdKey>): Promise<T>;
  bulkInsert(entities: WithOptionalId<T, IdKey>[]): Promise<T[]>;
  bulkRemove(ids: T[IdKey][]): Promise<T[]>;
  removeOne(id: T[IdKey]): Promise<T>;
  count(query?: MangoQuery<T>): Observable<number>;
}

type CollectionName = keyof Database['collections'];

@injectable()
export class DbCollection<Entity extends object, IdKey extends keyof Entity>
  implements IDbCollection<Entity, IdKey>
{
  protected readonly collectionName: CollectionName;
  protected readonly idKey: IdKey;

  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  private withId(entity: Partial<Entity>): Partial<Entity> {
    if (!Reflect.has(entity, this.idKey)) {
      return { ...entity, [this.idKey]: v4() };
    }

    return entity;
  }

  private assertIsString(value: unknown): asserts value is string {
    if (typeof value !== 'string') {
      throw new Error(`Expected string, got ${typeof value}`);
    }
  }

  constructor(@unmanaged() params: { collectionName: CollectionName; idKey: IdKey }) {
    this.collectionName = params.collectionName;
    this.idKey = params.idKey;
  }

  protected get collection(): Observable<RxCollection<Entity>> {
    return this.dbManager.getDb().pipe(
      map((db) => {
        if (!Reflect.has(db.collections, this.collectionName)) {
          throw new Error(`Collection ${this.collectionName} not found`);
        }

        return db.collections[this.collectionName] as unknown as RxCollection<Entity>;
      }),
    );
  }

  findAll(query: MangoQuery<Entity> = {}): Observable<Entity[]> {
    return this.collection.pipe(
      switchMap((collection) => {
        return collection.find({ ...query }).$;
      }),
      map((docs) => docs.map((doc) => doc.toMutableJSON())),
    );
  }

  findOne(query: MangoQuery<Entity> = {}): Observable<Entity | null> {
    return this.collection.pipe(
      switchMap((collection) => collection.findOne({ ...query }).$),
      map((doc) => (doc ? doc.toMutableJSON() : null)),
    );
  }

  findById(id: Entity[IdKey]): Observable<Entity | null> {
    return this.collection.pipe(
      switchMap(
        (collection) =>
          collection.findOne({
            selector: { [this.idKey]: id } as MangoQuerySelector<Entity>,
          }).$,
      ),
      map((doc) => (doc ? doc.toMutableJSON() : null)),
    );
  }

  findByIds(ids: Entity[IdKey][]): Observable<Entity[]> {
    return this.collection.pipe(
      switchMap(
        (collection) =>
          collection.find({
            selector: { [this.idKey]: { $in: ids } } as MangoQuerySelector<Entity>,
          }).$,
      ),
      map((docs) => docs.map((doc) => doc.toMutableJSON())),
    );
  }

  async updateOne(id: Entity[IdKey], patch: Partial<Entity>): Promise<Entity> {
    const collection = await firstValueFrom(this.collection);
    const doc = await collection
      .findOne({
        selector: { [this.idKey]: id } as MangoQuerySelector<Entity>,
      })
      .exec();

    if (!doc) {
      throw new Error(`Entity["${this.collectionName}] with id "${id}" not found`);
    }

    const result = await doc.incrementalPatch(patch);

    return result.toMutableJSON();
  }

  async upsert(entity: Partial<Entity>): Promise<Entity> {
    const collection = await firstValueFrom(this.collection);
    const result = await collection.upsert(this.withId(entity));

    return result.toMutableJSON();
  }

  async bulkUpsert(entities: Partial<Entity>[]): Promise<Entity[]> {
    const collection = await firstValueFrom(this.collection);
    const result = await collection.bulkUpsert(
      entities.map((entity) => this.withId(entity)),
    );

    return result.success.map((doc) => doc.toMutableJSON());
  }

  async insertOne(entity: WithOptionalId<Entity, IdKey>): Promise<Entity> {
    const collection = await firstValueFrom(this.collection);
    // @ts-ignore
    const result = await collection.insert(this.withId(entity));

    return result.toMutableJSON();
  }

  async bulkInsert(entities: WithOptionalId<Entity, IdKey>[]): Promise<Entity[]> {
    const collection = await firstValueFrom(this.collection);
    const result = await collection.bulkInsert(
      // @ts-ignore
      entities.map((entity) => this.withId(entity)),
    );

    return result.success.map((doc) => doc.toMutableJSON());
  }

  async bulkRemove(ids: Entity[IdKey][]): Promise<Entity[]> {
    const collection = await firstValueFrom(this.collection);
    const result = await collection.bulkRemove(
      ids.map((id) => {
        this.assertIsString(id);
        return id;
      }),
    );

    return result.success.map((doc) => doc.toMutableJSON());
  }

  async removeOne(id: Entity[IdKey]): Promise<Entity> {
    const collection = await firstValueFrom(this.collection);
    this.assertIsString(id);
    const entities = await collection.bulkRemove([id]);

    return entities[0];
  }

  count(query: MangoQuery<Entity> = {}): Observable<number> {
    return this.collection.pipe(
      switchMap((collection) => {
        return collection.count(query).$;
      }),
    );
  }
}
