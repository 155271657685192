import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const VkIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g transform="translate(-10 -135.741)">
        <rect
          width="20"
          height="20"
          rx="4"
          transform="translate(10 135.741)"
          fill="#4c75a3"
        />
        <path
          d="M90.372 160H89c-.392 0-.47.184-.47.388a11 11 0 0 0 2.164 4.544 5.34 5.34 0 0 0 4.181 2.507c.871 0 .979-.2.979-.533v-1.229c0-.392.083-.47.358-.47.2 0 .552.1 1.365.886.929.929 1.083 1.346 1.605 1.346h1.371c.392 0 .587-.2.475-.582a5.883 5.883 0 0 0-1.156-1.606c-.319-.377-.8-.784-.944-.987-.2-.261-.145-.377 0-.61a17.161 17.161 0 0 0 1.844-3.15c.087-.29 0-.5-.415-.5h-1.37a.592.592 0 0 0-.6.388 11.463 11.463 0 0 1-1.684 2.8c-.319.319-.465.421-.639.421-.087 0-.213-.1-.213-.392V160.5c0-.348-.1-.5-.392-.5h-2.15a.33.33 0 0 0-.349.315c0 .33.494.406.545 1.336v2.018c0 .442-.08.523-.254.523-.465 0-1.595-1.706-2.265-3.659-.132-.38-.264-.533-.614-.533z"
          transform="translate(-74.795 -17.979)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </SvgIcon>
  );
};
