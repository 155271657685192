import { Container } from 'inversify';

import { BILLING_SETTINGS_TYPES } from '@/ioc/types';

import type { IBillingSettingsUseCase } from './domain/abstractions/IBillingSettingsUseCase';
import { BillingSettingsUseCase } from './domain/BillingSettingsUseCase';

export const bindBillingSettingsModule = (container: Container): void => {
  container
    .bind<IBillingSettingsUseCase>(BILLING_SETTINGS_TYPES.BillingSettingsUseCase)
    .to(BillingSettingsUseCase);
};
