import { useInjection } from '@/ioc/ioc.react';
import { AUTH_TYPES } from '@/ioc/types';

import { IAuthUseCase } from '../../domain';

type UseAuthUseCase = () => IAuthUseCase;

export const useAuthUseCase: UseAuthUseCase = () => {
  return useInjection<IAuthUseCase>(AUTH_TYPES.AuthUseCase);
};
