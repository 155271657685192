import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AuthState } from '@/features/common/auth';

import { SplashScreen } from '@/components';

import { getDefaultRoute } from './getDefaultRoute';

export const VerifyEmailGuardRoute: FC<{ authState: AuthState }> = ({ authState }) => {
  switch (authState) {
    case AuthState.Identifying:
      return <SplashScreen />;
    case AuthState.ShouldProvideCredentials:
      return (
        <Navigate to={getDefaultRoute(AuthState.ShouldProvideCredentials)} replace />
      );
    case AuthState.ShouldSetupAccount:
      return <Navigate to={getDefaultRoute(AuthState.ShouldSetupAccount)} replace />;
    case AuthState.ShouldVerifyEmail:
      return <Outlet />;
    case AuthState.CanAccessSite:
      return <Navigate to={getDefaultRoute(AuthState.CanAccessSite)} replace />;
  }
};
