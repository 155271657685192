/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { ITagEntity, ITagUseCase } from '@/features/common/tag';
import { TagNameAlreadyExistError } from '@/features/common/tag/domain/errors';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';

import { useModalController } from '@/hooks';

import { useFormWithSchema } from '@/utils/validation';

export const DEFAULT_TAG_COLOR = '#f8c291';
export const TAG_COLORS = [
  '#60a3bc',
  '#4a69bd',
  '#f8c291',
  '#b8e994',
  '#ffc048',
  '#f8a5c2',
  '#63cdda',
  '#303952',
  '#786fa6',
  '#f6e58d',
  '#ef5777',
  '#c7ecee',
];

const TagSettingsFormSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(1, 'createTag.errors.minNameLength')
    .max(30, 'createTag.errors.maxNameLength')
    .required('createTag.errors.nameRequired'),
  color: yup.string().required('colorRequired'),
});

export const useTagSettingsDialogViewModel = ({
  onClose,
  tag,
}: {
  onClose: () => void;
  tag?: Pick<ITagEntity, 'name' | 'color' | 'uuid'>;
}) => {
  const { t } = useTranslation('tag');
  const analytics = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const editTagModal = useModalController('EditTagModal');
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);
  const form = useFormWithSchema(TagSettingsFormSchema, {
    mode: 'onChange',
    defaultValues: {
      name: tag?.name ?? '',
      color: tag?.color ?? DEFAULT_TAG_COLOR,
    },
  });
  const name = form.watch('name');
  const color = form.watch('color');
  const saveDisabled =
    form.formState.isSubmitting || !form.formState.isDirty || !form.formState.isValid;

  const createTag = async (values: { name: string; color: string }): Promise<void> => {
    try {
      await tagUseCase.addTag({
        color: values.color,
        name: values.name.trim(),
      });
      analytics.trackEvent(ANALYTICS_EVENTS.TAG_ADD_TAG, {
        platform: Platform.Web,
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('createTag.toastSuccessTitle'),
        description: t('createTag.toastSuccessSubtitle'),
      });
      onClose();
    } catch (error) {
      if (error instanceof TagNameAlreadyExistError) {
        enqueueSnackbar({
          variant: 'error',
          message: t('createTag.toastNameExistsTitle'),
          description: t('createTag.toastNameExistsSubtitle'),
        });
        form.setError('name', {
          message: error.message,
        });
      }
    }
  };

  const updateTag = async (): Promise<void> => {
    if (!tag) return;

    try {
      await tagUseCase.updateTag({
        uuid: tag.uuid,
        name: name,
        color: color,
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('editTag.toastSuccessTitle'),
        description: t('editTag.toastSuccessSubtitle'),
      });
      editTagModal.onEditTagModalClose();
      onClose();
    } catch (error) {
      if (error instanceof TagNameAlreadyExistError) {
        enqueueSnackbar({
          variant: 'error',
          message: t('editTag.toastErrorExistsTitle'),
          description: t('editTag.toastErrorExistsSubtitle'),
        });
        editTagModal.onEditTagModalClose();
        form.setError('name', {
          message: error.message,
        });
      }
    }
  };

  const handleSave = (): void => {
    void form.handleSubmit((values) => {
      if (!!tag) {
        editTagModal.onEditTagModalOpen();
      } else {
        createTag(values);
      }
    })();
  };

  const handleChangeName = (name: string): void => {
    form.setValue('name', name.replace(/[^\p{L}\p{N}\s_-]/gu, '').trimStart(), {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    form.reset({
      name: tag?.name ?? '',
      color: tag?.color ?? DEFAULT_TAG_COLOR,
    });
  }, [tag?.name, tag?.color, tag?.uuid]);

  return {
    updateTag,
    form,
    handleSave,
    handleChangeName,
    editTagModal,
    colorsList: TAG_COLORS,
    saveDisabled,
  };
};
