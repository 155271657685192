import { inject, injectable } from 'inversify';

import { SERVER_TIME_TYPES } from '@/ioc/types';

import { IServerTimeRepository } from '../data';

import { IServerTimeUseCase } from './abstractions';

@injectable()
export class ServerTimeUseCase implements IServerTimeUseCase {
  @inject(SERVER_TIME_TYPES.ServerTimeRepository)
  private serverTimeRepository: IServerTimeRepository;

  public sync(): Promise<number> {
    return this.serverTimeRepository.sync();
  }
}
