import { FC, useEffect } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { EXTENSION_AUTH_TYPES } from '@/ioc/types';

import { IExtensionAuthUseCase } from '../../domain';

export const AuthExtensionSync: FC = () => {
  const extensionAuthUseCase = useInjection<IExtensionAuthUseCase>(
    EXTENSION_AUTH_TYPES.ExtensionAuthUseCase,
  );

  useEffect(() => {
    const subscription = extensionAuthUseCase.syncAuthWithExtension().subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
};
