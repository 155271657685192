import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { RETENTION_TYPES } from '@/ioc/types';

import { IRetentionRepository } from '../domain/abstractions/repositories/IRetentionRepository';

import { IRetentionServicesApi } from './network';

@injectable()
export class RetentionRepository implements IRetentionRepository {
  @inject(RETENTION_TYPES.RetentionApiService)
  private retentionServiceApi: IRetentionServicesApi;

  acceptRetentionOffer(): Observable<void> {
    return this.retentionServiceApi.acceptRetentionOffer();
  }
}
