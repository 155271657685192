import React from 'react';
import { Box, Button, Popover } from '@mui/material';
import clsx from 'clsx';

import { UserRole } from '@/features/common/account';

import { ArrowDownIcon } from '@/components/Icons';

import { useRoleManagerButtonViewModel } from './useRoleManagerButtonViewModel';

import styles from './styles.module.scss';

type Props = {
  role: UserRole;
  email: string;
  roleTitle: string;
};

export const RoleManagementButton: React.FC<Props> = ({ email, roleTitle }) => {
  const { menu } = useRoleManagerButtonViewModel(email);

  return (
    <>
      <Button
        variant="outlined"
        color="info"
        size={'small'}
        endIcon={<ArrowDownIcon />}
        className={clsx(styles.ChangePermissionButton, {
          [styles.Expanded]: !!menu.anchorEl,
        })}
        onClick={menu.open}
      >
        {roleTitle}
      </Button>
      <Popover
        classes={{ paper: styles.DialogPaper }}
        anchorEl={menu.anchorEl}
        onClose={menu.close}
        open={!!menu.anchorEl}
      >
        <Box className={styles.RolesWrapper}>
          {menu.options.map((option) => (
            <Box
              onClick={option.onClick}
              className={styles.Role}
              role="button"
              tabIndex={0}
              key={option.label}
            >
              <Box component="span">{option.label}</Box>
              <Box component="span">{option.description}</Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};
