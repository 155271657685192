import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { ICreditsDetailsEntity } from '@/features/billing/domain/entities/CreditsDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { ObservableResult, useObservableResult } from '@/utils/rx';

type SourceKey = 'planFull' | 'planBulk' | 'referral' | 'rolledUp' | 'gift' | 'unknown';

type UseCreditsOverviewVideModel = {
  bySources: {
    [key in SourceKey]?: Nullable<FormatResult>;
  };
};

type FormatResult = {
  title: string;
  description?: string;
  credits: string;
  isOutOfCredits: boolean;
  buttonType?: 'upgrade' | 'getMoreCresits';
};

export const useCreditsOverviewVideModel =
  (): ObservableResult<UseCreditsOverviewVideModel> => {
    const { t } = useTranslation('billingSettings', {
      keyPrefix: 'subscriptionOverview.statistics',
    });

    const billingSettingsUseCase = useBillingSettingsUseCase();

    const formatPlanFullCredits = (
      data: ICreditsDetailsEntity['bySources']['planFull'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      let buttonType: FormatResult['buttonType'];
      if (!data.isUnlimited) {
        buttonType = 'getMoreCresits';
      }
      if (data.plan?.isFree) {
        buttonType = 'upgrade';
      }

      return {
        title: data.isUnlimited
          ? t('bySources.planFull.titleUnlimited')
          : t('bySources.planFull.titleDefault', { plan: data.plan?.name ?? 'Unknown' }),
        description: data.isUnlimited
          ? t('bySources.planFull.descriptionUnlimited')
          : undefined,
        credits: data.isUnlimited
          ? t('bySources.planFull.credits', {
              used: data.used,
            })
          : `${data.used}/${data.limit}`,
        isOutOfCredits: data.isUnlimited ? false : data.limit <= data.used,
        buttonType: buttonType,
      };
    };

    const formatPlanBulkCredits = (
      data: ICreditsDetailsEntity['bySources']['planBulk'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.planBulk.title'),
        description: t('bySources.planBulk.description'),
        credits: data.isUnlimited
          ? t('bySources.planBulk.credits', {
              used: data.used,
            })
          : `${data.used}/${data.limit}`,
        isOutOfCredits: data.isUnlimited ? false : data.limit <= data.used,
      };
    };

    const formatReferralCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.referral.title'),
        isOutOfCredits: data.limit <= data.used,
        credits: `${data.used}/${data.limit}`,
      };
    };

    const formatRolledUpCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.rolled_up.title'),
        isOutOfCredits: data.limit <= data.used,
        credits: `${data.used}/${data.limit}`,
      };
    };

    const formatGiftCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.gift.title'),
        isOutOfCredits: data.limit <= data.used,
        credits: `${data.used}/${data.limit}`,
      };
    };

    const formatUnknownCredits = (
      data: ICreditsDetailsEntity['bySources']['unknown'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.gift.title'),
        isOutOfCredits: data.limit <= data.used,
        credits: `${data.used}/${data.limit}`,
      };
    };

    return useObservableResult<UseCreditsOverviewVideModel>(
      () =>
        billingSettingsUseCase.getCreditsDetails().pipe(
          map(({ bySources }) => ({
            bySources: {
              planFull: formatPlanFullCredits(bySources.planFull),
              planBulk: formatPlanBulkCredits(bySources.planBulk),
              referral: formatReferralCredits(bySources.referral),
              rolledUp: formatRolledUpCredits(bySources.rolled_up),
              gift: formatGiftCredits(bySources.gift),
              unknown: formatUnknownCredits(bySources.unknown),
            },
          })),
        ),
      { deps: [] },
    );
  };
