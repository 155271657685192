import { FC, PropsWithChildren, ReactElement } from 'react';
import { Box } from '@mui/material';

import styles from './styles.module.scss';

type StartViewErrorProps = {
  icon: ReactElement;
  title: string;
  fileName: string;
};

export const StartViewError: FC<PropsWithChildren<StartViewErrorProps>> = ({
  icon,
  title,
  fileName,
  children,
}) => {
  return (
    <Box className={styles.Content}>
      <Box className={styles.Icon}>{icon}</Box>
      <Box className={styles.Title} component="span">
        {title}
      </Box>
      <Box className={styles.SubTitle} component="span">
        {fileName || ''}
      </Box>
      <Box className={styles.Progress} />
      <Box className={styles.Info} component="span">
        {children}
      </Box>
    </Box>
  );
};
