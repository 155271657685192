import { InvitationEntity } from '@/features/common/workspace/domain';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { useWorkspaceUseCase } from '../../../hooks';

export const useWorkspaceInvitationViewModel = (): ObservableResult<
  Nullable<InvitationEntity>
> => {
  const workspaceUseCase = useWorkspaceUseCase();
  return useObservableResult(() => workspaceUseCase.getInvitationToAnotherWorkspace());
};
