import { FC, PropsWithChildren, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { EnrichmentUploadingContext } from '../../../../../../EnrichmentUploadingContext';

import FileIcon from './assets/File.svg?react';

import styles from './styles.module.scss';

const ViewHeaderFileMetadata: FC = () => {
  const { fileMetadata } = useContext(EnrichmentUploadingContext);
  const { t } = useTranslation('enrichment');

  return (
    <Box className={styles.FileMeta}>
      <Box className={styles.FileMetaIcon}>
        <FileIcon />
      </Box>
      <Box className={styles.FileMetaContent}>
        <Box className={styles.FileMetaName} component="span">
          {fileMetadata?.name}
        </Box>
        <Box className={styles.FileMetaCount} component="span">
          {t('fileMetadata.rows', { count: fileMetadata?.rowsCount || 0 })}
        </Box>
      </Box>
    </Box>
  );
};

export type HeaderProps = {
  title: string;
  showFileMetadata?: boolean;
};

export const Header: FC<HeaderProps> = ({ showFileMetadata = false, title }) => {
  return (
    <Box className={styles.Header}>
      <Box className={styles.Title}>{title}</Box>
      {showFileMetadata && <ViewHeaderFileMetadata />}
    </Box>
  );
};

const Container: FC<PropsWithChildren> = ({ children }) => {
  return <Box className={styles.Container}>{children}</Box>;
};

type ActionsProps = {
  leftButton?: ReactElement;
  rightButton?: ReactElement;
  text?: string;
};

const Actions: FC<ActionsProps> = ({ leftButton, rightButton, text }) => {
  return (
    <Box className={styles.Actions}>
      {leftButton}
      <Box className={styles.ActionsInfo} component="span">
        {text}
      </Box>
      {rightButton}
    </Box>
  );
};

export const ViewLayout: FC<PropsWithChildren> & {
  Header: FC<HeaderProps>;
  Container: FC<PropsWithChildren>;
  Actions: FC<ActionsProps>;
} = ({ children }) => {
  return <Box className={styles.Wrapper}>{children}</Box>;
};

ViewLayout.Header = Header;
ViewLayout.Container = Container;
ViewLayout.Actions = Actions;
