import { ICompanyDC, IContactDC } from '@/features/common/contact';

export interface IProspectTaskEnrichmentDataDC {
  linkedin_id: string;
  company_id: string | null;
  name: string;
  title: string | null;
  city: string | null;
  country: string | null;
  language: string | null;
  jobs: IProspectTaskEnrichmentDataJobDC[];
  followers: number;
  company_data: IProspectTaskEnrichmentDataCompanyDC | null;
}

export interface IProspectTaskEnrichmentDataJobDC {
  company_id?: string;
  title?: string;
  name?: string;
  start_date: string;
  end_date?: string;
  image?: string;
  description?: string;
  location?: string;
}

export interface IProspectTaskEnrichmentDataCompanyDC {
  linkedin_source_id?: string;
  linkedin_id?: string;
  name?: string;
}

export interface IProspectTaskEnrichmentResultDC {
  contact: IContactDC;
  is_in_workspace: boolean;
  companies: Record<string, ICompanyDC>;
}

export enum ProspectTaskStatus {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed',
}

export enum ProspectTaskEnrichmentType {
  Full = 'full',
  Basic = 'basic',
}

export interface IProspectTaskDC {
  uuid: string;
  enrichment_type: ProspectTaskEnrichmentType;
  enrichment_data: IProspectTaskEnrichmentDataDC[];
  enrichment_result: IProspectTaskEnrichmentResultDC[] | null;
  ignore_cache: boolean;
  status: ProspectTaskStatus;
  append_tags: null | string[];
  contact_list_id: string;
}
