import { useContext, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { indexBy, prop } from 'ramda';

import { EnrichmentFileHeaderField } from '@/features/enrichment/domain/types';
import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';

import { FormContext } from '../../../../FormContext';

type UseExampleDataViewModelResult = {
  data: string;
  isValid: boolean;
  error: FieldError | undefined;
};

export const useExampleDataViewModel = (
  fieldName: EnrichmentFileHeaderField,
): UseExampleDataViewModelResult => {
  const { fileUploadResponse } = useContext(EnrichmentUploadingContext);
  const { fieldsMapperForm } = useContext(FormContext);

  const value = fieldsMapperForm.watch(`headers_map.${fieldName}`);

  const exampleDataMapByCsvHeaderName = useMemo(() => {
    if (!fileUploadResponse) return {};

    return indexBy(prop('csv_name'), fileUploadResponse.headers);
  }, [fileUploadResponse?.uuid]);

  const error = fieldsMapperForm.formState.errors.headers_map?.[fieldName];
  const isValid = !error && value !== '';

  let data = exampleDataMapByCsvHeaderName[value]?.example ?? '';
  if (data.endsWith(',')) {
    data = data.slice(0, -1);
  }

  return {
    data,
    error,
    isValid,
  };
};
