import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { Preloader } from '@/components';

export const ChangeCardAction: FC<{
  isCardChanging: boolean;
  onClick: () => void;
  disabled?: boolean;
}> = ({ isCardChanging, onClick, disabled }) => {
  const { t } = useTranslation('billingSettings', { keyPrefix: 'paymentDetails' });

  return (
    <Button
      variant="outlined"
      size="small"
      color="info"
      disabled={isCardChanging || disabled}
      onClick={onClick}
    >
      {isCardChanging ? (
        <Preloader />
      ) : (
        <Typography variant="textInButton" fontSize={12} fontWeight={500}>
          {t('changeCard')}
        </Typography>
      )}
    </Button>
  );
};
