import { useState } from 'react';

type UseReduceActiveUsersDialog = () => {
  onOpen: (parmas: { activeUsers: number; usersToReduce: number }) => void;
  onClose: () => void;
  isOpened: boolean;
  activeUsers: number;
  usersToReduce: number;
};

export const useReduceActiveUsersDialog: UseReduceActiveUsersDialog = () => {
  const [state, setState] = useState({
    isOpened: false,
    activeUsers: 0,
    usersToReduce: 0,
  });

  return {
    onOpen: ({ activeUsers, usersToReduce }): void => {
      setState({
        isOpened: true,
        activeUsers,
        usersToReduce,
      });
    },
    onClose: (): void => {
      setState({
        isOpened: false,
        activeUsers: 0,
        usersToReduce: 0,
      });
    },
    ...state,
  };
};
