import * as React from "react";
const SvgEmailVerification = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 114, height: 106, viewBox: "0 0 114 106", ...props }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18044", transform: "translate(-166 -163)" }, /* @__PURE__ */ React.createElement("path", { d: "M7 67.521h50.486a7.423 7.423 0 0 0 7.414-7.414V25.849a1.1 1.1 0 0 0-.572-.967L37.027 1.764a7.391 7.391 0 0 0-9.588 0L.224 24.849a1.1 1.1 0 0 0-.638 1v34.258A7.424 7.424 0 0 0 7 67.521z", transform: "translate(190.757 189.729)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "mail", d: "M7 67.521h50.486a7.423 7.423 0 0 0 7.414-7.414V25.849a1.1 1.1 0 0 0-.572-.967L37.027 1.764a7.391 7.391 0 0 0-9.588 0L.224 24.849a1.1 1.1 0 0 0-.638 1v34.258A7.424 7.424 0 0 0 7 67.521zm0-2.209a5.18 5.18 0 0 1-3.152-1.066L18.946 48.8a1.1 1.1 0 1 0-1.579-1.544L2.411 62.558a5.164 5.164 0 0 1-.616-2.452V28.283L27.46 50.016a7.392 7.392 0 0 0 9.588 0l25.644-21.753v31.844a5.17 5.17 0 0 1-.619 2.46l-15.047-15.31a1.1 1.1 0 0 0-1.575 1.548l15.18 15.446a5.177 5.177 0 0 1-3.146 1.061zm21.867-61.86a5.19 5.19 0 0 1 6.732 0l26.489 22.426L35.62 48.327a5.19 5.19 0 0 1-6.731 0L2.4 25.9zm0 0", transform: "translate(190.757 189.729)", style: {
  fill: "#2173ff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32152", d: "M-7548.8 47.888V27.672h47.7v20.216", transform: "translate(7747.947 172.99)", style: {
  stroke: "#2173ff",
  strokeLinecap: "square",
  strokeLinejoin: "round",
  strokeWidth: "2.5px",
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18443", width: 33.187, height: 3.073, rx: 1.536, transform: "translate(206.322 209.467)", style: {
  fill: "#f5f5fa"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18444", width: 33.187, height: 3.073, rx: 1.536, transform: "translate(206.322 214.384)", style: {
  fill: "#f5f5fa"
} }), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 18445", width: 20.895, height: 3.073, rx: 1.536, transform: "translate(212.467 219.3)", style: {
  fill: "#f5f5fa"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1531", cx: 18, cy: 18, r: 18, transform: "translate(240 233)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32154", d: "M14.93 0a14.93 14.93 0 1 0 14.93 14.93A14.947 14.947 0 0 0 14.93 0zm0 27.145A12.215 12.215 0 1 1 27.145 14.93 12.229 12.229 0 0 1 14.93 27.145z", style: {
  fill: "#2ed573"
}, transform: "translate(243.14 236.07)" }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 32155", d: "m91.035 102.668-7.678 7.678-3.519-3.519a1.357 1.357 0 1 0-1.919 1.919l4.479 4.479a1.357 1.357 0 0 0 .96.4 1.358 1.358 0 0 0 .96-.4l8.638-8.638a1.357 1.357 0 0 0-1.919-1.92z", transform: "translate(172.633 143.053)", style: {
  fill: "#2ed573"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18020" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18448", transform: "translate(203 163)", style: {
  stroke: "#ff5001",
  fill: "#fff"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18449", transform: "rotate(90 20.5 185.5)", style: {
  stroke: "#ff5001",
  fill: "#fff"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18021" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18451", transform: "translate(272 214)", style: {
  stroke: "#05e39c",
  fill: "#05e39c"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18450", transform: "rotate(90 29.5 245.5)", style: {
  stroke: "#05e39c",
  fill: "#05e39c"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18022" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18453", transform: "translate(196 190)", style: {
  stroke: "#fe93bb",
  fill: "#fe93bb"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 18452", transform: "rotate(90 3.5 195.5)", style: {
  stroke: "#fe93bb",
  fill: "#fe93bb"
} }, /* @__PURE__ */ React.createElement("rect", { width: 1, height: 5, rx: 0.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, height: 4, style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Ellipse 1538", transform: "translate(187 187)", style: {
  strokeWidth: 2,
  stroke: "#fff",
  fill: "none"
} }, /* @__PURE__ */ React.createElement("ellipse", { cx: 3.5, cy: 3, rx: 3.5, ry: 3, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 3.5, cy: 3, rx: 2.5, ry: 2, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Ellipse 1539", transform: "translate(273 189)", style: {
  stroke: "#fed402",
  strokeWidth: 2,
  fill: "none"
} }, /* @__PURE__ */ React.createElement("circle", { cx: 3.5, cy: 3.5, r: 3.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3.5, cy: 3.5, r: 2.5, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "Ellipse 1547", transform: "translate(173 183)", style: {
  stroke: "#fed402",
  strokeWidth: 2,
  fill: "none"
} }, /* @__PURE__ */ React.createElement("circle", { cx: 3.5, cy: 3.5, r: 3.5, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3.5, cy: 3.5, r: 2.5, style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1540", cx: 2, cy: 2, r: 2, transform: "translate(233 179)", style: {
  fill: "#fed402"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1541", cx: 2, cy: 2, r: 2, transform: "translate(166 202)", style: {
  fill: "#05e39c"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1542", cx: 2, cy: 2, r: 2, transform: "translate(260 176)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("circle", { "data-name": "Ellipse 1543", cx: 2, cy: 2, r: 2, transform: "translate(254 200)", style: {
  fill: "#fe93bb"
} })));
export default SvgEmailVerification;
