import { FC, useState } from 'react';

import { Modal, ModalProps } from './Modal';

export const useModal = (): {
  isShown: boolean;
  openModal: () => void;
  closeModal: () => void;
  Modal: FC<Partial<ModalProps>>;
} => {
  const [isShown, setIsShow] = useState(false);

  const openModal = (): void => {
    setIsShow(true);
  };

  const closeModal = (): void => {
    setIsShow(false);
  };

  return {
    isShown,
    openModal,
    closeModal,
    Modal: ({ children, ...props }) => (
      <Modal open={isShown} onClose={closeModal} {...props}>
        {/* @ts-ignore*/}
        {children}
      </Modal>
    ),
  };
};
