import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IWorkspaceDC } from '@/features/common/workspace';
import type { ITeamMemberApiService } from '@/features/settings';
import type { IHttpClient } from '@/features/system/network';

@injectable()
export default class TeamMemberApiService implements ITeamMemberApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  inviteTeamMembers(
    payload: {
      email: string;
      role: string;
    }[],
  ): Observable<IWorkspaceDC> {
    return this.httpClient
      .put('/api/v1/workspaces/members', payload)
      .pipe(map((response: AxiosResponse<IWorkspaceDC>) => response.data));
  }

  deleteTeamMembers(
    payload: {
      email: string;
      reassign_to: string;
    }[],
  ): Observable<boolean> {
    return this.httpClient
      .delete('/api/v1/workspaces/members', payload)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }

  resendInvitation(payload: { email: string; role: string }): Observable<IWorkspaceDC> {
    return this.httpClient
      .put('/api/v1/workspaces/members?resend_invitation=true', payload)
      .pipe(map((response: AxiosResponse<IWorkspaceDC>) => response.data));
  }
}
