import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlashOutlinedIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 9.158 14.77">
    <path
      d="M117.8-.5h5.334l-1.389 4.49h3.323l-6.587 10.28-.919-.314.476-5.411h-2.128zm3.978 1h-3.166l-1.472 7.045h1.989l-.393 4.473 4.5-7.028h-2.85z"
      transform="translate(-115.911 .5)"
      fill="#8083a3"
    />
  </SvgIcon>
);
