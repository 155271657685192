import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { LockTimer } from '@/components/LockTimer/LockTimer';

import EmailVerificationIcon from '@/assets/icons/EmailVerification.svg?react';

import useEmailConfirmationViewModel from './useEmailConfirmationViewModel';

import styles from './styles.module.scss';

export const EmailVerification: FC = () => {
  const { email, handleChangeEmail, resendCode, onComplete, lockedUntil } =
    useEmailConfirmationViewModel();
  const { t } = useTranslation('auth');

  return (
    <Box className={styles.wrapper}>
      <EmailVerificationIcon />
      <Typography className={styles.title} variant={'h1'}>
        {t('verification.title')}
      </Typography>
      <Typography className={styles.description} variant={'subtitle1'}>
        <Trans t={t} i18nKey={'verification.description'}>
          We sent a verification email to {/*@ts-ignore*/}
          <span className={styles.highlightText}>{{ email }}</span>. To activate your
          account, click the verification link in the email.
        </Trans>
      </Typography>
      <Typography className={clsx(styles.description, styles.changeEmail)}>
        <Trans t={t} i18nKey={'verification.changeEmail'}>
          Wrong Email?
          <span
            className={clsx(styles.highlightText, styles.link)}
            onClick={handleChangeEmail}
          >
            Change Email
          </span>
        </Trans>
      </Typography>
      <Typography className={styles.resendEmail}>
        <Trans
          t={t}
          i18nKey={'verification.resendCode'}
          components={{
            span: (
              <span
                className={clsx(styles.highlightText, styles.link)}
                onClick={resendCode}
              />
            ),
          }}
        >
          Don’t see the email? Check your spam folder.
          <span className={clsx(styles.highlightText, styles.link)} onClick={resendCode}>
            Resend Code
          </span>
        </Trans>
      </Typography>
      <LockTimer onComplete={onComplete} lockedUntil={lockedUntil} />
    </Box>
  );
};
