import React, { useEffect } from 'react';
import { filter, first } from 'rxjs';

import { useAccountUseCase } from '@/features/common/account';

import { useHubspotUseCase } from './useHubspotUseCase';

export const Hubspot: React.FC = () => {
  const accountUseCase = useAccountUseCase();
  const hubspotUseCase = useHubspotUseCase();

  useEffect(() => {
    if (import.meta.env.REACT_APP_FEATURE_HUBSPOT_SUGNUP === 'true') {
      const sub = accountUseCase
        .getAccount()
        .pipe(
          filter((account) => !account?.settings.isImportedToHubspot),
          first(),
        )
        .subscribe(() => {
          hubspotUseCase.setupHubspotContact();
        });

      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  return null;
};
