import type { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      minHeight: 'auto',
    },
    indicator: {
      height: '5px',
      borderRadius: '5px 5px 0 0',
    },
    flexContainer: {
      gap: '10px',
    },
  },
};

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '14px',
      fontWeight: 'normal',
      color: theme.palette.info?.main,
      padding: '0 10px 14px 10px',
      minWidth: 'auto',
      minHeight: 'auto',
      textTransform: 'initial',
      lineHeight: 1.2,
      '&.Mui-selected': {
        fontWeight: 600,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    }),
  },
};
