import { useInjection } from '@/ioc/ioc.react';
import { LOGIN_TYPES } from '@/ioc/types';

import { ISignInUseCase } from '../../domain/abstractions/ISignInUseCase';

type UseLoginUseCase = () => ISignInUseCase;

export const useLoginUseCase: UseLoginUseCase = () => {
  return useInjection<ISignInUseCase>(LOGIN_TYPES.SignInUseCase);
};
