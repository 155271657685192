import type { IProductEntity } from '@/features/common/billing';

import type { ProductPrice } from '../entities/GroupedProductMetadata';

import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class FreeProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    return {
      title: 'What’s included:',
      items: [
        'Access to millions of verified professional contact details',
        'Powerlead Chrome Extension',
        'Dashboard and list management',
        'Get more free credits by inviting friends',
      ],
    };
  }

  getName(): string {
    return 'Free';
  }

  getDescription(): string {
    return 'Get to know Powerlead, no strings attached';
  }

  getIsCreditCardRequired(): boolean {
    return false;
  }

  getIsMostPopular(): boolean {
    return false;
  }

  protected override isCurrentProduct(): boolean {
    return this.subscription.planIsFree;
  }

  protected override getPrice(product: IProductEntity): ProductPrice {
    return product.price;
  }

  /**
   * we do not have annual product for free plan, so we return the monthly product,
   * which is the same as the annual product
   */
  override getAnnualProduct(): IProductEntity {
    return this.getMonthlyProduct();
  }
}
