import { ReactNode, useContext, useMemo } from 'react';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { ProductCredits, ProductPrice } from '@/features/plans/domain';
import { PlanActionResolverContext } from '@/features/plans/ui/contexts/PlanActionResolverContext';
import { PlanAction } from '@/features/plans/ui/types';

import { PlanFeaturesMetadataContext } from '../../contexts';
import { BillingCycleContext } from '../../contexts/BillingCycleContext';
import { PlansMetadataContext } from '../../contexts/PlanMetadataContext';

type PlanCardViewModel = {
  billingCycle: BillingCycle;
  name: string;
  description: string;
  perSeatPrice: ProductPrice;
  totalPrice?: number;
  credits: ProductCredits;
  isPopular: boolean;
  features: {
    title: string;
    items: Array<string | ReactNode>;
  };
  featuresDetailed: {
    category: string;
    items: { text: string; hint: string }[];
  }[];
  action: PlanAction;
  onAction: () => void;
  creditCardRequired: boolean;
};

export function usePlanCardViewModel(params: { planType: PlanType }): PlanCardViewModel {
  const planActionResolver = useContext(PlanActionResolverContext);
  const plansMetadata = useContext(PlansMetadataContext);
  const billingCycle = useContext(BillingCycleContext);
  const planFeaturesMetadata = useContext(PlanFeaturesMetadataContext);

  const planMetadata = plansMetadata[params.planType];

  const price = planMetadata.variants[billingCycle].price;
  const credits = planMetadata.variants[billingCycle].fullCredits;
  const originalProduct = planMetadata.variants[billingCycle].originalProduct;

  const action = planActionResolver.resolve({
    planType: params.planType,
    billingCycle,
    originalProduct,
  });

  const featureDetailed = useMemo(() => {
    return Object.values(planFeaturesMetadata)
      .map((featureByCategory) => {
        return {
          category: featureByCategory.title,
          items: Object.values(featureByCategory.features)
            .filter(
              (feature) =>
                feature.supportByPlan[params.planType].brandTag === 'supported',
            )
            .map((feature) => ({
              text: feature.title,
              hint: feature.hint,
            })),
        };
      })
      .filter((feature) => feature.items.length > 0);
  }, [planFeaturesMetadata, params.planType]);

  return {
    billingCycle,
    name: planMetadata.name,
    description: planMetadata.description,
    isPopular: planMetadata.mostPopular ?? false,
    perSeatPrice: price,
    totalPrice: planMetadata.variants[billingCycle].totalPrice,
    credits,
    features: planMetadata.marketingFeatures,
    featuresDetailed: featureDetailed,
    action,
    onAction: (): void => {
      planActionResolver.onAction({
        action,
        planType: params.planType,
        billingCycle: billingCycle,
        originalProduct,
      });
    },
    creditCardRequired: planMetadata.creditCardRequired,
  };
}
