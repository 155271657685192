import { ITeamMemberDC, ITeamMemberEntity } from '@/features/settings';

export const mapTeamMemberDcToEntity = (dc: ITeamMemberDC): ITeamMemberEntity => ({
  uuid: dc.uuid,
  email: dc.email,
  thumbnail: dc.thumbnail ?? '',
  fullName: dc.full_name ?? '',
  invitationStatus: dc.invitation_status,
  invitedBy: dc.invited_by,
  invitedAt: dc.invited_at,
  acceptedInvitationAt: dc.accepted_invitation_at,
  role: dc.role,
  lastLoginAt: dc.last_login_at,
});

export const mapTeamMemberEntityToDc = (entity: ITeamMemberEntity): ITeamMemberDC => ({
  uuid: entity.uuid,
  email: entity.email,
  thumbnail: entity.thumbnail,
  full_name: entity.fullName,
  invitation_status: entity.invitationStatus,
  invited_by: entity.invitedBy,
  invited_at: entity.invitedAt,
  accepted_invitation_at: entity.acceptedInvitationAt,
  role: entity.role,
  last_login_at: entity.lastLoginAt,
});
