import * as React from "react";
const SvgInstallExtensionIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 51.62, height: 39.062, viewBox: "0 0 51.62 39.062", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "xjlybyfdoa" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 22", style: {
  fill: "#fff"
}, d: "M0 0h8.324v11.322H0z" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18713", transform: "translate(5019.253 -7270.556)" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16722" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31994", d: "M46.677 111.154H43.7V89.306a3.976 3.976 0 0 0-3.973-3.973H7.945a3.976 3.976 0 0 0-3.973 3.973v21.849H.993a.993.993 0 0 0-.993.993v.993a3.976 3.976 0 0 0 3.973 3.973H43.7a3.976 3.976 0 0 0 3.972-3.973v-.993a.993.993 0 0 0-.995-.994zM5.959 89.306a1.989 1.989 0 0 1 1.986-1.986h31.78a1.989 1.989 0 0 1 1.986 1.986v21.849H5.959zM43.7 115.127H3.973a1.989 1.989 0 0 1-1.986-1.986h16.471l.7.7a.992.992 0 0 0 .7.291h7.945a.993.993 0 0 0 .7-.291l.7-.7h16.481a1.989 1.989 0 0 1-1.984 1.986z", transform: "translate(-5019.003 7192.255)", style: {
  stroke: "#fff",
  strokeWidth: ".5px"
} })), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17881", width: 17.308, height: 17.308, rx: 5, transform: "translate(-4984.94 7270.556)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16723" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 12", style: {
  clipPath: "url(#xjlybyfdoa)"
}, transform: "translate(-4980.448 7273.549)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 17", d: "M7.868.988A2.526 2.526 0 0 0 5.749 0H1.122L.344 3.853l1 .022L.317 9.526l-.31 1.641h.019L0 11.322 3.271 2.9h-.826l.167-1.04h2.776a1.007 1.007 0 0 1 .847.375 1.159 1.159 0 0 1 .093 1.055 2.523 2.523 0 0 1-2.182 1.657h-.457l-.723 1.86h.817a4.96 4.96 0 0 0 4.369-3.289 2.8 2.8 0 0 0-.284-2.53", style: {
  fill: "#fff"
} }))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31995", d: "M-4994.888 7286.815a.611.611 0 0 1 .629.59v4.083l1.44-1.353a.659.659 0 0 1 .89 0 .564.564 0 0 1 0 .834l-2.518 2.36a.659.659 0 0 1-.89 0l-2.517-2.364a.564.564 0 0 1 0-.834.659.659 0 0 1 .89 0l1.443 1.353v-4.079a.611.611 0 0 1 .629-.59z" }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31996", d: "M-4998.825 7295.666a.606.606 0 0 1 .621-.59h6.629a.591.591 0 1 1 0 1.18h-6.629a.606.606 0 0 1-.621-.59z" })));
export default SvgInstallExtensionIcon;
