import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ROUTES } from '@/router/routes';

import { InvitationEntity } from '@/features/common/workspace/domain';
import { useWorkspaceUseCase } from '@/features/common/workspace/ui/hooks';
import { useAppLogger } from '@/features/system/logger';

import { useConfirmationModal } from '@/components';

import { useModalController } from '@/hooks';
import { useAsyncExecutor } from '@/hooks/useAsyncExecutor';

import { WorkspaceSeatsLimitError } from '../../../../../../domain/errors/WorkspaceSeatsLimitError';

export const useSwitchModalViewModel = (invitation: InvitationEntity) => {
  const { t } = useTranslation('common');
  const workspaceUseCase = useWorkspaceUseCase();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const logger = useAppLogger();
  const { openModal, closeModal } = useConfirmationModal();
  const processingModal = useModalController('ProcessingModal');

  const rejectAsyncExecutor = useAsyncExecutor(async () => {
    try {
      await workspaceUseCase.rejectSwitchWorkspace({
        uuid: invitation.invitedTo,
      });
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: t('errors.errorOccurred'),
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    }
  });

  const asseptAsyncExecutor = useAsyncExecutor(async () => {
    processingModal.onProcessingModalOpen();
    try {
      await workspaceUseCase.acceptSwitchWorkspace({
        uuid: invitation.invitedTo,
      });
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      logger.error(error);

      if (error instanceof WorkspaceSeatsLimitError) {
        openModal({
          title: t('workspaceInvitation.limitError.title'),
          description: t('workspaceInvitation.limitError.description'),
          buttonText: t('workspaceInvitation.limitError.decline'),
          handleAccept: async () => {
            await rejectAsyncExecutor.asyncExecute();
            closeModal();
          },
        });

        return;
      }
      enqueueSnackbar({
        variant: 'error',
        message: t('errors.errorOccurred'),
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    } finally {
      processingModal.onProcessingModalClose();
    }
  });

  return {
    handleAccept: asseptAsyncExecutor.asyncExecute,
    handleReject: rejectAsyncExecutor.asyncExecute,
    actionsDisabled: asseptAsyncExecutor.isLoading || rejectAsyncExecutor.isLoading,
    processingModal,
  };
};
