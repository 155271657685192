import { useState } from 'react';

import { EXTERNAL_ROUTES } from '@/router/routes';

import type { IProductEntity } from '@/features/common/billing';

import { openInNewTab } from '@/utils/openInNewTab';

export type PlanDowngradeDialogProps = {
  isOpen: boolean;
  text: string;
  onOpen(produt: IProductEntity): void;
  onClose(): void;
  onConfirm(): void;
};

export const usePlanDowngradeDialog = (): PlanDowngradeDialogProps => {
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<IProductEntity | null>(null);

  const text = product ? `${product.name} (${product.cycle})` : '';

  const open = (product: IProductEntity): void => {
    setIsOpen(true);
    setProduct(product);
  };

  const close = (): void => {
    setIsOpen(false);
    setProduct(null);
  };

  const onConfirm = async (): Promise<void> => {
    openInNewTab(EXTERNAL_ROUTES.TALK_TO_SALES);
  };

  return {
    isOpen,
    text,
    onOpen: open,
    onClose: close,
    onConfirm,
  };
};
