import { inject, injectable, postConstruct } from 'inversify';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { APP_LOGGER_TYPES } from '@/ioc/types';

import { IAppLogger } from '@/features/system/logger';

export interface ILeaderElectionRepository {
  getIsLeaderSubject: () => BehaviorSubject<boolean>;
  getIsLeader: () => boolean;
  isLeader: Readonly<boolean>;
  isLeader$: Observable<boolean>;
}

type TabLeaderMessage = {
  type: 'new-tab-leader';
};

@injectable()
export class LeaderElectionRepository implements ILeaderElectionRepository {
  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  private leaderSubject = new BehaviorSubject(false);

  private leaderChannel = new BroadcastChannel('tab-leader');
  private leaderMessages = new Subject<TabLeaderMessage>();

  public get isLeader(): Readonly<boolean> {
    return this.leaderSubject.value;
  }

  public get isLeader$(): Observable<boolean> {
    return this.leaderSubject.asObservable();
  }

  constructor() {
    this.leaderChannel.addEventListener('message', (event): void => {
      this.leaderMessages.next(event.data as TabLeaderMessage);
    });

    this.getVisibillityChange$()
      .pipe(
        switchMap((isVisibil) => {
          if (isVisibil) {
            this.leaderSubject.next(true);
            this.notifyIamLeader();
            return of(true);
          } else {
            return from(this.waitNewLeader()).pipe(
              tap(() => {
                this.leaderSubject.next(false);
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  @postConstruct()
  logStatus(): void {
    this.leaderSubject.pipe(distinctUntilChanged()).subscribe((isLeader) => {
      this.logger.log(`[LeaderElection]: ${isLeader ? 'Leader' : 'Follower'}`);
    });
  }

  private getVisibillityChange$(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const handleVisibilityChange = (): void => {
        subscriber.next(document.visibilityState === 'visible');
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      handleVisibilityChange();

      return (): void => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    });
  }

  private notifyIamLeader = (): void => {
    this.leaderChannel.postMessage({
      type: 'new-tab-leader',
    });
  };

  private waitNewLeader = (): Promise<unknown> => {
    return firstValueFrom(
      this.leaderMessages.pipe(filter((msg) => msg.type === 'new-tab-leader')),
    );
  };

  getIsLeaderSubject = (): BehaviorSubject<boolean> => this.leaderSubject;

  getIsLeader = (): boolean => this.leaderSubject.value;
}
