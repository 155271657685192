type IsEnum<T> = (token: unknown) => token is T[keyof T];

export const createIsEnum = <T extends object>(e: T): IsEnum<T> => {
  const keys = Object.keys(e).filter((k) => {
    return !/^\d/.test(k);
  });

  const values = keys.map((k) => {
    return e[k];
  });

  return (token: unknown): token is T[keyof T] => {
    return values.includes(token);
  };
};
