import { StripeError } from '@stripe/stripe-js';

import { BillingError } from './BillingError';

export class PaymentError extends BillingError {
  constructor(message = 'Payment error') {
    super(message);
  }

  static fromStripeError(error: StripeError): PaymentError {
    return new PaymentError(error.message);
  }
}
