import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import InfoIcon from '@mui/icons-material/Info';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { ProspectTaskStatus } from '../../../data';

import styles from './styles.module.scss';

type ProspectTaskNotificationWidgetItemTypeBase = {
  id: string;
  text: string;
  deleteItem(): void;
};

export type ProspectTaskNotificationWidgetItemTypeInProgress =
  ProspectTaskNotificationWidgetItemTypeBase & {
    status: ProspectTaskStatus.Pending;
    progress?: number;
  };

export type ProspectTaskNotificationWidgetItemTypeCompleted =
  ProspectTaskNotificationWidgetItemTypeBase & {
    status: ProspectTaskStatus.Finished;
  };

export type ProspectTaskNotificationWidgetItemTypeFailed =
  ProspectTaskNotificationWidgetItemTypeBase & {
    status: ProspectTaskStatus.Failed;
    errorMessage?: string;
    onRetry(): Promise<void>;
  };

export type ProspectTaskNotificationWidgetItemType =
  | ProspectTaskNotificationWidgetItemTypeInProgress
  | ProspectTaskNotificationWidgetItemTypeCompleted
  | ProspectTaskNotificationWidgetItemTypeFailed;

export const ProspectTaskNotificationWidgetItem: FC<{
  item: ProspectTaskNotificationWidgetItemType;
}> = ({ item }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (item.status === ProspectTaskStatus.Pending) return;

    const timeout = setTimeout(() => {
      item.deleteItem();
    }, 10_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [item.status]);

  return (
    <Box className={styles.item}>
      <Box className={styles.itemIcon}>
        <GroupOutlined />
      </Box>
      <Box className={styles.itemTextContext}>
        <Box component="span" className={styles.itemText}>
          {item.text}
        </Box>
        {item.status === ProspectTaskStatus.Failed && (
          <Box component="span" className={styles.itemFailedText}>
            {t('prospectTaskProgressNotification.item.errorMessage')}
          </Box>
        )}
      </Box>
      <Box className={styles.itemInfo}>
        {item.status === ProspectTaskStatus.Failed && (
          <Tooltip title={'Retry'}>
            <Box className={styles.itemInfoRetryButton} onClick={item.onRetry}>
              <ReplayIcon />
            </Box>
          </Tooltip>
        )}
        {item.status === ProspectTaskStatus.Pending && (
          <Box className={styles.itemInfoStatus}>
            <CircularProgress color="secondary" size={24} />
          </Box>
        )}
        {item.status === ProspectTaskStatus.Finished && (
          <Box className={clsx([styles.itemInfoStatus, styles.completed])}>
            <CheckCircleIcon />
          </Box>
        )}
        {item.status === ProspectTaskStatus.Failed && (
          <Tooltip title={item.errorMessage}>
            <Box className={clsx([styles.itemInfoStatus, styles.failed])}>
              <InfoIcon />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
