import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { combineLatest, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import {
  BillingCycle,
  IProductEntity,
  PlanType,
  useBillingUseCase,
  usePlanDowngradeDialog,
  useReduceActiveUsersDialog,
} from '@/features/common/billing';
import { useWorkspaceUseCase } from '@/features/common/workspace';
import { PlanActionResolverContextType } from '@/features/plans/ui/contexts';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { CustomerMessagingEventKeys, useCMPage } from '@/features/system/CustomerIO';

import { useDocumentMeta } from '@/hooks';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { useBillingCycleDialogViewModel } from './components/ChangeBillingCycleDialog/BillingCycleDialogViewModel';
import { useAvailablePlans, useNewPlansMetadata } from './hooks';
import { PlanAction, PlanFeatureCategory } from './types';
import { isPlanUpgrade } from './utils';

type PlanTypeActionResolver = (params: {
  currentSeats: number;
  nextSeats: number;
  currentProduct: IProductEntity;
  isCurrentPlanActive: boolean;
  nextProduct?: IProductEntity;
}) => PlanAction;

type ActionResolversMap = Record<PlanType, PlanTypeActionResolver>;

const freePlanActionResolver: PlanTypeActionResolver = ({ currentProduct }) => {
  if (currentProduct.family === 'free') {
    return PlanAction.CurrentPlan;
  }

  return PlanAction.None;
};

const defaultActionResolver: PlanTypeActionResolver = ({
  currentSeats,
  nextSeats,
  nextProduct,
  currentProduct,
  isCurrentPlanActive,
}) => {
  if (currentProduct.id === nextProduct?.id && isCurrentPlanActive) {
    return currentSeats === nextSeats ? PlanAction.CurrentPlan : PlanAction.UpdatePlan;
  }

  if (
    currentProduct.family === nextProduct?.family &&
    currentProduct.cycle !== nextProduct?.cycle &&
    isCurrentPlanActive
  ) {
    return PlanAction.ChangeBillingCycle;
  }

  return PlanAction.UpgradePlan;
};

const ACTION_RESOLVERS: ActionResolversMap = {
  [PlanType.Free]: freePlanActionResolver,
  [PlanType.Pro]: defaultActionResolver,
  [PlanType.Unlimited]: defaultActionResolver,
  [PlanType.Expand]: () => PlanAction.TalkToSales,
};

export type PlansViewModel = {
  planTypesToShow: PlanType[];
  planFeatureCategoriesToShow: PlanFeatureCategory[];
  billingCycleDialog: ReturnType<typeof useBillingCycleDialogViewModel>;
  planDowngradeDialog: ReturnType<typeof usePlanDowngradeDialog>;
  reduceActiveUsersDialog: ReturnType<typeof useReduceActiveUsersDialog>;
  planActionResolver: PlanActionResolverContextType;
  newPlansMetadataResult: ReturnType<typeof useNewPlansMetadata>;
  pageDataResult: ObservableResult<
    {
      currentProduct: IProductEntity;
      billableMembers: number;
      currentSeats: number;
      isGift: boolean;
      isCurrentPlanActive: boolean;
      maxAnnaulDiscount: number;
    },
    unknown,
    null
  >;
  seats: {
    value: number;
    onChange: (value: number) => void;
  };
  billingCycle: {
    value: BillingCycle.Monthly | BillingCycle.Yearly;
    onChange: (value: BillingCycle.Monthly | BillingCycle.Yearly) => void;
  };
};

const PLAN_FEATURE_CATEGORIES_TO_SHOW: PlanFeatureCategory[] = [
  PlanFeatureCategory.General,
  PlanFeatureCategory.ContactManagement,
  PlanFeatureCategory.CrmIntegrations,
  PlanFeatureCategory.Analytics,
  PlanFeatureCategory.Compliance,
  PlanFeatureCategory.SSO,
  PlanFeatureCategory.Support,
];

export function usePlansViewModel(): PlansViewModel {
  const navigate = useNavigate();

  const [nextSeats, setNextSeats] = useState(1);

  const [selectedBillingCycle, setSelectedBillingCycle] = useState<
    BillingCycle.Monthly | BillingCycle.Yearly
  >(BillingCycle.Yearly);

  const workspaceUseCase = useWorkspaceUseCase();
  const billingUseCase = useBillingUseCase();

  const { trackEvent } = useAnalytics();
  const snackbar = useSnackbar();

  const pageDataResult = useObservableResult(() => {
    const workspace$ = workspaceUseCase
      .getCurrentWorkspace()
      .pipe(filter((workspace) => !!workspace));

    return combineLatest({
      currentProduct: workspace$.pipe(
        map((workspace) => workspace.subscription.plan),
        distinctUntilChanged(),
        switchMap((currentPlan) => billingUseCase.getProduct(currentPlan)),
      ),
      billableMembers: workspace$.pipe(
        map((workspace) => workspace.billableMembersCount),
        distinctUntilChanged(),
      ),
      currentSeats: workspace$.pipe(
        map((workspace) => workspace.subscription.paidMembersCount),
        distinctUntilChanged(),
        tap((seats) => {
          setNextSeats(seats);
        }),
      ),
      currentBillingCycle: workspace$.pipe(
        map((workspace) => workspace.subscription.billingCycle),
        distinctUntilChanged(),
        tap((cycle) => {
          if (cycle !== BillingCycle.Daily) setSelectedBillingCycle(cycle);
        }),
      ),
      isGift: workspace$.pipe(
        map((workspace) => workspace.subscription.isGift),
        distinctUntilChanged(),
      ),
      isCurrentPlanActive: workspace$.pipe(
        map((workspace) => workspace.subscription.isActive),
        distinctUntilChanged(),
      ),
      maxAnnaulDiscount: billingUseCase.getMaxAnnualDiscount(),
    });
  });

  const billableMembers = pageDataResult.data?.billableMembers;
  const currentSeats = pageDataResult.data?.currentSeats ?? 1;
  const isGift = pageDataResult.data?.isGift ?? false;
  const isCurrentPlanActive = pageDataResult.data?.isCurrentPlanActive ?? false;
  const currentProduct = pageDataResult.data?.currentProduct;

  const billingCycleDialog = useBillingCycleDialogViewModel();
  const planDowngradeDialog = usePlanDowngradeDialog();
  const planTypesToShow = useAvailablePlans();
  const reduceActiveUsersDialog = useReduceActiveUsersDialog();

  const newPlansMetadataResult = useNewPlansMetadata({
    seats: nextSeats,
    billingCycle: selectedBillingCycle,
  });

  useEffect(() => {
    currentProduct &&
      trackEvent(ANALYTICS_EVENTS.VIEW_PLANS_PAGE, {
        current_plan: currentProduct.id,
      });
  }, [currentProduct]);

  useCMPage(CustomerMessagingEventKeys.VIEW_PLANS_PAGE);

  useDocumentMeta({
    title: 'plans.title',
    description: 'plans.description',
  });

  const navigateToPaymentDetails = (product: IProductEntity, seats: number): void => {
    const searchParams = new URLSearchParams();

    searchParams.set('plan_id', product.id);
    searchParams.set('quantity', seats.toString());

    trackEvent(ANALYTICS_EVENTS.CLICKS_SELECT_PLAN, {
      type: `${product.family} ${product.cycle}`,
    });

    navigate(`${ROUTES.BILLING.PAYMENT_DETAILS}?${searchParams.toString()}`);
  };

  const actionResolver = useMemo<PlanActionResolverContextType>(() => {
    return {
      resolve: ({ planType, originalProduct: nextProduct }): PlanAction => {
        const actionResolver = ACTION_RESOLVERS[planType];

        if (!currentProduct || !actionResolver) {
          return PlanAction.None;
        }

        return actionResolver({
          currentSeats,
          nextSeats,
          currentProduct,
          nextProduct,
          isCurrentPlanActive,
        });
      },
      onAction: ({ action, originalProduct: nextProduct }): void => {
        if (!currentProduct || !billableMembers) return;

        if (
          action === PlanAction.UpgradePlan ||
          action === PlanAction.UpdatePlan ||
          action === PlanAction.ChangeBillingCycle
        ) {
          if (!nextProduct) {
            snackbar.enqueueSnackbar({
              message: 'Error',
              description: 'Plan is missing',
              variant: 'error',
            });
            return;
          }

          if (!isGift && !isPlanUpgrade(nextProduct, currentProduct)) {
            return planDowngradeDialog.onOpen(nextProduct);
          }

          if (nextSeats < billableMembers) {
            reduceActiveUsersDialog.onOpen({
              activeUsers: billableMembers,
              usersToReduce: billableMembers - nextSeats,
            });
            return;
          }

          navigateToPaymentDetails(nextProduct, nextSeats);

          return;
        }

        if (action === PlanAction.TalkToSales) {
          trackEvent(ANALYTICS_EVENTS.CLICK_TALK_TO_SALES);
          window.open(EXTERNAL_ROUTES.TALK_TO_SALES);
          return;
        }
      },
    };
  }, [currentSeats, nextSeats, currentProduct]);

  return {
    planTypesToShow,
    planFeatureCategoriesToShow: PLAN_FEATURE_CATEGORIES_TO_SHOW,
    billingCycleDialog,
    planDowngradeDialog,
    reduceActiveUsersDialog,
    planActionResolver: actionResolver,
    newPlansMetadataResult,
    pageDataResult,
    seats: {
      value: nextSeats,
      onChange: setNextSeats,
    },
    billingCycle: {
      value: selectedBillingCycle,
      onChange: setSelectedBillingCycle,
    },
  };
}
