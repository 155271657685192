import { ObservableResult, useObservableResult } from '@/utils/rx';

import { useSubscriptionUseCase } from './useSubscriptionUseCase';

export const usePlanName = (params?: {
  variant?: 'short' | 'long';
}): ObservableResult<string> => {
  const subscriptionUseCase = useSubscriptionUseCase();

  return useObservableResult(() => subscriptionUseCase.getPlanName(params), {
    deps: [params?.variant],
  });
};
