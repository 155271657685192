import { FC } from 'react';

import { useUpdateNotificationViewModel } from './useUpdateNotificationViewModel';

type UpdateNotificationProps = {
  queryString: string;
  contactListId?: string;
};

export const UpdateNotification: FC<UpdateNotificationProps> = ({
  queryString,
  contactListId,
}) => {
  useUpdateNotificationViewModel(queryString, contactListId);

  return null;
};
