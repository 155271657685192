import { IAccountEntity } from '@/features/common/account';

import { ICioAccountDC } from '../dataContracts/ICioAccountDC';

export const mapAccountToCioAccountDC = (account: IAccountEntity): ICioAccountDC => {
  return {
    id: account.uuid,
    first_name: account.fullName ? account.fullName.split(' ')[0] : '',
    email: account.email,
  };
};
