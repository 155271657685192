import { useTranslation } from 'react-i18next';
import { TooltipItem } from 'chart.js/dist/types';
import dayjs from 'dayjs';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { HISTORY_EVENTS_DATASET_INDEX } from '../components/AnalyticsChart/hooks';

type Props = {
  selectedDate: string;
  tooltipTitles: string[];
  historyValues: { x: number; y: number; name: string }[];
};

type Return = {
  title: (i: TooltipItem<'line'>[]) => string;
  label: (i: TooltipItem<'line'>) => string;
};

export const useChartTooltipCallbacks = ({
  selectedDate,
  tooltipTitles,
  historyValues,
}: Props): Return => {
  const { t } = useTranslation(['dashboard', 'month']);

  const isYesterday = selectedDate === DATE_SELECT_OPTION_VALUES.yesterday;
  const isToday = selectedDate === DATE_SELECT_OPTION_VALUES.today;

  const title = (ctx: TooltipItem<'line'>[]): string => {
    const { dataIndex, datasetIndex } = ctx[0];

    if (datasetIndex === HISTORY_EVENTS_DATASET_INDEX) {
      return '';
    }

    const label = tooltipTitles[dataIndex];

    if (isToday) {
      return `${t('analytics.today')} - ${label}`;
    }

    if (isYesterday) {
      return `${t('analytics.yesterday')} - ${label}`;
    }

    return String(label);
  };

  const label = ({
    parsed: { y },
    datasetIndex,
    dataset,
    dataIndex,
  }: TooltipItem<'line'>): string => {
    if (datasetIndex === HISTORY_EVENTS_DATASET_INDEX) {
      const value = historyValues[dataIndex];
      const translationKey = value?.name ? 'userJoinedAt' : 'accountCreatedAt';

      return t(`analytics.tooltip.${translationKey}`, {
        // @ts-ignore
        date: dayjs(dataset.data[dataIndex]?.x).format('MMM DD, YYYY'),
        name: historyValues[dataIndex].name,
      });
    }

    return `${Math.floor(y)} ${t('analytics.tooltip.creditsUsed')}`;
  };

  return {
    title,
    label,
  };
};
