import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TagManagementIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 10.91">
    <path
      d="m19.65 15.155-3.262 4.616a2.386 2.386 0 0 1-.658.61 1.552 1.552 0 0 1-.83.229H6.947a1.3 1.3 0 0 1-1.3-1.3V11a1.3 1.3 0 0 1 1.3-1.3H14.9a1.552 1.552 0 0 1 .83.229 2.386 2.386 0 0 1 .658.61zm-1.621 0L15.11 11H6.947v8.316h8.163zm-11.082 0z"
      transform="translate(-5.65 -9.7)"
    />
  </SvgIcon>
);
