import { BillingCycle, IProductEntity } from '@/features/common/billing';

const cycleTireOrder = {
  daily: 0,
  monthly: 1,
  annually: 2,
};

const planTireByProductFamily = {
  free: 0,
  pro: 1,
  unlimited: 2,
  custom: 3,
};

const getCycleTire = (cycle: IProductEntity['cycle'] | BillingCycle): number => {
  return cycleTireOrder[cycle] ?? 1;
};

const getProductFamilyTire = (family: IProductEntity['family']): number => {
  return planTireByProductFamily[family] ?? 1;
};

export const isPlanUpgrade = (next: IProductEntity, current: IProductEntity): boolean => {
  const nextTire = getProductFamilyTire(next.family);
  const currentTire = getProductFamilyTire(current.family);

  if (nextTire > currentTire) return true;
  if (nextTire < currentTire) return false;

  return getCycleTire(next.cycle) >= getCycleTire(current.cycle);
};
