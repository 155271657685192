import { FC } from 'react';
import { Box } from '@mui/material';

import { Modal } from '@/components';

import CloseIcon from './assets/Close.svg?react';
import { useEnrichmentAccessDeniedModalViewModel } from './useEnrichmentAccessDeniedModalViewModel';

import styles from './styles.module.scss';

export const EnrichmentAccessDeniedModal: FC = () => {
  const { isOpen, close, CurrentModal } = useEnrichmentAccessDeniedModalViewModel();

  return (
    <Modal open={isOpen} onClose={close} containerClass={styles.Container}>
      <Box className={styles.Body}>
        <Box className={styles.CloseIcon} onClick={close}>
          <CloseIcon />
        </Box>
        <CurrentModal />
      </Box>
    </Modal>
  );
};
