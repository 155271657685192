import { inject, injectable, unmanaged } from 'inversify';
import {
  MigrationStrategies,
  RxCollection,
  RxCollectionCreator,
  RxJsonSchema,
} from 'rxdb';
import { v4 } from 'uuid';

import { SERVER_TIME_TYPES } from '@/ioc/types';

import { IServerTimeRepository } from '@/features/system/serverTime';

import { CollectionName } from '../types';

export interface IDbCollectionCreator<Document> {
  collectionName: CollectionName;
  getConfiguration(): RxCollectionCreator<Document>;
  applyHooks(collection: RxCollection): void;
}

@injectable()
export class DbCollectionCreator<Document> implements IDbCollectionCreator<Document> {
  private _schema: RxJsonSchema<Document>;

  private _migrationStrategies: MigrationStrategies;

  private _collectionName: CollectionName;

  @inject(SERVER_TIME_TYPES.ServerTimeRepository)
  private serverTimeRepository: IServerTimeRepository;

  constructor(
    @unmanaged()
    params: {
      collectionName: CollectionName;
      schema: RxJsonSchema<Document>;
      migrationStrategies: MigrationStrategies;
    },
  ) {
    this._collectionName = params.collectionName;
    this._schema = params.schema;
    this._migrationStrategies = params.migrationStrategies;
  }

  public get collectionName(): CollectionName {
    return this._collectionName;
  }

  public applyHooks(collection: RxCollection): void {
    collection.preInsert((rawData) => {
      const unixTimeStamp = this.serverTimeRepository.now();
      rawData.uuid = rawData.uuid || v4();
      rawData.updated_at = rawData.updated_at || unixTimeStamp;
      rawData.created_at = rawData.created_at || unixTimeStamp;
    }, false);
    collection.preSave((rawData) => {
      const unixTimeStamp = this.serverTimeRepository.now();
      rawData.updated_at = unixTimeStamp;
    }, false);
  }

  public getConfiguration(): RxCollectionCreator<Document> {
    return {
      schema: this._schema,
      migrationStrategies: this._migrationStrategies,
      autoMigrate: false,
    };
  }
}
