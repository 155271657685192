import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import {
  useBulkAggregatedCreditsInfo,
  useFullAggregatedCreditsInfo,
} from '@/features/common/workspace';

import styles from './styles.module.scss';

export const UnlimitedView: FC = () => {
  const bulkCreditsInfo = useBulkAggregatedCreditsInfo();
  const fullCreditsInfo = useFullAggregatedCreditsInfo();

  const { t } = useTranslation('common', {
    keyPrefix: 'header.creditUsage.tootlip.unlimited',
  });

  if (!bulkCreditsInfo.data || !fullCreditsInfo.data) {
    return null;
  }

  return (
    <Stack className={styles.wrapper}>
      <Stack className={styles.item}>
        <Box component="span" className={styles.label}>
          {t('prospectingCredits.label')}
        </Box>
        <Box component="span" className={styles.value}>
          {fullCreditsInfo.data.isUnlimited
            ? t('prospectingCredits.unlimited')
            : `${fullCreditsInfo.data.used}/${fullCreditsInfo.data.limit}`}
        </Box>
      </Stack>
      <Divider className={styles.divider} />
      <Stack className={styles.item}>
        <Box component="span" className={styles.label}>
          {t('bulkCredits.label')}
        </Box>
        <Box component="span" className={styles.value}>
          {bulkCreditsInfo.data.isUnlimited
            ? t('bulkCredits.unlimited')
            : `${bulkCreditsInfo.data.used}/${bulkCreditsInfo.data.limit}`}
        </Box>
        {bulkCreditsInfo.data.isUnlimited && fullCreditsInfo.data.isUnlimited ? null : (
          <CallToAction
            show="getCredits"
            textOverrides={{
              getCredits: t('bulkCredits.link'),
            }}
            withIcon={false}
            className={styles.link}
            variant="text"
          />
        )}
      </Stack>
    </Stack>
  );
};
