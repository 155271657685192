import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { SubHead } from '@/components';

import { ChartCardCircle, ChartCardPhones, EnrichmentFieldsList } from './components';
import { useEnrichmentReportViewModel } from './useEnrichmentReportViewModel';

import styles from './styles.module.scss';

export const EnrichmentReport: FC = () => {
  const { t } = useTranslation('enrichment', { keyPrefix: 'report' });
  const data = useEnrichmentReportViewModel();

  if (!data) return null;

  const { total, emails, phones, fields } = data;

  return (
    <Box className={styles.wrapper}>
      <SubHead className={styles.header}>
        <Box className={styles.headerContainer}>
          <SubHead.TitleWithNavigation goBackPath={ROUTES.ENRICHMENT_ENTRY}>
            {t('title')}
          </SubHead.TitleWithNavigation>
          <Typography color={'info'} className={styles.subtitle}>
            {t('subtitle')}
          </Typography>
        </Box>
      </SubHead>
      <Box className={styles.content}>
        <Box className={styles.chartsWrapper}>
          <ChartCardCircle type="records" data={total} />
          <ChartCardCircle type="emails" data={emails} />
          <ChartCardPhones data={phones} />
        </Box>
        <EnrichmentFieldsList data={fields} />
      </Box>
    </Box>
  );
};
