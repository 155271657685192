import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useAccount } from '@/features/common/account';
import { VerificationStatus } from '@/features/common/account';
import { UserAlreadyExistsError } from '@/features/common/auth';

import { ModalControler, useModalController } from '@/hooks';

import {
  NameValidationSchema,
  useFormWithSchema,
  WorkEmailValidationSchema,
} from '@/utils/validation';

export const AccountSettingsFormDefaultSchema = yup.object({
  name: NameValidationSchema,
  email: WorkEmailValidationSchema,
});

export type AccountSettingsFormDefaultType = yup.InferType<
  typeof AccountSettingsFormDefaultSchema
>;

type UseAccountSettingsFormDefaultViewModel = (params: {
  onEmailChange: (email: string) => Promise<void>;
  onNameChange: (name: string) => Promise<void>;
}) => {
  passwordDialog: ModalControler;
  disabled: boolean;
  shouldVerifyEmail: boolean;
  form: UseFormReturn<AccountSettingsFormDefaultType>;
  onSubmit: (e: BaseSyntheticEvent) => void;
};

export const useAccountSettingsFormDefaultViewModel: UseAccountSettingsFormDefaultViewModel =
  ({ onEmailChange, onNameChange }) => {
    const { account } = useAccount();
    const { t } = useTranslation('validation');

    const passwordDialog = useModalController();

    const form = useFormWithSchema(AccountSettingsFormDefaultSchema, {
      defaultValues: {
        email: account?.unconfirmedEmail ?? account?.email ?? '',
        name: account?.fullName ?? '',
      },
    });

    const onSubmit = (e: BaseSyntheticEvent): void => {
      void form.handleSubmit(async ({ email, name }: AccountSettingsFormDefaultType) => {
        try {
          if (form.formState.dirtyFields.email) {
            await onEmailChange(email);
          }

          if (form.formState.dirtyFields.name) {
            await onNameChange(name);
          }

          form.reset({ email, name });
        } catch (error) {
          if (error instanceof UserAlreadyExistsError) {
            form.setError('email', {
              message: t('emailAlreadyExists'),
            });
          }
        }
      })(e);
    };

    return {
      passwordDialog,
      disabled: form.formState.isSubmitting || !form.formState.isDirty,
      form,
      onSubmit,
      shouldVerifyEmail:
        !form.formState.dirtyFields.email &&
        account?.verificationStatus === VerificationStatus.ShouldVerifyChangeEmail,
    };
  };
