import { Container } from 'inversify';

import { INTEGRATIONS_TYPES } from '@/ioc/types';

import IntegrationsRepository from '@/features/integrations/data/IntegrationsRepository';
import IntegrationsApiService from '@/features/integrations/data/network/IntegrationsApiService';
import IIntegrationsApiService from '@/features/integrations/domain/abstractions/IIntegrationsApiService';
import IIntegrationsRepository from '@/features/integrations/domain/abstractions/IIntegrationsRepository';
import IIntegrationsUseCases from '@/features/integrations/domain/abstractions/IIntegrationsUseCases';
import IntegrationsUseCases from '@/features/integrations/domain/IntegrationsUseCases';

import {
  IIntegrationsState,
  IntegrationsState,
  IntegrationsStateCreator,
} from '../data/db';

export const bindIntegrationsModule = (container: Container): void => {
  container
    .bind<IIntegrationsState>(INTEGRATIONS_TYPES.IntegrationsState)
    .to(IntegrationsState)
    .inSingletonScope();

  container.bind<IntegrationsStateCreator>('DbStateCreator').to(IntegrationsStateCreator);

  container
    .bind<IIntegrationsApiService>(INTEGRATIONS_TYPES.IntegrationsApiService)
    .to(IntegrationsApiService);
  container
    .bind<IIntegrationsRepository>(INTEGRATIONS_TYPES.IntegrationsRepository)
    .to(IntegrationsRepository);
  container
    .bind<IIntegrationsUseCases>(INTEGRATIONS_TYPES.IntegrationsUseCases)
    .to(IntegrationsUseCases);
};
