import { injectable } from 'inversify';
import { MangoQuery } from 'rxdb';
import { firstValueFrom, Observable } from 'rxjs';

import { ITagDC } from '@/features/common/tag';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { TagNameAlreadyExistError } from '../../../domain/errors';

export type ITagDao = Pick<
  IDbCollection<ITagDC, 'uuid'>,
  'upsert' | 'findAll' | 'removeOne' | 'insertOne' | 'updateOne' | 'count'
>;

@injectable()
export default class TagDao extends DbCollection<ITagDC, 'uuid'> implements ITagDao {
  constructor() {
    super({ collectionName: CollectionName.Tag, idKey: 'uuid' });
  }

  private async assertIfNameAlreadyExist(tag: {
    uuid?: string;
    name: string;
  }): Promise<void> {
    const uuidSelector = tag.uuid ? { uuid: { $ne: tag.uuid } } : {};
    const nameSelector = { name: tag.name };

    const foundedTag = await firstValueFrom(
      this.findOne({ selector: { ...uuidSelector, ...nameSelector } }),
    );

    if (foundedTag) {
      throw new TagNameAlreadyExistError(tag.name);
    }
  }

  override async insertOne(payload: WithOptionalId<ITagDC, 'uuid'>): Promise<ITagDC> {
    await this.assertIfNameAlreadyExist(payload);
    return super.insertOne(payload);
  }

  override async upsert(payload: WithOptionalId<ITagDC, 'uuid'>): Promise<ITagDC> {
    await this.assertIfNameAlreadyExist(payload);
    return super.upsert(payload);
  }

  override findAll(
    query: MangoQuery<ITagDC> = { sort: [{ created_at: 'asc' }] },
  ): Observable<ITagDC[]> {
    return super.findAll(query);
  }

  override async updateOne(
    id: ITagDC['uuid'],
    payload: Partial<ITagDC>,
  ): Promise<ITagDC> {
    if (payload.name) {
      await this.assertIfNameAlreadyExist({ uuid: id, name: payload.name });
    }

    return super.updateOne(id, payload);
  }
}
