import dayjs from 'dayjs';

import { AggregatedChartDataEntity } from '../../domain/entities/AggregatedChartDataEntity';

export const mapChartEntityToValues = (
  entity: AggregatedChartDataEntity[],
): { x: number; y: number }[] => {
  return entity.map(({ creditsUsed, at }) => ({
    x: dayjs(at * 1000)
      .startOf('day')
      .valueOf(),
    y: creditsUsed,
  }));
};
