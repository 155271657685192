import { useInjection } from '@/ioc/index';
import { SIGN_UP_TYPES } from '@/ioc/types';

import { ISignUpUseCases } from '@/features/signup/domain/SignUpUseCases';

type UseSignUpUseCase = () => ISignUpUseCases;

export const useSignUpUseCase: UseSignUpUseCase = () => {
  return useInjection<ISignUpUseCases>(SIGN_UP_TYPES.SignUpUseCases);
};
