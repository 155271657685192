import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

import { SearchInput } from '@/components';

import { CreateTag } from './components/CreateTag';
import TagTable from './components/TagTable';
import { useTagManagementViewModel } from './useTagManagementViewModel';

import styles from './styles.module.scss';

const TagManagement: React.FC = () => {
  const { t } = useTranslation('tag');
  const { tagTableList, searchValue, isLoading, onSearchChange, onSearchClear } =
    useTagManagementViewModel();

  return (
    <Box className={clsx(styles.Wrapper)}>
      <Box className={styles.TabHeader}>
        <SearchInput
          onChange={onSearchChange}
          className={styles.SearchInput}
          value={searchValue}
          placeholder={t('searchTagsPlaceholder')}
          onClear={onSearchClear}
        />
        <CreateTag />
      </Box>
      <TagTable tagTableList={tagTableList} isLoading={isLoading} />
    </Box>
  );
};

export default TagManagement;
