import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { MobileScreen } from '@/components';

import { useIsMobile } from '@/hooks';

import { ROUTES } from '../routes';

export const MobileAccessGuardRoute: FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const accessedRoutes = [
    ROUTES.SIGN_UP,
    ROUTES.SIGN_UP_NEW,
    ROUTES.SIGN_UP_VERIFICATION,
    ROUTES.LOGIN,
  ];

  if (accessedRoutes.includes(location.pathname)) {
    return <Outlet />;
  }

  if (isMobile) {
    return <MobileScreen />;
  }

  return <Outlet />;
};
