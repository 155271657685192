import type { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const styles = {
        fontSize: '11px',
        lineHeight: '1.2',
        margin: '5px 0 0 0',
      };

      if (ownerState.error) {
        styles['color'] = theme.palette.error?.main;
      }

      return styles;
    },
  },
};
