import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ACCOUNT_TYPES, ANALYTICS_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountEntity, type IAccountRepository } from '@/features/common/account';
import { IWorkspaceEntity, type IWorkspaceRepository } from '@/features/common/workspace';

import { type IAnalyticsRepository } from './abstractions/IAnalyticsRepository';
import { type IAnalyticsUseCase } from './abstractions/IAnalyticsUseCase';

@injectable()
export class AnalyticsUseCase implements IAnalyticsUseCase {
  @inject(ANALYTICS_TYPES.AnalyticsRepository)
  private analyticsRepository: IAnalyticsRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  init(): void {
    this.analyticsRepository.init();
  }

  identify(): Observable<void> {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
    }).pipe(
      filter(({ account, workspace }) => !!account && !!workspace),
      distinctUntilChanged(
        (
          prev: { account: IAccountEntity; workspace: IWorkspaceEntity },
          current: { account: IAccountEntity; workspace: IWorkspaceEntity },
        ) => {
          return (
            prev.account.uuid === current.account.uuid &&
            prev.workspace.uuid === current.workspace.uuid
          );
        },
      ),
      tap(
        ({
          account,
          workspace,
        }: {
          account: IAccountEntity;
          workspace: IWorkspaceEntity;
        }) => {
          this.analyticsRepository.identify(account, workspace);
        },
      ),
      switchMap(() => of(void 0)),
    );
  }
}
