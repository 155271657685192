import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { DASHBOARD_CHART_TYPES } from '@/ioc/types';

import { AggregationStrategy } from '@/features/dashboard/features/chart/domain';
import { GetChartDataReq } from '@/features/dashboard/features/chart/domain/types/GetChartDataReq';

import { ChartDataEntity } from '../domain/entities/ChartDataEntity';
import { FetchChartDataReq } from '../domain/types/FetchChartDataReq';
import { QueryChartData } from '../domain/types/QueryChartData';

import { IDashboardChartApiService } from './abstractions/IDashboardChartApiService';
import { IDashboardChartDao } from './abstractions/IDashboardChartDao';
import { IDashboardChartRepository } from './abstractions/IDashboardChartRepository';
import { mapDashboardDcToEntity } from './db/mappers/mapDashboardDcToEntity';
import { mapDashboardReqEntityToReqDc } from './db/mappers/mapDashboardReqEntityToReqDc';
import { mapQueryChartEntityToDc } from './db/mappers/mapQueryChartEntityToDc';

@injectable()
export class DashboardChartRepository implements IDashboardChartRepository {
  public aggregationStrategy: AggregationStrategy = AggregationStrategy.Daily;

  @inject(DASHBOARD_CHART_TYPES.ChartDao)
  private chartDao: IDashboardChartDao;

  @inject(DASHBOARD_CHART_TYPES.ChartApiService)
  private chartApiService: IDashboardChartApiService;

  getData(query: GetChartDataReq): Observable<ChartDataEntity[]> {
    return this.switchDataSource(query);
  }

  queryData(query: QueryChartData): Observable<ChartDataEntity[]> {
    return this.chartDao.getData(mapQueryChartEntityToDc(query)).pipe(
      map((data) => {
        return mapDashboardDcToEntity(data);
      }),
    );
  }

  fetchData(reqEntity: FetchChartDataReq): Observable<ChartDataEntity[]> {
    const reqDC = mapDashboardReqEntityToReqDc(reqEntity);

    if (reqDC.user === null) {
      delete reqDC.user;
    }

    return this.chartApiService.fetchChartData(reqDC).pipe(
      map((data) => {
        if (!reqDC.user) {
          return data.filter((dc) => dc.member === null);
        }

        return data;
      }),
      map((data) => mapDashboardDcToEntity(data)),
    );
  }

  private switchDataSource({
    fromDate,
    toDate,
    selectedUser,
  }: GetChartDataReq): Observable<ChartDataEntity[]> {
    const isQueryWithinLastYear = this.isQueryWithinLastYear(fromDate);

    if (isQueryWithinLastYear) {
      return this.queryData({
        fromDate,
        toDate,
        member: selectedUser,
      });
    }

    return this.fetchData({
      fromDate,
      toDate,
      user: selectedUser,
    });
  }

  private isQueryWithinLastYear(fromDate: number): boolean {
    const from = dayjs.unix(fromDate);
    const yearAgo = dayjs().subtract(365, 'days').startOf('day');
    return yearAgo.isBefore(from) || yearAgo.isSame(from);
  }
}
