import { RxJsonSchema } from 'rxdb';

import { IEnrichmentDC } from '../../dataContract';

export const EnrichmentSchema: RxJsonSchema<IEnrichmentDC> = {
  title: 'Enrichment schema',
  version: 0,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    name: { type: 'string' },
    status: { type: 'string' },
    progress: { type: 'number' },
    updated_at: { type: 'number' },
    created_at: { type: 'number' },
    created_by: {
      type: 'object',
      properties: {
        uuid: { type: 'string', maxLength: 100 },
        full_name: { type: 'string' },
      },
    },
    enrich_type: { type: 'array', items: { type: 'string' } },
    statistic: {
      type: 'object',
      properties: {
        total: {
          type: 'object',
          properties: {
            count: { type: 'number' },
            matched: { type: 'number' },
            duplicate: { type: 'number' },
            not_found: { type: 'number' },
          },
        },
        emails: {
          type: 'object',
          properties: {
            count: { type: 'number' },
            verified: { type: 'number' },
            not_verified: { type: 'number' },
            not_found: { type: 'number' },
          },
        },
        phones: {
          type: 'object',
          properties: {
            count: { type: 'number' },
            hq_phone: { type: 'number' },
            direct_dial: { type: 'number' },
          },
        },
        fields: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              field_name: { type: 'string' },
              count: { type: 'number' },
            },
          },
        },
      },
    },
  },
};
