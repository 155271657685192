import { useMemo } from 'react';

import { useGetFreeCreditsUseCase } from '@/features/getFreeCredits';

import { ObservableResult, useObservableResult } from '@/utils/rx';

export const useHasUserReceivedFreeCredits = (): ObservableResult<
  boolean,
  unknown,
  boolean
> => {
  const useCase = useGetFreeCreditsUseCase();
  const hasUserReceivedFreeCredits$ = useMemo(
    () => useCase.hasUserReceivedFreeCredits(),
    [],
  );

  return useObservableResult(hasUserReceivedFreeCredits$, { defaultData: false });
};
