import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { CardBrand, CardIcon } from '@/features/common/billing';

import { LockIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type CreditCardDetailsProps = {
  expDate: string;
  last4: string;
  brand: CardBrand;
  isExpired?: boolean;
};

export const CreditCardDetails: FC<CreditCardDetailsProps> = ({
  expDate,
  last4,
  brand,
  isExpired,
}) => {
  const { t } = useTranslation('billingSettings');

  return (
    <Box className={styles.creditCard}>
      <Box className={styles.creditCardDetails}>
        <CardIcon brand={brand} />
        <Typography variant="inherit" className={styles.last4Degits}>
          {last4}
        </Typography>
        <Typography
          variant="inherit"
          className={styles.expDate}
          color={isExpired ? 'error' : 'info'}
        >
          {isExpired ? t('expires') : null} {expDate}
        </Typography>
        <LockIcon className={styles.iconLock} />
      </Box>
    </Box>
  );
};
