import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export const NoPaymentMethod: FC = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'paymentDetails',
  });

  return (
    <Box>
      <Typography component="span" color="error" fontSize="13px">
        {t('noPaymentMethod')}
      </Typography>
    </Box>
  );
};
