export const openInNewTab = (link: string, simulateClick?: boolean): void => {
  if (simulateClick) {
    const a = document.createElement('a');

    a.href = link;
    a.target = '_blank';

    a.click();
  } else {
    window.open(link, '_blank');
  }
};
