import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';

import { ExampleDataCell, FileFieldCell, OwnCellField } from '../components';

import styles from '../styles.module.scss';

export const useConfigViewFieldsMapperTableColumns = (): GridColDef[] => {
  const { t } = useTranslation('enrichment');

  return [
    {
      headerName: t('uploadingFieldsMapperModal.table.columns.powerleadFields.header'),
      sortable: false,
      field: 'ownField',
      width: 230,
      renderCell(params): ReactElement {
        return <OwnCellField value={params.value} />;
      },
    },
    {
      headerName: t('uploadingFieldsMapperModal.table.columns.csvColumn.header'),
      headerClassName: styles.CsvColumnHeader,
      sortable: false,
      field: 'fileField',
      width: 260,
      renderCell(params): ReactElement {
        return <FileFieldCell fieldName={params.row.ownField} />;
      },
    },
    {
      headerName: t('uploadingFieldsMapperModal.table.columns.exampleData.header'),
      sortable: false,
      field: 'exampleData',
      flex: 1,
      renderCell(params): ReactElement {
        return <ExampleDataCell fieldName={params.row.ownField} />;
      },
    },
  ];
};
