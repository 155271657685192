import { FC, ReactElement } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { CustomSelectV2, IOSSwitch } from '@/components';
import { RubbishBinIcon } from '@/components/Icons';

import ArrowRight from '@/assets/icons/integrations/arrowRight.svg?react';

import { FieldMappingFormValues, FieldOption } from '../../types';

import styles from './styles.module.scss';

type Props = {
  powerleadFieldOptions: FieldOption[];
  providerFieldOptions: FieldOption[];
  onDelete: () => void;
  control: Control<FieldMappingFormValues>;
  canBeDeleted: boolean;
  name: string;
};
export const TableRow: FC<Props> = ({
  onDelete,
  canBeDeleted = false,
  control,
  name,
  powerleadFieldOptions = [],
  providerFieldOptions = [],
}) => {
  // names for faster access to form values
  const names = {
    powerleadField: `${name}.powerleadField` as const,
    providerField: `${name}.providerField` as const,
    predefinedValue: `${name}.predefinedValue` as const,
    allowOverride: `${name}.allowOverride` as const,
  };

  const { t } = useTranslation('integrations');

  const currentPowerleadField = useWatch({
    control,
    name: names.powerleadField,
  });

  const exampleValue =
    powerleadFieldOptions.find((option) => option.value === currentPowerleadField)
      ?.example ?? '';

  const showPredefinedValueInput = currentPowerleadField === 'predefined_value';

  return (
    <Box className={styles.row}>
      <Box className={clsx(styles.rowCell, styles.powerleadSelectField)}>
        <Controller
          name={names.powerleadField}
          control={control}
          render={(field): ReactElement => {
            return (
              <CustomSelectV2
                options={powerleadFieldOptions}
                key={field.field.value}
                disabled={field.formState.isSubmitting}
                error={!!field.fieldState.error}
                value={field.field.value}
                MenuProps={{
                  ListProps: {
                    className: styles.optionList,
                  },
                }}
                onChange={field.field.onChange}
              />
            );
          }}
        />

        {exampleValue && (
          <Typography className={styles.rowExample} variant={'subtitle1'}>
            {t('integration.tabs.fieldMapping.example', { example: exampleValue })}
          </Typography>
        )}
      </Box>
      <Box className={styles.arrowWrapper}>
        <ArrowRight />
      </Box>
      <Box className={styles.rowCell}>
        <Controller
          name={names.providerField}
          control={control}
          render={(field): ReactElement => {
            return (
              <>
                <CustomSelectV2
                  options={providerFieldOptions}
                  disabled={field.formState.isSubmitting || !canBeDeleted}
                  error={!!field.fieldState.error}
                  value={field.field.value}
                  onChange={field.field.onChange}
                  MenuProps={{
                    ListProps: {
                      className: styles.optionList,
                    },
                  }}
                />
                {field.fieldState.error?.message && (
                  <FormHelperText error className={styles.error}>
                    {field.fieldState.error.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Box>

      <Box className={clsx(styles.rowCell, styles.predefValueItem)}>
        {showPredefinedValueInput && (
          <Controller
            control={control}
            name={names.predefinedValue}
            disabled={control._formState.isSubmitting}
            render={({
              field: { onChange, onBlur, value },
              fieldState,
            }): ReactElement => (
              <>
                <OutlinedInput
                  className={styles.inputPredefinedValue}
                  error={!!fieldState.error}
                  placeholder={t(
                    'integration.tabs.fieldMapping.predefinedValue.placeholder',
                  )}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value ?? ''}
                />
                {fieldState.error?.message ? (
                  <FormHelperText error className={styles.error}>
                    {fieldState.error.message}
                  </FormHelperText>
                ) : null}
              </>
            )}
          />
        )}
      </Box>

      <Box className={clsx(styles.rowCell, styles.allowOverwrite)}>
        <Controller
          control={control}
          name={names.allowOverride}
          render={(field): ReactElement => {
            return (
              <IOSSwitch
                disabled={field.formState.isSubmitting}
                className={styles.checkBox}
                checked={field.field.value}
                onChange={(_e, checked): void => field.field.onChange(checked)}
              />
            );
          }}
        />
      </Box>
      <Box className={clsx(styles.rowCell, styles.deleteRowAction)}>
        <IconButton
          className={styles.deleteActionButton}
          onClick={onDelete}
          disabled={!canBeDeleted || control._formState.isSubmitting}
        >
          <RubbishBinIcon color={'info'} />
        </IconButton>
      </Box>
    </Box>
  );
};
