import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '../CustomSelectV2';

import { countries } from './countries';

type UseCountrySelectViewModelResult = {
  options: Option<string>[];
};

export const useCountrySelectViewModel = (): UseCountrySelectViewModelResult => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  const options = useMemo(() => {
    return countries.map((country) => ({
      value: country.code,
      label: t(`countrySelect.countries.${country.code}`),
    }));
  }, [language]);

  return {
    options,
  };
};
