import { FC } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const SummaryPrice: FC<{
  price: string;
  isTotal?: boolean;
  isCrossed?: boolean;
}> = ({ price, isTotal, isCrossed }) => {
  return (
    <Typography
      className={clsx(styles.summaryPrice, {
        [styles.total]: isTotal,
        [styles.crossed]: isCrossed,
      })}
    >
      {price}
    </Typography>
  );
};
