import { FetchChartDataReqDC } from '@/features/dashboard/features/chart/data/dataContracts/FetchChartDataReqDC';
import { FetchChartDataReq } from '@/features/dashboard/features/chart/domain/types/FetchChartDataReq';

export const mapDashboardReqEntityToReqDc = (
  req: FetchChartDataReq,
): FetchChartDataReqDC => {
  return {
    from_dt: String(req.fromDate),
    to_dt: String(req.toDate),
    user: req.user,
  };
};
