type CountryMeta = {
  iso2: string;
  codes: string[];
};

const countries: CountryMeta[] = [
  {
    iso2: 'AD',
    codes: ['376'],
  },
  {
    iso2: 'AE',
    codes: ['971'],
  },
  {
    iso2: 'AF',
    codes: ['93'],
  },
  {
    iso2: 'AG',
    codes: ['1268'],
  },
  {
    iso2: 'AI',
    codes: ['1264'],
  },
  {
    iso2: 'AL',
    codes: ['355'],
  },
  {
    iso2: 'AM',
    codes: ['374'],
  },
  {
    iso2: 'AO',
    codes: ['244'],
  },
  {
    iso2: 'AR',
    codes: ['54'],
  },
  {
    iso2: 'AS',
    codes: ['1684'],
  },
  {
    iso2: 'AT',
    codes: ['43'],
  },
  {
    iso2: 'AU',
    codes: ['61'],
  },
  {
    iso2: 'AW',
    codes: ['297'],
  },
  {
    iso2: 'AZ',
    codes: ['994'],
  },
  {
    iso2: 'BA',
    codes: ['387'],
  },
  {
    iso2: 'BB',
    codes: ['1246'],
  },
  {
    iso2: 'BD',
    codes: ['880'],
  },
  {
    iso2: 'BE',
    codes: ['32'],
  },
  {
    iso2: 'BF',
    codes: ['226'],
  },
  {
    iso2: 'BG',
    codes: ['359'],
  },
  {
    iso2: 'BH',
    codes: ['973'],
  },
  {
    iso2: 'BI',
    codes: ['257'],
  },
  {
    iso2: 'BJ',
    codes: ['229'],
  },
  {
    iso2: 'BM',
    codes: ['1441'],
  },
  {
    iso2: 'BN',
    codes: ['673'],
  },
  {
    iso2: 'BO',
    codes: ['591'],
  },
  {
    iso2: 'BQ',
    codes: ['599'],
  },
  {
    iso2: 'BR',
    codes: ['55'],
  },
  {
    iso2: 'BS',
    codes: ['1242'],
  },
  {
    iso2: 'BT',
    codes: ['975'],
  },
  {
    iso2: 'BW',
    codes: ['267'],
  },
  {
    iso2: 'BY',
    codes: ['375'],
  },
  {
    iso2: 'BZ',
    codes: ['501'],
  },
  {
    iso2: 'CA',
    codes: ['1'],
  },
  {
    iso2: 'CD',
    codes: ['243'],
  },
  {
    iso2: 'CF',
    codes: ['236'],
  },
  {
    iso2: 'CG',
    codes: ['242'],
  },
  {
    iso2: 'CH',
    codes: ['41'],
  },
  {
    iso2: 'CI',
    codes: ['225'],
  },
  {
    iso2: 'CK',
    codes: ['682'],
  },
  {
    iso2: 'CL',
    codes: ['56'],
  },
  {
    iso2: 'CM',
    codes: ['237'],
  },
  {
    iso2: 'CN',
    codes: ['86'],
  },
  {
    iso2: 'CO',
    codes: ['57'],
  },
  {
    iso2: 'CR',
    codes: ['506'],
  },
  {
    iso2: 'CU',
    codes: ['53'],
  },
  {
    iso2: 'CV',
    codes: ['238'],
  },
  {
    iso2: 'CW',
    codes: ['599'],
  },
  {
    iso2: 'CY',
    codes: ['357'],
  },
  {
    iso2: 'CZ',
    codes: ['420'],
  },
  {
    iso2: 'DE',
    codes: ['49'],
  },
  {
    iso2: 'DJ',
    codes: ['253'],
  },
  {
    iso2: 'DK',
    codes: ['45'],
  },
  {
    iso2: 'DM',
    codes: ['1767'],
  },
  {
    iso2: 'DO',
    codes: ['1809', '1829', '1849'],
  },
  {
    iso2: 'DZ',
    codes: ['213'],
  },
  {
    iso2: 'EC',
    codes: ['593'],
  },
  {
    iso2: 'EE',
    codes: ['372'],
  },
  {
    iso2: 'EG',
    codes: ['20'],
  },
  {
    iso2: 'ER',
    codes: ['291'],
  },
  {
    iso2: 'ES',
    codes: ['34'],
  },
  {
    iso2: 'ET',
    codes: ['251'],
  },
  {
    iso2: 'FI',
    codes: ['358'],
  },
  {
    iso2: 'FJ',
    codes: ['679'],
  },
  {
    iso2: 'FK',
    codes: ['500'],
  },
  {
    iso2: 'FM',
    codes: ['691'],
  },
  {
    iso2: 'FO',
    codes: ['298'],
  },
  {
    iso2: 'FR',
    codes: ['33'],
  },
  {
    iso2: 'GA',
    codes: ['241'],
  },
  {
    iso2: 'GB',
    codes: ['44'],
  },
  {
    iso2: 'GD',
    codes: ['1473'],
  },
  {
    iso2: 'GE',
    codes: ['995'],
  },
  {
    iso2: 'GF',
    codes: ['594'],
  },
  {
    iso2: 'GH',
    codes: ['233'],
  },
  {
    iso2: 'GI',
    codes: ['350'],
  },
  {
    iso2: 'GL',
    codes: ['299'],
  },
  {
    iso2: 'GM',
    codes: ['220'],
  },
  {
    iso2: 'GN',
    codes: ['224'],
  },
  {
    iso2: 'GP',
    codes: ['590'],
  },
  {
    iso2: 'GQ',
    codes: ['240'],
  },
  {
    iso2: 'GR',
    codes: ['30'],
  },
  {
    iso2: 'GT',
    codes: ['502'],
  },
  {
    iso2: 'GU',
    codes: ['1671'],
  },
  {
    iso2: 'GW',
    codes: ['245'],
  },
  {
    iso2: 'GY',
    codes: ['592'],
  },
  {
    iso2: 'HK',
    codes: ['852'],
  },
  {
    iso2: 'HN',
    codes: ['504'],
  },
  {
    iso2: 'HR',
    codes: ['385'],
  },
  {
    iso2: 'HT',
    codes: ['509'],
  },
  {
    iso2: 'HU',
    codes: ['36'],
  },
  {
    iso2: 'ID',
    codes: ['62'],
  },
  {
    iso2: 'IE',
    codes: ['353'],
  },
  {
    iso2: 'IL',
    codes: ['972'],
  },
  {
    iso2: 'IN',
    codes: ['91'],
  },
  {
    iso2: 'IO',
    codes: ['246'],
  },
  {
    iso2: 'IQ',
    codes: ['964'],
  },
  {
    iso2: 'IR',
    codes: ['98'],
  },
  {
    iso2: 'IS',
    codes: ['354'],
  },
  {
    iso2: 'IT',
    codes: ['39'],
  },
  {
    iso2: 'JM',
    codes: ['1876'],
  },
  {
    iso2: 'JO',
    codes: ['962'],
  },
  {
    iso2: 'JP',
    codes: ['81'],
  },
  {
    iso2: 'KE',
    codes: ['254'],
  },
  {
    iso2: 'KG',
    codes: ['996'],
  },
  {
    iso2: 'KH',
    codes: ['855'],
  },
  {
    iso2: 'KI',
    codes: ['686'],
  },
  {
    iso2: 'KM',
    codes: ['269'],
  },
  {
    iso2: 'KN',
    codes: ['1869'],
  },
  {
    iso2: 'KP',
    codes: ['850'],
  },
  {
    iso2: 'KR',
    codes: ['82'],
  },
  {
    iso2: 'KW',
    codes: ['965'],
  },
  {
    iso2: 'KY',
    codes: ['1345'],
  },
  {
    iso2: 'KZ',
    codes: ['7'],
  },
  {
    iso2: 'LA',
    codes: ['856'],
  },
  {
    iso2: 'LB',
    codes: ['961'],
  },
  {
    iso2: 'LC',
    codes: ['1758'],
  },
  {
    iso2: 'LI',
    codes: ['423'],
  },
  {
    iso2: 'LK',
    codes: ['94'],
  },
  {
    iso2: 'LR',
    codes: ['231'],
  },
  {
    iso2: 'LS',
    codes: ['266'],
  },
  {
    iso2: 'LT',
    codes: ['370'],
  },
  {
    iso2: 'LU',
    codes: ['352'],
  },
  {
    iso2: 'LV',
    codes: ['371'],
  },
  {
    iso2: 'LY',
    codes: ['218'],
  },
  {
    iso2: 'MA',
    codes: ['212'],
  },
  {
    iso2: 'MC',
    codes: ['377'],
  },
  {
    iso2: 'MD',
    codes: ['373'],
  },
  {
    iso2: 'ME',
    codes: ['382'],
  },
  {
    iso2: 'MG',
    codes: ['261'],
  },
  {
    iso2: 'MH',
    codes: ['692'],
  },
  {
    iso2: 'MK',
    codes: ['389'],
  },
  {
    iso2: 'ML',
    codes: ['223'],
  },
  {
    iso2: 'MM',
    codes: ['95'],
  },
  {
    iso2: 'MN',
    codes: ['976'],
  },
  {
    iso2: 'MO',
    codes: ['853'],
  },
  {
    iso2: 'MP',
    codes: ['1670'],
  },
  {
    iso2: 'MQ',
    codes: ['596'],
  },
  {
    iso2: 'MR',
    codes: ['222'],
  },
  {
    iso2: 'MS',
    codes: ['1664'],
  },
  {
    iso2: 'MT',
    codes: ['356'],
  },
  {
    iso2: 'MU',
    codes: ['230'],
  },
  {
    iso2: 'MV',
    codes: ['960'],
  },
  {
    iso2: 'MW',
    codes: ['265'],
  },
  {
    iso2: 'MX',
    codes: ['52'],
  },
  {
    iso2: 'MY',
    codes: ['60'],
  },
  {
    iso2: 'MZ',
    codes: ['258'],
  },
  {
    iso2: 'NA',
    codes: ['264'],
  },
  {
    iso2: 'NC',
    codes: ['687'],
  },
  {
    iso2: 'NE',
    codes: ['227'],
  },
  {
    iso2: 'NF',
    codes: ['672'],
  },
  {
    iso2: 'NG',
    codes: ['234'],
  },
  {
    iso2: 'NI',
    codes: ['505'],
  },
  {
    iso2: 'NL',
    codes: ['31'],
  },
  {
    iso2: 'NO',
    codes: ['47'],
  },
  {
    iso2: 'NP',
    codes: ['977'],
  },
  {
    iso2: 'NR',
    codes: ['674'],
  },
  {
    iso2: 'NU',
    codes: ['683'],
  },
  {
    iso2: 'NZ',
    codes: ['64'],
  },
  {
    iso2: 'OM',
    codes: ['968'],
  },
  {
    iso2: 'PA',
    codes: ['507'],
  },
  {
    iso2: 'PE',
    codes: ['51'],
  },
  {
    iso2: 'PF',
    codes: ['689'],
  },
  {
    iso2: 'PG',
    codes: ['675'],
  },
  {
    iso2: 'PH',
    codes: ['63'],
  },
  {
    iso2: 'PK',
    codes: ['92'],
  },
  {
    iso2: 'PL',
    codes: ['48'],
  },
  {
    iso2: 'PM',
    codes: ['508'],
  },
  {
    iso2: 'PR',
    codes: ['1787', '1939'],
  },
  {
    iso2: 'PS',
    codes: ['970'],
  },
  {
    iso2: 'PT',
    codes: ['351'],
  },
  {
    iso2: 'PW',
    codes: ['680'],
  },
  {
    iso2: 'PY',
    codes: ['595'],
  },
  {
    iso2: 'QA',
    codes: ['974'],
  },
  {
    iso2: 'RE',
    codes: ['262'],
  },
  {
    iso2: 'RO',
    codes: ['40'],
  },
  {
    iso2: 'RS',
    codes: ['381'],
  },
  {
    iso2: 'RU',
    codes: ['7'],
  },
  {
    iso2: 'RW',
    codes: ['250'],
  },
  {
    iso2: 'SA',
    codes: ['966'],
  },
  {
    iso2: 'SB',
    codes: ['677'],
  },
  {
    iso2: 'SC',
    codes: ['248'],
  },
  {
    iso2: 'SD',
    codes: ['249'],
  },
  {
    iso2: 'SE',
    codes: ['46'],
  },
  {
    iso2: 'SG',
    codes: ['65'],
  },
  {
    iso2: 'SH',
    codes: ['247', '290'],
  },
  {
    iso2: 'SI',
    codes: ['386'],
  },
  {
    iso2: 'SK',
    codes: ['421'],
  },
  {
    iso2: 'SL',
    codes: ['232'],
  },
  {
    iso2: 'SM',
    codes: ['378'],
  },
  {
    iso2: 'SN',
    codes: ['221'],
  },
  {
    iso2: 'SO',
    codes: ['252'],
  },
  {
    iso2: 'SR',
    codes: ['597'],
  },
  {
    iso2: 'SS',
    codes: ['211'],
  },
  {
    iso2: 'ST',
    codes: ['239'],
  },
  {
    iso2: 'SV',
    codes: ['503'],
  },
  {
    iso2: 'SX',
    codes: ['1721'],
  },
  {
    iso2: 'SY',
    codes: ['963'],
  },
  {
    iso2: 'SZ',
    codes: ['268'],
  },
  {
    iso2: 'TC',
    codes: ['1649'],
  },
  {
    iso2: 'TD',
    codes: ['235'],
  },
  {
    iso2: 'TG',
    codes: ['228'],
  },
  {
    iso2: 'TH',
    codes: ['66'],
  },
  {
    iso2: 'TJ',
    codes: ['992'],
  },
  {
    iso2: 'TK',
    codes: ['690'],
  },
  {
    iso2: 'TL',
    codes: ['670'],
  },
  {
    iso2: 'TM',
    codes: ['993'],
  },
  {
    iso2: 'TN',
    codes: ['216'],
  },
  {
    iso2: 'TO',
    codes: ['676'],
  },
  {
    iso2: 'TR',
    codes: ['90'],
  },
  {
    iso2: 'TT',
    codes: ['1868'],
  },
  {
    iso2: 'TV',
    codes: ['688'],
  },
  {
    iso2: 'TW',
    codes: ['886'],
  },
  {
    iso2: 'TZ',
    codes: ['255'],
  },
  {
    iso2: 'UA',
    codes: ['380'],
  },
  {
    iso2: 'UG',
    codes: ['256'],
  },
  {
    iso2: 'US',
    codes: ['1'],
  },
  {
    iso2: 'UY',
    codes: ['598'],
  },
  {
    iso2: 'UZ',
    codes: ['998'],
  },
  {
    iso2: 'VC',
    codes: ['1784'],
  },
  {
    iso2: 'VE',
    codes: ['58'],
  },
  {
    iso2: 'VG',
    codes: ['1284'],
  },
  {
    iso2: 'VI',
    codes: ['1340'],
  },
  {
    iso2: 'VN',
    codes: ['84'],
  },
  {
    iso2: 'VU',
    codes: ['678'],
  },
  {
    iso2: 'WF',
    codes: ['681'],
  },
  {
    iso2: 'WS',
    codes: ['685'],
  },
  {
    iso2: 'XK',
    codes: ['383'],
  },
  {
    iso2: 'YE',
    codes: ['967'],
  },
  {
    iso2: 'ZA',
    codes: ['27'],
  },
  {
    iso2: 'ZM',
    codes: ['260'],
  },
  {
    iso2: 'ZW',
    codes: ['263'],
  },
];

export const suggestCountries = (phonePart: string): string[] => {
  const trimedPhonePart = phonePart.trim();

  if (trimedPhonePart.length === 0) {
    return [];
  }

  return countries
    .filter((meta) => {
      return meta.codes.some((code) => {
        if (trimedPhonePart.length >= code.length) {
          return trimedPhonePart.startsWith(code);
        }

        return false;
      });
    })
    .map(({ iso2 }) => iso2);
};
