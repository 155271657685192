import { injectable } from 'inversify';
import { Observable } from 'rxjs';

export interface ISseClient {
  connect<T>(url: string): Observable<T>;
}

@injectable()
export class SseClient implements ISseClient {
  connect<T>(url: string): Observable<T> {
    return new Observable((subscriber) => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = (event: MessageEvent<string>): void => {
        try {
          const data = JSON.parse(event.data) as T;
          subscriber.next(data);
        } catch {}
      };

      eventSource.onerror = (e): void => {
        subscriber.error(e);
      };

      return () => {
        eventSource.close();
      };
    });
  }
}
