// noinspection AllyPlainJsInspection

import { MONTHS_SHORTCUTS } from '@/translations/enums/monthsShortcuts';

export default {
  [MONTHS_SHORTCUTS.Jan]: 'Jan',
  [MONTHS_SHORTCUTS.Feb]: 'Feb',
  [MONTHS_SHORTCUTS.March]: 'March',
  [MONTHS_SHORTCUTS.April]: 'April',
  [MONTHS_SHORTCUTS.May]: 'May',
  [MONTHS_SHORTCUTS.June]: 'June',
  [MONTHS_SHORTCUTS.July]: 'July',
  [MONTHS_SHORTCUTS.Aug]: 'Aug',
  [MONTHS_SHORTCUTS.Sept]: 'Sept',
  [MONTHS_SHORTCUTS.Oct]: 'Oct',
  [MONTHS_SHORTCUTS.Nov]: 'Nov',
  [MONTHS_SHORTCUTS.Dec]: 'Dec',
};
