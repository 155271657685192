import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import NoSearchImage from './assets/noSearchResults.svg?react';

import styles from './styles.module.scss';

export const NoResultsOverlay: FC = () => {
  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Image}>
        <NoSearchImage />
      </Box>
      <Box className={styles.Title}>
        <Typography variant="h1">
          <Trans
            ns="enrichment"
            i18nKey="table.noResults.title"
            components={{ br: <br /> }}
          />
        </Typography>
      </Box>
    </Box>
  );
};
