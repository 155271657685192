import { createContext } from 'react';

import type { ConformationModalData } from './components/confirmationModal/ConfirmationModal';
import type { InformationModalData } from './components/informationModal/InformationModal';
import { MODAL_VARIANTS } from './constants';

export type ModalPropsType =
  | {
      type: MODAL_VARIANTS.confirmationModal;
      props: ConformationModalData;
    }
  | {
      type: MODAL_VARIANTS.informationModal;
      props: InformationModalData;
    };

export type ContextType = {
  isShown: boolean;
  openModal: (modalProps: ModalPropsType) => void;
  closeModal: () => void;
  modalData: Nullable<ModalPropsType>;
};

export const ModalContext = createContext<ContextType>({
  isShown: false,
  openModal: () => {
    return;
  },
  closeModal: () => {
    return;
  },
  modalData: null,
});
