import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  EnrichmentConfigForm,
  EnrichmentDataType,
} from '@/features/enrichment/domain/types';
import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';
import { useEnrichmentFileUploadUseCase } from '@/features/enrichment/ui/Enrichment/hooks';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { EnrichmentReportSelectedData } from '@/features/system/analytics/domain/types/eventProperties';
import { useAppLogger } from '@/features/system/logger';

import { ConfigViewSettingsForm, FormContext } from '../../FormContext';

type UseConfigViewSettingsViewModelResult = {
  form: UseFormReturn<ConfigViewSettingsForm>;
  items: string[];
  settingsIsOpen: boolean;
  isSomeSelected: boolean;
  isDisabled: boolean;
  selectedSettingsCount: number;
  toggleSetting(item: string): void;
  settingIsChecked(item: string): boolean;
  toggleAllSettings(): void;
  setSettingsIsOpen: Dispatch<SetStateAction<boolean>>;
  startEnrich(): void;
  back(): void;
};

export const useConfigViewSettingsViewModel =
  (): UseConfigViewSettingsViewModelResult => {
    const logger = useAppLogger();
    const { settingsForm: form, fieldsMapperForm } = useContext(FormContext);
    const { trackEvent } = useAnalytics();
    const { toView, fileUploadResponse } = useContext(EnrichmentUploadingContext);
    const [settingsIsOpen, setSettingsIsOpen] = useState(false);
    const settingsValue = form.watch('settings');
    const isSomeSelected = settingsValue.length > 0;
    const isDisabled =
      !!form.formState.errors.enrichByEmail || !!form.formState.errors.enrichByPhone;
    const enrichmentFileUploadUseCase = useEnrichmentFileUploadUseCase();
    const settingsList = fileUploadResponse?.settings || [];

    const toggleSetting = (item: string): void => {
      const idx = settingsValue.findIndex((i) => i === item);
      if (idx >= 0) {
        form.setValue(
          'settings',
          settingsValue.filter((i) => i !== item),
        );
      } else {
        form.setValue('settings', [...settingsValue, item]);
      }
    };

    const toggleAllSettings = (): void => {
      if (isSomeSelected) {
        form.setValue('settings', []);
      } else {
        form.setValue('settings', settingsList);
      }
    };

    const settingIsChecked = (item: string): boolean => {
      return settingsValue.some((i) => i === item);
    };

    const startEnrich = async () => {
      if (!fileUploadResponse) return;
      const enrichByPhone = form.getValues('enrichByPhone');
      const enrichByEmail = form.getValues('enrichByEmail');

      try {
        reportSelectedData();
        await enrichmentFileUploadUseCase.startJob(fileUploadResponse.uuid, {
          settings: settingsValue,
          data_to_enrich: [
            { value: enrichByPhone, type: EnrichmentDataType.Phones },
            { value: enrichByEmail, type: EnrichmentDataType.Email },
          ]
            .filter((i) => i.value)
            .map((item) => item.type),
          headers_map: fieldsMapperForm.getValues(
            'headers_map',
          ) as EnrichmentConfigForm['headers_map'],
        });
        toView('ConfigResultView');

        trackEvent(ANALYTICS_EVENTS.ENRICHMENT_COMPLETED);
      } catch (error) {
        logger.error(error);
      }
    };

    const back = () => {
      toView('ConfigFieldsMapperView');
    };

    const reportSelectedData = () => {
      const enrichByPhone = form.getValues('enrichByPhone');
      const enrichByEmail = form.getValues('enrichByEmail');

      let type: EnrichmentReportSelectedData = EnrichmentReportSelectedData.Emails;

      if (enrichByEmail && enrichByPhone) {
        type = EnrichmentReportSelectedData.Both;
      }

      if (enrichByEmail && !enrichByPhone) {
        type = EnrichmentReportSelectedData.Emails;
      }

      if (enrichByPhone && !enrichByEmail) {
        type = EnrichmentReportSelectedData.Phones;
      }

      trackEvent(ANALYTICS_EVENTS.ENRICHMENT_REPORT_CHOSEN_DATA, { type });
    };

    return {
      form,
      settingsIsOpen,
      setSettingsIsOpen,
      toggleSetting,
      toggleAllSettings,
      settingIsChecked,
      items: settingsList,
      isSomeSelected,
      startEnrich,
      back,
      isDisabled,
      selectedSettingsCount: settingsValue?.length ?? 0,
    };
  };
