import {
  IntegrationError,
  IntegrationNotFoundError,
  SalesforceApiDisabledError,
} from '@/features/integration/domain';
import { NetworkError } from '@/features/system/network';

export function mapError(error: unknown) {
  if (error instanceof NetworkError && error.response) {
    if (error.response.data?.name === 'SalesforceApiDisabled') {
      return new SalesforceApiDisabledError();
    }

    if (error.response.status === 404) {
      return new IntegrationNotFoundError();
    }
  }

  return new IntegrationError();
}
