import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAggregatedCreditsExist, usePlanName } from '@/features/common/workspace';

import PlanCardPreview from '@/assets/icons/planCardPreview.svg?react';

import { PlanCardCreditBased } from './components/PlanCardCreditBased';
import { PlanCardLayout } from './components/PlanCardLayout';
import { PlanCardUnlimited } from './components/PlanCardUnlimited';

export const PlanCard: FC = () => {
  const { t } = useTranslation('dashboard');
  const bulkCreditsExist = useAggregatedCreditsExist('bulk');
  const planName = usePlanName();

  if (!bulkCreditsExist.isLoaded || !planName.isLoaded) {
    return <PlanCardPreview width="402px" preserveAspectRatio="none" />;
  }

  if (bulkCreditsExist.data === true) {
    return (
      <PlanCardLayout
        title={t('planCard.title', {
          planName: planName.data,
        })}
      >
        <PlanCardUnlimited />
      </PlanCardLayout>
    );
  }

  return (
    <PlanCardLayout
      title={t('planCard.title', {
        planName: planName.data,
      })}
    >
      <PlanCardCreditBased />
    </PlanCardLayout>
  );
};
