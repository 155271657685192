import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const LimitReached: FC = () => {
  const { t } = useTranslation('settings');
  return (
    <>
      <Typography className={styles.title}>
        {t('teamManagement.limitError.title')}
      </Typography>
      <Typography className={styles.description}>
        {t('teamManagement.limitError.description')}
      </Typography>
    </>
  );
};
