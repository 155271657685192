import { FC, KeyboardEventHandler, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { SearchInput } from '@/components';

import { useSearch } from '@/hooks';

import { EnrichmentContext } from '../../EnrichmentContext';

import styles from './styles.module.scss';

export type SearchActionProps = {};

export const EnrichmentSearch: FC<SearchActionProps> = () => {
  const { t } = useTranslation('enrichment');
  const { value, handleSearchChange, handleSearchClear } = useSearch();
  const { search, setSearch } = useContext(EnrichmentContext);

  useEffect(() => {
    if (search !== value) {
      handleClear();
    }
  }, [search]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === 'Enter') {
      setSearch(value);
    }
  };

  const handleClear = (): void => {
    handleSearchClear();
    setSearch('');
  };

  return (
    <Box className={styles.Wrapper}>
      <SearchInput
        placeholder={t('subhead.searchField.placeholder')}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleSearchChange}
        onClear={handleClear}
      />
    </Box>
  );
};
