import { FC } from 'react';

import { UnauthenticatedLayout } from '@/router/layouts';

import { EmailVerification } from './components';
export const EmailConfirmPage: FC = () => {
  return (
    <UnauthenticatedLayout leftSlot={<EmailVerification />} privacyAndTerms={true} />
  );
};
