import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, Button } from '@mui/material';

import { IIntegrationEntity } from '@/features/integration/domain';
import { OnlineRequiredAction } from '@/features/system/OnlineTracker';

import { Tabs } from '@/components';

import { IMappingByObjectTypeEntityUI } from '../../../entities';

import { Table } from './Table';
import { useFieldMappingViewModel } from './useFieldMappingViewModel';

import styles from './styles.module.scss';

export const FieldMappingsTab: FC<{
  integration: IIntegrationEntity;
  providerName: string;
  integrationMapping: IMappingByObjectTypeEntityUI;
}> = ({ integration, integrationMapping, providerName }) => {
  const { tabs, form, disabled, handleSubmit, objectType, options } =
    useFieldMappingViewModel({
      integration,
      integrationMapping,
    });

  return (
    <Box className={styles.wrapper}>
      <Box component={'form'} onSubmit={handleSubmit}>
        <Box className={styles.containerHeader}>
          <Tabs
            aria-disabled={disabled}
            options={tabs.options}
            value={tabs.value}
            classes={{ indicator: styles.indicator }}
            onChange={(_e, tab): void => tabs.handleChange(tab)}
          />
          <OnlineRequiredAction>
            <Button
              variant={'contained'}
              type="submit"
              color="secondary"
              disabled={disabled}
              className={styles.submitButton}
            >
              Save
            </Button>
          </OnlineRequiredAction>
        </Box>

        <Box className={styles.divider} />

        <FormProvider {...form}>
          <Table
            key={objectType}
            objectType={objectType}
            providerName={providerName}
            requiredProviderFields={
              integrationMapping[objectType]?.requiredProviderFields ?? []
            }
            providerFieldOptions={options[objectType]?.providerFields ?? []}
            powerleadFieldOptions={options[objectType]?.powerleadFields ?? []}
          />
        </FormProvider>
      </Box>
    </Box>
  );
};
