import { injectable } from 'inversify';
import { inject } from 'inversify';

import { HUBSPOT_TYPES } from '@/ioc/types';

import type { IHubspotRepository } from '../data';

export interface IHubspotUseCase {
  setupHubspotContact(): Promise<void>;
}

@injectable()
export class HubspotUseCase implements IHubspotUseCase {
  @inject(HUBSPOT_TYPES.HubspotRepository)
  private hubspotRepository: IHubspotRepository;

  async setupHubspotContact(): Promise<void> {
    return this.hubspotRepository.setupHubspotContact();
  }
}
