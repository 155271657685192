import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlashIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="11.25 6 13 24">
    <path
      d="M129.526 7.7h-5.26l2.368-7.7h-7.243l-2.865 13.792h3.508L119.141 24z"
      transform="translate(-105.276 6)"
    />
  </SvgIcon>
);
