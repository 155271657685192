import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';

import UpgradeDialogImage from '@/assets/icons/settings/upgradeDialogImage.svg?react';

import styles from './styles.module.scss';

export const UpgradeAccount: React.FC = () => {
  const { t } = useTranslation('settings');

  return (
    <>
      <UpgradeDialogImage />
      <Typography className={styles.text}>
        {t('teamManagement.freePlanError.title')}
      </Typography>
      <CallToAction
        show="getCredits"
        className={styles.button}
        variant="contained"
        textOverrides={{
          getCredits: t('teamManagement.freePlanError.buttonText'),
        }}
      />
    </>
  );
};
