import { Container } from 'inversify';

import { ACCOUNT_SETTINGS_TYPES } from '@/ioc/types';

import { IAccountSettingsUseCase } from '@/features/settings/features/account';

import AccountSettingsUseCase from '../domain/AccountSettingsUseCase';

export const bindAccountSettingsModule = (container: Container) => {
  container
    .bind<IAccountSettingsUseCase>(ACCOUNT_SETTINGS_TYPES.AccountSettingsUseCase)
    .to(AccountSettingsUseCase);
};
