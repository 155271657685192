import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HelpCenterIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 -960 960 960">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M476-235q18 0 30.5-12.5T519-278q0-18-12.5-30.5T476-321q-18 0-30.5 12.5T433-278q0 18 12.5 30.5T476-235Zm-39-155h69q0-32 9.5-53.5T557-497q29-28 42.5-54.5T613-609q0-57-39-88t-98-31q-57 0-94 28t-54 72l63 26q11-23 31-41t56-18q33 0 50 17t17 41q0 22-12 39.5T499-525q-45 43-53.5 64.5T437-390ZM180-107q-29 0-51-22t-22-51v-600q0-29 22-51t51-22h600q29 0 51 22t22 51v600q0 29-22 51t-51 22H180Z"
    />
  </SvgIcon>
);
