import { GridPreProcessEditCellProps } from '@mui/x-data-grid/models/params/gridCellParams';
import { firstValueFrom } from 'rxjs';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListUseCase } from '@/features/common/contactList';
import { useAppLogger } from '@/features/system/logger';

export const usePreProcessNameCellEdit = () => {
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );
  const logger = useAppLogger();

  return async (params: GridPreProcessEditCellProps) => {
    try {
      if (!params.props.value || /^\s*$/.test(params.props.value)) {
        return {
          ...params,
          error: 'emptyValue',
        };
      }

      const listsArray = await firstValueFrom(
        contactListUseCase.getContactListByQuery({}),
      );

      if (listsArray.some(({ name }) => name === params.props.value)) {
        return {
          ...params,
          error: 'alreadyExists',
        };
      }

      return params;
    } catch (error) {
      logger.error(error);
    }
  };
};
