import { FC, forwardRef } from 'react';

import { CustomizedAlert, CustomizedAlertProps } from './CustomizedAlert';

type ErrorAlertProps = CustomizedAlertProps;

export const ErrorAlert: FC<ErrorAlertProps> = forwardRef<
  HTMLDivElement,
  ErrorAlertProps
>((props, ref) => {
  return <CustomizedAlert ref={ref} {...props} variant="error" />;
});

ErrorAlert.displayName = 'ErrorAlert';
