import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Select } from '@mui/material';
import clsx from 'clsx';

import { EnrichmentFileHeaderField } from '@/features/enrichment/domain/types';

import { MenuItem } from '@/components';
import { ArrowDownIcon, CheckCircleIcon } from '@/components/Icons';

import { useFileFieldCellViewModel } from './useFileFieldCellViewModel';

import styles from './styles.module.scss';

export const FileFieldCell: FC<{ fieldName: EnrichmentFileHeaderField }> = ({
  fieldName,
}) => {
  const { t } = useTranslation('enrichment');
  const { onChange, value, register, isValid, options, error } =
    useFileFieldCellViewModel(fieldName);

  return (
    <Box className={styles.Cell}>
      {isValid && (
        <Box className={styles.Icon}>
          <CheckCircleIcon />
        </Box>
      )}
      <Select
        {...register}
        value={value}
        onChange={onChange}
        IconComponent={ArrowDownIcon}
        displayEmpty
        MenuProps={{
          classes: {
            paper: styles.Paper,
          },
        }}
        error={!!error}
        className={styles.SelectWrapper}
        classes={{
          select: clsx(styles.Select, {
            [styles.Empty]: !value,
          }),
        }}
      >
        <MenuItem value="">
          {t('uploadingFieldsMapperModal.table.columns.csvColumn.select.defaultItem')}
        </MenuItem>
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
