import type { Components, Theme } from '@mui/material';

export const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: () => {
      return {
        fontSize: '14px',
        fontWeight: 'normal',
        color: 'black',
        lineHeight: '1.4',
      };
    },
  },
};
