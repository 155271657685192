import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path d="M6.164 0a6.164 6.164 0 1 0 6.164 6.164A6.171 6.171 0 0 0 6.164 0zm0 11.19a5.026 5.026 0 1 1 5.026-5.026 5.032 5.032 0 0 1-5.026 5.026z" />
    <path
      d="m355.279 354.475-3.262-3.262a.569.569 0 0 0-.8.8l3.262 3.262a.569.569 0 0 0 .8-.8z"
      transform="translate(-341.449 -341.449)"
    />
  </SvgIcon>
);
