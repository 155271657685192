import type { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: () => {
      return {
        margin: 0,
      };
    },
    label: ({ ownerState }) => {
      const placementStyles = {};
      if (ownerState.labelPlacement === 'end') {
        placementStyles['marginLeft'] = '5px';
      }
      return {
        fontSize: '12px',
        lineHeight: '16px',
        ...placementStyles,
      };
    },
  },
};
