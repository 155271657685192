import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, List, ListItem, Typography } from '@mui/material';

import { Checkbox, SearchInput, TagBadge } from '@/components';

import { Value } from '../../types';

import { usePopoverBodyViewModel } from './usePopoverBodyViewModel';

import styles from './styles.module.scss';

const NoResults: FC = () => {
  const { t } = useTranslation('tag');

  return (
    <Box className={styles.noResults}>
      <Typography className={styles.noResultsText}>
        {t('tagApplier.noResults')}
      </Typography>
    </Box>
  );
};

export const PopoverBody: FC<{
  selectedContacts: string[];
  defaultIndeterminatedValues: Value[];
  onApply: (values: Value[]) => void;
}> = ({ selectedContacts, defaultIndeterminatedValues, onApply }) => {
  const {
    search,
    handleListItemClick,
    handleSearchChange,
    handleSearchClear,
    tags,
    isCheckedItem,
    isIndeterminate,
    applyIsDisable,
    value,
    goToTagManagement,
  } = usePopoverBodyViewModel({ selectedContacts, defaultIndeterminatedValues });
  const { t } = useTranslation('tag');

  return (
    <>
      <Box className={styles.header}>
        <SearchInput
          value={search}
          placeholder={t('tagApplier.searchField.placeholder')}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
          variant="filled"
        />
      </Box>
      {tags.length ? (
        <List className={styles.list}>
          {tags.map((tag) => (
            <ListItem
              key={tag.uuid}
              className={styles.listItem}
              onClick={(): void => {
                handleListItemClick(tag.uuid);
              }}
            >
              <TagBadge bgColor={tag.color}>{tag.name}</TagBadge>
              <Checkbox
                indeterminate={isIndeterminate(tag.uuid)}
                checked={isCheckedItem(tag.uuid)}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <NoResults />
      )}
      <Box className={styles.footer}>
        <Button onClick={goToTagManagement}>{t('tagApplier.tagsManage')}</Button>
        <Button
          onClick={(): void => {
            onApply(value);
          }}
          disabled={applyIsDisable}
        >
          {t('tagApplier.apply')}
        </Button>
      </Box>
    </>
  );
};
