import type { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: ({ theme }) => {
      return {
        padding: '0',
        width: '16px',
        height: '16px',
        fontSize: '20px',
        color: theme.palette.info?.main,
      };
    },
  },
};
