import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactExportStatus } from '../types';

type ExportConfig = {
  open?: boolean;
  status?: ContactExportStatus;
  provider?: Nullable<{
    key: 'csv' | 'integration';
    label: string;
  }>;
};

type UseContactExportProgressResult = {
  open: boolean;
  status: ContactExportStatus;
  getMetadata(params: { selectedCount: number }): {
    successText: string;
    pendingText: string;
    errorText: string;
  };
  setConfig: (fn: (prevConfig: ExportConfig) => ExportConfig) => void;
  reset: () => void;
};

export const useContactExportProgress = (): UseContactExportProgressResult => {
  const { t } = useTranslation('contacts');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<ContactExportStatus>('pending');
  const [provider, setProvider] =
    useState<Nullable<{ key: 'csv' | 'integration'; label: string }>>(null);

  const setConfig = (fn: (prevConfig: ExportConfig) => ExportConfig): void => {
    const {
      open: newOpen,
      status: newStatus,
      provider: newProvider,
    } = fn({ open, status });
    if (newOpen !== undefined) setOpen(newOpen);
    if (newStatus !== undefined) setStatus(newStatus);
    if (newProvider !== undefined) setProvider(newProvider);
  };

  const getMetadata = (params: {
    selectedCount: number;
  }): {
    successText: string;
    pendingText: string;
    errorText: string;
  } => {
    const successText =
      params.selectedCount > 1
        ? t(`exportProgress.${provider?.key}.success_many`, {
            count: params.selectedCount,
            provider: provider?.label,
          })
        : t(`exportProgress.${provider?.key}.success_one`, {
            count: params.selectedCount,
            provider: provider?.label,
          });
    const pendingText = t(`exportProgress.${provider?.key}.pending`);
    const errorText = t(`exportProgress.${provider?.key}.error`, {
      count: params.selectedCount,
      provider: provider?.label,
    });
    return { successText, pendingText, errorText };
  };

  const reset = (): void => {
    setOpen(false);
    setStatus('pending');
    setProvider(null);
  };

  return {
    open,
    status,
    getMetadata,
    setConfig,
    reset,
  };
};
