import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { EnrichmentJobStatus } from '@/features/enrichment/domain/types';

import { useDownloadCellViewModel } from './useDownloadCellViewModel';

export const DownloadCell: FC<{ id: string; status: EnrichmentJobStatus }> = ({
  id,
  status,
}) => {
  const { t } = useTranslation('enrichment');
  const { handleClick, isDisabled } = useDownloadCellViewModel(id, status);

  return (
    <Button onClick={handleClick} variant="contained" size="small" disabled={isDisabled}>
      {t('table.row.download')}
    </Button>
  );
};
