import { Container } from 'inversify';

import { TAG_TYPES } from '@/ioc/types';

import {
  ITagDao,
  ITagRepository,
  ITagServicesApi,
  ITagUseCase,
} from '@/features/common/tag';
import { IReplicationService } from '@/features/system/replication';

import TagDao from '../data/db/dao/TagDao';
import { TagCollectionCreator } from '../data/db/TagCollectionCreator';
import { TagReplicationService } from '../data/db/TagReplicationService';
import TagApiService from '../data/network/TagApiSevices';
import TagRepository from '../data/TagRepository';
import TagUseCase from '../domain/TagUseCase';

export const bindTagModule = (container: Container): void => {
  container
    .bind<IReplicationService>(TAG_TYPES.TagReplicationService)
    .to(TagReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(TAG_TYPES.TagReplicationService);

  container.bind<TagCollectionCreator>('DbCollectionCreator').to(TagCollectionCreator);

  container.bind<ITagRepository>(TAG_TYPES.TagRepository).to(TagRepository);

  container.bind<ITagServicesApi>(TAG_TYPES.TagApiService).to(TagApiService);

  container.bind<ITagUseCase>(TAG_TYPES.TagUseCase).to(TagUseCase);

  container.bind<ITagDao>(TAG_TYPES.TagDao).to(TagDao).inSingletonScope();
};
