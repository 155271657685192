import { useParams } from 'react-router-dom';

import { useObservableResult } from '@/utils/rx';

import { IEnrichmentStatisticEntity } from '../../domain/entities';

import { useEnrichmentReportUseCase } from './hooks';

export const useEnrichmentReportViewModel = (): Nullable<IEnrichmentStatisticEntity> => {
  const { id = '' } = useParams<{ id: string }>();
  const enrichmentReportUseCase = useEnrichmentReportUseCase();

  const { data } = useObservableResult(() => enrichmentReportUseCase.getById(id), {
    deps: [id],
  });

  return data;
};
