import { BillingCycle, type IProductEntity } from '@/features/common/billing';
import type { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import type {
  ProductCredits,
  ProductMetadata,
  ProductPrice,
} from '../entities/GroupedProductMetadata';

/**
 * Provides general algorithm for building product metadata,
 * which have to be used by concrete builders for concrete plan.
 */
export abstract class ProductMetadataBuilder {
  constructor(
    protected products: IProductEntity[],
    protected subscription: IWorkspaceSubscriptionEntity,
    protected seats: number,
    protected billingCycle: BillingCycle,
  ) {}

  protected getMonthlyProduct(): IProductEntity {
    const product = this.products.find((product) => product.cycle === 'monthly');
    if (!product) throw new Error('Monthly product not found');
    return product;
  }

  protected getAnnualProduct(): IProductEntity {
    const product = this.products.find((product) => product.cycle === 'annually');
    if (!product) throw new Error('Annual product not found');
    return product;
  }

  protected getCreditsCount(
    product: IProductEntity,
    creditType: 'full' | 'bulk',
  ): ProductCredits {
    const quota = product.quotas.find((quota) => quota.creditType === creditType);
    if (!quota) return 0;
    if (quota.isUnlimited) return 'unlimited';
    return (quota.creditsFixed ?? 0) + (quota.creditsPerSeat ?? 0) * this.seats;
  }

  protected getPrice(
    product: IProductEntity,
    productToCompare?: IProductEntity,
  ): ProductPrice {
    const price = product.cycle === 'annually' ? product.price / 12 : product.price;

    if (!productToCompare) return price;

    return {
      price,
      crossed: productToCompare.price,
    };
  }

  protected getTotalPrice(product: IProductEntity): number {
    const monthlyPrice =
      product.cycle === 'annually' ? product.price / 12 : product.price;
    if (product.price === 0) return 0;
    if (product.isPriceFixed) return monthlyPrice;
    return monthlyPrice * this.seats;
  }

  protected isCurrentProduct(product: IProductEntity): boolean {
    return product.id === this.subscription.plan;
  }

  protected getId(product: IProductEntity): string {
    return product.id;
  }

  abstract getFeatures(): { title: string; items: string[] };
  abstract getName(): string;
  abstract getDescription(): string;
  abstract getIsCreditCardRequired(): boolean;
  abstract getIsMostPopular(): boolean;

  build(): ProductMetadata {
    const monthlyProduct = this.getMonthlyProduct();
    const annualProduct = this.getAnnualProduct();

    return {
      name: this.getName(),
      description: this.getDescription(),
      mostPopular: this.getIsMostPopular(),
      creditCardRequired: this.getIsCreditCardRequired(),
      variants: {
        [BillingCycle.Monthly]: {
          id: this.getId(monthlyProduct),
          current: this.isCurrentProduct(monthlyProduct),
          price: this.getPrice(monthlyProduct),
          totalPrice: this.getTotalPrice(monthlyProduct),
          isPriceFixed: monthlyProduct.isPriceFixed,
          fullCredits: this.getCreditsCount(monthlyProduct, 'full'),
          bulkCredits: this.getCreditsCount(monthlyProduct, 'bulk'),
          originalProduct: monthlyProduct,
        },
        [BillingCycle.Yearly]: {
          id: this.getId(annualProduct),
          current: this.isCurrentProduct(annualProduct),
          price: this.getPrice(annualProduct, monthlyProduct),
          totalPrice: this.getTotalPrice(annualProduct),
          isPriceFixed: annualProduct.isPriceFixed,
          fullCredits: this.getCreditsCount(annualProduct, 'full'),
          bulkCredits: this.getCreditsCount(annualProduct, 'bulk'),
          originalProduct: annualProduct,
        },
      },
      features: monthlyProduct.features,
      marketingFeatures: this.getFeatures(),
    };
  }
}
