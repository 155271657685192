import { inject, injectable } from 'inversify';
import type { Observable } from 'rxjs';

import { STORAGE_TYPES } from '@/ioc/types';

import type { IStorageRepository } from '../data/StorageRepository';

export interface IStorageUseCase {
  save: (map: Record<string, unknown>) => void;
  clear: () => void;
  get$: <T = unknown>(
    key: string,
    validator?: ValueTypeValidator<T>,
  ) => Observable<Nullable<T>>;
  get: <T = unknown>(key: string, validator?: ValueTypeValidator<T>) => Nullable<T>;
  delete: (key: string) => void;
}

@injectable()
export class StorageUseCase implements IStorageUseCase {
  @inject(STORAGE_TYPES.StorageRepository)
  private storageRepository: IStorageRepository;

  save(map: Record<string, unknown>): void {
    return this.storageRepository.save(map);
  }

  clear(): void {
    return this.storageRepository.clear();
  }

  get$<T = unknown>(
    key: string,
    validator?: ValueTypeValidator<T>,
  ): Observable<Nullable<T>> {
    return this.storageRepository.get$(key, validator);
  }

  get<T = unknown>(key: string, validator?: ValueTypeValidator<T>): Nullable<T> {
    return this.storageRepository.get(key, validator);
  }

  delete(key: string): void {
    return this.storageRepository.delete(key);
  }
}
