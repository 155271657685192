import { useEffect, useState } from 'react';

import { useAccount } from '@/features/common/account';
import { Permission, usePermissions } from '@/features/common/permissions';

type UseTeamMemberSelectResult = {
  value: string;
  setValue: (value: string) => void;
  resetValue: () => void;
};

export const useTeamMemberSelect = (defaultValue = ''): UseTeamMemberSelectResult => {
  const { account } = useAccount();
  const { hasPermissions } = usePermissions();
  const canAccessAllContacts = hasPermissions(Permission.CanAccessAllContact);

  const getDefaultValue = (): string => {
    if (account && !canAccessAllContacts) return account.uuid;
    return defaultValue ?? '';
  };

  const [value, setValue] = useState<string>(getDefaultValue);

  const resetValue = (): void => {
    setValue(getDefaultValue());
  };

  useEffect(() => {
    if (!account) return;

    if (!canAccessAllContacts) {
      setValue(account.uuid);
    } else {
      setValue('');
    }
  }, [canAccessAllContacts, account?.uuid]);

  return {
    value,
    setValue,
    resetValue,
  };
};
