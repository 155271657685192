import { Container } from 'inversify';

import { STORAGE_TYPES } from '@/ioc/types';

import { type IStorageRepository, StorageRepository } from '../data/StorageRepository';
import { type IStorageUseCase, StorageUseCase } from '../domain/StorageUseCase';

export const bindStorageModule = (container: Container): void => {
  container
    .bind<IStorageRepository>(STORAGE_TYPES.StorageRepository)
    .to(StorageRepository)
    .inSingletonScope();

  container.bind<IStorageUseCase>(STORAGE_TYPES.StorageUseCase).to(StorageUseCase);
};
