import { inject, injectable } from 'inversify';
import { map } from 'rxjs';

import { CONTACT_TYPES } from '@/ioc/types';

import { CollectionName, CompanyDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';

import { IContactSyncRepository } from '../../domain';

@injectable()
export class CompanyReplicationService extends ReplicationService<CompanyDocument> {
  @inject(CONTACT_TYPES.ContactSyncRepository)
  private syncRepository: IContactSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Company,
      pullStreamFactory: () =>
        this.syncRepository.getEvent().pipe(
          map(({ event }) => ({
            documents: Object.values(event.companies).map((dto: any) => {
              return {
                uuid: dto.uuid,
                name: dto.name,
                company_id: dto.company_id,
                topics: dto.topics,
                description: dto.description,
                industry: dto.industry,
                image: dto.image,
                images: dto.images,
                founded_year: dto.founded_year,
                phone: dto.phone,
                contact_email: dto.contact_email,
                employees: dto.employees,
                employees_min: dto.employees_min,
                employees_max: dto.employees_max,
                funding_rounds: dto.funding_rounds,
                total_funding: dto.total_funding,
                address: dto.address,
                websites: dto.websites,
                social_networks: dto.social_networks,
                financials: dto.financials,
              };
            }) as CompanyDocument[],
            checkpoint: { resume_token: null },
          })),
        ),
    });
  }
}
