import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Popover } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { PopoverBody } from './components/PopoverBody';
import { useTagApplyActionViewModel } from './useTagApplyActionViewModel';

import styles from './styles.module.scss';

export const TagApplyAction: FC = () => {
  const {
    handleApply,
    handleButtonClick,
    isOpen,
    anchorElement,
    intederminatedValues,
    isDisabled,
    selectedContacts,
    close,
  } = useTagApplyActionViewModel();
  const { t } = useTranslation('contacts');

  return (
    <Box className={styles.wrapper}>
      <ActionDisableGuard>
        <Button
          variant="outlined"
          color="info"
          disabled={isDisabled}
          onClick={handleButtonClick}
        >
          {t('subhead.tagsApply.button')}
        </Button>
      </ActionDisableGuard>
      <Popover
        open={isOpen}
        anchorEl={anchorElement}
        onClose={close}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        classes={{
          paper: styles.paper,
        }}
        keepMounted={false}
      >
        <PopoverBody
          defaultIndeterminatedValues={intederminatedValues}
          selectedContacts={selectedContacts}
          onApply={handleApply}
        />
      </Popover>
    </Box>
  );
};
