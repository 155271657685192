import { FC } from 'react';
import { Box } from '@mui/material';

import { Preview } from '@/components';

import styles from './styles.module.scss';

type CellBasePreviewProps = {
  variant?: 'single' | 'multiple';
};

export const CellBasePreview: FC<CellBasePreviewProps> = ({ variant = 'single' }) => {
  if (variant === 'multiple') {
    return (
      <Box className={styles.cellBasePreviewMultiple}>
        <Box className={styles.cellBasePreviewBoxDefault}>
          <Preview />
        </Box>
        <Box className={styles.cellBasePreviewBoxSmall}>
          <Preview />
        </Box>
      </Box>
    );
  }
  return (
    <Box className={styles.cellBasePreviewBoxDefault}>
      <Preview />
    </Box>
  );
};
