import { Permission, usePermissions } from '@/features/common/permissions';
import { useTeamMemberSelectOptions } from '@/features/settings';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useContactUseCase } from '../../../../hooks';

export const useContactOwnerCellViewModel = ({
  assignedTo,
  contactId,
}: {
  assignedTo: string;
  contactId: string;
}): {
  options: ReturnType<typeof useTeamMemberSelectOptions>;
  handleApply: (newAssignedTo: string) => Promise<void>;
  canChangeContactsOwner: boolean;
} => {
  const logger = useAppLogger();
  const options = useTeamMemberSelectOptions(true);
  const contactUseCase = useContactUseCase();
  const { trackEvent } = useAnalytics();

  const { hasPermissions } = usePermissions();
  const canChangeContactsOwner = hasPermissions(Permission.CanManageContactOwner);

  const handleApply = async (newAssignedTo: string): Promise<void> => {
    if (newAssignedTo === assignedTo) {
      return;
    }

    try {
      await contactUseCase.updateById(String(contactId), {
        assigned_to: newAssignedTo,
      }),
        trackEvent(ANALYTICS_EVENTS.CONTACT_OWNER_CHANGE);
    } catch (error) {
      logger.error(error);
    }
  };

  return {
    options,
    handleApply,
    canChangeContactsOwner,
  };
};
