import { RxJsonSchema } from 'rxdb';

import { ICompanyDC } from '../../dataContracts';

export const companySchema: RxJsonSchema<ICompanyDC> = {
  title: 'company schema',
  version: 1,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: {
      type: 'string',
      maxLength: 100,
    },
    company_id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    topics: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    description: {
      type: 'string',
    },
    industry: {
      type: 'string',
    },
    image: {
      type: 'string',
    },
    images: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    founded_year: {
      type: 'string',
    },
    total_funding: {
      type: 'string',
    },
    funding_rounds: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          last_round_money_raised: {
            type: 'string',
          },
        },
      },
    },
    phone: {
      type: 'string',
    },
    contact_email: {
      type: 'string',
    },
    employees: {
      type: 'number',
    },
    employees_min: {
      type: 'number',
    },
    employees_max: {
      type: 'number',
    },
    address: {
      type: 'string',
    },
    websites: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
          },
          og_image: {
            type: 'string',
          },
        },
      },
    },
    social_networks: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          sn_type: {
            type: 'number',
          },
          sn_id: {
            type: 'string',
          },
          url: {
            type: 'string',
          },
        },
      },
    },
    financials: {
      type: 'object',
      properties: {
        annual: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              total_revenue: {
                type: 'number',
              },
            },
          },
        },
      },
    },
  },
};
