import { FC } from 'react';

import { TeamMemberSelect } from '@/features/settings';

import { CommonActionProps } from '../types';

type FilterByUserActionProps = CommonActionProps & {
  value: string | null;
  onChange: (value: string) => void;
};

export const FilterByUserAction: FC<FilterByUserActionProps> = ({
  isLoading,
  onChange,
  value,
}) => {
  return <TeamMemberSelect value={value} isLoading={isLoading} onChange={onChange} />;
};
