import { injectable } from 'inversify';
import { MigrationStrategies } from 'rxdb';

import { IMigrationService } from '@/features/system/db';

@injectable()
export class EnrichmentMigrationService implements IMigrationService {
  public getMigrationStrategies(): MigrationStrategies {
    return {};
  }
}
