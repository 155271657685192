import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { IntegrationCardsGrid } from '@/features/integrations/ui/components/IntegrationCardsGrid';
import { useIntegrationsViewModel } from '@/features/integrations/ui/IntegrationsViewModel';

import { IntegrationCard } from './components/IntegrationCard';
import { IntegrationCardPreview } from './components/IntegrationCardPreview';
import { IntegrationConnectDialog } from './components/IntegrationConnectDialog';
import { IntegrationContactAdminDialog } from './components/IntegrationContactAdminDialog';
import { IntegrationErrorDialog } from './components/IntegrationErrorDialog';
import { IntegrationRequestDialog } from './components/IntegrationRequestDialog';
import { IntegrationUpgradeDialog } from './components/IntegrationUpgradeDialog';

import styles from './styles.module.scss';

const Integrations: React.FC = () => {
  const { t } = useTranslation('integrations');
  const {
    integrationsList,
    isLoading,
    selectedIntegration,
    handleIntegrationSelect,
    handleIntegrationConnect,
    handleContactSales,
    handleRetryConnect,
    connectDialog,
    connectionFailedDialog,
    errorVariant,
    upgradeDialog,
    requestDialog,
    rightsDialog,
  } = useIntegrationsViewModel();
  return (
    <>
      <div className={styles.PageHeader}>
        <Typography className={styles.PageHeaderTitle}>{t('pageTitle')}</Typography>
        <Typography className={styles.PageHeaderDescription}>
          {t('pageDescription')}
        </Typography>
      </div>
      <IntegrationCardsGrid>
        {isLoading ? (
          <>
            <IntegrationCardPreview />
            <IntegrationCardPreview />
            <IntegrationCardPreview />
            <IntegrationCardPreview />
            <IntegrationCardPreview />
            <IntegrationCardPreview />
            <IntegrationCardPreview />
          </>
        ) : (
          integrationsList.map((integration) => (
            <IntegrationCard
              key={integration.id}
              integration={integration}
              onConnect={handleIntegrationConnect}
              onContactSales={handleContactSales}
            />
          ))
        )}
      </IntegrationCardsGrid>
      <IntegrationErrorDialog
        isOpen={connectionFailedDialog.isOpen}
        onClose={connectionFailedDialog.onClose}
        onRetry={handleRetryConnect}
        integrationId={selectedIntegration?.id}
        variant={errorVariant}
      />
      <IntegrationContactAdminDialog
        isOpen={rightsDialog.isOpen}
        onClose={rightsDialog.onClose}
        integration={selectedIntegration}
        onSubmit={rightsDialog.onClose}
      />
      <IntegrationRequestDialog
        isOpen={requestDialog.isOpen}
        onClose={requestDialog.onClose}
        integrations={integrationsList}
        integration={selectedIntegration}
        onIntegrationChange={handleIntegrationSelect}
      />
      <IntegrationUpgradeDialog
        isOpen={upgradeDialog.isOpen}
        onClose={upgradeDialog.onClose}
        integration={selectedIntegration}
      />
      <IntegrationConnectDialog
        isOpen={connectDialog.isOpen}
        onClose={connectDialog.onClose}
        integration={selectedIntegration}
        onContactSales={handleContactSales}
      />
    </>
  );
};

export default Integrations;
