import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MUIconLockOutlined from '@mui/icons-material/LockOutlined';
import { Box, Button, FormLabel, OutlinedInput, Typography } from '@mui/material';

import { AuthIdentityProvider } from '@/features/common/auth/domain/types/AuthIdentityProvider';
import { ActionDisableGuard } from '@/features/common/permissions';

import { FormField } from '@/components';

import GoogleIcon from '@/assets/icons/Google.svg?react';
import MicrosoftIcon from '@/assets/icons/Microsoft.svg?react';

import { useAccountSettingsFormViewModel } from './useAccountSettingsFormViewModel';

import styles from './styles.module.scss';

type AccountSettingsFormProps = {
  provider: AuthIdentityProvider;
  onNameChange: (name: string) => Promise<void>;
  onPasswordChange: () => void;
};

const ICONS = {
  [AuthIdentityProvider.google]: <GoogleIcon className={styles.providerIcon} />,
  [AuthIdentityProvider.microsoft]: <MicrosoftIcon />,
  [AuthIdentityProvider.password]: null,
};

export const AccountSettingsForm: FC<AccountSettingsFormProps> = ({
  provider,
  onNameChange,
  onPasswordChange,
}) => {
  const { t } = useTranslation('settings');
  const { t: validationTranslate } = useTranslation('validation');

  const { form, onSubmit, onSupportContact, isFormDisabled, email } =
    useAccountSettingsFormViewModel({
      onNameChange,
    });

  return (
    <>
      <Box className={styles.inputWrapper}>
        <ActionDisableGuard>
          <FormField
            error={!!form.formState.errors.name}
            errorText={validationTranslate(form.formState.errors.name?.message || '')}
            labelText={t('account.providerFields.name')}
          >
            <OutlinedInput className={styles.input} {...form.register('name')} />
          </FormField>
        </ActionDisableGuard>
      </Box>

      <FormField>
        <Box className={styles.inputWrapper}>
          <FormLabel className={styles.label}>
            {t('account.providerFields.email')}
          </FormLabel>
          <Box className={styles.emailChangeWrapper}>
            {ICONS[provider]}
            <Typography variant={'h3'} className={styles.email}>
              {email}
            </Typography>
          </Box>
          <Typography variant={'subtitle2'} color={'info'} className={styles.emailTip}>
            <Trans
              t={t}
              i18nKey="account.providerFields.emailTip"
              components={{ a: <a onClick={onSupportContact} /> }}
            >
              To change your email, please{' '}
              <a onClick={onSupportContact}>contact support</a>
            </Trans>
          </Typography>
        </Box>
      </FormField>

      <FormField>
        <>
          <Box className={styles.inputWrapper}>
            <FormLabel className={styles.label}>
              {t('account.defaultProviderFields.password')}
            </FormLabel>
            <Box className={styles.passwordWrapper}>
              <Typography variant={'h3'} className={styles.password}>
                ************
              </Typography>
              <MUIconLockOutlined className={styles.lockIcon} />
            </Box>

            <ActionDisableGuard>
              <Button className={styles.changePasswordButton} onClick={onPasswordChange}>
                {t('account.defaultProviderFields.changePasswordButton')}
              </Button>
            </ActionDisableGuard>
          </Box>
        </>
      </FormField>

      <ActionDisableGuard>
        <Button
          type={'submit'}
          variant={'contained'}
          disabled={isFormDisabled}
          className={styles.buttonSave}
          onClick={onSubmit}
        >
          {t('account.saveChangesButton')}
        </Button>
      </ActionDisableGuard>
    </>
  );
};
