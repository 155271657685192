import { FC, forwardRef } from 'react';
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

import { CheckIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type CheckBoxDefaultProps = {
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
};
export type CheckboxProps = CheckBoxDefaultProps & MuiCheckboxProps;
export type CheckboxWithLabelProps = CheckBoxDefaultProps &
  Omit<FormControlLabelProps, 'control'>;

const DefaultIcon: FC = () => {
  return <Box className={styles.defaultIcon}></Box>;
};

const CheckedIcon: FC = () => {
  return (
    <Box className={styles.checkedIcon}>
      <CheckIcon />
    </Box>
  );
};

const IndeterminateIcon: FC = () => {
  return <Box className={styles.indeterminateIcon}>-</Box>;
};

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => (
  <MuiCheckbox
    {...props}
    icon={<DefaultIcon />}
    checkedIcon={<CheckedIcon />}
    indeterminateIcon={<IndeterminateIcon />}
    ref={ref}
  />
));

export const CheckboxWithLabel = forwardRef<unknown, CheckboxWithLabelProps>(
  (props, ref) => {
    return <FormControlLabel {...props} control={<Checkbox />} ref={ref} />;
  },
);
