import { injectable } from 'inversify';
import { mergeDeepRight } from 'ramda';
import { MangoQuery } from 'rxdb';
import { Observable } from 'rxjs';

import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { IEnrichmentDC } from '../../dataContract';

export type IEnrichmentDao = IDbCollection<IEnrichmentDC, 'uuid'> & {
  findAllByParams(params: MangoQuery<IEnrichmentDC>): Observable<IEnrichmentDC[]>;
};

@injectable()
export class EnrichmentDao
  extends DbCollection<IEnrichmentDC, 'uuid'>
  implements IEnrichmentDao
{
  constructor() {
    super({ collectionName: CollectionName.Enrichment, idKey: 'uuid' });
  }

  public findAllByParams(
    params: MangoQuery<IEnrichmentDC> = {},
  ): Observable<IEnrichmentDC[]> {
    const defaultQuery: MangoQuery<IEnrichmentDC> = {
      sort: [{ created_at: 'desc' }],
    };
    const query = mergeDeepRight(defaultQuery, params);

    return this.findAll(query);
  }
}
