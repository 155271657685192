import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';

import ModalIcon from '../../assets/ModalIcon.svg?react';

import styles from './styles.module.scss';

export const AdminAccessDeniedModal: FC = () => {
  const { t } = useTranslation('enrichment');

  return (
    <>
      <Box className={styles.Icon}>
        <ModalIcon />
      </Box>
      <Typography variant="h3" className={styles.Title}>
        {t('adminAccessDeniedModal.title')}
      </Typography>
      <Typography className={styles.SubTitle}>
        {t('adminAccessDeniedModal.subTitle')}
      </Typography>
      <CallToAction
        withIcon={false}
        show="getCredits"
        textOverrides={{
          getCredits: t('adminAccessDeniedModal.button'),
        }}
        variant="contained"
        className={styles.Button}
      />
    </>
  );
};
