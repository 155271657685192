import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { IPlanDetailsEntity } from '@/features/billing/domain/entities/PlanDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { useObservableResult } from '@/utils/rx';

type UsePlanOverviewViewModel = () => {
  isLoading: boolean;
  name: string;
  isUpgradable: boolean;
  isCustom: boolean;
  lifecycle: {
    title: string;
    value: string;
  };
  seats: string;
};

export const usePlanOverviewViewModel: UsePlanOverviewViewModel = () => {
  const billingSettingsUseCase = useBillingSettingsUseCase();
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const formatSeats = (seats: IPlanDetailsEntity['seats']): string => {
    if (seats.limit === 0 || seats.count === seats.limit) {
      return t('seats.count', { count: seats.count });
    }

    return t('seats.countRange', { used: seats.count, limit: seats.limit });
  };

  const formatLifecycle = (
    lifecycle: IPlanDetailsEntity['lifecycle'],
  ): ReturnType<UsePlanOverviewViewModel>['lifecycle'] => {
    if (!lifecycle.date) {
      if (lifecycle.type === 'expirable')
        return {
          title: t('lifecycle.expireble.title'),
          value: t('lifecycle.expireble.noExpire'),
        };
      return {
        title: t('lifecycle.renewable.title'),
        value: t('lifecycle.renewable.noRenewal'),
      };
    }

    const value = lifecycle.date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return {
      title:
        lifecycle.type === 'expirable'
          ? t('lifecycle.expireble.title')
          : t('lifecycle.renewable.title'),
      value,
    };
  };

  const planDetails = useObservableResult(
    () =>
      billingSettingsUseCase.getCurrentPlanDetails().pipe(
        map((planDetails) => ({
          name: planDetails.name,
          isUpgradable: planDetails.isUpgradable,
          isCustom: planDetails.isCustom,
          lifecycle: formatLifecycle(planDetails.lifecycle),
          seats: formatSeats(planDetails.seats),
        })),
      ),
    {
      deps: [],
    },
  );

  return {
    isLoading: planDetails.isLoading,
    name: planDetails.data?.name ?? '',
    isUpgradable: planDetails.data?.isUpgradable ?? false,
    isCustom: planDetails.data?.isCustom ?? false,
    lifecycle: planDetails.data?.lifecycle ?? { title: '', value: '' },
    seats: planDetails.data?.seats ?? '',
  };
};
