import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AnalyticsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 -960 960 960">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M284-277h60v-205h-60v205Zm332 0h60v-420h-60v420Zm-166 0h60v-118h-60v118Zm0-205h60v-60h-60v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Z"
    />
  </SvgIcon>
);
