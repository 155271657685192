import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class ProProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    return {
      title: 'What’s included:',
      items: [
        'Data exports to CSV',
        'Invite, collaborate with, and manage team members',
        'Bulk enrichment & bulk save',
      ],
    };
  }
  getName(): string {
    return 'Pro';
  }
  getDescription(): string {
    return 'The tools your team needs to keep on growing';
  }
  getIsCreditCardRequired(): boolean {
    return true;
  }
  getIsMostPopular(): boolean {
    return false;
  }
}
