export enum ANALYTICS_EVENTS {
  VIEW_DASHBOARD_PAGE = 'view_dashboard_page',
  USER_PRODUCT_WALKTHROUGH = 'user_product_walkthrough',
  VIEW_TEAM_MANAGEMENT_PAGE = 'view_team_managment_page',
  ADD_TEAM_MEMBER_BUTTON_CLICK = 'invite_button_clicked',
  ADD_TEAM_MEMBER = 'add_member',
  REMOVE_TEAM_MEMBER = 'remove_member',
  TEAM_MEMBER_RESEND_INVITATION = 'resend_invitation',
  INTEGRATIONS_VIEW_PAGE = 'view_integrations_page',
  INTEGRATIONS_CONNECT_CLICK = 'integrations_clicked_connect',
  INTEGRATIONS_CONTACT_SALES_CLICK = 'integrations_contact_sales_click',
  INTEGRATIONS_REQUEST_SENT = 'request_integrations_sent',
  TAG_ADD_TAG = 'create_new_tag',
  VIEW_MY_ACCOUNT_PAGE = 'view_my_account_page',
  DELETE_ACCOUNT = 'delete_account',
  CHANGE_EMAIL = 'change_email',
  CHANGE_PASSWORD = 'change_password',
  NEW_LIST_CLICK = 'new_list_clicked',
  VIEW_SIGNIN_PAGE_WEB = 'view_signin_page_web',
  SIGNIN_CLICK_BUTTON_WEB = 'signin_click_button_web',
  SIGNIN_ERROR = 'signin_error',
  SIGNIN_SUCCESS = 'signin_success',
  VIEW_SIGNUP_PAGE_WEB = 'view_signup_page_web',
  SIGNUP_CLICK_BUTTON_WEB = 'signup_click_button_web',
  SIGNUP_ERROR = 'signup_error',
  SIGNUP_COMPLETE = 'signup_complete',
  CLICK_CHANGE_CARD = 'click_change_card',
  VIEW_VERIFICATION_PAGE = 'view_verification_page',

  VIEW_CREATE_ACCOUNT_PAGE = 'view_create_account_page',

  FORGOT_PASSWORD_CLICK_BUTTON = 'forgot_password_click_button',
  FORGOT_PASSWORD_INSERT_EMAIL = 'forgot_password_insert_email',
  VIEW_SUBSCRIPTION_PAGE = 'view_subscriptions_page',
  RESET_PASSWORD_EMAIL_SENT = 'reset_password_email_sent',
  VIEW_RESET_PASSWORD_PAGE = 'view_reset_password_page',
  RESET_PASSWORD_COMPLETE = 'reset_password_complete',
  TAG_MANAGEMENT_VIEWED = 'tag_management_viewed',
  REMOVE_TAG = 'remove_tag',
  VIEW_CONTACTS_TAB = 'view_contacts_tab',
  VIEW_ALL_CONTACTS_LIST = 'view_all_contacts_list',
  VIEW_LIST = 'view_list_page',
  RENAME_LIST = 'rename_list',
  DELETE_LIST = 'delete_list',
  CONTACT_INFO_EMAIL_ACTION = 'contact_info_email_action',
  CONTACT_INFO_PHONE_ACTION = 'contact_info_phone_action',
  CONTACT_INFO_SOCIAL_ACTION = 'contact_info_social_action',
  COMPANY_FIELD_CLICK = 'company_field_click',
  CONTACT_OWNER_CHANGE = 'change_contact_owner',
  CONTACT_ADD_TO_LIST = 'contact_add_to_list',
  CREATE_NEW_LIST = 'create_new_list',
  CONTACT_ADD_TAG = 'contact_add_tag',
  EXPORT_BUTTON_CLICK = 'export_button_click',
  EXPORT_COMPLETED = 'export_completed',
  CLICK_TALK_TO_SALES = 'click_talk_to_sales',
  VIEW_PLANS_PAGE = 'view_plans_page',
  CLICKS_SELECT_PLAN = 'clicks_select_plan',
  VIEW_PAYMENT_DETAILS_PAGE = 'view_payment_details_page',
  CLICKS_SUBMIT_PURCHASE = 'clicks_submit_purchase',
  PAYMENT_SUCCESS = 'payment_success',
  PAYMENT_FAILED = 'payment_failed',
  CANCEL_SUBSCRIPTION = 'cancel_subscription',
  CHANGE_SUBSCRIPTION = 'change_subscription',

  FILTER_BY_USERS = 'filter_by_users',
  FILTER_BY_TIME = 'filter_by_time',

  WATCH_TUTORIAL = 'watch_tutorial',

  RECEIVED_RETENTION_OFFER = 'received_retention_offer',
  ACCEPTED_RETENTION_OFFER = 'accepted_retention_offer',
  DECLINED_RETENTION_OFFER = 'declined_retention_offer',

  ENRICHMENT_UPLOAD_BTN_CLICK = 'user_clicks_upload_csv_button',
  ENRICHMENT_UPLOAD_COMPLETED = 'upload_csv_file_completed',
  ENRICHMENT_UPLOAD_ERROR = 'upload_csv_file_error',
  ENRICHMENT_REPORT_CHOSEN_DATA = 'csv_choose_data_to_enrich',
  ENRICHMENT_COMPLETED = 'enrich_csv_completed',
  ENRICHMENT_DOWNLOAD_CSV = 'download_csv',

  SEND_REFERRAL_EMAIL = 'send_referral_email',
  REFERRAL_COPY_REFERRAL_LINK = 'copy_referral_link',
  INVITEE_OPEN_LINK = 'invitee_open_link',
  INVITEE_COMPLETE_REGISTRATION = 'invitee_complete_registration',
}
