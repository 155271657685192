import { useCallback } from 'react';
import dayjs from 'dayjs';

import { useInjection } from '@/ioc/ioc.react';
import { STORAGE_TYPES } from '@/ioc/types';

import { IStorageUseCase } from '@/features/system/storage';

import { useObservableResult } from '@/utils/rx';

type UseLockTimerResult = (
  key: string,
  minutes: number,
) => {
  isLocked: boolean;
  lockedUntil: Nullable<number>;
  lock(): void;
  onTimerComplete: () => void;
};

export const useLockTimer: UseLockTimerResult = (key: string, minutes: number) => {
  const now = dayjs().unix();
  const storageUseCase = useInjection<IStorageUseCase>(STORAGE_TYPES.StorageUseCase);
  const { data } = useObservableResult(() => storageUseCase.get$<number>(key), {
    deps: [key, minutes],
  });
  const isLocked = !!data && data > now;

  const onTimerComplete = useCallback(() => {
    storageUseCase.delete(key);
  }, []);

  const lock = useCallback(() => {
    if (isLocked) {
      return;
    }
    storageUseCase.save({
      [key]: dayjs().add(minutes, 'minutes').unix(),
    });
  }, [isLocked, minutes]);

  return {
    lock,
    isLocked,
    lockedUntil: data,
    onTimerComplete,
  };
};
