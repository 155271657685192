import type { Container } from 'inversify';

import { AUTH_TYPES } from '@/ioc/types';

import {
  AuthApiService,
  AuthRepository,
  FirebaseService,
  type IAuthApiService,
  type IAuthRepository,
} from '../data';
import { type IAuthUseCase, type IAuthWatcherUseCase } from '../domain';
import { AuthUseCase } from '../domain/AuthUseCase';
import { AuthWatcherUseCase } from '../domain/AuthWatcherUseCase';

export function bindAuthModule(container: Container): void {
  container
    .bind<IAuthApiService>(AUTH_TYPES.AuthApiService)
    .to(AuthApiService)
    .inSingletonScope();

  container.bind(AUTH_TYPES.FirebaseService).to(FirebaseService).inSingletonScope();

  container
    .bind<IAuthRepository>(AUTH_TYPES.AuthRepository)
    .to(AuthRepository)
    .inSingletonScope();

  container.bind<IAuthUseCase>(AUTH_TYPES.AuthUseCase).to(AuthUseCase);

  container
    .bind<IAuthWatcherUseCase>(AUTH_TYPES.AuthWatcherUseCase)
    .to(AuthWatcherUseCase);
}
