import * as yup from 'yup';

import { PhoneHelper } from '@/features/system/PhoneHelper';

export const PhoneValidationSchema = yup
  .string()
  .test('isPhoneValid', 'phoneInvalid', (value: string) =>
    new PhoneHelper().isPossibleNumber(value),
  )
  .required('phoneRequired');
