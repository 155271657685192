import { useEffect, useState } from 'react';
import { isMobile as getIsMobile } from 'utils/isMobile';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(() => getIsMobile());

  useEffect(() => {
    const handleWindowSizeChange = (): void => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return (): void => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};
