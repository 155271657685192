import { FC, memo } from 'react';
import { Box } from '@mui/material';

import { ContactsTable as Table } from '@/features/common/contact';
import { IContactListEntity } from '@/features/common/contactList';

import { useContactsTableViewModel } from './useContactsTableViewModel';

import styles from './styles.module.scss';

type ContactsTableProps = {
  currentContactList: IContactListEntity;
  defaultContactList: IContactListEntity;
};

export const ContactsTable: FC<ContactsTableProps> = memo(
  ({ currentContactList, defaultContactList }) => {
    const {
      columns,
      rows,
      isLoading,
      handleSortModelChange,
      pageSize,
      pagesCount,
      page,
      contactEntitiesCount,
      handlePaginationChange,
      noResultsOverlay,
      handleRowSelectionModelChange,
    } = useContactsTableViewModel({ currentContactList, defaultContactList });

    return (
      <Box className={styles.wrapper}>
        <Table
          columns={columns}
          rows={rows}
          loading={isLoading}
          pageSize={pageSize}
          onSortModelChange={handleSortModelChange}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          checkboxSelection={true}
          slots={{
            noResultsOverlay,
          }}
          slotProps={{
            noResultsOverlay: {
              // @ts-ignore
              defaultContactListId: defaultContactList.uuid,
            },
          }}
          classes={{ main: styles.main }}
        >
          <Table.Pagination
            showExtraInfo
            isLoading={isLoading}
            pageSize={pageSize}
            page={page}
            count={pagesCount}
            totalCount={contactEntitiesCount}
            onChange={handlePaginationChange}
          />
        </Table>
      </Box>
    );
  },
);
