import { FC } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

export const DateCell: FC<{ value: number }> = ({ value }) => {
  return (
    <Typography variant="inherit">
      {dayjs(value * 1000).format('MMM DD, YYYY')}
    </Typography>
  );
};
