import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

export interface IRetentionServicesApi {
  acceptRetentionOffer(): Observable<void>;
}

const retentionEndpoints = {
  postRetentionOffer: '/api/v1/billing/retension-credits',
};

@injectable()
export class RetentionApiService implements IRetentionServicesApi {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  acceptRetentionOffer(): Observable<void> {
    return this.httpClient
      .post(retentionEndpoints.postRetentionOffer, {})
      .pipe(map(() => void 0));
  }
}
