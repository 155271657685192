import { useEffect } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import {
  CustomerMessagingEventKeys,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

export const useCMPage = (eventKey: CustomerMessagingEventKeys): void => {
  const cmUseCase = useInjection<ICustomerMessagingUseCase>(
    CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase,
  );

  useEffect(() => {
    cmUseCase.page(eventKey);
  }, []);
};
