import { useMemo } from 'react';
import { AnimationEvent } from 'chart.js';

import { theme } from '@/theme';

export const useChartOptions = () => {
  return useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false },
      },
      scales: {
        x: { display: false, grid: { display: false } },
        y: { display: false },
      },
      elements: {
        bar: {
          borderRadius: { topLeft: 12, topRight: 12 },
        },
      },
      layout: { padding: { top: 25, bottom: 26 } },
      events: [],
      animation: {
        duration: 0.1,
        onComplete({ chart }: AnimationEvent) {
          const { ctx } = chart;

          ctx.font = `17px ${theme.typography.fontFamily}`;
          ctx.textAlign = 'center';
          ctx.fillStyle = 'black';
          ctx.textBaseline = 'bottom';

          chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(`${data}`, bar.x, bar.y - 5);
            });
          });
        },
      },
    }),
    [],
  );
};
