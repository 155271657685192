import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { GridToolbarProps } from '@mui/x-data-grid-pro';

import { ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

type ToolbarProps = GridToolbarProps & {
  isLoading: boolean;
};

const ToolbarPreview: FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.preview} />
    </Box>
  );
};

export const Toolbar: FC<ToolbarProps> = ({ isLoading }) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const handleClick = (): void => {
    navigate(ROUTES.CONTACTS_ENTRY);
  };

  if (isLoading) {
    return <ToolbarPreview />;
  }

  return (
    <Box className={styles.toolbar}>
      <Typography variant="h1">{t('recentContacts')}</Typography>
      <Button variant="text" onClick={handleClick}>
        {t('viewAll')}
      </Button>
    </Box>
  );
};
