import { createContext } from 'react';

export type CacheKey = string;

export class ObservableResultCache {
  private cache = new Map<CacheKey, unknown>();

  get<T>(key: CacheKey): T | undefined {
    if (!this.cache.has(key)) return;

    return this.cache.get(key) as T;
  }

  has(key: CacheKey): boolean {
    return this.cache.has(key);
  }

  set<T>(key: CacheKey, value: T): void {
    this.cache.set(key, value);
  }

  clear() {
    this.cache = new Map();
  }
}

export const ObservableResultCacheContext = createContext<ObservableResultCache>(
  new ObservableResultCache(),
);
