import { type Container } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import { AnalyticsRepository } from '../data';
import {
  AnalyticsUseCase,
  type IAnalyticsRepository,
  type IAnalyticsUseCase,
} from '../domain';

export const bindAnalyticsModule = (container: Container): void => {
  container
    .bind<IAnalyticsRepository>(ANALYTICS_TYPES.AnalyticsRepository)
    .to(AnalyticsRepository)
    .inSingletonScope();

  container
    .bind<IAnalyticsUseCase>(ANALYTICS_TYPES.AnalyticsUseCase)
    .to(AnalyticsUseCase);
};
