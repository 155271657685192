import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

export enum ContainerVariant {
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl',
  LG = 'lg',
}

export type ContainerProps = BoxProps & {
  variant?: ContainerVariant;
};

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  variant = ContainerVariant.XL,
  className,
  ...props
}) => {
  return (
    <Box
      {...props}
      className={clsx(className, styles.container, {
        [styles['container-xl']]: variant === ContainerVariant.XL,
        [styles['container-xxl']]: variant === ContainerVariant.XXL,
        [styles['container-xxxl']]: variant === ContainerVariant.XXXL,
        [styles['container-lg']]: variant === ContainerVariant.LG,
      })}
    >
      {children}
    </Box>
  );
};
