import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const NoRowsOverlay: FC = () => {
  return (
    <Box className={styles.wrapper}>
      <Typography color="info" fontWeight={600} fontSize={14}>
        Billing history is empty
      </Typography>
    </Box>
  );
};
