import { Container } from 'inversify';

import { SERVER_TIME_TYPES } from '@/ioc/types';

import { IServerTimeRepository, ServerTimeRepository } from '../data';
import { IServerTimeUseCase, ServerTimeUseCase } from '../domain';

export const bindServerTimeModule = (container: Container): void => {
  container
    .bind<IServerTimeRepository>(SERVER_TIME_TYPES.ServerTimeRepository)
    .to(ServerTimeRepository)
    .inSingletonScope();

  container
    .bind<IServerTimeUseCase>(SERVER_TIME_TYPES.ServerTimeUseCase)
    .to(ServerTimeUseCase);
};
