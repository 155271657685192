import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { ArrowDownIcon } from '@/components/Icons';

import { useCollapsable } from '@/hooks';

import { CheckedList } from '..';

import styles from './styles.module.scss';

type PlanFeaturesProps = {
  features: {
    title: string;
    items: Array<string | ReactNode>;
  };
  featuresDetailed: {
    category: string;
    items: {
      text: string;
      hint?: string;
    }[];
  }[];
};

export const PlanFeatures: FC<PlanFeaturesProps> = ({ features, featuresDetailed }) => {
  const { t } = useTranslation('plans');
  const [isExpanded, setIsExpanded] = useState(false);
  const collapsable = useCollapsable<HTMLDivElement>(isExpanded);

  return (
    <div>
      <div className={styles.planFeatures}>
        {features.title ? (
          <Typography className={clsx(styles.planFeaturesText, styles.title)}>
            {features.title}
          </Typography>
        ) : null}
        {isExpanded ? null : (
          <CheckedList items={features.items.map((feature) => ({ text: feature }))} />
        )}
      </div>
      <div
        className={clsx(styles.featuresDetailed, {
          [styles.isExpanded]: isExpanded,
        })}
      >
        <div ref={collapsable.ref} className={styles.featuresDetailedContent}>
          {featuresDetailed.map((featureByCategory, index) => (
            <div
              key={featureByCategory.category}
              className={styles.featuresDetailedCategory}
            >
              <Typography className={styles.featuresDetailedCategoryTitle}>
                {featureByCategory.category}
              </Typography>
              {index === 0 ? (
                <CheckedList
                  items={features.items.map((feature) => ({ text: feature }))}
                />
              ) : null}
              <CheckedList items={featureByCategory.items} />
            </div>
          ))}
        </div>
        <div
          className={styles.featuresDetailedToggle}
          onClick={(): void => setIsExpanded((isExpanded) => !isExpanded)}
        >
          <Typography className={styles.featuresDetailedToggleText}>
            {t('seeAllFeatures')}
          </Typography>
          <ArrowDownIcon className={styles.iconArrow} />
        </div>
      </div>
    </div>
  );
};
