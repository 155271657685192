import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { IEnrichmentStatisticEntity } from '../../../../domain/entities';

import styles from './styles.module.scss';

type Props = {
  data: IEnrichmentStatisticEntity['fields'];
};

export const EnrichmentFieldsList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('enrichment', { keyPrefix: 'report' });

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.wrapperInner}>
        <Typography fontSize={19}>{t('tableTitle')}</Typography>
        <Box className={styles.grid}>
          {data.map(({ fieldName, count }) => (
            <Box key={fieldName} className={styles.item}>
              <Typography>{fieldName}</Typography>
              <Typography>{count}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
