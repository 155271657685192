import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IContactListEntity, IContactListUseCase } from '../..';

export const useContactListById = (
  id: string,
): ObservableResult<Nullable<IContactListEntity>> => {
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );

  return useObservableResult(() => contactListUseCase.getContactListById(id), {
    deps: [id],
  });
};
