import { useContext } from 'react';

import type { ConformationModalData } from './components/confirmationModal/ConfirmationModal';
import type { InformationModalData } from './components/informationModal/InformationModal';
import { MODAL_VARIANTS } from './constants';
import type { ContextType } from './context';
import { ModalContext } from './ModalProvider';

type UseConfirmationModal = () => Omit<ContextType, 'openModal'> & {
  openModal: (confirmationModalData: ConformationModalData) => void;
};

export const useConfirmationModal: UseConfirmationModal = () => {
  const context = useContext(ModalContext);

  return {
    ...context,
    openModal: (confirmationModalData: ConformationModalData) =>
      context.openModal({
        type: MODAL_VARIANTS.confirmationModal,
        props: confirmationModalData,
      }),
  };
};

type UseInformationModal = () => Omit<ContextType, 'openModal'> & {
  openModal: (informationModalData: InformationModalData) => void;
};

export const useInformationModal: UseInformationModal = () => {
  const context = useContext(ModalContext);

  return {
    ...context,
    openModal: (informationModalData: InformationModalData) =>
      context.openModal({
        type: MODAL_VARIANTS.informationModal,
        props: informationModalData,
      }),
  };
};
