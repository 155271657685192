import { UserCredential } from 'firebase/auth';
import { inject, injectable } from 'inversify';

import { AUTH_TYPES, ONBOARDING_TYPES } from '@/ioc/types';

import type { IAuthRepository } from '@/features/common/auth';
import type { IOnboardingUseCase } from '@/features/common/onboarding';
import { ISignInUseCase } from '@/features/signin/domain';

@injectable()
export class SignInUseCase implements ISignInUseCase {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ONBOARDING_TYPES.OnboardingUseCase)
  private onboardingUseCase: IOnboardingUseCase;

  async loginGoogle(): Promise<string> {
    const result = await this.authRepository.signInWithGoogle();
    this.completeOnboardingSteps();
    return result;
  }

  async loginMicrosoft(): Promise<string> {
    const result = await this.authRepository.signInWithMicrosoft();
    this.completeOnboardingSteps();
    return result;
  }

  async loginWithEmailAndPass(email: string, password: string): Promise<string> {
    const result = await this.authRepository.signInWithEmailAndPassword(email, password);
    this.completeOnboardingSteps();
    return result;
  }

  private completeOnboardingSteps(): void {
    this.onboardingUseCase.completeMobileSignupOpenChromeStoreStep();
  }

  sendVerificationLinkForRestorePassword(email: string): Promise<boolean> {
    return this.authRepository.sendPasswordResetEmail(email);
  }

  loginWithCustomToken(customToken: string): Promise<UserCredential> {
    return this.authRepository.signInWithCustomToken(customToken);
  }
}
