import { useEffect, useSyncExternalStore } from 'react';

type WidgetConfig = {
  isVisible?: boolean;
  isOpen?: boolean;
};
type OpenShowOptions = { closeRest?: boolean; hideRest?: boolean };
type HideOptions = { showPrevious?: boolean };

class Widget {
  name: string;
  isVisible: boolean;
  isOpen: boolean;

  constructor(name: string, config?: WidgetConfig) {
    this.name = name;
    this.isVisible = config?.isVisible !== undefined ? config.isVisible : true;
    this.isOpen = config?.isOpen !== undefined ? config.isOpen : false;
  }

  open = (): void => {
    this.isOpen = true;
  };

  close = (): void => {
    this.isOpen = false;
  };

  show = (): void => {
    this.isVisible = true;
  };

  hide = (): void => {
    this.isVisible = false;
  };
}

class WidgetStore {
  private widgetsMap: Record<string, Widget> = {};
  private listeners: (() => void)[] = [];
  private previousVisible: string[] = [];

  private copyMap = (): Record<string, Widget> => {
    return { ...this.widgetsMap };
  };

  private guardByExistence = (name: string): void => {
    if (!this.widgetsMap[name]) {
      throw new Error(`Widget with name ${name} not exists`);
    }
  };

  private makePreviousSnapshot = (): void => {
    this.previousVisible = Object.entries(this.widgetsMap)
      .filter(([_, widget]) => widget.isVisible)
      .map(([key]) => key);
  };

  private updateByOpenShowOptions = (
    nameToExlude: string,
    options?: OpenShowOptions,
  ): void => {
    Object.entries(this.widgetsMap)
      .filter(([key]) => key !== nameToExlude)
      .forEach(([_, widget]) => {
        if (options?.hideRest || options?.closeRest) {
          widget.close();
        }
        if (options?.hideRest) {
          widget.hide();
        }
      });
  };

  private getCurrentWidgetAndCopyMap = (
    name: string,
  ): [Widget, Record<string, Widget>] => {
    const copy = this.copyMap();
    const widget = copy[name];
    return [widget, copy];
  };

  public register = (name: string, config?: WidgetConfig): void => {
    if (this.widgetsMap[name]) return;

    this.widgetsMap = {
      ...this.widgetsMap,
      [name]: new Widget(name, config),
    };
  };

  public unRegister = (name: string): void => {
    this.guardByExistence(name);
    const [, copy] = this.getCurrentWidgetAndCopyMap(name);
    delete copy[name];

    this.widgetsMap = copy;
  };

  public open = (name: string, options?: OpenShowOptions): void => {
    if (options?.hideRest) this.makePreviousSnapshot();

    this.updateByOpenShowOptions(name, options);

    const [widget, copy] = this.getCurrentWidgetAndCopyMap(name);
    widget.open();
    this.widgetsMap = copy;

    this.emitChange();
  };

  public close = (name: string): void => {
    this.guardByExistence(name);

    const [widget, copy] = this.getCurrentWidgetAndCopyMap(name);
    widget.close();
    this.widgetsMap = copy;

    this.emitChange();
  };

  public show = (name: string, options?: OpenShowOptions): void => {
    this.guardByExistence(name);
    if (options?.hideRest) this.makePreviousSnapshot();

    this.updateByOpenShowOptions(name, options);

    const [widget, copy] = this.getCurrentWidgetAndCopyMap(name);
    widget.show();
    this.widgetsMap = copy;

    this.emitChange();
  };

  public hide = (name: string, options?: HideOptions): void => {
    this.guardByExistence(name);

    if (options?.showPrevious) {
      Object.entries(this.widgetsMap)
        .filter(([key, _]) => this.previousVisible.includes(key))
        .forEach(([_, widget]) => {
          widget.show();
        });
    }

    const [widget, copy] = this.getCurrentWidgetAndCopyMap(name);
    widget.hide();
    this.widgetsMap = copy;

    this.emitChange();
  };

  public hideAll = (): void => {
    const copy = this.copyMap();
    Object.entries(copy).forEach(([_, widget]) => {
      widget.close();
      widget.hide();
    });

    this.widgetsMap = copy;
    this.emitChange();
  };

  public subscribe = (listener: () => void): (() => void) => {
    this.listeners = [...this.listeners, listener];
    return (): void => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };

  public getVisibleSnapshot = (): Record<string, Widget> => {
    return this.widgetsMap;
  };

  private emitChange = (): void => {
    for (const listener of this.listeners) {
      listener();
    }
  };
}

const widgetStore = new WidgetStore();

export const useWidgetManager = (widgetName: string, config?: WidgetConfig) => {
  widgetStore.register(widgetName, config);
  const widgets = useSyncExternalStore(
    widgetStore.subscribe,
    widgetStore.getVisibleSnapshot,
  );
  const widget = widgets[widgetName];

  const open = (options?: OpenShowOptions): void => {
    widgetStore.open(widgetName, options);
  };

  const close = (): void => {
    widgetStore.close(widgetName);
  };

  const show = (options?: OpenShowOptions): void => {
    widgetStore.show(widgetName, options);
  };

  const hide = (options?: HideOptions): void => {
    widgetStore.hide(widgetName, options);
  };

  useEffect(() => {
    return (): void => {
      widgetStore.unRegister(widgetName);
    };
  }, []);

  return {
    show,
    open,
    close,
    hide,
    hideAll: widgetStore.hideAll,
    isOpen: Boolean(widget.isOpen),
    isVisible: Boolean(widget.isVisible),
    isAnotherOpened: Object.values(widgets).some(
      (w) => w.isOpen && w.name !== widgetName,
    ),
  };
};
