import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  share,
  switchMap,
} from 'rxjs';

import { LEADER_ELECTION_TYPES, REPLICATION_TYPES } from '@/ioc/types';
import { AUTH_TYPES, DB_TYPES } from '@/ioc/types';

import { AuthStatus, IAuthRepository } from '@/features/common/auth';
import { Database, IDbManager } from '@/features/system/db';
import { DbStatus } from '@/features/system/db/data/DbManagerNew';
import type { ILeaderElectionRepository } from '@/features/system/leaderElection';

import { IReplicationRepository, type ReplicationStatus } from '../data';

export interface IReplicationUseCase {
  start: () => Observable<ReplicationStatus>;
}

@injectable()
export class ReplicationUseCase implements IReplicationUseCase {
  @inject(REPLICATION_TYPES.ReplicationRepository)
  private replicationRepository: IReplicationRepository;

  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leaderElectionRepository: ILeaderElectionRepository;

  private canRunReplication(): Observable<boolean> {
    const isDbReady$ = this.dbManager
      .getStatus()
      .pipe(map((status) => status === DbStatus.Ready));
    const isAuthorized$ = this.authRepository
      .getAuthStatus()
      .pipe(map((status) => status === AuthStatus.Authorized));
    const isTabActive$ = this.leaderElectionRepository.getIsLeaderSubject();

    return combineLatest([isDbReady$, isAuthorized$, isTabActive$]).pipe(
      map((dependencies) => dependencies.every(Boolean)),
      distinctUntilChanged(),
    );
  }

  public start(): Observable<ReplicationStatus> {
    return combineLatest({
      canRunReplication: this.canRunReplication(),
      db: this.dbManager.getDb(),
    }).pipe(
      switchMap(({ canRunReplication, db }) => {
        if (canRunReplication) {
          return this.runReplication(db);
        }

        return of(null);
      }),
      switchMap(() => this.replicationRepository.getStatus()),
      distinctUntilChanged(),
      share(),
    );
  }

  private runReplication(db: Database): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      (async (): Promise<void> => {
        this.replicationRepository.init(db);
        await this.replicationRepository.start();
        subscriber.next(true);
      })();

      return () => {
        this.replicationRepository.stop();
      };
    });
  }
}
