import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { WORKSPACE_TYPES } from '@/ioc/types';

import {
  ISubscriptionUseCase,
  IWorkspaceSubscriptionEntity,
} from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

export const useWorkspaceSubscription =
  (): ObservableResult<IWorkspaceSubscriptionEntity> => {
    const useCase = useInjection<ISubscriptionUseCase>(
      WORKSPACE_TYPES.SubscriptionUseCase,
    );
    const getSubscription$ = useMemo(() => {
      return useCase.getSubscription();
    }, []);
    return useObservableResult(getSubscription$);
  };
