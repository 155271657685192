import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowBackIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 -960 960 960">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M372-182 73-480l299-299 83 84-156 156h597v118H299l156 156-83 83Z"
    />
  </SvgIcon>
);
