import { RxJsonSchema } from 'rxdb';

import { IContactListDC } from '../../dataContracts/ContactListDC';

export const contactListSchema: RxJsonSchema<IContactListDC> = {
  title: 'contactList schema',
  version: 0,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    name: { type: 'string' },
    created_by: { type: 'string' },
    created_at: { type: 'number' },
    updated_at: { type: 'number' },
    contacts_amount: { type: 'number' },
    special_type: { type: 'string' },
  },
};
