import { useCurrentWorkspace } from '@/features/common/workspace';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { useChartData } from './hooks/useChartData';
import { useDateSelectFacade } from './hooks/useDateSelectFacade';
import { useMembersSelectFacade } from './hooks/useMembersSelectFacade';
import { getMinDateFromWorkspaceMember } from './utils/getMinDateFromWorkspaceMember';
import { parseSelectedDate } from './utils';

export type UseAnalyticsCardViewModelReturn = {
  creditsUsed: number;
  emailAddresses: number;
  phoneNumbers: number;
  selectedUser: string;
  handleUsersChange: (value: string) => void;
  isLoading: boolean;
  tooltipTitles: string[];
  labels: string[];
  values: { x: number; y: number }[];
  handleDateChange: (value: string) => void;
  selectedDate: DATE_SELECT_OPTION_VALUES;
  minDate: number;
};

export const useAnalyticsCardViewModel = (): UseAnalyticsCardViewModelReturn => {
  const { selectedDate, handleDateChange } = useDateSelectFacade();

  const { selectedUser, handleUsersChange } = useMembersSelectFacade();

  const { data: workspace } = useCurrentWorkspace();

  const { minDate } = getMinDateFromWorkspaceMember(selectedUser, workspace);

  const { fromDate, toDate } = parseSelectedDate({ selectedDate, minDate });

  const { labels, values, tooltipTitles, creditsUsed, emailAddresses, phoneNumbers } =
    useChartData({
      selectedUser,
      selectedDate,
      fromDate,
      toDate,
    });

  const isLoading = false;

  return {
    creditsUsed,
    emailAddresses,
    phoneNumbers,
    tooltipTitles,
    selectedUser,
    handleUsersChange,
    handleDateChange,
    selectedDate,
    isLoading,
    labels,
    values,
    minDate,
  };
};
