import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { GridSortItem } from '@mui/x-data-grid-pro';
import { indexBy, prop } from 'ramda';

import {
  ContactInfoFilterType,
  IContactEntity,
  UpdateNotification,
  useContacts,
} from '@/features/common/contact';
import { useTeamMemberSelect } from '@/features/settings';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

import { createSearchParameters } from './contextUtils';

export interface IContactsContext {
  selectedContacts: string[];
  setSelectedContacts: (ids: string[]) => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  filterByUser: string | null;
  setFilterByUser: Dispatch<SetStateAction<string>>;
  filterByDate: string;
  setFilterByDate: Dispatch<SetStateAction<string>>;
  filterByTags: string[];
  setFilterByTags: Dispatch<SetStateAction<string[]>>;
  filterByExport: string;
  setFilterByExport: Dispatch<SetStateAction<string>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  sort: GridSortItem;
  setSort: Dispatch<SetStateAction<GridSortItem>>;
  filterByContactInfo: ContactInfoFilterType[];
  setFilterByContactInfo: Dispatch<SetStateAction<ContactInfoFilterType[]>>;
  contactURLSearchParams: URLSearchParams;
  contactListId?: string;
  contactEntities: IContactEntity[] | null;
  contactEntitiesMapById: { [key: string]: Nullable<IContactEntity> };
  contactEntitiesCount: number;
  contactEntitiesLoading: boolean;
  contactEntitiesError: boolean;
  filtersIsApplied: boolean;
  resetFilterByUser: () => void;
  resetAllFilters: () => void;
}

export const ContactsContext = createContext<IContactsContext>({} as IContactsContext);

export const ContactsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id: contactListId } = useParams();
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const {
    value: filterByUser,
    setValue: setFilterByUser,
    resetValue: resetFilterByUser,
  } = useTeamMemberSelect();
  const [filterByDate, setFilterByDate] = useState<DATE_SELECT_OPTION_VALUES | string>(
    DATE_SELECT_OPTION_VALUES.allTime,
  );
  const [filterByExport, setFilterByExport] = useState<string>('');
  const [filterByTags, setFilterByTags] = useState<string[]>([]);
  const [filterByContactInfo, setFilterByContactInfo] = useState<ContactInfoFilterType[]>(
    [],
  );
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<GridSortItem>({ field: 'created_at', sort: 'asc' });
  const contactURLSearchParams = createSearchParameters({
    search,
    filterByUser,
    filterByDate,
    filterByTags,
    filterByExport,
    page,
    sort,
    filterByContactInfo,
    contactListId,
  });
  const queryString = contactURLSearchParams.toString();
  const { data, isLoading, error } = useContacts(queryString);

  function withPageReset<T>(func: Dispatch<SetStateAction<T>>): (value: T) => void {
    return (value: T): void => {
      setPage(1);
      return func(value);
    };
  }

  const resetAllFilters = (): void => {
    setPage(1);
    setSearch('');
    setSort({ field: 'created_at', sort: 'asc' });
    resetFilterByUser();
    setFilterByDate(DATE_SELECT_OPTION_VALUES.allTime);
    setFilterByTags([]);
    setFilterByContactInfo([]);
    setFilterByExport('');
  };

  return (
    <ContactsContext.Provider
      value={{
        selectedContacts,
        setSelectedContacts,
        search,
        setSearch: withPageReset(setSearch),
        filterByDate,
        setFilterByDate: withPageReset(setFilterByDate),
        filterByUser,
        setFilterByUser: withPageReset(setFilterByUser),
        filterByTags,
        setFilterByTags: withPageReset(setFilterByTags),
        page,
        filterByExport,
        setFilterByExport: withPageReset(setFilterByExport),
        setPage,
        sort,
        setSort,
        filterByContactInfo,
        setFilterByContactInfo: withPageReset(setFilterByContactInfo),
        contactURLSearchParams,
        contactListId,
        contactEntities: data.entities,
        contactEntitiesMapById: indexBy(prop('uuid'), data.entities),
        contactEntitiesCount: data.totalCount,
        contactEntitiesLoading: isLoading,
        contactEntitiesError: Boolean(error),
        filtersIsApplied: Boolean(
          filterByTags.length ||
            filterByUser ||
            search ||
            filterByDate !== DATE_SELECT_OPTION_VALUES.allTime ||
            filterByContactInfo.length ||
            filterByExport,
        ),
        resetFilterByUser,
        resetAllFilters,
      }}
    >
      {children}
      <UpdateNotification queryString={queryString} contactListId={contactListId} />
    </ContactsContext.Provider>
  );
};
