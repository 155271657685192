import { FC, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BillingCycleContext } from '@/features/plans/ui/contexts';
import { PlanFeatureLevelSupport } from '@/features/plans/ui/types';

import { CheckIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const FeatureSupportLevelCell: FC<{ level: PlanFeatureLevelSupport }> = ({
  level,
}) => {
  const billingCycle = useContext(BillingCycleContext);

  const { t } = useTranslation('plans');

  switch (level.brandTag) {
    case 'supported':
      return (
        <CheckIcon
          classes={{
            root: styles.checkIcon,
          }}
        />
      );
    case 'unlimited':
      return <Fragment>{t('features.supportLevels.unlimited')}</Fragment>;
    case 'comingSoon':
      return <Fragment>{t('features.supportLevels.comingSoon')}</Fragment>;
    case 'limited':
      return (
        <Fragment>
          {level.translationKey ? t(level.translationKey, level) : level.limit}
        </Fragment>
      );
    case 'billingCycleDependent':
      return <FeatureSupportLevelCell level={level.support[billingCycle]} />;
    default:
      return null;
  }
};
