export enum ReplicationServiceStatus {
  Initial = 'initial',
  Ready = 'ready',
  Error = 'error',
}

export enum ReplicationStatus {
  Starting = 'Starting',
  Started = 'Started',
  Stopped = 'Stopped',
  Stopping = 'Stopping',
}

export enum ReplicationPushHandlerExecActionType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}
