/**
 * Enum for the different auth status
 * @enum AuthStatus
 * @property {string} Initialisation - The auth is not determined yet, as we are still loading the user or account.
 * @property {string} Unauthorized - The user is not authorized and we do not have his access token
 * @property {string} Authorized - The user is authorized and we have his access token
 **/
export enum AuthStatus {
  Initialisation = 'INITIALISATION',
  Unauthorized = 'UNAUTHORIZED',
  Authorized = 'AUTHORIZED',
}
