import type { Components, Theme } from '@mui/material';

export const MuiSnackbar: Components<Theme>['MuiSnackbar'] = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  styleOverrides: {
    root: () => {
      return {
        borderRadius: 6,
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.16)',
      };
    },
  },
};
