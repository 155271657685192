import { createContext } from 'react';

type CanBuyContext = {
  canBuy: () => Promise<boolean>;
};

export const CanBuyContext = createContext<CanBuyContext>({
  async canBuy() {
    return true;
  },
});
