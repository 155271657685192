import { DomainError } from '@/features/common/error';

import type { IReceiptEntity, IUpcomingReceiptEntity } from '../entities';

export class WrongUpcomingReceiptTotalError extends DomainError {
  upcomingReceipt: IUpcomingReceiptEntity;
  predictedReceipt: IReceiptEntity;

  constructor({
    upcomingReceipt,
    predictedReceipt,
  }: {
    upcomingReceipt: IUpcomingReceiptEntity;
    predictedReceipt: IReceiptEntity;
  }) {
    super(`Wrong total in upcoming receipt entity.`);
    this.upcomingReceipt = upcomingReceipt;
    this.predictedReceipt = predictedReceipt;
  }
}
