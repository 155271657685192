import { inject, injectable, postConstruct } from 'inversify';
import { filter, Observable } from 'rxjs';

import { SYNC_TYPES } from '@/ioc/types';
import { APP_LOGGER_TYPES } from '@/ioc/types';

import { IAppLogger } from '@/features/system/logger';

import {
  BaseSyncEntityType,
  IBaseSyncEntity,
  IBaseSyncInvalidateEvent,
  IBaseSyncRepository,
  isBaseSyncEntity,
  isBaseSyncInvalidateEvent,
  isDeleteBaseSyncEntity,
  SyncStatus,
} from '../domain';

import { IBaseSyncService } from './network';

@injectable()
export class BaseSyncRepository implements IBaseSyncRepository {
  @inject(SYNC_TYPES.BaseSyncService)
  private baseSyncService: IBaseSyncService;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  @postConstruct()
  logStatus(): void {
    this.getStatus().subscribe((status) => {
      this.logger.log(`[BaseSync]: ${status}`);
    });
  }

  getEntityEvents<T extends BaseSyncEntityType>(
    entityType: T,
  ): Observable<IBaseSyncEntity<T>> {
    return this.baseSyncService.events$.pipe(
      filter((event): event is IBaseSyncEntity<T> => {
        return isBaseSyncEntity(event) && event.entity_type === entityType;
      }),
    );
  }

  getProspectTaskEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.ProspectTask>> {
    return this.getEntityEvents(BaseSyncEntityType.ProspectTask);
  }

  getAccountEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.Account>> {
    return this.getEntityEvents(BaseSyncEntityType.Account);
  }

  getContactListEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.ContactList>> {
    return this.getEntityEvents(BaseSyncEntityType.ContactList);
  }

  getTagEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.Tag>> {
    return this.getEntityEvents(BaseSyncEntityType.Tag);
  }

  getWorkspaceEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.Workspace>> {
    return this.getEntityEvents(BaseSyncEntityType.Workspace);
  }

  getWorkspaceStatEvents(): Observable<
    IBaseSyncEntity<BaseSyncEntityType.WorkspaceStat>
  > {
    return this.getEntityEvents(BaseSyncEntityType.WorkspaceStat);
  }

  getEnrichmentEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.Enrichment>> {
    return this.getEntityEvents(BaseSyncEntityType.Enrichment);
  }

  getInvitationEvents(): Observable<IBaseSyncEntity<BaseSyncEntityType.Invitation>> {
    return this.getEntityEvents(BaseSyncEntityType.Invitation);
  }

  getInvalidateEvents(): Observable<IBaseSyncInvalidateEvent> {
    return this.baseSyncService.events$.pipe(
      filter((event): event is IBaseSyncInvalidateEvent =>
        isBaseSyncInvalidateEvent(event),
      ),
    );
  }

  getIntegrationExportTaskEvents(): Observable<
    IBaseSyncEntity<BaseSyncEntityType.IntegrationExportTask>
  > {
    return this.getEntityEvents(BaseSyncEntityType.IntegrationExportTask);
  }

  getDeleteAccountEvents(): Observable<IBaseSyncEntity> {
    return this.getAccountEvents().pipe(filter(isDeleteBaseSyncEntity));
  }

  stop(options?: { reset?: boolean }): void {
    this.baseSyncService.stop(options);
  }

  start(token: string): void {
    this.baseSyncService.start({ token });
  }

  pause(): void {
    this.baseSyncService.pause();
  }

  unpause(): void {
    this.baseSyncService.unpause();
  }

  getStatus(): Observable<SyncStatus> {
    return this.baseSyncService.status$;
  }
}
