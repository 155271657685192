import { Container } from 'inversify';

import { PROSPECT_TASK_TYPES } from '@/ioc/types';

import {
  IProspectTaskApiService,
  ProspectTaskApiService,
  ProspectTaskRepository,
} from '../data';
import {
  IProspectTaskRepository,
  IProspectTaskUseCase,
  ProspectTaskUseCase,
} from '../domain';

export const bindProspectTaskModule = (container: Container): void => {
  container
    .bind<IProspectTaskRepository>(PROSPECT_TASK_TYPES.ProspectTaskRepository)
    .to(ProspectTaskRepository)
    .inSingletonScope();

  container
    .bind<IProspectTaskApiService>(PROSPECT_TASK_TYPES.ProspectTaskApiService)
    .to(ProspectTaskApiService);

  container
    .bind<IProspectTaskUseCase>(PROSPECT_TASK_TYPES.ProspectTaskUseCase)
    .to(ProspectTaskUseCase);
};
