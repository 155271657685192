import { FC, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useAccount, UserRole } from '@/features/common/account';
import { useWorkspaceSubscription } from '@/features/common/workspace';

import { CreditCardExpiredBanner, PaymentIssueBanner } from './components';

type BillingBannerViewModel = {
  banner: FC | null;
};

enum BillingIssue {
  CreditCardExpired = 'CreditCardExpired',
  PaymentFailed = 'PaymentFailed',
}

const billingIssueBannerMap: Record<BillingIssue, FC> = {
  [BillingIssue.CreditCardExpired]: CreditCardExpiredBanner,
  [BillingIssue.PaymentFailed]: PaymentIssueBanner,
};

const GLOBAL_BANNER_ROUTES_BLACKLIST = Object.values(ROUTES.SETTINGS);

function useBillingIssue(): BillingIssue | null {
  const { data: subscription } = useWorkspaceSubscription();

  const isLastPaymentFailed = subscription?.isGracePeriod ?? false;

  if (isLastPaymentFailed) {
    return BillingIssue.PaymentFailed;
  }

  //TODO: Add logic for expired card later, cause now we do not have a way to check if credit card is expired

  return null;
}

function useCanShowBanner(params: { isGlobal?: boolean }): boolean {
  const location = useLocation();
  const { account } = useAccount();

  return useMemo(() => {
    if (!(account?.role === UserRole.Admin || account?.role === UserRole.Owner)) {
      return false;
    }

    if (matchPath(ROUTES.SETTINGS.SUBSCRIPTION, location.pathname)) {
      return false;
    }
    if (!params.isGlobal) {
      return true;
    }
    return !GLOBAL_BANNER_ROUTES_BLACKLIST.find((route) =>
      matchPath(route, location.pathname),
    );
  }, [location.pathname, params.isGlobal, account?.role]);
}

export function useBillingBannerViewModel(params: {
  isGlobal?: boolean;
}): BillingBannerViewModel {
  const billingIssue = useBillingIssue();
  const banner = billingIssue ? billingIssueBannerMap[billingIssue] : null;
  const showBanner = useCanShowBanner(params);
  return {
    banner: showBanner ? banner : null,
  };
}
