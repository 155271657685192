import { ChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/ChartDataDC';
import { ChartDataEntity } from '@/features/dashboard/features/chart/domain/entities/ChartDataEntity';

export const mapDashboardDcToEntity = (data: ChartDataDC[]): ChartDataEntity[] => {
  return data.map((dc) => ({
    uuid: dc.uuid,
    creditsUsed: dc.credits_used,
    at: dc.at,
    member: dc.member,
    revealedContactsCount: dc.revealed_contacts_count,
    emailsCount: dc.emails_count,
    phonesCount: dc.phones_count,
    totalRevealedContactsCount: dc.total_revealed_contacts_count,
    totalCreditsUsed: dc.total_credits_used,
    totalEmailsCount: dc.total_emails_count,
    totalPhonesCount: dc.total_phones_count,
    currentEmailsCount: dc.current_emails_count,
    currentPhonesCount: dc.current_phones_count,
    currentRevealedContactsCount: dc.current_revealed_contacts_count,
    currentCreditsUsed: dc.current_credits_used,
    lastRevealedContactDate: dc.last_revealed_contact_date,
  }));
};
