import React, { PropsWithChildren } from 'react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';

import { SnackbarProvider } from '@/components';

import { theme } from './theme';

LicenseInfo.setLicenseKey(
  '2e94c83c5a42067551fce691dbf9d4e5Tz05MTI4NixFPTE3NDgxODUxNjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  </MuiThemeProvider>
);
