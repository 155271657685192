import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';

import { EnrichmentJobStatus } from '@/features/enrichment/domain/types';

import { CellBasePreview } from '@/components';

import {
  DateCell,
  DownloadCell,
  StatusCell,
  UserCell,
  ViewReportCell,
} from './components';

import styles from './styles.module.scss';

export const useEnrichmentTableColumns = (isLoading = false): GridColDef[] => {
  const { t } = useTranslation('enrichment');

  const columns: GridColDef[] = [
    {
      headerName: t('table.columns.name'),
      field: 'name',
      sortable: true,
      minWidth: 170,
      headerClassName: styles.FirstCell,
      cellClassName: styles.FirstCell,
      flex: 1,
    },
    {
      headerName: t('table.columns.date'),
      field: 'createdAt',
      sortable: true,
      minWidth: 170,
      flex: 1,
      renderCell({ row }): ReactElement {
        return <DateCell value={row.createdAt} />;
      },
    },
    {
      headerName: t('table.columns.status'),
      field: 'status',
      sortable: true,
      minWidth: 180,
      flex: 1,
      renderCell({ row }): ReactElement {
        if (row.status === EnrichmentJobStatus.InProgress) {
          return (
            <StatusCell
              type={row.status}
              value={Math.floor((row.progress / row.statistic.total.count) * 100) || 0}
            />
          );
        }
        return <StatusCell type={row.status} />;
      },
    },
    {
      headerName: t('table.columns.totalRecords'),
      field: 'total',
      sortable: true,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell({ row }): ReactElement {
        return row.statistic.total.count;
      },
    },
    {
      headerName: t('table.columns.matchedRecords'),
      field: 'matched',
      sortable: true,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell({ row }): ReactElement {
        return row.statistic.total.matched || '- - -';
      },
    },
    {
      headerName: t('table.columns.duplicates'),
      field: 'duplicate',
      sortable: true,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell({ row }): ReactElement {
        return row.statistic.total.duplicate || '- - -';
      },
    },
    {
      headerName: t('table.columns.user'),
      field: 'createdBy',
      sortable: true,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell({ row }): ReactElement {
        return (
          <UserCell uuid={row.createdBy.uuid} fullName={row.createdBy.fullName || ''} />
        );
      },
    },
    {
      headerName: t('table.columns.viewReport'),
      field: 'viewReport',
      sortable: false,
      minWidth: 140,
      maxWidth: 200,
      flex: 1,
      renderCell({ row }): ReactElement {
        return <ViewReportCell id={row.uuid} status={row.status} />;
      },
    },
    {
      headerName: t('table.columns.download'),
      field: 'download',
      sortable: false,
      minWidth: 140,
      maxWidth: 200,
      renderCell({ row }): ReactElement {
        return <DownloadCell id={row.uuid} status={row.status} />;
      },
    },
  ];

  if (!isLoading) return columns;

  return columns.map((column, index) => ({
    ...column,
    renderCell: () => <CellBasePreview />,
  }));
};
