import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export interface IAddToListButtonProps {
  onClick: () => void;
  widthFitsContainer?: boolean;
  inputValue: string;
}

export const AddToListButton: FC<IAddToListButtonProps> = ({
  onClick,
  widthFitsContainer,
  inputValue,
}) => {
  const { t } = useTranslation('contactsTable');
  return (
    <Box
      className={clsx(styles.addToListButton, {
        [styles.addToListButtonWidthFitsContainer]: widthFitsContainer,
      })}
    >
      <Button onClick={onClick}>
        {t('listCell.newListButton', {
          inputValue,
          interpolation: { escapeValue: false },
        })}
      </Button>
    </Box>
  );
};
