import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';

const TagTableContactsCountCell: React.FC<GridRenderCellParams> = ({
  row: { contacts },
}) => {
  return <div className="MuiDataGrid-cellContent">{contacts}</div>;
};

export default TagTableContactsCountCell;
