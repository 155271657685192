import { FormEventHandler } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { useCreateContactList } from '@/features/common/contactList';
import { ContactNameAlreadyExistError } from '@/features/common/contactList/domain/errors';
import { useAppLogger } from '@/features/system/logger';

import { useFormWithSchema } from '@/utils/validation';

import { ListNameValidationSchema } from '../validation/ListNameValidationSchema';

const CreateNewListFormSchema = yup.object({
  listName: ListNameValidationSchema,
});

type CreateNewListFormType = yup.InferType<typeof CreateNewListFormSchema>;

export const useCreateNewListForm = (
  onSuccessCallback: () => void,
): {
  handleFormSubmit: FormEventHandler;
  form: UseFormReturn<{ listName: string }>;
} => {
  const createContactList = useCreateContactList();
  const logger = useAppLogger();

  const form = useFormWithSchema(CreateNewListFormSchema, {
    defaultValues: {
      listName: '',
    },
  });

  const handleFormSubmit: FormEventHandler = (e): void => {
    e.preventDefault();
    void form.handleSubmit(async ({ listName }: CreateNewListFormType) => {
      try {
        await createContactList(listName);
        onSuccessCallback && onSuccessCallback();
      } catch (error) {
        logger.error(error);
        let errorMessage = error;

        if (error instanceof ContactNameAlreadyExistError) {
          errorMessage = error.message;
        }

        form.setError('listName', {
          message: errorMessage,
        });
      }
    })();
  };

  return {
    form,
    handleFormSubmit,
  };
};
