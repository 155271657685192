import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, OutlinedInput, Typography } from '@mui/material';
import clsx from 'clsx';

import { UnauthenticatedLayout } from '@/router/layouts';

import { LockTimer } from '@/components/LockTimer';

import { ErrorTypes, useForgotPasswordViewModel } from './useForgotPasswordViewModel';

import styles from './styles.module.scss';

export const ForgotPassword: FC = () => {
  const { error, onSubmit, onComplete, linkSent, form, lockedUntil } =
    useForgotPasswordViewModel();
  const { t } = useTranslation('auth');
  const { t: validationTranslate } = useTranslation('validation');
  const email = form.watch('email');

  return (
    <UnauthenticatedLayout.Container>
      <UnauthenticatedLayout.Title>
        {t('forgotPassword.title')}
      </UnauthenticatedLayout.Title>
      <UnauthenticatedLayout.Subtitle>
        {t('forgotPassword.description')}
      </UnauthenticatedLayout.Subtitle>
      {linkSent && error?.type !== ErrorTypes.TOO_MANY_ATTEMPTS ? (
        <>
          <Box className={styles.containerSent}>
            {t('forgotPassword.successSending', { email })}
          </Box>
          <Typography className={styles.resendEmail}>
            <Trans
              t={t}
              i18nKey={'verification.resendCode'}
              components={{
                span: (
                  <span
                    className={clsx(styles.highlightText, styles.link)}
                    onClick={onSubmit}
                  />
                ),
              }}
            >
              Don’t see the email? Check your spam folder or have us
              <span>resend the code</span>.
            </Trans>
          </Typography>
        </>
      ) : (
        error?.type !== ErrorTypes.TOO_MANY_ATTEMPTS && (
          <UnauthenticatedLayout.Form>
            <UnauthenticatedLayout.FormField
              error={validationTranslate(form.formState.errors.email?.message || '')}
            >
              <OutlinedInput
                placeholder={t('forgotPassword.emailPlaceholder')}
                {...form.register('email')}
                className={styles.input}
              />
            </UnauthenticatedLayout.FormField>

            <UnauthenticatedLayout.SubmitButton
              onClick={onSubmit}
              disabled={
                form.formState.isSubmitting ||
                !form.formState.isDirty ||
                !form.formState.isValid
              }
            >
              {t('forgotPassword.submitButton')}
            </UnauthenticatedLayout.SubmitButton>

            {error?.type === ErrorTypes.EMAIL && (
              <UnauthenticatedLayout.ErrorAlert className={styles.error}>
                {error.text}
              </UnauthenticatedLayout.ErrorAlert>
            )}
          </UnauthenticatedLayout.Form>
        )
      )}
      <LockTimer onComplete={onComplete} lockedUntil={lockedUntil} />
    </UnauthenticatedLayout.Container>
  );
};
