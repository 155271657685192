import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { EyeIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const InvoiceAction: FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions' });

  return (
    <Tooltip title={t('billingHistory.table.actions.download')} placement="top">
      <a href={url} target="_blank" rel="noreferrer">
        <EyeIcon className={styles.iconShow} />
      </a>
    </Tooltip>
  );
};
