import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import * as yup from 'yup';

import { ROUTES } from '@/router/routes';

import {
  CaptchaError,
  InvalidEmailError,
  InvalidWorkEmailError,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import { useSignUpUseCase } from '@/features/signup';
import {
  ANALYTICS_EVENTS,
  AnalyticsProvider,
  useAnalytics,
} from '@/features/system/analytics';
import {
  CustomerMessagingEventKeys,
  useCustomerMessagingUseCase,
} from '@/features/system/CustomerIO';
import { sentryCaptureException } from '@/features/system/sentry';

import { useFormWithSchema, WorkEmailValidationSchema } from '@/utils/validation';

const SignUpFormSchema = yup.object({
  email: WorkEmailValidationSchema,
});

type SignUpFormType = yup.InferType<typeof SignUpFormSchema>;

export enum SignupError {
  InvalidEmail = 'signup.errors.validEmail',
  InvalidCaptcha = 'signup.errors.validCaptcha',
  UserExists = 'signup.errors.exist',
  InvalidWorkEmail = 'workEmailInvalidContactSales',
}

type UseSignUpViewModel = () => {
  signUpGoogle: () => Promise<void>;
  signUpMicrosoft: () => Promise<void>;
  signUpWithEmail: (e: BaseSyntheticEvent) => void;
  error: SignupError | null;
  form: UseFormReturn<SignUpFormType>;
  captchaError: boolean;
  onClickCaptcha: (value: string) => void;
  loginRoute: string;
  onCaptchaExpired: () => void;
  emailInputError: string;
};

export const useSignUpViewModel: UseSignUpViewModel = () => {
  const signUpUseCases = useSignUpUseCase();
  const cmUseCase = useCustomerMessagingUseCase();
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const { t: validationTranslate } = useTranslation('validation');
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState<SignupError | null>(null);

  const form = useFormWithSchema(SignUpFormSchema, {
    defaultValues: {
      email: '',
    },
  });

  const emailErrorMessage = form.formState.errors.email?.message;
  const emailInputError = emailErrorMessage
    ? emailErrorMessage !== 'workEmailInvalid'
      ? validationTranslate(emailErrorMessage)
      : ''
    : '';

  useEffect(() => {
    let emailFromParams = searchParams.get('email');

    if (emailFromParams) {
      try {
        emailFromParams = Base64.decode(emailFromParams);
        form.setValue('email', emailFromParams, { shouldDirty: true });
      } catch (err) {
        sentryCaptureException('Invalid email in search params');
      }

      searchParams.delete('email');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (emailErrorMessage === 'workEmailInvalid') {
      setError(SignupError.InvalidWorkEmail);
    }
  }, [emailErrorMessage]);

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.VIEW_SIGNUP_PAGE_WEB);
  }, []);

  const checkCaptchaValue = (captcha: string | null): void => {
    if (!captcha) throw new CaptchaError('captcha required');
  };

  const onClickCaptcha = (captcha): void => {
    try {
      setCaptchaValue(captcha);
      checkCaptchaValue(captcha);
      setError(null);
      setCaptchaError(false);
    } catch (error) {
      handleSignUpError(error);
    }
  };

  const onCaptchaExpired = (): void => {
    setCaptchaValue(null);
    setCaptchaError(true);
    setError(SignupError.InvalidCaptcha);
  };

  const handleSignUpError = (error: unknown): void => {
    if (error instanceof InvalidEmailError) {
      trackEvent(ANALYTICS_EVENTS.SIGNUP_ERROR, { type: 'invalid email' });
      return setError(SignupError.InvalidEmail);
    }
    if (error instanceof CaptchaError) {
      trackEvent(ANALYTICS_EVENTS.SIGNUP_ERROR, { type: 'invalid captcha' });
      setError(SignupError.InvalidCaptcha);
      return setCaptchaError(true);
    }
    if (error instanceof InvalidWorkEmailError) {
      trackEvent(ANALYTICS_EVENTS.SIGNUP_ERROR, { type: 'not work email' });
      return setError(SignupError.InvalidWorkEmail);
    }

    if (error instanceof UserAlreadyExistsError) {
      trackEvent(ANALYTICS_EVENTS.SIGNUP_ERROR, { type: 'existing account' });
      return setError(SignupError.UserExists);
    }
  };

  const signUpGoogle = async (): Promise<void> => {
    trackEvent(ANALYTICS_EVENTS.SIGNUP_CLICK_BUTTON_WEB, {
      type: AnalyticsProvider.Google,
    });

    try {
      await signUpUseCases.signUpGoogle();
      cmUseCase.track(CustomerMessagingEventKeys.INSERT_EMAIL);
    } catch (error) {
      return handleSignUpError(error);
    }
  };

  const signUpMicrosoft = async (): Promise<void> => {
    trackEvent(ANALYTICS_EVENTS.SIGNUP_CLICK_BUTTON_WEB, {
      type: AnalyticsProvider.Microsoft,
    });

    try {
      await signUpUseCases.signUpMicrosoft();
      cmUseCase.track(CustomerMessagingEventKeys.INSERT_EMAIL);
    } catch (error) {
      return handleSignUpError(error);
    }
  };

  const signUpWithEmail = (e: BaseSyntheticEvent): void => {
    trackEvent(ANALYTICS_EVENTS.SIGNUP_CLICK_BUTTON_WEB, {
      type: AnalyticsProvider.Email,
    });

    void form.handleSubmit(async ({ email }: SignUpFormType) => {
      try {
        checkCaptchaValue(captchaValue);
        await signUpUseCases.signUpWithEmail(email);
        cmUseCase.track(CustomerMessagingEventKeys.INSERT_EMAIL, {
          email,
        });
      } catch (error) {
        return handleSignUpError(error);
      }
    })(e);
  };

  const email = form.watch('email');
  const encodedEmail = Base64.encode(email);
  const loginRoute = email ? `${ROUTES.LOGIN}?email=${encodedEmail}` : ROUTES.LOGIN;

  return {
    signUpGoogle,
    signUpMicrosoft,
    signUpWithEmail,
    error,
    captchaError,
    onClickCaptcha,
    form,
    onCaptchaExpired,
    loginRoute,
    emailInputError,
  };
};
