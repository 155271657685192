import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { ROUTES } from '@/router/routes';

import { EnrichmentJobStatus } from '@/features/enrichment/domain/types';

import styles from './styles.module.scss';

export const ViewReportCell: FC<{ id: string; status: EnrichmentJobStatus }> = ({
  id,
  status,
}) => {
  const { t } = useTranslation('enrichment');
  const isDisabled = ![
    EnrichmentJobStatus.Completed,
    EnrichmentJobStatus.CompletedPartial,
  ].includes(status);

  return (
    <Link
      to={ROUTES.ENRICHMENT_REPORT.replace(':id', id)}
      className={clsx(styles.Link, {
        [styles.Disabled]: isDisabled,
      })}
    >
      {t('table.row.viewReport')}
    </Link>
  );
};
