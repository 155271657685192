import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { BannerLayout } from '..';

import styles from './styles.module.scss';

export const CreditCardExpiredBanner: FC = () => {
  const { t } = useTranslation('billing', {
    keyPrefix: 'banners.cardExpired',
  });

  return (
    <BannerLayout>
      <Box className={styles.banner}>
        <Trans
          t={t}
          i18nKey="title"
          defaultValue={'Your payment method has expired. <a>Update info now</a>'}
          components={{
            a: <NavLink to={ROUTES.SETTINGS.SUBSCRIPTION} />,
          }}
        />
      </Box>
    </BannerLayout>
  );
};
