import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { usePaymentCard } from '@/features/common/billing';

import { useFormWithSchema } from '@/utils/validation';

import { PaymentDetailsContext } from '../PaymentDetailsContext';

import { usePaymentFormSchema } from './usePaymentFormSchema';

export const PaymentFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    account,
    billingDetails,
    paymentMethod,
    currentWorkspace,
    targetProduct,
    quantity,
  } = useContext(PaymentDetailsContext);

  const shouldValidateCard = paymentMethod == null;

  const { error: cardError } = usePaymentCard({
    shouldValidate: shouldValidateCard,
  });

  const validationSchema = usePaymentFormSchema({
    cardError,
  });

  // eslint-disable-next-line no-var
  var form = useFormWithSchema(validationSchema, {
    defaultValues: {
      paymentMethod,
      billingDetails: {
        name: billingDetails?.name || '',
        email: billingDetails?.email || '',
        address: billingDetails?.address || '',
        city: billingDetails?.city || '',
        state: billingDetails?.state || '',
        postalCode: billingDetails?.postalCode || '',
        country: billingDetails?.country || account.settings.country || '',
        company: billingDetails?.company || '',
        vatId: billingDetails?.vatId || '',
      },
      promotionCode: undefined,
      quantity,
      seats: targetProduct.isPriceFixed
        ? (targetProduct.maxSeats ?? currentWorkspace.billableMembersCount)
        : quantity,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  // sync card error with form
  useEffect(() => {
    const isSubmitted = form.formState.isSubmitted;
    if (isSubmitted) {
      form.trigger('paymentMethod.card');
    }
  }, [cardError, form]);

  return <FormProvider {...form}>{children}</FormProvider>;
};
