import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

type Props = {
  title: string;
};

export const ChartCard: React.FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
    <Box className={styles.card}>
      <Typography fontSize={19}>{title}</Typography>
      {children}
    </Box>
  );
};
