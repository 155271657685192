import { Container } from 'inversify';

import { LEADER_ELECTION_TYPES } from '@/ioc/types';

import { ILeaderElectionRepository, LeaderElectionRepository } from '../data';
import {
  ILeaderElectionUseCase,
  LeaderElectionUseCase,
} from '../domain/LeaderElectionUseCase';

export const bindLeaderElectionModule = (container: Container): void => {
  container
    .bind<ILeaderElectionRepository>(LEADER_ELECTION_TYPES.LeaderElectionRepository)
    .to(LeaderElectionRepository)
    .inSingletonScope();

  container
    .bind<ILeaderElectionUseCase>(LEADER_ELECTION_TYPES.LeaderElectionUseCase)
    .to(LeaderElectionUseCase);
};
