import { useState } from 'react';

import { IAccountEntity, useAccountUseCase } from '@/features/common/account';

import { useObservableResult } from '@/utils/rx';

type Return = {
  account: Nullable<IAccountEntity>;
  isLoading: boolean;
};

export const useAccount = (): Return => {
  const accountUseCase = useAccountUseCase();

  const [account$] = useState(() => accountUseCase.getAccount());

  const { data: account, isLoading } = useObservableResult(account$);

  return {
    account,
    isLoading,
  };
};
