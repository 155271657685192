import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { clone } from 'ramda';

import { useAppLogger } from '@/features/system/logger';

import { useObservableResult } from '@/utils/rx';

import { ProspectTaskStatus } from '../../data';
import { IProspectTaskProgressEntity } from '../../domain';
import { useProspectTaskUseCase } from '../hooks';

export interface IProspectTaskProgressContext {
  taskList: IProspectTaskProgressEntity[];
  hasPendingTask(contactListId?: string): boolean;
  deleteAll(): void;
  deleteBulk(ids: string[]): void;
  retryMany(ids: string[]): Promise<void>;
}

export const ProspectTaskProgressContext = createContext<IProspectTaskProgressContext>({
  taskList: [],
  hasPendingTask: (): boolean => false,
  deleteAll: (): void => {
    return;
  },
  deleteBulk: (): void => {
    return;
  },
  retryMany: async (): Promise<void> => {
    return;
  },
});

export const ProspectTaskProgressProvider: FC<PropsWithChildren> = ({ children }) => {
  const prospectTaskUseCase = useProspectTaskUseCase();
  const [getProspectTaskProgressEntity$] = useState(() =>
    prospectTaskUseCase.getProspectTaskProgress(),
  );
  const [getCleanupIndicator$] = useState(() =>
    prospectTaskUseCase.getCleanupIndicator(),
  );
  const { data } = useObservableResult(getProspectTaskProgressEntity$);
  const { data: cleanupIndicator } = useObservableResult(getCleanupIndicator$);
  const logger = useAppLogger();

  const [taskList, setTaskList] = useState<IProspectTaskProgressEntity[]>([]);

  const contextValue = useMemo<IProspectTaskProgressContext>(() => {
    return {
      taskList,
      hasPendingTask: (contactListId): boolean => {
        if (contactListId)
          return (
            taskList.find((task) => task.contactList.uuid === contactListId)?.status ===
            ProspectTaskStatus.Pending
          );

        return taskList.some((task) => task.status === ProspectTaskStatus.Pending);
      },
      deleteAll: (): void => {
        setTaskList([]);
        prospectTaskUseCase.sendCleanupEvent();
      },
      deleteBulk: (ids: string[]): void => {
        const idsMapToDelete = ids.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {});

        setTaskList((prev) => {
          return prev.filter((item) => !idsMapToDelete[item.uuid]);
        });
      },
      retryMany: async (ids: string[]): Promise<void> => {
        try {
          for (const id of ids) {
            await prospectTaskUseCase.retry(id);
          }
        } catch (error) {
          logger.error(error);
        }
      },
    } satisfies IProspectTaskProgressContext;
  }, [taskList]);

  useEffect(() => {
    if (!data) return;

    setTaskList((prev) => {
      const existedTaskIndex = prev.findIndex((task) => task.uuid === data.uuid);

      if (existedTaskIndex !== -1) {
        const prevCopy = clone(prev);
        prevCopy.splice(existedTaskIndex, 1, data);

        return prevCopy;
      }

      return [data, ...taskList];
    });
  }, [data]);

  useEffect(() => {
    setTaskList([]);
  }, [cleanupIndicator]);

  return (
    <ProspectTaskProgressContext.Provider value={contextValue}>
      {children}
    </ProspectTaskProgressContext.Provider>
  );
};
