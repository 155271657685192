import type { Components, Theme } from '@mui/material';

export const MuiPopover: Components<Theme>['MuiPopover'] = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  styleOverrides: {
    paper: {
      boxShadow: '10px 10px 40px 0 #d8dfea',
      marginTop: '6px',
      borderRadius: '6px',
      backgroundColor: '#fff',
    },
    // @ts-ignore
    list: {
      padding: '10px',
    },
  },
};
