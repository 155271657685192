import { FC } from 'react';

import { useAggregatedCreditsExist } from '@/features/common/workspace';

import { CreditBasedView } from './components/CreditBasedView';
import { UnlimitedView } from './components/UnlimitedView';

export const UserMenuCreditUsage: FC = () => {
  const { data } = useAggregatedCreditsExist('bulk');

  switch (data) {
    case true:
      return <UnlimitedView />;
    case false:
      return <CreditBasedView />;
    default:
      return null;
  }
};
