import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import { useInjection } from '@/ioc';
import { PLANS_TYPES } from '@/ioc/types';

import { BillingCycle, NotLegacyPlanType, PlanType } from '@/features/common/billing';

import {
  AnalyticsIcon,
  HelpCenterIcon,
  HubIcon,
  KeyIcon,
  PageInfoIcon,
  PersonIcon,
  VerifiedUserIcon,
} from '@/components/Icons';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { GroupedProductMetadata } from '../../domain';
import type { IPlansUseCase } from '../../domain/abstractions/IPlansUseCase';
import {
  BillingCycleDependent,
  LimitedByQuantity,
  PlanFeatureCategory,
  PlanFeatureLevelSupport,
  type PlanFeaturesMetadata,
  Unlimited,
} from '../types';
import {
  billingCycleDependent,
  comingSoon,
  fullySupported,
  limittedByQuantity,
  unlimitted,
  unsupported,
} from '../utils';

type NewPlansMetadata = {
  plansMetadata: GroupedProductMetadata;
  plansFeatureMetadata: PlanFeaturesMetadata;
};

type UseNewPlansMetadata = (params: {
  seats: number;
  billingCycle: BillingCycle;
}) => ObservableResult<NewPlansMetadata>;
type FeaturesSupport = Record<NotLegacyPlanType, PlanFeatureLevelSupport>;

function getFullCredits(
  planGroups: GroupedProductMetadata,
  planType: PlanType,
): { [key in BillingCycle.Monthly | BillingCycle.Yearly]: number | 'unlimited' } {
  return {
    [BillingCycle.Monthly]: planGroups[planType].variants.monthly.fullCredits,
    [BillingCycle.Yearly]: planGroups[planType].variants.annually.fullCredits,
  };
}

function mapCredit(limit: number | 'unlimited'): LimitedByQuantity | Unlimited {
  return limit === 'unlimited' ? unlimitted() : limittedByQuantity({ limit });
}

function mapFullCredits(
  planGroups: GroupedProductMetadata,
  planType: PlanType,
): BillingCycleDependent {
  const creditsByCycle = getFullCredits(planGroups, planType);

  return billingCycleDependent({
    [BillingCycle.Monthly]: mapCredit(creditsByCycle[BillingCycle.Monthly]),
    [BillingCycle.Yearly]: mapCredit(creditsByCycle[BillingCycle.Yearly]),
  });
}

function mapBulkFeature(planGroups: GroupedProductMetadata): FeaturesSupport {
  const getSupportLevel = (planType: PlanType): PlanFeatureLevelSupport => {
    return planGroups[planType].features.includes('bulk_save')
      ? fullySupported()
      : unsupported();
  };

  return {
    [PlanType.Free]: getSupportLevel(PlanType.Free),
    [PlanType.Pro]: getSupportLevel(PlanType.Pro),
    [PlanType.Unlimited]: getSupportLevel(PlanType.Unlimited),
    [PlanType.Expand]: fullySupported(),
  };
}

function mapEnrichCSVFeature(planGroups: GroupedProductMetadata): FeaturesSupport {
  const getSupportLevel = (planType: PlanType): PlanFeatureLevelSupport => {
    return planGroups[planType].features.includes('csv_enrichment')
      ? fullySupported()
      : unsupported();
  };

  return {
    [PlanType.Free]: getSupportLevel(PlanType.Free),
    [PlanType.Pro]: getSupportLevel(PlanType.Pro),
    [PlanType.Unlimited]: getSupportLevel(PlanType.Unlimited),
    [PlanType.Expand]: fullySupported(),
  };
}

function mapDataExportFeature(planGroups: GroupedProductMetadata): FeaturesSupport {
  const getSupportLevel = (planType: PlanType): PlanFeatureLevelSupport => {
    return planGroups[planType].features.includes('data_export')
      ? fullySupported()
      : unsupported();
  };

  return {
    [PlanType.Free]: getSupportLevel(PlanType.Free),
    [PlanType.Pro]: getSupportLevel(PlanType.Pro),
    [PlanType.Unlimited]: getSupportLevel(PlanType.Unlimited),
    [PlanType.Expand]: fullySupported(),
  };
}

function mapCrmIntegrationFeature(planGroups: GroupedProductMetadata): FeaturesSupport {
  const getSupportLevel = (planType: PlanType): PlanFeatureLevelSupport => {
    return planGroups[planType].features.includes('crm_integration')
      ? fullySupported()
      : unsupported();
  };

  return {
    [PlanType.Free]: getSupportLevel(PlanType.Free),
    [PlanType.Pro]: getSupportLevel(PlanType.Pro),
    [PlanType.Unlimited]: getSupportLevel(PlanType.Unlimited),
    [PlanType.Expand]: fullySupported(),
  };
}

export const useNewPlansMetadata: UseNewPlansMetadata = ({ seats, billingCycle }) => {
  const { t } = useTranslation('plans');
  const plansUseCase = useInjection<IPlansUseCase>(PLANS_TYPES.PlansUseCase);

  function getFeatureCategoryTitle(category: PlanFeatureCategory): string {
    return t(`features.categories.${category}`);
  }

  function getFeatureTitle(feature: string): string {
    return t(`features.${feature}.title`);
  }

  function getFeatureHint(feature: string): string {
    return t(`features.${feature}.hint`);
  }

  return useObservableResult(
    () =>
      plansUseCase.getGroupedProductMetadata({ seats, billingCycle }).pipe(
        map((productsMetadata) => {
          return {
            plansMetadata: productsMetadata,
            plansFeatureMetadata: {
              [PlanFeatureCategory.General]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.General),
                icon: <PageInfoIcon />,
                features: [
                  {
                    title: getFeatureTitle('amountOfCredits'),
                    hint: getFeatureHint('amountOfCredits'),
                    supportByPlan: {
                      [PlanType.Free]: mapFullCredits(productsMetadata, PlanType.Free),
                      [PlanType.Pro]: mapFullCredits(productsMetadata, PlanType.Pro),
                      [PlanType.Unlimited]: mapFullCredits(
                        productsMetadata,
                        PlanType.Unlimited,
                      ),
                      [PlanType.Expand]: mapFullCredits(
                        productsMetadata,
                        PlanType.Expand,
                      ),
                    },
                  },
                  {
                    title: getFeatureTitle('exportCredits'),
                    hint: getFeatureHint('exportCredits'),
                    supportByPlan: {
                      [PlanType.Free]: unlimitted(),
                      [PlanType.Pro]: unlimitted(),
                      [PlanType.Unlimited]: unlimitted(),
                      [PlanType.Expand]: unlimitted(),
                    },
                  },
                  {
                    title: getFeatureTitle('linkedinExt'),
                    hint: getFeatureHint('linkedinExt'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('universalExt'),
                    hint: getFeatureHint('universalExt'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('dashboard'),
                    hint: getFeatureHint('dashboard'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('topUpCredits'),
                    hint: getFeatureHint('topUpCredits'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: unlimitted(),
                      [PlanType.Expand]: unlimitted(),
                    },
                  },
                  {
                    title: getFeatureTitle('bulkSave'),
                    hint: getFeatureHint('bulkSave'),
                    supportByPlan: mapBulkFeature(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('enrichCsv'),
                    hint: getFeatureHint('enrichCsv'),
                    supportByPlan: mapEnrichCSVFeature(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.ContactManagement]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.ContactManagement),
                icon: <PersonIcon />,
                features: [
                  {
                    title: getFeatureTitle('listManagement'),
                    hint: getFeatureHint('listManagement'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('tagManagement'),
                    hint: getFeatureHint('tagManagement'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('exportToCsv'),
                    hint: getFeatureHint('exportToCsv'),
                    supportByPlan: mapDataExportFeature(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('saveToCrm'),
                    hint: getFeatureHint('saveToCrm'),
                    supportByPlan: mapCrmIntegrationFeature(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('saveToSalesEngagementTool'),
                    hint: getFeatureHint('saveToSalesEngagementTool'),
                    supportByPlan: mapCrmIntegrationFeature(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.CrmIntegrations]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.CrmIntegrations),
                icon: <HubIcon />,
                features: [
                  {
                    title: getFeatureTitle('salesforce'),
                    hint: getFeatureHint('salesforce'),
                    supportByPlan: mapCrmIntegrationFeature(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('hubspot'),
                    hint: getFeatureHint('hubspot'),
                    supportByPlan: mapCrmIntegrationFeature(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('zohoCrm'),
                    hint: getFeatureHint('zohoCrm'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('pipedrive'),
                    hint: getFeatureHint('pipedrive'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('zapier'),
                    hint: getFeatureHint('zapier'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('outreach'),
                    hint: getFeatureHint('outreach'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('salesloft'),
                    hint: getFeatureHint('salesloft'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('customIntegration'),
                    hint: getFeatureHint('customIntegration'),
                    supportByPlan: mapCrmIntegrationFeature(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.Analytics]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Analytics),
                icon: <AnalyticsIcon />,
                features: [
                  {
                    title: getFeatureTitle('usageAnalytics'),
                    hint: getFeatureHint('usageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('teamUsageAnalytics'),
                    hint: getFeatureHint('teamUsageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Compliance]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Compliance),
                icon: <VerifiedUserIcon />,
                features: [
                  {
                    title: getFeatureTitle('gdprCompliant'),
                    hint: getFeatureHint('gdprCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('ccpaCompliant'),
                    hint: getFeatureHint('ccpaCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.SSO]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.SSO),
                icon: <KeyIcon />,
                features: [
                  {
                    title: getFeatureTitle('google'),
                    hint: getFeatureHint('google'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('microsoft365'),
                    hint: getFeatureHint('microsoft365'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('okta'),
                    hint: getFeatureHint('okta'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('saml'),
                    hint: getFeatureHint('saml'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Support]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Support),
                icon: <HelpCenterIcon />,
                features: [
                  {
                    title: getFeatureTitle('helpCenter'),
                    hint: getFeatureHint('helpCenter'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('emailSupport'),
                    hint: getFeatureHint('emailSupport'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('liveChat'),
                    hint: getFeatureHint('liveChat'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('onboarding'),
                    hint: getFeatureHint('onboarding'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('dedicatedSupport'),
                    hint: getFeatureHint('dedicatedSupport'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
            },
          } satisfies NewPlansMetadata;
        }),
      ),
    {
      deps: [seats, billingCycle],
    },
  );
};
