import { injectable } from 'inversify';
import { MangoQuery } from 'rxdb/dist/types/types/rx-query';
import { Observable } from 'rxjs';

import { IDashboardChartDao } from '@/features/dashboard/features/chart/data/abstractions/IDashboardChartDao';
import { ChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/ChartDataDC';
import { QueryChartDataDC } from '@/features/dashboard/features/chart/data/dataContracts/QueryChartDataDC';
import { CollectionName, DbCollection } from '@/features/system/db';

@injectable()
export class DashboardChartDao
  extends DbCollection<ChartDataDC, 'uuid'>
  implements IDashboardChartDao
{
  constructor() {
    super({ collectionName: CollectionName.DashboardChart, idKey: 'uuid' });
  }

  getData(params: QueryChartDataDC): Observable<ChartDataDC[]> {
    const query: MangoQuery<ChartDataDC> = {
      selector: {
        member: params.member || undefined,
        at: {
          $gte: params.from_dt,
          $lte: params.to_dt,
        },
      },
      sort: [
        {
          at: 'asc',
        },
      ],
    };

    return this.findAll(query);
  }
}
