import { PlanType } from '@/features/common/billing/domain/types'; // circular dependency

import { UserRole as UR } from './UserRole';

export const enum ACCESS_PERMISSIONS {
  CAN_MANAGE_TEAM_MEMBERS = 'CAN_MANAGE_TEAM_MEMBERS',
  CAN_MANAGE_ALL_TAGS = 'CAN_MANAGE_ALL_TAGS',
  CAN_ACCESS_BILLING = 'CAN_ACCESS_BILLING',
  CAN_USE_EXTENSION = 'CAN_USE_EXTENSION',
  CAN_CHANGE_CONTACTS_OWNER = 'CAN_CHANGE_CONTACTS_OWNER',
  CAN_ACCESS_ALL_CONTACTS = 'CAN_ACCESS_ALL_CONTACTS',
  CAN_EDIT_DELETE_CONTACT_LIST = 'CAN_EDIT_DELETE_CONTACT_LIST',
  CAN_MANAGE_INTEGRATIONS = 'CAN_MANAGE_INTEGRATIONS', // for integration's configuration, lol.
  // not_used: can_add_tags
  // not_used: can_access_all_contacts
  // not_used: can_edit_own_tags
  CAN_VIEW_PROSPECT_TASK_PROGRESS = 'CAN_VIEW_PROSPECT_TASK_PROGRESS',
  CAN_ACCESS_REFFERAL = 'CAN_ACCESS_REFFERAL',
}

export const PERMISSION_BY_ROLES = {
  [ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS]: [UR.Owner, UR.Admin],
  [ACCESS_PERMISSIONS.CAN_MANAGE_ALL_TAGS]: [UR.Owner, UR.Admin, UR.Manager],
  [ACCESS_PERMISSIONS.CAN_ACCESS_BILLING]: [UR.Owner, UR.Admin],
  [ACCESS_PERMISSIONS.CAN_MANAGE_INTEGRATIONS]: [UR.Owner, UR.Admin],
  [ACCESS_PERMISSIONS.CAN_USE_EXTENSION]: [UR.Owner, UR.Admin, UR.Member],
  [ACCESS_PERMISSIONS.CAN_CHANGE_CONTACTS_OWNER]: [UR.Owner, UR.Admin, UR.Manager],
  [ACCESS_PERMISSIONS.CAN_EDIT_DELETE_CONTACT_LIST]: [UR.Owner, UR.Admin, UR.Manager],
  [ACCESS_PERMISSIONS.CAN_VIEW_PROSPECT_TASK_PROGRESS]: [UR.Owner, UR.Admin, UR.Manager],
};

export const PERMISSION_BY_PLAN_TYPE = {
  [ACCESS_PERMISSIONS.CAN_ACCESS_REFFERAL]: [PlanType.Free],
};

//TODO: UNITE theese 2 diff objects into 1.
// ACCESS_PERMISSIONS = {
//   CAN_MANAGE_TEAM_MEMBERS: [UR.Owner, UR.Admin]
// }
