import { FC } from 'react';
import type { Components, Theme } from '@mui/material';

import ArrowFirst from '@/assets/icons/arrowFirst.svg?react';
import ArrowLast from '@/assets/icons/arrowLast.svg?react';
import ArrowLeft from '@/assets/icons/arrowLeft.svg?react';
import ArrowRight from '@/assets/icons/arrowRight.svg?react';

const baseIconStyle = {
  color: 'black',
  width: '10px',
  height: '10px',
};

const StyledArrowLeft: FC = (props) => <ArrowLeft style={baseIconStyle} {...props} />;
const StyledArrowRight: FC = (props) => <ArrowRight style={baseIconStyle} {...props} />;
const StyledArrowFirst: FC = (props) => <ArrowFirst style={baseIconStyle} {...props} />;
const StyledArrowLast: FC = (props) => <ArrowLast style={baseIconStyle} {...props} />;

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {
    slots: {
      previous: StyledArrowLeft,
      next: StyledArrowRight,
      first: StyledArrowFirst,
      last: StyledArrowLast,
    },
  },
  styleOverrides: {
    root: ({ theme }) => {
      return {
        fontSize: '13px',
        lineHeight: '27px',
        minWidth: '27px',
        height: '27px',
        '&.Mui-selected': {
          backgroundColor: theme.palette.info?.disabled,
          ':hover': {
            // @ts-ignore
            backgroundColor: theme.palette.info?.border,
          },
        },
        '&.Mui-disabled': {
          opacity: 0.3,
          color: theme.palette.info?.main,
        },
        '&.MuiPaginationItem-firstLast': {
          margin: 0,
        },
      };
    },
  },
};
