import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useContactUseCase } from '@/features/common/contact';

type SendReportType = (
  arg: { contactId: string; value; entityType },
  successCallback?: () => void,
  errorCallback?: () => void,
) => void;

export const useContactWrongInfoReport = (): {
  sendReport: SendReportType;
} => {
  const contactUseCase = useContactUseCase();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('contactsTable');

  const sendReport: SendReportType = async (
    { contactId, value, entityType },
    successCallback,
    errorCallback,
  ) => {
    try {
      await contactUseCase.wrongInfoReport({
        value,
        entity_type: entityType,
        contact_uuid: contactId,
      });
      successCallback && successCallback();
      enqueueSnackbar(t('contactsInfoCell.wrongInfoToast.title'), {
        variant: 'success',
        description: t('contactsInfoCell.wrongInfoToast.description'),
        autoHideDuration: 3000,
      });
    } catch (error) {
      errorCallback && errorCallback();
    }
  };

  return {
    sendReport,
  };
};
