import { FC, ReactElement } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { CustomSelectV2 } from '../CustomSelectV2';

import { useCountrySelectViewModel } from './useCountrySelectViewModel';

import styles from './styles.module.scss';

type CountrySelectProps = {
  value: string;
  placeholder?: string;
  onChange(country: string): void;
};

export const CountrySelect: FC<CountrySelectProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const { t } = useTranslation('common');
  const { options } = useCountrySelectViewModel();

  return (
    <CustomSelectV2
      value={value}
      onChange={onChange}
      options={options}
      size="large"
      searchable={true}
      InputProps={{
        render({ inputData, size, disabled, required, error }): ReactElement {
          return (
            <CustomSelectV2.InputContainer
              size={size}
              disabled={disabled}
              required={required}
              error={error}
            >
              <Box component="span" className={styles.inputContainer}>
                {value !== null ? (
                  <Box className={styles.inputContainerValue}>
                    <ReactCountryFlag countryCode={inputData.value} svg />
                    {inputData.label}
                  </Box>
                ) : (
                  placeholder || t('countrySelect.placeholder')
                )}
              </Box>
              <input type="hidden" value={inputData.value} />
            </CustomSelectV2.InputContainer>
          );
        },
      }}
      MenuProps={{
        SearchProps: {
          placeholder: t('countrySelect.search.placeholder'),
        },
        ListProps: {
          className: styles.menuList,
          renderItem: ({ option }): ReactElement => {
            return (
              <Box className={styles.item}>
                <ReactCountryFlag
                  className={styles.itemImg}
                  countryCode={option.value}
                  svg
                />
                <Box className={styles.itemText} component="span">
                  {option.label}
                </Box>
              </Box>
            );
          },
        },
      }}
    />
  );
};
