import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListRepository } from '../domain/abstractions/repositories/IContactListRepository';
import { IContactListEntity } from '../domain/entities/ContactListEntity';
import { ContactListSortSchema } from '../domain/types';

import { IContactListDao } from './db/dao/ContactListDao';
import { mapContactListDcToEntity } from './mappers';

@injectable()
export default class ContactListRepository implements IContactListRepository {
  @inject(CONTACT_LIST_TYPES.ContactListDao)
  private contactListDao: IContactListDao;

  public getDefaultContactList(): Observable<IContactListEntity> {
    return this.contactListDao
      .getDefaultContactList()
      .pipe(map(mapContactListDcToEntity));
  }

  public getContactListByQuery(
    payload: {
      nameReg?: string;
      created_by?: string;
      sortSchema?: ContactListSortSchema;
    } = {},
  ): Observable<IContactListEntity[]> {
    return this.contactListDao
      .getContactListByQuery(payload)
      .pipe(map((list) => list.map(mapContactListDcToEntity)));
  }

  public async createContactList(payload: {
    name: string;
    created_by: string;
  }): Promise<IContactListEntity> {
    const result = await this.contactListDao.upsert({
      name: payload.name,
      created_by: payload.created_by,
      contacts_amount: 0,
    });
    return mapContactListDcToEntity(result);
  }

  public async updateContactList(payload: {
    uuid: string;
    name: string;
  }): Promise<IContactListEntity> {
    const result = await this.contactListDao.updateOne(payload.uuid, {
      name: payload.name,
    });
    return mapContactListDcToEntity(result);
  }

  public async deleteContactList(uuid: string): Promise<boolean> {
    const result = await this.contactListDao.removeOne(uuid);
    return !!result;
  }

  public getContactListById(id: string): Observable<Nullable<IContactListEntity>> {
    return this.contactListDao.findById(id).pipe(
      map((value) => {
        if (!value) return undefined;

        return mapContactListDcToEntity(value);
      }),
    );
  }
}
