import { Dayjs } from 'dayjs';

import { Aggregator } from '../abstractions/Aggregation';
import { ChartDataEntity } from '../entities/ChartDataEntity';

export class MonthlyAggregation extends Aggregator {
  private readonly diff: number;
  private readonly monthStart: Dayjs;

  constructor(dataOriginal: ChartDataEntity[], from: Dayjs, to: Dayjs) {
    super(dataOriginal, from, to);

    this.monthStart = this.to.startOf('month');
    this.diff = this.getDiff();
    this.populate(this.diff);
    this.result = this.groupDataByTimestamps(this.dateObjs, this.data);
  }

  private populate(diff: number): void {
    for (let i = 1; i <= diff; i++) {
      this.dateObjs.push(this.monthStart.subtract(i, 'months').endOf('month').unix());
    }
  }

  private getDiff(): number {
    const yearDiff = this.to.get('year') - this.from.get('year');
    return this.to.get('month') + 12 * yearDiff - this.from.get('month');
  }
}
