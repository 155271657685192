import { RefObject, useEffect, useRef } from 'react';

export function useCollapsable<T extends HTMLElement>(
  isOpened: boolean,
): { ref: RefObject<T> } {
  const collapsableRef = useRef<T>(null);

  useEffect(() => {
    const collapsable = collapsableRef.current;

    if (!collapsable) {
      return;
    }

    if (isOpened) {
      collapsable.style.maxHeight = `${collapsable.scrollHeight}px`;

      return () => {
        collapsable.style.maxHeight = `0px`;
      };
    }
  }, [isOpened]);

  return { ref: collapsableRef };
}
