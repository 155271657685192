import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';

import { ACCOUNT_TYPES, AUTH_TYPES } from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';
import { IAuthRepository, UserAlreadyExistsError } from '@/features/common/auth';
import { IAccountSettingsUseCase } from '@/features/settings/features/account';

import { EmailAlreadyExistError } from './errors/EmailAlreadyExistError';
import { NameAlreadyExistError } from './errors/NameAlreadyExistError';

@injectable()
export default class AccountSettingsUseCase implements IAccountSettingsUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  changeEmail = async (email: string): Promise<void> => {
    const account = await firstValueFrom(this.accountRepository.getAccount());
    if (account?.email === email) {
      throw new EmailAlreadyExistError();
    }

    const isExist = await this.authRepository.checkUserExists(email);
    if (isExist) {
      throw new UserAlreadyExistsError();
    }

    await this.accountRepository.updateAccount({ email });
  };

  changePassword = async (password: string, currentPassword?: string): Promise<void> => {
    await this.authRepository.updatePassword(password, currentPassword);
  };

  changeName = async (name: string): Promise<void> => {
    const account = await firstValueFrom(this.accountRepository.getAccount());
    if (account?.fullName === name) {
      throw new NameAlreadyExistError();
    }

    await this.accountRepository.updateAccount({ fullName: name });
  };

  deleteAccount = async (): Promise<void> => {
    this.accountRepository.deleteAccount(); // HACK: we have problems with proper database removing and replication sropage that cause errors
    return this.authRepository.signOut();
  };
}
