import { inject, injectable } from 'inversify';
import { map } from 'rxjs';

import { SYNC_TYPES } from '@/ioc/types';

import { CollectionName, DashboardChartDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { IBaseSyncRepository } from '@/features/system/sync';

@injectable()
export class WorkspaceStatReplicationService extends ReplicationService<DashboardChartDocument> {
  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.DashboardChart,
      pullStreamFactory: () => {
        return this.syncRepository
          .getWorkspaceStatEvents()
          .pipe(map(mapBaseSyncEntity<DashboardChartDocument>));
      },
    });
  }
}
