export function parseFinancialString(value: string | null): number {
  if (!value) {
    return 0;
  }

  const amountStr = value.replace('US$', '').trim();

  // Define a map to hold suffixes and their corresponding multiplier values
  const suffixMultipliers: { [key: string]: number } = {
    K: 1_000,
    M: 1_000_000,
    B: 1_000_000_000,
    T: 1_000_000_000_000,
  };

  // Extract the numerical part and the suffix
  let suffix = '';
  let amount = Number(amountStr);

  if (Number.isNaN(amount)) {
    suffix = amountStr.slice(-1);
    amount = Number(amountStr.slice(0, -1));
  }

  // Multiply the numerical part by the appropriate value if there's a suffix
  if (suffix && suffixMultipliers[suffix]) {
    amount *= suffixMultipliers[suffix];
  }

  return amount;
}
