import { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { ROUTES } from '@/router/routes';

import { useContactListById } from '@/features/common/contactList';

import styles from './styles.module.scss';

type Props = GridCellParams & {
  value?: string;
};

export const ListLinkCell: FC<Props> = ({ row }) => {
  const navigate = useNavigate();
  const { data, isLoading } = useContactListById(row.contactListId);

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(ROUTES.CONTACTS_INNER.replace(':id', row.contactListId));
  };

  if (!row.contactListId || isLoading) {
    return null;
  }

  return (
    <Button variant="text" onClick={handleClick} className={styles.button}>
      {data?.name || ''}
    </Button>
  );
};
