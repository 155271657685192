import * as React from "react";
const SvgConnectPowerleadIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 80, height: 80, viewBox: "0 0 80 80", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", { id: "3kzd35zp6a", x: 0, y: 0, width: 80, height: 80, filterUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("feOffset", { dy: 3 }), /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 3, result: "blur" }), /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0.161 }), /* @__PURE__ */ React.createElement("feComposite", { operator: "in", in2: "blur" }), /* @__PURE__ */ React.createElement("feComposite", { in: "SourceGraphic" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 18073", transform: "translate(-66 -278)" }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 27", width: 52, height: 52, rx: 8, transform: "translate(80 289)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 17", d: "M20.965 2.631A6.731 6.731 0 0 0 15.318 0H2.991L.916 10.267l2.663.059L.845 25.383v.013L.02 29.754l.052.01-.072.4L8.714 7.716h-2.2l.446-2.77h3.355v.01h4.044a2.683 2.683 0 0 1 2.257 1 3.089 3.089 0 0 1 .248 2.81 6.723 6.723 0 0 1-5.815 4.418h-1.22L7.9 18.138h2.178c4.852 0 10.072-3.932 11.638-8.764a7.446 7.446 0 0 0-.756-6.742", transform: "translate(95 301.521)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  filter: "url(#3kzd35zp6a)"
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Rectangle 17590", transform: "translate(75 284)", style: {
  stroke: "#f5f5fa",
  fill: "none"
} }, /* @__PURE__ */ React.createElement("rect", { width: 62, height: 62, rx: 13, style: {
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("rect", { x: 0.5, y: 0.5, width: 61, height: 61, rx: 12.5, style: {
  fill: "none"
} })))));
export default SvgConnectPowerleadIcon;
