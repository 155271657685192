import React from 'react';
import { Stack } from '@mui/material';

import { DashboardHeader } from './ui/DashboardHeader';
import { DashboardTable } from './ui/DashboardTable';
import { PlanCard } from './ui/PlanCard';
import { TeamMembersCard } from './ui/TeamMembersCard';
import { AnalyticsCard } from './features';
import { useDashboardViewModel } from './useDashboardViewModel';

import styles from './styles.module.scss';

const Dashboard: React.FC = () => {
  useDashboardViewModel();

  return (
    <>
      <Stack className={styles.rootContainer}>
        <DashboardHeader />
        <Stack direction="row" gap="15px" className={styles.cardsContainer}>
          <Stack gap="15px" className={styles.leftColumn}>
            <TeamMembersCard />
            <PlanCard />
          </Stack>
          <AnalyticsCard />
        </Stack>
        <DashboardTable />
      </Stack>
    </>
  );
};

export default Dashboard;
