import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { IEnrichmentFileUploadUseCase, IEnrichmentRepository } from './abstraction';
import {
  EnrichmentFieldCombinationRequirementError,
  EnrichmentFieldValidationError,
  EnrichmentUploadFileSizeError,
} from './errors';
import {
  EnrichmentConfigForm,
  EnrichmentFileHeaderField,
  EnrichmentFileUploadResponse,
} from './types';

@injectable()
export class EnrichmentFileUploadUseCase implements IEnrichmentFileUploadUseCase {
  @inject(ENRICHMENT_TYPES.EnrichmentRepository)
  private readonly enrichmentRepository: IEnrichmentRepository;

  private async uploadGuardByFileSize(file: File): Promise<void> {
    const MAX_SIZE = 10; // in MB

    if (file.size / 1_000_000 > MAX_SIZE) {
      throw new EnrichmentUploadFileSizeError();
    }
  }

  private trimStartEnd(str: string): string {
    return str.replace(/^[, ]+|[, ]+$/g, '');
  }

  public async uploadFile(
    body: { file: File },
    options?: Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal'>,
  ): Promise<EnrichmentFileUploadResponse> {
    await this.uploadGuardByFileSize(body.file);

    const formData = new FormData();
    formData.append('file', body.file);

    return this.enrichmentRepository.uploadFile(formData, options);
  }

  public validateRequiredHeaderFieldsCombination(
    headerFields: Partial<EnrichmentConfigForm['headers_map']>,
  ): {
    isValid: boolean;
    error?: Error;
  } {
    const requiredCombinations = [
      [EnrichmentFileHeaderField.Email],
      [EnrichmentFileHeaderField.LinkedinUrl],
      [EnrichmentFileHeaderField.FullName, EnrichmentFileHeaderField.Website],
      [
        EnrichmentFileHeaderField.FirstName,
        EnrichmentFileHeaderField.LastName,
        EnrichmentFileHeaderField.Website,
      ],
      [EnrichmentFileHeaderField.FullName, EnrichmentFileHeaderField.CompanyName],
      [
        EnrichmentFileHeaderField.FirstName,
        EnrichmentFileHeaderField.LastName,
        EnrichmentFileHeaderField.CompanyName,
      ],
    ];

    for (const combination of requiredCombinations) {
      if (combination.every((field) => Boolean(headerFields[field]))) {
        return {
          isValid: true,
        };
      }
    }

    return {
      isValid: false,
      error: new EnrichmentFieldCombinationRequirementError(),
    };
  }

  public validateHeaderField(options: {
    headerField: EnrichmentFileHeaderField;
    exampleData: string;
  }): { isValid: boolean; error?: Error } {
    const regMapByHeaderField = {
      [EnrichmentFileHeaderField.FirstName]:
        /^([\p{L}\p{M}\s'-]+(?:,\s*[\p{L}\p{M}\s'-]+)*)$/u,
      [EnrichmentFileHeaderField.LastName]:
        /^([\p{L}\p{M}\s'-]+(?:,\s*[\p{L}\p{M}\s'-]+)*)$/u,
      [EnrichmentFileHeaderField.FullName]:
        /^([\p{L}\p{M}]+\s[\p{L}\p{M}]+(?:,\s*[\p{L}\p{M}]+\s[\p{L}\p{M}]+)*)$/u,
      [EnrichmentFileHeaderField.Email]:
        /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,},\s*)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
      [EnrichmentFileHeaderField.Website]:
        /^(https?:\/\/[^\s,]+)(,\s*https?:\/\/[^\s,]+)*$/,
      [EnrichmentFileHeaderField.LinkedinUrl]:
        /^(https?:\/\/(www\.)?linkedin\.com\/[^\s,]+)(,\s*https?:\/\/(www\.)?linkedin\.com\/[^\s,]+)*$/,
      [EnrichmentFileHeaderField.CompanyName]:
        /^([\p{L}\p{M}\p{N}\p{P}\p{S}\p{Z}]+(?:[\p{L}\p{M}\p{N}\p{P}\p{S}\p{Z}\s]*[,;:]\s*[\p{L}\p{M}\p{N}\p{P}\p{S}\p{Z}]+)*)$/u,
    };

    const exampleData = this.trimStartEnd(options.exampleData);

    if (exampleData.length === 0) {
      return {
        isValid: true,
      };
    }

    if (
      !regMapByHeaderField[options.headerField].test(exampleData) &&
      !!exampleData.trim()
    ) {
      return {
        isValid: false,
        error: new EnrichmentFieldValidationError(),
      };
    }

    return {
      isValid: true,
    };
  }

  public startJob(jobId: string, configForm: EnrichmentConfigForm): Promise<unknown> {
    return this.enrichmentRepository.startJob(jobId, configForm);
  }
}
