import { useState } from 'react';

import {
  ANALYTICS_EVENTS,
  DataFiltersPageProperty,
  useAnalytics,
} from '@/features/system/analytics';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

type Return = {
  handleDateChange(value: string): void;
  selectedDate: DATE_SELECT_OPTION_VALUES;
};

export const useDateSelectFacade = (): Return => {
  const analytics = useAnalytics();

  const [selectedDate, setSelectedDate] = useState<DATE_SELECT_OPTION_VALUES>(
    DATE_SELECT_OPTION_VALUES.lastMonth,
  );

  const handleDateChange = (value: DATE_SELECT_OPTION_VALUES): void => {
    setSelectedDate(value);
    analytics.trackEvent(ANALYTICS_EVENTS.FILTER_BY_TIME, {
      page: DataFiltersPageProperty.Dashboard,
      time: value,
    });
  };

  return {
    selectedDate,
    handleDateChange,
  };
};
