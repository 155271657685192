import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import RetryIcon from '@/assets/icons/export/retry.svg?react';

import styles from './styles.module.scss';

type FailedProps = {
  text: string;
  onRetry: () => void;
};

export const Failed: FC<FailedProps> = ({ onRetry, text }) => {
  return (
    <Box className={styles.wrapper}>
      <IconButton onClick={onRetry}>
        <RetryIcon />
      </IconButton>
      <Typography variant="subtitle1" className={styles.description}>
        {text}
      </Typography>
    </Box>
  );
};
