import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { useObservableResult } from '@/utils/rx';

import { IContactByFiltersEntity } from '../../../domain';
import { useContactByFiltersUseCase } from '../../hooks';

import { NotificationIcon } from './components';

export const useUpdateNotificationViewModel = (
  queryString: string,
  listId = '',
): void => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation('contacts');
  const contactByFilterUseCase = useContactByFiltersUseCase();
  const { data } = useObservableResult(
    () => {
      return contactByFilterUseCase.compareToUpdate(queryString, listId);
    },
    {
      deps: [queryString, listId],
      defaultData: {
        readyToUpdate: false,
        entity: {} as IContactByFiltersEntity,
      },
    },
  );

  const handleClick = async () => {
    closeSnackbar();
    await firstValueFrom(contactByFilterUseCase.internalUpdate(queryString));
  };

  useEffect(() => {
    if (!data?.readyToUpdate) return;

    enqueueSnackbar(t('updateNotification.title'), {
      variant: 'info',
      preventDuplicate: true,
      persist: true,
      description: t('updateNotification.description'),
      icon: <NotificationIcon />,
      action: (
        <Button onClick={handleClick}>{t('updateNotification.updateButton')}</Button>
      ),
    });
  }, [data?.readyToUpdate, queryString]);

  useEffect(() => {
    return () => {
      closeSnackbar();
    };
  }, [queryString]);
};
