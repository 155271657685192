import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { VideoModal } from '@/components/VideoModal';

import { useModalController } from '@/hooks';

import { openInNewTab } from '@/utils/openInNewTab';

import VideoPlaceholderSrc from '@/assets/images/video_placeholder.webp';

import styles from './styles.module.scss';

export const DefaultListNoResultsOverlay: FC = () => {
  const { t } = useTranslation('contacts');
  const videoTutorialModalController = useModalController('VideoTutorial');

  const onStartProspecting = (): void => {
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_URL);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        <Typography variant="h1">
          {t('table.defaultListNoResultsOverlay.noProspects')}
        </Typography>
      </Box>

      <Box className={styles.descriptionContainer}>
        <Typography variant="h3">
          {t('table.defaultListNoResultsOverlay.onceYouReveal')}
        </Typography>
        <Button variant="contained" color="secondary" onClick={onStartProspecting}>
          {t('table.defaultListNoResultsOverlay.noProspectsButton')}
        </Button>
        <Typography>
          {t('table.defaultListNoResultsOverlay.noProspectsVideoDescription')}
        </Typography>
      </Box>
      <Box className={styles.wrapperVideo}>
        <Button
          onClick={videoTutorialModalController.onVideoTutorialOpen}
          className={styles.placeholder}
        >
          <div className="pw-play-button" />
          <img src={VideoPlaceholderSrc} alt={''} />
        </Button>
        <VideoModal
          videoSrc={'/videos/video_placeholder.mp4'}
          open={videoTutorialModalController.isVideoTutorialOpen}
          onClose={videoTutorialModalController.onVideoTutorialClose}
        />
      </Box>
    </Box>
  );
};
