import type { Components, Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    rounded: {
      backgroundColor: '#f5f5fa',
    },
    pulse: {
      backgroundColor: '#e4e6e8',
    },
    wave: {
      backgroundColor: '#e4e6e8',
    },
  },
};
