import { MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equals } from 'ramda';

import { ContactInfoFilterType } from '@/features/common/contact';
import { useContactByFilters } from '@/features/common/contact';

import { useOptimisticState } from '@/hooks';

import { ContactsContext } from '../../context';

type Option = { label: string; value: ContactInfoFilterType };

type UseFilterByContactInfoViewModelResult = {
  anchorElement: Nullable<HTMLElement>;
  value: ContactInfoFilterType[];
  options: Option[];
  valuesIsMatched: boolean;
  appliedValuesCount: number;
  handleApply(): void;
  handleCloseAnimationEnd(): void;
  handleAnchorClick: (e: MouseEvent<HTMLElement>) => void;
  handleOptionClick: (option: ContactInfoFilterType) => void;
  close: () => void;
};

export const useFilterByContactInfoViewModel =
  (): UseFilterByContactInfoViewModelResult => {
    const [anchorElement, setAnchorElement] = useState<Nullable<HTMLElement>>(null);
    const { t } = useTranslation('contacts');
    const { filterByContactInfo, setFilterByContactInfo, contactURLSearchParams } =
      useContext(ContactsContext);
    const [value, setValue] = useOptimisticState(filterByContactInfo);
    const { data } = useContactByFilters(contactURLSearchParams.toString());

    const options: Option[] = [
      {
        value: 'phones',
        label: t('subhead.contactInfoFilter.options.phoneNumbers', {
          total: data?.contactInfoCountCurrent.phones || 0,
        }),
      },
      {
        value: 'verified_emails',
        label: t('subhead.contactInfoFilter.options.verifiedEmails', {
          total: data?.contactInfoCountCurrent.verifiedEmails || 0,
        }),
      },
      {
        value: 'emails',
        label: t('subhead.contactInfoFilter.options.emails', {
          total: data?.contactInfoCountCurrent.emails || 0,
        }),
      },
    ];
    const valuesIsMatched = equals(value.sort(), filterByContactInfo.sort());

    const handleApply = (): void => {
      setFilterByContactInfo(value);
      setAnchorElement(null);
    };

    const handleCloseAnimationEnd = (): void => {
      if (!valuesIsMatched) {
        setValue(filterByContactInfo);
      }
    };

    const handleAnchorClick = (e: MouseEvent<HTMLElement>): void => {
      setAnchorElement((prev) => (!prev ? e.currentTarget : null));
    };

    const handleOptionClick = (option: ContactInfoFilterType): void => {
      if (value.includes(option)) {
        setValue(value.filter((item) => item !== option));
      } else {
        setValue([...value, option]);
      }
    };

    const close = (): void => {
      setAnchorElement(null);
    };

    return {
      handleOptionClick,
      handleAnchorClick,
      anchorElement,
      value,
      options,
      handleApply,
      handleCloseAnimationEnd,
      close,
      valuesIsMatched,
      appliedValuesCount: filterByContactInfo.length,
    };
  };
