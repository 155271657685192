import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, Typography } from '@mui/material';
import clsx from 'clsx';

import { ROUTES } from '@/router/routes';

import { IContactListEntity } from '@/features/common/contactList';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import {
  ContextualOnboardingEvents,
  useContextualOnboarding,
} from '@/features/system/contextualOnboarding';

import { MenuItem, TabOption, Tabs } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import { useDocumentMeta } from '@/hooks';

import { ContactsContext } from '../../context';

import styles from './styles.module.scss';

const VISIBLE_TABS_COUNT = 4;
const mapContactListEntityToTabOption = (entity: IContactListEntity): TabOption => ({
  label: entity.name,
  value: entity.uuid,
});

export const ContactsTabs: FC<{
  contactLists: IContactListEntity[];
  currentContactList: IContactListEntity | null;
}> = ({ contactLists, currentContactList }) => {
  const navigate = useNavigate();
  const [currentContactLists, setCurrentContactLists] =
    useState<IContactListEntity[]>(contactLists);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { trackEvent } = useAnalytics();
  const { track: trackContextualOnboardingEvent } = useContextualOnboarding();
  const { setPage } = useContext(ContactsContext);
  const { t } = useTranslation('common');
  const onChangeCurrentTab = (id: string): void => {
    setPage(1);
    navigate(ROUTES.CONTACTS_INNER.replace(':id', id));
  };
  const onCloseMenu = (): void => setAnchorEl(null);
  const handleTabsChange = (e, value: string): void => {
    onChangeCurrentTab(value);
  };
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = (value): void => {
    onChangeCurrentTab(value);
    onCloseMenu();
  };

  useEffect(() => {
    const sortedContactLists = (
      contactLists: IContactListEntity[],
    ): IContactListEntity[] => {
      if (!currentContactList) return [];

      const currentItemIndex = contactLists.findIndex(
        (item) => item.uuid === currentContactList.uuid,
      );

      if (currentItemIndex >= VISIBLE_TABS_COUNT && contactLists[currentItemIndex]) {
        return [
          contactLists[0],
          contactLists[currentItemIndex],
          ...contactLists.slice(1, currentItemIndex),
          ...contactLists.slice(currentItemIndex + 1, contactLists.length),
        ];
      }
      return [...contactLists];
    };

    if (currentContactLists.length === contactLists.length) {
      setCurrentContactLists(sortedContactLists(currentContactLists));
    } else {
      setCurrentContactLists(sortedContactLists(contactLists));
    }
  }, [currentContactList?.uuid, contactLists]);

  useEffect(() => {
    if (!currentContactList) return;

    const analyticType =
      currentContactList.contactsAmount > 0 ? 'with contacts' : 'empty';

    if (currentContactList.specialType === 'all_contacts') {
      trackEvent(ANALYTICS_EVENTS.VIEW_ALL_CONTACTS_LIST, {
        type: analyticType,
        contacts_count: currentContactList.contactsAmount,
      });
      currentContactList.contactsAmount > 0 &&
        trackContextualOnboardingEvent(ContextualOnboardingEvents.viewAllContactsList);
    } else {
      trackEvent(ANALYTICS_EVENTS.VIEW_LIST, {
        type: analyticType,
        contacts_count: currentContactList.contactsAmount,
      });
      currentContactList.contactsAmount > 0 &&
        trackContextualOnboardingEvent(ContextualOnboardingEvents.viewList);
    }
  }, [currentContactList?.uuid]);

  useDocumentMeta(
    {
      title: {
        key: 'contactsList.title',
        options: { listName: currentContactList?.name || '' },
      },
      description: 'contactsList.description',
    },
    [currentContactList?.uuid],
  );

  const tabs = currentContactLists
    .slice(0, VISIBLE_TABS_COUNT)
    .map(mapContactListEntityToTabOption);
  const menuOptions = currentContactLists
    .slice(VISIBLE_TABS_COUNT)
    .map(mapContactListEntityToTabOption);

  return (
    <Box className={styles.wrapper}>
      <Tabs options={tabs} value={currentContactList?.uuid} onChange={handleTabsChange} />
      {Boolean(menuOptions.length) && (
        <>
          <Box
            className={clsx(styles.button, { [styles.isOpen]: Boolean(anchorEl) })}
            onClick={handleButtonClick}
          >
            <Typography className={styles.buttonText}>{t('more')}</Typography>
            <Box className={styles.buttonAnchor}>
              <ArrowDownIcon />
            </Box>
          </Box>

          <Menu
            classes={{ list: styles.menuList, paper: styles.menuPaper }}
            open={Boolean(anchorEl)}
            onClose={onCloseMenu}
            anchorEl={anchorEl}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            {menuOptions.map((option) => {
              return (
                <MenuItem
                  onClick={(): void => {
                    handleMenuItemClick(option.value);
                  }}
                  key={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </Box>
  );
};
