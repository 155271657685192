import { injectable } from 'inversify';

import { sentryCaptureException } from '@/features/system/sentry';

import { type IAppLogger } from '../domain';

@injectable()
export class AppLogger implements IAppLogger {
  error(error: unknown): void {
    console.error(error);
    sentryCaptureException(
      error instanceof Error
        ? error
        : new Error(`Unknown Error: ${JSON.stringify(error)}`),
    );
  }

  log(...data: any[]): void {
    console.log(...data);
  }
}
