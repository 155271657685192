import { injectable } from 'inversify';

import { DbStateCreator } from '@/features/system/db/data/DbStateCreator';

import type { IBillingStateDC } from './BillingState';

@injectable()
export class BillingStateCreator extends DbStateCreator<IBillingStateDC> {
  constructor() {
    super({
      stateName: 'billing',
    });
  }
}
