import { FC, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput, SearchInputProps } from '@/components';

import { CustomSelectV2Context } from '../../../../CustomSelectV2Context';

type SearchProps = Omit<SearchInputProps, 'value' | 'onChange' | 'onClear'> & {
  variant?: 'filled' | 'outlined';
};

export const Search: FC<SearchProps> = ({
  variant = 'filled',
  autoFocus = true,
  ...props
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('common');
  const { searchValue, changeSearchValue, clearSearchValue } =
    useContext(CustomSelectV2Context);

  const [optimisticSearchValue, setOptimisticSearchValue] = useState(searchValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.preventDefault();
  };

  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, []);

  return (
    <SearchInput
      inputRef={ref}
      placeholder={props.placeholder || t('customSelect.search.placeholder')}
      value={optimisticSearchValue}
      onChange={(e): void => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setOptimisticSearchValue(e.target.value);
        timeoutRef.current = setTimeout(() => {
          // @ts-ignore
          changeSearchValue(e);
          timeoutRef.current = null;
        }, 50);
      }}
      onClear={(): void => {
        clearSearchValue();
        setOptimisticSearchValue('');
      }}
      onKeyDown={handleKeyDown}
      variant={variant}
    />
  );
};
