import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const RevealContact: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();

  const handleClick = (): void => {
    window.open(EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL, '_blank');
    onClick?.();
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={props.text ?? t('callToActions.getContact')}
    />
  );
};
