import { IContactListDC, IContactListEntity } from '@/features/common/contactList';

export const mapContactListDcToEntity = (dc: IContactListDC): IContactListEntity => {
  return {
    uuid: dc.uuid,
    name: dc.name,
    createdBy: dc.created_by,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    specialType: dc.special_type,
    contactsAmount: dc.contacts_amount,
  };
};
