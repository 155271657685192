import { injectable } from 'inversify';

import { DbStateCreator } from '@/features/system/db/data/DbStateCreator';

import { IIntegrationStateDC } from './IntegrationState';

@injectable()
export class IntegrationStateCreator extends DbStateCreator<IIntegrationStateDC> {
  constructor() {
    super({
      stateName: 'integration',
    });
  }
}
