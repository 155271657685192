import { FC } from 'react';
import { Box } from '@mui/material';

import { ErrorPage500 } from '@/router/error';

import { EmptyHeader } from '@/components';

import styles from './styles.module.scss';

export const BaseSyncError: FC = () => {
  return (
    <Box className={styles.page}>
      <EmptyHeader />
      <Box className={styles.error}>
        <ErrorPage500 />
      </Box>
    </Box>
  );
};
