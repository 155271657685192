import { ReactNode, useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { UserRole } from '@/features/common/account';
import { useAccount } from '@/features/common/account/ui/hooks/useAccount';
import { useExportContacts } from '@/features/common/contact';
import {
  useCurrentWorkspaceOwner,
  useWorkspaceSubscription,
} from '@/features/common/workspace';

import { AccountLockedAdminOverlay, AccountLockedUserOverlay } from './components';

type BillingOverlayViewModel = {
  overlay: ReactNode;
};

enum BillingIssue {
  None = 'None',
  AccountLocked = 'AccountLocked',
}

const BILLING_OVERLAY_WHITELIST_ROUTES = [
  ROUTES.DASHBOARD,
  ROUTES.CONTACTS_ENTRY,
  ROUTES.INTEGRATIONS,
  ROUTES.CONTACTS_INNER,
  ROUTES.SAVED_CONTACTS,
];

function useBillingLimitation(): BillingIssue {
  const { data: currentSubscription } = useWorkspaceSubscription();

  const isActive = currentSubscription?.isActive ?? true;

  if (!isActive) {
    return BillingIssue.AccountLocked;
  }

  return BillingIssue.None;
}

function useOverlay(params: { limitation: BillingIssue }): ReactNode {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { account } = useAccount();
  const { onExportToCsvAll } = useExportContacts();
  const { data: owner } = useCurrentWorkspaceOwner();

  const canShowOverlay = useMemo(() => {
    return BILLING_OVERLAY_WHITELIST_ROUTES.find((route) => matchPath(route, pathname));
  }, [pathname, params.limitation]);

  if (!canShowOverlay || !account) {
    return null;
  }

  const handleIssueFix = (): void => {
    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  const handleContactsExport = (): void => {
    onExportToCsvAll();
  };

  switch (params.limitation) {
    case BillingIssue.None:
      return null;
    case BillingIssue.AccountLocked:
      return account?.role === UserRole.Admin || account?.role === UserRole.Owner ? (
        <AccountLockedAdminOverlay
          onIssueFix={handleIssueFix}
          onContactsExport={handleContactsExport}
        />
      ) : (
        <AccountLockedUserOverlay
          onContactsExport={handleContactsExport}
          ownerEmail={owner?.email ?? ''}
        />
      );
  }
}

export function useBillingOverlayViewModel(): BillingOverlayViewModel {
  const limitation = useBillingLimitation();
  const overlay = useOverlay({ limitation });

  return {
    overlay,
  };
}
