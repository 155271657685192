import { useInjection } from '@/ioc/ioc.react';
import { ENRICHMENT_TYPES } from '@/ioc/types';

import { IEnrichmentFileUploadUseCase } from '@/features/enrichment/domain/abstraction';

export const useEnrichmentFileUploadUseCase = () => {
  return useInjection<IEnrichmentFileUploadUseCase>(
    ENRICHMENT_TYPES.EnrichmentFileUploadUseCase,
  );
};
