import { Container } from 'inversify';

import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import {
  type IPaymentDetailsApiService,
  type IPaymentDetailsRepository,
  PaymentDetailsApiService,
} from '../data';
import { PaymentDetailsRepository } from '../data/PaymentDetailsRepository';
import type { IPaymentDetailsUseCase } from '../domain/abstractions/useCases/IPaymentDetailsUseCase';
import { PaymentDetailsUseCase } from '../domain/PaymentDetailsUseCase';

export const bindPaymentDetailsModule = (container: Container): void => {
  container
    .bind<IPaymentDetailsApiService>(PAYMENT_DETAILS_TYPES.PaymentDetailsApiService)
    .to(PaymentDetailsApiService);
  container
    .bind<IPaymentDetailsRepository>(PAYMENT_DETAILS_TYPES.PaymentDetailsRepository)
    .to(PaymentDetailsRepository);
  container
    .bind<IPaymentDetailsUseCase>(PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase)
    .to(PaymentDetailsUseCase);
};
