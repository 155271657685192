import { DomainError } from '@/features/common/error';

type EnrichmentUploadCreditsLimitErrorOptions = ErrorOptions & {
  fileMetadata: {
    records: number;
    filename: string;
  };
};

export class EnrichmentUploadCreditsLimitError extends DomainError {
  options: EnrichmentUploadCreditsLimitErrorOptions;

  constructor(message: string, options: EnrichmentUploadCreditsLimitErrorOptions) {
    super(message);
    this.options = options;
    this.name = 'EnrichmentUploadRateLimitError';
  }
}
