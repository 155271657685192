import { RxReplicationPullStreamItem, WithDeleted } from 'rxdb';

import { IBaseSyncEntity, isDeleteBaseSyncEntity } from '@/features/system/sync';

export const mapBaseSyncEntity = <Out extends WithDeleted<unknown>>(
  syncEntityEvent: IBaseSyncEntity,
): RxReplicationPullStreamItem<Out, { resume_token: string | null }> => {
  if (typeof syncEntityEvent.entity !== 'object') {
    throw new Error('syncEntity.entity must be an object');
  }

  return {
    documents: [
      {
        ...syncEntityEvent.entity,
        ...{ _deleted: isDeleteBaseSyncEntity(syncEntityEvent) },
      } as Out,
    ],
    checkpoint: {
      resume_token: syncEntityEvent.resume_token,
    },
  };
};
