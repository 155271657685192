export enum ContactListSortableField {
  name = 'name',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
  contactsAmount = 'contacts_amount',
  createdBy = 'created_by',
}

export type ContactListSortSchema = Partial<
  Record<ContactListSortableField, 'asc' | 'desc'>
>;
