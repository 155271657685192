import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import { catchError, combineLatest, filter, first, map, of, switchMap, tap } from 'rxjs';

import { ErrorPage500 } from '@/router/error';

import { useInjection } from '@/ioc';
import { ACCOUNT_TYPES, BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IAccountUseCase } from '@/features/common/account';
import type { IBillingUseCase } from '@/features/common/billing';
import type { IWorkspaceUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { usePlanIdFromUrl, useQuantityFromUrl } from '../../hooks';

import { PaymentDetailsContext } from './Context';

/*
Purpose of this provider is to provide all necessary data
for payment details before rendering feature
*/
export const PaymentDetailsProvider: FC<
  PropsWithChildren<{
    fallback?: ReactNode;
  }>
> = ({ children, fallback = null }) => {
  const billingUseCase = useInjection<IBillingUseCase>(BILLING_TYPES.BillingUseCase);
  const accountUseCase = useInjection<IAccountUseCase>(ACCOUNT_TYPES.AccountUseCase);
  const workspacesUseCase = useInjection<IWorkspaceUseCase>(
    WORKSPACE_TYPES.WorkspaceUseCase,
  );

  const [planIdFromUrl, setPlanId] = usePlanIdFromUrl();
  const [quantityFromUrl, setQuantity] = useQuantityFromUrl(1);

  const result = useObservableResult(
    () => {
      const currentWorkspace$ = workspacesUseCase.getCurrentWorkspace();

      const currentSubscription$ = currentWorkspace$.pipe(
        map(({ subscription }) => subscription),
      );

      const currentProduct$ = currentSubscription$.pipe(
        first(),
        switchMap((currentSubscription) =>
          billingUseCase.getProduct(currentSubscription.plan),
        ),
      );

      const targetProduct$ = planIdFromUrl
        ? billingUseCase.getProduct(planIdFromUrl)
        : currentProduct$.pipe(
            tap((currentProduct) => {
              setPlanId(currentProduct.id);
            }),
          );

      return combineLatest({
        account: accountUseCase.getAccount().pipe(filter((a) => !!a)),
        currentWorkspace: currentWorkspace$,
        currentSubscription: currentSubscription$,
        currentProduct: currentProduct$,
        targetProduct: targetProduct$,
        billingDetails: billingUseCase
          .getBillingDetails()
          .pipe(catchError(() => of(null))),
        paymentMethod: billingUseCase.getPaymentMethod().pipe(catchError(() => of(null))),
        quantity: targetProduct$.pipe().pipe(
          map((product) => (product.isPriceFixed ? 1 : quantityFromUrl)),
          tap(setQuantity), // sync correct quantity with url
        ),
      });
    },
    {
      deps: [planIdFromUrl],
    },
  );

  if (result.isLoading) {
    return <Fragment>{fallback}</Fragment>;
  }

  if (result.hasError) {
    return <ErrorPage500 />;
  }

  return (
    <PaymentDetailsContext.Provider value={result.data}>
      {children}
    </PaymentDetailsContext.Provider>
  );
};
