import { inject, injectable } from 'inversify';
import { equals } from 'ramda';
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { CONTACT_BY_FILTERS_TYPES, CONTACT_TYPES } from '@/ioc/types';

import {
  IContactByFiltersRepository,
  IContactByFiltersUseCase,
  IContactRepository,
} from './abstractions';
import { IContactByFiltersEntity } from './entities';

@injectable()
export class ContactByFiltersUseCase implements IContactByFiltersUseCase {
  @inject(CONTACT_BY_FILTERS_TYPES.ContactByFiltersRepository)
  private contactByFiltersRepository: IContactByFiltersRepository;

  @inject(CONTACT_TYPES.ContactRepository)
  private contactRepository: IContactRepository;

  public compareToUpdate(
    id: string,
    listId: string,
  ): Observable<{
    readyToUpdate: boolean;
    entity: IContactByFiltersEntity;
  }> {
    return this.contactByFiltersRepository.getById(id).pipe(
      filter((entity) => !!entity),
      debounceTime(1000),
      switchMap((entity: IContactByFiltersEntity) => {
        return combineLatest([
          this.contactRepository.getByIds(entity.contactByFiltersIdsLatest).pipe(first()),
          of(entity),
        ]);
      }),
      map(([latest, entity]) => {
        const isPredictableResult = listId
          ? latest.every((contact) => {
              return contact.contactListId === listId;
            })
          : true;

        return {
          readyToUpdate:
            isPredictableResult &&
            !equals(entity.contactByFiltersIdsCurrent, entity.contactByFiltersIdsLatest),
          entity,
        };
      }),
    );
  }

  public internalUpdate(id: string): Observable<IContactByFiltersEntity> {
    return this.contactByFiltersRepository.getById(id).pipe(
      first(),
      filter((entity) => !!entity),
      switchMap((entity: IContactByFiltersEntity) => {
        return this.contactByFiltersRepository.update({
          ...entity,
          contactByFiltersIdsCurrent: entity.contactByFiltersIdsLatest,
          contactInfoCountCurrent: entity.contactInfoCountLatest,
          companyByFilterIdsCurrent: entity.companyByFilterIdsLatest,
          countCurrent: entity.countLatest,
        });
      }),
    );
  }

  public getById(id: string): Observable<IContactByFiltersEntity | null> {
    return this.contactByFiltersRepository.getById(id);
  }
}
