import { AxiosResponse } from 'axios';

export class NetworkError extends Error {
  statusCode?: number;
  code?: string;
  response?: AxiosResponse;
  constructor(
    message?: string,
    statusCode?: number,
    code?: string,
    response?: AxiosResponse,
  ) {
    super(message);
    this.statusCode = statusCode;
    this.code = code;
    this.response = response;
  }
}
