import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

type SharedAlertProps = {
  description?: ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
};

declare module 'notistack' {
  interface VariantOverrides {
    default: false;
    success: SharedAlertProps;
    warning: SharedAlertProps;
    error: SharedAlertProps;
    info: SharedAlertProps;
  }
}

export type CustomizedAlertProps = SharedAlertProps & {
  id: string;
  message: string;
  variant?: 'success' | 'warning' | 'error' | 'info';
};

export const CustomizedAlert = forwardRef<HTMLDivElement, CustomizedAlertProps>(
  ({ message, description, id, variant, icon, action }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation('common');

    const onClose = (): void => {
      closeSnackbar(id);
    };

    return (
      <Alert
        icon={icon}
        severity={variant ?? 'success'}
        action={
          action ?? (
            <Button variant="text" onClick={onClose} sx={{ textTransform: 'capitalize' }}>
              {t('close')}
            </Button>
          )
        }
        onClose={onClose}
        ref={ref}
      >
        <Typography variant="subtitle1" color="black" lineHeight={1}>
          {message}
        </Typography>
        {description && (
          <Typography variant="subtitle2" color="info" lineHeight={1} mt={1}>
            {description}
          </Typography>
        )}
      </Alert>
    );
  },
);

CustomizedAlert.displayName = 'CustomizedAlert';
