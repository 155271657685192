import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import { ACCOUNT_TYPES, DASHBOARD_CHART_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountEntity, IAccountRepository } from '@/features/common/account';
import { ISubscriptionUseCase, IWorkspaceRepository } from '@/features/common/workspace';
import { IDashboardChartRepository } from '@/features/dashboard/features/chart/data';

import { IFairUsageUseCase } from './abstractions/IFareUsageUseCase';

@injectable()
export class FairUsageUseCase implements IFairUsageUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(DASHBOARD_CHART_TYPES.ChartRepository)
  private chartRepository: IDashboardChartRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  getIsFairUsageLimitReached(): Observable<boolean> {
    return combineLatest({
      isDailyLimitReached: this.isDailyLimitReached(),
      isMonthlyLimitReached: this.isMonthlyLimitReached(),
      isUnlimitedPlan: this.subscriptionUseCase.getIsUnlimitedPlan(),
    }).pipe(
      map(({ isDailyLimitReached, isMonthlyLimitReached, isUnlimitedPlan }) => {
        if (!isUnlimitedPlan) {
          return false;
        }

        return isDailyLimitReached || isMonthlyLimitReached;
      }),
      distinctUntilChanged(),
    );
  }

  private getDailyCreditsLimit(): Observable<number> {
    return this.workspaceRepository
      .getCurrentWorkspaceSubscription()
      .pipe(map((subscription) => subscription.dailyFairLimit ?? 0));
  }

  private getMonthlyCreditsLimit(): Observable<number> {
    return this.workspaceRepository
      .getCurrentWorkspaceSubscription()
      .pipe(map((subscription) => subscription.monthlyFairLimit ?? 0));
  }

  private getDailyUsedCredits(): Observable<number> {
    return this.accountRepository.getAccount().pipe(
      filter((account): account is IAccountEntity => !!account),
      switchMap((account) => {
        const today = dayjs();

        return this.chartRepository.getData({
          fromDate: today.startOf('day').unix(),
          toDate: today.endOf('day').unix(),
          selectedUser: account.uuid,
        });
      }),
      map((data) => Math.max(...data.map((d) => d.creditsUsed))),
    );
  }

  private getMonthlyUsedCredits(): Observable<number> {
    return this.accountRepository.getAccount().pipe(
      filter((account): account is IAccountEntity => !!account),
      map((account) => account.currentCreditsUsed ?? 0),
    );
  }

  private isMonthlyLimitReached(): Observable<boolean> {
    return combineLatest({
      used: this.getMonthlyUsedCredits(),
      limit: this.getMonthlyCreditsLimit(),
    }).pipe(
      map(({ used, limit }) => {
        return used >= limit;
      }),
    );
  }

  private isDailyLimitReached(): Observable<boolean> {
    return combineLatest({
      used: this.getDailyUsedCredits(),
      limit: this.getDailyCreditsLimit(),
    }).pipe(
      map(({ used, limit }) => {
        return used >= limit;
      }),
    );
  }
}
