import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import {
  ActionDisableGuard,
  HasPermission,
  Permission,
} from '@/features/common/permissions';

import { ErrorModal } from './components/ErrorModal/ErrorModal';
import { InviteModal } from './components/InviteModal';
import { PermissionsButton } from './components/PermissionsButton';
import { TeamMembersTable } from './components/TeamMembersTable';
import { useTeamManagementViewModel } from './useTeamManagementViewModel';

import styles from './styles.module.scss';

const TeamManagement: React.FC = () => {
  const { t } = useTranslation('settings');
  const { inviteModal, errorModal, handleInvite } = useTeamManagementViewModel();

  return (
    <Box className={styles.TabWrapper}>
      <HasPermission permission={Permission.CanManageMembers}>
        <Box className={styles.ButtonsWrapper}>
          <HasPermission permission={Permission.CanChangeUserPermissions}>
            <PermissionsButton />
          </HasPermission>
          <ActionDisableGuard>
            <Button
              onClick={handleInvite}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t('teamManagement.inviteButton')}
            </Button>
          </ActionDisableGuard>
        </Box>
      </HasPermission>

      <ErrorModal {...errorModal} />
      <InviteModal {...inviteModal} />
      <TeamMembersTable />
    </Box>
  );
};

export default TeamManagement;
