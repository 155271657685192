import { equals } from 'ramda';
import { RxReplicationWriteToMasterRow, WithDeleted } from 'rxdb/dist/types/types';

import { NetworkError } from '@/features/system/network';

import { ReplicationPushHandlerExecActionType } from './types';

type ExecutionConfig<T> = {
  [ReplicationPushHandlerExecActionType.Create]?: (
    docsToCreate: RxReplicationWriteToMasterRow<T>[],
  ) => Promise<T | T[]>;
  [ReplicationPushHandlerExecActionType.Update]?: (
    docsToUpdate: RxReplicationWriteToMasterRow<T>[],
  ) => Promise<T | T[]>;
  [ReplicationPushHandlerExecActionType.Delete]?: (
    docsToDelete: RxReplicationWriteToMasterRow<T>[],
  ) => Promise<unknown>;
};

type BaseDocType = { created_at?: number; updated_at?: number; uuid: string };

type PushHandlerGroupDocsByActionTypeResult<T> = {
  docsToCreate: RxReplicationWriteToMasterRow<T>[];
  docsToDelete: RxReplicationWriteToMasterRow<T>[];
  docsToUpdate: RxReplicationWriteToMasterRow<T>[];
};

export interface IReplicationPushHandlerCreator<T> {
  create: (toMasterRows: RxReplicationWriteToMasterRow<T>[]) => Promise<WithDeleted<T>[]>;
}

export class ReplicationPushHandlerCreator<T extends BaseDocType>
  implements IReplicationPushHandlerCreator<T>
{
  private readonly execConfig: ExecutionConfig<T>;

  constructor(execConfig: ExecutionConfig<T>) {
    this.execConfig = execConfig;
  }

  private groupDocsByActionType(
    toMasterRows: RxReplicationWriteToMasterRow<T>[] = [],
  ): PushHandlerGroupDocsByActionTypeResult<T> {
    // [Skip extra sync request after conflict resolution]
    // sync mechanism will try to sync entities again to push conflict resolution, but since we always apply server response we can skip this sync
    const docs = toMasterRows.filter(
      (doc) =>
        !(
          doc.newDocumentState.created_at === doc.assumedMasterState?.created_at &&
          doc.newDocumentState.updated_at === doc.assumedMasterState?.updated_at &&
          doc.newDocumentState._deleted === doc.assumedMasterState?._deleted
        ),
    );

    const docsToCreate = docs.filter(
      ({ newDocumentState }) =>
        !newDocumentState._deleted &&
        newDocumentState.updated_at === newDocumentState.created_at,
    );

    const docsToUpdate = docs.filter(
      ({ newDocumentState }) =>
        !newDocumentState._deleted &&
        newDocumentState.updated_at !== newDocumentState.created_at,
    );

    const docsToDelete = docs.filter(({ newDocumentState }) => newDocumentState._deleted);

    return {
      docsToCreate,
      docsToDelete,
      docsToUpdate,
    };
  }

  private conflictResolver(
    remoteResult: WithDeleted<T>[] = [],
    toMasterRows: RxReplicationWriteToMasterRow<T>[] = [],
  ): WithDeleted<T>[] {
    // [Return docs with conflicts]
    // We agreed to apply server response despite of the conflict state (motivation: server could apply our change, but some fields could be updated from back-end side anyway e.g. merge conflicts on their side).
    // However, if we return docs from the push stream handler function without real-conflict, the replication plugin will not allow to update this entity in the future pull stream events (that's how replication plugin works, plugin restrict to return only real conflicts from the push handler fn).
    // For that case we should check for real conflicted docs (deep equal check) and return only them. Equal docs have been already saved in DB, so no need to apply such change.
    // NOTE: We check only fields from local documents because some fields could be missed in rxdb schema.
    const masterRowsById = new Map(
      toMasterRows.map((row) => [row.newDocumentState.uuid, row]),
    );

    return remoteResult.filter((remoteDocument) => {
      const localDocument = masterRowsById.get(remoteDocument.uuid)?.newDocumentState;

      if (!localDocument) {
        return true;
      }

      return Object.keys(localDocument)
        .filter((key) => !key.startsWith('_')) // client internal field
        .some((key) => !equals(localDocument[key], remoteDocument[key]));
    });
  }

  private revertWithDeleted<T extends BaseDocType>(
    toMasterRows: RxReplicationWriteToMasterRow<T>[] = [],
  ): WithDeleted<T>[] {
    return toMasterRows.map((obj) => {
      // if assume master state is not exist(in case creation new entity),
      // need return new doc state with _deleted flag to revert creation the changes
      return (
        obj.assumedMasterState || {
          ...obj.newDocumentState,
          _deleted: true,
        }
      );
    }); // revert to previous state
  }

  public async create(
    toMasterRows: RxReplicationWriteToMasterRow<T>[],
  ): Promise<WithDeleted<T>[]> {
    const {
      docsToUpdate = [],
      docsToCreate = [],
      docsToDelete = [],
    } = this.groupDocsByActionType(toMasterRows);

    const responses: T[] = [];

    const pushResponse = (response: T | T[]): void => {
      if (Array.isArray(response)) {
        responses.push(...response);
      } else {
        responses.push(response);
      }
    };

    try {
      if (docsToCreate.length && this.execConfig?.create) {
        const response = await this.execConfig.create(docsToCreate);
        pushResponse(response);
      }

      if (docsToUpdate.length && this.execConfig?.update) {
        const response = await this.execConfig.update(docsToUpdate);
        pushResponse(response);
      }

      if (docsToDelete.length && this.execConfig?.delete) {
        await this.execConfig.delete(docsToDelete);
      }
    } catch (error) {
      if (error instanceof NetworkError) return this.revertWithDeleted(toMasterRows);
      throw error;
    }

    return this.conflictResolver(
      responses.map((doc) => ({ ...doc, _deleted: false })),
      toMasterRows,
    );
  }
}
