import { IEnrichmentEntity, IEnrichmentStatisticEntity } from '../../../domain/entities';
import { IEnrichmentDC } from '../../dataContract';

export const mapEnrichmentStatisticDcToEntity = (
  dc: IEnrichmentDC['statistic'],
): IEnrichmentStatisticEntity =>
  ({
    total: {
      count: dc.total.count || 0,
      matched: dc.total.matched || 0,
      duplicate: dc.total.duplicate || 0,
      notFound: dc.total.not_found || 0,
    },
    emails: {
      count: dc.emails.count || 0,
      verified: dc.emails.verified || 0,
      notVerified: dc.emails.not_verified || 0,
      notFound: dc.emails.not_found || 0,
    },
    phones: {
      count: dc.phones.count || 0,
      hqPhone: dc.phones.hq_phone || 0,
      direct_dial: dc.phones.direct_dial || 0,
    },
    fields: dc.fields.map((field) => ({
      fieldName: field.field_name,
      count: field.count || 0,
    })),
  }) as IEnrichmentStatisticEntity;

export const mapEnrichmentDcToEntity = (dc: IEnrichmentDC): IEnrichmentEntity =>
  ({
    uuid: dc.uuid,
    name: dc.name,
    status: dc.status,
    progress: dc.progress,
    updatedAt: dc.updated_at,
    createdAt: dc.created_at,
    createdBy: {
      uuid: dc.created_by.uuid,
      fullName: dc.created_by.full_name,
    },
    enrichType: dc.enrich_type,
    statistic: mapEnrichmentStatisticDcToEntity(dc.statistic),
  }) as IEnrichmentEntity;
