import { FC, MouseEvent, PropsWithChildren, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, styled } from '@mui/material';

import { ArrowDownIcon } from '@/components/Icons';

import {
  CustomSelectV2Context,
  CustomSelectV2ContextType,
} from '../../CustomSelectV2Context';
import { PrimitiveValue, Size } from '../../types';

export type InputProps<OptionValue extends PrimitiveValue, Multiple extends boolean> = {
  placeholder?: string;
  label?: string;
  name?: string;
  size?: Size;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  render?(
    params: Omit<InputProps<OptionValue, Multiple>, 'render'> & {
      isOpen: boolean;
    } & Pick<
        CustomSelectV2ContextType<OptionValue, Multiple>,
        'setAnchorElement' | 'closeMenu' | 'inputData'
      >,
  ): ReactElement;
};

type StateProps<OptionValue extends PrimitiveValue, Multiple extends boolean> = Pick<
  InputProps<OptionValue, Multiple>,
  'error' | 'disabled' | 'required' | 'size'
> & {
  isOpen?: boolean;
};

const WrappedBox: FC<BoxProps & StateProps<PrimitiveValue, boolean>> = ({
  error,
  disabled,
  size,
  required,
  isOpen,
  ...rest
}) => <Box {...rest} />;

const Wrapper = styled(WrappedBox)(({ theme, ...rest }) => {
  return {
    borderRadius: '4px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    paddingRight: '32px',
    paddingLeft: '12px',
    border: `solid 1px #e4e6e8`,
    height: ((props): string => {
      switch (props.size) {
        case 'small':
          return '28px';
        case 'medium':
          return '34px';
        case 'large':
          return '40px';
        default:
          return '34px';
      }
    })(rest),
    fontSize: ((props): string => {
      switch (props.size) {
        case 'small':
          return '11px';
        case 'medium':
          return '13px';
        case 'large':
          return '14px';
        default:
          return '13px';
      }
    })(rest),
    borderColor: ((props): string => {
      if (props.error) {
        return `#ff4757`;
      } else if (props.isOpen) {
        return `#2173ff`;
      } else if (props.disabled) {
        return `#e4e6e8`;
      }
      return `#e4e6e8`;
    })(rest),
    cursor: ((props): string => {
      if (props.disabled) {
        return 'default';
      }
      return 'pointer';
    })(rest),
    opacity: rest.disabled ? 0.4 : 1,
    variants: [],
  };
});

const Text = styled(Box)(() => ({
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const Icon = styled(WrappedBox)(({ theme, ...rest }) => ({
  fontSize: ((props): string => {
    if (props.size === 'small') {
      return '8px';
    }

    return '10px';
  })(rest),
  position: 'absolute',
  right: '12px',
  display: 'flex',
  color: `#8083a3`,
  transform: ((props): string => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'))(
    rest,
  ),
  variants: [],
}));

export const InputContainer: FC<
  PropsWithChildren<{
    size?: Size;
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
  }>
> = ({ children, disabled, error, size = 'medium', required }) => {
  const { isOpen, setAnchorElement } = useContext(CustomSelectV2Context);

  const handleClick = (e: MouseEvent<HTMLElement>): void => {
    if (disabled) return;
    setAnchorElement((prev) => (!prev ? e.currentTarget : null));
  };

  return (
    <Wrapper
      size={size}
      error={error}
      disabled={disabled}
      required={required}
      onClick={handleClick}
      isOpen={isOpen}
    >
      {children}
      <Icon
        size={size}
        isOpen={isOpen}
        error={error}
        disabled={disabled}
        required={undefined}
      >
        <ArrowDownIcon />
      </Icon>
    </Wrapper>
  );
};

export function Input<OptionValue extends PrimitiveValue, Multiple extends boolean>(
  props: PropsWithChildren<InputProps<OptionValue, Multiple>>,
): ReactElement {
  const { render, placeholder, label, disabled, name, required, error, size } = props;
  const { isOpen, setAnchorElement, closeMenu, inputData } =
    useContext(CustomSelectV2Context);
  const { t } = useTranslation('common');

  const labelText = ((): string => {
    if (label) return label;
    if (!inputData.label) return placeholder || t('customSelect.placeholder');

    return inputData.label;
  })();

  return (
    <>
      {render ? (
        render({
          ...props,
          isOpen,
          setAnchorElement,
          inputData,
          closeMenu,
        })
      ) : (
        <InputContainer size={size} disabled={disabled} required={required} error={error}>
          <Text as="span">{labelText}</Text>
          <input type="hidden" value={inputData.value} name={name} required={required} />
        </InputContainer>
      )}
    </>
  );
}
