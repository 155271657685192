import { FC } from 'react';
import clsx from 'clsx';

import { Paper, Preview, PreviewVariant } from '@/components';

import styles from './styles.module.scss';

export const IntegrationCardPreview: FC = () => {
  return (
    <Paper className={styles.Card}>
      <div className={styles.CardInfoWrapper}>
        <div className={clsx(styles.CardIcon, styles.CardIconPreview)}>
          <Preview variant={PreviewVariant.MEDIUM} />
        </div>
        <div className={clsx(styles.CardInfo, styles.CardInfoPreview)}>
          <div className={clsx(styles.CardTitlePreview, styles.CardTitle)}>
            <Preview variant={PreviewVariant.MEDIUM} />
          </div>
          <div className={clsx(styles.CardDescription, styles.CardDescriptionPreview)}>
            <Preview variant={PreviewVariant.LIGHT} />
          </div>
          <div
            className={clsx(
              styles.CardDescriptionAdditional,
              styles.CardDescriptionAdditionalPreview,
            )}
          >
            <Preview variant={PreviewVariant.LIGHT} />
          </div>
          <div className={clsx(styles.CardButton, styles.CardButtonPreview)}>
            <Preview />
          </div>
        </div>
      </div>
    </Paper>
  );
};
