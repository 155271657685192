import { Chart, TooltipModel } from 'chart.js';

import { HISTORY_EVENTS_DATASET_INDEX } from '../hooks';

import { theme } from '@/theme';

type TooltipExtended = TooltipModel<'line'> & {
  _active: unknown[];
};

export const hoverLinePlugin = {
  id: 'hoverLine',
  afterTooltipDraw(chart: Chart<'line'>) {
    const {
      ctx,
      tooltip,
      chartArea: { top },
      scales: { y },
    } = chart;

    if (tooltip) {
      const targetDataPoint = tooltip?.dataPoints[0];

      if (
        (tooltip as TooltipExtended)._active.length > 0 &&
        targetDataPoint.datasetIndex === HISTORY_EVENTS_DATASET_INDEX
      ) {
        const { caretX: xCord } = tooltip;
        const yCord = y.getPixelForTick(tooltip.dataPoints[0].parsed.y);

        ctx.save();
        ctx.beginPath();

        ctx.lineWidth = 1;
        ctx.strokeStyle = theme.palette.primary.main;

        ctx.setLineDash([5, 5]);

        ctx.moveTo(xCord, yCord);
        ctx.lineTo(xCord, top);
        ctx.stroke();

        ctx.setLineDash([]);
        ctx.closePath();
      }
    }
  },
};
