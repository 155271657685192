import { FC } from 'react';
import {
  InputAdornment as MuiInputAdornment,
  InputAdornmentProps,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from '@mui/material';

import { CancelIcon, SearchIcon } from '@/components/Icons';

declare module '@mui/material' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

export type SearchInputProps = Omit<
  OutlinedInputProps,
  'type' | 'startAdornment' | 'endAdornment'
> & {
  variant?: 'filled' | 'outlined';
  onClear?: () => void;
};

const InputStyled = styled(OutlinedInput)<
  OutlinedInputProps & { variant?: 'filled' | 'outlined' }
>((props) => {
  return {
    padding: ((): string => {
      switch (props.size) {
        case 'small':
          return '0 32px';
        case 'large':
          return '0 36px';
        default:
          return '0 34px';
      }
    })(),
    '.MuiInputAdornment-positionStart': {
      fontSize: ((): string => {
        switch (props.size) {
          case 'small':
            return '12px';
          case 'large':
            return '14px';
          default:
            return '16px';
        }
      })(),
    },
    variants: [
      {
        props: { variant: 'filled' },
        style: {
          backgroundColor: props.theme.palette.grey200,
        },
      },
    ],
  };
});

const StartInputAdornment = styled(MuiInputAdornment)<InputAdornmentProps>(() => ({
  position: 'absolute',
  left: '10px',
}));

const EndInputAdornment = styled(MuiInputAdornment)<InputAdornmentProps>(() => ({
  position: 'absolute',
  right: '10px',
  cursor: 'pointer',
  fontSize: '18px',
}));

export const SearchInput: FC<SearchInputProps> = ({
  size = 'medium',
  value,
  onClear,
  ...props
}) => {
  return (
    <InputStyled
      {...props}
      size={size}
      type="text"
      value={value}
      startAdornment={
        <StartInputAdornment position="start">
          <SearchIcon />
        </StartInputAdornment>
      }
      endAdornment={
        value ? (
          <EndInputAdornment
            position="end"
            onClick={(): void => {
              onClear && onClear();
            }}
          >
            <CancelIcon />
          </EndInputAdornment>
        ) : undefined
      }
    />
  );
};
