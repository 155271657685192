import { FC } from 'react';
import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

export type PaperProps = MuiPaperProps;

export const Paper: FC<PaperProps> = ({ elevation = 0, classes, variant, ...props }) => {
  const { root: rootClass, ...restClasses } = classes || {};

  return (
    <MuiPaper
      {...props}
      classes={{
        root: clsx(styles.paperRoot, rootClass, {
          paperOutlined: variant === 'outlined',
        }),
        ...restClasses,
      }}
      elevation={elevation}
    />
  );
};
