import { FC } from 'react';
import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';

import styles from './styles.module.scss';

type JobTitleCellProps = GridCellParams & {
  value?: string;
};

export const JobTitleCell: FC<JobTitleCellProps> = ({ value }) => {
  return <Box className={styles.jobTitleCell}>{value}</Box>;
};
