import React, { useContext } from 'react';
import { Button } from '@mui/material';

import { ContactsContext } from '../../context';

export const ClearFiltersAction: React.FC = () => {
  const { resetAllFilters, filtersIsApplied } = useContext(ContactsContext);

  if (!filtersIsApplied) return null;

  return <Button onClick={resetAllFilters}>Clear all</Button>;
};
