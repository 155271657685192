import { FC } from 'react';
import { Typography } from '@mui/material';

import { useAvailablePlans } from '../../hooks';

import { PlanCard } from './components';

import styles from './styles.module.scss';

export const ComparePlansHeader: FC = () => {
  const plansToShow = useAvailablePlans();

  return (
    <div className={styles.comparePlansHeaderWrapper}>
      <div className={styles.comparePlansHeader}>
        <div className={styles.comparePlansHeaderStart}>
          <Typography variant="h3" className={styles.comparePlansHeaderStartTitle}>
            Features by plan
          </Typography>
          <Typography className={styles.comparePlansHeaderStartDescription}>
            Compare plans to find the right one for your business.
          </Typography>
        </div>
        {plansToShow.map((planType) => (
          <PlanCard key={planType} variant={planType} />
        ))}
      </div>
    </div>
  );
};
