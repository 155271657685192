import { ChangeEventHandler, useDeferredValue, useState } from 'react';

export const useSearch = (
  defaultValue = '',
): {
  value: string;
  handleSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleSearchClear: () => void;
} => {
  const [search, setSearch] = useState(defaultValue);
  const deferredSearch = useDeferredValue(search);
  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchClear = (): void => {
    setSearch('');
  };

  return {
    value: deferredSearch,
    handleSearchChange,
    handleSearchClear,
  };
};
