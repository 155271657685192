import { FC, PropsWithChildren, ReactElement } from 'react';

import { useFeatureFlag } from '../../hooks';

type FeatureEnabledProps = {
  featureName: string;
  fallback?: ReactElement;
};

export const FeatureEnabled: FC<PropsWithChildren<FeatureEnabledProps>> = ({
  featureName,
  children,
  fallback,
}) => {
  const { isFeatureEnabled } = useFeatureFlag({ feature: featureName });

  return isFeatureEnabled ? children : fallback || null;
};
