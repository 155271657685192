import { inject, injectable } from 'inversify';
import { MigrationStrategies } from 'rxdb';
import { firstValueFrom } from 'rxjs';

import { ACCOUNT_TYPES } from '@/ioc/types';

import { IAccountServicesApi } from '@/features/common/account';
import { IMigrationService } from '@/features/system/db';

import { IAccountDC } from '../../dataContracts/AccountDC';

@injectable()
export class AccountMigrationService implements IMigrationService {
  @inject(ACCOUNT_TYPES.AccountApiService)
  private api: IAccountServicesApi;

  private async getAccount(): Promise<IAccountDC> {
    return await firstValueFrom(this.api.fetchAccount());
  }

  getMigrationStrategies(): MigrationStrategies {
    return {
      1: (oldDoc: IAccountDC): IAccountDC => {
        oldDoc.admin_email = null;
        return oldDoc;
      },
      2: (oldDoc: IAccountDC): IAccountDC => {
        oldDoc.settings.has_tried_to_uninstall_extension =
          oldDoc.settings?.has_tried_to_uninstall_extension || null;
        oldDoc.settings.got_retension_credits =
          oldDoc.settings?.got_retension_credits || null;
        return oldDoc;
      },
      3: async (oldDoc: IAccountDC): Promise<IAccountDC> => {
        const account = await this.getAccount();

        oldDoc.referrer_token = account.referrer_token;
        oldDoc.referrer_credits_earned = account.referrer_credits_earned;
        oldDoc.is_invited = account.is_invited;

        return oldDoc;
      },
      4: async (oldDoc: IAccountDC): Promise<IAccountDC> => {
        const onboarding = oldDoc.settings.onboarding;

        onboarding.is_signup_complete = onboarding.is_signup_complete ?? false;
        onboarding.is_viewed_dashboard = onboarding.is_viewed_dashboard ?? false;
        onboarding.is_clicked_download_extension =
          onboarding.is_clicked_download_extension ?? false;
        onboarding.is_viewed_install_extension_page =
          onboarding.is_viewed_install_extension_page ?? false;

        return oldDoc;
      },
      5: async (oldDoc: IAccountDC): Promise<IAccountDC> => {
        const onboarding = oldDoc.settings.onboarding;

        onboarding.mobile_signup_open_chrome_store =
          onboarding.mobile_signup_open_chrome_store ?? false;

        return oldDoc;
      },
    };
  }
}
