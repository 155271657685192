import type { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    fullWidth: true,
    disableClearable: true,
    autoComplete: true,
    freeSolo: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiAutocomplete-inputRoot': {
        padding: '5px 10px',
        height: 'auto',
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        borderColor: theme.palette.grey300,
      },
    }),
    paper: () => ({
      borderRadius: '6px',
      boxShadow: '10px 10px 40px 0 #d8dfea',
      marginTop: '6px',
    }),
    listbox: () => ({
      padding: '0px',
    }),
    input: ({ theme }) => ({
      border: 'none',
      padding: '0px',
      height: '30px',
      minHeight: 'auto',
      boxSizing: 'border-box',
      fontSize: '13px',
      '&::placeholder': {
        color: theme.palette.grey500,
      },
    }),
  },
};
