import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import {
  useAggregatedCreditsProgress,
  useFullAggregatedCreditsInfo,
} from '@/features/common/workspace';

import { LinearProgress } from '@/components/LinearProgress';

import styles from './styles.module.scss';

export const PlanCardCreditBased: FC = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'planCard.creditBased' });
  const { data: fullAggregatedCreditsInfo } = useFullAggregatedCreditsInfo();
  const { data: aggregatedCreditsProgress } = useAggregatedCreditsProgress();

  if (!fullAggregatedCreditsInfo) {
    return null;
  }

  return (
    <Stack className={styles.wrapper}>
      <Stack direction="row" justifyContent="space-between">
        <Box component="span" className={styles.label}>
          {t('label')}
        </Box>
        <Box component="span" className={styles.value}>
          {fullAggregatedCreditsInfo.isUnlimited
            ? t('unlimited')
            : `${fullAggregatedCreditsInfo.used}/${fullAggregatedCreditsInfo.limit}`}
        </Box>
      </Stack>
      <Box component="span" className={styles.description}>
        {t('description', {
          used: fullAggregatedCreditsInfo.used,
          limit: fullAggregatedCreditsInfo.isUnlimited
            ? 'unlimited'
            : fullAggregatedCreditsInfo.limit,
        })}
      </Box>
      <LinearProgress
        value={aggregatedCreditsProgress}
        orientation="horizontal"
        className={styles.progress}
      />
      {fullAggregatedCreditsInfo.isUnlimited ? null : (
        <CallToAction
          variant="outlined"
          size="small"
          show={'getCredits'}
          className={styles.button}
        />
      )}
    </Stack>
  );
};
