import { PropsWithChildren, ReactElement } from 'react';

import { SearchInputProps } from '@/components';

import {
  Footer,
  FooterProps,
  Header,
  HeaderProps,
  Input,
  InputContainer,
  InputProps,
  List,
  ListProps,
  Menu,
  MenuProps,
  Search,
} from './components';
import { CustomSelectV2ContextProvider } from './CustomSelectV2Context';
import { Option, OptionType, PrimitiveValue, SelectValue, Size } from './types';

export type CustomSelectV2Props<
  OptionValue extends PrimitiveValue,
  Multiple extends boolean,
  Type extends OptionType = 'default',
> = {
  value: Nullable<SelectValue<OptionValue, Multiple>>;
  options: Option<OptionValue, Type>[];
  placeholder?: string;
  label?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  size?: Size;
  multiple?: Multiple;
  searchable?: boolean;
  closeAfterSelect?: boolean;
  InputProps?: Pick<InputProps<OptionValue, Multiple>, 'render'>;
  MenuProps?: {
    SearchProps?: Omit<SearchInputProps, 'onClear'>;
    PopoverProps?: MenuProps['PopoverProps'];
    ListProps?: ListProps<OptionValue, Type> & {
      destroyIfEmpty?: boolean;
    };
    renderHeader?: HeaderProps<OptionValue, Multiple>['render'];
    renderFooter?: FooterProps<OptionValue, Multiple>['render'];
  };
  onChange: (
    value: SelectValue<OptionValue, Multiple>,
    options: { closeMenu: () => void },
  ) => void;
  onClose?: () => void;
};

export function CustomSelectV2<
  OptionValue extends PrimitiveValue,
  Multiple extends boolean,
  Type extends OptionType = 'default',
>({
  value,
  options,
  placeholder,
  label,
  name,
  error,
  required,
  disabled,
  size = 'medium',
  multiple = false as Multiple,
  searchable = false,
  closeAfterSelect = true,
  onChange,
  onClose,
  InputProps = {},
  MenuProps = {
    SearchProps: {},
    PopoverProps: {},
    ListProps: {},
  },
}: PropsWithChildren<CustomSelectV2Props<OptionValue, Multiple, Type>>): ReactElement {
  return (
    <CustomSelectV2ContextProvider
      value={value}
      options={options}
      multiple={multiple}
      searchable={searchable}
      closeAfterSelect={closeAfterSelect}
      onChange={onChange}
      onClose={onClose}
      searchValue={MenuProps.SearchProps?.value as string}
      onSearchChange={MenuProps.SearchProps?.onChange}
    >
      <Input
        placeholder={placeholder}
        label={label}
        name={name}
        error={error}
        required={required}
        disabled={disabled}
        size={size}
        {...InputProps}
      />
      <Menu PopoverProps={MenuProps.PopoverProps}>
        {searchable ? (
          <Header>
            <Search {...MenuProps.SearchProps} />
          </Header>
        ) : MenuProps.renderHeader ? (
          <Header render={MenuProps.renderHeader} />
        ) : null}
        <List {...MenuProps.ListProps} />
        {MenuProps.renderFooter && <Footer render={MenuProps.renderFooter} />}
      </Menu>
    </CustomSelectV2ContextProvider>
  );
}

CustomSelectV2.InputContainer = InputContainer;
