import { FC } from 'react';

import { SplashScreen } from '@/components';

import useSuccessSignUpViewModel from './useSuccessSignUpViewModel';

export const SuccessSignUp: FC = () => {
  useSuccessSignUpViewModel();

  return <SplashScreen />;
};
