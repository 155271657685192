import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import SuccessIcon from '@/assets/icons/export/success.svg?react';

import styles from './styles.module.scss';

export const Success: FC<{ text: string }> = ({ text }) => {
  return (
    <Box className={styles.wrapper}>
      <SuccessIcon />
      <Typography variant="subtitle1" className={styles.description}>
        {text}
      </Typography>
    </Box>
  );
};
