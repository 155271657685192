import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { BarElement, Chart as ChartJS, Title } from 'chart.js';

import { IEnrichmentStatisticEntity } from '../../../../domain/entities';
import { ChartCard } from '../ChartCard';

import { useChartCardPhonesViewModel } from './hooks';

import styles from './styles.module.scss';

ChartJS.register(BarElement, Title);

export type ChartCardPhonesProps = {
  data: IEnrichmentStatisticEntity['phones'];
};

export const ChartCardPhones: React.FC<ChartCardPhonesProps> = (props) => {
  const { t } = useTranslation('enrichment', { keyPrefix: 'report.chart' });
  const { chartProps } = useChartCardPhonesViewModel(props);

  if (!props.data.count) return null;

  return (
    <ChartCard title={t('phonesTitle')}>
      <Box className={styles.chartWrapper}>
        <Bar height={270} {...chartProps} />
      </Box>
    </ChartCard>
  );
};
