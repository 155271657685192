import { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { of } from 'rxjs';

import { useInjection } from '@/ioc';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import {
  PlanDowngradeDialog,
  ProductAlreadyOwnedDialog,
  ReduceActiveUsersDialog,
  usePlanDowngradeDialog,
  useProductAlreadyOwnedDialog,
  useReduceActiveUsersDialog,
} from '@/features/common/billing';
import type { IPaymentDetailsUseCase } from '@/features/paymentDetails';
import { ProductAlreadyOwnedError } from '@/features/paymentDetails/domain/errors/ProductAlreadyOwnedError';
import { ProductDowngradeError } from '@/features/paymentDetails/domain/errors/ProductDowngradeError';
import { ReduceActiveUsersCountError } from '@/features/paymentDetails/domain/errors/ReduceActiveUsersCountError';

import { SplashScreen } from '@/components';

import { useObservableResult } from '@/utils/rx';

import { useQuantityFromUrl } from '../../hooks';
import { PaymentDetailsContext } from '../PaymentDetailsContext';

import { CanBuyContext } from './Context';

export const CanBuyProvider: FC<PropsWithChildren> = ({ children }) => {
  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const { targetProduct } = useContext(PaymentDetailsContext);

  const [seats] = useQuantityFromUrl(1);

  const reduceActiveUsersDialog = useReduceActiveUsersDialog();
  const planDowngradeDialog = usePlanDowngradeDialog();
  const productAlreadyOwnedDialog = useProductAlreadyOwnedDialog();

  const canBuy = useCallback(async (): Promise<boolean> => {
    try {
      await paymentDetailsUseCase.assertCanBuyProduct({ plan: targetProduct.id, seats });
      return true;
    } catch (error) {
      switch (true) {
        case error instanceof ProductAlreadyOwnedError:
          productAlreadyOwnedDialog.onOpen(error.product);
          break;
        case error instanceof ProductDowngradeError:
          planDowngradeDialog.onOpen(error.product);
          break;
        case error instanceof ReduceActiveUsersCountError:
          reduceActiveUsersDialog.onOpen(error);
          break;
      }

      return false;
    }
  }, [targetProduct, seats]);

  const initialCanBuyCheckResult = useObservableResult(() => of(canBuy()), {
    deps: [targetProduct, seats],
  });

  const value = useMemo(() => ({ canBuy }), [canBuy]);

  if (initialCanBuyCheckResult.isLoading) {
    return <SplashScreen />;
  }

  return (
    <CanBuyContext.Provider value={value}>
      <ProductAlreadyOwnedDialog {...productAlreadyOwnedDialog} />
      <ReduceActiveUsersDialog {...reduceActiveUsersDialog} />
      <PlanDowngradeDialog {...planDowngradeDialog} />
      {children}
    </CanBuyContext.Provider>
  );
};
