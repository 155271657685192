import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export const createChartMinDateGuard =
  (minDateTimestamp: number) =>
  (date: Dayjs | string): Dayjs => {
    const minDate = dayjs.unix(minDateTimestamp);
    const parsedDate = typeof date === 'string' ? dayjs(date) : date;

    const max = dayjs.max(parsedDate, minDate);

    if (!max) throw new Error('Date is less than min date');

    return max;
  };
