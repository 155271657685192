import { injectable } from 'inversify';

import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { IContactDC } from '../../dataContracts';

export type IContactDao = IDbCollection<IContactDC, 'uuid'>;

@injectable()
export class ContactDao extends DbCollection<IContactDC, 'uuid'> implements IContactDao {
  constructor() {
    super({ collectionName: CollectionName.Contact, idKey: 'uuid' });
  }
}
