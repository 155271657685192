import { inject, injectable } from 'inversify';
import { catchError, combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IBillingUseCase } from '@/features/common/billing/domain';
import type {
  IAggregatedCreditEntity,
  ICreditUseCase,
  ISubscriptionUseCase,
  IWorkspaceSubscriptionEntity,
  IWorkspaceUseCase,
} from '@/features/common/workspace';

import { distinctUntilKeysChanged } from '@/utils/rx';

import type { IBillingSettingsUseCase } from './abstractions/IBillingSettingsUseCase';
import type { ICreditsDetailsEntity } from './entities/CreditsDetailsEntity';
import type { IPaymentMethodDetailsEntity } from './entities/PaymentMethodDetailsEntity';
import type { IPlanDetailsEntity } from './entities/PlanDetailsEntity';

@injectable()
export class BillingSettingsUseCase implements IBillingSettingsUseCase {
  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private readonly subscriptionUseCase: ISubscriptionUseCase;

  @inject(BILLING_TYPES.BillingUseCase)
  private readonly billingUseCase: IBillingUseCase;

  @inject(WORKSPACE_TYPES.CreditUseCase)
  private readonly creditUseCase: ICreditUseCase;

  @inject(WORKSPACE_TYPES.WorkspaceUseCase)
  private readonly workspaceUseCase: IWorkspaceUseCase;

  public getCurrentPlanDetails(): Observable<IPlanDetailsEntity> {
    return this.workspaceUseCase.getCurrentWorkspace().pipe(
      filter((workspace) => !!workspace),
      map((workspace) => {
        const subscription = workspace.subscription;

        return {
          name: subscription.planName,
          isUpgradable: !subscription.planIsUnlimited,
          isCustom: subscription.planIsCustom,
          lifecycle: {
            type:
              subscription.isActive && !subscription.isGift ? 'renewable' : 'expirable',
            date: subscription.expirationDate
              ? new Date(subscription.expirationDate * 1000)
              : undefined,
          },
          seats: {
            count: workspace.billableMembersCount,
            limit:
              subscription.planPaidMembersLimit || subscription.paidMembersCount || 0,
          },
        } satisfies IPlanDetailsEntity;
      }),
    );
  }

  public getCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return combineLatest({
      fullCredits: this.creditUseCase.getFullAggregatedCreditsInfo(),
      bulkCredits: this.creditUseCase.getBulkAggregatedCreditsInfo(),
      subscription: this.subscriptionUseCase.getSubscription(),
    }).pipe(
      map(({ fullCredits, bulkCredits, subscription }) => {
        return {
          bySources: {
            planFull:
              fullCredits && this.getPlanFullCreditDetails(subscription, fullCredits),
            planBulk: bulkCredits && this.getPlanBulkCreditDetails(bulkCredits),
          },
        };
      }),
    );
  }

  private getPlanFullCreditDetails(
    subscription: IWorkspaceSubscriptionEntity,
    aggregatedCreditEntity: IAggregatedCreditEntity,
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (!aggregatedCreditEntity) return undefined;

    return {
      plan: {
        name: subscription.planName,
        isFree: subscription.planIsFree,
      },
      limit: aggregatedCreditEntity.limit,
      used: aggregatedCreditEntity.used,
      isUnlimited: aggregatedCreditEntity.isUnlimited,
    };
  }

  private getPlanBulkCreditDetails(
    aggregatedCreditEntity: IAggregatedCreditEntity,
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (!aggregatedCreditEntity) return undefined;

    return {
      limit: aggregatedCreditEntity.limit,
      used: aggregatedCreditEntity.used,
      isUnlimited: aggregatedCreditEntity.isUnlimited,
    };
  }

  private resolveStatus(
    subscription: IWorkspaceSubscriptionEntity,
  ): 'active' | 'canceled' | 'suspended' | 'grace' {
    if (!subscription.isActive) return 'suspended';
    if (subscription.isCanceled) return 'canceled';
    if (subscription.isGracePeriod) return 'grace';

    return 'active';
  }

  public getPaymentMethodDetails(): Observable<IPaymentMethodDetailsEntity> {
    return this.subscriptionUseCase.getSubscription().pipe(
      distinctUntilKeysChanged(
        'plan',
        'futurePlan',
        'paidMembersCount',
        'expirationDate',
        'isActive',
        'isCanceled',
        'isGracePeriod',
        'isGift',
      ),
      switchMap((subscription) => {
        return combineLatest({
          subscription: of(subscription),
          paymentMethod: this.billingUseCase.getPaymentMethod(),
          upcomingInvoice: subscription.stripeCustomerId
            ? this.billingUseCase
                .getUpcomingInvoice({
                  plan: subscription.plan,
                })
                .pipe(catchError(() => of(null)))
            : of(null),
        });
      }),
      map(({ subscription, paymentMethod, upcomingInvoice }) => {
        return {
          method: paymentMethod,
          upcomingInvoice: upcomingInvoice
            ? {
                ammount: upcomingInvoice.total,
                expiresAt: upcomingInvoice.next_payment_attempt
                  ? new Date(upcomingInvoice.next_payment_attempt * 1000)
                  : subscription.expirationDate
                    ? new Date(subscription.expirationDate * 1000)
                    : undefined,
              }
            : undefined,
          status: this.resolveStatus(subscription),
          isGift: subscription.isGift,
        } satisfies IPaymentMethodDetailsEntity;
      }),
    );
  }
}
