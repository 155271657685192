import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, styled } from '@mui/material';

const Wrapper = styled(Box)<BoxProps>(() => ({
  padding: '10px',
  overflow: 'hidden',
  fontSize: '13px',
}));

export const NoResults: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');

  return <Wrapper>{children || t('customSelect.noResults')}</Wrapper>;
};
