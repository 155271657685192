import { Container } from 'inversify';

import { GET_FREE_CREDITS_TYPES } from '@/ioc/types';

import {
  IGetFreeCreditsRepository,
  IGetFreeCreditsUseCase,
} from '@/features/getFreeCredits';
import { GetFreeCreditsRepository } from '@/features/getFreeCredits/data/GetFreeCreditsRepository';
import GetFreeCreditsUseCase from '@/features/getFreeCredits/domain/GetFreeCreditsUseCase';

export const bindGetFreeCreditsModule = (container: Container): void => {
  container
    .bind<IGetFreeCreditsUseCase>(GET_FREE_CREDITS_TYPES.GetFreeCreditsUseCase)
    .to(GetFreeCreditsUseCase);
  container
    .bind<IGetFreeCreditsRepository>(GET_FREE_CREDITS_TYPES.GetFreeCreditsRepository)
    .to(GetFreeCreditsRepository);
};
