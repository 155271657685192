import { ITagDC, ITagEntity } from '@/features/common/tag';

export const mapTagDcToEntity = (dc: ITagDC): ITagEntity => ({
  uuid: dc.uuid,
  color: dc.color,
  name: dc.name,
  createdBy: dc.created_by,
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  contactsAmount: dc.contacts_amount,
});
