import { FC } from 'react';

import { CardBrand } from '@/features/common/billing/domain';

import AmexIcon from '@/assets/icons/billing/cardTypes/amex.svg?react';
import DinersIcon from '@/assets/icons/billing/cardTypes/diners.svg?react';
import DiscoverIcon from '@/assets/icons/billing/cardTypes/discover.svg?react';
import JcbIcon from '@/assets/icons/billing/cardTypes/jcb.svg?react';
import MastercardIcon from '@/assets/icons/billing/cardTypes/mastercard.svg?react';
import ChinaUnionPayIcon from '@/assets/icons/billing/cardTypes/unionpay.svg?react';
import VisaIcon from '@/assets/icons/billing/cardTypes/visa.svg?react';

export const CardIcon: FC<{ brand: CardBrand }> = ({ brand }) => {
  switch (brand) {
    case CardBrand.Visa:
      return <VisaIcon />;
    case CardBrand.MasterCard:
      return <MastercardIcon />;
    case CardBrand.Discover:
      return <DiscoverIcon />;
    case CardBrand.AmericanExpress:
      return <AmexIcon />;
    case CardBrand.JCB:
      return <JcbIcon />;
    case CardBrand.UnionPay:
      return <ChinaUnionPayIcon />;
    case CardBrand.DinersClub:
      return <DinersIcon />;
    case CardBrand.EftposAustralia:
    case CardBrand.Unknown:
    default:
      return (
        <img
          style={{ height: '1em' }}
          src="https://files.readme.io/d1a25b4-generic-card.png"
        />
      );
  }
};
