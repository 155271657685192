import { inject, injectable } from 'inversify';
import type { Observable } from 'rxjs';

import { INTEGRATION_TYPES } from '@/ioc/types';

import type { IIntegrationRepository, IIntegrationUseCase } from './abstractions';
import type { IIntegrationEntity, IIntegrationMapEntity } from './entities';

@injectable()
export default class IntegrationUseCase implements IIntegrationUseCase {
  @inject(INTEGRATION_TYPES.IntegrationRepository)
  private repository: IIntegrationRepository;

  getMapping(provider: string, objectType: string): Observable<IIntegrationMapEntity> {
    return this.repository.getMapping(provider, objectType);
  }

  saveMapping(provider: string, objectType: string, data: []): Promise<void> {
    return this.repository.saveMapping(provider, objectType, data);
  }

  disconnectUser(provider: string): Promise<void> {
    return this.repository.disconnectUser(provider);
  }

  updateSettings(provider: string, enabledObjects: string[]): Promise<void> {
    return this.repository.updateSettings(provider, enabledObjects);
  }

  getIntegration(provider: string): Observable<IIntegrationEntity> {
    return this.repository.getIntegration(provider);
  }
}
