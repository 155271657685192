import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import {
  ActionDisableGuard,
  HasPermission,
  Permission,
  usePermissions,
} from '@/features/common/permissions';
import { OnlineRequiredAction } from '@/features/system/OnlineTracker';

import { Paper } from '@/components';
import { CheckIcon } from '@/components/Icons';

import { IIntegrationEntityUI } from '../../entities';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntityUI;
  onConnect: (integration: IIntegrationEntityUI) => void;
  onContactSales: (integration: IIntegrationEntityUI) => void;
};

export const IntegrationCard: FC<Props> = ({
  integration,
  onConnect,
  onContactSales,
}) => {
  const { t } = useTranslation('integrations');
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { id, name, icon, isConnected, isExpired } = integration;

  const hasAccessPermissions = permissions.hasPermissions(
    Permission.CanAccessCrmIntegration,
  );

  const shouldContactSales = integration?.id === 'custom';

  const manageIntegrationRoute = `${ROUTES.INTEGRATIONS}/${id}`;

  const handleClick: MouseEventHandler = (): void => {
    if (shouldContactSales) return onContactSales(integration);
    onConnect(integration);
  };

  const handleRedirect: MouseEventHandler = (e): void => {
    if (e.metaKey) return;
    e.preventDefault();
    navigate(manageIntegrationRoute);
  };

  return (
    <Paper className={styles.Card}>
      <Box className={styles.CardInfoWrapper}>
        <Box
          className={styles.CardIcon}
          style={{
            backgroundImage: `url(${icon})`,
          }}
        />
        <Box className={styles.CardInfo}>
          <Typography className={styles.CardTitle}>{t(name)}</Typography>
          <Typography className={styles.CardDescription}>
            {t(`cards.${id}Description`)}
          </Typography>
          {isExpired ? (
            <div className={styles.reconnect}>
              <Typography className={styles.reconnectTitle}>
                {t('reconnect.description')}
              </Typography>
              <HasPermission permission={Permission.CanEditIntegrations}>
                <ActionDisableGuard>
                  <OnlineRequiredAction>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={styles.CardButton}
                      onClick={handleClick}
                    >
                      {t('reconnect.buttonTitle')}
                    </Button>
                  </OnlineRequiredAction>
                </ActionDisableGuard>
              </HasPermission>
            </div>
          ) : isConnected ? (
            <div className={styles.connected}>
              <Typography className={styles.connectedTitle}>
                <CheckIcon />
                {t('accountConnectedText')}
              </Typography>
              <HasPermission permission={Permission.CanEditIntegrations}>
                <ActionDisableGuard>
                  <OnlineRequiredAction>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={styles.CardButton}
                      href={manageIntegrationRoute}
                      onClick={handleRedirect}
                      disabled={!hasAccessPermissions}
                    >
                      {t('manageButtonText')}
                    </Button>
                  </OnlineRequiredAction>
                </ActionDisableGuard>
              </HasPermission>
            </div>
          ) : (
            <ActionDisableGuard>
              <OnlineRequiredAction>
                <Button
                  color="primary"
                  variant="contained"
                  className={styles.CardButton}
                  onClick={handleClick}
                >
                  {shouldContactSales
                    ? t('contactSalesButtonText')
                    : t('connectButtonText')}
                </Button>
              </OnlineRequiredAction>
            </ActionDisableGuard>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
