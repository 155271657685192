import { BillingCycle } from '@/features/common/billing';

import {
  BillingCycleDependent,
  ComingSoon,
  FullySupported,
  LimitedByQuantity,
  Unlimited,
  Unsupported,
} from '../types';

export function unsupported(): Unsupported {
  return { brandTag: 'unsupported' };
}

export function fullySupported(): FullySupported {
  return { brandTag: 'supported' };
}

export function unlimitted(): Unlimited {
  return { brandTag: 'unlimited' };
}

export function comingSoon(): ComingSoon {
  return { brandTag: 'comingSoon' };
}

export function limittedByQuantity(
  params: Omit<LimitedByQuantity, 'brandTag'>,
): LimitedByQuantity {
  return { brandTag: 'limited', ...params };
}

export function billingCycleDependent(
  support: Record<
    BillingCycle.Monthly | BillingCycle.Yearly,
    LimitedByQuantity | Unlimited
  >,
): BillingCycleDependent {
  return { brandTag: 'billingCycleDependent', support };
}
