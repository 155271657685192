import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { WORKSPACE_TYPES } from '@/ioc/types';

import { ITeamMemberEntity } from '@/features/settings';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IWorkspaceUseCase } from '../../domain';

export function useCurrentWorkspaceOwner(): ObservableResult<ITeamMemberEntity | null> {
  const useCase = useInjection<IWorkspaceUseCase>(WORKSPACE_TYPES.WorkspaceUseCase);

  const currentWorkspaceOwner$ = useMemo(() => useCase.getCurrentWorkspaceOwner(), []);

  return useObservableResult(currentWorkspaceOwner$);
}
