import { injectable } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import { DbState } from '@/features/system/db';

import { IIntegrationProviderDC, IIntegrationStatusDC } from '../dataContracts';

export interface IIntegrationsStateDC {
  integrations: IIntegrationStatusDC[];
  providers: IIntegrationProviderDC[];
}

export interface IIntegrationsState {
  getState(): Observable<Nullable<IIntegrationsStateDC>>;
  setState(state: IIntegrationsStateDC): Promise<void>;
}

@injectable()
export class IntegrationsState
  extends DbState<IIntegrationsStateDC>
  implements IIntegrationsState
{
  constructor() {
    super({ stateName: 'integrations' });
  }

  getState(): Observable<Nullable<IIntegrationsStateDC>> {
    return combineLatest({
      integrations: this.get$('integrations'),
      providers: this.get$('providers'),
    }).pipe(
      map(({ integrations, providers }) => {
        if (!integrations && !providers) {
          return null;
        }

        return {
          integrations: integrations ?? [],
          providers: providers ?? [],
        };
      }),
    );
  }

  async setState(state: IIntegrationsStateDC): Promise<void> {
    await Promise.all([
      this.set('integrations', () => state.integrations),
      this.set('providers', () => state.providers),
    ]);
  }
}
