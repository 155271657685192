import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GroupManagementIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 10.119 14.5">
    <path
      d="M342.79 45.583a1.457 1.457 0 1 1 1.457-1.457 1.457 1.457 0 0 1-1.457 1.457zm0-2.332a.874.874 0 1 0 .874.874.877.877 0 0 0-.874-.874zm0 0"
      transform="translate(-335.544 -41.835)"
      fill="#8083a3"
    />
    <path
      d="M342.79 45.833a1.707 1.707 0 1 1 1.707-1.707 1.709 1.709 0 0 1-1.707 1.707zm0-2.915a1.207 1.207 0 1 0 1.21 1.207 1.209 1.209 0 0 0-1.21-1.207zm0 2.332a1.124 1.124 0 1 1 1.124-1.124 1.126 1.126 0 0 1-1.124 1.124zm0-1.749a.624.624 0 1 0 .624.624.625.625 0 0 0-.624-.625z"
      transform="translate(-335.544 -41.835)"
      fill="#8083a3"
    />
    <path
      d="M340.766 179.121h-.619a.874.874 0 0 1-.856-.7l-.357-1.807.572-.113.357 1.807a.289.289 0 0 0 .284.233h.619a.289.289 0 0 0 .284-.233l.7-3.556h.452a.292.292 0 0 0 .291-.292v-2.332a.877.877 0 0 0-.874-.874h-1.457v-.583h1.457a1.457 1.457 0 0 1 1.458 1.457v2.332a.876.876 0 0 1-.845.874l-.612 3.086a.874.874 0 0 1-.854.701zm0 0"
      transform="translate(-333.211 -165.746)"
      fill="#8083a3"
    />
    <path
      d="M340.766 179.371h-.619a1.124 1.124 0 0 1-1.1-.905l-.405-2.052 1.063-.21.406 2.052a.04.04 0 0 0 .039.032h.619a.039.039 0 0 0 .039-.03l.743-3.76h.657a.043.043 0 0 0 .041-.042v-2.332a.625.625 0 0 0-.624-.624h-1.707v-1.083h1.707a1.709 1.709 0 0 1 1.708 1.707v2.332a1.122 1.122 0 0 1-.885 1.1l-.577 2.91a1.124 1.124 0 0 1-1.105.905zm-1.539-2.564.309 1.562a.623.623 0 0 0 .611.5h.619a.623.623 0 0 0 .611-.5l.651-3.28.2-.008a.625.625 0 0 0 .6-.625v-2.332a1.209 1.209 0 0 0-1.208-1.207h-1.207V171h1.207a1.126 1.126 0 0 1 1.124 1.124v2.332a.542.542 0 0 1-.541.542h-.246l-.663 3.355a.538.538 0 0 1-.529.435h-.619a.539.539 0 0 1-.53-.437l-.308-1.56z"
      transform="translate(-333.211 -165.746)"
      fill="#8083a3"
    />
    <path
      d="M183.081 3.5a1.749 1.749 0 1 1 1.749-1.749 1.749 1.749 0 0 1-1.749 1.749zm0-2.915a1.166 1.166 0 1 0 1.166 1.166 1.169 1.169 0 0 0-1.166-1.168zm0 0"
      transform="translate(-179.916 .25)"
      fill="#8083a3"
    />
    <path
      d="M183.081 3.748a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3.5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.498zm0 2.915a1.416 1.416 0 1 1 1.419-1.414 1.418 1.418 0 0 1-1.419 1.416zm0-2.332a.916.916 0 1 0 .916.916.917.917 0 0 0-.916-.914z"
      transform="translate(-179.916 .25)"
      fill="#8083a3"
    />
    <path
      d="M142.115 159.243h-1.064a.884.884 0 0 1-.925-.853l-.583-3.52a.877.877 0 0 1-.874-.874v-3.206a1.457 1.457 0 0 1 1.457-1.458h2.915a1.457 1.457 0 0 1 1.457 1.458V154a.877.877 0 0 1-.874.874h-.011l-.576 3.545a.88.88 0 0 1-.922.824zm-1.989-9.328a.877.877 0 0 0-.874.874V154a.292.292 0 0 0 .292.292h.506l.656 4.033c.018.233.135.339.346.339h1.064a.3.3 0 0 0 .342-.313l.656-4.059h.51a.292.292 0 0 0 .292-.292v-3.21a.877.877 0 0 0-.874-.874zm0 0"
      transform="translate(-138.418 -144.993)"
      fill="#8083a3"
    />
    <path
      d="M142.115 159.493h-1.064a1.136 1.136 0 0 1-1.174-1.073l-.55-3.32a1.126 1.126 0 0 1-.909-1.1v-3.21a1.709 1.709 0 0 1 1.707-1.708h2.915a1.709 1.709 0 0 1 1.707 1.708V154a1.126 1.126 0 0 1-.92 1.106l-.543 3.344a1.125 1.125 0 0 1-1.169 1.043zm-1.989-9.911a1.209 1.209 0 0 0-1.207 1.208V154a.625.625 0 0 0 .624.624h.212l.62 3.752a.638.638 0 0 0 .676.62h1.064a.634.634 0 0 0 .673-.6v-.021l.61-3.755h.224a.625.625 0 0 0 .624-.624v-3.206a1.209 1.209 0 0 0-1.207-1.208zm1.989 9.328h-1.064a.562.562 0 0 1-.594-.558l-.62-3.814h-.294A.542.542 0 0 1 139 154v-3.21a1.126 1.126 0 0 1 1.124-1.124h2.915a1.126 1.126 0 0 1 1.124 1.124V154a.542.542 0 0 1-.542.542h-.3l-.62 3.839a.552.552 0 0 1-.586.529zm-1.989-8.745a.625.625 0 0 0-.624.624V154a.043.043 0 0 0 .042.042h.719l.692 4.264c.008.108.025.108.1.108h1.064c.087 0 .088-.011.093-.081v-.022l.69-4.269h.723a.043.043 0 0 0 .042-.042v-3.21a.625.625 0 0 0-.624-.624z"
      transform="translate(-138.418 -144.993)"
      fill="#8083a3"
    />
  </SvgIcon>
);
