import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { useOnboardingUseCase } from '@/features/common/onboarding';

import { openInNewTab } from '@/utils/openInNewTab';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const GetExtension: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();
  const onboardingUseCase = useOnboardingUseCase();

  const handleClick = (): void => {
    openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
    onboardingUseCase.completeClickDownloadExtensionStep();
    onClick?.();
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={props.text ?? t('callToActions.getExtension', { ns: 'common' })}
    />
  );
};
