import { FC, forwardRef } from 'react';

import { CustomizedAlert, CustomizedAlertProps } from './CustomizedAlert';

type InfoAlertProps = CustomizedAlertProps;

export const InfoAlert: FC<InfoAlertProps> = forwardRef<HTMLDivElement, InfoAlertProps>(
  (props, ref) => {
    return <CustomizedAlert ref={ref} {...props} variant="info" />;
  },
);

InfoAlert.displayName = 'InfoAlert';
