import { Container } from 'inversify';

import { FAIR_USAGE_TYPES } from '@/ioc/types';

import { FairUsageUseCase, IFairUsageUseCase } from '../domain';

export const bindFairUsageModule = (container: Container): void => {
  container
    .bind<IFairUsageUseCase>(FAIR_USAGE_TYPES.FairUsageUseCase)
    .to(FairUsageUseCase);
};
