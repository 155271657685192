import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ActionDisableGuard } from '@/features/common/permissions';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { Modal } from '@/components';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconArrows from '@/assets/icons/integrations/arrows.svg?react';
import IconConnectPowerlead from '@/assets/icons/integrations/connectPowerleadIcon.svg?react';

import { IIntegrationEntityUI } from '../../entities';
import { useIntegrationsUseCase } from '../../hooks';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  integration: Nullable<IIntegrationEntityUI>;
  onClose(): void;
  onContactSales: (integration: IIntegrationEntityUI) => void;
};

export const IntegrationConnectDialog: FC<Props> = ({
  isOpen,
  integration,
  onClose,
  onContactSales,
}) => {
  const { t } = useTranslation(['integrations', 'common']);
  const analytics = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const integrationsUseCase = useIntegrationsUseCase();
  const [isProcessing, setIsProcessing] = useState(false);

  const title = integration?.name ?? '';
  const icon = integration?.icon ?? '';

  const isWiderIcon = integration?.id === 'salesforce';

  const shouldContactSales = !integration?.isAvailable;

  useEffect(() => {
    if (isOpen && integration) {
      analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_CONNECT_CLICK, {
        type: t(integration.name),
      });
    }
  }, [integration?.id, isOpen]);

  const handleSubmit = async (): Promise<void> => {
    if (!integration) throw new Error('Integration is not defined');

    if (shouldContactSales) {
      onContactSales(integration);
      onClose();
      return;
    }

    try {
      setIsProcessing(true);

      const { url } = await integrationsUseCase.getAuthUrl(integration.id);
      window.open(url, '_blank');
    } catch {
      enqueueSnackbar({
        variant: 'error',
        message: t('errors.errorOccurred', {
          ns: 'common',
        }),
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal containerClass={styles.dialogPaper} open={isOpen} onClose={onClose}>
      <>
        <IconClose onClick={onClose} className={styles.closeIcon} />
        <div className={styles.dialogIcons}>
          <div className={styles.withBorder}>
            <IconConnectPowerlead />
          </div>
          <IconArrows />
          <div className={styles.integrationIconWrapper}>
            <img src={icon} alt={t(title)} style={{ width: isWiderIcon ? 65 : 52 }} />
          </div>
        </div>
        <Typography className={styles.dialogTitle}>
          {t('connectDialog.title', { title: t(title) })}
        </Typography>
        <Typography className={styles.dialogSubtitle}>
          {t('connectDialog.description', { title: t(title) })}
        </Typography>

        <ActionDisableGuard>
          <Button
            onClick={handleSubmit}
            className={styles.dialogButton}
            color="primary"
            variant="contained"
            disabled={isProcessing}
          >
            {shouldContactSales ? t('contactSalesButtonText') : t('connectButtonText')}
          </Button>
        </ActionDisableGuard>
      </>
    </Modal>
  );
};
