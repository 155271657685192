import { IExtensionMessageEntity } from '@/features/common/extension';

export class LoggedInExtensionMessage
  implements IExtensionMessageEntity<'FROM_WEB_LOGGED_IN', { token: string }>
{
  public type = 'FROM_WEB_LOGGED_IN' as const;
  public data: { readonly token: string };

  constructor(token: string) {
    this.data = { token };
  }
}
