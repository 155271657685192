import { v4 as uuidv4 } from 'uuid';

import type { IReceiptAdjustmentEntity } from './ReceiptAdjustmentEntity';

export class PercentageReceiptAdjustment implements IReceiptAdjustmentEntity {
  public id: string;
  public title: string;
  public percentage: number;
  public description?: string | undefined;
  public type: 'discount' | 'surcharge';
  public isApplyable: boolean;

  private applyiedAmount?: number = undefined;

  public get amount(): number {
    if (!this.isApplyable) return 0;

    if (this.applyiedAmount === undefined) {
      throw new Error('Amount is not calculated yet');
    }

    return this.applyiedAmount;
  }

  constructor(params: {
    id?: string;
    percentage: number;
    title: string;
    type: 'discount' | 'surcharge';
    description?: string;
    isApplyable?: boolean;
  }) {
    this.id = params.id ?? uuidv4();
    this.percentage = params.percentage;
    this.title = params.title;
    this.description = params.description;
    this.type = params.type;
    this.isApplyable = params.isApplyable ?? true;
  }

  apply(sum: number): number {
    if (!this.isApplyable) return sum;

    this.applyiedAmount = sum * this.percentage;

    if (this.type === 'discount') {
      return sum - this.applyiedAmount;
    }

    return sum + this.applyiedAmount;
  }
}
