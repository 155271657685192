import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

export class Gate {
  private _isLocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLocked(): boolean {
    return this._isLocked.value;
  }

  pass = <T>(source: Observable<T>): Observable<T> => {
    return combineLatest([this._isLocked, source]).pipe(
      filter(([isLocked]) => !isLocked),
      map(([, source]) => source),
    );
  };

  withLocked = <T>(source: Observable<T>): Observable<T> => {
    return of(this.lock()).pipe(
      switchMap(() => source),
      finalize(() => this.unlock()),
    );
  };

  lock(): void {
    this._isLocked.next(true);
  }

  unlock(): void {
    this._isLocked.next(false);
  }
}
