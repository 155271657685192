import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  VkIcon,
  XIcon,
} from '@/components/Icons';

export type SocialItem = {
  host: string;
  Icon: FC<SvgIconProps>;
  name: string;
};

export type SocialItemResult = SocialItem & { url: string };

export const getSocialItemByUrl = (url: string): SocialItemResult | undefined => {
  const SocialItems: SocialItem[] = [
    {
      host: 'linkedin.com',
      Icon: LinkedinIcon,
      name: 'LinkedIn',
    },
    {
      host: 'facebook.com',
      Icon: FacebookIcon,
      name: 'Facebook',
    },
    {
      host: 'twitter.com',
      Icon: XIcon,
      name: 'Twitter',
    },
    {
      host: 'x.com',
      Icon: XIcon,
      name: 'X',
    },
    {
      host: 'instagram.com',
      Icon: InstagramIcon,
      name: 'Instagram',
    },
    {
      host: 'vk.com',
      Icon: VkIcon,
      name: 'VK',
    },
  ];

  const result = SocialItems.find((item) => url.indexOf(item.host) >= 0);

  if (result) {
    return { ...result, url };
  }
};
