import { injectable } from 'inversify';
import Cookies, { CookieSetOptions } from 'universal-cookie';

export interface ICookiesManager {
  remove(name: string, options?: CookieSetOptions): void;
  get: <T>(name: string) => T;
  // TODO: we have to move this to a feature
  setSharableAuthIdentityCookie(): void;
  removeSharableAuthIdentityCookie(): void;
}

@injectable()
export class CookiesManager implements ICookiesManager {
  static appSubDomain = import.meta.env.REACT_APP_SUBDOMAIN;
  static sharableAuthIdentityCookieName = 'pwAuthIdentity';
  private _cookies: Cookies;

  constructor() {
    this._cookies = new Cookies(null, {});
  }

  public remove(name: string, options?: CookieSetOptions): void {
    return this._cookies.remove(name, options);
  }

  public setSharableAuthIdentityCookie(): void {
    const expireDate = new Date();
    expireDate.setMonth(expireDate.getMonth() + 1);
    this._cookies.set(
      CookiesManager.sharableAuthIdentityCookieName,
      JSON.stringify({
        domain: import.meta.env.REACT_APP_POWERLEAD_MARKETING_SITE_DOMAIN,
        auth: true,
      }),
      {
        domain: CookiesManager.appSubDomain,
        expires: expireDate,
      },
    );
  }

  public get<T>(name: string): T {
    return this._cookies.get(name);
  }

  public removeSharableAuthIdentityCookie(): void {
    this._cookies.remove(CookiesManager.sharableAuthIdentityCookieName, {
      domain: CookiesManager.appSubDomain,
    });
  }
}
