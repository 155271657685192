import { Container, injectable } from 'inversify';
import { CountryCode, isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export interface IPhoneHelper {
  format(phone: string, userCountry?: CountryCode): string;
  isPossibleNumber(phone: string, country: CountryCode): boolean;
}

@injectable()
export class PhoneHelper implements IPhoneHelper {
  format = (phone: string, userCountry?: CountryCode): string => {
    // detect count of digit in phone number
    const count = (phone.match(/\d/g) || []).length;
    if (count <= 1) return `+${phone}`;
    const numberWithPlus = this.getNumberWithPlus(phone);

    try {
      const parsed = parsePhoneNumber(numberWithPlus);

      return parsed.formatInternational();
    } catch (e) {
      try {
        if (userCountry) {
          const parsed = parsePhoneNumber(numberWithPlus, userCountry);

          return parsed.formatInternational();
        }
        return phone;
      } catch (er) {
        try {
          const parsed = parsePhoneNumber(phone, userCountry);

          return parsed.formatInternational();
        } catch (err) {
          return phone;
        }
      }
    }
  };

  isPossibleNumber = (phone: string): boolean => {
    return isPossiblePhoneNumber(this.getNumberWithPlus(phone));
  };

  private getNumberWithPlus(number: string): string {
    return number.startsWith('+') ? number : `+${number}`;
  }
}

export const TYPES = {
  PhoneHelper: Symbol.for('PhoneHelper'),
};

export const bindPhoneHelperModule = (container: Container): void => {
  container.bind<IPhoneHelper>(TYPES.PhoneHelper).to(PhoneHelper);
};
