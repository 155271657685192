import { useEffect } from 'react';

import { useInjection } from '@/ioc/index';
import { ANALYTICS_TYPES } from '@/ioc/types';

import { IAnalyticsRepository } from '../domain';

export const useAnalytics = (
  event?: string,
  eventProperties?: Record<string, unknown>,
) => {
  const analyticsRepository = useInjection<IAnalyticsRepository>(
    ANALYTICS_TYPES.AnalyticsRepository,
  );

  useEffect(() => {
    if (event) {
      trackEvent(event, eventProperties);
    }
  }, []);

  const trackEvent = (event, eventProperties?) =>
    analyticsRepository.trackEvent(event, eventProperties);

  return {
    trackEvent,
  };
};
